import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const WIZARDCREATEJOBTITLE_REQUEST = 'WIZARDCREATEJOBTITLE_REQUEST';
export const WIZARDCREATEJOBTITLE_SUCCESS = 'WIZARDCREATEJOBTITLE_SUCCESS';
export const WIZARDCREATEJOBTITLE_FAILURE = 'WIZARDCREATEJOBTITLE_FAILURE';


export const wizard_create_jobtitle = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDCREATEJOBTITLE_REQUEST'
        });

        myaxios.post('company/jobtitle/array_save/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDCREATEJOBTITLE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDCREATEJOBTITLE_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}