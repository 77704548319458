import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const NEW_CREATEEMPPASSPORT1_REQUEST = 'NEW_CREATEEMPPASSPORT1_REQUEST';
export const NEW_CREATEEMPPASSPORT1_SUCCESS = 'NEW_CREATEEMPPASSPORT1_SUCCESS';
export const NEW_CREATEEMPPASSPORT1_FAILURE = 'NEW_CREATEEMPPASSPORT1_FAILURE';


export const create_employee_passport1 = (data)=>{
    return dispatch=>{
        dispatch({
            type:'NEW_CREATEEMPPASSPORT1_REQUEST'
        });

        if(data.length !== 0){
            for(let i=0;i<data.length;i++){
                const {passport,expiry_date,issued_date,employee} = data[i];

                myaxios.post('employee/passport/',{
                    employee:employee,
                    passport:passport,
                    expiry_date:expiry_date,
                    issued_date:issued_date
                })
                .then((response)=>{
                    dispatch({
                        type:'NEW_CREATEEMPPASSPORT1_SUCCESS',
                        payload:response
                    });
                    
                    Toast.fire({
                        type: 'success',
                        title: IMLocalized('create_success')
                    });
                })
                .catch((error)=>{
                    dispatch({
                        type:'NEW_CREATEEMPPASSPORT1_FAILURE',
                        payload:error
                    });

                    ErrToast.fire({
                        type:'error',
                        title:IMLocalized('create_failure')
                    });
                })
            }
        }
    }
}