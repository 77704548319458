import * as deleteemppassport from '../../action/employee/delete_employee_passport';


const initialState={
    isLoading:false,
    data:null,
    errors:[]
}

const delete_employee_passport = (state=initialState,action)=>{
    switch(action.type){
        case deleteemppassport.NEW_DELEMPPASSPORT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
            }
        case deleteemppassport.NEW_DELEMPPASSPORT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case deleteemppassport.NEW_DELEMPPASSPORT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case deleteemppassport.NEW_DELEMPPASSPORT_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default delete_employee_passport;