import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalViewPermit from '../hr_employee/modal/ModalViewPermit';

const mapStateToProps=(state)=>({
    data:state.user_self.data,
    permitlist:state.dropdown_list.work_permit,
    isLoading:state.user_self.isLoading,
})


function UserEmployeePermit(props){

    const [list,setList] = useState([]);
    const [toggle,setToggle] = useState(false);
    const [view_detail,setViewDetail]  = useState(null); 

    useEffect(()=>{
        if(props.data.length !== 0){
            const {employeeworkpermit} = props.data;
            setList(employeeworkpermit);
        }
    },[props.data])

    return(
        <>
        <div className='d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title'>
            <div className='d-flex align-items-center'>
                <i className="far fa-id-card text-primary"></i>
                <h4 className="text-primary pl-2 m-0">{IMLocalized('employee_permit')}</h4>
            </div>
        </div> 
        {props.isLoading && <div className='p-2'><h4>{IMLocalized('loading')}..</h4></div>}
        {!props.isLoading && list.length === 0 && <div className='p-2'><h4>{IMLocalized('no_data')}</h4></div>}
        {!props.isLoading && list.length !== 0 &&
        <Table size="sm" className='align-items-center table-bordered mt-2' responsive>
            <thead className='thead-light text-center'>
                <tr>
                    <th>{IMLocalized('permit_type')}</th>
                    <th>{IMLocalized('permit_number')}</th>
                    <th>{IMLocalized('view')}</th>
                </tr>
            </thead>
            <tbody className='text-center'>
                {list.map((item,index)=>{
                    const detail = props.permitlist.find(el=>el.value === item.permit_type);
                    const permit_name = detail?.display ?? '-';
                    return(
                        <tr key={index}>
                            <td>{permit_name}</td>
                            <td>{item.permit}</td>
                            <td align="center" valign="center">
                                <div className="primary_btn d-flex align-items-center justify-content-center">
                                    <i className="far fa-eye text-primary cursor-pointer" 
                                    onClick={()=>{
                                        const data = list[index];
                                        setViewDetail(data);
                                        setToggle(true);
                                    }} />
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>}
        <ModalViewPermit detail={view_detail} toggle={toggle} closeModal={()=>{setViewDetail(null); setToggle(false)}} />
        </>
    )
}
export default connect(mapStateToProps,null)(UserEmployeePermit);