import * as logo from '../action/update_general_logo';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const update_general_logo = (state=initialState,action)=>{
    switch(action.type){
        case logo.UPDATEGENLOGO_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case logo.UPDATEGENLOGO_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case logo.UPDATEGENLOGO_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case logo.UPDATEGENLOGO_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default update_general_logo;