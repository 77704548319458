import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col ,DropdownMenu,DropdownToggle, Input,  DropdownItem, Dropdown } from 'reactstrap';
import {get_payroll_instance} from '../../action/payroll/get_payroll_instance';
import {payroll_detail_get_aggridheader} from '../../action/payroll/payroll_detail_get_aggridheader';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; 
import {regenerate_payroll} from '../../action/payroll/regenerate_payroll';
import '../../css/aggrid.css';
import {get_payroll_history_list} from '../../action/history/get_payroll_history_list';
import Swal from 'sweetalert2';
import {get_payslip_employee_contract} from '../../action/payroll/get_payslip_employee_contract';
import {payroll_basic_payout} from '../../action/payroll/payroll_basic_payout';
import {IMLocalized} from '../../language/IMLocalized';
import '../../css/loading1.css';
import OverlayLoading from '../loading_component/overlay_loading';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import ModalUpdateRates from './modal/ModalUpdateRates';
import DownloadPayslipConsolidatedButton from './button/DownloadPayslipConsolidatedButton';
import DownloadPayslipButton from './button/DownloadPayslipButton';
import ModalViewPayout from './modal/ModalViewPayout';
import ModalPayoutDetail from './modal/ModalPayoutDetail';
import { usePrevious } from '../../hook/usePrevious';
import {popError} from '../../func/popError';
import ModalPayoutError from './modal/ModalPayoutError';
import ModalUploadBonus from './modal/ModalUploadBonus';
import ModalUploadAbsent from './modal/ModalUploadAbsent';
import ModalUploadBackPay from './modal/ModalUploadBackPay';
import PayrollDetailAggrid from './PayrollDetailAggrid';
import PayrollDetailHistory from './PayrollDetailHistory';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

const mapStateToProps=(state)=>({
    letterhead:state.get_general_letter.data,
    list:state.get_payroll_instance.data,
    payrolllist:state.get_payroll_list.data,
    basic_success:state.payroll_basic_payout.data,
    basic_errors:state.payroll_basic_payout.errors,
    regenerate_success:state.regenerate_payroll.data,

    isLoading:state.get_payroll_instance.isLoading,
    isLoading1:state.payroll_basic_payout.isLoading,
    isLoading2:state.regenerate_payroll.isLoading,
    isLoading3:state.get_payslip_employee_contract.isLoading,
    isLoading4:state.get_general_letter.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    get_payslip_employee_contract:(id)=>{
        dispatch(get_payslip_employee_contract(id))
    },
    payroll_detail_get_aggridheader:(id)=>{
        dispatch(payroll_detail_get_aggridheader(id))
    },
    get_payroll_instance:(id)=>{
        dispatch(get_payroll_instance(id))
    },
    payroll_basic_payout:(id,first)=>{
        dispatch(payroll_basic_payout(id,first))
    },
    get_payroll_history_list:()=>{
        dispatch(get_payroll_history_list())
    },
    regenerate_payroll:(id)=>{
        dispatch(regenerate_payroll(id))
    },
})

const initialState={
    is_edit:false,
    status_display:'',
    payroll_date:'',
    select_payroll:'',
    additional_toggle:false,
    rates:null,
    rates_toggle:false,
    view_payout_toggle:false,
    payout_detail_toggle:false,
    payout_detail_id:null,
    payout_errors_msg:[],
    payout_errors_toggle:false,
    bonus_toggle:false,
    absent_toggle:false,
    backpay_toggle:false,
}


function PayrollDetail(props){
    const {get_payroll_instance,regenerate_success,basic_errors,basic_success,handlePayroll,payroll_detail_get_aggridheader,get_payroll_history_list} = props;
    const [state,setState] = useState(initialState);
    const [monthNames] = useState([IMLocalized("january"), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")]);
    const { addItem } = useContext(NewMainContext);
    const prevBasicError = usePrevious(basic_errors);
    const prevBasicSuccess = usePrevious(basic_success);
    const prevRegenerate = usePrevious(regenerate_success);


    useEffect(()=>{ 
        if(props.list.length !== 0){
            const item = props.list[0];
            const {status,id,payroll_date,rates} = item;
            const month1 = new Date(payroll_date).getMonth();
            const month = monthNames[month1];
            const year = new Date(payroll_date).getFullYear();
            const date1 = `${month} ${year}`;

            if(status === 'PAID'){
                handlePayroll({step:'2',id});
            }
            setState(prevState=>({...prevState,status_display:status,payroll_date:date1,rates:rates}));
        }
    },[props.list,handlePayroll,monthNames])

    useEffect(()=>{
        if(prevBasicError !== undefined && prevBasicError !== basic_errors && basic_errors != null){
            if(basic_errors.hasOwnProperty('non_field_errors')){
                popError(basic_errors['non_field_errors'].toString());
            }
            else if(basic_errors.hasOwnProperty('negative')){
                const word = basic_errors['negative'];
                setState(prevState=>({...prevState,payout_errors_msg:word,payout_errors_toggle:true}));
            }
            else if(basic_errors.hasOwnProperty('bank')){
                const word = basic_errors['bank'];
                setState(prevState=>({...prevState,payout_errors_msg:word,payout_errors_toggle:true}));
            }
        }
    },[prevBasicError,basic_errors])

    useEffect(()=>{
        if(prevBasicSuccess !== undefined && prevBasicSuccess !== basic_success && basic_success != null){
            get_payroll_instance(props.id);
            payroll_detail_get_aggridheader(props.id);
            get_payroll_history_list();
        }
    },[props.id,prevBasicSuccess,basic_success,get_payroll_instance,payroll_detail_get_aggridheader,get_payroll_history_list])

    useEffect(()=>{
        if(prevRegenerate !== undefined && prevRegenerate !== regenerate_success && regenerate_success != null){
            get_payroll_instance(props.id);
            payroll_detail_get_aggridheader(props.id);
            get_payroll_history_list();
        }
    },[prevRegenerate,regenerate_success,props.id,get_payroll_instance,payroll_detail_get_aggridheader,get_payroll_history_list])

    const toPayrollList=()=>{
        if(state.is_edit){
            Swal.fire({
                title:IMLocalized('changes_may_not_be_saved'),
                type:'warning',
                showCancelButton:true,
                cancelButtonColor:'#d33',
                confirmButtonColor:'#3085d6',
                confirmButtonText:IMLocalized('yes!'),
                cancelButtonText:IMLocalized('no!'),
            })
            .then((result)=>{
                if(result.value){
                    setState(prevState=>({...prevState,is_edit:false}))
                    props.handlePayroll({step:'0'});
                }
            })
        }
        else{
            props.handlePayroll({step:'0'});
        }
    }

    const submitPayout=(boolean)=>{
        Swal.fire({
            title:boolean?IMLocalized('confirm_basic_payout'): IMLocalized('complete_payout'),
            text: !boolean ? IMLocalized('no_more_changes_can_be_made') :'',
            type:'warning',
            showCancelButton:true,
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
        })
        .then((result)=>{
            if(result.value){
                props.payroll_basic_payout(props.id,boolean);
            }
        })
    }

    let prev_btn = '';
    if(props.prev_item.payroll !== '' && props.prev_item.payroll_name !== ''){
        const month1 = new Date(props.prev_item.payroll_name).getMonth();
        const month = monthNames[month1];
        const year = new Date(props.prev_item.payroll_name).getFullYear();
        prev_btn = <button className="btn btn-primary btn-sm" onClick={()=>props.payrollInfo(props.prev_item.payroll)} >{month} {year}</button>;
    }

    let next_btn = '';
    if(props.next_item.payroll !== '' && props.next_item.payroll_name !== ''){
        const month1 = new Date(props.next_item.payroll_name).getMonth();
        const month = monthNames[month1];
        const year = new Date(props.next_item.payroll_name).getFullYear();
        next_btn = <button className="btn btn-primary btn-sm" onClick={()=>props.payrollInfo(props.next_item.payroll)}>{month} {year}</button>;
    }

    return(
        <>
        {props.isLoading || props.isLoading1 || props.isLoading2 || props.isLoading3 || props.isLoading4 ? <OverlayLoading/> :
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('payroll'), onClick:()=>handlePayroll({step:0})},
                        { active: true, title:state.payroll_date}
                    ]}/>
                    <div className='d-flex justify-content-between'>
                        <Input className='ml-2' size="sm" style={{width:'150px'}} type="select" value={state.select_payroll} onChange={(e)=>{
                            const value = e.target.value;
                            props.payrollInfo(value);
                        }} >
                            <option>{IMLocalized('select_month')}</option>
                            {props.payrolllist.map((item,index)=>{
                                const date = item.payroll_date;
                                const month1 = new Date(date).getMonth();
                                const month = monthNames[month1];
                                const year = new Date(date).getFullYear();
                                return <option value={item.id} key={index}>{month} {year}</option>
                            })}
                        </Input>
                        {state.status_display !== '' && 
                        <small className='mr-2'>{IMLocalized('status')} : {IMLocalized(state.status_display)}</small>}
                    </div>
                    <div className='mt-1 d-flex justify-content-between'>
                        <div className='ml-2'>
                            <button className="btn btn-primary btn-sm" onClick={()=>toPayrollList()}> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                            {props.prev_item.payroll_name !== '' && props.prev_item.payroll !== '' && prev_btn }
                            {props.next_item.payroll_name !== '' && props.next_item.payroll !== '' && next_btn }
                        </div>
                        <Dropdown size="sm" direction='left' toggle={()=>setState(prevState=>({...prevState,additional_toggle:!state.additional_toggle}))} isOpen={state.additional_toggle}>
                            <DropdownToggle>
                                {IMLocalized('more_action')} <i className="fas fa-ellipsis-v"/>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={()=>submitPayout(true)}>{IMLocalized('basic_payout')}</DropdownItem>
                                <DropdownItem onClick={()=>submitPayout(false)}>{IMLocalized('final_payout')}</DropdownItem>
                                <DropdownItem onClick={()=>setState(prevState=>({...prevState,view_payout_toggle:true}))}>{IMLocalized('view_payout')}</DropdownItem>
                                <DownloadPayslipButton/>
                                <DownloadPayslipConsolidatedButton />
                                <DropdownItem 
                                onClick={()=>{
                                    Swal.fire({
                                        title:IMLocalized('confirm_regenerate_payroll'),
                                        text:IMLocalized('regenerate_data_gone'),
                                        type:'warning',
                                        confirmButtonColor:'#d33',
                                        confirmButtonText:IMLocalized('yes!'),
                                        showCancelButton:true,
                                        cancelButtonText:IMLocalized('no!')
                                    })
                                    .then((result)=>{
                                        if(result.value){
                                            props.regenerate_payroll(props.id);
                                        }
                                    })
                                }}
                                >{IMLocalized('regenerate_payroll')}</DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem 
                                onClick={()=>{
                                    if(state.is_edit === true){
                                        Swal.fire({
                                            title:IMLocalized('changes_may_not_be_saved'),
                                            type:'warning',
                                            showCancelButton:true,
                                            cancelButtonColor:'#d33',
                                            confirmButtonColor:'#3085d6',
                                            confirmButtonText:IMLocalized('yes!'),
                                            cancelButtonText:IMLocalized('no!'),
                                        })
                                        .then((result)=>{
                                            if('value' in result){
                                                setState(prevState=>({...prevState,is_edit:false}));
                                                props.handlePayroll({step:'3',id:props.id});
                                                localStorage.setItem('payroll_report_id',props.id);
                                            }
                                        })
                                    }
                                    else{
                                        props.handlePayroll({step:'3',id:props.id});
                                        localStorage.setItem('payroll_report_id',props.id);
                                    }
                                }}>{IMLocalized('report')}</DropdownItem>
                                <DropdownItem 
                                onClick={()=>{
                                    if(state.is_edit === true){
                                        Swal.fire({
                                            title:IMLocalized('changes_may_not_be_saved'),
                                            type:'warning',
                                            showCancelButton:true,
                                            cancelButtonColor:'#d33',
                                            confirmButtonColor:'#3085d6',
                                            confirmButtonText:IMLocalized('yes!'),
                                            cancelButtonText:IMLocalized('no!'),
                                        })
                                        .then((result)=>{
                                            if('value' in result){
                                                setState(prevState=>({...prevState,is_edit:false}));
                                                props.handlePayroll({step:'2',id:props.id});
                                            }
                                        })
                                    }
                                    else{
                                        props.handlePayroll({step:'2',id:props.id});
                                    }
                                }}>{IMLocalized('summary')}</DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem onClick={()=>setState(prevState=>({...prevState,rates_toggle:true}))}>{IMLocalized('update_rates')}</DropdownItem>
                                <DropdownItem onClick={()=>setState(prevState=>({...prevState,bonus_toggle:true}))}>{IMLocalized('upload_bonus')}</DropdownItem>                                        
                                <DropdownItem onClick={()=>setState(prevState=>({...prevState,absent_toggle:true}))}>{IMLocalized('upload_absent')}</DropdownItem>
                                <DropdownItem onClick={()=>setState(prevState=>({...prevState,backpay_toggle:true}))}>{IMLocalized('upload_other_pay')}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
            <PayrollDetailAggrid is_edit={state.is_edit} id={props.id} setEdit={(value)=>setState(prevState=>({...prevState,is_edit:value}))} toPayrollPayslip={props.toPayrollPayslip} />
            <PayrollDetailHistory id={props.id} />
        </Container>
        <ModalUpdateRates toggle={state.rates_toggle} closeModal={()=>setState(prevState=>({...prevState,rates_toggle:false}))} rates={state.rates} id={props.id}  />
        <ModalViewPayout toggle={state.view_payout_toggle} closeModal={()=>setState(prevState=>({...prevState,view_payout_toggle:false}))} 
        toViewDetail={(id)=>{setState(prevState=>({...prevState,payout_detail_toggle:true,payout_detail_id:id})); }} />
        <ModalPayoutDetail toggle={state.payout_detail_toggle} id={state.payout_detail_id} closeModal={()=>setState(prevState=>({...prevState,payout_detail_id:null,payout_detail_toggle:false}))} />
        <ModalPayoutError toggle={state.payout_errors_toggle} detail={state.payout_errors_msg} closeModal={()=>setState(prevState=>({...prevState,payout_errors_msg:[],payout_errors_toggle:false}))} />
        <ModalUploadBonus toggle={state.bonus_toggle} closeModal={()=>setState(prevState=>({...prevState,bonus_toggle:false}))} id={props.id} />
        <ModalUploadAbsent toggle={state.absent_toggle} closeModal={()=>{setState(prevState=>({...prevState,absent_toggle:false}))}} id={props.id} />
        <ModalUploadBackPay toggle={state.backpay_toggle} closeModal={()=>{setState(prevState=>({...prevState,backpay_toggle:false}))}} id={props.id} />
        </>}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(PayrollDetail);