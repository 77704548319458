import * as updateselfhelp from '../../action/employee/update_employee_self_help';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const update_employee_self_help = (state=initialState,action)=>{
    switch(action.type){
        case updateselfhelp.UPDATEEMPSELFHELP_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case updateselfhelp.UPDATEEMPSELFHELP_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updateselfhelp.UPDATEEMPSELFHELP_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case updateselfhelp.UPDATEEMPSELFHELP_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state
    }
}
export default update_employee_self_help;