import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';


const mapStateToProps=(state)=>({
    nationalitylist:state.dropdown_list.countries,
    data:state.user_self.data,
})

function UserEmployeeAddress(props){

    const [address,setAddress]  = useState(null);

    useEffect(()=>{
        if(props.data.length !== 0 && props.nationalitylist.length !== 0){
            const {address} = props.data;
            setAddress(address);
        }
    },[props.data,props.nationalitylist])


    let country_name = '';
    if(address != null){
        const detail = props.nationalitylist.find(item=>item.value === address.country_of_residence);
        country_name = detail?.display_name ?? '-';
    }

    return(
        <>
        <div className="d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title">
            <div className="d-flex align-items-center">
                <i className="far fa-address-card text-primary"/>
                <h4 className="text-primary m-0 pl-2">{IMLocalized('employee_address')}</h4>
            </div>
        </div>
        {address != null && address.country_of_residence === 'SG' &&
        <Row className='mt-2'>
            <Col lg="6">
                <Row className='pl-2'>
                    <Col xs="5">
                        <h5>{IMLocalized('country_of_residence')}</h5>
                    </Col>
                    <Col xs="7">
                        {country_name}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className='pl-2'>
                    <Col xs="5">
                        <h5>{IMLocalized('sg_postal_code')}</h5>
                    </Col>
                    <Col xs="7">
                        {address.sg_postal_code}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('block_number')}</h5>
                    </Col>
                    <Col xs="7">
                        {address.block_number}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('street_name')}</h5>
                    </Col>
                    <Col xs="7">
                        {address.street_name}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('level_number')}</h5>
                    </Col>
                    <Col xs="7">
                        {address.level_number}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('unit_number')}</h5>
                    </Col>
                    <Col xs="7">
                        {address.unit_number}
                    </Col>
                </Row>
            </Col>
        </Row>}
        {address != null && address.country_of_residence !== 'SG' &&
        <Row className="pt-2">
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('country_of_residence')}</h5>
                    </Col>
                    <Col xs="7">
                        {country_name}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('postal_code')}</h5>
                    </Col>
                    <Col xs="7">
                        {address.postal_code}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('address')}</h5>
                    </Col>
                    <Col xs="7">
                        {address.address}
                    </Col>
                </Row>
            </Col>
        </Row>
        }
        </>
    )
}
export default connect(mapStateToProps,null)(UserEmployeeAddress);