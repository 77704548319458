import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';


export const WIZARDCREATEALLOWANCE_REQUEST = 'WIZARDCREATEALLOWANCE_REQUEST';
export const WIZARDCREATEALLOWANCE_SUCCESS = 'WIZARDCREATEALLOWANCE_SUCCESS';
export const WIZARDCREATEALLOWANCE_FAILURE = 'WIZARDCREATEALLOWANCE_FAILURE';

export const wizard_create_allowance = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDCREATEALLOWANCE_REQUEST'
        });

        myaxios.post('company/allowance/array_save/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDCREATEALLOWANCE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDCREATEALLOWANCE_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}