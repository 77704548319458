import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const NEW_CREATEEMPRELATION_REQUEST = 'NEW_CREATEEMPRELATION_REQUEST';
export const NEW_CREATEEMPRELATION_SUCCESS = 'NEW_CREATEEMPRELATION_SUCCESS';
export const NEW_CREATEEMPRELATION_FAILURE = 'NEW_CREATEEMPRELATION_FAILURE';
export const NEW_CREATEEMPRELATION_RESET = 'NEW_CREATEEMPRELATION_RESET';

export const create_employee_relationship = (data)=>{
    return dispatch=>{
        dispatch({
            type:'NEW_CREATEEMPRELATION_REQUEST'
        });

        if(data.length !==0){
            for(let i =0;i<data.length;i++){
                const { name, relation, contact_detail, employee} = data[i];

                myaxios.post('employee/relationship/',{
                    name:name,
                    relation:relation,
                    contact_detail:contact_detail,
                    employee:employee
                })
                .then((response)=>{
                    dispatch({
                        type:'NEW_CREATEEMPRELATION_SUCCESS',
                        payload:response
                    });

                    Toast.fire({
                        type: 'success',
                        title: IMLocalized('create_success')
                    });
                })
                .catch((error)=>{
                    dispatch({
                        type:'NEW_CREATEEMPRELATION_FAILURE',
                        payload:error
                    });

                    ErrToast.fire({
                        type:'error',
                        title:IMLocalized('create_failure')
                    });
                })
            }
        }
    }
}

export const reset_create_employee_relationship = () => dispatch =>{
    dispatch({
        type:'NEW_CREATEEMPRELATION_RESET'
    });
}
