import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';


export const ARRAYAPPROVECLAIM_REQUEST = 'ARRAYAPPROVECLAIM_REQUEST';
export const ARRAYAPPROVECLAIM_SUCCESS = 'ARRAYAPPROVECLAIM_SUCCESS';
export const ARRAYAPPROVECLAIM_FAILURE = 'ARRAYAPPROVECLAIM_FAILURE';
export const ARRAYAPPROVECLAIM_RESET = 'ARRAYAPPROVECLAIM_RESET';

export const array_approve_apply_claim = (data)=>{
    return dispatch=>{
        dispatch({
            type:'ARRAYAPPROVECLAIM_REQUEST'
        });

        myaxios.post(`claim/apply/array_approve/`,{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'ARRAYAPPROVECLAIM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('approve_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'ARRAYAPPROVECLAIM_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('approve_failure')
            });
        })
    }
}

export const reset_array_approve_apply_claim = () => dispatch =>{
    dispatch({
        type:'ARRAYAPPROVECLAIM_RESET'
    });
}
