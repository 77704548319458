import * as approvalsleave from '../../action/approvals/get_approvals_leave_list';

const initialState={
    data:[],
    errors:[],
    isLoading:false
}

const get_approvals_leave_list = (state=initialState,action)=>{
    switch(action.type){
        case approvalsleave.GETAPPROVALSLEAVELIST_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case approvalsleave.GETAPPROVALSLEAVELIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case approvalsleave.GETAPPROVALSLEAVELIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                error:action.payload
            }
        default:
            return state;
    }
}
export default get_approvals_leave_list;