import React,{useContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Row,Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Container} from 'reactstrap';
import {get_general_dashboard} from '../../action/dashboard/get_general_dashboard';
import moment from 'moment';
import '../../css/dashboard.css';
import {update_dashboard_widget} from '../../action/dashboard/update_dashboard_widget';
import {Responsive,WidthProvider} from 'react-grid-layout';
import {IMLocalized} from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';
import ClaimTable from './dashboard_component/ClaimTable';
import LeaveTable from './dashboard_component/LeaveTable';
import PayslipTable from './dashboard_component/PayslipTable';
import ApprovalTable from './dashboard_component/ApprovalTable';
import { usePrevious } from '../../hook/usePrevious';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

const ResponsiveGridLayout = WidthProvider(Responsive);

function mapStateToProps(state,ownProps){
    return{
       
        isLoading:state.get_general_dashboard.isLoading,
        emplist:state.get_employee_list.data,
        dashboardlist:state.get_general_dashboard.data,
        update_success:state.update_dashboard_widget.data,
        availablelist:state.user_get_available_leave.data,
    }
}

const mapDispatchToProps =(dispatch)=>{
    return{
        get_general_dashboard:()=>{
            dispatch(get_general_dashboard())
        },
        update_dashboard_widget:(layout)=>{
            dispatch(update_dashboard_widget(layout))
        },
    }
}

const initialState={
    toggle:false,
    drag:false,
    currentBreakpoint:'lg',
    series:[],
    absentlist:[],
    userclaimlist:[],
    userleavelist:[],
    leave_availablelist:[],
    userpayslip:[],
    count:0,
    approvals:[],
    claimapprovals:[],
    probationlist:[],
    resignlist:[],

}

function LeaveOfficerDashboard(props){

    const { addItem, setPayrollID, setPayrollStep, setPayslipEmpID  } = useContext(NewMainContext);
    const {update_success,get_general_dashboard} = props;
    const [state,setState] = useState(initialState);
    const [layoutlist,setLayout] = useState({
        lg:[
            {i: '1', x: 0,  y: 4, w: 4, h: 7},
            {i: '2', x: 4,  y: 4, w: 4, h: 7},
            {i: '3', x: 0 , y: 12, w: 4, h: 7},
            {i: '4', x: 8 , y: 4, w: 4, h: 7},
            {i: '5', x: 0 , y: 0, w: 4, h: 3},
            {i: '6', x: 4 , y: 0, w: 4, h: 3},
            {i: '7', x: 8 , y: 0, w: 4, h: 3},
            {i: '8', x: 4 , y: 12 , w:4 , h:7},
            {i: '9', x: 8 , y: 12 , w:4 , h:7},
            {i: '10',x: 0 , y: 20 , w:4 , h:7},
            {i: '11',x: 4 , y: 20 , w:4 , h:7},
            {i: '12',x: 8 , y: 20 , w:4 , h:7},
            {i: '13',x: 0 , y: 28 , w:4 , h:7},
            {i: '14',x: 4 , y: 28 , w:4 , h:7},
            {i: '15',x: 8 , y: 28 , w:4 , h:7}
        ],
        md:[
            {i: '1', x: 5, y: 4, w: 5, h: 7},
            {i: '2', x: 0, y: 8, w: 5, h: 7},
            {i: '3', x: 0, y: 16, w:5, h: 7},
            {i: '4', x: 5, y: 12, w:5, h: 7},
            {i: '5', x: 0, y: 0, w: 5, h: 3},
            {i: '6', x: 5, y: 0, w: 5, h: 3},
            {i: '7', x: 0, y: 4, w: 5, h: 3},
            {i: '8', x: 5, y: 20, w:5, h: 7},
            {i: '9', x: 0, y: 23,w: 5, h: 7},
            {i: '10',x: 5 , y: 28 , w:5 , h:7},
            {i: '11',x: 0 , y: 31 , w:5 , h:7},
            {i: '12',x: 5 , y: 36 , w:5 , h:7},
            {i: '13',x: 0 , y: 39 , w:5 , h:7},
            {i: '14',x: 5 , y: 44 , w:5 , h:7},
            {i: '15',x: 0 , y: 47 , w:5 , h:7}
        ],
        sm:[
            {i: '1', x: 0, y: 12, w: 6, h: 7},
            {i: '2', x: 0, y: 20, w: 6, h: 7},
            {i: '3', x: 0, y: 36, w:6, h: 7},
            {i: '4', x: 0, y: 28, w:6, h: 7},
            {i: '5', x: 0, y: 0, w: 6, h: 3},
            {i: '6', x: 0, y: 4, w: 6, h: 3},
            {i: '7', x: 0, y: 8, w: 6, h: 3},
            {i: '8', x: 0, y: 44, w: 6, h: 7},
            {i: '9', x: 0, y: 52,w: 6, h: 7},
            {i: '10',x: 0 , y: 60 , w:6 , h:7},
            {i: '11',x: 0 , y: 68 , w:6 , h:7},
            {i: '12',x: 0 , y: 76 , w:6 , h:7},
            {i: '13',x: 0 , y: 84 , w:6 , h:7},
            {i: '14',x: 0 , y: 92 , w:6 , h:7},
            {i: '15',x: 0 , y:100 , w:6, h:7}
        ],
        xs:[
            {i: '1', x: 0, y: 12, w: 4, h: 7},
            {i: '2', x: 0, y: 20, w: 4, h: 7},
            {i: '3', x: 0, y: 36, w: 4, h: 7},
            {i: '4', x: 0, y: 28, w: 4, h: 7},
            {i: '5', x: 0, y: 0, w: 4, h: 3},
            {i: '6', x: 0, y: 4, w: 4, h: 3},
            {i: '7', x: 0, y: 8, w: 4, h: 3},
            {i: '8', x: 0, y: 44, w: 4, h: 7},
            {i: '9', x: 0, y: 52,w: 4, h: 7},
            {i: '10',x: 0 , y: 60 , w:4 , h:7},
            {i: '11',x: 0 , y: 68 , w:4 , h:7},
            {i: '12',x: 0 , y: 76 , w:4 , h:7},
            {i: '13',x: 0 , y: 84 , w:4 , h:7},
            {i: '14',x: 0 , y: 92 , w:4 , h:7},
            {i: '15',x: 0 , y:100 , w:4, h:7}
        ],
        xxs:[
            {i: '1', x: 0, y: 12, w: 2, h: 7},
            {i: '2', x: 0, y: 20, w: 2, h: 7},
            {i: '3', x: 0, y: 36, w: 2, h: 7},
            {i: '4', x: 0, y: 28, w: 2, h: 7},
            {i: '5', x: 0, y: 0, w: 2, h: 3},
            {i: '6', x: 0, y: 4, w: 2, h: 3},
            {i: '7', x: 0, y: 8, w: 2, h: 3},
            {i: '8', x: 0, y: 44, w: 2, h: 7},
            {i: '9', x: 0, y: 52,w: 2, h: 7},
            {i: '10',x: 0 , y: 60 , w:2 , h:7},
            {i: '11',x: 0 , y: 68 , w:2 , h:7},
            {i: '12',x: 0 , y: 76 , w:2 , h:7},
            {i: '13',x: 0 , y: 84 , w:2 , h:7},
            {i: '14',x: 0 , y: 92 , w:2 , h:7},
            {i: '15',x: 0 , y:100 , w:2, h:7}
        ]
    });
    const [defaultlist] = useState({
        lg:[
            {i: '1', x: 0,  y: 4, w: 4, h: 7},
            {i: '2', x: 4,  y: 4, w: 4, h: 7},
            {i: '3', x: 0 , y: 12, w: 4, h: 7},
            {i: '4', x: 8 , y: 4, w: 4, h: 7},
            {i: '5', x: 0 , y: 0, w: 4, h: 3},
            {i: '6', x: 4 , y: 0, w: 4, h: 3},
            {i: '7', x: 8 , y: 0, w: 4, h: 3},
            {i: '8', x: 4 , y: 12 , w:4 , h:7},
            {i: '9', x: 8 , y: 12 , w:4 , h:7},
            {i: '10',x: 0 , y: 20 , w:4 , h:7},
            {i: '11',x: 4 , y: 20 , w:4 , h:7},
            {i: '12',x: 8 , y: 20 , w:4 , h:7},
            {i: '13',x: 0 , y: 28 , w:4 , h:7},
            {i: '14',x: 4 , y: 28 , w:4 , h:7},
            {i: '15',x: 8 , y: 28 , w:4 , h:7}
        ],
        md:[
            {i: '1', x: 5, y: 4, w: 5, h: 7},
            {i: '2', x: 0, y: 8, w: 5, h: 7},
            {i: '3', x: 0, y: 16, w:5, h: 7},
            {i: '4', x: 5, y: 12, w:5, h: 7},
            {i: '5', x: 0, y: 0, w: 5, h: 3},
            {i: '6', x: 5, y: 0, w: 5, h: 3},
            {i: '7', x: 0, y: 4, w: 5, h: 3},
            {i: '8', x: 5, y: 20, w:5, h: 7},
            {i: '9', x: 0, y: 23,w: 5, h: 7},
            {i: '10',x: 5 , y: 28 , w:5 , h:7},
            {i: '11',x: 0 , y: 31 , w:5 , h:7},
            {i: '12',x: 5 , y: 36 , w:5 , h:7},
            {i: '13',x: 0 , y: 39 , w:5 , h:7},
            {i: '14',x: 5 , y: 44 , w:5 , h:7},
            {i: '15',x: 0 , y: 47 , w:5 , h:7}
        ],
        sm:[
            {i: '1', x: 0, y: 12, w: 6, h: 7},
            {i: '2', x: 0, y: 20, w: 6, h: 7},
            {i: '3', x: 0, y: 36, w:6, h: 7},
            {i: '4', x: 0, y: 28, w:6, h: 7},
            {i: '5', x: 0, y: 0, w: 6, h: 3},
            {i: '6', x: 0, y: 4, w: 6, h: 3},
            {i: '7', x: 0, y: 8, w: 6, h: 3},
            {i: '8', x: 0, y: 44, w: 6, h: 7},
            {i: '9', x: 0, y: 52,w: 6, h: 7},
            {i: '10',x: 0 , y: 60 , w:6 , h:7},
            {i: '11',x: 0 , y: 68 , w:6 , h:7},
            {i: '12',x: 0 , y: 76 , w:6 , h:7},
            {i: '13',x: 0 , y: 84 , w:6 , h:7},
            {i: '14',x: 0 , y: 92 , w:6 , h:7},
            {i: '15',x: 0 , y:100 , w:6, h:7}
        ],
        xs:[
            {i: '1', x: 0, y: 12, w: 4, h: 7},
            {i: '2', x: 0, y: 20, w: 4, h: 7},
            {i: '3', x: 0, y: 36, w: 4, h: 7},
            {i: '4', x: 0, y: 28, w: 4, h: 7},
            {i: '5', x: 0, y: 0, w: 4, h: 3},
            {i: '6', x: 0, y: 4, w: 4, h: 3},
            {i: '7', x: 0, y: 8, w: 4, h: 3},
            {i: '8', x: 0, y: 44, w: 4, h: 7},
            {i: '9', x: 0, y: 52,w: 4, h: 7},
            {i: '10',x: 0 , y: 60 , w:4 , h:7},
            {i: '11',x: 0 , y: 68 , w:4 , h:7},
            {i: '12',x: 0 , y: 76 , w:4 , h:7},
            {i: '13',x: 0 , y: 84 , w:4 , h:7},
            {i: '14',x: 0 , y: 92 , w:4 , h:7},
            {i: '15',x: 0 , y:100 , w:4, h:7}
        ],
        xxs:[
            {i: '1', x: 0, y: 12, w: 2, h: 7},
            {i: '2', x: 0, y: 20, w: 2, h: 7},
            {i: '3', x: 0, y: 36, w: 2, h: 7},
            {i: '4', x: 0, y: 28, w: 2, h: 7},
            {i: '5', x: 0, y: 0, w: 2, h: 3},
            {i: '6', x: 0, y: 4, w: 2, h: 3},
            {i: '7', x: 0, y: 8, w: 2, h: 3},
            {i: '8', x: 0, y: 44, w: 2, h: 7},
            {i: '9', x: 0, y: 52,w: 2, h: 7},
            {i: '10',x: 0 , y: 60 , w:2 , h:7},
            {i: '11',x: 0 , y: 68 , w:2 , h:7},
            {i: '12',x: 0 , y: 76 , w:2 , h:7},
            {i: '13',x: 0 , y: 84 , w:2 , h:7},
            {i: '14',x: 0 , y: 92 , w:2 , h:7},
            {i: '15',x: 0 , y:100 , w:2, h:7}
        ]
    })

    const prevUpdate = usePrevious(update_success);

    useEffect(()=>{
        if(prevUpdate !== undefined && prevUpdate !== update_success && update_success != null){
            get_general_dashboard()
        }
    },[prevUpdate,update_success,get_general_dashboard])

    useEffect(()=>{
        if(props.dashboardlist.length !== 0){
            const {is_admin,user,layout} = props.dashboardlist;
            if(is_admin === false){
                
                setState(prevState=>({
                    ...prevState,
                    userclaimlist:user.claims,
                    userleavelist:user.leave,
                    userpayslip:user.payslip,
                    approvals:user.approvals,
                    claimapprovals:user.claimapprovals,
                }))
                setLayout(layout ?? defaultlist)
               
            }
        }
    },[props.dashboardlist,defaultlist])

    useEffect(()=>{
        setState(prevState=>({...prevState,leave_availablelist:props.availablelist}));
    },[props.availablelist])

    useEffect(()=>{
        let arr = [];
        let arr2 = [];
        const new_date = moment().format('YYYY-MM-DD');
        for(let i=0;i<props.emplist.length;i++){
            const item = props.emplist[i];
            if(item.probation_end_date !== null){
                if(new Date(item.probation_end_date) >= new Date(new_date)){
                    const new_date2 = moment(new_date,'YYYY-MM-DD').add(1,'M').endOf('month');
                    const date2 = new_date2.format('YYYY-MM-DD');
                    if(new Date(item.probation_end_date) <= new Date(date2)){
                        arr.push(item);
                    }
                }
            }

            if(item.resignation_date !== null){
                if(new Date(item.resignation_date) >= new Date(new_date)){
                    const new_date2 = moment(new_date,'YYYY-MM-DD').add(1,'M').endOf('month');
                    const date2 = new_date2.format('YYYY-MM-DD');
                    if(new Date(item.resignation_date) <= new Date(date2)){
                        arr2.push(item);
                    }
                }
            }
        }
        setState(prevState=>({
            ...prevState,
            probationlist:arr,
            resignlist:arr2,
        }))
    },[props.emplist])

    return(
        <>
        {props.isLoading ? <OverlayLoading/> :
        <Container className='scrollbarstyle1' fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active:true, title:IMLocalized('home')}
                    ]} />
                </Col>
                <Col className='col-auto d-flex align-items-center'>
                    <Dropdown size="sm" isOpen={state.toggle} direction="left" toggle={()=>setState(prevState=>({...prevState,toggle:!state.toggle}))} >
                        <DropdownToggle className="d-flex align-items-center justify-content-center setting_btn" aria-expanded={state.toggle}>
                            <i className="ni ni-settings-gear-65"/>
                        </DropdownToggle>
                        <DropdownMenu>
                            <div className="custom-control custom-checkbox ml-2">
                                <input type="checkbox" id="hr_draggable" className="custom-control-input" name="drag" checked={state.drag} onChange={()=>setState(prevState=>({...prevState,drag:!state.drag}))}  />
                                <label className="custom-control-label" htmlFor='hr_draggable'>{IMLocalized('is_draggable')}</label>
                            </div>
                            <DropdownItem divider></DropdownItem>
                            <div className="pl-2">
                                {state.toggle === true && 
                                <button className="btn btn-primary btn-sm"
                                onClick={()=>{
                                    props.update_dashboard_widget(layoutlist);
                                    setState(prevState=>({...prevState,drag:false,toggle:false}))
                                }}>{IMLocalized('save')}</button>}
                                <button className="btn btn-secondary btn-sm" 
                                onClick={()=>{
                                   props.update_dashboard_widget(defaultlist);
                                   setState(prevState=>({...prevState,drag:false,toggle:false}))
                                }}>{IMLocalized('default')}</button>
                            </div>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </Row>
            <ResponsiveGridLayout
            layouts={layoutlist}
            measureBeforeMount={true}
            autoSize={false}
            className="layout"
            rowHeight={30}
            isDraggable={state.drag}
            isResizable={false}
            onDragStop={(index)=>{
                setLayout(prevState=>({
                   ...prevState,
                   [state.currentBreakpoint]:index
                }))
            }}
            onBreakpointChange={(breakpoint)=>{
                setState(prevState=>({...prevState,currentBreakpoint:breakpoint}))
            }}
            margin={[10, 10]}
            >
                <div key="3">
                    <ClaimTable list={state.userclaimlist} />
                </div>
                <div key="2">
                    <LeaveTable list={state.leave_availablelist} />
                </div>
                <div key="5">
                    <PayslipTable list={state.userpayslip} drag={state.drag}
                    viewPayroll={(payroll,id)=>{
                        console.log(payroll,id)
                        addItem('15','payroll_list');
                        setPayrollID(payroll);
                        setPayrollStep('4');
                        setPayslipEmpID(id);
                    }} />
                </div>
                <div key="6">
                    <ApprovalTable list={state.approvals} drag={state.drag} toApprovalLeave={()=>addItem('24','leave_calendar')} />
                </div>

            </ResponsiveGridLayout>
                
        </Container>}
        </>
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(LeaveOfficerDashboard);