import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { get_additional_setting } from '../../../action/additional_setting';
import { create_allowance, reset_create_allowance } from '../../../action/create_allowance';
import { IMLocalized } from '../../../language/IMLocalized';

const initialState={
    code:'',
    err_code:'',
    name:'',
    err_name:'',
    type:'',
    err_type:'',
    amount:'',
    err_amount:'',
    gross:false,
}

function ModalCreateAllowance({toggle,closeModal}){
    const [data,setData] = useState(initialState);
    
    const typelist = useSelector(state=>state.dropdown_list.allowance_type);
    const isLoading = useSelector(state=>state.create_allowance.isLoading);
    const success = useSelector(state=>state.create_allowance.data);
    const errors = useSelector(state=>state.create_allowance.errors);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(toggle){
            setData(initialState);
        }
    },[toggle])

    useEffect(()=>{
        if( toggle && errors !== null ){
            setData(prevState=>({
                ...prevState,
                err_name:errors.hasOwnProperty('name') ? errors['name'].toString(): '',
                err_code:errors.hasOwnProperty('code') ? errors['code'].toString(): '',
            }))
            dispatch(reset_create_allowance());
        }
    },[errors,toggle,dispatch])

    useEffect(()=>{
        if( toggle && success !== null){
            dispatch(get_additional_setting());
            closeModal();
            dispatch(reset_create_allowance());
        }
    },[success,toggle,closeModal,dispatch])


    const createAllowance=()=>{
        if(data.code !== '' && data.name !== '' && data.amount !== '' && data.type !== '' ){
            dispatch(create_allowance(data.code,data.name,data.amount,data.gross,data.type));
        }
        else{
            setData(prevState=>({
                ...prevState,
                err_code:data.code === '' ? IMLocalized('code_is_required') : '',
                err_name:data.name === '' ? IMLocalized('name_is_required') : '',
                err_amount:data.amount === '' ? IMLocalized('amount_is_required') : '',
                err_type:data.type === '' ? IMLocalized('type_is_required') : '',
            }))
        }
    }

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={()=>closeModal()}>{IMLocalized('add_allowance')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='code'>{IMLocalized('code')}</label>
                            <Input type="text" id="code" size="sm" value={data.code} onChange={(e)=>{const value = e.target.value; setData(prevState=>({...prevState,code:value,err_code:''}))}} invalid={data.err_code.length > 0} />
                            <FormFeedback>{data.err_code}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('name')}</label>
                            <Input type="text" id="name" size="sm" value={data.name} onChange={(e)=>{const value = e.target.value; setData(prevState =>({...prevState,name:value,err_name:''}))}} invalid={data.err_name.length > 0} />
                            <FormFeedback>{data.err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='type'>{IMLocalized('type')}</label>
                            <Input size="sm" type="select" id="type" value={data.type} onChange={(e)=>{const value = e.target.value; setData(prevState=>({...prevState,type:value,err_type:''}))}} invalid={data.err_type.length > 0} >
                                <option></option>
                                {typelist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{data.err_type}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='amount'>{IMLocalized('amount')}</label>
                            <Input type="number" step="1" min="0" id="amount" size="sm" value={data.amount} onChange={(e)=>{const value = e.target.value; setData(prevState =>({...prevState,amount:value,err_amount:''}))}} invalid={data.err_amount.length > 0} />
                            <FormFeedback>{data.err_amount}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="12" className="d-flex align-items-center">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="company_allowancegross" name='allowance_gross' className="custom-control-input"  checked={data.gross} onChange={(e)=>{const checked= e.target.checked; setData(prevState=>({...prevState,gross:checked}))}} />
                            <label className="custom-control-label" htmlFor="company_allowancegross">{IMLocalized('is_included_in_gross')}</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={()=>createAllowance()}>{IMLocalized('add')}</button>
                    <button className="btn btn-danger btn-sm" onClick={()=>closeModal()}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default ModalCreateAllowance;