import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { IMLocalized } from "../../language/IMLocalized";
import moment from "moment";
import IncrementDetail from './PayrollIncrementViewDetail';
import OverlayLoading from '../loading_component/overlay_loading';
import { NewMainContext } from "../../context/NewMainContext";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";


const mapStateToProps=(state)=>({
    isLoading:state.get_payroll_increment_list.isLoading,
    list:state.get_payroll_increment_list.data,
})

function PayrollIncrementView(props){

    const [id,setId] = useState(null);
    const {addItem, payroll_increment_step, setPayrollIncrementStep} = useContext(NewMainContext);
    

    const toHome=()=>{
        addItem('1','dashboard');
    }

    const getDateFormat=(date)=>{
        const date1 = moment(date,'YYYY-MM-DD');
        const new_date = date1.format('DD/MM/YYYY');
        return new_date;
    }

    const viewDetail =(id)=>{
        setId(id);
        setPayrollIncrementStep(1);
    }

    const displayView=()=>{

        switch(payroll_increment_step){
            case 0:
                return viewList();
            case 1:
                return <IncrementDetail toHome={toHome} id={id} goBack={()=>{setPayrollIncrementStep(0);setId(null)}}/>;
            default:
                return viewList();
        }
    }

    const viewList=()=>{
        return <div>
                <Container fluid>
                    <Row className="border-bottom bg-white" >
                        <Col className="p-2">
                            <BreadCrumbList list={[
                                { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                                { active: true, title:IMLocalized('payroll_increment_list')}
                            ]}/>
                        </Col>
                    </Row>
                </Container>
                <Container className="pt-2">
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader><h4 className="mb-0">{IMLocalized('payroll_increment_list')}</h4></CardHeader>
                                {props.list.length !== 0 ?
                                <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                                    {props.list.map((item,index)=>{
                                        const y = index + 1;
                                        return(
                                            <ListGroupItem className="py-2" key={index}>
                                                <Row className="algin-items-center">
                                                    <Col>
                                                        {y}. {getDateFormat(item)}
                                                    </Col>
                                                    <Col className="col-auto mr-2">
                                                        <button className="btn btn-info btn-sm" onClick={()=>viewDetail(item)}>{IMLocalized('view')}</button>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                        )
                                    })}
                                </ListGroup>:
                                <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
    }
    return(
        <div>
            {
                props.isLoading ? <OverlayLoading/>:
                displayView()
            }
            
        </div>
    )
}
export default connect(mapStateToProps,null)(PayrollIncrementView);