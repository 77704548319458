import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const WIZARDCREATERECURRING_REQUEST = 'WIZARDCREATERECURRING_REQUEST';
export const WIZARDCREATERECURRING_SUCCESS = 'WIZARDCREATERECURRING_SUCCESS';
export const WIZARDCREATERECURRING_FAILURE = 'WIZARDCREATERECURRING_FAILURE';


export const wizard_create_recurring = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDCREATERECURRING_REQUEST'

        });

        myaxios.post('company/recurring_allowance/array_save/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDCREATERECURRING_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDCREATERECURRING_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}