import * as permit_array from '../../action/wizard_employee/employee_permit_array_save';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const employee_permit_array_save = (state=initialState,action)=>{
    switch(action.type){
        case permit_array.EMPLOYEEPERMITARRAYSAVE_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case permit_array.EMPLOYEEPERMITARRAYSAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case permit_array.EMPLOYEEPERMITARRAYSAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_permit_array_save;