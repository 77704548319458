import * as picture from '../../action/employee/get_employee_profile_picture_list.js';

const initialState={
    data:[],
    error:{},
    isLoading:false
}

const get_employee_profile_picture_list = (state=initialState,action)=>{
    switch(action.type){
        case picture.GETEMPPICTURELIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case picture.GETEMPPICTURELIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                error:{}
            }
        case picture.GETEMPPICTURELIST_FAILURE:
            return{
                ...state,
                error:{},
                isLoading:false,
                data:[]
            }
            default:
                return state;
    }
}
export default get_employee_profile_picture_list;