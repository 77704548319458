import * as approval from '../../action/claim/create_claim_approval1';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const create_claim_approval1 = (state=initialState,action)=>{
    switch(action.type){
        case approval.CREATECLAIMAPPROVAL1_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case approval.CREATECLAIMAPPROVAL1_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case approval.CREATECLAIMAPPROVAL1_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case approval.CREATECLAIMAPPROVAL1_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default create_claim_approval1;