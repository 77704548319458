import * as validate_pay from '../../action/employee/emp_all_validate_claim_pay';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const emp_all_validate_claim_pay = (state=initialState,action)=>{
    switch(action.type){
        case validate_pay.EMPALLVALIDATECLAIMPAY_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case validate_pay.EMPALLVALIDATECLAIMPAY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case validate_pay.EMPALLVALIDATECLAIMPAY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case validate_pay.EMPALLVALIDATECLAIMPAY_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default emp_all_validate_claim_pay;