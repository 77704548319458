import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';


export default function OtpIPBlackList({list,removeUser}){


    return(
        <>
        {list && list.length === 0 &&
        <div style={{height:'100px'}} className="d-flex justify-content-center align-items-center bg-white">
            <span className="h4  mb-0">{IMLocalized('no_data')}</span>
        </div>}
        {list && list.length !== 0 &&
        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 340px)'}}>
                {list.map((item,index)=>{

                    return(
                        <ListGroupItem className="py-2 d-flex align-items-center justify-content-between" key={index}>
                            <span>{item}</span>
                            <div className="delete_btn mr-3 d-flex align-items-center">
                                <i className="fas fa-trash text-danger cursor-pointer" onClick={()=>removeUser(item,false)}   />
                            </div>
                        </ListGroupItem>
                    )
                })}
        </ListGroup>}
        </>
    )
}