import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { Toast } from '../../swal/SwalToast';

export const APPLYLEAVERESET_REQUEST = 'APPLYLEAVERESET_REQUEST';
export const APPLYLEAVERESET_SUCCESS = 'APPLYLEAVERESET_SUCCESS';
export const APPLYLEAVERESET_FAILURE = 'APPLYLEAVERESET_FAILURE';
export const APPLYLEAVERESET_RESET = 'APPLYLEAVERESET_RESET';

export const apply_leave_reset =(idd,data)=>{
    return dispatch=>{
        dispatch({
            type:'APPLYLEAVERESET_REQUEST'
        });

        if(data.length !== 0){
            for(let i =0;i<data.length;i++){
                let id = data[i].id;
                let entitlement = data[i].entitlement;
                let carry_over_amount  = data[i].carry_over_amount;
                let encash = data[i].encash;
                let encash_amount = data[i].encash_amount;

                myaxios.post(`leave/reset/${idd}/reset/`,{
                    id:id,
                    entitlement:entitlement,
                    carry_over_amount:carry_over_amount,
                    encash:encash,
                    encash_amount:encash_amount
                })
                .then((response)=>{
                    dispatch({
                        type:'APPLYLEAVERESET_SUCCESS',
                        payload:response
                    })
                    if(i === data.length-1){
                        Toast.fire({
                            type:'success',
                            title:IMLocalized('reset_success')
                        })

                    }
                })
                .catch((error)=>{
                    dispatch({
                        type:'APPLYLEAVERESET_FAILURE',
                        payload:error
                    })
                })
            }
        }
    }
}

export const reset_apply_leave_reset = () => dispatch =>{
    dispatch({
        type:'APPLYLEAVERESET_RESET'
    });
}