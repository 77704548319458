import * as dropdown from '../action/dropdown_list';

const initialState={
    data:[],
    error:[],
    isLoading:false,
    work_permit:[],
    bank_payee:[],
    countries:[],
    gender:[],
    race:[],
    religion:[],
    relationship:[],
    payment:[],
    user_profile_relationship:[],
    salutation:[],
    payroll_choice:[],
    payroll_bonus_code:[],
    proration_option:[],
    calendar_view_list:[],
    leave_reset_day:[],
    leave_unit:[],
    leave_setting_year_initialization:[],
    schedule_leave_options:[],
    leave_status:[],
    schedule_holiday_options:[],
    schedule_all_options:[],
    claim_decimal_places:[],
    claim_rounding:[],
    bank_payer:[],
    s_g_company_sector:[],
    days_range_selector:[],
    claim_status:[],
    leave_type:[],
    pay_component_type:[],
    allowance_type:[],
    s_g_identification_type:[],
    user_actions:[],
    self_help_group:[],
    payroll_user_actions:[],
    education_level:[],
    task_status:[],
    general_priority:[],
    wage:[],
    payroll_status:[],
    sgpr_employee_cpf_payment:[],
    key_event:[],
}

const dropdown_list = (state=initialState,action)=>{
    switch(action.type){
        case dropdown.DROPDOWNLIST_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case dropdown.DROPDOWNLIST_SUCCESS:
            return{
                ...state,
                data:action.payload.data,
                salutation:action.payload.data.salutation,
                bank_payee:action.payload.data.bank_payee,
                countries:action.payload.data.countries,
                gender:action.payload.data.gender,
                race:action.payload.data.race,
                religion:action.payload.data.religion,
                relationship:action.payload.data.relationship,
                payment:action.payload.data.payment,
                payroll_choice:action.payload.data.payroll_choice,
                user_profile_relationship:action.payload.data.user_profile_relation,
                work_permit:action.payload.data.work_permit,
                payroll_bonus_code:action.payload.data.payroll_bonus_code,
                proration_option:action.payload.data.leave_proration,
                leave_reset_day:action.payload.data.leave_reset_day,
                calendar_view_list:action.payload.data.leave_view_choice,
                leave_unit:action.payload.data.leave_unit,
                leave_setting_year_initialization:action.payload.data.leave_setting_year_initialization,
                schedule_leave_options:action.payload.data.schedule_leave_options,
                leave_status:action.payload.data.leave_status,
                leave_status1:action.payload.data.leave_status,
                schedule_holiday_options:action.payload.data.schedule_holiday_options,
                schedule_all_options:action.payload.data.schedule_all_options,
                error:[],
                wage:action.payload.data.wage,
                claim_decimal_places:action.payload.data.claim_decimal_places,
                claim_rounding:action.payload.data.claim_rounding,
                bank_payer:action.payload.data.bank_payer,
                s_g_company_sector:action.payload.data.s_g_company_sector,
                isLoading:false,
                payroll_status:action.payload.data.payroll_status,
                leave_type:action.payload.data.leave_type,
                days_range_selector:action.payload.data.days_range_selector,
                claim_status:action.payload.data.claim_status,
                allowance_type:action.payload.data.allowance_type,
                pay_component_type:action.payload.data.pay_component_type,
                s_g_identification_type:action.payload.data.s_g_identification_type,
                user_actions:action.payload.data.user_actions,
                self_help_group:action.payload.data.self_help_group,
                payroll_user_actions:action.payload.data.payroll_user_actions,
                education_level:action.payload.data.education_level,
                task_status:action.payload.data.task_status,
                general_priority:action.payload.data.general_priority,
                sgpr_employee_cpf_payment:action.payload.data.s_g_p_r_employee_c_p_f_payment,
                key_event:action.payload.data.key_event,
            }
        case dropdown.DROPDOWNLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                error:action.payload
            }
        default:
            return state;
    }
}
export default dropdown_list;


export const nationality_detail = (state,id)=>{
    const { countries } = state.dropdown_list;
    const detail = id != null ? countries.find((item)=>item.value === id) : null;
    return detail?.display_name ?? '-';
}

export const salutation_detail = (state,id)=>{
    const { salutation } = state.dropdown_list;
    const detail = id != null ? salutation.find((item)=>item.value === id) : null;
    return detail?.display ?? '-'; 
}

export const gender_detail = (state,id)=>{
    const { gender } = state.dropdown_list;
    const detail = id != null ? gender.find((item)=>item.value === id) : null;
    return detail?.display ?? '-'; 
}

export const race_detail = (state,id)=>{
    const { race } = state.dropdown_list;
    const detail = id != null ? race.find((item)=>item.value === id) : null;
    return detail?.display ?? '-'; 
}

export const relationship_detail = (state,id)=>{
    const { relationship } = state.dropdown_list;
    const detail = id != null ? relationship.find((item)=>item.value === id) : null;
    return detail?.display ?? '-'; 
}

export const religion_detail = (state,id)=>{
    const { religion } = state.dropdown_list;
    const detail = id != null ? religion.find((item)=>item.value === id) : null;
    return detail?.display ?? '-'; 
}

export const identification_type_detail = (state,id)=>{
    const { s_g_identification_type } = state.dropdown_list;
    const detail = id != null ? s_g_identification_type.find((item)=>item.value === id) : null;
    return detail?.display ?? '-'; 
}

export const education_level_detail = (state,id)=>{
    const { education_level } = state.dropdown_list;
    const detail = id != null ? education_level.find((item)=>item.value === id) : null;
    return detail?.display ?? '-';
}