import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { getDateFormat } from '../../../func/getDateFormat';
import { IMLocalized } from '../../../language/IMLocalized';


const mapStateToProps=(state)=>({
    events:state.get_key_events.data,
    isLoading:state.get_key_events.isLoading,
    emplist:state.get_employee_list.data,
    isLoading1:state.get_employee_list.isLoading,
    eventdropdown:state.dropdown_list.key_event,
    isLoading2:state.dropdown_list.isLoading,

})


function KeyEventTable(props){

    return(
        <Row>
            <Col>
                <Card style={{height:'270px'}}>
                    <CardHeader className="border-0 p-2 bg-white">
                        <h3 className='title-color1 pl-2 pt-2'>{IMLocalized('key_event')}</h3>
                    </CardHeader>
                    {props.isLoading || props.isLoading1 || props.isLoading2 ? <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody> : null}
                    {!props.isLoading && !props.isLoading1 && !props.isLoading2 && props.events.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                    {!props.isLoading && !props.isLoading1 && !props.isLoading2 && props.events.length !== 0 && 
                    <Table size="sm" className="align-items-center" responsive>
                        <thead className='thead-light'>
                            <tr>
                                <th>{IMLocalized('emp_id')}</th>
                                <th>{IMLocalized('name')}</th>
                                <th>{IMLocalized('event')}</th>
                                <th>{IMLocalized('date')}</th>
                                <th>{IMLocalized('created_date')}</th>
                                <th>{IMLocalized('modified_date')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.events.map((item,index)=>{
                                const detail = props.emplist.find(element=>element.id === item.employee);
                                const data = props.eventdropdown.find(element=>element.value === item.event);
                                const date1 = new Date(item.created);
                                const modified = new Date(item.modified);
                                return(
                                    <tr key={index}>
                                        <td>{detail?.employee_number ?? '-'}</td>
                                        <td>{detail?.name ?? '-'}</td>
                                        <td>{data?.display ?? '-'}</td>
                                        <td>{getDateFormat(item.date)}</td>
                                        <td>{getDateFormat(date1)}</td>
                                        <td>{getDateFormat(modified)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>}
                </Card>
            </Col>
        </Row>
    )
}
export default connect(mapStateToProps,null)(KeyEventTable);