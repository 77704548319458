import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { backpaylist1,wagelist1 } from '../../../data/backpay_data';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';
import { getValue } from '../../../func/getValue';
import { getColumnName } from '../../../func/getColumnName';
import { popError } from '../../../func/popError';
import {create_payslip_backpay} from '../../../action/payroll/create_payslip_backpay';
import XLSX from 'xlsx';
import { usePrevious } from '../../../hook/usePrevious';
import { get_payroll_instance } from '../../../action/payroll/get_payroll_instance';
import { payroll_detail_get_aggridheader } from '../../../action/payroll/payroll_detail_get_aggridheader';
import { get_payroll_history_list } from '../../../action/history/get_payroll_history_list';

const mapStateToProps=(state)=>({
    contractlist:state.get_payslip_employee_contract.data,
    wagelist:state.dropdown_list.wage,
    pay_component_type:state.dropdown_list.pay_component_type,
    errors:state.create_payslip_backpay.errors,
    success:state.create_payslip_backpay.data,
})

const mapDispatchToProps=(dispatch)=>({
    create_payslip_backpay:(id,data)=>{
        dispatch(create_payslip_backpay(id,data))
    },
    get_payroll_instance:(id)=>{
        dispatch(get_payroll_instance(id))
    },
    payroll_detail_get_aggridheader:(id)=>{
        dispatch(payroll_detail_get_aggridheader(id))
    },
    get_payroll_history_list:()=>{
        dispatch(get_payroll_history_list())
    },
})

const initialState={
    pay_type:'BACKPAY',
    backpay_department:false,
    backpay_from:0,
    backpay_to:0,
    departmentlist:[],
    backpaylist:[],
    backpay_data:[],
}

function ModalUploadBackPay(props){
    const {success,closeModal,get_payroll_history_list,get_payroll_instance,payroll_detail_get_aggridheader} = props;
    const [state,setState] = useState(initialState);
    const prevSuccess = usePrevious(success);
    const [language] = useState(localStorage.getItem('language'));

    useEffect(()=>{
        if(!props.toggle){
            setState(initialState);
        }
    },[props.toggle])

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== success && success != null){
            closeModal();
            get_payroll_instance(props.id);
            payroll_detail_get_aggridheader(props.id);
            get_payroll_history_list();
        }
    },[prevSuccess,success,closeModal,get_payroll_instance,payroll_detail_get_aggridheader,get_payroll_history_list,props.id])

    useEffect(()=>{
        if(props.toggle){
            let data = [];
            let arr = [];
            let step = 0;
            for(let i=0;i<props.contractlist.length;i++){
                const item = props.contractlist[i];
                const department = item.employee.contracts[0].department;
                data.push({...item,department});
                const found = arr.some(el=>el.name === department);
                if(!found){
                    arr.push({id:step,name:department});
                    step += 1;
                }
            }
            setState(prevState=>({...prevState,departmentlist:arr,backpaylist:data}));
        }
    },[props.contractlist,props.toggle])

    const generateBackpay=()=>{
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_other_pay');
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');
        ws3.getCell('A1').value = 'Otherpay';
        ws3.getCell('A2').value = state.pay_type;
        ws3.getCell('A3').value = language;
        ws.columns=[
            {header:IMLocalized('agrid_emp_id'),key:'employee.employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'employee.name'},
            {header:IMLocalized('agrid_department'),key:'department'},
            {header:IMLocalized('agrid_name'),key:'name'},
            {header:IMLocalized('agrid_is_included_in_gross'),key:'is_gross'},
            {header:IMLocalized('agrid_wage'),key:'wage'},
            {header:IMLocalized('agrid_amount'),key:'amount'},
        ]


        let dropdown = [{data:IMLocalized('excel_true')},{data:IMLocalized('excel_false')}];
        for(let i=0;i<dropdown.length;i++){
            let y = i+1;
            ws2.getCell('A'+y).value = dropdown[i].data;
        }

       
        for(let i=0;i<wagelist1.length;i++){
            let y = i+1;
            ws2.getCell('B'+y).value = language === 'zh' ?  wagelist1[i].display_zh : wagelist1[i].display;
        }
        

        if(state.backpaylist.length !== 0){
            for(let i=0;i<ws.columns.length;i++){
                const field = ws.columns[i].key;
                for(let x=0;x<state.backpaylist.length;x++){
                    const step = 2+x;
                    if(field === 'employee.employee_number' || field === 'employee.name' || field === 'department'){
                        const data = getValue(state.backpaylist[x],field);
                        ws.getCell(getColumnName(i)+step).value = data;
                    }
                    else if (field === 'is_gross'){
                        ws.getCell(getColumnName(i)+step).value = language === 'zh' ? '否' : 'No';
                        ws.getCell(getColumnName(i)+step).dataValidation={
                            type:'list',
                            allowBlank:false,
                            error:IMLocalized('excel_valid_in_list'),
                            errorStyle:'error',
                            errorTitle:'Error',
                            showErrorMessage:true,
                            formulae:[`=setting!$A$1:$A$2`]
                        }
                    }
                    else if(field === 'wage'){
                        ws.getCell(getColumnName(i)+step).value = null;
                        ws.getCell(getColumnName(i)+step).dataValidation={
                            type:'list',
                            allowBlank:false,
                            error:IMLocalized('excel_valid_in_list'),
                            errorStyle:'error',
                            errorTitle:'Error',
                            showErrorMessage:true,
                            formulae:[`=setting!$B$1:$B$`+wagelist1.length]
                        }
                    }
                }
            }
            const detail1 = props.pay_component_type.find(item=>item.value === state.pay_type);
            const title = detail1 ? detail1.display : '';

            ws.columns.forEach(column =>{
                column.width = column.header.length < 15 ? 15 : column.header.length
            })

            wb.xlsx.writeBuffer().then((buf)=>{
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, 'Employee_Other_Pay_Template_'+title+'.xlsx');
            });
        }
    }

    const generateBackpay1=()=>{
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_other_pay');
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');

        let rowData =[];
        for(let i =0;i<state.departmentlist.length;i++){
            const id = state.departmentlist[i].id;
            const name = state.departmentlist[i].name;
            if(id >= state.backpay_from && id <= state.backpay_to){
                const detail =state.backpaylist.filter(function(item){
                    return item.department === name
                })
                rowData = rowData.concat(detail)
            }
            else{
                popError(IMLocalized('from_department_cant_great_to_department'));
            }
        }

        ws3.getCell('A1').value = 'Otherpay';
        ws3.getCell('A2').value = state.pay_type;
        ws3.getCell('A3').value = language;
        ws.columns=[
            {header:IMLocalized('agrid_emp_id'),key:'employee.employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'employee.name'},
            {header:IMLocalized('agrid_department'),key:'department'},
            {header:IMLocalized('agrid_name'),key:'name'},
            {header:IMLocalized('agrid_is_included_in_gross'),key:'is_gross'},
            {header:IMLocalized('agrid_wage'),key:'wage'},
            {header:IMLocalized('agrid_amount'),key:'amount'},
        ]

        let dropdown = [{data:IMLocalized('excel_true')},{data:IMLocalized('excel_false')}];
        for(let i=0;i<dropdown.length;i++){
            let y = i+1;
            ws2.getCell('A'+y).value = dropdown[i].data;
        }

        for(let i=0;i<wagelist1.length;i++){
            let y = i+1;
            ws2.getCell('B'+y).value = language === 'zh' ?  wagelist1[i].display_zh : wagelist1[i].display;
        }
    
        if(rowData.length !== 0){
            for(let i=0;i<ws.columns.length;i++){
                const field = ws.columns[i].key;
                for(let x=0;x<rowData.length;x++){
                    const step = 2+x;
                    if(field === 'employee.employee_number' || field === 'employee.name' || field === 'department'){
                        const data = getValue(rowData[x],field);
                        ws.getCell(getColumnName(i)+step).value = data;
                    }
                    else if (field === 'is_gross'){
                        ws.getCell(getColumnName(i)+step).value = language === 'zh' ? '否' : 'No';
                        ws.getCell(getColumnName(i)+step).dataValidation={
                            type:'list',
                            allowBlank:false,
                            error:IMLocalized('excel_valid_in_list'),
                            errorStyle:'error',
                            errorTitle:'Error',
                            showErrorMessage:true,
                            formulae:[`=setting!$A$1:$A$2`]
                        }
                    }
                    else if(field === 'wage'){
                        ws.getCell(getColumnName(i)+step).value = null;
                        ws.getCell(getColumnName(i)+step).dataValidation={
                            type:'list',
                            allowBlank:false,
                            error:IMLocalized('excel_valid_in_list'),
                            errorStyle:'error',
                            errorTitle:'Error',
                            showErrorMessage:true,
                            formulae:[`=setting!$B$1:$B$`+wagelist1.length]
                        }
                    }
                }
            }
           
            const detail1 = props.pay_component_type.find((item)=>item.value === state.pay_type)
            const title = detail1 ? detail1.display : '';

            ws.columns.forEach(column =>{
                column.width = column.header.length < 15 ? 15 : column.header.length
            })

            wb.xlsx.writeBuffer().then((buf)=>{
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, 'Employee_Other_Pay_Template_'+title+'.xlsx');
            });
        }
    }

    const uploadBackPayExcel=(e)=>{
        let file = e.target.files[0];

        const reader = new FileReader();
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3];
            const ws = wb.Sheets[wsname];
            const columns={
                'A':'employee_number',
                'B':'employee_name',
                'C':'department',
                'D':'name',
                'E':'is_gross',
                'F':'wage',
                'G':'amount',
            }
            let backpayData = [];
            let rowIndex = 2;
            let idd = '';
            let pay_type = '';
            let lang = '';
            if(ws3 !== undefined){
                if(ws3['A1'] !== undefined){
                    idd = ws3['A1'].w;
                }
                if(ws3['A2'] !== undefined){
                    pay_type = ws3['A2'].w;
                }
                if(ws3['A3'] !== undefined){
                    lang = ws3['A3'].w;
                }
            }
            while(ws['A'+rowIndex]){
                let row = {};
                for(let i =0;i<Object.keys(columns).length;i++){
                    const column = Object.keys(columns)[i];
                    const index = column + rowIndex;
                    if(ws[index] !== undefined){
                        row[columns[column]] = ws[index].w;
                    }
                    else{
                        row[columns[column]] = null;
                    }
                }
                backpayData.push(row);
                rowIndex++;
            }

            if(idd === 'Otherpay' && pay_type !== ''){
                if(lang === language){
                    if(backpayData.length !== 0){
                        let arr =[];
                        for(let i =0;i<backpayData.length;i++){
                            const employee_number = backpayData[i].employee_number;
                            const backpay_detail  = state.backpaylist.find(item=>(item.employee.employee_number === employee_number));
                            const payslip_contract = backpay_detail ? backpay_detail.payslipcontract[0].id : '';
                            const payslip = backpay_detail ? backpay_detail.id :'';
                            
                            const is_gross1 = backpayData[i].is_gross;
                            let is_gross;
                            if(language === 'zh'){
                                is_gross = is_gross1 === '是' ? true : false;
                            }
                            else{
                                is_gross = is_gross1 === 'Yes' ? true : false;
                            }
                            
                            const amount = backpayData[i].amount;
                            const wage = backpayData[i].wage;
                            const name = backpayData[i].name;
                            let additional_wage = false;
                            if(language === 'zh' && wage === '额外薪资'){
                                additional_wage = true;
                            }
                            else{
                                if(wage === 'Additional'){
                                    additional_wage = true;
                                }
                            }
                            
                            if(amount !== null && amount !== '' &&name !==null && name !== '' ){
                                if(parseFloat(amount)){
                                    arr.push({payslip_contract,is_gross,amount,additional_wage,payslip,pay_type,name})
                                }
                                
                            }
                        }

                        if(arr.length !== 0){
                            setState(prevState=>({...prevState,backpay_data:arr}));
                            props.create_payslip_backpay(props.id,arr);
                        }
                        else{
                            popError(IMLocalized('no_data_updated'));
                        }
                    }
                }
                else{
                    popError(IMLocalized('wrong_file_selected'),IMLocalized('please_choose_correct_language'))
                }
            }
            else{
                popError(IMLocalized('wrong_file_selected'));
            }
        }
        reader.readAsBinaryString(file);
    }

    const arr2  = state.backpay_from !== '' ? state.departmentlist.slice(state.backpay_from,state.departmentlist.length) : state.departmentlist;

    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('upload_other_pay')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='pay_type'>{IMLocalized('pay_type')}</label>
                            <Input size="sm" type="select" id="pay_type" value={state.pay_type} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,pay_type:value}))
                            }}
                            >
                                {language === 'en' && 
                                backpaylist1.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                                {language === 'zh' && 
                                backpaylist1.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display_zh}</option>
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <div className="d-flex">
                    <div className="custom-control custom-radio mr-3">
                        <input className="custom-control-input" id="detailPayback1" name="detailPayback" type="radio" value="false" checked = {state.backpay_department ===false} onChange={()=>{setState(prevState=>({...prevState,backpay_department:!prevState.backpay_department}))}}/>
                        <label className="custom-control-label" htmlFor="detailPayback1">{IMLocalized('all_department')}</label>
                    </div>
                    <div className="custom-control custom-radio mr-3">
                        <input className="custom-control-input" id="detailPayback2" name="detailPayback" type="radio" value="true" checked = {state.backpay_department === true} onChange={()=>{setState(prevState=>({...prevState,backpay_department:!prevState.backpay_department}))}}/>
                        <label className="custom-control-label" htmlFor="detailPayback2">{IMLocalized('select_department')}</label>
                    </div>
                </div>
                {state.backpay_department === true &&
                <Row className='mt-2'>
                    <Col lg="3">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='backpay_from'>{IMLocalized('from_department')}</label>
                            <Input size="sm" type="select" id="backpay_from" value={state.backpay_from} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,backpay_from:value,backpay_to:value}));
                            }}>
                                {state.departmentlist.map((item,index)=>{
                                    return <option value={item.id} key={index}>{item.name}</option>
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col lg="3">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='backpay_to'>{IMLocalized('to_department')}</label>
                            <Input size="sm" type="select" id="backpay_to" value={state.backpay_to} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,backpay_to:value}));
                            }}>
                                {arr2.map((item,index)=>{
                                    return <option value={item.id} key={index}>{item.name}</option>
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>}
                <div className='mt-3'>
                    <button className='btn btn-primary btn-sm' onClick={state.backpay_department === true ? ()=>generateBackpay1() : ()=>generateBackpay() }>{IMLocalized('get_other_pay_template')}</button>
                    <input type="file" id="ImportBackpayFile" className="custom-file-input form-control-sm d-none"  accept=".xlsx"
                    onClick={(event)=>{
                        const {target = {} } = event || {};
                        target.value = "";
                    }}
                    onChange={uploadBackPayExcel}
                    />
                    <label className="btn btn-primary btn-sm mb-0" htmlFor="ImportBackpayFile">{IMLocalized('upload_file')}</label>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-danger btn-sm' onClick={props.closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalUploadBackPay);