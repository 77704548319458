import myaxios from '../../axios';
import FormData from 'form-data';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const EMPCLAIMAPPLYVALIDATE_REQUEST = 'EMPCLAIMAPPLYVALIDATE_REQUEST';
export const EMPCLAIMAPPLYVALIDATE_SUCCESS = 'EMPCLAIMAPPLYVALIDATE_SUCCESS';
export const EMPCLAIMAPPLYVALIDATE_FAILURE = 'EMPCLAIMAPPLYVALIDATE_FAILURE';


export const employee_claim_apply_validate = (employee,claim_type,original_amount,claim_date,dependant,set_pending,notes,file,doctor,diagnosis,location)=>{
    return dispatch=>{
        dispatch({
            type:'EMPCLAIMAPPLYVALIDATE_REQUEST'
        });

        let formData = new FormData();
        if(file.length !==0){
            for(let i=0;i<file.length;i++){
                formData.append('upload_images',file[i],file[i].name);
            }
        }
        formData.append('employee',employee);
        formData.append('claim_type',claim_type);
        formData.append('original_amount',original_amount);
        formData.append('dependant',dependant);
        formData.append('claim_date',claim_date);
        formData.append('set_pending',set_pending);
        formData.append('comment',notes);
        formData.append('medicalclaim.doctor',doctor);
        formData.append('medicalclaim.diagnosis',diagnosis);
        formData.append('medicalclaim.location',location)

        myaxios.post('claim/apply/validate/',formData,{headers:{'Content-Type':'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'EMPCLAIMAPPLYVALIDATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPCLAIMAPPLYVALIDATE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('apply_failure')
            });
        })
        
    }
}