import * as createjobtitle from '../action/create_jobtitle';

const initialState ={
  isLoading:false,
    errors:null,
    data:null
}

const create_jobtitle = (state=initialState,action) =>{
    switch(action.type) {
        case createjobtitle.CREATEJOBTITLE_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case createjobtitle.CREATEJOBTITLE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createjobtitle.CREATEJOBTITLE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case createjobtitle.CREATEJOBTITLE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
        }
}
export default create_jobtitle;