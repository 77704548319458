import * as empdetail from '../../action/employee/get_employee_detail';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const get_employee_detail = (state=initialState,action)=>{
    switch(action.type){
        case empdetail.EMPDETAIL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case empdetail.EMPDETAIL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case empdetail.EMPDETAIL_FAILURE:
            return{
                ...state,
                errors:action.payload,
                isLoading:false,
            }
        case empdetail.EMPDETAIL_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default get_employee_detail;