import * as relationarraysave from '../../action/wizard_employee/employee_relationship_array_save';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}

const employee_relationship_array_save = (state=initialState,action)=>{
    switch(action.type){
        case relationarraysave.EMPRELATIONARRAYSAVE_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case relationarraysave.EMPRELATIONARRAYSAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case relationarraysave.EMPRELATIONARRAYSAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_relationship_array_save;