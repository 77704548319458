

export function checkboxRenderer(params){
    const field = params.colDef.field;
    const data = params.data;
    const roles = data.roles;
    const user = data.user;
    let value = false;
    if(field !== 'role_user'){
        for(let i=0;i<roles.length;i++){
            const name = roles[i];
            if(name === field){
                value = true;
            }
        }
    }
    else{
        if(user !== null){
            value = true;
        }
    }

    return <input type="checkbox" checked={value} readOnly/>
}
