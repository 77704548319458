import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELOVERTIME_REQUEST = 'DELOVERTIME_REQUEST';
export const DELOVERTIME_FAILURE = 'DELOVERTIME_FAILURE';
export const DELOVERTIME_SUCCESS = 'DELOVERTIME_SUCCESS';
export const DELOVERTIME_RESET = 'DELOVERTIME_RESET';

export const delOvertime = (id)=>{
    return dispatch =>{
        dispatch({
            type: 'DELOVERTIME_REQUEST'
        });
        
        myaxios.delete(`company/overtime/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELOVERTIME_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'DELOVERTIME_FAILURE',
                payload: error,
                error:true
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            })
        })
    }
}

export const reset_delete_overtime = () => dispatch =>{
    dispatch({
        type:'DELOVERTIME_RESET'
    });
}



