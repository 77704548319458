import * as payrollhistory from '../../action/history/get_payroll_history_list';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const get_payroll_history_list = (state=initialState,action)=>{
    switch(action.type){
        case payrollhistory.GETPAYROLLHISTORYLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case payrollhistory.GETPAYROLLHISTORYLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case payrollhistory.GETPAYROLLHISTORYLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload,
            }
        default:
            return state;
    }
}
export default get_payroll_history_list;