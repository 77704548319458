import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';


export const CREATETASKLIST_REQUEST = 'CREATETASKLIST_REQUEST';
export const CREATETASKLIST_SUCCESS = 'CREATETASKLIST_SUCCESS';
export const CREATETASKLIST_FAILURE = 'CREATETASKLIST_FAILURE';

export const create_task_list = (employee,name,details,priority,status,single_acknowledge,scheduled_date,metadata)=>{
    return dispatch=>{
        dispatch({
            type:'CREATETASKLIST_REQUEST'
        });

        myaxios.post('tasks/',{
            employee:employee,
            name:name,
            details:details,
            priority:priority,
            status:status,
            single_acknowledge:single_acknowledge,
            scheduled_date:scheduled_date,
            metadata:metadata
        })
        .then((response)=>{
            dispatch({
                type:'CREATETASKLIST_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATETASKLIST_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}