import * as paymentupdatepayment from '../../action/payroll/payment_update_payroll_payment';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const payment_update_payroll_payment = (state=initialState,action)=>{
    switch(action.type){
        case paymentupdatepayment.UPDATEPAYROLLPAYMENTDETAIL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case paymentupdatepayment.UPDATEPAYROLLPAYMENTDETAIL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case paymentupdatepayment.UPDATEPAYROLLPAYMENTDETAIL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default payment_update_payroll_payment;