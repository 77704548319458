import myaxios from '../../axios';

export const GETEMPEDUCATION_REQUEST = 'GETEMPEDUCATION_REQUEST';
export const GETEMPEDUCATION_SUCCESS = 'GETEMPEDUCATION_SUCCESS';
export const GETEMPEDUCATION_FAILURE = 'GETEMPEDUCATION_FAILURE';


export const get_employee_education = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETEMPEDUCATION_REQUEST'
        })

        myaxios.get('employee/education/')
        .then((response)=>{
            dispatch({
                type:'GETEMPEDUCATION_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPEDUCATION_FAILURE',
                payload:error
            })
        })
    }
}