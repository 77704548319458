import myaxios from '../../axios';
export const EMPCONTRACTJOBTITLE1_SUCCESS = 'EMPCONTRACTJOBTITLE1_SUCCESS';
export const EMPCONTRACTJOBTITLE1_REQUEST = 'EMPCONTRACTJOBTITLE1_REQUEST';
export const EMPCONTRACTJOBTITLE1_FAILURE = 'EMPCONTRACTJOBTITLE1_FAILURE';


export const employee_contract_jobtitle1 = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'EMPCONTRACTJOBTITLE1_REQUEST'
        });
        
        myaxios.post(`company/jobtitle/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'EMPCONTRACTJOBTITLE1_SUCCESS',
                payload:response  
            }
        )})
        .catch((error) =>{
            dispatch({
                type: 'EMPCONTRACTJOBTITLE1_FAILURE',
                payload: error,
            })
        })
    }
}