import * as emppassport from '../../action/employee/get_employee_passport';

const initialState ={
    data:[],
    error:null,
    isLoading:false,
}


const get_employee_passport = (state=initialState,action) =>{

    switch(action.type) {
        case emppassport.REQUEST_EMPPASSPORT:
            return{
                ...state,
                isLoading: true,
                error:null,
                data:[]
            }
        case emppassport.GET_EMPPASSPORT:
            return{
                ...state,
                data: action.payload.data,
                error:{},
                isLoading:false,
                isLoaded:true
            }
        case emppassport.FAILURE_EMPPASSPORT:
            return{
                ...state,
                error:action.payload,
                isLoading:false
            }
        default:
            return state;
    }
}
export default get_employee_passport;


export const emp_get_employee_passport = (state,id) =>{
    const { data } = state.get_employee_passport;

    return id != null ? data.filter(item=>item.employee === id) : [];
    
}