import myaxios from '../../axios';


export const CLAIMALLGETCLAIMDETAIL_REQUEST = 'CLAIMALLGETCLAIMDETAIL_REQUEST';
export const CLAIMALLGETCLAIMDETAIL_SUCCESS = 'CLAIMALLGETCLAIMDETAIL_SUCCESS';
export const CLAIMALLGETCLAIMDETAIL_FAILURE = 'CLAIMALLGETCLAIMDETAIL_FAILURE';
export const CLAIMALLGETCLAIMDETAIL_RESET = 'CLAIMALLGETCLAIMDETAIL_RESET';


export const claim_all_get_claim_detail = (id)=>{
    return dispatch=>{
        dispatch({
            type:'CLAIMALLGETCLAIMDETAIL_REQUEST'
        });

        myaxios.get(`claim/apply/${id}/`)
        .then((response)=>{
            dispatch({
                type:'CLAIMALLGETCLAIMDETAIL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'CLAIMALLGETCLAIMDETAIL_FAILURE',
                payload:error
            })
        })
    }
}


export const reset_claim_all_get_claim_detail = () =>dispatch =>{
    dispatch({
        type:'CLAIMALLGETCLAIMDETAIL_RESET'
    });
}