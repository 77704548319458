import * as delcertification from '../../action/employee/delete_employee_certification';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const delete_employee_certification = (state=initialState,action)=>{
    switch(action.type){
        case delcertification.DELEMPCERTIFICATION_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case delcertification.DELEMPCERTIFICATION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delcertification.DELEMPCERTIFICATION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case delcertification.DELEMPCERTIFICATION_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default delete_employee_certification;