import * as createholidaylist from '../action/create_holiday_list';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const create_holiday_list = (state=initialState,action)=>{
    switch(action.type){
        case createholidaylist.CREATEHOLIDAYLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case createholidaylist.CREATEHOLIDAYLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data,
            }
        case createholidaylist.CREATEHOLIDAYLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case createholidaylist.CREATEHOLIDAYLIST_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default create_holiday_list;