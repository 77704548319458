import * as updatepicture from '../../action/employee/update_employee_profile_picture';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}

const update_employee_profile_picture = (state=initialState,action)=>{
    switch(action.type){
        case updatepicture.UPDATEEMPPROFILEPICTURE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case updatepicture.UPDATEEMPPROFILEPICTURE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updatepicture.UPDATEEMPPROFILEPICTURE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default update_employee_profile_picture;