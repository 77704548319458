import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Input, Col, Container, FormFeedback, FormGroup, Row, Table } from "reactstrap";
import { wizard_validate_recurring } from "../../action/wizard/wizard_validate_recurring";
import {allowance_type} from '../../data/allowance_data';
import { IMLocalized } from "../../language/IMLocalized";
import ModalErrOvertime from "./modal/ModalErrOvertime";



export default function RecurringDetail({language,setData}){

    const dispatch = useDispatch();
    const success = useSelector(state=>state.wizard_validate_recurring.data);
    const errors = useSelector(state=>state.wizard_validate_recurring.errors);
    const [ code, setCode ] = useState('');
    const [ err_code, setErrCode ] = useState('');
    const [ name, setName] = useState('');
    const [ err_name, setErrName ] = useState('');
    const [ type, setType] = useState('');
    const [ err_type, setErrType] = useState('');
    const [ amount, setAmount] = useState('');
    const [ err_amount, setErrAmount] = useState('');
    const [ gross, setGross] = useState(false);
    const [ list, setList] = useState([]);
    const [ err_msg, setErrMsg] = useState([]);
    const [ err_toggle, setErrToggle] = useState(false);
    const [ is_pending, setIsPending] = useState(false);

    useEffect(()=>{
        if(success != null){
            setData(list);
        }
    },[success,list,setData])

    useEffect(()=>{
        if(errors != null && is_pending === true){
            const arr = [];
            for(let i=0; i<errors.length; i++ ){
                const obj = errors[i];
                if(obj !== true){
                    if(obj.hasOwnProperty('name')){
                        const msg = obj['name'].toString();
                        const name = list[i].name;
                        arr.push({ name, msg });
                    }
                    if(obj.hasOwnProperty('code')){
                        const msg = obj['code'].toString();
                        const name = list[i].code;
                        arr.push({ name, msg });
                    }
                    if(obj.hasOwnProperty('non_field_errors')){
                        const msg = obj['non_field_errors'].toString();
                        const name = list[i].code;
                        arr.push({ name, msg });
                    }
                }
            }
            setErrMsg(arr);
            setErrToggle(true);
            setIsPending(false);
        }
    },[errors,is_pending,list])

    const removeList=(index)=>{
        const data = [...list];
        data.splice(index,1);
        setList(data);
    }

    const addList=()=>{
        if(name !== '' && amount !== '' && code !== '' &&  type !== ''){
            let count = 0;
            if(list.some(el => el.name === name)){
                setErrCode(IMLocalized('duplicate_name'));
                count++;
            }
            if(list.some(el => el.code === code)){
                setErrCode(IMLocalized('duplicate_code'));
                count++;
            }

            if(count === 0){
                setList(prevState=>([...prevState,{code,name,allowance_type:type,amount,is_included_in_gross:gross}]));
                setName('');
                setCode('');
                setAmount('');
                setType('');
                setGross(false);
            }
        }
        else{
            if(name === ''){
                setErrName(IMLocalized('name_is_required'));
            }
            if(code === ''){
                setErrCode(IMLocalized('code_is_required'));
            }
            if(amount === ''){
                setErrAmount(IMLocalized('amount_is_required'));
            }
            if(allowance_type === ''){
                setErrType(IMLocalized('type_is_required'));
            }
        }
    }

    const onSubmit=()=>{
        if(list.length !== 0 ){
            dispatch(wizard_validate_recurring(list));
            setIsPending(true);
        }
        else{
            setData([]);
        }
    }

    return(
        <>
        <Container >
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h4 className="mb-0">{IMLocalized('add_recurring_allowance')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm" onClick={addList}>{IMLocalized('add')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="code">{IMLocalized('code')}</label>
                                        <Input size="sm" id="code" type="text" value={code} invalid={err_code.length >0} onChange={(e)=>{setCode(e.target.value); setErrCode('')}} />
                                        <FormFeedback>{err_code}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="name">{IMLocalized('name')}</label>
                                        <Input size="sm" id="name" type="text" value={name} invalid={err_name.length >0 } onChange={(e)=>{setName(e.target.value); setErrName('')}} />
                                        <FormFeedback>{err_name}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="type">{IMLocalized('type')}</label>
                                        <Input size="sm" id="type" type="select" value={type} invalid={err_type.length >0 } onChange={(e)=>{setType(e.target.value); setErrType('')}}>
                                            <option></option>
                                            {allowance_type.map((item,index)=>{
                                                return (
                                                    <option value={item.value} key={index}>{language === 'zh' ? item.display_zh : item.display }</option>
                                                )
                                            })}
                                        </Input>
                                        <FormFeedback>{err_type}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="amount">{IMLocalized('amount')}</label>
                                        <Input size="sm" id="amount" type="number" value={amount} onChange={(e)=>{ setAmount(e.target.value); setErrAmount('') }} invalid={err_amount.length >0} />
                                        <FormFeedback>{err_amount}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="12">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="wizardrecurringgross" name='gross' className="custom-control-input" checked={gross} onChange={(e)=>setGross(e.target.checked)} />
                                        <label className="custom-control-label" htmlFor="wizardrecurringgross">{IMLocalized('is_included_in_gross')}</label>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <h4 className="mb-0">{IMLocalized('recurring_allowance')}</h4>
                        </CardHeader>
                        {list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {list.length !== 0 &&
                        <Table size="sm" className="algin-items-center" responsive>
                            <thead className="text-center">
                                <tr>
                                    <th>{IMLocalized('code')}</th>
                                    <th>{IMLocalized('name')}</th>
                                    <th>{IMLocalized('type')}</th>
                                    <th>{IMLocalized('amount')}</th>
                                    <th>{IMLocalized('is_included_in_gross')}</th>
                                    <th>{IMLocalized('delete')}</th>
                                </tr>
                            </thead>
                            <tbody className="text-center">
                                {list.map((item,index)=>{
                                    const detail = allowance_type.find(el=>el.value === item.allowance_type);
                                    let type_name = language === 'zh' ? detail.display_zh : detail.display;

                                    return(
                                        <tr>
                                            <td>{item.code}</td>
                                            <td>{item.name}</td>
                                            <td>{type_name}</td>
                                            <td>$ {item.amount}</td>
                                            <td>
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" id={'wizard_recurringgross'+index} className="custom-control-input" name="gross" checked={item.is_included_in_gross} readOnly/>
                                                    <label className="custom-control-label" htmlFor={'wizard_recurringgross'+index}></label>
                                                </div>
                                            </td>
                                            <td align='center' valign='center'>
                                                <div className="delete_btn d-flex align-items-center justify-content-center">
                                                    <i className="fa fa-trash text-red cursor-pointer" onClick={()=>removeList(index)} />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>}
                    </Card>
                </Col>
            </Row>
            <div className='create_btn_parent'>
                <i className='fas fa-arrow-circle-right text-success cursor-pointer create_btn' onClick={()=>onSubmit()}  />
            </div>
        </Container>
        <ModalErrOvertime toggle={err_toggle} closeModal={()=>{setErrToggle(false); setErrMsg([])}} detail={err_msg} />
        </>
    )
}