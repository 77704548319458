import React, { useEffect, useState } from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader,Col,Input,Row} from 'reactstrap';
import PasswordCheckList from 'react-password-checklist';
import { IMLocalized } from '../../../language/IMLocalized';
import { first_time_password } from '../../../action/first_time_password'; 
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '../../../hook/usePrevious';

export default function FirstTimeLogin({closeModal,toggle,token}){
    const [password,setPassword] = useState('');
    const [password2,setPassword2] = useState('');
    const [isValid,setValid] = useState(false);
    const dispatch = useDispatch();
    const success = useSelector(state=>state.first_time_password.data);
    const prevSuccess = usePrevious(success);
    
    useEffect(()=>{
       
        if(prevSuccess !== undefined && prevSuccess !== success && success !== null){
            setPassword('');
            setPassword2('');
            closeModal();
        }
    },[success,prevSuccess,closeModal])

    const handleInput=(e)=>{
        const target  = e.target;
        const value = target.value;
        setPassword(value);
    }

    const handleInput2=(e)=>{
        const target  = e.target;
        const value = target.value;
        setPassword2(value);
    }

    const onSubmit=()=>{
        if(isValid){
            dispatch(first_time_password(token,password));
        }
    }

    return(
        <Modal isOpen={toggle} size="lg" >
            <ModalHeader toggle={closeModal}>
                {IMLocalized('set_new_password')}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <div className="form-group">
                            <label className="form-control-label">{IMLocalized('new_password')}</label>
                            <Input size="sm" type="password" name="new_password1" value={password} onChange={handleInput} />
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="form-group">
                            <label className="form-control-label">{IMLocalized('confirm_password')}</label>
                            <Input size="sm" type="password" name="confirm_password1" value={password2} onChange={handleInput2}  />
                        </div>
                    </Col>
                </Row>
                <PasswordCheckList
                    rules={['minLength','number','capital','match','lowerCase','notEmpty','specialChar']}
                    minLength={8}
                    value={password}
                    valueAgain={password2}
                    onChange={(isValid)=>{setValid(isValid)}}
                    messages={{
                        minLength: IMLocalized('password_has_8_char'),
                        number: IMLocalized('password_has_a_number'),
                        capital: IMLocalized('password_has_a_capital_letter'),
                        match:IMLocalized('password_match'),
                        notEmpty:IMLocalized('password_not_empty'),
                        lowercase:IMLocalized('password_has_a_lowercase_letter'),
                        specialChar:IMLocalized('password_has_special')
                    }}
                />
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-primary btn-sm' onClick={onSubmit} >{IMLocalized('submit')}</button>
                <button className='btn btn-danger btn-sm' onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}