import myaxios from '../../axios';

export const GETUSERAPPLYLIST_REQUEST = 'GETUSERAPPLYLIST_REQUEST';
export const GETUSERAPPLYLIST_SUCCESS = 'GETUSERAPPLYLIST_SUCCESS';
export const GETUSERAPPLYLIST_FAILURE = 'GETUSERAPPLYLIST_FAILURE';

export const user_apply_leave_list = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETUSERAPPLYLIST_REQUEST'
        });

        myaxios.get('leave/self/apply/')
        .then((response)=>{
            dispatch({
                type:'GETUSERAPPLYLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETUSERAPPLYLIST_FAILURE',
                payload:error
            })
        })
    }
}