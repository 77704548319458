import * as bankarrayvalidate from '../../action/wizard_employee/employee_bank_array_validate';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const employee_bank_array_validate = (state=initialState,action)=>{
    switch(action.type){
        case bankarrayvalidate.EMPBANKARRAYVALIDATE_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case bankarrayvalidate.EMPBANKARRAYVALIDATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case bankarrayvalidate.EMPBANKARRAYVALIDATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_bank_array_validate;