import * as sg_child from '../../action/employee/get_employee_sg_child';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
}

const get_employee_sg_child = (state=initialState,action)=>{
    switch(action.type){
        case sg_child.GETSGCHILD_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case sg_child.GETSGCHILD_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                errors:[]
            }
        case sg_child.GETSGCHILD_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload,
            }
        default:
            return state;
    }
}
export default get_employee_sg_child;

export const emp_get_employee_sg_child = (state,id)=>{
    const { data } = state.get_employee_sg_child;

    return id != null ? data.filter(item=>item.employee === id).sort((a,b) => (new Date(b.date_of_birth) - new Date(a.date_of_birth))) : []
}