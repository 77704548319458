import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const LEAVEHRAPPROVE_REQUEST ='LEAVEHRAPPROVE_REQUEST';
export const LEAVEHRAPPROVE_SUCCESS ='LEAVEHRAPPROVE_SUCCESS';
export const LEAVEHRAPPROVE_FAILURE ='LEAVEHRAPPROVE_FAILURE';

export const leave_hr_approve =(id,approve,remarks)=>{
    return dispatch=>{
        dispatch({
            type:'LEAVEHRAPPROVE_REQUEST'
        });

        const swal = approve === true ? IMLocalized('approve_success') : IMLocalized('reject_success');
        const err_swal = approve === true ? IMLocalized('approve_failure') : IMLocalized('reject_failure');
    
        myaxios.post('leave/apply/approve/',{
            use_day_leave:id,
            approve:approve,
            remarks:remarks
        })
        .then((response)=>{
            dispatch({
                type:'LEAVEHRAPPROVE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: swal
            })
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVEHRAPPROVE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:err_swal,
            });
        })
    }
}
