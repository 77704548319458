import React, { useContext } from "react";
import { Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import Swal from "sweetalert2";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";
import MainTabContent from "./MainTabContent";
import TabList from "./tab_component/TabList";

export default function MainTab(){

    const {currentnavs, resetTab, is_edit, setIsEdit, current_user} = useContext(NewMainContext);


    const clearNavs = ()=>{
        if(current_user === 'hr'){
            if(is_edit === true){
                Swal.fire({
                    title:IMLocalized('changes_not_be_saved'),
                    type:'warning',
                    showCancelButton:true,
                    cancelButtonColor:'#d33',
                    confirmButtonColor:'#3085d6',
                    confirmButtonText:IMLocalized('yes!'),
                    cancelButtonText:IMLocalized('no!'),
                })
                .then((result)=>{
                    if(result.hasOwnProperty('value')){
                        resetTab();
                        setIsEdit(false);
                    }
                })
            }
            else{
                resetTab();
            }
        }
        else{
            resetTab();
        }
    }

    return(
        <Row className="pt-2">
            <Col className="p-0">
                <Nav tabs>
                    {currentnavs != null && currentnavs.length !== 0 &&
                    currentnavs.map((item)=>{
                        return(
                            <TabList id={item.id} name={item.name} key={item.id} />
                        )
                    })}
                    <NavItem className="cursor-pointer">
                        <NavLink>
                            <span onClick={clearNavs} className="tab_button1"><i className="fas fa-trash-alt"/></span>
                        </NavLink>
                    </NavItem>
                </Nav>
                <MainTabContent/>
            </Col>
        </Row>
    )
}