import React, { useContext, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Input, Row } from "reactstrap";
import Swal from "sweetalert2";
import { claim_all_get_claim_detail } from "../../../action/claim/claim_all_get_claim_detail";
import { get_apply_claim_list } from "../../../action/claim/get_apply_claim_list";
import { emp_all_array_approve_apply_claim, reset_emp_all_array_approve_apply_claim } from "../../../action/employee/emp_all_array_approve_apply_claim";
import { emp_all_array_reject_apply_claim, reset_emp_all_array_reject_apply_claim } from "../../../action/employee/emp_all_array_reject_apply_claim";
import { get_all_employee_available_claim } from "../../../action/employee/get_all_employee_available_claim";
import { NewMainContext } from "../../../context/NewMainContext";
import { emp_get_apply_claim_list_detail } from "../../../reducers/claim/get_apply_claim_list";
import { get_claim_list_detail } from "../../../reducers/claim/get_claim_list";
import { get_all_employee_available_claim_detail } from "../../../reducers/employee/get_all_employee_available_claim";
import { get_employee_list_detail } from "../../../reducers/employee/get_employee_list";
import { amountRenderer } from "../../ag-grid_component/amountRenderer";
import { date2Renderer } from "../../ag-grid_component/date2Renderer";
import { dateRenderer } from "../../ag-grid_component/dateRenderer";
import { isFirstColumn } from "../../ag-grid_component/isFirstColumn";
import ModalClaimDetail from "../../claim/modal/ModalClaimDetail";
import { popError } from "../../../func/popError";
import { IMLocalized } from "../../../language/IMLocalized";
import ClaimRemainingTable from "../hr_employee/component/ClaimRemainingTable";
import ModalEmpApplyClaim from "../hr_employee/modal/ModalEmpApplyClaim";
import { ClaimStatusRenderer } from "../../../func/ClaimStatusRenderer";
import { ClaimOfficerEmployeeContext } from "../../../context/ClaimOfficerEmployeeContext";
import OverlayLoading from "../../loading_component/OverlayLoading";
import BreadCrumbList from "../../breadcrumb/BreadCrumbList";

const gridOptions={
    defaultColDef:{
        resizable:true,
        sortable:true,
        filter:true,
        enableValue:true,
        enableRowGroup:true,
    }
}

export default function EmpClaimDetail(){

    const {employee_claim_detail_id : id, employee_claim_detail_claim : claim_type, toEmployeeList, toEmployeeProfile } = useContext(ClaimOfficerEmployeeContext);
    const empdetail = useSelector(state=>get_employee_list_detail(state,parseInt(id)));
    const claimdetail = useSelector(state=>get_claim_list_detail(state,parseInt(claim_type)));
    const reject_success = useSelector(state=>state.emp_all_array_reject_apply_claim.data);
    const approve_success = useSelector(state=>state.emp_all_array_approve_apply_claim.data);
    const datalist = useSelector(state=>get_all_employee_available_claim_detail(state,parseInt(id),parseInt(claim_type)));
    const totallist = useSelector(state=>emp_get_apply_claim_list_detail(state,parseInt(id), parseInt(claim_type)));
    const isLoading = useSelector(state=>state.get_apply_claim_list.isLoading);
    const dispatch = useDispatch();
    const { action_toggle, addItem} = useContext(NewMainContext);
    const [ selected, setSelected] = useState('4');
    const [ detail_toggle, setDetailToggle] = useState(false);
    const [ apply_toggle, setApplyToggle] = useState(false);

    useEffect(()=>{
        if( reject_success != null){
            dispatch(get_apply_claim_list());
            dispatch(get_all_employee_available_claim());
            dispatch(reset_emp_all_array_reject_apply_claim());
            setDetailToggle(false);
            gridOptions.api.clearFocusedCell();
        }
    },[reject_success,dispatch])

    useEffect(()=>{
        if( approve_success != null){
            dispatch(get_apply_claim_list());
            dispatch(get_all_employee_available_claim());
            dispatch(reset_emp_all_array_approve_apply_claim());
            setDetailToggle(false);
            gridOptions.api.clearFocusedCell();
        }
    },[approve_success,dispatch])

    const onCellFocused=(params)=>{
        if(params?.column?.colDef?.field != null &&  params?.column?.colDef?.field !== 'select'){
            const focusedCell = gridOptions.api.getFocusedCell();
            const cellValue = gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex)
            const id = cellValue.data.id;
            dispatch(claim_all_get_claim_detail(id))
            setDetailToggle(true)
        }
    }

    const pendingApprove = ( bool ) =>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0 ){
            Swal.fire({
                title: bool ? IMLocalized('approving_claim') : IMLocalized('rejecting_claim'),
                input:'textarea',
                text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
                showCancelButton:true,
                cancelButtonText:IMLocalized('no!'),
                confirmButtonText:IMLocalized('yes!')
            })
            .then((result)=>{
                if(result.value != null){
                    const comment = result.value;
                    const arr = data.map(item=>{
                        return { claim: item.id, comment}
                    });
                    if(bool){
                        dispatch(emp_all_array_approve_apply_claim(arr));
                    }
                    else{
                        dispatch(emp_all_array_reject_apply_claim(arr));
                    }
                }
            })
        }
        else{
            popError(bool ? IMLocalized('select_claim_to_approve') : IMLocalized('select_claim_to_reject'));
        }
    }

    const rowData = selected === '4' ? totallist : totallist.filter(item=>{
        let filter;
        if(selected === '0') filter = 'PENDING';
        else if(selected === '1')  filter = 'APPROVED';
        else if( selected === '2')  filter = 'REJECTED';
        else if (selected === '3')  filter = 'CANCELLED';
        else if (selected === '5')  filter = 'PAID';
        else filter= 'PAYROLL';
        return item.status === filter;
    })

    const amount = rowData.reduce((sum,item)=>{
        return sum + item.claim_amount;
    },0)

    const pinnedData = rowData.length !== 0 && [{ claim_amount: amount, claim_date: IMLocalized('total'), created_date: null}];

    const option1 = [
        { value : '4', display: IMLocalized('ALL') },
        { value : '0', display: IMLocalized('PENDING')},
        { value : '1', display: IMLocalized('APPROVED')},
        { value : '2', display: IMLocalized('REJECTED')},
        { value : '3', display: IMLocalized('CANCELLED')},
        { value : '5', display: IMLocalized('PAID')},
        { value : '6', display: IMLocalized('PAYROLL')}
    ];

    return(
        <>
        {isLoading ? <OverlayLoading/>:
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('employee'), onClick:()=>toEmployeeList('0')},
                        { active: false, title:empdetail?.name ?? '', onClick:()=>toEmployeeProfile('1')},
                        { active: true, title:claimdetail?.name ?? ''}
                    ]}
                    />
                    <div className="ml-2">
                        <button className="btn btn-primary btn-sm" onClick={()=>toEmployeeProfile('1')}><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                        <button className="btn btn-primary btn-sm" onClick={()=>setApplyToggle(true)}>{IMLocalized('apply_claim')}</button>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2 justify-content-center">
                <Col lg="10">
                    {datalist != null && claimdetail != null && <ClaimRemainingTable list={datalist} detail={claimdetail} />}
                    <div className="mt-2 d-flex justify-content-between align-items-center">
                        <h3 className='mb-0'>
                            {selected === '4' && IMLocalized('all_claim_list')}
                            {selected === '0' && IMLocalized('pending_claim_list')}
                            {selected === '1' && IMLocalized('approved_claim_list')}
                            {selected === '2' && IMLocalized('rejected_claim_list')}
                            {selected === '3' && IMLocalized('cancelled_claim_list')}
                            {selected === '5' && IMLocalized('paid_claim_list')}
                            {selected === '6' && IMLocalized('payroll_paid_claim_list')}
                        </h3>
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="d-flex mr-2">
                                {selected === '0' &&
                                <>
                                <button className='btn btn-primary btn-sm' onClick={()=>pendingApprove(true)}>{IMLocalized('approve')}</button>
                                <button className='btn btn-danger btn-sm' onClick={()=>pendingApprove(false)}>{IMLocalized('reject')}</button>
                                </>}
                                {selected === '1' && 
                                <>
                                <button className='btn btn-danger btn-sm' onClick={()=>pendingApprove(false)}>{IMLocalized('reject')}</button>
                                </>}
                            </div>
                            <Input type="select" size="sm" name="selected" value={selected} onChange={(e)=>setSelected(e.target.value)}>
                                {option1.map((item)=>{
                                    return(
                                        <option value={item.value} key={item.value}>{item.display}</option>
                                    )
                                })}
                            </Input>
                        </div>
                    </div>
                    <div className="ag-theme-balham mt-2" style={{ height:action_toggle ? 'calc(100vh - 450px)' : 'calc(100vh - 414px)', width:'100%'}}>
                        <AgGridReact
                            enableRangeSelection={true}
                            gridOptions={gridOptions}
                            suppressDragLeaveHidesColumns={true}
                            suppressSizeToFit={true}
                            suppressColumnMoveAnimation={false}
                            rowData={rowData}
                            pinnedBottomRowData={pinnedData}
                            onCellFocused={onCellFocused}
                            overlayNoRowsTemplate = {IMLocalized('no_data')}
                            columnDefs={selected === '0' || selected === '1' ? [
                                {
                                    headerName:IMLocalized('select'),
                                    pinned:'left',
                                    field:'select',
                                    width:150,
                                    headerCheckboxSelection: isFirstColumn,
                                    checkboxSelection: isFirstColumn,
                                    headerCheckboxSelectionFilteredOnly:true,
                                    lockPosition: true
                                },
                                {
                                    headerName:IMLocalized('claim_date'),
                                    field:'claim_date',
                                    cellRendererFramework:date2Renderer
                                },
                                {
                                    headerName:IMLocalized('claim_amount'),
                                    field:'claim_amount',
                                    cellRendererFramework:amountRenderer,
                                },
                                {
                                    headerName:IMLocalized('status'),
                                    field:'status',
                                    cellRendererFramework:ClaimStatusRenderer
                                },
                                {
                                    headerName:IMLocalized('created_date'),
                                    field:'created_date',
                                    cellRendererFramework:dateRenderer,
                                }
                            ]: [
                                {
                                    headerName:IMLocalized('claim_date'),
                                    field:'claim_date',
                                    cellRendererFramework:date2Renderer
                                },
                                {
                                    headerName:IMLocalized('claim_amount'),
                                    field:'claim_amount',
                                    cellRendererFramework:amountRenderer,
                                },
                                {
                                    headerName:IMLocalized('status'),
                                    field:'status',
                                    cellRendererFramework:ClaimStatusRenderer
                                },
                                {
                                    headerName:IMLocalized('created_date'),
                                    field:'created_date',
                                    cellRendererFramework:dateRenderer,
                                }
                            ]}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
        <ModalClaimDetail toggle={detail_toggle} closeModal={()=>{ setDetailToggle(false); gridOptions.api.clearFocusedCell()}} />
        <ModalEmpApplyClaim toggle={apply_toggle} has_emp={true} has_claim={true} id={id} claim_type={claim_type} closeModal={()=>setApplyToggle(false)} />
        </>}
        </>
    )
}