import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const EMPALLARRAYREJECTCLAIM_REQUEST = 'EMPALLARRAYREJECTCLAIM_REQUEST';
export const EMPALLARRAYREJECTCLAIM_SUCCESS = 'EMPALLARRAYREJECTCLAIM_SUCCESS';
export const EMPALLARRAYREJECTCLAIM_FAILURE = 'EMPALLARRAYREJECTCLAIM_FAILURE';
export const EMPALLARRAYREJECTCLAIM_RESET = 'EMPALLARRAYREJECTCLAIM_RESET';

export const emp_all_array_reject_apply_claim = (data)=>{
    return dispatch=>{
        dispatch({
            type:'EMPALLARRAYREJECTCLAIM_REQUEST'
        });

        myaxios.post('claim/apply/array_reject/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'EMPALLARRAYREJECTCLAIM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type:'success',
                title:IMLocalized('reject_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'EMPALLARRAYREJECTCLAIM_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title: IMLocalized('reject_failure')
            });
        })
    }
}

export const reset_emp_all_array_reject_apply_claim = () => dispatch =>{
    dispatch({
        type:'EMPALLARRAYREJECTCLAIM_RESET'
    });
}