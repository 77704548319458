import React,{useState, useEffect} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import Swal from 'sweetalert2';
import { getDateFormat } from '../../../func/getDateFormat';
import { Table} from 'reactstrap';
import {get_employee_passport} from '../../../action/employee/get_employee_passport';
import {delete_employee_passport, reset_delete_employee_passport} from '../../../action/employee/delete_employee_passport';
import {IMLocalized} from '../../../language/IMLocalized';
import ModalCreatePassport from './modal/ModalCreatePassport';
import ModalEditPassport from './modal/ModalEditPassport';
import { emp_get_employee_passport } from '../../../reducers/employee/get_employee_passport';

export default function EmployeePassport({ id }){

    const isLoading = useSelector(state=>state.get_employee_passport.isLoading);
    const delete_success = useSelector(state=>state.delete_employee_passport.data);
    const list = useSelector(state=>emp_get_employee_passport(state,parseInt(id)));
    
    const [add_toggle,setAddToggle] = useState(false);
    const [toggle,setToggle] = useState(false);
    const [edit_detail,setEditDetail] = useState(null);

    const dispatch = useDispatch();

    

    useEffect(()=>{
        if(delete_success != null){
            dispatch(get_employee_passport());
            dispatch(reset_delete_employee_passport());
        }
    },[delete_success,dispatch])

    const updateSuccess=()=>{
        editToggle();
        dispatch(get_employee_passport());
    }

    const createSuccess=()=>{
        setAddToggle(false);
        dispatch(get_employee_passport());
    }

    const editToggle=(index)=>{
        const toggle1  = !toggle;
        if(toggle1 === true){
            setEditDetail(list[index]);
        }
        else{
            setEditDetail(null);
        }
        setToggle(toggle1);
    }

    const deletePassport=(index)=>{
        const { id } = list[index];
        Swal.fire({
            title:IMLocalized('delete_passport_confirmation'),
            text:IMLocalized('revert_msg'),
            type:'warning',
            showCancelButton:true,
            cancelButtonColor:'#3085d6',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
        })
        .then((result) =>{
            if(result.value){
                dispatch(delete_employee_passport([{id}]));
            }
        })
    }

    return(
        <>
        <div className="d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title" >
            <div className="d-flex align-items-center">
                <i className="fas fa-passport text-primary"/>
                <h4 className="text-primary m-0 pl-2">{IMLocalized('employee_passport')}</h4>
            </div>
            <div className="text-primary pt-2 cursor-pointer pr-2" onClick={()=>setAddToggle(!add_toggle)} >
                <i className="fas fa-plus-circle mr-1 function_icon" />
                <small>{IMLocalized('add_passport')}</small>
            </div>
        </div>
        {!isLoading && list.length === 0 && <div className="p-2"><h4>{IMLocalized('no_data')}</h4></div>}
        {isLoading && <div className='p-2'><h4>{IMLocalized('loading')}..</h4></div>}
        {!isLoading && list.length !== 0 &&
        <Table size="sm" className="mt-2 align-items-center table-bordered" responsive>
            <thead className="thead-light text-center">
                <tr >
                    <th className="w-25">{IMLocalized('passport_number')}</th>
                    <th className="w-25">{IMLocalized('issued_date')}</th>
                    <th className="w-25">{IMLocalized('expiry_date')}</th>
                    <th>{IMLocalized('edit')} / {IMLocalized('delete')}</th>
                </tr>
            </thead>
            <tbody className="text-center">
                {list.map((item,index)=>{
                    return(
                        <tr key={item.id}>
                            <td>{item.passport}</td>
                            <td>{getDateFormat(item.issued_date)}</td>
                            <td>{getDateFormat(item.expiry_date)}</td>
                            <td align="center" valign="center">
                                <div className='d-flex justify-content-center'>
                                    <div className="primary_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-pen text-primary cursor-pointer" onClick={()=>editToggle(index)}  />
                                    </div>
                                    <div className="delete_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-trash text-red cursor-pointer" onClick={()=>deletePassport(index)}  />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
        }
        <ModalCreatePassport id={id} toggle={add_toggle} onSuccess={createSuccess} closeModal={()=>setAddToggle(!add_toggle)}  />
        <ModalEditPassport toggle={toggle} closeModal={editToggle} detail={edit_detail} onSuccess={updateSuccess} />
        </>
    )
}