import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Row,Col,Container,Breadcrumb,BreadcrumbItem} from 'reactstrap';
import * as Survey from 'survey-react';
import "survey-react/survey.css";
import '../../css/matrixtable.css';
import {submit_appraisal, remove_submit_appraisal} from '../../action/appraisal/submit_appraisal';
import {IMLocalized} from '../../language/IMLocalized';
import {get_appraisal_group_list} from '../../action/appraisal/get_appraisal_group_list';
import AppraisalActionGroup from './AppraisalActionGroup';
import AppraisalActionEmployee from './AppraisalActionEmployee';
import { NewMainContext } from '../../context/NewMainContext';


const myCss = {
    matrix: {root: "table table-striped matrixtable"},
};

function AppraisalAction(props){

    const { appraisal_action_step, setAppraisalActionStep, addItem} = useContext(NewMainContext);
    const [group_detail,setGroupDetail] = useState(null);
    const [group_id,setGroupID] = useState(null);
    const [model,setModel] = useState(new Survey.Model([]));
    const [view_data,setViewData] = useState(null);
    const [emp_id,setEmpID] = useState(null);

    const grouplist = useSelector(state=>state.get_appraisal_group_list.data);
    const formlist = useSelector(state=>state.get_appraisal_form_list.data);
    const submit_success = useSelector(state=>state.submit_appraisal.data);
    const dispatch = useDispatch();

    useEffect(()=>{
        if( submit_success != null){
            dispatch(get_appraisal_group_list());
            dispatch(remove_submit_appraisal());
            setAppraisalActionStep(1);
        }
    },[submit_success,dispatch,setAppraisalActionStep])

    useEffect(()=>{
        if(grouplist.length !== 0 && group_id !== null){
            const detail = grouplist.find(item=>item.id === group_id);
            setGroupDetail(detail);
        }
    },[grouplist,group_id])

    const handleGroup=(item)=>{
        setAppraisalActionStep(1);
        setGroupDetail(item);
        setGroupID(item.id);
    }

    const viewAppraisal=(item)=>{
        const data = formlist.find(el=>el.id === group_detail.form);
        setModel(new Survey.Model({
            questions:data.questions,
            completedHtml:"<p>Thank you for completing appraisal<p>"
        }));
        setViewData(item.response);
        setAppraisalActionStep(2);
    }

    const startAppraisal=(item)=>{
        setAppraisalActionStep(3);
        setEmpID(item.employee.id);
        const data = formlist.find(el=>el.id === group_detail.form);
        setModel(new Survey.Model({
            questions:data.questions,
            completedHtml:"<p>Thank you for completing appraisal<p>"
        }));
    }

    const completedSurvey=(json)=>{
        dispatch(submit_appraisal(group_detail?.id,emp_id,json.data))
    }

    return(
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('appraisal')}</BreadcrumbItem>
                    </Breadcrumb>
                    {appraisal_action_step === 1 && 
                    <button className='btn btn-primary btn-sm ml-2' onClick={()=>{setAppraisalActionStep(0); setGroupDetail(null)}}><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                    }
                    {appraisal_action_step === 2 && 
                    <button className="btn btn-primary btn-sm ml-2" 
                    onClick={()=>{
                        setAppraisalActionStep(appraisal_action_step-1);
                        setModel(new Survey.Model([]))
                    }}>
                        <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}
                    </button>
                    }
                    {appraisal_action_step === 3 && 
                    <button className="btn btn-primary btn-sm ml-2" 
                    onClick={()=>{
                        setAppraisalActionStep(1);
                        setModel(new Survey.Model([]))
                    }}>
                        <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}
                    </button>
                    }
                </Col>
            </Row>
            {appraisal_action_step === 0 && <AppraisalActionGroup grouplist={grouplist} handleGroup={handleGroup} />}
            {appraisal_action_step === 1 && <AppraisalActionEmployee detail={group_detail} viewAppraisal={viewAppraisal} startAppraisal={startAppraisal} />}
            {appraisal_action_step === 2 && <Survey.Survey cssType="bootstrap" data={view_data} css={myCss} model={model} showNavigationButtons={false} mode="display"></Survey.Survey>}
            {appraisal_action_step === 3 && <div className='mt-2'><Survey.Survey cssType="bootstrap" css={myCss} model={model} onComplete={completedSurvey} ></Survey.Survey></div>}
        </Container>
        </>
    )
}
export default AppraisalAction;