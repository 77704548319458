import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Input } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; 
import '../../../css/aggrid.css';
import { getDateFormat } from '../../../func/getDateFormat';
import { get_hr_employee_all_leave_detail } from '../../../action/employee/get_hr_employee_all_leave_detail';
import ModalReportLeave from './modal/ModalReportLeave';
import moment from 'moment';
import ModalEmpApplyLeave from './modal/ModalEmpApplyLeave';
import Swal from 'sweetalert2';
import { emp_all_array_approve_leave, reset_emp_all_array_approve_leave } from '../../../action/employee/emp_all_array_approve_leave';
import { get_apply_leave_list } from '../../../action/get_apply_leave_list';
import { manager_leave_list } from '../../../action/leave/manager_leave_list';
import OverlayLoading from '../../loading_component/overlay_loading';
import { get_approvals_leave_list } from '../../../action/approvals/get_approvals_leave_list';
import { get_all_employee_available_leave } from '../../../action/employee/get_all_employee_available_leave';
import { dateRenderer } from '../../ag-grid_component/dateRenderer';
import { isFirstColumn } from '../../ag-grid_component/isFirstColumn';
import { dateRangeOverlap } from '../../../func/dateRangeOverlap';
import { NewMainContext } from '../../../context/NewMainContext';
import { get_employee_list_detail } from '../../../reducers/employee/get_employee_list';
import { popError } from '../../../func/popError';
import ModalLeaveDetail1 from '../../leave/Modal/ModalLeaveDetail1';
import { LeaveStatusRenderer } from '../../../func/LeaveStatusRenderer';
import BreadCrumbList from '../../breadcrumb/BreadCrumbList';

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    suppressRowClickSelection:true,
    defaultColDef:{
        resizable:true,
        sortable:true,
        filter:true
    }
}

export default function EmployeeAllLeaveView(props){

    const { addItem, action_toggle } = useContext(NewMainContext);
    const dispatch = useDispatch();
    const isLoading = useSelector(state=>state.get_apply_leave_list.isLoading);
    const isLoading1 = useSelector(state=>state.get_general_color.isLoading);
    const empdetail = useSelector(state=>get_employee_list_detail(state,parseInt(props.id)));
    const applylist = useSelector(state=>state.get_apply_leave_list.data);
    const approve_success = useSelector(state=>state.emp_all_array_approve_leave.data);


    const [startDate,setStartDate] = useState(moment().startOf('year'));
    const [endDate,setEndDate] = useState(moment().endOf('year'));
    const [start_date,setStart_Date] = useState(moment().startOf('year').format('YYYY-MM-DD'));
    const [end_date,setEnd_Date] = useState(moment().endOf('year').format('YYYY-MM-DD'));
    const [report_toggle,setReportToggle] = useState(false);
    const [selected,setSelected] = useState('5');
    const [focusedInput,setFocused] = useState(null);
    const [apply_toggle,setApplyToggle] = useState(false);
    const [totallist, setTotalList] = useState([]);
    const [detail_toggle, setDetailToggle] = useState(false);

    useEffect(()=>{
        if(approve_success != null){
            dispatch(manager_leave_list());
            dispatch(get_apply_leave_list());
            dispatch(get_approvals_leave_list());
            dispatch(get_all_employee_available_leave());
            dispatch(reset_emp_all_array_approve_leave());
        }
    },[approve_success,dispatch])

    useEffect(()=>{
        let list = dateRangeOverlap(applylist,start_date,end_date);

        const arr = list.filter(function(item){
            return item.employee.id === parseInt(props.id);
        })

        const data = arr.map((item)=>{
            let date = '-';
            if(item.from_date === item.to_date){
                date = getDateFormat(item.from_date);
            }
            else{
                date = getDateFormat(item.from_date) + ' - ' + getDateFormat(item.to_date);
            }
            return {...item,leave_date:date,leave_name:item.leave_setting.name};
        })

        data.sort((a,b) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime());

        setTotalList(data);
        
    },[applylist,start_date,end_date,props.id])

    const onCellFocused=(params)=>{
        if(params?.column?.colDef?.field != null &&  params?.column?.colDef?.field !== 'select'){
            const focusedCell = gridOptions.api.getFocusedCell();
            const cellValue = gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex)
            const id = cellValue.data.id;
            dispatch(get_hr_employee_all_leave_detail(id));
            setDetailToggle(true);
        }
    }

    const onDatesChange=({startDate:startDate1,endDate:endDate1})=>{
        setStartDate(startDate1);
        setEndDate(endDate1);
        let start = null;
        if(startDate1 !== null){
            let startdate =startDate1._d;
            start = moment(startdate).format('YYYY-MM-DD');
        }
        
        let end = null;
        if(endDate1 !== null){
            let enddate = endDate1._d;
            end = moment(enddate).format('YYYY-MM-DD');
        }
        setEnd_Date(end);
        setStart_Date(start);
    }

    const pendingReject = (boolean)=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0){
            const arr = data.map((item)=>item.id);

            const title1 = boolean ? IMLocalized('approving_leave') : IMLocalized('rejecting_leave');

            Swal.fire({
                title:title1,
                input:'textarea',
                text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
                showCancelButton:true,
                cancelButtonText:IMLocalized('no!'),
                confirmButtonText:IMLocalized('yes!')
            })
            .then((result)=>{
                if(result.hasOwnProperty('value')){
                    const remark = result.value;
                    dispatch(emp_all_array_approve_leave(arr,boolean,remark));
                }
            })
        }
        else{
            const title = boolean ? IMLocalized('select_leave_to_approve')  : IMLocalized('select_leave_to_reject');
            popError(title);
        }
    }

    const columnDefs = selected === '0' || selected === '1' ? [
        {
            headerName:IMLocalized('select'),
            pinned:'left',
            field:'select',
            width:150,
            headerCheckboxSelection: isFirstColumn,
            checkboxSelection: isFirstColumn,
            headerCheckboxSelectionFilteredOnly:true,
            lockPosition: true
        },
        {
            headerName:IMLocalized('leave_date'),
            field:'leave_date',
            width:250,
            comparator:(valueA,ValueB,nodeA,nodeB)=>{
                const date1 = new Date(nodeA.data.from_date) 
                const date2 = new Date(nodeB.data.from_date);
                return date2 -date1
            },
            filter:false,
        },
        {
            headerName:IMLocalized('leave_type'),
            field:'leave_name',
            width:250
        },
        {   
            headerName:IMLocalized('leave_amount'),
            field:'amount',
            width:250
        },
        {
            headerName:IMLocalized('status'),
            field:'status',
            width:250,
            cellRendererFramework:LeaveStatusRenderer
        },
        {
            headerName:IMLocalized('created_date'),
            field:'created_date',
            width:250,
            cellRendererFramework:dateRenderer
        }
    ] : [
        {
            headerName:IMLocalized('leave_date'),
            field:'leave_date',
            width:250,
            comparator:(valueA,ValueB,nodeA,nodeB)=>{
                const date1 = new Date(nodeA.data.from_date) 
                const date2 = new Date(nodeB.data.from_date);
                return date2 -date1
            },
            filter:false,
        },
        {
            headerName:IMLocalized('leave_type'),
            field:'leave_name',
            width:250,
        },
        {   
            headerName:IMLocalized('leave_amount'),
            field:'amount',
            width:250
        },
        {
            headerName:IMLocalized('status'),
            field:'status',
            width:250,
            cellRendererFramework:LeaveStatusRenderer
        },
        {
            headerName:IMLocalized('created_date'),
            field:'created_date',
            width:250,
            cellRendererFramework:dateRenderer
        }
    ]


    const rowData = useMemo(()=>{
        if(selected === '5'){
            return totallist;
        }
        else{
            let filter;
            if(selected === '0'){
                filter = 'PENDING';
            }
            else if(selected === '1'){
                filter = 'APPROVED';
            }
            else if(selected === '2'){
                filter = 'REJECTED';
            }
            else if(selected === '3'){
                filter = 'CANCELLED';
            }
            else if(selected === '4'){
                filter = 'CONSUMED';
            }

            return totallist.filter(item=>item.status === filter);
        }

    },[selected,totallist]) 

    const amount = rowData.reduce((total,item)=> total + item.amount, 0);
    const pinnedBottomRowData = rowData.length !== 0 ? [{amount, leave_date:IMLocalized('total'), created_date:null, id:null}] : null;

    return(
        <>
        {isLoading || isLoading1 ? <OverlayLoading/> :
        <>
        <Container fluid>
            <Row className='bg-white border-bottom'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('employee'), onClick:()=>props.toEmployeeList('0')},
                        { active: false, title:empdetail?.name ?? '', onClick:()=>props.toEmployeeProfile('1')},
                        { active: true, title:IMLocalized('all_leave')}
                    ]}/>
                    <div className='d-flex pl-2'>
                        <button onClick={()=>props.toEmployeeProfile('1')} className="btn btn-primary btn-sm"> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                        <button onClick={()=>setApplyToggle(true)} className="btn btn-primary btn-sm">{IMLocalized('apply_leave')}</button>
                    </div>
                </Col>
            </Row>
            <Row className='mt-2 justify-content-center'>
                <Col lg="10">
                    <div className='d-flex justify-content-between'>
                        <DateRangePicker
                            startDate={startDate}
                            isOutsideRange={()=>false}
                            small = {true}
                            minimumNights={0}
                            startDateId="unique_start_date_id"
                            endDate={endDate}
                            endDateId="unique_end_date_id"
                            onDatesChange={onDatesChange}
                            focusedInput={focusedInput}
                            displayFormat='DD/MM/YYYY'
                            onFocusChange={(v)=>setFocused(v)} 
                        />
                        <div className='d-flex'>
                            <Input type="select" className="mr-2" size="sm" name="selected" value={selected} onChange={(e)=>setSelected(e.target.value)}>
                                <option value='5'>{IMLocalized('ALL')}</option>
                                <option value='0'>{IMLocalized('PENDING')}</option>
                                <option value="1">{IMLocalized('APPROVED')}</option>
                                <option value="2">{IMLocalized('REJECTED')}</option>
                                <option value="3">{IMLocalized('CANCELLED')}</option>
                                <option value="4">{IMLocalized('CONSUMED')}</option>
                            </Input>
                            <div>
                                <button className="btn btn-success btn-sm" onClick={()=>setReportToggle(true)}>{IMLocalized('export')}</button>
                            </div>
                        </div>
                    </div>
                    <div className='mt-2 d-flex justify-content-between'>
                        <h3 className='mb-0'>
                            {selected === '5' && IMLocalized('all_leave_list')}
                            {selected === '0' && IMLocalized('pending_leave_list')}
                            {selected === '1' && IMLocalized('approved_leave_list')}
                            {selected === '2' && IMLocalized('rejected_leave_list')}
                            {selected === '3' && IMLocalized('cancelled_leave_list')}
                            {selected === '4' && IMLocalized('consumed_leave_list')}
                        </h3>
                        <div>
                            {selected === '0' && 
                            <>
                            <button className="btn btn-primary btn-sm" onClick={()=>pendingReject(true)}>{IMLocalized('approve')}</button>
                            <button className="btn btn-danger btn-sm" onClick={()=>pendingReject(false)}>{IMLocalized('reject')}</button>
                            </>}
                            {selected === '1' &&
                            <button className="btn btn-danger btn-sm" onClick={()=>pendingReject(false)}>{IMLocalized('reject')}</button>
                            }
                        </div>
                    </div>
                    <div className='ag-theme-balham mt-1' style={{height:action_toggle ? 'calc(100vh - 320px)':'calc(100vh - 284px)' ,width:'100%'}} >
                        <AgGridReact
                            columnDefs={columnDefs}
                            gridOptions={gridOptions}
                            rowData={rowData}
                            suppressDragLeaveHidesColumns={true}
                            suppressSizeToFit={true}
                            suppressColumnMoveAnimation={false}
                            overlayNoRowsTemplate = {IMLocalized('no_data')}
                            pinnedBottomRowData={pinnedBottomRowData}
                            onCellFocused={onCellFocused}
                            >
                            </AgGridReact>
                    </div>
                </Col>
            </Row>
        </Container>
        <ModalLeaveDetail1 toggle={detail_toggle} toprofile={false} closeModal={()=>{setDetailToggle(false); gridOptions.api.clearFocusedCell()}}  />
        <ModalEmpApplyLeave toggle={apply_toggle} id={props.id} closeModal={()=>{setApplyToggle(false)}} has_emp={true} has_leave={false}   />
        <ModalReportLeave toggle={report_toggle} startDate={startDate} endDate={endDate} start_date={start_date} end_date={end_date} id={props.id} emp_name={empdetail?.name} emp_number={empdetail?.employee_number} closeModal={()=>setReportToggle(false)}  />
        </>}
        </>
    )
}