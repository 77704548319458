import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELALLOWANCE_REQUEST = 'DELALLOWANCE_REQUEST';
export const DELALLOWANCE_FAILURE = 'DELALLOWANCE_FAILURE';
export const DELALLOWANCE_SUCCESS = 'DELALLOWANCE_SUCCESS';
export const DELALLOWANCE_RESET = 'DELALLOWANCE_RESET';

export const delAllowance = (id)=>{
    return dispatch =>{
        dispatch({
            type: 'DELALLOWANCE_REQUEST'
        });
        
        myaxios.delete(`company/allowance/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELALLOWANCE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'DELALLOWANCE_FAILURE',
                payload: error,
                error:true
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_allowance = ()=> dispatch =>{
    dispatch({
        type:'DELALLOWANCE_RESET'
    });
}




