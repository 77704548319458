import myaxios from '../axios';

export const GETGENERALCOLOR_REQUEST = 'GETGENERALCOLOR_REQUEST';
export const GETGENERALCOLOR_SUCCESS = 'GETGENERALCOLOR_SUCCESS';
export const GETGENERALCOLOR_FAILURE = 'GETGENERALCOLOR_FAILURE';

export const get_general_color = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETGENERALCOLOR_REQUEST'
        });

        myaxios.get('general/color/')
        .then((response)=>{
            dispatch({
                type:'GETGENERALCOLOR_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETGENERALCOLOR_FAILURE',
                payload:error
            })
        })
    }
}