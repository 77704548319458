import * as contractsection1 from '../../action/employee/employee_contract_section1';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const employee_contract_section1 = (state=initialState,action)=>{
    switch(action.type){
        case contractsection1.EMPCONTRACTSECTION1_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case contractsection1.EMPCONTRACTSECTION1_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractsection1.EMPCONTRACTSECTION1_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_contract_section1;