import myaxios from '../../axios';
export const REQUEST_EMPWORKPERMIT = 'REQUEST_EMPWORKPERMIT';
export const GET_EMPWORKPERMIT = 'GET_EMPWORKPERMIT';
export const FAILURE_EMPWORKPERMIT = 'FAILURE_EMPWORKPERMIT';


export const get_employee_permit = ()=>{
    return dispatch =>{
        dispatch({
            type: 'REQUEST_EMPWORKPERMIT'
        });
        myaxios.get('employee/workpermit/')
        .then((response)=>{
            dispatch({
                type: 'GET_EMPWORKPERMIT',
                payload:response
                
            })
        })
        .catch((error) =>
        dispatch({
            type: 'FAILURE_EMPWORKPERMIT',
            payload: error,
        })
        )
    }
}
