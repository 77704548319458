import myaxios from '../../axios';

export const USERPICTURE_REQUEST = 'USERPICTURE_REQUEST'; 
export const USERPICTURE_SUCCESS = 'USERPICTURE_SUCCESS';
export const USERPICTURE_FAILURE = 'USERPICTURE_FAILURE';

export const get_user_picture = ()=>{
    return dispatch=>{
        dispatch({
            type:'USERPICTURE_REQUEST'
        });

        myaxios.get(`ob/employee/self/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'USERPICTURE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERPICTURE_FAILURE',
                payload:error
            })
        })
    }
}