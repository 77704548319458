import * as createcompany from '../action/create_company';


const initialState ={
    isLoading:false,
    errors:null,
    name:[],
    data:null
}


const create_company = (state=initialState,action) =>{
    switch(action.type) {
        case createcompany.CREATECOM_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case createcompany.CREATECOM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case createcompany.CREATECOM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data,
            }
        case createcompany.CREATECOM_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default create_company;
