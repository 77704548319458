import * as claimvalidate from '../../action/employee/employee_claim_apply_validate';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}

const employee_claim_apply_validate = (state=initialState,action)=>{
    switch(action.type){
        case claimvalidate.EMPCLAIMAPPLYVALIDATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case claimvalidate.EMPCLAIMAPPLYVALIDATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case claimvalidate.EMPCLAIMAPPLYVALIDATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default employee_claim_apply_validate;
