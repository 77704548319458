import * as importcas from '../../action/company/import_cas';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const import_cas = (state=initialState,action)=>{
    switch(action.type){
        case importcas.IMPORTCAS_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case importcas.IMPORTCAS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case importcas.IMPORTCAS_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case importcas.IMPORTCAS_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default import_cas;