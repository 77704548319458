import myaxios from '../../axios';

export const GETCLAIMEMPNOSCHEME_REQUEST = 'GETCLAIMEMPNOSCHEME_REQUEST';
export const GETCLAIMEMPNOSCHEME_SUCCESS = 'GETCLAIMEMPNOSCHEME_SUCCESS';
export const GETCLAIMEMPNOSCHEME_FAILURE = 'GETCLAIMEMPNOSCHEME_FAILURE';


export const get_claim_employee_noscheme = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETCLAIMEMPNOSCHEME_REQUEST'
        });

        myaxios.get('claim/noscheme/')
        .then((response)=>{
            dispatch({
                type:'GETCLAIMEMPNOSCHEME_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETCLAIMEMPNOSCHEME_FAILURE',
                payload:error
            })
        })
    }
}