import myaxios from '../../axios';

export const GETCLAIMOPTION_REQUEST = 'GETCLAIMOPTION_REQUEST';
export const GETCLAIMOPTION_SUCCESS = 'GETCLAIMOPTION_SUCCESS';
export const GETCLAIMOPTION_FAILURE = 'GETCLAIMOPTION_FAILURE';

export const get_claim_option = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETCLAIMOPTION_REQUEST'
        });

        myaxios.get('claim/base_setting/')
        .then((response)=>{
            dispatch({
                type:'GETCLAIMOPTION_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETCLAIMOPTION_FAILURE',
                payload:error
            })
        })
    }
}