import myaxios from '../axios';
export const REQUEST_COMPANYDROPDOWN = 'REQUEST_COMPANYDROPDOWN';
export const GET_COMPANYDROPDOWN = 'GET_COMPANYDROPDOWN';
export const FAILURE_COMPANYDROPDOWN = 'FAILURE_COMPANYDROPDOWN';


export const getCompanyDropdown = ()=>{
    return dispatch =>{
        dispatch({
            type: 'REQUEST_COMPANYDROPDOWN'
        });
        myaxios.get('company/company/dropdown/')
        .then((response)=>{
            dispatch({
                type: 'GET_COMPANYDROPDOWN',
                payload:response
                
            })
        })
        .catch((error) =>{
            dispatch({
                type: 'FAILURE_COMPANYDROPDOWN',
                payload: error,
                error:true
            })  
        })
    }
}
