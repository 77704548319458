import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Row } from 'reactstrap';
import { IMLocalized } from '../../language/IMLocalized';
import { get_payroll_instance } from '../../action/payroll/get_payroll_instance';
import ModalViewPayout from './modal/ModalViewPayout';
import ModalPayoutDetail from './modal/ModalPayoutDetail';
import DownloadPayslipConsolidatedButton from './button/DownloadPayslipConsolidatedButton';
import DownloadPayslipButton from './button/DownloadPayslipButton';
import PayrollDetailHistory from './PayrollDetailHistory';
import ModalUOB from './modal/ModalUOB';
import { get_payment_uob } from '../../action/payroll/get_payment_uob';
import { download_bankpayment } from '../../action/payroll/payroll_downloadbankpayment';
import {download_cpf} from '../../action/payroll/payroll_printcpf';
import PayrollPaymentAggrid from './PayrollPaymentAggrid';
import { usePrevious } from '../../hook/usePrevious';
import OverlayLoading from '../loading_component/overlay_loading';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

const mapStateToProps = (state) =>({
    payrolllist:state.get_payroll_list.data,
    list:state.get_payroll_instance.data,
    update_success:state.payment_update_payroll_payment.data,
    isLoading:state.get_payroll_instance.isLoading,
    isLoading1:state.payment_update_payroll_payment.isLoading,
})

const mapDispatchToProps = (dispatch) =>({
    get_payment_uob:(id)=>{
        dispatch(get_payment_uob(id))
    },
    download_bankpayment:(id,msg)=>{
        dispatch(download_bankpayment(id,msg))
    },
    download_cpf:(id,msg)=>{
        dispatch(download_cpf(id,msg))
    },
    get_payroll_instance:(id)=>{
        dispatch(get_payroll_instance(id))
    }
})

const initialState ={
    select_payroll:'',
    payroll_date:'',
    status_display:'',
    additional_toggle:false,
    view_payout_toggle:false,
    payout_detail_id:null,
    payout_detail_toggle:false,
    uob_toggle:false,
}

function PayrollPayment(props){
    const {update_success,get_payroll_instance} = props;
    const [state,setState] = useState(initialState);
    const { addItem } = useContext(NewMainContext);
    const [monthNames] = useState([IMLocalized("january"), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")]);
    const prevUpdate = usePrevious(update_success);

    useEffect(()=>{
        if(prevUpdate !== undefined && prevUpdate !== update_success && update_success !== null ){
            get_payroll_instance(props.id)
        }
    },[prevUpdate,update_success,props.id,get_payroll_instance])

    useEffect(()=>{
        // get_payroll_instance(props.id);
    },[props.id,get_payroll_instance])

    useEffect(()=>{
        if(props.list.length !== 0){
            const item = props.list[0];
            const {payroll_date,status} = item;
            const month1 = new Date(payroll_date).getMonth();
            const month = monthNames[month1];
            const year = new Date(payroll_date).getFullYear();
            const date1 = `${month} ${year}`;

            setState(prevState=>({...prevState,status_display:status,payroll_date:date1}))
        }
    },[props.list,monthNames])


    let prev_btn = '';
    if(props.prev_item.payroll !== '' && props.prev_item.payroll_name !== ''){
        const month1 = new Date(props.prev_item.payroll_name).getMonth();
        const month = monthNames[month1];
        const year = new Date(props.prev_item.payroll_name).getFullYear();
        prev_btn = <button className="btn btn-primary btn-sm" onClick={()=>props.payrollInfo(props.prev_item.payroll)} >{month} {year}</button>;
    }

    let next_btn = '';
    if(props.next_item.payroll !== '' && props.next_item.payroll_name !== ''){
        const month1 = new Date(props.next_item.payroll_name).getMonth();
        const month = monthNames[month1];
        const year = new Date(props.next_item.payroll_name).getFullYear();
        next_btn = <button className="btn btn-primary btn-sm" onClick={()=>props.payrollInfo(props.next_item.payroll)}>{month} {year}</button>;
    }

    return(
        <>
        {props.isLoading || props.isLoading1 ? <OverlayLoading/> :
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('payroll'), onClick:()=>props.handlePayroll({step:'0'})},
                        { active: true, title: state.payroll_date },
                        { active: true, title:IMLocalized('payment1')}
                    ]}/>
                    <div className='d-flex justify-content-between ml-2'>
                        <Input size="sm" style={{width:'150px'}} value={state.select_payroll} type="select" onChange={(e)=>{
                            const value = e.target.value;
                            props.payrollInfo(value);
                        }}>
                            <option>{IMLocalized('select_month')}</option>
                            {props.payrolllist.map((item,index)=>{
                                const date = item.payroll_date;
                                const month1 = new Date(date).getMonth();
                                const month = monthNames[month1];
                                const year = new Date(date).getFullYear();
                                return <option value={item.id} key={index}>{month} {year}</option>
                            })}
                        </Input>
                        {state.status_display !== '' &&
                        <small className='mr-2'>{IMLocalized('status')} : {IMLocalized(state.status_display)}</small>}
                    </div>
                    <div className='mt-1 ml-2 d-flex justify-content-between'>
                        <div>
                            <button className="btn btn-primary btn-sm" onClick={()=>props.handlePayroll({step:'0'})}> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                            {props.prev_item.payroll_name !== '' && props.prev_item.payroll !== '' && prev_btn }
                            {props.next_item.payroll_name !== '' && props.next_item.payroll !== '' && next_btn }
                        </div>
                        <Dropdown size="sm" direction='left' toggle={()=>setState(prevState=>({...prevState,additional_toggle:!state.additional_toggle}))} isOpen={state.additional_toggle}>
                            <DropdownToggle>
                            {IMLocalized('more_action')}  <i className="fas fa-ellipsis-v"></i> 
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={()=>setState(prevState=>({...prevState,view_payout_toggle:true}))}>{IMLocalized('view_payout')}</DropdownItem>
                                <DropdownItem onClick={()=>props.handlePayroll({step:'3',id:props.id})}>{IMLocalized('report')}</DropdownItem>
                                <DownloadPayslipButton/>
                                <DownloadPayslipConsolidatedButton />
                                {state.status_display === 'COMPLETE' || state.status_display === 'PAID' ? 
                                <>
                                <DropdownItem
                                onClick={()=>{
                                    let payroll_date ='';
                                    if(props.list.length !== 0){
                                        const item = props.list[0];
                                        const date = item.payroll_date;
                                        const month = new Date(date).getMonth();
                                        const month1 = monthNames[month];
                                        const year = new Date(date).getFullYear();
                                        payroll_date = month1 + ' ' + year ;
                                    }
                                    const msg = `BankPayment - ${payroll_date}`;
                                    props.download_bankpayment(props.id,msg);
                                }}
                                >{IMLocalized('download_bank_payment')}</DropdownItem>
                                <DropdownItem
                                onClick={()=>{
                                    let payroll_date ='';
                                    if(props.list.length !== 0){
                                        const item = props.list[0];
                                        const date = item.payroll_date;
                                        const month = new Date(date).getMonth();
                                        const month1 = monthNames[month];
                                        const year = new Date(date).getFullYear();
                                        payroll_date = month1 + ' ' + year ;
                                    }

                                    const msg = `CPF - ${payroll_date}`;
                                    props.download_cpf(props.id,msg);
                                }}>{IMLocalized('download_cpf_esubmission')}</DropdownItem>
                                </>
                                : null}
                                <DropdownItem onClick={()=>{setState(prevState=>({...prevState,uob_toggle:true})); props.get_payment_uob(props.id)  }}>Show UOB Payment</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
            <PayrollPaymentAggrid id={props.id} toPayrollPayslip={props.toPayrollPayslip} />
            <PayrollDetailHistory id={props.id} />
        </Container>
        <ModalViewPayout toggle={state.view_payout_toggle} closeModal={()=>setState(prevState=>({...prevState,view_payout_toggle:false}))} 
        toViewDetail={(id)=>{setState(prevState=>({...prevState,payout_detail_toggle:true,payout_detail_id:id})); }} />
        <ModalPayoutDetail toggle={state.payout_detail_toggle} id={state.payout_detail_id} closeModal={()=>setState(prevState=>({...prevState,payout_detail_id:null,payout_detail_toggle:false}))} />
        <ModalUOB toggle={state.uob_toggle} closeToggle={()=>setState(prevState=>({...prevState,uob_toggle:false}))} />
        </>}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(PayrollPayment);