import myaxios from '../../axios';
export const REQUEST_EMPPASSPORT = 'REQUEST_EMPPASSPORT';
export const GET_EMPPASSPORT = 'GET_EMPPASSPORT';
export const FAILURE_EMPPASSPORT = 'FAILURE_EMPPASSPORT';


export const get_employee_passport = ()=>{
    return dispatch =>{
        dispatch({
            type: 'REQUEST_EMPPASSPORT'
        });
        myaxios.get('employee/passport/')
        .then((response)=>{
            dispatch({
                type: 'GET_EMPPASSPORT',
                payload:response
                
            })
        })
        .catch((error) =>{
            dispatch({
                type: 'FAILURE_EMPPASSPORT',
                payload: error,
            })
        });
    }
}
