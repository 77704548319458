import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEPAYPERIOD_REQUEST1 ='UPDATEPAYPERIOD_REQUEST1';
export const UPDATEPAYPERIOD_SUCCESS1 ='UPDATEPAYPERIOD_SUCCESS1';
export const UPDATEPAYPERIOD_FAILURE1 ='UPDATEPAYPERIOD_FAILURE1';

export const wizard_update_pay_period =(data)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEPAYPERIOD_REQUEST1'
        });
        return myaxios.post(`payroll/basesetting/`,data)
        .then((response)=>{
            dispatch({
                type:'UPDATEPAYPERIOD_SUCCESS1',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEPAYPERIOD_FAILURE1',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('update_failure')
            })
        })
    }
}