import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const UPDATESCHEDULE_REQUEST = 'UPDATESCHEDULE_REQUEST'; 
export const UPDATESCHEDULE_SUCCESS = 'UPDATESCHEDULE_SUCCESS';
export const UPDATESCHEDULE_FAILURE = 'UPDATESCHEDULE_FAILURE';

export const update_schedule = (id,name,start_year,working_hours,hours_in_year,calendar_array,holiday_list)=>{
    return dispatch =>{
        dispatch({
            type: 'UPDATESCHEDULE_REQUEST'
        });

        myaxios.patch(`contract/schedules/${id}/`,
        {
            name:name,
            starting_year:start_year,
            working_hours:working_hours,
            hours_in_year:hours_in_year,
            calendar_array:calendar_array,
            holiday_list
        })
        .then((response)=>{
            dispatch({
                type:'UPDATESCHEDULE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATESCHEDULE_FAILURE',
                payload:error,
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('update_failure')
            });
        })

    }
}