import myaxios from '../../axios';

export const MONTHLYPAYROLLINSTANCE_REQUEST = 'MONTHLYPAYROLLINSTANCE_REQUEST';
export const MONTHLYPAYROLLINSTANCE_SUCCESS = 'MONTHLYPAYROLLINSTANCE_SUCCESS';
export const MONTHLYPAYROLLINSTANCE_FAILURE = 'MONTHLYPAYROLLINSTANCE_FAILURE';

export const monthly_payroll_instance = (id) =>{
    return dispatch=>{
        dispatch({
            type: 'MONTHLYPAYROLLINSTANCE_REQUEST'
        });

        myaxios.get(`payroll/payroll/${id}/`)
        .then((response)=>{
            dispatch({
                type:'MONTHLYPAYROLLINSTANCE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'MONTHLYPAYROLLINSTANCE_FAILURE',
                payload:error.response
            })
        })
    }
}