import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, FormGroup, Input, Row, Table } from "reactstrap";
import { create_claim_scheme, reset_create_claim_scheme } from "../../action/claim/create_claim_scheme";
import { get_claim_list } from "../../action/claim/get_claim_list";
import { get_claim_scheme_list } from "../../action/claim/get_claim_scheme_list";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";



export default function ClaimCreateSchemeList({ setStep }){

    const list = useSelector(state=>state.get_claim_list.data);
    const errors = useSelector(state=>state.create_claim_scheme.errors);
    const success = useSelector(state=>state.create_claim_scheme.data);
    const dispatch = useDispatch();

    const { addItem } = useContext(NewMainContext);
    const [ data, setData] = useState([]);
    const [ setting, setSetting] = useState(false);
    const [ select_all, setSelectAll] = useState(true);
    const [ name, setName ] = useState('');
    const [ err_name, setErrName] = useState('');
    const [ submit_arr, setSubmitArr] = useState([]);
    const schemeRef = useRef([]);

    useEffect(()=>{
        if(success != null){
            dispatch(get_claim_list());
            dispatch(get_claim_scheme_list());
            dispatch(reset_create_claim_scheme());
            setName('');
        }
    },[dispatch,success])

    useEffect(()=>{

        function compareArray(submit){
            const arr1 = [...data];
            let first = false;
            for( let i=0; i<submit.length; i++){
                const claim_id = submit[i].claim_type;
                for(let x =0; x<arr1.length; x++){
                    const { claim_type } = arr1[x];
                    if(claim_type === claim_id){
                        arr1[x].err_amount = submit[i].err_amount;
                        arr1[x].err_age = submit[i].err_age;
                        if((submit[i].err_amount.length !== 0 || submit[i].err_age.length !== 0) && !first){
                            schemeRef.current[x].scrollIntoView({ block:'start', behavior:'smooth'});
                            first = true;
                        }
                    }
                }
            }
        }
        
        if(errors != null){
            if(errors.hasOwnProperty('name')){
                const word = errors['name'].toString();
                setErrName(word);
            }

            if(errors.hasOwnProperty('claim_entitlement')){
                const { claim_entitlement } = errors;
                const arr = [];
                for(let i = 0 ; i < claim_entitlement.length; i++){
                    if(Object.entries(claim_entitlement[i].length !== 0)){
                        const age = claim_entitlement[i].age_tier;
                        const amount = claim_entitlement[i].amount_tier;
                        const {claim_type} = submit_arr[i];
                        let err_age = age !== undefined ? age.toString() : '';
                        let err_amount = amount !== undefined ? amount.toString() : '' ;
                        arr.push({ claim_type, err_age, err_amount});
                    }
                }
                compareArray(arr);
            }
            dispatch(reset_create_claim_scheme());
        }
    },[errors,dispatch,data,submit_arr])

    useEffect(()=>{

        if(list.length !== 0 ){
            const arr = list
            .sort((a,b)=> a.id - b.id)
            .map((item)=>{
                const {id, name } = item;
                const claim_type = id;
                const age_tier = [99, 0, 0, 0];
                const amount_tier = [0, 0, 0, 0];
                const employee_percent = 100;
                const dependent_percent = 0;
                const err_employee_percent = '';
                const err_dependent_percent = '';
                const err_non = '';
                const err_amount ='';
                const err_age = '';
                const is_monthly = false;
                const checked = true;

                return { name, err_non, err_amount, err_dependent_percent, err_employee_percent, claim_type, is_monthly, employee_percent, dependent_percent, age_tier, amount_tier, err_age, checked};
            })
            setData(arr);
        }
    },[list])

    const handleAll = (e)=>{
        const { checked } = e.target;
        setSelectAll(checked);

        const arr = [...data];
        const new_arr = arr.map((item)=>{
            return {...item, checked}
        })
        setData(new_arr);
    }

    const handleCheckbox=(index)=>{
        const arr = [...data];
        arr[index].checked = !arr[index].checked;
        setData(arr);

        let checked = arr.every(element => element.checked === true);
        setSelectAll(checked);
    }

    const handleValue=(e,index)=>{
        const { name, value} = e.target;
        const arr = [...data];
        arr[index][name] = value;
        setData(arr);
    }

    const handleEmpty = (e,index)=>{
        const { name, value} = e.target;
        const arr = [...data];
        if(value === ''){
            arr[index][name] = 0;
        }
        setData(arr);
    }

    const handleCheckboxChange=(e,index)=>{
        const arr = [...data];
        arr[index].is_monthly = e.target.checked;
        setData(arr);
    }

    const handleInput=( e, index, index1)=>{
        const {name, value} = e.target;
        const arr = [...data];
        arr[index][name][index1] = value;
        setData(arr);
    }

    const handleEmpty2 =( e, index, index1)=>{
        const {name, value} = e.target;
        const arr = [...data];
        if(value === ''){
            arr[index][name][index1] = 0;
            setData(arr);
        }
    }

    const addRow = (index)=>{
        const arr = [...data];
        arr[index].age_tier.push(0);
        arr[index].amount_tier.push(0);

        setData(arr);
    }

    const removeRow = (index)=>{
        const arr = [...data];
        if(arr[index].age_tier.length >1){
            arr[index].age_tier.pop();
            arr[index].amount_tier.pop();
    
            setData(arr);
        }
        
    }

    const onSubmit = ()=>{
        if(name !== ''){
            const arr = data.filter(item=>item.checked === true);
            dispatch(create_claim_scheme(name,arr));
            setSubmitArr(arr);
        }
        else{
            setErrName(IMLocalized('name_is_required'));
        }
    }

    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem><a href="/#" onClick={()=>setStep(0)}>{IMLocalized('claim_scheme_list')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('create')}</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="ml-2">
                        <button onClick={()=>setStep(0)} className="btn btn-primary btn-sm"> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('new_claim_scheme')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm" onClick={onSubmit}>{IMLocalized('create')}</button>
                                    <Dropdown size="sm" direction="left" isOpen={setting} toggle={()=>setSetting(!setting)}>
                                        <DropdownToggle className="px-1 py-0 m-0" aria-expanded={setting}>
                                            <i className="ni ni-settings-gear-65" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-scrll p-2">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" name="select_all" id="claimcreateschemelistall" className="custom-control-input" checked={select_all} onChange={(e)=>handleAll(e)} />
                                                <label className="custom-control-label" htmlFor="claimcreateschemelistall">{IMLocalized('select_all')}</label>
                                            </div>
                                            <DropdownItem divider />
                                            {data.map((item,index)=>{
                                                return(
                                                    <div className="custom-control custom-checkbox" key={item.claim_type}>
                                                        <input type="checkbox" id={"claimcreateschemelist"+index} className="custom-control-input"  checked={item.checked} onChange={()=>handleCheckbox(index)} />
                                                        <label className="custom-control-label" htmlFor={"claimcreateschemelist"+index}>{item.name}</label>
                                                    </div>
                                                )
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="claim_create_scheme_name">{IMLocalized('name')}</label>
                                        <Input size="sm" type="text" id="claim_create_scheme_name" value={name} invalid={err_name.length >0} onChange={(e)=>{ setName(e.target.value); setErrName('') }} />
                                        <FormFeedback>{err_name}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {data.filter(el=>el.checked === true).map((item,index)=>{
                        const {amount_tier, age_tier, name, err_employee_percent, err_dependent_percent, err_age, err_amount, err_non, employee_percent, dependent_percent,is_monthly } = item;
                        return(
                            <div ref={el => (schemeRef.current[index] = el)} key={item.id}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <h4 className="mb-0">{name}</h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor={"employee_percent"+index}>{IMLocalized('employee_percent')} (%)</label>
                                                    <Input size="sm" type="number" id={"employee_percent"+index} name="employee_percent" min="0" max="100" value={employee_percent} onChange={(e)=>handleValue(e,index)} onBlur={(e)=>handleEmpty(e,index)} invalid={err_employee_percent.length >0} />
                                                    <FormFeedback>{err_employee_percent}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor={"dependent_percent"+index}> {IMLocalized('dependent_percent')} (%)</label>
                                                    <Input size="sm" type="number" id={"dependent_percent"+index} name="dependent_percent" min='0' max='100' value={dependent_percent} onChange={(e)=>handleValue(e,index)} onBlur={(e)=>handleEmpty(e,index)} invalid={err_dependent_percent.length >0}  />
                                                    <FormFeedback>{err_dependent_percent}</FormFeedback>
                                                </FormGroup> 
                                            </Col>
                                            <Col lg="4" className="d-flex align-items-center">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" id={'claim_scheme_create_monthly'+index} name='is_monthly' className="custom-control-input" checked={is_monthly} onChange={(e)=>handleCheckboxChange(e,index)} />
                                                    <label className="custom-control-label" htmlFor={'claim_scheme_create_monthly'+index}>{IMLocalized('is_monthly')}</label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                {err_age.length !== 0 &&
                                                <div>
                                                    <small className="text-danger">{IMLocalized('claim_scheme_year')} : {err_age}</small>
                                                </div>}
                                                {err_amount.length !== 0 &&
                                                <div>
                                                    <small className="text-danger">{IMLocalized('claim_scheme_amount')} : {err_amount}</small>
                                                </div>}
                                                {err_non.length !== 0 &&
                                                <div>
                                                    <small className="text-danger">{err_non}</small>
                                                </div>}
                                            </Col>
                                            <Col className="col-auto">
                                                <button className="btn btn-primary btn-sm min_width_50_btn" onClick={()=>addRow(index)} >{IMLocalized('add')}</button>
                                                <button className="btn btn-danger btn-sm min_width_50_btn" onClick={()=>removeRow(index)}>{IMLocalized('delete')}</button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Table className="align-items-center table-bordered" responsive>
                                                    <thead className="thead-light text-center">
                                                        <tr>
                                                            <th>{IMLocalized('year_in_company')}</th>
                                                            <th>{IMLocalized('claim_entitlement_amount')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-center">
                                                        {amount_tier.map((el,index1)=>{
                                                            const age = age_tier[index1];
                                                            return(
                                                                <tr key={index1}>
                                                                    <td>
                                                                        <div className="d-flex justify-content-center">
                                                                            <Input type="number" size="sm" min="0" className="text-center" style={{width:'100px'}} name="age_tier" value={age} onChange={(e)=>handleInput(e,index,index1)} onBlur={(e)=>handleEmpty2(e,index,index1)} />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex justify-content-center">
                                                                            <Input type="number" size="sm" min="0" className="text-center" style={{width:'100px'}} name="amount_tier" value={el} onChange={(e)=>handleInput(e,index,index1)} onBlur={(e)=>handleEmpty2(e,index,index1)} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        )
                    })}
                </Col>
            </Row>
        </Container>
        </>
    )
}