import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELETEAPPRAISALLIST_REQUEST = 'DELETEAPPRAISALLIST_REQUEST';
export const DELETEAPPRAISALLIST_SUCCESS = 'DELETEAPPRAISALLIST_SUCCESS';
export const DELETEAPPRAISALLIST_FAILURE = 'DELETEAPPRAISALLIST_FAILURE';
export const DELETEAPPRAISALLIST_RESET = 'DELETEAPPRAISALLIST_RESET';


export const delete_appraisal_list = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELETEAPPRAISALLIST_REQUEST'
        });

        myaxios.delete(`forms/forms/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELETEAPPRAISALLIST_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELETEAPPRAISALLIST_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_appraisal_list = () => dispatch =>{
    dispatch({
        type:'DELETEAPPRAISALLIST_RESET'
    });
}