import * as leaveoption from '../action/update_leave_option';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const update_leave_option = (state=initialState,action)=>{
    switch(action.type){
        case leaveoption.UPDATELEAVEOPTION_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case leaveoption.UPDATELEAVEOPTION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case leaveoption.UPDATELEAVEOPTION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case leaveoption.UPDATELEAVEOPTION_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default update_leave_option