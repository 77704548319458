import myaxios from '../../axios';
export const GETCLAIMLIST_REQUEST = 'GETCLAIMLIST_REQUEST';
export const GETCLAIMLIST_SUCCESS = 'GETCLAIMLIST_SUCCESS';
export const GETCLAIMLIST_FAILURE = 'GETCLAIMLIST_FAILURE';

export const get_claim_list =()=>{
    return dispatch=>{
        dispatch({
            type:'GETCLAIMLIST_REQUEST'
        });

        myaxios.get('claim/type/')
        .then((response)=>{
            dispatch({
                type:'GETCLAIMLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETCLAIMLIST_FAILURE',
                payload:error
            })
        })
    }
}