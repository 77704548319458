import * as updatebranch from '../../action/company/update_branch';


const initialState ={
    isLoading:false,
    errors:null,
    data:null
}

const update_branch = (state=initialState,action) =>{
    switch(action.type) {
        case updatebranch.UPDATEBRANCH_REQUEST:
            return {
                ...state,
                isLoading:true,
                data:null,
                errors:null
            };
        case updatebranch.UPDATEBRANCH_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updatebranch.UPDATEBRANCH_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default update_branch;
