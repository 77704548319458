import * as delbranch from '../../action/company/delete_branch';

const initialState ={
   errors:null,
   data:null,
   isLoading:false
}


const delete_branch = (state=initialState,action) =>{
    switch(action.type) {
        case delbranch.DELBRANCH_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case delbranch.DELBRANCH_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delbranch.DELBRANCH_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case delbranch.DELBRANCH_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:            
            return state;
    }
}
export default delete_branch;


