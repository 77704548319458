import myaxios from '../../axios';
export const MONTHLYREPORTDATEFILTER_REQUEST = 'MONTHLYREPORTDATEFILTER_REQUEST';
export const MONTHLYREPORTDATEFILTER_SUCCESS = 'MONTHLYREPORTDATEFILTER_SUCCESS';
export const MONTHLYREPORTDATEFILTER_FAILURE = 'MONTHLYREPORTDATEFILTER_FAILURE';

export const monthly_report_date_filter=(from_date,to_date)=>{
    return dispatch=>{
        dispatch({
            type:'MONTHLYREPORTDATEFILTER_REQUEST'
        });

        myaxios.post('payroll/payroll/ReportDateFiler/',{
            from_date:from_date,
            to_date:to_date
        })
        .then((response)=>{
            dispatch({
                type:'MONTHLYREPORTDATEFILTER_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'MONTHLYREPORTDATEFILTER_FAILURE',
                payload:error
            })
        })
    }
}