import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import { reset_get_user_leave_detail2 } from "../../../../action/user/get_user_leave_detail2";
import { user_apply_leave_list } from "../../../../action/user/user_apply_leave_list";
import { user_get_all_apply_leave_list } from "../../../../action/user/user_get_all_apply_leave_list";
import { user_get_all_leave_list } from "../../../../action/user/user_get_all_leave_list";
import { user_get_available_leave } from "../../../../action/user/user_get_available_leave";
import { reset_user_leave_cancel_apply_leave, user_leave_cancel_apply_leave } from "../../../../action/user/user_leave_cancel_apply_leave";
import { getDateFormat } from "../../../../func/getDateFormat";
import { IMLocalized } from "../../../../language/IMLocalized";


export default function ModalCalendarViewLeaveDetail({ toggle, closeModal}){

    const detail = useSelector(state=>state.get_user_leave_detail2.data);
    const isLoading = useSelector(state=>state.get_user_leave_detail2.isLoading);
    const emp_data = useSelector(state=>state.user_self.data);
    const cancel_success = useSelector(state=>state.user_leave_cancel_apply_leave.data);
    const [ hasCancel, setHasCancel] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(toggle && cancel_success != null){
            dispatch(reset_user_leave_cancel_apply_leave());
            dispatch(user_apply_leave_list());
            dispatch(user_get_available_leave());
            dispatch(user_get_all_leave_list());
            dispatch(user_get_all_apply_leave_list());
            closeModal();
        }
    },[toggle,cancel_success,dispatch,closeModal])

    useEffect(()=>{
        if(!toggle){
            dispatch(reset_get_user_leave_detail2());
            setHasCancel(false);
        }
    },[toggle, dispatch])

    useEffect(()=>{
        if(toggle && detail && emp_data.length !== 0 ){
            if(detail?.employee.id === emp_data?.id && detail?.status === 'PENDING'){
                setHasCancel(true);
            }
        }
        else{
            setHasCancel(false);
        }
    },[toggle,detail,emp_data])

    const cancelLeave=()=>{
        Swal.fire({
            title:IMLocalized('cancelling_leave'),
            input:'textarea',
            text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then(result=>{
            if('value' in result){
                let memo = result.value;
                let arr = [detail?.id];
                dispatch(user_leave_cancel_apply_leave(arr,memo));
            }
        })
    }


    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('leave_detail')}</ModalHeader>
            <ModalBody>
                {isLoading && <span>{IMLocalized('loading')}..</span>}
                {!isLoading && detail && 
                <>
                <Row>
                    <Col className="d-flex jusitfy-content-center">
                        <Table size="sm" className="align-items-center bg-white table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th className='td-w-30'>{IMLocalized('date')}</th>
                                    <th>{IMLocalized('option')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detail.days.map((item,index)=>{
                                    const date = getDateFormat(item.date);
                                    const day = IMLocalized(item.day);
                                    return(
                                        <tr key={index}>
                                            <td>{date}</td>
                                            <td>{day}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Table size="sm" className="table-bordered">
                    <tbody>
                        <tr>
                            <td className='td-w-30'>{IMLocalized('employee_number')}</td>
                            <td>{detail.employee.employee_number}</td>
                        </tr>
                        <tr>
                            <td >{IMLocalized('employee_name')}</td>
                            <td>{detail.employee.name}</td>
                        </tr>
                        <tr>
                            <td>{IMLocalized('leave_type')}</td>
                            <td>{detail.leave_setting.name}</td>
                        </tr>
                        <tr>
                            <td>{IMLocalized('status')}</td>
                            <td>{IMLocalized(detail.status)}</td>
                        </tr>
                        <tr>
                            <td>{IMLocalized('leave_amount')}</td>
                            <td>{detail.amount}</td>
                        </tr>
                    </tbody>
                </Table>
                </>}
            </ModalBody>
            <ModalFooter>
                {hasCancel && <button className="btn btn-danger btn-sm" onClick={()=>cancelLeave()}>{IMLocalized('cancel')}</button>}
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}