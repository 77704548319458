import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { NewMainContext } from '../../../context/NewMainContext';
import { IMLocalized } from '../../../language/IMLocalized';

const mapStateToProps=(state)=>({
    leavelist:state.get_leave_list.data,
    isLoading:state.get_leave_list.isLoading,
    isLoading1:state.user_get_available_leave.isLoading,
})

function LeaveTable(props){

    const { setLeaveCalendarApplyDetail, addItem, current_user } = useContext(NewMainContext);

    return(
        <Row>
            <Col>
                <Card style={{height:'270px'}}>
                    <CardHeader className="border-0 p-2 bg-white">
                        <h3 className='title-color1 pl-2 pt-2'>
                            {IMLocalized('leave_balance')}
                        </h3>
                    </CardHeader>
                    {props.isLoading || props.isLoading1 ? <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody> : null }
                    {!props.isLoading && !props.isLoading1 && props.list.length === 0 &&
                    <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                    {!props.isLoading && !props.isLoading1 && props.list.length !== 0 && 
                    <Table size="sm" className='align-items-center' responsive>
                        <thead className='thead-light'>
                            <tr>
                                <th>{IMLocalized('leave_type')}</th>
                                <th className="text-center">{IMLocalized('remaining')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.list.map((item,index)=>{
                                const current_month = new Date().getMonth();
                                const leave_detail = props.leavelist.find(element=>element.id === item.leave_setting);
                                return(
                                    <tr key={index}>
                                        <td><a href="/#" onClick={()=>{
                                            if(current_user === 'hr'){
                                                addItem('24','leave_calendar1');
                                            }
                                            // else if(current_user === 'manager' || current_user === 'user'){
                                            //     addItem('5','self_leave');
                                            // }
                                            else if(current_user === 'manager'){
                                                addItem('24','leave_calendar');
                                            }
                                            else if(current_user === 'user'){
                                                addItem('5','self_leave');
                                            }
                                            else if(current_user === 'leave_officer'){
                                                addItem('4','leave_calendar1')
                                            }
                                            setLeaveCalendarApplyDetail({leave_setting:item.leave_setting,employee:item.employee});
                                        }}>{leave_detail?.name ?? '-'}</a></td>
                                        <td className='text-center'>{item.leave_type === 'E' ? (item.remaining_monthly[current_month]).toFixed(2) : (item.remaining).toFixed(2)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>}
                </Card>
            </Col>
        </Row>
    )
}
export default connect(mapStateToProps,null)(LeaveTable);