import * as detail from '../../action/claim/claim_all_get_claim_detail';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const claim_all_get_claim_detail = (state=initialState,action)=>{
    switch(action.type){
        case detail.CLAIMALLGETCLAIMDETAIL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case detail.CLAIMALLGETCLAIMDETAIL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case detail.CLAIMALLGETCLAIMDETAIL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case detail.CLAIMALLGETCLAIMDETAIL_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default claim_all_get_claim_detail;