import React from 'react';
import {IMLocalized} from '../../../language/IMLocalized';


function ClaimApprovalTable(props){

    const arr = props.list.filter(item=>item.claim.status === 'PENDING');
    return(
        <div className="card-single cursor-pointer bg-orange" onClick={(!props.drag) ? ()=>props.toApprovalClaim() :null} >
            <div>
                <h1 className="text-white">{arr.length}</h1>
                <span className="text-white">{IMLocalized('claim_pending_for_approval')}</span>
            </div>
            <div>
                <i className="fas fa-money-check text-white dashboard_text" />
            </div>
        </div>
    )
}
export default ClaimApprovalTable;