import React, { useContext, useEffect, useState } from "react";
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { WizardHolidayContext } from "../../../context/WizardHolidayContext";
import { IMLocalized } from "../../../language/IMLocalized";


export default function ModalAddHolidayList({toggle,closeModal,setData}){
    const { list, setList, setStep, setHolidayID } = useContext(WizardHolidayContext);
    const [ name, setName] = useState('');
    const [ err_name, setErrName] = useState('');

    useEffect(()=>{
        if(toggle){
            setName('');
            setErrName('');
        }
    },[toggle])

    const addList = () =>{
        if(name !== ''){
            let count = 0;
            for( let i=0; i<list.length; i++){
                const n1 = list[i].name;
                if(name === n1){
                    count++;
                }
            }

            if(count === 0){
                setList(prevState=>([...prevState,{ name, holiday:[]}]));
                closeModal();
                setStep(1);
                setHolidayID(list.length);
            }
            else{
                setErrName(IMLocalized('duplicate_name'));
            }
        }
        else{
            setErrName(IMLocalized('name_is_required'));
        }
    }

    return(
        <Modal isOpen={toggle} size="md">
            <ModalHeader toggle={closeModal}>{IMLocalized('create_holiday_list')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="name">{IMLocalized('name')}</label>
                            <Input id="name" size="sm" value={name} invalid={err_name.length > 0 } onChange={(e)=>{ setName(e.target.value); setErrName('')}}   />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary btn-sm" onClick={addList}>{IMLocalized('create')}</button>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}