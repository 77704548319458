import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { connect, useDispatch } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { get_self_available_leave_options, reset_get_self_available_leave_options } from '../../../../action/user/get_self_available_leave_options';
import { getDateFormat } from '../../../../func/getDateFormat';
import { popError } from '../../../../func/popError';
import { IMLocalized } from '../../../../language/IMLocalized';
import { reset_user_apply_leave_self, user_apply_leave_self } from '../../../../action/user/user_apply_leave_self';
import { user_get_available_leave } from '../../../../action/user/user_get_available_leave';
import { user_apply_leave_list } from '../../../../action/user/user_apply_leave_list';
import { user_get_all_leave_list } from '../../../../action/user/user_get_all_leave_list';
import { user_get_all_apply_leave_list } from '../../../../action/user/user_get_all_apply_leave_list';

const mapStateToProps=(state)=>({
    approval_group:state.user_leave_approval_group.data,
    availablelist:state.user_get_available_leave.data,
    data:state.user_self.data,
    leavelist:state.get_leave_list.data,
    isLoading:state.get_self_available_leave_options.isLoading,
    isLoading1:state.user_apply_leave_self.isLoading,
    options:state.get_self_available_leave_options.data,
    options_errors:state.get_self_available_leave_options.errors,
    success:state.user_apply_leave_self.data,
    errors:state.user_apply_leave_self.errors,
})

const mapDispatchToProps=(dispatch)=>({
    get_self_available_leave_options:(employee,leave_setting,from_date,to_date)=>{
        dispatch(get_self_available_leave_options(employee,leave_setting,from_date,to_date))
    },
    user_apply_leave_self:(leave_options,pending,file,leave_entitlement,remarks)=>{
        dispatch(user_apply_leave_self(leave_options,pending,file,leave_entitlement,remarks))
    },
    user_apply_leave_list:()=>{
        dispatch(user_apply_leave_list())
    },
    user_get_all_leave_list:()=>{
        dispatch(user_get_all_leave_list())
    },
    user_get_all_apply_leave_list:()=>{
        dispatch(user_get_all_apply_leave_list())
    },
    user_get_available_leave:()=>{
        dispatch(user_get_available_leave())
    },
    reset_get_self_available_leave_options:()=>{
        dispatch(reset_get_self_available_leave_options())
    },
    reset_user_apply_leave_self:()=>{
        dispatch(reset_user_apply_leave_self())
    }
})

const initialState={
    startDate:null,
    start_date:null,
    endDate:null,
    end_date:null,
    focusedInput:null,
    err_select_date:'',
    comment:'',
    leave_setting:'',
    err_leave_setting:'',
    min:0,
    max:0,
    err_images:'',
    file:[],
    step:0,
    leave_options:[],
    leave_entitlement:null,
}

function ModalUserApplyLeave(props){

    const dispatch = useDispatch();
    const {options,options_errors,errors,success,closeModal} =props;
    const [state,setState]= useState(initialState);
    const [arr_approvers,setArrApprovers] = useState([]);
    const [leave_options,setLeaveOptions] = useState([]);

    useEffect(()=>{
        if(props.toggle && success !=null){
            dispatch(user_get_available_leave());
            dispatch(user_apply_leave_list());
            dispatch(user_get_all_leave_list());
            dispatch(user_get_all_apply_leave_list());
            dispatch(reset_user_apply_leave_self());
            closeModal();
        }
    },[props.toggle,success,closeModal,dispatch])

    useEffect(()=>{
        if(props.toggle && errors != null){
            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
            else if(errors.hasOwnProperty('leave_entitlement')){
                const word = errors['leave_entitlement'];
                const remaining = word['remaining'];
                const amount = word['amount'];
                const word1 = `${IMLocalized('not_enough_leave')} \n ${IMLocalized('leave_entitlement_error2',{amount,remaining})}`;
                popError(word1);
            }
            else if(errors.hasOwnProperty('upload_iamges')){
                const word = errors['upload_images'].toString();
                popError(word);
            }
            dispatch(reset_user_apply_leave_self());
        }   
    },[errors,props.toggle,dispatch])

    useEffect(()=>{
        if(props.toggle && options_errors != null){
            if(options_errors.hasOwnProperty('non_field_errors')){
                const word = options_errors['non_field_errors'].toString();
                popError(word);
            }
            else if(options_errors.hasOwnProperty('to_date')){
                const word = options_errors['to_date'].toString();
                popError(word);
            }
            else if(options_errors.hasOwnProperty('leave_setting')){
                const word = options_errors['leave_setting'].toString();
                popError(word);
            }
            dispatch(reset_get_self_available_leave_options());
            setState(prevState=>({...prevState,step:0}));
        }
    },[options_errors,props.toggle,dispatch])

    useEffect(()=>{
        if( options != null && props.toggle){
            setState(prevState=>({
                ...prevState,
                leave_entitlement:options.leave_entitlement
            }))
            dispatch(reset_get_self_available_leave_options());
            setLeaveOptions(options.leave_options);
        }
    },[options,props.toggle,dispatch])

    useEffect(()=>{
        if(props.toggle){
            setState({
                ...initialState,
                leave_setting:props.leave_id ?? '',
                startDate:props.startDate ?? null,
                start_date:props.start_date ?? null
            })
        }
    },[props.toggle,props.leave_id,props.startDate,props.start_date])

    useEffect(()=>{
        if(props.leavelist.length !== 0 && state.leave_setting !== ''){
            const detail = props.leavelist.find(item=>item.id === parseInt(state.leave_setting));
            setState(prevState=>({
                ...prevState,
                min:detail?.minimum_images ?? 0,
                max:detail?.maximum_images ?? 0,
            }))
        }
    },[props.leavelist,state.leave_setting])

    useEffect(()=>{
        let arr = [];
        if(props.approval_group != null && state.leave_setting !== '' ){
            const {exceptions,approvers} = props.approval_group;
            const approval_detail = exceptions.find(item=>item.leave_setting === parseInt(state.leave_setting));
            const group = approval_detail?.group ?? null;
            if(group !== null){
                const approver = approvers[group];
                for(let j=0;j<approver.length;j++){
                    const data = approver[j];
                    for(const emp_data of data){
                        const employee = emp_data.name;
                        const ordering = j+1;
                        arr.push({employee,ordering});
                    }
                }
            }
        }
        setArrApprovers(arr);
    },[state.leave_setting,props.approval_group])

    const onDatesChange=({startDate,endDate})=>{

        let start_date = null;
        let end_date = null;
        if(startDate !== null){
            let startdate =startDate._d;
            let startmonth = (startdate.getMonth()+1);
            if(startmonth <10){
                startmonth = '0'+startmonth;
            }
            let startday = startdate.getDate();
            if(startday<10){
                startday = '0'+startday;
            }
            start_date = startdate.getFullYear()+'-' + startmonth+'-' +startday;
        }

        if(endDate !== null){
            let enddate = endDate._d;
            let endmonth =(enddate.getMonth()+1);
            if(endmonth <10){
                endmonth = '0'+endmonth;
            }
            let endday = enddate.getDate();
            if(endday<10){
                endday = '0'+endday
            }
            end_date = enddate.getFullYear()+'-'+endmonth+'-'+endday
        }

        setState(prevState=>({...prevState,startDate,endDate,end_date,start_date,err_select_date:'',step:0,leave_options:[],leave_entitlement:null}));
    }

    const loadDay=()=>{
        const emp = props.data?.id;
        if(emp != null && state.start_date !== null && state.leave_setting !== ''){
            const to_date = state.end_date ?? state.start_date;
            setLeaveOptions([]);
            setState(prevState=>({...prevState,step:1}));
            props.get_self_available_leave_options(emp,state.leave_setting,state.start_date,to_date);
        }
        else{
            setState(prevState=>({
                ...prevState,
                err_select_date:state.start_date === null ? IMLocalized('leave_date_required'):'',
                err_leave_setting:state.leave_setting === '' ? IMLocalized('leave_type_required'):''
            }));
        }
    }

    const handleRadio=(e,index)=>{
        let data = leave_options;
        const target = e.target;
        const value = target.value;
        data[index].default = value;
        setLeaveOptions([...data]);
    }

    const submitLeave=()=>{
        
        const option = leave_options.map((item)=>{
            return {date:item.date,day:item.default}
        })

        if(state.file.length <= state.max && state.file.length >= state.min ){
            dispatch(user_apply_leave_self(option,true,state.file,state.leave_entitlement,state.comment));
        }
        else{
            setState(prevState=>({
                ...prevState,
                err_images:IMLocalized('must_upload_image_number',{min:state.min,max:state.max})
            }));
        }
    }

    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('apply_leave')} {props.leave_name != null ? ` - ${props.leave_name}` : null}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="3">
                        <label>{IMLocalized('leave_date')}</label>
                    </Col>
                    <Col lg="9">
                        <DateRangePicker
                            startDate={state.startDate}
                            endDate={state.endDate}
                            isOutsideRange={()=>false}
                            small={true}
                            startDateId="unique_start_date_id"
                            endDateId="unique_end_date_id"
                            onDatesChange={onDatesChange}
                            focusedInput={state.focusedInput}
                            displayFormat={'DD/MM/YYYY'}
                            minimumNights={0}
                            onFocusChange={(focusedInput)=>setState(prevState=>({...prevState,focusedInput}))} 
                        />
                        <div>
                            <small className='text-danger'>{state.err_select_date}</small>
                        </div>
                    </Col>
                </Row>
                {props.has_leave == null && 
                <Row className='mt-2'>
                    <Col lg="3">
                        <label htmlFor='leave_type'>{IMLocalized('leave_type')}</label>
                    </Col>
                    <Col lg="9">
                        <Input size="sm" type="select" value={state.leave_setting} id="leave_type" invalid={state.err_leave_setting.length > 0}
                        onChange={(e)=>{
                            const value = e.target.value;
                            setState(prevState=>({
                                ...prevState,
                                leave_setting:value,
                                err_leave_setting:'',
                                file:[],
                                step:0,
                                leave_options:[],
                                leave_entitlement:null
                            }))
                        }}>
                            <option></option>
                            {props.availablelist.map((item,index)=>{
                                const detail = props.leavelist.find(el=>el.id === item.leave_setting);
                                return <option value={item.leave_setting} key={index}>{detail?.name ?? '-'}</option>
                            })}
                        </Input>
                        <FormFeedback>{state.err_leave_setting}</FormFeedback>
                    </Col>
                </Row>}
                <Row className='mt-2'>
                    <Col lg="3">
                        <label htmlFor='comment'>{IMLocalized('comment')}</label>
                    </Col>
                    <Col lg="9">
                        <Input size="sm" type="textarea" id="comment" value={state.comment} 
                        onChange={(e)=>{
                            const value = e.target.value;
                            setState(prevState=>({...prevState,comment:value}))
                        }}  />
                    </Col>
                </Row>
                {state.max !== 0 &&
                <Row className='mt-3'>
                    <Col lg="3">
                        <label htmlFor='upload_image_user_leave'>{IMLocalized('image')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <input type="file" multiple accept="image/*" id="upload_image_user_leave"
                            onChange={(e)=>{
                                const file = e.target.files;
                                setState(prevState=>({
                                    ...prevState,
                                    file,
                                    err_images:'',
                                }))
                            }}
                            />
                            <div>
                                <small className="text-danger">{state.err_images}</small>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                }
                {arr_approvers.length !== 0 &&
                <Row className='mt-2'>
                    <Col lg="3">
                        <label>{IMLocalized('leave_approval')}</label>
                    </Col>
                    <Col lg="9">
                        <Table size="sm" className="table-bordered" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th>{IMLocalized('approval_level')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {arr_approvers.map((item,index)=>{
                                            return(
                                                <div key={index}>
                                                    <span>{item.employee} - ({IMLocalized('level')} : {item.ordering}) </span>
                                                </div>
                                            )
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>}
                {state.step === 1 && leave_options.length !== 0 &&
                <Table size="sm" className='align-items-center table-bordered' responsive>
                    <thead className='thead-light'>
                        <tr>
                            <th>{IMLocalized('leave_date')}</th>
                            <th>{IMLocalized('option')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leave_options.map((item,index)=>{
                            const tbutton = item.options.map((item1,index1)=>{
                                if(item1.value === item.default){
                                    return(
                                        <div className="custom-control custom-radio mr-3 d-flex align-items-center" key={'radio'+index+'radio'+index1}>
                                            <input name={"leaveoptions"+index} className="custom-control-input" id={'usercustom'+index+'Radio'+index1} type="radio" value={item1.value} defaultChecked={item1.value === item.default}  onChange={(e)=>{handleRadio(e,index)}} />
                                            <label className="custom-control-label" htmlFor={'usercustom'+index+'Radio'+index1}>{IMLocalized(item1.value)}</label>
                                        </div>
                                    )
                                }
                                else{
                                    return(
                                        <div className="custom-control custom-radio mr-3 d-flex align-items-center" key={'radio'+index+'radio'+index1}>
                                            <input name={"leaveoptions"+index}  className="custom-control-input" id={'usercustom'+index+'Radio'+index1} type="radio" value={item1.value}  defaultChecked={item1.value === item.default} onChange={(e)=>{handleRadio(e,index)}}  />
                                            <label className="custom-control-label" htmlFor={'usercustom'+index+'Radio'+index1}>{IMLocalized(item1.value)}</label>
                                        </div>
                                    )
                                }
                            })

                            return(
                                <tr key={index}>
                                    <td>{getDateFormat(item.date)}</td>
                                    <td>
                                        <div className='d-flex'>
                                            {tbutton}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>}
            </ModalBody>
            <ModalFooter>
                {props.isLoading || props.isLoading1 ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                {state.step === 0 && <button className='btn btn-primary btn-sm' onClick={()=>loadDay()}>{IMLocalized('load_day')}</button>}
                {state.step === 1 && <button className='btn btn-primary btn-sm' onClick={()=>submitLeave()}>{IMLocalized('apply')}</button>}
                <button className='btn btn-danger btn-sm' onClick={props.closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalUserApplyLeave);