import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { create_holiday_list, reset_create_holiday_list } from "../../../action/create_holiday_list";
import { getHolidayList } from "../../../action/get_holiday_list";
import { IMLocalized } from "../../../language/IMLocalized";


const initialState={
    name:'',
    err_name:''
}

function ModalCreateHolidayList({toggle,closeModal}){
    const dispatch = useDispatch();
    const create_success = useSelector(state=>state.create_holiday_list.data);
    const create_errors = useSelector(state=>state.create_holiday_list.errors);
    const isLoading = useSelector(state=>state.create_holiday_list.isLoading);
    const [state,setState] = useState(initialState);

    useEffect(()=>{
        if(toggle && create_success != null){
            closeModal();
            dispatch(getHolidayList());
            dispatch(reset_create_holiday_list());
        }
    },[ create_success,toggle,dispatch,closeModal])

    useEffect(()=>{
        if( create_errors != null && toggle){
            if( create_errors.hasOwnProperty('name')){
                const word = create_errors['name'].toString();
                setState(prevState=>({...prevState,err_name:word}));
            }
            dispatch(reset_create_holiday_list());
        }
    },[create_errors,toggle,dispatch])

    useEffect(()=>{
        if(toggle){
            setState(initialState);
        }
    },[toggle])

    const createHoliday=()=>{
        if(state.name !== ''){
            dispatch(create_holiday_list(state.name,[]))
        }
        else{
            setState(prevState=>({...prevState,err_name:IMLocalized('name_is_required')}))
        }
    }

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('create_holiday_list')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="name">{IMLocalized('name')}</label>
                            <Input size="sm" type="text" id="name" value={state.name} invalid={state.err_name.length >0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({
                                    ...prevState,
                                    name:value,
                                    err_name:'',
                                }))
                            }} />
                            <FormFeedback>{state.err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={()=>createHoliday()} >{IMLocalized('create')}</button>
                    <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default ModalCreateHolidayList;