import React, { useCallback, useContext } from 'react';
import { useSelector} from 'react-redux';
import { Container,Row,Col} from 'reactstrap';
import { IMLocalized } from '../../language/IMLocalized';
import { AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; 
import '../../css/aggrid.css';
import { dateRenderer } from '../ag-grid_component/dateRenderer';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

const gridOptions={
    defaultColDef:{
        resizable:true,
        sortable:true
    }
}

function PayrollHistory(props){
    const { action_toggle, setPayrollStep, setPayslipEmpID, setPayrollID, addItem } = useContext(NewMainContext);
    const list = useSelector(state=>state.get_payroll_history_list.data);
    
    const date2Renderer= useCallback(function(params){
        const monthNames=[IMLocalized("january"), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
        IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")
        ];
        const date1 = params.data.payroll_date;
        const month1 = new Date(date1).getMonth();
        const month2 = monthNames[month1]
        const year1 = new Date(date1).getFullYear();
        const payroll_date = month2 + ' ' + year1;

        return payroll_date

    },[])

    const buttonRenderer=function(params){
        const id = params.data.id;
        return <div className="success_btn d-flex align-items-center"><i onClick={()=>reDirect(id)} className="fa fa-arrow-circle-right text-success cursor-pointer"/></div>
    }

    const reDirect=(id)=>{
        const detail = props.list.find((item)=>item.id === id);
        if(detail){
            console.log(detail);
            const {payslip} = detail;
            const {payroll} = detail;
            if(payslip){

                const {payslip_employee} = detail;
                setPayrollID(payroll);
                setPayslipEmpID(payslip_employee);
                setPayrollStep('4');
            }
            else{
                setPayrollStep('1');
                setPayrollID(payroll);
                // props.toPayrollDetail(payroll,count1,'1');
            }
            addItem('15','payroll_list');
        }
    }

    const columnDefs = [
        {
            headerName:IMLocalized('history_time'),
            field:'created_at',
            cellRendererFramework:dateRenderer,
            width:200
        },
        {
            headerName:IMLocalized('hr_employee'),
            field:'employee_name',
            width:200
        },
        {
            headerName:IMLocalized('payroll'),
            field:'payroll_date',
            cellRendererFramework:date2Renderer,
            width:200
        },
        {
            headerName:IMLocalized('payslip_employee_name'),
            field:'payslip_employee_name',
            width:200,
            cellRenderer:params=>{
                if(params.value){
                    return params.value
                };
                return '-'
            },
        },
        {
            headerName:IMLocalized('field_name'),
            field:'name',
            cellRenderer:params=>{
                if(params.value){
                    return params.value
                };
                return '-'
            },
            width:200
        },
        {
            headerName:IMLocalized('old_value'),
            field:'old_value',
            width:150,
            cellRenderer:params=>{
                if(params.value){
                    return params.value
                };
                return '-'
            },
        },
        {
            headerName:IMLocalized('new_value'),
            field:'new_value',
            width:150,
            cellRenderer:params=>{
                if(params.value){
                    return params.value
                };
                return '-'
            },
        },
        {
            headerName:IMLocalized('old_count'),
            field:'count_old_value',
            width:150,
            cellRenderer:params=>{
                if(params.value){
                    return params.value
                };
                return '-'
            },
        },
        {
            headerName:IMLocalized('new_count'),
            field:'count_new_value',
            width:150,
            cellRenderer:params=>{
                if(params.value){
                    return params.value
                };
                return '-'
            },
        },
        {
            headerName:IMLocalized('history_action'),
            field:'action',
            cellRenderer:params=>{
                if(params.value){
                    return IMLocalized(params.value)
                };
                return '-'
            },
            width:150
        },
        {
            headerName:'',
            cellRendererFramework:buttonRenderer,
        }
    ]

    return(
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('payroll_history')}
                    ]}/>
                </Col>
            </Row>
            <Row className='mt-2 justify-content-center'>
                <Col>
                    <div className='ag-theme-balham' style={{height:action_toggle ? 'calc(100vh - 240px)' : 'calc(100vh - 204px)', width:'100%' }}>
                        <AgGridReact
                            columnDefs={columnDefs}
                            rowData={list.sort((a,b)=>new Date(b.created_at) - new Date(a.created_at) )}
                            gridOptions={gridOptions}
                            suppressDragLeaveHidesColumns={true}
                            suppressSizeToFit={true}
                            suppressColumnMoveAnimation={false}
                            overlayNoRowsTemplate={IMLocalized('no_data')}
                            >
                            </AgGridReact>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    )
}
export default PayrollHistory;