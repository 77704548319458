import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEEMPLOYEEMASS_SUCCESS = 'UPDATEEMPLOYEEMASS_SUCCESS';
export const UPDATEEMPLOYEEMASS_REQUEST = 'UPDATEEMPLOYEEMASS_REQUEST';
export const UPDATEEMPLOYEEMASS_FAILURE = 'UPDATEEMPLOYEEMASS_FAILURE';
export const UNIQUECHECK_FAILURE = 'UNIQUECHECK_FAILURE';


export const update_employee_profile_mass = (data)=>{
    // console.log(data.map((item)=>item.id))
    // const arr = data.map((item)=>item)
    console.log('data=' + data)
    // console.log(data.map((item)=>item.id))
    const tt = data.map((item)=>item)
    console.log(tt)
    
    return dispatch=>{
        dispatch({
            type:'UPDATEEMPLOYEEMASS_REQUEST'
        });
            data.map((item)=>
            
            myaxios.patch(`employee/employees/${(item.id)}/`,{
                id:item.id,
                name:item.name,
                badge_number:item.badge_number,
                email:item.email,
            }
            
            )

            .then((response)=>{
                dispatch({
                    type:'UPDATEEMPLOYEEMASS_SUCCESS',
                    payload:response
                });

                Toast.fire({
                    type: 'success',
                    title: IMLocalized('update_success')
                });
            })
            .catch((error)=>{
                dispatch({
                    type:'UPDATEEMPLOYEEMASS_FAILURE',
                    payload:error
                });
                ErrToast.fire({
                    type:'error',
                    title:IMLocalized('update_failure')
                });
            })
        .catch((error)=>{
            dispatch({
                type:'UNIQUECHECK_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })


        )
    }
}