import myaxios from '../../axios';

export const GETALLEMPAVACLAIM_REQUEST = 'GETALLEMPAVACLAIM_REQUEST';
export const GETALLEMPAVACLAIM_SUCCESS = 'GETALLEMPAVACLAIM_SUCCESS';
export const GETALLEMPAVACLAIM_FAILURE = 'GETALLEMPAVACLAIM_FAILURE';

export const get_all_employee_available_claim = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETALLEMPAVACLAIM_REQUEST'
        });

        myaxios.get(`claim/available/`)
        .then((response)=>{
            dispatch({
                type:'GETALLEMPAVACLAIM_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETALLEMPAVACLAIM_FAILURE',
                payload:error
            })
        })
    }
}