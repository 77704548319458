import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const EMPDETAILLEAVEHRAPPROVE_REQUEST ='EMPDETAILLEAVEHRAPPROVE_REQUEST';
export const EMPDETAILLEAVEHRAPPROVE_SUCCESS ='EMPDETAILLEAVEHRAPPROVE_SUCCESS';
export const EMPDETAILLEAVEHRAPPROVE_FAILURE ='EMPDETAILLEAVEHRAPPROVE_FAILURE';
export const EMPDETAILLEAVEHRAPPROVE_RESET ='EMPDETAILLEAVEHRAPPROVE_RESET';



export const emp_detail_array_approve_leave =(id,approve,comment)=>{
    return dispatch=>{
        dispatch({
            type:'EMPDETAILLEAVEHRAPPROVE_REQUEST'
        });

        const swal = approve ?  IMLocalized('approve_success') : IMLocalized('reject_success');
        const err_swal = approve ? IMLocalized('approve_failure') : IMLocalized('reject_failure');

        myaxios.post('leave/employee/approve/',{
            leaves:id,
            approve:approve,
            comment:comment
        })
        .then((response)=>{
            dispatch({
                type:'EMPDETAILLEAVEHRAPPROVE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: swal
            });
        })
        .catch((error)=>{
            dispatch({
                type:'EMPDETAILLEAVEHRAPPROVE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:err_swal,
            });
        })
    }
}

export const reset_emp_detail_array_approve_leave = () => dispatch =>{
    dispatch({
        type:'EMPDETAILLEAVEHRAPPROVE_RESET'
    });
}