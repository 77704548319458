import myaxios from '../../axios';

export const AVAILABLEDATE_REQUEST = 'AVAILABLEDATE_REQUEST';
export const AVAILABLEDATE_SUCCESS = 'AVAILABLEDATE_SUCCESS';
export const AVAILABLEDATE_FAILURE = 'AVAILABLEDATE_FAILURE';

export const get_payroll_available_date = () =>{
    return dispatch=>{
        dispatch({
            type:'AVAILABLEDATE_REQUEST'
        });
        myaxios.get('payroll/payroll/get_available_dates/')
        .then((response)=>{
            dispatch({
                type: 'AVAILABLEDATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'AVAILABLEDATE_FAILURE',
                payload:error
            })
        })
    }
}