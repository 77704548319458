import myaxios from '../../axios';
import FormData from 'form-data';
import { IMLocalized } from '../../language/IMLocalized';
import { Toast } from '../../swal/SwalToast';

export const SELFAPPLYLEAVE_REQUEST = 'SELFAPPLYLEAVE_REQUEST';
export const SELFAPPLYLEAVE_SUCCESS = 'SELFAPPLYLEAVE_SUCCESS';
export const SELFAPPLYLEAVE_FAILURE = 'SELFAPPLYLEAVE_FAILURE';
export const SELFAPPLYLEAVE_RESET = 'SELFAPPLYLEAVE_RESET';

export const user_apply_leave_self = (leave_options,require_confirmation,file,leave_entitlement,comment)=>{
    return dispatch=>{
        dispatch({
            type: 'SELFAPPLYLEAVE_REQUEST'
        });
        let formData  = new FormData();
        if(file.length!==0){
            for(let i=0;i<file.length;i++){
                formData.append('upload_images',file[i],file[i].name);
            }
        }
        if(leave_options.length !== 0){
            for(let i=0;i<leave_options.length;i++){
                formData.append(`leave_options[${i}]day`,leave_options[i].day);
                formData.append(`leave_options[${i}]date`,leave_options[i].date );
            }
        }
        formData.append('pending',require_confirmation);
        formData.append('leave_entitlement',leave_entitlement);
        formData.append('comment',comment);

        myaxios.post('leave/self/apply/',formData,{headers:{'Content-Type':'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'SELFAPPLYLEAVE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('apply_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'SELFAPPLYLEAVE_FAILURE',
                payload:error
            });
        })
    }
}


export const reset_user_apply_leave_self = () => dispatch =>{
    dispatch({
        type:'SELFAPPLYLEAVE_RESET'
    });
}