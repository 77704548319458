import * as createholiday from '../action/create_holiday';

const initialState ={
    isLoading:false,
    errors:null,
    data:null
}

const create_holiday = (state=initialState,action)=>{
    switch(action.type){
        case createholiday.CREATEHOLIDAY_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:{},
            }
        case createholiday.CREATEHOLIDAY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createholiday.CREATEHOLIDAY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case createholiday.CREATEHOLIDAY_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default create_holiday;