import * as deleteholidaylist from '../action/delete_holiday_list';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const delete_holiday_list = (state=initialState,action)=>{
    switch(action.type){
        case deleteholidaylist.DELETEHOLIDAYLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case deleteholidaylist.DELETEHOLIDAYLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case deleteholidaylist.DELETEHOLIDAYLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case deleteholidaylist.DELETEHOLIDAYLIST_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default delete_holiday_list;