import myaxios from "../../axios";
import { IMLocalized } from "../../language/IMLocalized";
import { ErrToast,Toast } from "../../swal/SwalToast";

export const USERMODALCANCELCLAIM_REQUEST = 'USERMODALCANCELCLAIM_REQUEST';
export const USERMODALCANCELCLAIM_SUCCESS = 'USERMODALCANCELCLAIM_SUCCESS';
export const USERMODALCANCELCLAIM_FAILURE = 'USERMODALCANCELCLAIM_FAILURE';
export const USERMODALCANCELCLAIM_RESET = 'USERMODALCANCELCLAIM_RESET';


export const user_modal_cancel_claim = (claims,comment) =>{
    return dispatch=>{
        dispatch({
            type:'USERMODALCANCELCLAIM_REQUEST'
        });

        myaxios.post('claim/self/apply/array_cancel/',{
            claims:claims,
            comment:comment
        })
        .then((response)=>{
            dispatch({
                type:'USERMODALCANCELCLAIM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('cancel_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'USERMODALCANCELCLAIM_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('cancel_failure')
            });
        })
    }
}


export const reset_user_modal_cancel_claim = () =>dispatch=>{
    dispatch({
        type:'USERMODALCANCELCLAIM_RESET'
    });
}