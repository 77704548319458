import * as assign from '../../action/approvals/assign_leave_approval';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const assign_leave_approval = (state=initialState,action)=>{
    switch(action.type){
        case assign.ASSIGNLEAVEAPPROVAL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case assign.ASSIGNLEAVEAPPROVAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case assign.ASSIGNLEAVEAPPROVAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case assign.ASSIGNLEAVEAPPROVAL_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default assign_leave_approval;