import React from 'react';
import { useSelector } from 'react-redux';
import { IMLocalized } from '../../../language/IMLocalized';


export default function LeaveCalendarColor(props){

    const colorlist = useSelector(state=>state.get_general_color.data);

    return(
        <div style={{position:'sticky',top:'25px'}}>
            <div className='pt-3 bg-white px-3'>
                <div className=''>
                    <h5 className='mb-0 '>{IMLocalized('legend')}</h5>
                </div>
                <hr className='my-1' />
                <div className='pt-2'>
                    <div className='d-flex'>
                        <i className="fas fa-square mr-2" style={{color: colorlist.length !== 0 && colorlist?.data?.PENDING  ?  colorlist.data.PENDING : '#fff'}} />
                        <h5>{IMLocalized('pending_leave')}</h5>
                    </div>
                    <div className="d-flex">
                        <i className="fas fa-square mr-2" style={{color: colorlist.length !== 0 && colorlist?.data?.APPROVED  ?  colorlist.data.APPROVED : '#fff'}}/>
                        <h5>{IMLocalized('approved_leave')}</h5>
                    </div>
                    <div className="d-flex">
                        <i className="fas fa-square mr-2" style={{color: colorlist.length !== 0 && colorlist?.data?.REJECTED  ?  colorlist.data.REJECTED : '#fff'}} />
                        <h5>{IMLocalized('rejected_leave')}</h5>
                    </div>
                    <div className="d-flex">
                        <i className="fas fa-square mr-2" style={{color: colorlist.length !== 0 && colorlist?.data?.CONSUMED  ?  colorlist.data.CONSUMED : '#fff'}} />
                        <h5>{IMLocalized('consumed_leave')}</h5>
                    </div>
                    <div className="d-flex">
                        <i className="fas fa-square mr-2"  style={{color: colorlist.length !== 0 && colorlist?.data?.CANCELLED  ?  colorlist.data.CANCELLED : '#fff'}} />
                        <h5>{IMLocalized('cancelled_leave')}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
