import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../css/loading.css';
import '../../css/body.css';
import '../../css/tab.css';
import '../../css/text.css';
import '../../css/dashboard.css';
import '../../css/scrollbar.css';
import '../../css/dragcontent.css';
import { Helmet } from 'react-helmet';
import { useIdleTimer } from 'react-idle-timer';
import { general_group } from '../../action/general_group';
import { get_general_logo } from '../../action/get_general_logo';
import { get_user_picture } from '../../action/user/get_user_picture';
import { general_first_time } from '../../action/general_first_time';
import Swal from 'sweetalert2';
import { IMLocalized, init } from '../../language/IMLocalized';
import ErrorBoundary from '../error/ErrorBoundary';
import NewNavbar from './NewNavbar';
import { user_self } from '../../action/user/user_self';
import ModalQr from './modal/ModalQr';
import ModalPassword from './modal/ModalPassword';
import { Container } from 'reactstrap';
import NewMainContextProvider, { NewMainContext } from '../../context/NewMainContext';
import FunctionBtn from '../function/FunctionBtn';
import MainTab from './MainTab';

export default function NewMainNav(){
    
    return(
        <>
        <Helmet>
            <title>{process.env.REACT_APP_TITLE}</title>
        </Helmet>
        <ErrorBoundary fallback={<h1>Something went wrong</h1>}>
            <NewMainContextProvider>
                <AppInstance/>
            </NewMainContextProvider>
        </ErrorBoundary>
        </>
    )
}

const AppInstance = () =>{
    const [qr_toggle,setQrToggle] = useState(false);
    const [password_toggle,setPasswordToggle] = useState(false);
    const {is_redirect, setIsRedirect } = useContext(NewMainContext);
    const auth = useSelector(state=>state.auth1.fail);
    const is_first = useSelector(state=>state.general_first_time.data);
    const [language, setLanguage ] = useState(localStorage.getItem('language'));
    const dispatch = useDispatch();
   

    const handleUnload = useCallback((e)=>{
        var message = "";
        (e || window.event).returnValue = message;
        return message;
    },[])

    const logOut=()=>{
        Swal.fire({
            title:IMLocalized('logout_confirmation'),
            type:'warning',
            showCancelButton:true,
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!')
        })
        .then((result)=>{
            if(result.value){
                Promise.resolve(window.removeEventListener('beforeunload',handleUnload))
                .then(localStorage.clear())
                .then(window.location.reload())
            }
        })
        
    }

    const reloadWeb= useCallback(()=>{
        Swal.fire({
            title:IMLocalized('session_expired'),
            type:'warning',
            confirmButtonText:IMLocalized('ok!'),
            allowEnterKey:true,
            allowOutsideClick:false,
        })
        .then((res)=>{
            if(res.value){
                Promise.resolve(window.removeEventListener('beforeunload',handleUnload))
                .then(()=>localStorage.clear())
                .then(()=>window.location.reload())
            }
        })
    },[handleUnload])

    useEffect(()=>{
        const outside = localStorage.getItem('reoutside');
        const side = localStorage.getItem('reside');
        if(outside === '1' && side === '1'){
            window.location.href= "/#/iras_upload"; 
        }
        else{

            window.addEventListener('beforeunload', handleUnload);
            window.onunload = function () {
                localStorage.clear();
            }
            dispatch(general_group());
            dispatch(get_general_logo());
            dispatch(get_user_picture()) ;
            dispatch(general_first_time());
            dispatch(user_self());
        }
        return ()=> window.removeEventListener('beforeunload', handleUnload)
    },[handleUnload,dispatch])

    useEffect(()=>{
        if(is_first === 'no' ){
            window.location.href='/#/wizard/first/time/';
        }
    },[is_first])

    useEffect(()=>{
        if(auth === '401'){
            reloadWeb();
        }
    },[auth,reloadWeb])

    useEffect(()=>{
        if(is_redirect === true){
            Promise.resolve(window.removeEventListener('beforeunload',handleUnload))
            .then(window.onunload = function () {
           
            })
            .then(setIsRedirect(false));
            
        }
    },[is_redirect,handleUnload,setIsRedirect])

    const timeout = 1000 * parseInt(process.env.REACT_APP_SESSION_TIMEOUT) * 60;

    useIdleTimer({
        timeout:timeout,
        onIdle:reloadWeb,
        debounce:250
    })

    init(language);

    return(
        <>
        <NewNavbar setQrToggle={()=>setQrToggle(true)} setPasswordToggle={()=>setPasswordToggle(true)} logOut={()=>logOut()} />
        <Container className='body-height scrollbarstyle1' fluid>
            <FunctionBtn/>
            <MainTab/>
        </Container>
        <ModalPassword toggle={password_toggle} closeModal={()=>setPasswordToggle(false)} onSuccess={()=>setPasswordToggle(false)} />
        <ModalQr toggle={qr_toggle} closeModal={()=>setQrToggle(false)} />
        </>
    )
}