import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row,Input } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { reset_update_employee_relationship, update_employee_relationship } from '../../../../action/employee/update_employee_relationship';

export default function ModalEditFamily({ toggle, onSuccess, closeModal, detail }){

    const isLoading = useSelector(state=>state.update_employee_relationship.isLoading);
    const success = useSelector(state=>state.update_employee_relationship.data);
    const relationshiplist = useSelector(state=>state.dropdown_list.user_profile_relationship);
    const dispatch = useDispatch();
    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');
    const [relation,setRelation] = useState('');
    const [err_relation,setErrRelation] = useState('');
    const [contact_detail,setContactDetail] = useState('');
    const [err_contact_detail,setErrContactDetail]  = useState('');

    useEffect(()=>{
        if(toggle){
            setName(detail?.name ?? '');
            setRelation(detail?.relation ?? '');
            setContactDetail(detail?.contact_detail ?? '');
            setErrName('');
            setErrRelation('');
            setErrContactDetail('');
        }
    },[toggle,detail])

    useEffect(()=>{
        if(success !== null && toggle){
            onSuccess();
            dispatch(reset_update_employee_relationship());
        }
    },[success,toggle,onSuccess,dispatch])

    const updateFamily = ()=>{
        if(name !== '' && relation !== '' && contact_detail !== ''){
            dispatch(update_employee_relationship([{id:detail.id,contact_detail,relation,name}]));
        }
        else{
            if(name === ''){
                setErrName(IMLocalized('name_is_required'));
            }
            if(relation === ''){
                setErrRelation(IMLocalized('relation_is_required'));
            }
            if(contact_detail === ''){
                setErrContactDetail(IMLocalized('contact_detail_is_required'));
            }
        }
    }
    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('edit_family_other_contact')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('name')}</label>
                            <Input size="sm" id="name" type="text" name="name" value={name} onChange={(e)=>{setName(e.target.value); setErrName('') }} invalid={err_name.length >0} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='relation'>{IMLocalized('relation')}</label>
                            <Input size="sm" type="select" name="relation" value={relation} id="relation" onChange={(e)=>{setRelation(e.target.value); setErrRelation('')}} invalid={err_relation.length >0} >
                                <option></option>
                                {relationshiplist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_relation}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='contact_detail'>{IMLocalized('contact_detail')}</label>
                            <Input size="sm" type="textarea" name="contact_detail" id="contact_detail" value={contact_detail} onChange={(e)=>{setContactDetail(e.target.value); setErrContactDetail('')}} invalid={err_contact_detail.length > 0} />
                            <FormFeedback>{err_contact_detail}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={updateFamily}>{IMLocalized('update')}</button>
                    <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}