import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const UPDATEPAYPERIOD_REQUEST ='UPDATEPAYPERIOD_REQUEST';
export const UPDATEPAYPERIOD_SUCCESS ='UPDATEPAYPERIOD_SUCCESS';
export const UPDATEPAYPERIOD_FAILURE ='UPDATEPAYPERIOD_FAILURE';

export const update_pay_period =(pay_period,end_day)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEPAYPERIOD_REQUEST'
        });
        
        myaxios.post(`payroll/basesetting/`,{
            pay_period:pay_period,
            end_day:end_day
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEPAYPERIOD_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            })
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEPAYPERIOD_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('update_failure')
            })
        })
    }
}