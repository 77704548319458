import myaxios from "../../axios";

export const GETEMPQUICKVIEW_REQUEST = 'GETEMPQUICKVIEW_REQUEST';
export const GETEMPQUICKVIEW_SUCCESS = 'GETEMPQUICKVIEW_SUCCESS';
export const GETEMPQUICKVIEW_FAILURE = 'GETEMPQUICKVIEW_FAILURE';

export const get_employee_quick_view = () =>{
    return dispatch=>{
        dispatch({
            type:'GETEMPQUICKVIEW_REQUEST'
        });

        myaxios.get('employee/quick_view/')
        .then((res)=>res.data)
        .then((json)=>{
            dispatch({
                type:'GETEMPQUICKVIEW_SUCCESS',
                payload:json
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPQUICKVIEW_FAILURE',
                payload:error
            })
        })
    }
}