import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CHANGEEMPPASS_REQUEST = 'CHANGEEMPPASS_REQUEST';
export const CHANGEEMPPASS_SUCCESS = 'CHANGEEMPPASS_SUCCESS';
export const CHANGEEMPPASS_FAILURE = 'CHANGEEMPPASS_FAILURE';
export const CHANGEEMPPASS_RESET = 'CHANGEEMPPASS_RESET';


export const change_emp_password = (id,password)=>{
    return dispatch=>{
        dispatch({
            type:'CHANGEEMPPASS_REQUEST'
        });

        myaxios.post(`employee/users/${id}/password/`,{
            password:password
        })
        .then((response)=>{
            dispatch({
                type:'CHANGEEMPPASS_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CHANGEEMPPASS_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_change_emp_password = () => dispatch =>{
    dispatch({
        type:'CHANGEEMPPASS_RESET'
    })
}