import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";
import { get_all_employee_available_claim } from "../../../action/employee/get_all_employee_available_claim";
import { get_employee_list } from "../../../action/employee/get_employee_list";
import ModalAssignClaimScheme from "./modal/ModalAssignClaimScheme";
import ModalEmpApplyClaim from "./modal/ModalEmpApplyClaim";
import NoDataTable from "./component/NoDataTable";

const mapStateToProps=(state)=>({
    availablelist:state.get_all_employee_available_claim.data,
    claimlist:state.get_claim_list.data,
    emp_detail:state.get_employee_detail.data,
})

const mapDispatchToProps=(dispatch)=>({
    get_all_employee_available_claim:()=>{
        dispatch(get_all_employee_available_claim())
    },
    get_employee_list:()=>{
        dispatch(get_employee_list())
    },
})


function EmployeeClaim(props){

    const [availablelist,setAvailable] = useState([]);
    const [approval_group,setApprovalGroup] = useState([]);
    const [emp_scheme,setEmpScheme] = useState(null);
    const [approval_name,setApprovalName] = useState('');
    const [claim_approvers,setClaimApprovers] = useState([]);
    const [claim_exceptions,setClaimExceptions] = useState([]);
    const [scheme_toggle,setSchemeToggle] = useState(false);
    const [apply_toggle,setApplyToggle] = useState(false);
    const [apply_name,setApplyName] = useState('');
    const [apply_id,setApplyID] = useState('');

    const approvalRef = useRef();

    useEffect(()=>{
        if(props.emp_detail != null){
    
            const {metadata} = props.emp_detail;
            if('claim_scheme' in metadata){
                if(metadata.claim_scheme !== null){
                    setEmpScheme(metadata.claim_scheme.name);
                }
                else{
                    setEmpScheme(null);
                }
            }
        }
        else{
            setEmpScheme(null);
        }
    },[props.emp_detail])

    useEffect(()=>{
        let arr = [];
        let arr1 = approval_group;
        if(props.emp_detail != null && props.id !=='' && approval_group.length !==0){
            const {metadata} = props.emp_detail;
            if('cag' in metadata){
                const {cag} = metadata;
                if(cag !== null){
                    setApprovalName(cag.name);
                    arr.push(cag.approvers);
                    const {exceptions} = cag;
                    for(let i=0;i<exceptions.length;i++){
                        const number = i+1;
                        const data = exceptions[i];
                        arr.push(data.approvers);
                        const exceptions1 = data.exceptions;
                        for(let j=0;j< exceptions1.length;j++){
                            arr1 = arr1.map(item=>
                                item.claim_type === exceptions1[j].id
                                ?{...item,group:number}
                                :item
                            )
                        }
                    }
                }
                else{
                    setApprovalName('');
                }
            }
        }
        setClaimApprovers(arr);
        setClaimExceptions(arr1);
    },[props.emp_detail,approval_group,props.id])

    useEffect(()=>{
        if(props.id !== '' && props.availablelist.length !== 0){
            const data = props.availablelist.filter(function(item){
                return item.employee === parseInt(props.id);
            })

            const approval_data = data.map((item)=>{
                return {...item,group:0}
            })
            setApprovalGroup(approval_data);
            setAvailable(data);
        }
        else{
            setAvailable([]);
            setApprovalGroup([]);
        }
    },[props.availablelist,props.id])

    const toApply=(claim_type,name)=>{
        setApplyToggle(true);
        setApplyName(name);
        setApplyID(claim_type);
    }

    return(
        <>
            <div className="d-flex justify-content-end align-items-center text-primary pt-1 pr-2">
                <div className="cursor-pointer" onClick={()=>approvalRef.current.scrollIntoView({block:'start',behavior:'smooth'})}>
                    <i className="fas fa-angle-down mr-1 function_icon"/>
                    <small>{IMLocalized('scroll_down')}</small>
                </div>
            </div>
            <div className="d-flex align-items-center pl-2 emp-tabcontent-title">
                <i className="fas fa-money-check text-primary" />
                <h4 className="text-primary pl-2 m-0">{IMLocalized('employee_claim')}</h4>
            </div>
            {availablelist.length === 0 &&
            <div className='p-2'>
                <h4>{IMLocalized('no_claim_scheme_found')}</h4>
                <span>{IMLocalized('please_assign_claim_scheme_for_employee')}</span>
            </div>}
            {availablelist.length !== 0 &&
            <>
            <div className="mt-1 d-flex justify-content-end align-items-center">
                <div className="text-primary cursor-pointer" onClick={()=>props.toViewClaimAll(props.id)}>
                    <i className="far fa-eye mr-1 function_icon" />
                    <small>{IMLocalized('view_all_claim')}</small>
                </div>
            </div>
            <Table className="mt-1 align-items-center table-bordered" size="sm" responsive>
                <thead className="thead-light text-center">
                    <tr>
                        <th>{IMLocalized('claim_type')}</th>
                        <th>{IMLocalized('current_month_balance')}</th>
                        <th>{IMLocalized('apply')}</th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    {availablelist.map((item,index)=>{
                        const current = new Date().getMonth();
                        const claim_detail = props.claimlist.find((item1)=>item1.id === item.claim_type);
                        return(
                            <tr key={index}>
                                <td><a href='/#' className="cursor-pointer" onClick={()=>props.toViewClaimDetail(props.id,item.claim_type)} >{claim_detail ? claim_detail.name : ''}</a></td>
                                <td>$ {item.is_monthly ? item.remaining_monthly[current] : item.remaining} </td>
                                <td><button className="btn btn-primary btn-sm" onClick={()=>toApply(item.claim_type,claim_detail.name)}>{IMLocalized('apply')}</button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            </>}
            <>
            <div className="d-flex justify-content-between align-items-center mt-2 pl-2 emp-tabcontent-title">
                <div className="d-flex align-items-center">
                    <i className="fas fa-money-check text-primary"/>
                    <h4 className="text-primary pl-2 m-0">{IMLocalized('employee_claim_scheme')}</h4>
                </div>
                <div className="d-flex align-items-center text-primary p-2 cursor-pointer " onClick={()=>setSchemeToggle(true)} >
                    <i className="fas fa-plus-circle mr-1 function_icon "/>
                    <small>{IMLocalized('assign_claim_scheme')}</small>
                </div>
            </div>
            <div className="p-2">
                <h4>{emp_scheme === null ? IMLocalized('no_data') : emp_scheme}</h4>
            </div>
            <div ref={approvalRef}>
                <div className="d-flex align-items-center pl-2 emp-tabcontent-title" >
                    <i className="fas fa-money-check text-primary"/>
                    <h4 className="text-primary pl-2 m-0">{IMLocalized('claim_approval')}</h4>
                </div>
            </div>
            {claim_approvers.length === 0 && <NoDataTable/>}
            {claim_approvers.length !== 0 && claim_exceptions.length !==0 && 
            <>
                <div className='p-2'>
                    <h4>{approval_name}</h4>
                </div>
                {claim_approvers.map((item,index)=>{
                    const group = claim_exceptions.filter(function(x){
                        return x.group === index 
                    })
                    const arr_table = group.map((d,index1)=>{
                        const detail = props.claimlist.find((item1)=>item1.id === d.claim_type );

                        return(
                            <div key={'exception'+index1} className="pt-1"><span className="mr-2" >●</span><span>{detail ? detail.name :'-'}</span></div>
                        )
                    })

                    const arr_approvers = item.map((data,index1)=>{
                        const data1 = data.map((item1,index2)=>{
                            return <div key={'approvers'+index2}><span>{item1.name} - ({IMLocalized('level')} : {index1 +1})</span></div>
                        })

                        return data1;
                    })
                    if(arr_table.length !== 0){
                        return (
                            <div className='px-2' key={index}>
                                <h4>{IMLocalized('group')} {index+1}</h4>
                                <Table size="sm" className='table-bordered' responsive>
                                    <thead className='thead-light'>
                                        <tr>
                                            <th className="w-50">{IMLocalized('claim_type')}</th>
                                            <th>{IMLocalized('approval_level')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{arr_table}</td>
                                            <td>{arr_approvers}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        )
                    }
                    else{
                        return null;
                    }
                })}
            </>
            }
            </>
            <ModalAssignClaimScheme toggle={scheme_toggle} closeModal={()=>setSchemeToggle(false)} onSuccess={()=>{props.get_employee_list();props.get_all_employee_available_claim();setSchemeToggle(false)}} id={props.id}  />
            <ModalEmpApplyClaim toggle={apply_toggle} has_emp={true} has_claim={true} id={props.id} claim_type={apply_id} closeModal={()=>{setApplyToggle(false); setApplyID(null); setApplyName('')}} name={apply_name} />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(EmployeeClaim);