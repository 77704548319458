export const checkboxCellRenderer=(params)=>{
    if(params.value !== 'true' && params.value !== 'false'){
        params.setValue(params.value === true || params.value === 'true' ? 'true' : 'false');
    }
    else{
        var input = document.createElement("input");
        
        input.type = "checkbox";
        input.value = params.value === true || params.value === 'true' ? 'true' : 'false';
        input.checked = params.value === true || params.value === 'true' ? true : false;
        
        input.onclick = function(){
            params.setValue(this.checked === true ? 'true' : 'false');
        }
       
        return input;
    }
}


export const checkboxCellRenderer2=(params)=>{
    if(params.value !== 'true' && params.value !== 'false'){
        params.setValue(params.value === true || params.value === 'true' ? true : false);
    }
    else{
        var input = document.createElement("input");
        
        input.type = "checkbox";
        input.value = params.value === true || params.value === 'true' ? true : false;
        input.checked = params.value === true || params.value === 'true' ? true : false;
        
        input.onclick = function(){
            params.setValue(this.checked === true ? true : false);
        }
       
        return input;
    }
}