import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Modal,ModalBody,ModalFooter,ModalHeader,Row,Col,FormGroup,Input,FormFeedback } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { create_payslip_backpay } from '../../../action/payroll/create_payslip_backpay';
import { usePrevious } from '../../../hook/usePrevious';

const mapStateToProps = (state)=>({
    isLoading:state.create_payslip_backpay.isLoading,
    success:state.create_payslip_backpay.data,
})

const mapDispatchToProps = (dispatch)=>({
    create_payslip_backpay:(id,data)=>{
        dispatch(create_payslip_backpay(id,data))
    }

})

function ModalBackpay(props){
    const {success,closeModal} = props;
    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');
    const [pay_type,setPayType] = useState('');
    const [err_pay_type,setErrPayType] = useState('');
    const [err_contract,setErrContract] = useState('');
    const [contract,setContract] = useState('');
    const [amount,setAmount] = useState('');
    const [err_amount,setErrAmount] = useState('');
    const [is_gross,setGross] = useState(false);
    const [wage,setWage] = useState(false);

    const prevSuccess = usePrevious(success);

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== success && success != null && props.toggle){
            closeModal();
        }
    },[prevSuccess,success,closeModal,props.toggle])

    useEffect(()=>{
        if(props.toggle){
            setName('');
            setErrName('');
            setPayType('');
            setErrPayType('');
            setErrContract('');
            setGross(false);
            setWage(false);
            setAmount('');
            setErrAmount('');

            if(props.payslipcontract.length !== 0){
                const id = props.payslipcontract[0].id;
                setContract(id);
            }
        }
    },[props.toggle,props.payslipcontract])

    const addBackpay=()=>{
        if(pay_type !== '' && amount !== '' && contract !== '' && name !== ''){
            const data = [{payslip_contract:contract,pay_type,amount,is_gross,name,additional_wage:wage,payslip:props.payslip_id}]
            props.create_payslip_backpay(props.id,data);
        }
        else{
            if(name === ''){
                setErrName(IMLocalized('name_is_required'));
            }
            if(pay_type === ''){
                setErrPayType(IMLocalized('pay_type_is_required'));
            }
            if(amount === ''){
                setErrAmount(IMLocalized('amount_is_required'));
            }
            if(contract === ''){
                setErrContract(IMLocalized('payslip_contract_is_required'));
            }
        }
    }

    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('add_other_pay')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('name')}</label>
                            <Input size="sm" type="text" value={name} id="name" onChange={(e)=>{setName(e.target.value); setErrName('')}} invalid={err_name.length >0} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='pay_type'>{IMLocalized('pay_type')}</label>
                            <Input size="sm" type="select" id="pay_type" name="pay_type" value={pay_type} invalid={err_pay_type.length>0} onChange={(e)=>{setPayType(e.target.value); setErrPayType('')}}>
                                <option></option>
                                <option value="COMM">{IMLocalized('COMM')}</option>
                                <option value="GRATUITY">{IMLocalized('GRATUITY')}</option>
                                <option value="INSURANCE">{IMLocalized('INSURANCE')}</option>
                                <option value="SHARES">{IMLocalized('SHARES')}</option>
                                <option value="DIRECTORS">{IMLocalized('DIRECTORS')}</option>
                                <option value="PENSION">{IMLocalized('PENSION')}</option>
                                <option value="RETIREMENT">{IMLocalized('RETIREMENT')}</option>
                                <option value="BACKPAY">{IMLocalized('BACKPAY')}</option>
                                <option value="OTHERS">{IMLocalized('OTHERS')}</option>
                                <option value="DEDUCT">{IMLocalized('DEDUCT')}</option>
                            </Input>
                            <FormFeedback>{err_pay_type}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="contract">{IMLocalized('payslip_contract')}</label>
                            <Input size="sm" type="select" ud="contract" name="contract" value={contract} onChange={(e)=>{setContract(e.target.value); setErrContract('') }} invalid={err_contract.length >0} >
                                <option></option>
                                {props.payslipcontract.map((item,index)=>{
                                    const name = item.contract?.job_title ? `${item.contract.job_title} - ${item.gross_month}` : item.gross_month
                                    return <option value={item.id} key={index}>{name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_contract}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='amount'>{IMLocalized('amount')}</label>
                            <Input size="sm" type="number" id="amount" name="amount" value={amount} onChange={(e)=>{setAmount(e.target.value); setErrAmount('')}} invalid={err_amount.length >0}  />
                            <FormFeedback>{err_amount}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6" className="d-flex align-items-center">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id='backpayisgross' name='backpay_is_gross' className="custom-control-input" checked={is_gross} onChange={()=>setGross(state=>!state)} />
                            <label className="custom-control-label" htmlFor='backpayisgross'>{IMLocalized('is_included_in_gross')}</label>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex">
                            <div className="custom-control custom-radio mr-3">
                                <input className="custom-control-input" id="payslipBonus1" name="payslipBonusPay1" type="radio" value="false" checked = {wage ===false} onChange={()=>setWage(false)}/>
                                <label className="custom-control-label" htmlFor="payslipBonus1">{IMLocalized('payslip_ordinary_wage')}</label>
                            </div>
                            <div className="custom-control custom-radio mr-3">
                                <input className="custom-control-input" id="payslipBonus2" name="payslipBonusPay1" type="radio" value="true" checked = {wage === true} onChange={()=>setWage(true)}/>
                                <label className="custom-control-label" htmlFor="payslipBonus2">{IMLocalized('payslip_additional_wage')}</label>
                            </div>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
            {props.isLoading?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button className='btn btn-primary btn-sm' onClick={addBackpay}>{IMLocalized('add')}</button>
                <button className='btn btn-danger btn-sm' onClick={props.closeModal} >{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalBackpay);