import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";




export default function ModalLeaveResetDetailErrorMessage({ toggle, closeModal, message}){
    
    
    
    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('error_msg')}</ModalHeader>
            <ModalBody>
                <Table size="sm" className="align-items-center table-bordered" responsive>
                    <thead className="thead-light p-2">
                        <th>{IMLocalized('employee_id')}</th>
                        <th>{IMLocalized('name')}</th>
                        <th>{IMLocalized('leave_type')}</th>
                        <th>{IMLocalized('error')}</th>
                    </thead>
                    <tbody>
                        {message.map((item)=>{
                            const { employee, leave_setting, err_encash, err_year_over, leave_entitlement } = item;
                            const max_encash_limit = leave_entitlement?.max_encash_limit ?? 0;
                            const year_carry_over_limit = leave_entitlement?.year_carry_over_limit ?? 0;
                            return(
                                <tr key={item.id}>
                                    <td>{employee.employee_number}</td>
                                    <td>{employee.name}</td>
                                    <td>{leave_setting.name}</td>
                                    <td>
                                        {err_encash && 
                                        <div>
                                            <span>● Encash cannot more than {max_encash_limit} </span>
                                        </div>}
                                        {err_year_over && 
                                        <div>
                                            <span>● Carry over amount cannot more than {year_carry_over_limit}</span>
                                        </div>}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}