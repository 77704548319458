import myaxios from '../../axios';

export const GETEMPLIST_REQUEST = 'GETEMPLIST_REQUEST';
export const GETEMPLIST_SUCCESS = 'GETEMPLIST_SUCCESS';
export const GETEMPLIST_FAILURE = 'GETEMPLIST_FAILURE';

export const get_employee_list = (signal)=>{
    return dispatch =>{
        dispatch({
            type:'GETEMPLIST_REQUEST'
        });
        myaxios.get('employee/employees/',{
            signal:signal
        })
        .then((response)=>{
            const data = response.data;
            const arr = data.filter(function(item){
                if(item.active !== true && item.resignation_date === null){
                    return false;
                }
                return true
            })
            dispatch({
                type:'GETEMPLIST_SUCCESS',
                payload:arr
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPLIST_FAILURE',
                payload:error,
                error:true
            });
        })

    }
}
