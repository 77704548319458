import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const WIZARDVALIDATERECURRING_REQUEST ='WIZARDVALIDATERECURRING_REQUEST';
export const WIZARDVALIDATERECURRING_SUCCESS ='WIZARDVALIDATERECURRING_SUCCESS';
export const WIZARDVALIDATERECURRING_FAILURE ='WIZARDVALIDATERECURRING_FAILURE';


export const wizard_validate_recurring = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDVALIDATERECURRING_REQUEST'
        });

        myaxios.post('company/recurring_allowance/array_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDVALIDATERECURRING_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDVALIDATERECURRING_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('validate_failure')
            });
        })
    }
}