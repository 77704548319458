import * as arraypass from '../../action/employee/array_create_employee_passport';


const initialState={
    data:[],
    errors:[],
    isLoading:false
}


const array_create_employee_passport = (state=initialState,action)=>{
    switch(action.type){
        case arraypass.ARRAYEMPPASSPORT_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case arraypass.ARRAYEMPPASSPORT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case arraypass.ARRAYEMPPASSPORT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default array_create_employee_passport;