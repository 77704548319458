import * as emp_array_create from '../../action/employee/create_employee_array_create';

const initialState={
    isLoading:false,
    errors:null,
    data:null,
}


const create_employee_array_create = (state=initialState,action)=>{
    switch(action.type){
        case emp_array_create.EMPARRCREATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case emp_array_create.EMPARRCREATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case emp_array_create.EMPARRCREATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default create_employee_array_create;