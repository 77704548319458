import * as summarydetail from '../../action/claim/get_pay_summary_detail';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}

const get_pay_summary_detail = (state=initialState,action)=>{
    switch(action.type){
        case summarydetail.GETPAYSUMMARYDETAIL_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case summarydetail.GETPAYSUMMARYDETAIL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case summarydetail.GETPAYSUMMARYDETAIL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_pay_summary_detail;