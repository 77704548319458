import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const NEW_UPDATEEMPCONTRACT_REQUEST = 'NEW_UPDATEEMPCONTRACT_REQUEST';
export const NEW_UPDATEEMPCONTRACT_SUCCESS = 'NEW_UPDATEEMPCONTRACT_SUCCESS';
export const NEW_UPDATEEMPCONTRACT_FAILURE = 'NEW_UPDATEEMPCONTRACT_FAILURE';
export const NEW_UPDATEEMPCINTRACT_RESET = 'NEW_UPDDATEEMPCONTRACT_RESET';


export const update_employee_contract = (data)=>{
    return dispatch=>{
        dispatch({
            type:'NEW_UPDATEEMPCONTRACT_REQUEST'
        });

        const {id,hours_day,contract_type,contract_rate,is_workman,start_date,employee,pay,
        hourly_rate,notes,job_level,job_title,section,department,cost_center,wschedule,allowances,recurring_allowances,
        currency,daily_rate} = data[0];
        
        myaxios.patch(`contract/contracts/${id}/`,{
            start_date:start_date,
            pay:pay,
            hourly_rate:hourly_rate,
            is_workman:is_workman,
            hours_day:hours_day,
            wschedule:wschedule,
            job_level:job_level,
            allowances:allowances,
            department:department,
            section:section,
            job_title:job_title,
            cost_center:cost_center,
            contract_rate:contract_rate,
            contract_type:contract_type,
            notes:notes,
            employee:employee,
            recurring_allowances:recurring_allowances,
            contract_currency:currency,
            daily_rate:daily_rate,
        })
        .then((response)=>{
            dispatch({
                type:'NEW_UPDATEEMPCONTRACT_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'NEW_UPDATEEMPCONTRACT_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_employee_contract = () => dispatch=>{
    dispatch({
        type:'NEW_UPDATEEMPCINTRACT_RESET'
    });
}