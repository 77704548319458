import * as createemppassport1 from '../../action/employee/create_employee_passport1';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const create_employee_passport1 = (state=initialState,action)=>{
    switch(action.type){
        case createemppassport1.NEW_CREATEEMPPASSPORT1_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case createemppassport1.NEW_CREATEEMPPASSPORT1_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createemppassport1.NEW_CREATEEMPPASSPORT1_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default create_employee_passport1;