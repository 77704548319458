import React, { useState,forwardRef, useImperativeHandle, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Input , FormFeedback, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import Swal from 'sweetalert2';
import { reset_save_contract_template, save_contract_template } from '../../../../action/employee/save_contract_template';
import { get_contract_template_list } from '../../../../action/get_contract_template_list';


export default forwardRef(function ModalSaveTemplate({detail,toggle,closeModal},ref){

    const isLoading = useSelector(state=>state.save_contract_template.isLoading);
    const success = useSelector(state=>state.save_contract_template.data); 
    const errors = useSelector(state=>state.save_contract_template.errors);

    const dispatch = useDispatch();

    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');
    const [lower_pay,setLower] = useState(0);
    const [err_lower_pay,setErrLower] = useState('');
    const [upper_pay,setUpper] = useState(0);
    const [err_upper_pay,setErrUpper] = useState('');

    useImperativeHandle(ref,()=>{
        return {
            reset:()=>{
                setName('');
                setErrName('');
                setLower(0);
                setUpper(0);
                setErrLower('');
                setErrUpper('');
            }
        }
    })

    useEffect(()=>{
        if(toggle && success !== null){
            closeModal();
            dispatch(get_contract_template_list());
            dispatch(reset_save_contract_template());
        }
    },[success,toggle,closeModal,dispatch])

    useEffect(()=>{
        if( toggle && errors != null){
            if(errors.hasOwnProperty('name')){
                const word = errors['name'].toString();
                setErrName(word);
            }
            dispatch(reset_save_contract_template());
        }
    },[errors,toggle,dispatch])
    

    const onSave=()=>{
        if(name !== '' && lower_pay !== '' && upper_pay !== ''){
            if(parseInt(lower_pay)>parseInt(upper_pay)){
                setErrUpper(IMLocalized('upper_pay_low_than_lower_pay'))
            }
            else{
                Swal.fire({
                    title:IMLocalized('save_as_contract_template_confirmation'),
                    type:'warning',
                    showConfirmButton:true,
                    showCancelButton:true,
                    confirmButtonText:IMLocalized('yes!'),
                    cancelButtonText:IMLocalized('no!')
                })
                .then(result=>{
                    if(result.value){
                        if(detail != null){
                            dispatch(save_contract_template(name,detail.hours_day,lower_pay,upper_pay,detail.is_workman,detail.department,detail.section,detail.job_title,detail.job_level,detail.allowances,detail.recurring_allowances,detail.cost_center,detail.contract_type,detail.contract_rate,detail.wschedule))
                        }
                    }
                })
            }
        }
        else{
            if(name === ''){
                setErrName(IMLocalized('template_name_required'));
            }
            if(lower_pay === ''){
                setErrLower(IMLocalized('lower_pay_required'));
            }
            if(upper_pay === ''){
                setErrUpper(IMLocalized('upper_pay_required'));
            }
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('save_as_contract_template')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('template_name')}</label>
                            <Input size="sm" id="name" type="text" name="name" value={name} onChange={(e)=>{setName(e.target.value); setErrName('')}} invalid={err_name.length >0} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='lower_pay'>{IMLocalized('lower_pay_range')}</label>
                            <Input size="sm" id="lower_pay" type="number" min="0" value={lower_pay} onChange={(e)=>{setLower(e.target.value); setErrLower('')}} invalid={err_lower_pay.length >0} />
                            <FormFeedback>{err_lower_pay}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='upper_pay'>{IMLocalized('upper_pay_range')}</label>
                            <Input  size="sm" id="upper_pay" type="number" min="0" value={upper_pay} onChange={(e)=>{setUpper(e.target.value); setErrUpper('')}} invalid={err_upper_pay.length >0} />
                            <FormFeedback>{err_upper_pay}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> :
                <>
                    <button onClick={()=>onSave()} className="btn btn-primary btn-sm">{IMLocalized('save')}</button>
                    <button onClick={closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
})