import * as assignscheme3 from '../../action/claim/claim_assign_scheme3';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const claim_assign_scheme3 =(state=initialState,action)=>{
    switch(action.type){
        case assignscheme3.CLAIMASSIGNSCHEME3_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case assignscheme3.CLAIMASSIGNSCHEME3_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case assignscheme3.CLAIMASSIGNSCHEME3_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case assignscheme3.CLAIMASSIGNSCHEME3_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default claim_assign_scheme3;