import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Input, Row } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalEmpApplyClaim from './modal/ModalEmpApplyClaim';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; 
import '../../../css/aggrid.css';
import {AgGridReact} from 'ag-grid-react';
import { date2Renderer } from '../../ag-grid_component/date2Renderer';
import { amountRenderer } from '../../ag-grid_component/amountRenderer';
import { dateRenderer } from '../../ag-grid_component/dateRenderer';
import { isFirstColumn } from '../../ag-grid_component/isFirstColumn';
import { popError } from '../../../func/popError';
import { emp_all_array_reject_apply_claim, reset_emp_all_array_reject_apply_claim} from '../../../action/employee/emp_all_array_reject_apply_claim';
import { emp_all_array_approve_apply_claim } from '../../../action/employee/emp_all_array_approve_apply_claim';
import Swal from 'sweetalert2';
import { get_apply_claim_list } from '../../../action/claim/get_apply_claim_list';
import { get_all_employee_available_claim } from '../../../action/employee/get_all_employee_available_claim';
import { get_approvals_claim_list } from '../../../action/claim/get_approvals_claim_list';
import {emp_all_validate_claim_pay, reset_emp_all_validate_claim_pay} from '../../../action/employee/emp_all_validate_claim_pay';
import { get_pay_summary_list } from '../../../action/claim/get_pay_summary_list';
import ModalClaimDetail from '../../claim/modal/ModalClaimDetail';
import ButtonClaimReport from './button/ButtonClaimReport';
import { NewMainContext } from '../../../context/NewMainContext';
import { claim_all_get_claim_detail } from '../../../action/claim/claim_all_get_claim_detail';
import { get_claim_list_detail } from '../../../reducers/claim/get_claim_list';
import { get_employee_list_detail } from '../../../reducers/employee/get_employee_list';
import { get_all_employee_available_claim_detail } from '../../../reducers/employee/get_all_employee_available_claim';
import { emp_get_apply_claim_list_detail } from '../../../reducers/claim/get_apply_claim_list';
import ClaimRemainingTable from './component/ClaimRemainingTable';
import moment from 'moment';
import OverlayLoading from '../../loading_component/OverlayLoading';
import { ClaimStatusRenderer } from '../../../func/ClaimStatusRenderer';
import BreadCrumbList from '../../breadcrumb/BreadCrumbList';

const gridOptions={
    defaultColDef:{
        resizable:true,
        sortable:true,
        filter:true,
        enableValue:true,
        enableRowGroup:true,
    }
}

function EmployeeClaimDetailView({id, claim_type, toEmployeeList, toEmployeeProfile}){
    const dispatch = useDispatch();
    const {action_toggle, addItem, setClaimAllListPayId, setClaimAllListStep} = useContext(NewMainContext);
    const [apply_toggle,setApplyToggle] = useState(false);
    const [selected,setSelected] = useState('4');
    const [detail_toggle,setDetailToggle] = useState(false);
    
    const isLoading = useSelector(state=>state.get_general_color.isLoading);
    const isLoading1 = useSelector(state=>state.get_apply_claim_list.isLoading);
    const reject_success = useSelector(state=>state.emp_all_array_reject_apply_claim.data);
    const approve_success = useSelector(state=>state.emp_all_array_approve_apply_claim.data);
    const pay_success = useSelector(state=>state.emp_all_validate_claim_pay.data);
    const claimdetail = useSelector(state=>get_claim_list_detail(state,parseInt(claim_type)))
    const empdetail = useSelector(state=>get_employee_list_detail(state,parseInt(id)));
    const datalist = useSelector(state=>get_all_employee_available_claim_detail(state,parseInt(id),parseInt(claim_type)))
    const totallist = useSelector(state=>emp_get_apply_claim_list_detail(state,parseInt(id), parseInt(claim_type)));


    useEffect(()=>{
        if( reject_success != null){
            dispatch(get_apply_claim_list());
            dispatch(get_all_employee_available_claim());
            dispatch(get_approvals_claim_list());
            dispatch(reset_emp_all_array_reject_apply_claim());
            setDetailToggle(false);
            gridOptions.api.clearFocusedCell();
        }
    },[reject_success,dispatch])

    useEffect(()=>{
        if( approve_success != null){
            dispatch(get_apply_claim_list());
            dispatch(get_all_employee_available_claim());
            dispatch(get_approvals_claim_list());
            dispatch(emp_all_array_approve_apply_claim());
            setDetailToggle(false);
            gridOptions.api.clearFocusedCell();
        }
    },[approve_success,dispatch])

    useEffect(()=>{
        if( pay_success != null){
            const {id} = pay_success;
            dispatch(get_apply_claim_list());
            dispatch(get_pay_summary_list());
            dispatch(get_approvals_claim_list())
            setClaimAllListPayId(id);
            setClaimAllListStep('1');
            localStorage.setItem('claim_alllist_pay_id',id);
            localStorage.setItem('claim_alllist_step','1');
            dispatch(reset_emp_all_validate_claim_pay());
            addItem('34','claim_table');
        }
    },[pay_success,dispatch,setClaimAllListPayId,setClaimAllListStep, addItem])

    const pendingApprove=(bool)=>{
        
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0){
            
            Swal.fire({
                title: bool ? IMLocalized('approving_claim') : IMLocalized('rejecting_claim'),
                input:'textarea',
                text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
                showCancelButton:true,
                cancelButtonText:IMLocalized('no!'),
                confirmButtonText:IMLocalized('yes!')
            })
            .then(result =>{
                if(result.value !== undefined){
                    const  comment = result.value;
                    const arr = data.map(item=>{
                        return { claim: item.id, comment}
                    });
                    if(bool){
                        dispatch(emp_all_array_approve_apply_claim(arr));
                    }
                    else{
                        dispatch(emp_all_array_reject_apply_claim(arr));
                    }
                }
            })
        }
        else{
            popError(bool ? IMLocalized('select_claim_to_approve') : IMLocalized('select_claim_to_reject'));
        }
    }

    const payPayroll=(type)=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0){
            Swal.fire({
                type:'warning',
                title:IMLocalized('caution'),
                text:type ? IMLocalized('send_to_payroll_irreversible') : IMLocalized('send_to_petty_irreversible'),
                showConfirmButton:true,
                showCancelButton:true,
                confirmButtonText:IMLocalized('yes!'),
                cancelButtonText:IMLocalized('no!')
            })
            .then(result =>{
                if(result.value){
                    const arr = data.map(item=>item.id);
                    const pay_date = moment().format('YYYY-MM-DD');
                    dispatch(emp_all_validate_claim_pay(arr,pay_date,type))
                }
            })
        }
        else{
            popError(IMLocalized('select_claim_to_pay'));
        }
    }

    const onCellFocused=(params)=>{
        if(params?.column?.colDef?.field != null &&  params?.column?.colDef?.field !== 'select'){
            const focusedCell = gridOptions.api.getFocusedCell();
            const cellValue = gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex)
            const id = cellValue.data.id;
            dispatch(claim_all_get_claim_detail(id))
            setDetailToggle(true)
        }
    }

    const rowData = selected === '4' ? totallist : totallist.filter(item=>{
        let filter;
        if(selected === '0') filter = 'PENDING';
        else if(selected === '1')  filter = 'APPROVED';
        else if( selected === '2')  filter = 'REJECTED';
        else if (selected === '3')  filter = 'CANCELLED';
        else if (selected === '5')  filter = 'PAID';
        else filter= 'PAYROLL';
        return item.status === filter;
    })

    const amount = rowData.reduce((sum,item)=>{
        return sum + item.claim_amount;
    },0)

    const pinnedData = rowData.length !== 0 && [{ claim_amount: amount, claim_date: IMLocalized('total'), created_date: null}];

    const option1 = [
        { value : '4', display: IMLocalized('ALL') },
        { value : '0', display: IMLocalized('PENDING')},
        { value : '1', display: IMLocalized('APPROVED')},
        { value : '2', display: IMLocalized('REJECTED')},
        { value : '3', display: IMLocalized('CANCELLED')},
        { value : '5', display: IMLocalized('PAID')},
        { value : '6', display: IMLocalized('PAYROLL')}
    ];

    return(
        <>
        {isLoading || isLoading1 ? <OverlayLoading/>:
        <Container fluid>
            <Row className='bg-white border-bottom'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('employee'), onClick:()=>toEmployeeList('0')},
                        { active: false, title:empdetail?.name ?? '', onClick:()=>toEmployeeProfile('1')},
                        { active: true, title:claimdetail?.name ?? ''}
                    ]}/>
                    <div className='ml-2'>
                        <button className='btn btn-primary btn-sm' onClick={()=>toEmployeeProfile('1')}><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                        <button className="btn btn-primary btn-sm" onClick={()=>setApplyToggle(true)}>{IMLocalized('apply_claim')}</button>
                    </div>
                </Col>
            </Row>
            <Row className='mt-2 justify-content-center'>
                <Col lg="10">
                    {datalist != null && claimdetail != null && <ClaimRemainingTable list={datalist} detail={claimdetail} />}
                    <div className='mt-2 d-flex justify-content-between align-items-center'>
                        <h3 className='mb-0'>
                            {selected === '4' && IMLocalized('all_claim_list')}
                            {selected === '0' && IMLocalized('pending_claim_list')}
                            {selected === '1' && IMLocalized('approved_claim_list')}
                            {selected === '2' && IMLocalized('rejected_claim_list')}
                            {selected === '3' && IMLocalized('cancelled_claim_list')}
                            {selected === '5' && IMLocalized('paid_claim_list')}
                            {selected === '6' && IMLocalized('payroll_paid_claim_list')}
                        </h3>
                        <div className='d-flex align-items-center justify-content-end'>
                            
                            <div className='d-flex mr-2'>
                                {selected === '0' &&
                                <>
                                <button className='btn btn-primary btn-sm' onClick={()=>pendingApprove(true)}>{IMLocalized('approve')}</button>
                                <button className='btn btn-danger btn-sm' onClick={()=>pendingApprove(false)}>{IMLocalized('reject')}</button>
                                </>}
                                {selected === '1' &&
                                <>
                                <button onClick={()=>payPayroll(true)} className="btn btn-primary btn-sm">{IMLocalized('pay_by_payroll')}</button>
                                <button onClick={()=>payPayroll(false)} className="btn btn-primary btn-sm" >{IMLocalized('pay_by_petty_cash')}</button>
                                <button className='btn btn-danger btn-sm' onClick={()=>pendingApprove(false)}>{IMLocalized('reject')}</button>
                                </>}
                                <ButtonClaimReport rowData={totallist} selected={selected} emp_name={empdetail?.name ?? ''} emp_number={empdetail?.employee_number ?? ''} />
                            </div>
                            <Input type="select" size="sm" name="selected" value={selected} onChange={(e)=>setSelected(e.target.value)}>
                                {option1.map((item)=>{
                                    return(
                                        <option value={item.value} key={item.value}>{item.display}</option>
                                    )
                                })}
                            </Input>
                        </div>
                    </div>
                    <div className='mt-2 d-flex jusitfy-content-center'>
                        <div className='ag-theme-balham' style={{height:action_toggle ? 'calc(100vh - 450px)' : 'calc(100vh - 414px', width:'100%'}} >
                            <AgGridReact
                                rowData={rowData}
                                gridOptions={gridOptions}
                                suppressDragLeaveHidesColumns={true}
                                suppressSizeToFit={true}
                                suppressColumnMoveAnimation={false}
                                pinnedBottomRowData={pinnedData}
                                onCellFocused={onCellFocused}
                                overlayNoRowsTemplate={IMLocalized('no_data')}
                                columnDefs={ selected !== '0' && selected !== '1' ?
                                [
                                    {
                                        headerName:IMLocalized('claim_date'),
                                        field:'claim_date',
                                        cellRendererFramework:date2Renderer
                                    },
                                    {
                                        headerName:IMLocalized('claim_amount'),
                                        field:'claim_amount',
                                        cellRendererFramework:amountRenderer
                                    },
                                    {
                                        headerName:IMLocalized('status'),
                                        field:'status',
                                        cellRendererFramework:ClaimStatusRenderer
                                    },
                                    {
                                        headerName:IMLocalized('created_date'),
                                        field:'created_date',
                                        cellRendererFramework:dateRenderer
                                    }
                                ]:
                                [
                                    {
                                        headerName:IMLocalized('select'),
                                        pinned:'left',
                                        field:'select',
                                        width:150,
                                        headerCheckboxSelection: isFirstColumn,
                                        checkboxSelection: isFirstColumn,
                                        headerCheckboxSelectionFilteredOnly:true,
                                        lockPosition: true
                                    },
                                    {
                                        headerName:IMLocalized('claim_date'),
                                        field:'claim_date',
                                        cellRendererFramework:date2Renderer
                                    },
                                    {
                                        headerName:IMLocalized('claim_amount'),
                                        field:'claim_amount',
                                        cellRendererFramework:amountRenderer,
                                    },
                                    {
                                        headerName:IMLocalized('status'),
                                        field:'status',
                                        cellRendererFramework:ClaimStatusRenderer
                                    },
                                    {
                                        headerName:IMLocalized('created_date'),
                                        field:'created_date',
                                        cellRendererFramework:dateRenderer,
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <ModalClaimDetail toggle={detail_toggle} closeModal={()=>{ setDetailToggle(false); gridOptions.api.clearFocusedCell()}}  />
            <ModalEmpApplyClaim toggle={apply_toggle} has_emp={true} has_claim={true} id={id} claim_type={claim_type} closeModal={()=>setApplyToggle(false)} />
        </Container>}
        </>
    )
}
export default EmployeeClaimDetailView;