import myaxios from '../../axios';
export const EMPCONTRACTJOBTITLE_SUCCESS = 'EMPCONTRACTJOBTITLE_SUCCESS';
export const EMPCONTRACTJOBTITLE_REQUEST = 'EMPCONTRACTJOBTITLE_REQUEST';
export const EMPCONTRACTJOBTITLE_FAILURE = 'EMPCONTRACTJOBTITLE_FAILURE';


export const employee_contract_jobtitle = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'EMPCONTRACTJOBTITLE_REQUEST'
        });
        
         myaxios.post(`company/jobtitle/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'EMPCONTRACTJOBTITLE_SUCCESS',
                payload:response
            }
        )})
        .catch((error) =>{
            dispatch({
                type: 'EMPCONTRACTJOBTITLE_FAILURE',
                payload: error,
            })
        })
    }
}