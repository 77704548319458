import * as delete_scheme from '../action/delete_leave_scheme';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const delete_leave_scheme = (state= initialState,action)=>{
    switch(action.type){
        case delete_scheme.DELSCHEME_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case delete_scheme.DELSCHEME_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delete_scheme.DELSCHEME_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case delete_scheme.DELSCHEME_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default delete_leave_scheme;