import * as payslipbonus from '../../action/payroll/create_payslip_bonus';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const create_payslip_bonus = (state=initialState,action)=>{
    switch(action.type){
        case payslipbonus.CREATEPAYSLIPBONUS_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case payslipbonus.CREATEPAYSLIPBONUS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case payslipbonus.CREATEPAYSLIPBONUS_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default create_payslip_bonus;