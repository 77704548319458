import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, FormFeedback, FormGroup, Input, Row, Table } from "reactstrap";
import { wizard_validate_overtime } from "../../action/wizard/wizard_validate_overtime";
import { IMLocalized } from "../../language/IMLocalized";
import ModalErrOvertime from "./modal/ModalErrOvertime";


export default function OvertimeDetail({setData}){

    const dispatch = useDispatch();
    const success = useSelector(state=>state.wizard_validate_overtime.data);
    const errors = useSelector(state=>state.wizard_validate_overtime.errors);

    const [list, setList] = useState([
        { code: 'OT1.0' , multiplier: '1.0' },
        { code: 'OT1.5' , multiplier: '1.5' },
        { code: 'OT2.0' , multiplier: '2.0'}
    ]);
    const [ multi, setMulti ] = useState('');
    const [ code, setCode ] = useState('');
    const [ err_multi, setErrMulti ] = useState('');
    const [ err_code, setErrCode] = useState('');
    const [ err_msg, setErrMsg] = useState([]);
    const [ err_toggle, setErrToggle] = useState(false);
    const [ is_pending, setIsPending] = useState(false);

    const overtimeRef = useRef();

    useEffect(()=>{
        if(success !== null){
            setData(list);
        }
    },[success,setData,list])

    useEffect(()=>{
        if(errors != null && is_pending === true){
            const arr = [];
            for(let i=0; i<errors.length; i++){
                const obj = errors[i];
                console.log(obj);
                if(obj !== true){
                    if(obj.hasOwnProperty('multiplier')){
                        const msg = obj['multiplier'].toString();
                        const name = list[i].multiplier;
                        arr.push({ name, msg });
                    }
                    if(obj.hasOwnProperty('code')){
                        const msg = obj['code'].toString();
                        const name = list[i].code;
                        arr.push({ name, msg });
                    }
                    if(obj.hasOwnProperty('non_field_errors')){
                        const msg = obj['non_field_errors'].toString();
                        const name = list[i].code;
                        arr.push({ name, msg });
                    }
                }
            }
            setErrMsg(arr);
            setErrToggle(true);
            setIsPending(false);
        }
    },[errors,list,is_pending])

    const removeOvertime=(index)=>{
        const data = [...list];
        data.splice(index,1);
        setList(data);
    }

    const onAdd=()=>{
        if(code !== '' && multi !== ''){
            let count = 0;
            if(list.some(el => el.code === code)){
                setErrCode(IMLocalized('duplicate_code'));
                count++;
            }
            if(list.some(el => el.multiplier === multi)){
                setErrMulti(IMLocalized('duplicate_multiplier'));
                count++;
            }

            if(count === 0){
                setList(prevState=>([...prevState,{ code, multiplier:multi }]));
                setCode('');
                setMulti('');
            }
        }
        else{
            if(code === ''){
                setErrCode(IMLocalized('code_is_required'));
            }
            if(multi === ''){
                setErrMulti(IMLocalized('multiplier_is_required'));
            }
        }
    }

    const onSubmit=()=>{
        if(list.length !== 0 ){
            dispatch(wizard_validate_overtime(list));
            setIsPending(true);
        } 
        else {
            setData([]);
        } 
    }

    return(

        <>
        <Container>
            <Row className="mt-2 justify-content-center">
                <Col>
                    <div ref={overtimeRef}>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h4 className="mb-0">{IMLocalized('add_overtime')}</h4>
                                    </Col>
                                    <Col className="col-auto">
                                        <button className="btn btn-primary btn-sm" onClick={onAdd}>{IMLocalized('add')}</button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="code">{IMLocalized('code')}</label>
                                            <Input type="text" id="code" size="sm" value={code} invalid={err_code.length >0} onChange={(e)=>{setCode(e.target.value); setErrCode('')}} />
                                            <FormFeedback>{err_code}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="multiplier">{IMLocalized('multiplier')}</label>
                                            <Input type="number" step="0.5" min="0" size="sm" id="multiplier" value={multi} invalid={err_multi.length >0} onChange={(e)=>{setMulti(e.target.value); setErrMulti('')}} />
                                            <FormFeedback>{err_multi}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h4 className="mb-0">{IMLocalized('overtime')}</h4>
                            </CardHeader>
                            {list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                            {list.length !== 0 &&
                            <Table size="sm" className="align-items-center" responsive>
                                <thead className="text-center">
                                    <tr>
                                        <th>{IMLocalized('code')}</th>
                                        <th>{IMLocalized('multiplier')}</th>
                                        <th>{IMLocalized('delete')}</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {list.map((item,index)=>{
                                        return(
                                            <tr key={index}>
                                                <td>{item.code}</td>
                                                <td>{item.multiplier}</td>
                                                <td align="center" valign="center">
                                                    <div className="delete_btn d-flex align-items-center justify-content-center">
                                                        <i className="fas fa-trash text-red cursor-pointer" onClick={()=>removeOvertime(index)}  />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>}
                        </Card>
                    </div>
                </Col>
            </Row>
            <div className='create_btn_parent'>
                <i className='fas fa-arrow-circle-right text-success cursor-pointer create_btn' onClick={()=>onSubmit()}  />
            </div>
        </Container>
        <ModalErrOvertime toggle={err_toggle} closeModal={()=>{setErrToggle(false); setErrMsg([]) }} detail={err_msg} />
        </>
    )
}