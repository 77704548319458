import * as updatecert from '../../action/employee/update_employee_certification';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_employee_certification = (state=initialState,action)=>{
    switch(action.type){
        case updatecert.UPDATEEMPCERT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case updatecert.UPDATEEMPCERT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updatecert.UPDATEEMPCERT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case updatecert.UPDATEEMPCERT_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default update_employee_certification;