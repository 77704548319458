import React from 'react';
import { Card, CardHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';

export default function RecurringTable(props){

    return(
        <Card>
            <CardHeader className="p-2 border-0 bg-white">
                <h4 className='text-primary pl-2 pt-2'>{IMLocalized('recurring_allowance')}</h4>
            </CardHeader>
            <Table size="sm" className='align-items-center' responsive>
                <thead className="text-center thead-light p-1">
                    <tr>
                        <th className="p-2">{IMLocalized('name')}</th>
                        <th className="p-2">{IMLocalized('code')}</th>
                        <th className="p-2">{IMLocalized('amount')}</th>
                    </tr>
                </thead>
                <tbody className="text-center p-2">
                    {props.list.map((item,index)=>{
                        return(
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.code}</td>
                                <td>${item.amount.toFixed(2)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Card>
    )
}