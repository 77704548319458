import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../../language/IMLocalized';
import { delete_pay_component } from '../../../action/payroll/delete_pay_component';
import { connect } from 'react-redux';
import ModalNoPay from '../payslip_modal/ModalNoPay';

const mapDispatchToProps=(dispatch)=>({
    delete_pay_component:(id,data)=>{
        dispatch(delete_pay_component(id,data))
    }
})

function NoPayTable(props){

    const [toggle,setToggle] = useState(false);

    return(
        <>
        <Card>
            <CardHeader className='p-2 border-0 bg-white d-flex justify-content-between'>
                <h4 className='text-primary pl-2 pt-2'>
                    {props.type === 'nopay' && IMLocalized('payslip_no_pay_leave')} 
                    {props.type === 'absent' && IMLocalized('payslip_absent')}
                    {props.type === 'national' && IMLocalized('national_service')}
                </h4>
                <div className='d-flex align-items-center mr-2'>
                    <button className='btn btn-primary btn-sm' onClick={()=>setToggle(true)}>{IMLocalized('add')}</button>
                </div>
            </CardHeader>
            {props.list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
            {props.list.length !== 0 &&
            <Table size="sm" className="align-items-center" responsive>
                <thead className="text-center p-2 thead-light">
                    <tr>
                        <th className="p-2">{IMLocalized('job_title')}</th>
                        <th className="p-2">{IMLocalized('days')}</th>
                        <th className="p-2">{IMLocalized('payslip_hours')}</th>
                        <th className="p-2">{IMLocalized('amount')}</th>
                        <th className="p-2">{IMLocalized('delete')}</th>
                    </tr>
                </thead>
                <tbody className="text-center p-2">
                    {props.list.map((item,index)=>{

                        const detail = props.payslipcontract.find((item1)=>item1.id === item.payslip_contract);

                        return(
                            <tr key={index}>
                                <td className="p-2">{detail?.contract?.job_title ?? '-'}</td>
                                <td className="p-2">{item.timedata?.days ?? '-'}</td>
                                <td className="p-2">{item.timedata?.hours ?? '-'}</td>
                                <td className="p-2">$ {item.amount.toFixed(2)}</td>
                                <td className="p-2" align="center" valign="center">
                                    <div className="delete_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-trash text-danger cursor-pointer" 
                                        onClick={()=>{
                                            Swal.fire({
                                                type:"warning",
                                                title:IMLocalized('delete_no_pay_confirmation'),
                                                showCancelButton:true,
                                                confirmButtonText:IMLocalized('yes!'),
                                                cancelButtonText:IMLocalized('no!')
                                            })
                                            .then((result)=>{
                                                if(result.value){
                                                   
                                                    const data = [{id:item.id}];
                                                    props.delete_pay_component(props.id,data);
                                                }
                                            })
                                        }}
                                        >
                                        </i>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>}
        </Card>
        <ModalNoPay id={props.id} payslip_id={props.payslip_id} payslipcontract={props.payslipcontract} closeModal={()=>setToggle(false)} toggle={toggle} type={props.type} emp={props.emp} emp_payslip={props.emp_payslip}  />
        </>
    )
}
export default connect(null,mapDispatchToProps)(NoPayTable);