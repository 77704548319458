import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import Swal from "sweetalert2";
import { delete_claim_scheme, reset_delete_claim_scheme } from "../../action/claim/delete_claim_scheme";
import { get_claim_scheme_list } from "../../action/claim/get_claim_scheme_list";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";
import ModalViewClaimSchemeDetail from "./modal/ModalViewClaimSchemeDetail";



const ClaimSchemeList = ({ setStep })=>{

    const { addItem } = useContext(NewMainContext);
    const list = useSelector(state=>state.get_claim_scheme_list.data);
    const isLoading = useSelector(state=>state.get_claim_scheme_list.isLoading);
    const delete_success = useSelector(state=>state.delete_claim_scheme.data);

    const dispatch = useDispatch();
    const [view_toggle, setViewToggle] = useState(false);
    const [view_id, setViewId] = useState(null);

    useEffect(()=>{
        if(delete_success != null){
            dispatch(reset_delete_claim_scheme());
            dispatch(get_claim_scheme_list());
        }
    },[delete_success,dispatch])

    const viewScheme = (id)=>{
        setViewId(id);
        setViewToggle(true);
    }

    const deleteScheme = (id,name)=>{
        Swal.fire({
            type:"warning",
            title:`${IMLocalized('delete_claim_scheme_confirmation')} (` + name +') !',
            showCancelButton:true,
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!')
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_claim_scheme(id));
            }
        })
    }

    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('claim_scheme_list')}</BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('claim_scheme_list')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm" onClick={()=>setStep(1)}>{IMLocalized('create')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        { isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                        { !isLoading && list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        { !isLoading && list.length !== 0 && 
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                            {list.map((item,index)=>{
                                const number = index + 1;
                                return(
                                    <ListGroupItem className="py-2" key={item.id}>
                                        <Row>
                                            <Col className="d-flex align-items-center">
                                                <span>{number}. {item.name}</span>
                                            </Col>
                                            <Col className="col-auto mr-1">
                                                <button onClick={()=>viewScheme(item.id)} className="btn btn-info btn-sm">{IMLocalized('view')}</button>
                                                <button onClick={()=>deleteScheme(item.id,item.name)} className="btn btn-danger btn-sm">{IMLocalized('delete')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
        </Container>
        <ModalViewClaimSchemeDetail toggle={view_toggle} id={view_id} closeModal={()=>{
            setViewToggle(false);
            setViewId(null);
        }} />
        </>
    )
}
export default ClaimSchemeList;