import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { IMLocalized } from "../../../../language/IMLocalized";



export default function AddressTable({list}){

    const nationalitylist = useSelector(state=>state.dropdown_list.countries);

    const country_data = nationalitylist.find(item=> list?.country_of_residence ? item.value === list.country_of_residence : null);
    
    if(list?.country_of_residence != null && list?.country_of_residence === 'SG'){
        return(
            <Row className="mt-2">
                <Col lg="6">
                    <Row className="pl-2">
                        <Col xs="5">
                            <h5>{IMLocalized('country_of_residence')}</h5>
                        </Col>
                        <Col xs="7">
                            {country_data?.display_name ?? ''}
                        </Col>
                    </Row>
                </Col>
                <Col lg="6">
                    <Row className="pl-2">
                        <Col xs="5">
                            <h5>{IMLocalized('sg_postal_code')}</h5>
                        </Col>
                        <Col xs="7">
                            {list.sg_postal_code}
                        </Col>
                    </Row>
                </Col>
                <Col lg="6">
                    <Row className="pl-2">
                        <Col xs="5">
                            <h5>{IMLocalized('block_number')}</h5>
                        </Col>
                        <Col xs="7">
                            {list.block_number}
                        </Col>
                    </Row>
                </Col>
                <Col lg="6">
                    <Row className="pl-2">
                        <Col xs="5">
                            <h5>{IMLocalized('street_name')}</h5>
                        </Col>
                        <Col xs="7">
                            {list.street_name}
                        </Col>
                    </Row>
                </Col>
                <Col lg="6">
                    <Row className="pl-2">
                        <Col xs="5">
                            <h5>{IMLocalized('level_number')}</h5>
                        </Col>
                        <Col xs="7">
                            {list.level_number}
                        </Col>
                    </Row>
                </Col>
                <Col lg="6">
                    <Row className="pl-2">
                        <Col xs="5">
                            <h5>{IMLocalized('unit_number')}</h5>
                        </Col>
                        <Col xs="7">
                            {list.unit_number}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    else if(list?.country_of_residence != null && list?.country_of_residence !== 'SG'){
        return(
            <Row className="pt-2">
                    <Col lg="6">
                        <Row className="pl-2">
                            <Col xs="5">
                                <h5>{IMLocalized('country_of_residence')}</h5>
                            </Col>
                            <Col xs="7">
                                {country_data?.display_name ?? ''} 
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6">
                        <Row className="pl-2">
                            <Col xs="5">
                                <h5>{IMLocalized('postal_code')}</h5>
                            </Col>
                            <Col xs="7">
                                {list.postal_code}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6">
                        <Row className="pl-2">
                            <Col xs="5">
                                <h5>{IMLocalized('address')}</h5>
                            </Col>
                            <Col xs="7">
                                {list.address}
                            </Col>
                        </Row>
                    </Col>
                </Row>
        )
    }
    
    return null;
}