import React,{Component} from 'react';
import {connect} from 'react-redux';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, Table, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../../language/IMLocalized';
import 'ag-grid-enterprise';
import OverlayLoading from '../../loading_component/overlay_loading';
import { array_create_employee_passport } from '../../../action/employee/array_create_employee_passport';
import moment from 'moment';
import { get_employee_passport } from '../../../action/employee/get_employee_passport';
import { NewMainContext } from '../../../context/NewMainContext';
import BreadCrumbList from '../../breadcrumb/BreadCrumbList';


const gridOptions={
    singleClickEdit:true,
    defaultColDef:{
        sortable:true,
        filter:true,
        suppressMenu:true,
        resizable:true
    },
    sideBar:{
        toolPanels:[
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                
            }
        ],
        defaultToolPanel:''
    },
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
}

class import_create_employee_passport extends Component{

    constructor(props){
        super(props);
        this.state={
            list:[],
            language:localStorage.getItem('language'),
            error_msg:[],
            rowData:[],
            rowData2:[],
            import_lang:'',
            errorlist:[],
            columnsDefs:[],
            err_toggle:false,
            noRowTemplate:IMLocalized('no_data'),
        }
    }

    componentDidMount(){
        const {emplist} = this.props;
        if(emplist.length !== 0){
            this.getDetail();
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.emplist !== this.props.emplist){
            Promise.resolve(this.getDetail())
        }
        if(prevProps.success !== this.props.success){
            this.props.get_employee_passport();
            
            this.clearData();
        }
        if(this.context.import_employee_passport_reset === true){
            this.clearData();
            this.context.setImportEmployeePassportReset(false);
        }
    }

    getDetail=()=>{
        let arr = [];

        const {emplist} = this.props;
        if(emplist.length !== 0){
            for(let i =0;i<emplist.length ;i++){
                const item = emplist[i];
                const employee = item.id;
                const name = item.name;
                const employee_number = item.employee_number;
                const is_active = item.active;
                if(is_active === true){
                    arr.push({name,employee,employee_number,passport:null,expiry_date:null,issued_date:null,err_passport:false,err_expiry:false,err_issued:false,err_non:false});
                }
            }
        }

        this.setState({rowData:arr});


        const columnsDefs=[
            {
                headerName:IMLocalized('agrid_select'),
                field:'',
                editable:false,
                width:120,
                lockPosition:true,
                pinned:'left',
                filter:false,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
            },
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee_number',
                editable:false,
                width:200
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'name',
                editable:false,
                width:200
            },
            {
                headerName:IMLocalized('agrid_passport_number'),
                field:'passport',
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_passport}
                },
                editable:true,
            },
            {
                headerName:IMLocalized('agrid_issued_date'),
                field:'issued_date',
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_issued}
                },
                editable:true,
            },
            {
                headerName:IMLocalized('agrid_expiry_date'),
                field:'expiry_date',
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_expiry}
                },
                editable:true
            }
        ]

        this.setState({columnsDefs});
        
    }

    onCellValueChanged=(params)=>{
        if(params.oldValue !== params.newValue){
            const column = params.column.colDef.field;
            if(column === 'passport'){
                const focusedCell = gridOptions.api.getFocusedCell();
                gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                    if(index === focusedCell.rowIndex){
                        rowNode.setSelected(true);
                    }
                })

                params.data.err_passport = false;
                
            }
            else if(column === 'expiry_date'){
                const focusedCell = gridOptions.api.getFocusedCell();
                gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                    if(index === focusedCell.rowIndex){
                        rowNode.setSelected(true);
                    }
                })

                params.data.err_expiry = false;
                    
                
            }
            else if (column === 'issued_date'){
                const focusedCell = gridOptions.api.getFocusedCell();
                gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                    if(index === focusedCell.rowIndex){
                        rowNode.setSelected(true);
                    }
                })

                params.data.err_issued = false;
            }
            params.data.err_non = false;
        }
        params.api.refreshCells();
    }

    onCellFocused=(params)=>{
        if(params !== null){
            if(params.column.colDef !== null){
                if(params.column.colDef.field ==='passport' || params.column.colDef.field ==='issued_date' || params.column.colDef.field === 'expiry_date'){
                    
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = true;
                }
                else{
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = false;
                }
            }
        }
    }

    getTemplate=()=>{
        const {language} = this.state;
        const rowData = gridOptions.api.getSelectedRows();
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_passport');
        const ws3 = wb.addWorksheet('id');

        ws.columns =[
            {header:IMLocalized('agrid_emp_id'),key:'employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'name'},
            {header:IMLocalized('agrid_passport_number'),key:'passport'},
            {header:IMLocalized('agrid_issued_date'),key:'issued_date'},
            {header:IMLocalized('agrid_expiry_date'),key:'expiry_date'}
        ];

        ws3.getCell('A1').value = 'ImportEmployeePassport';
        ws3.getCell('A2').value = language;
        if(rowData.length !== 0){
            for(let i=0;i<ws.columns.length;i++){
                const field = ws.columns[i].key;
                for(let x=0;x<rowData.length;x++){
                    const step = 2+x;
                    const data = this.getValue(rowData[x],field);
                    ws.getCell(this.getColumnName(i)+step).value = data;
                    if(field === 'issued_date' || field === 'expiry_date'){
                        ws.getCell(this.getColumnName(i)+step).numFmt = 'd/M/yy';
                    }
                }
            }

            ws.columns.forEach(column =>{
                column.width = column.header.length < 15 ? 15 : column.header.length
            })
    
            wb.xlsx.writeBuffer().then((buf)=>{
                
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, 'Import_Employee_Passport_Template.xlsx')
            });
        }
        else{
            Swal.fire({
                title:IMLocalized('no_employee_selected'),
                type:'error',
                confirmButtonColor:'#d33',
                confirmButtonText:IMLocalized('ok!'),
            })
        }
    }

    getValue(object, path) {
        const parts = path.split(".");
       
        return parts.reduce((value, key) => value ? value[key] : value, object)
    }

    getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }

    uploadExcel=(e)=>{
        const file = e.target.files[0];
        const data1 = this.state.rowData;
        const reader = new FileReader();
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const wsname3 = wb.SheetNames[1];
            const ws3 = wb.Sheets[wsname3];
            const ws = wb.Sheets[wsname];
            const columns ={
                'A':'employee_number',
                'B':'name',
                'C':'passport',
                'D':'issued_date',
                'E':'expiry_date'
            }

            let rowData1 = [];
            let rowIndex = 2 ;
            let idd = '';
            if(ws3 !== undefined){
                if(ws3['A1'] !== undefined){
                    idd = ws3['A1'].w;
                }
            }

            if(idd === 'ImportEmployeePassport'){
                while(ws['A'+rowIndex]){
                    let row = {};
                    for(let i =0;i<Object.keys(columns).length;i++){
                        const column = Object.keys(columns)[i];
                        const index = column + rowIndex;
                        if(ws[index] !== undefined){
                            row[columns[column]] = ws[index].w;
                        }
                        else{
                            row[columns[column]] = null;
                        }
                    }
                    rowData1.push(row);
                    rowIndex++;
                }

                let arr = [];
                for(let i=0;i<rowData1.length;i++){
                    const item = rowData1[i];
                    const number = item.employee_number;
                    const name = item.name;
                    const passport = item.passport;
                    const expiry_date = item.expiry_date;
                    const issued_date = item.issued_date;
                    const err_passport = false;
                    const err_expiry = false;
                    const err_issued = false;
                    const err_non = false;
                    let employee = '';
                    data1.forEach((item1)=>{
                        if(number === item1.employee_number){
                            employee = item1.employee;
                            arr.push({name,employee_number:number,err_passport,err_expiry,err_issued,err_non,passport,expiry_date,issued_date,employee});
                        }
                    })
                }

                Promise.resolve(this.setState({rowData:arr}))
                .then(gridOptions.api.setRowData(arr))
                .then(()=>setTimeout(()=>{
                    this.selectCell()
                }))
            }
            else{
                Swal.fire({
                    title:IMLocalized('wrong_file_selected'),
                    type:'error',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!')
                })
            }
        }
        reader.readAsBinaryString(file)
    }

    handleFile = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    selectCell=()=>{
        gridOptions.api.forEachNode((rowNode)=>{
            rowNode.setSelected(true);
        })
    }

    selectCell2=(items)=>{
        for(let i =0;i<items.length;i++){
            const employee = items[i].employee;
            gridOptions.api.forEachNode((rowNode,index)=>{
                if(rowNode.data.employee === employee){
                    rowNode.setSelected(true);
                }
            })
        }
    }

    clearData=()=>{
        this.setState({validatelist:[]});
        this.setState({error_msg:[]});
        Promise.resolve( this.setState({rowData:[]}))
        .then(()=>this.getDetail());
    }

    isFirstColumn(params) {
        if(params !== null){
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }   
    }

    onCreate=()=>{
        const rowData = gridOptions.api.getSelectedRows();
        let arr = [];
        let err = [];
        for(let i=0;i<rowData.length;i++){
            const item = rowData[i];
            const employee = item.employee;
            const name = item.name;
            const employee_number =item.employee_number;
            const passport = item.passport;
            const expiry_date = this.getDateFormat(item.expiry_date);
            const issued_date = this.getDateFormat(item.issued_date);
            if(passport !== null && passport !== '' && expiry_date !== 'Invalid date' && issued_date !== 'Invalid date'){
                arr.push({passport,expiry_date,issued_date,employee,employee_number,name})
            }
            else{
                err.push({passport,expiry_date,issued_date,employee,employee_number,name})
            }
        }
        if(err.length !== 0){
            let arr1 = [];
            for(let i=0;i<err.length;i++){
                const expiry_date = err[i].expiry_date;
                const issued_date = err[i].issued_date;
                const passport = err[i].passport;
                const emp_number = err[i].employee_number;
                const name = err[i].name;
                let msg = [];
                if(passport === null || passport === ''){
                    msg.push(<div><span>● {IMLocalized('passport_number_is_required')}</span></div>)

                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee_number === emp_number){
                            rowNode.data.err_passport = true;
                            gridOptions.api.refreshCells(rowNode)
                            rowNode.setSelected(true);
                        }
                    })
                }
                if(issued_date === 'Invalid date'){
                    msg.push(<div><span>● {IMLocalized('issued_date_is_invalid')}</span></div>)

                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee_number === emp_number){
                            rowNode.data.err_issued = true;
                            gridOptions.api.refreshCells(rowNode)
                            rowNode.setSelected(true);
                        }
                    })
                }
                if(expiry_date === 'Invalid date'){
                    msg.push(<div><span>● {IMLocalized('expiry_date_is_invalid')}</span></div>)

                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee_number === emp_number){
                            rowNode.data.err_expiry = true;
                            gridOptions.api.refreshCells(rowNode)
                            rowNode.setSelected(true);
                        }
                    })
                }

                arr1.push(
                    <tr>
                        <td>{emp_number}</td>
                        <td>{name}</td>
                        <td>{msg}</td>
                    </tr>
                )
            }
            this.setState({error_msg:arr1});
            this.setState({err_toggle:true});
        }
        else{
            if(arr.length !== 0){
                this.props.array_create_employee_passport(arr);
                this.setState({validatelist:arr});
            }
            else{
                Swal.fire({
                    type:'warning',
                    title:IMLocalized('no_data'),
                    confirmButtonText:IMLocalized('ok!'),
                    confirmButtonColor:'#d33',
                })
            }
        }
    }

    getDateFormat=(date)=>{
        const date1 = moment(date,'DD/MM/YYYY');
        const new_date = date1.format('YYYY-MM-DD');
        return new_date;
    }

    setErrToggle=()=>{
        const toggle = !this.state.err_toggle;
        this.setState({err_toggle:toggle});
    }

    getError=()=>{
        const table=(
            <Modal isOpen={this.state.err_toggle} size="lg" >
                <ModalHeader toggle={this.setErrToggle}>{IMLocalized('error_msg')}</ModalHeader>
                <ModalBody>
                    <Table size="sm" className="table-bordered align-items-center" responsive>
                        <thead className="thead-light p-2">
                            <tr>
                                <th>{IMLocalized('employee_number')}</th>
                                <th>{IMLocalized('name')}</th>
                                <th>{IMLocalized('error')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.error_msg}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-danger btn-sm" onClick={this.setErrToggle}>{IMLocalized('close')}</button>
                </ModalFooter>
            </Modal>
        )

        return table;
    }

    render(){

        const {rowData,error_msg} = this.state;
        let btn  ='';
        if(rowData.length !== 0){
            btn= <button className="btn btn-primary btn-sm" onClick={this.getTemplate}>{IMLocalized('get_employee_passport_template')}</button>
        }
        let error_btn = '';
        if(error_msg.length !== 0){
            error_btn =<button className="btn btn-danger btn-sm" onClick={this.setErrToggle}>{IMLocalized('error_msg')}</button>
        }
        const {action_toggle} = this.context;
        let currentHeight = 'calc(100vh - 260px)';
        if(action_toggle === false){
            currentHeight = 'calc(100vh - 224px)';
        }

        
        return(
            <div>
                {this.props.isLoading || this.props.isLoading1 || this.props.isLoading2 ? <OverlayLoading />:
                <div>
                    <Container fluid>
                        <Row className="border-bottom bg-white">
                            <Col className="p-2">
                                <BreadCrumbList list={[
                                    { active: false, title:IMLocalized('home'), onClick:()=>this.context.addItem('1','dashboard')},
                                    { active: true, title:IMLocalized('employee_passport_import')}
                                ]}/>
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col>
                                <div className="d-flex">
                                    <div>
                                        {btn}
                                    </div>
                                    <div className="ml-2 mr-2">
                                        <input type="file" style={{display:'none'}} id="ImportPassportcustomFile" className="custom-file-input form-control-sm" onChange={this.uploadExcel.bind(this)} onClick={this.handleFile} accept=".xlsx" />
                                        <label className="btn btn-primary btn-sm" for="ImportPassportcustomFile">{IMLocalized('import_from_file')}</label>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary btn-sm" onClick={this.clearData}>{IMLocalized('clear')}</button>
                                        <button className="btn btn-primary btn-sm" onClick={this.onCreate}>{IMLocalized('create')}</button>
                                        {error_btn}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <div className="ag-theme-balham" style={{height:currentHeight, width:'100%' }}>
                                        <AgGridReact
                                        columnDefs={this.state.columnsDefs}
                                        rowData={this.state.rowData}
                                        suppressDragLeaveHidesColumns={true}
                                        suppressSizeToFit={true}
                                        suppressColumnMoveAnimation={false}
                                        singleClickEdit={true}
                                        onCellValueChanged={this.onCellValueChanged}
                                        onCellFocused={this.onCellFocused}
                                        rowClassRules={{
                                            'sick-days-warning': function (params) {
                                                var numSickDays = params.data.err_non;
                                                return numSickDays ===true
                                                },
                                        
                                        }}
                                        defaultColDef={this.state.defaultColDef}
                                        onGridReady={this.onGridReady}
                                        gridOptions={gridOptions}
                                        overlayNoRowsTemplate = {this.state.noRowTemplate}
                                        stopEditingWhenCellsLoseFocus={true}
                                        >
                                        </AgGridReact>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {this.getError.call(this)}
                </div>
                }
            </div>
        )
    }
}
function mapStateToProps(state){
    return{
        emplist:state.get_employee_list.data,
        isLoading:state.get_employee_list.isLoading,
        success:state.array_create_employee_passport.data,
        errors:state.array_create_employee_passport.errors,
        isLoading1:state.array_create_employee_passport.isLoading,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        array_create_employee_passport:(data)=>{
            dispatch(array_create_employee_passport(data))
        },
        get_employee_passport:()=>{
            dispatch(get_employee_passport())
        }
    }  
}
import_create_employee_passport.contextType = NewMainContext;
export default connect(mapStateToProps, mapDispatchToProps)(import_create_employee_passport);