import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATEAPPRAISALGROUP_REQUEST = 'CREATEAPPRAISALGROUP_REQUEST';
export const CREATEAPPRAISALGROUP_SUCCESS = 'CREATEAPPRAISALGROUP_SUCCESS';
export const CREATEAPPRAISALGROUP_FAILURE = 'CREATEAPPRAISALGROUP_FAILURE';


export const create_appraisal_group = (name,form,appraiser,year,from_date,to_date,employees)=>{
    return dispatch=>{
        dispatch({
            type:'CREATEAPPRAISALGROUP_REQUEST'
        })


        myaxios.post('forms/groups/',{
            name:name,
            form:form,
            appraiser:appraiser,
            year:year,
            from_date:from_date,
            to_date:to_date,
            employees:employees
        })
        .then((response)=>{
            dispatch({
                type:'CREATEAPPRAISALGROUP_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATEAPPRAISALGROUP_FAILURE',
                payload:error
            });
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}
