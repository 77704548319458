import React from 'react';
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader,ModalFooter } from 'reactstrap';
import ModalUobAggrid from './ModalUobAggrid';
import {IMLocalized } from '../../../language/IMLocalized';

const mapStateToProps = (state)=>({
    isLoading:state.get_payment_uob.isLoading,
    list:state.get_payment_uob.data,
})

const mapDispatchToProps = (dispatch)=>({

})

function ModalUOB(props){

    const getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }

    let arr  =[];
    if(props.list !== null){
        for(let i=0;i<Object.keys(props.list).length ;i++){
            const name = Object.keys(props.list)[i];
            const rowData = Object.values(props.list)[i];
            let data = [];
            rowData.forEach((item)=>{
                let object = {};
                for(let j =0;j<item.length;j++){
                    const n = getColumnName(j);
                    object[n] = item[j];
                }
                data.push(object);
            })
           
            arr.push(<ModalUobAggrid company_name={name} rowData={data} />)
        }
    }


    return(
        <Modal isOpen={props.toggle}  style={{maxWidth: '1600px', width: '80%'}} size="lg">
            <ModalHeader toggle={props.closeToggle}>UOB Payments</ModalHeader>
            <ModalBody>
                {props.isLoading ? <div>{IMLocalized('loading')}..</div> : arr.length !== 0 ? arr :<span>{IMLocalized('no_data')}</span> }
                
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-danger btn-sm' onClick={props.closeToggle}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalUOB);