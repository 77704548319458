import * as deleteeducationpic from '../../action/employee/delete_employee_education_picture';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const delete_employee_education_picture = (state=initialState,action)=>{
    switch(action.type){
        case deleteeducationpic.DELEMPEDUCATIONPIC_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case deleteeducationpic.DELEMPEDUCATIONPIC_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case deleteeducationpic.DELEMPEDUCATIONPIC_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default delete_employee_education_picture;