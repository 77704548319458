import * as addemprole from '../../action/employee/add_employee_role';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const add_employee_role = (state=initialState,action)=>{
    switch(action.type){
        case addemprole.ADDEMPROLE_REQUEST:
            return{
                isLoading:true,
                data:null,
                errors:null
            }
        case addemprole.ADDEMPROLE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case addemprole.ADDEMPROLE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case addemprole.ADDEMPROLE_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default add_employee_role;