import * as delovertime from '../action/delete_overtime';

const initialState ={
    isLoading:false,
    errors:null,
    data:null
}


const delete_overtime = (state=initialState,action) =>{
    switch(action.type) {
        case delovertime.DELOVERTIME_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case delovertime.DELOVERTIME_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delovertime.DELOVERTIME_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default delete_overtime;


