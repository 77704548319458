import React from "react";
import { useSelector } from "react-redux";
import { IMLocalized } from "../../../../language/IMLocalized";
import { get_leave_list_detail } from "../../../../reducers/get_leave_list";



export default function LeaveDetailTable({leave_setting, id, total, leave_type , remaining, remaining_monthly, fixed_leave, setAward, toApply, toViewDetail}){

    const leave_data = useSelector(state=>get_leave_list_detail(state,leave_setting));
    const current_month = new Date().getMonth();

    return(
        <tr key={id}>
            <td><span className="text-primary cursor-pointer" onClick={()=>toViewDetail(leave_setting)}>{leave_data?.name ?? '-'}</span></td>
            <td>{total}</td>
            <td>{leave_type === 'E' ? parseFloat(remaining_monthly[current_month].toFixed(2)) : parseFloat(remaining.toFixed(2))}</td>
            <td>{leave_type === 'G' && !fixed_leave ? <button className="btn btn-primary btn-sm" onClick={()=>setAward(leave_setting)}>{IMLocalized('award')}</button> : '-'}</td>
            <td><button className="btn btn-primary btn-sm" onClick={()=>toApply(leave_setting,leave_data?.name)}>{IMLocalized('apply')}</button></td>
        </tr>
    )
}