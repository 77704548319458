import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../../language/IMLocalized';
import { delAllowance, reset_delete_allowance } from '../../../action/delete_allowance';
import { get_additional_setting } from '../../../action/additional_setting';
import ModalCreateAllowance from '../modal/ModalCreateAllowance';
import { useDispatch, useSelector } from 'react-redux';

function AllowanceContent(){
   
    const [create_toggle,setCreateToggle] = useState(false);
    const dispatch = useDispatch();
    const success = useSelector(state=>state.delete_allowance.data);
    const allowancelist = useSelector(state=>state.add.allowance);
    const isLoading = useSelector(state=>state.add.isLoading);
    const typelist = useSelector(state=>state.dropdown_list.allowance_type);
    const isLoading1 = useSelector(state=>state.dropdown_list.isLoading);

    useEffect(()=>{
        if( success !== null){
            dispatch(get_additional_setting());
            dispatch(reset_delete_allowance());
        }
    },[success,dispatch])

    const removeAllowance=(name)=>{
        Swal.fire({
            type:'warning',
            title:`${IMLocalized('do_you_really_want_to_delete')} \n ${IMLocalized('allowance')} (`+ name + `)`,
            showCancelButton:true,
            confirmButtonColor:'red'
        })
        .then((result)=>{
            if(result.value){
                dispatch(delAllowance(name));
            }
        })
    }

    return(
        <>
        <Card>
            <CardHeader className='d-flex align-items-center justify-content-between'>
                <h4 className="mb-0">{IMLocalized('allowance')}</h4>
                <button className='btn btn-primary btn-sm' onClick={()=>setCreateToggle(true)}>{IMLocalized('add')}</button>
            </CardHeader>
            {isLoading || isLoading ? <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>: null}
            {!isLoading && !isLoading1 && allowancelist.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}..</span></CardBody>}
            {!isLoading && !isLoading1 && allowancelist.length !== 0 &&
            <Table size="sm" className='align-items-center' responsive>
                <thead className="text-center">
                    <tr>
                        <th>{IMLocalized('code')}</th>
                        <th>{IMLocalized('name')}</th>
                        <th>{IMLocalized('type')}</th>
                        <th>{IMLocalized('amount')}</th>
                        <th>{IMLocalized('is_included_in_gross')}</th>
                        <th>{IMLocalized('delete')}</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {allowancelist.map((item,index)=>{
                        const detail = typelist.find(function(item1){
                            return item1.value === item.allowance_type;
                        })
                        return(
                            <tr key={index}>
                                <td>{item.code}</td>
                                <td>{item.name}</td>
                                <td>{detail ? detail.display : '-'}</td>
                                <td>$ {item.amount}</td>
                                <td className="p-2">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id={'gross'+index} className="custom-control-input" name="gross" checked={item.is_included_in_gross} readOnly/>
                                        <label className="custom-control-label" htmlFor={'gross'+index}></label>
                                    </div>
                                </td>
                                <td align="center" valign="center">
                                    <div className="delete_btn d-flex align-items-center justify-content-center">
                                        <i onClick={()=>removeAllowance(item.name)} className="fas fa-trash text-red cursor-pointer"></i>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>}
        </Card>
        <ModalCreateAllowance toggle={create_toggle} closeModal={()=>setCreateToggle(false)} />
        </>
    )
}
export default AllowanceContent;