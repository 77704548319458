import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELDEPARTMENT_REQUEST = 'DELDEPARTMENT_REQUEST';
export const DELDEPARTMENT_FAILURE = 'DELDEPARTMENT_FAILURE';
export const DELDEPARTMENT_SUCCESS = 'DELDEPARTMENT_SUCCESS';
export const DELDEPARTMENT_RESET = 'DELDEPARTMENT_RESET';

export const delDepartment = (id)=>{
    return dispatch =>{
        dispatch({
            type: 'DELDEPARTMENT_REQUEST'
        });
        
        myaxios.delete(`company/department/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELDEPARTMENT_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'DELDEPARTMENT_FAILURE',
                payload: error,
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_department = () => dispatch =>{
    dispatch({
        type:'DELDEPARTMENT_RESET'
    });
}


