import * as userlayout from '../../action/dashboard/update_user_layout';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_user_layout = (state=initialState,action)=>{
    switch(action.type){
        case userlayout.UPDATEUSERLAYOUT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case userlayout.UPDATEUSERLAYOUT_SUCCESS:
            return{
                ...state,
                data:action.payload,
                isLoading:false
            }
        case userlayout.UPDATEUSERLAYOUT_FAILURE:
            return{
                ...state,
                errors:action.payload,
                isLoading:false
            }
        default:
            return state;
    }
}
export default update_user_layout;