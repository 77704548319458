import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";


export default function ModalErrOvertime({toggle,closeModal,detail}){


    return(
        <Modal isOpen={toggle} size="lg">
            {detail &&
            <>
            <ModalHeader toggle={closeModal}>{IMLocalized('error_msg')}</ModalHeader>
            <ModalBody>
                <Table size="sm" className="table-bordered align-items-center" responsive>
                    <thead className="thead-light p-2">
                        <tr>
                            <th>{IMLocalized('name')}</th>
                            <th>{IMLocalized('error')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {detail.map((item,index)=>{
                            return(
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.msg}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </ModalBody>
            </>}
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}