import * as apply from '../../action/employee/employee_user_apply_leave';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}

const employee_user_apply_leave = (state=initialState,action)=>{
    switch(action.type){
        case apply.EMPUSERAPPLYLEAVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case apply.EMPUSERAPPLYLEAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                errors:[],
                data:action.payload
            }
        case apply.EMPUSERAPPLYLEAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;

    }
}
export default employee_user_apply_leave;