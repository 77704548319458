import * as payroll_list from '../../action/payroll/get_payroll_list';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
    month:[]
}

const get_payroll_list = (state=initialState,action)=>{
    switch(action.type){
        case payroll_list.PAYROLLLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case payroll_list.PAYROLLLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                month:action.payload.data,
                errors:[]
            }
        case payroll_list.PAYROLLLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_payroll_list;
