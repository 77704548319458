import * as contractincrementvalidate from '../../action/payroll/create_contract_increment_pay_validate';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const create_contract_increment_pay_validate = (state=initialState,action)=>{
    switch(action.type){
        case contractincrementvalidate.CONTRACTINPAYVALIDATE_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case contractincrementvalidate.CONTRACTINPAYVALIDATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractincrementvalidate.CONTRACTINPAYVALIDATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default create_contract_increment_pay_validate;