import * as employeeimage from '../../action/employee/get_employee_picture';

const initialState={
    isLoading:false,
    data:null,
    errors:[],
    hello:'',
}

const get_employee_picture = (state=initialState,action)=>{
    switch(action.type){
        case employeeimage.GETEMPLOYEEPICTURE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
            }
        case employeeimage.GETEMPLOYEEPICTURE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                hello:action.payload,
                data:action.payload
            }
        case employeeimage.GETEMPLOYEEPICTURE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_picture;