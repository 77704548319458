import * as applyclaimlist2 from '../../action/claim/get_apply_claim_list2';

const initialState={
    isLoading:false,
    errors:[],
    data:[]
}

const get_apply_claim_list2 = (state=initialState,action)=>{
    switch(action.type){
        case applyclaimlist2.GETAPPLYCLAIMLIST2_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case applyclaimlist2.GETAPPLYCLAIMLIST2_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case applyclaimlist2.GETAPPLYCLAIMLIST2_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_apply_claim_list2;