import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col,FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { reset_update_employee_certification, update_employee_certification } from '../../../../action/employee/update_employee_certification';
import { add_employee_certification_picture } from '../../../../action/employee/add_employee_certification_picture';


export default function ModalEditCertification({ toggle, closeModal, onSuccess, detail}){
   
    const nationalitylist = useSelector(state=>state.dropdown_list.countries);
    const success = useSelector(state=>state.update_employee_certification.data);
    const isLoading = useSelector(state=>state.update_employee_certification.isLoading);
    const dispatch = useDispatch();
    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');
    const [school,setSchool] = useState('');
    const [err_school,setErrSchool] = useState('');
    const [country,setCountry] = useState('');
    const [err_country,setErrCountry] = useState('');
    const [acquired_date,setAcquiredDate] = useState('');
    const [err_acquired_date,setErrAcquired] = useState('');
    const [expiry_date,setExpiryDate] = useState('');
    const [err_expiry_date,setErrExpiry] = useState('');
    const [images,setImages] = useState([]);
    const [err_images,setErrImages] = useState('');
    const [notification_duration_days,setNotification] = useState('');
    const [err_notification_duration_days,setErrNotification] = useState('');

    useEffect(()=>{
        if(toggle && detail){
            setName(detail.name);
            setErrName('');
            setSchool(detail.school);
            setErrSchool('');
            setCountry(detail.country);
            setErrCountry('');
            setAcquiredDate(detail.acquired_date);
            setErrAcquired('');
            setExpiryDate(detail.expiry_date);
            setErrExpiry('');
            setImages([]);
            setErrImages('');
            setNotification(detail.notification_duration_days);
            setErrNotification('');
        }
    },[toggle,detail])

    useEffect(()=>{
        if( success !== null && toggle){
            onSuccess();
            dispatch(reset_update_employee_certification());
        }
    },[toggle, success, onSuccess, dispatch])


    const updateCertification = ()=>{

        if(name !== '' && school !== '' && acquired_date !== ''&& country !== ''){
            if(images.length !== 0 ){
                dispatch(add_employee_certification_picture(detail.id,images));
                dispatch(update_employee_certification(detail.id,name,school,country,acquired_date,expiry_date,notification_duration_days,images));
            }
            else{
                dispatch(update_employee_certification(detail.id,name,school,country,acquired_date,expiry_date,notification_duration_days,images));
            }
        }
        else{
            if(name === ''){
                setErrName(IMLocalized('name_is_required'));
            }
            if(school === ''){
                setErrSchool(IMLocalized('school_is_required'));
            }
            if(acquired_date === ''){
                setErrAcquired(IMLocalized('acquired_date_is_required'));
            }
            if(country === ''){
                setErrCountry(IMLocalized('country_is_required'));
            }
        }
    }

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('edit_certification')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('name')}</label>
                            <Input size="sm" type="text" id="name" name="name" value={name} onChange={(e)=>{setName(e.target.value); setErrName('')}} invalid={err_name.length >0} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='school'>{IMLocalized('school')}</label>
                            <Input size="sm" type="text" id="school" name="school" value={school} onChange={(e)=>{setSchool(e.target.value); setErrSchool('')}} invalid={err_school.length > 0} />
                            <FormFeedback>{err_school}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='country'>{IMLocalized('country')}</label>
                            <Input size="sm" type="select" id="country" name="country" value={country} onChange={(e)=>{setCountry(e.target.value); setErrCountry('')}} invalid={err_country.length > 0}>
                                <option></option>
                                {nationalitylist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display_name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_country}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='acquired_date'>{IMLocalized('acquired_date')}</label>
                            <Input size="sm" id="acquired_date" type="date" name="acquired_date" value={acquired_date} onChange={(e)=>{setAcquiredDate(e.target.value); setErrAcquired('')}} invalid={err_acquired_date.length >0} />
                            <FormFeedback>{err_acquired_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='expiry_date'>{IMLocalized('expiry_date')}</label>
                            <Input size="sm" id="expiry_date" type="date" name="expiry_date" value={expiry_date} onChange={(e)=>{setExpiryDate(e.target.value); setErrExpiry('')}} invalid={err_expiry_date.length >0} />
                            <FormFeedback>{err_expiry_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='notification_duration_days'>{IMLocalized('notification_duration_days')}</label>
                            <Input size="sm" type="number" id='notification_duration_days' name="notification_duration_days" value={notification_duration_days} onChange={(e)=>{setNotification(e.target.value); setErrNotification('')}} invalid={err_notification_duration_days.length >0} />
                            <FormFeedback>{err_notification_duration_days}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="12">
                        <FormGroup>
                            <input type="file" multiple onChange={(e)=>{setImages(e.target.files); setErrImages('')}} accept="image/*" />
                            <div>
                                <small className='text-red'>{err_images}</small>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={updateCertification}>{IMLocalized('update')}</button>
                    <button className="btn btn-danger btn-sm" onClick={closeModal} >{IMLocalized('close')}</button>
                </>
                }
            </ModalFooter>
        </Modal>
    )
}