import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { getDateFormat } from '../../../../func/getDateFormat';
import { get_employee_education_picture, reset_get_employee_education_picture } from '../../../../action/employee/get_employee_education_picture';
import Viewer from 'react-viewer';
import Swal from 'sweetalert2';
import { delete_employee_education_picture } from '../../../../action/employee/delete_employee_education_picture';
import { Buffer } from 'buffer';
import { education_level_detail, nationality_detail } from '../../../../reducers/dropdown_list';


export default function ModalViewEducation({ toggle, closeModal, detail, type }){
    
    const picture = useSelector(state=>state.get_employee_education_picture.data);
    const dispatch = useDispatch();
    const [imagelist,setImgList] = useState([]);
    const [images,setImages] = useState([]);
    const [activeImg,setActive] = useState(0);
    const [img_toggle,setImgToggle] = useState(false);

    useEffect(()=>{
        if(toggle && detail){
            setImgList(detail.images);
            setImages([]);
        }
        else{
            setImgList([]);
            setImages([]);
            dispatch(reset_get_employee_education_picture());
        }
    },[toggle,detail, dispatch])

    useEffect(()=>{
        if(imagelist.length !== 0 && toggle){
            setImages([]);
            for(let i=0;i<imagelist.length;i++){
                const id = imagelist[i];
                dispatch(get_employee_education_picture(id));
            }
        }
    },[imagelist,dispatch,toggle])

    useEffect(()=>{
        if( picture !== null && toggle){
            const img = new Buffer.from(picture,'binary').toString('base64');
            const src = 'data:image/png;base64,'+img;
            setImages(state=>[...state,{src}]);
        }
    },[picture,toggle])

    const open=(index)=>{
        setImgToggle(true);
        setActive(index);
    }

    const removePicture=(src,e)=>{
        if(e && e.stopPropagation){
            e.stopPropagation();
        }
        Swal.fire({
            title:IMLocalized('delete_picture_confirmation'),
            text:IMLocalized('revert_msg'),
            type:'warning',
            showCancelButton:true,
            cancelButtonColor:'#3085d6',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
        })
        .then((result)=>{
            if(result.value){
                const index = images.findIndex(item=>item.src === src);
                const id = imagelist[index];
                const data = [...images];
                const data1 = [...imagelist];
                data.splice(index,1);
                data1.splice(index,1);
                setImgList(data1);
                setImages(data);
                dispatch(delete_employee_education_picture(id));
            }
        })
    }

    const country_name = useSelector(state=>nationality_detail(state,detail?.country));
    const level_name = useSelector(state=>education_level_detail(state,detail?.level));

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('education_detail')}</ModalHeader>
            <ModalBody>
                {detail &&
                <Row>
                    <Col>
                        <Table className="table-bordered" size="sm">
                            <tbody>
                                <tr>
                                    <td className="w-25">{IMLocalized('education_level')}</td>
                                    <td>{level_name}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('institute')}</td>
                                    <td>{detail.institute}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('school')}</td>
                                    <td>{detail.school}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('country')}</td>
                                    <td>{country_name}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('certification')}</td>
                                    <td>{detail.certification}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('GPA')}</td>
                                    <td>{detail.gpa}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('highest_education')}</td>
                                    <td>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id={'hrdetail_highest_education'} className="custom-control-input" name="detail_highest_education" checked={detail.highest_education} readOnly/>
                                            <label className="custom-control-label" htmlFor={'hrdetail_highest_education'}></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('graduation_date')}</td>
                                    <td>{getDateFormat(detail.graduation_date)}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('enrollment_date')}</td>
                                    <td>{getDateFormat(detail.enrollment_date)}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('note')}</td>
                                    <td>{detail.notes === '' || detail.notes === null ? '---' : detail.notes}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('image')}</td>
                                    <td>
                                        {images.length !== 0 ?
                                        <div className='image-gallery'>
                                            {images.map((item,index)=>{
                                                return(
                                                    <div key={item.src}>
                                                        <div className="image-item mr-2" >
                                                            <div className="image-inner" onClick={()=>open(index)}>
                                                                <img src={item.src} key={index}  className="img-thumbnail" alt="emp_education_picture"></img>
                                                                {type === 'hr' &&
                                                                <div className='picture_btn ' style={{zIndex:100}} onClick={(e)=>removePicture(item.src,e)}>
                                                                    <i className="ni ni-fat-remove text-danger cursor-pointer "  />
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>:
                                        <span>---</span>}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                }
                <Viewer activeIndex={activeImg} zIndex={1060} visible={img_toggle} onClose={()=>setImgToggle(false)} images={images} />
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-danger btn-sm' onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
