import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELEMPLOYEEBANK_REQUEST = 'DELEMPLOYEEBANK_REQUEST';
export const DELEMPLOYEEBANK_FAILURE = 'DELEMPLOYEEBANK_FAILURE';
export const DELEMPLOYEEBANK_SUCCESS = 'DELEMPLOYEEBANK_SUCCESS';
export const DELEMPLOYEEBANK_RESET = 'DELEMPLOYEEBANK_RESET';

export const delete_employee_bank= (data)=>{
    return dispatch =>{
        dispatch({
            type: 'DELEMPLOYEEBANK_REQUEST'
        });
        myaxios.delete(`employee/bank/${data}/`)
        .then((response)=>{
            dispatch({
                type:'DELEMPLOYEEBANK_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error) =>{

            dispatch({
                type: 'DELEMPLOYEEBANK_FAILURE',
                payload: error,
                error:true
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_employee_bank = ()=> dispatch=>{
    dispatch({
        type:'DELEMPLOYEEBANK_RESET'
    })
}


