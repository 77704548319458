import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Viewer from "react-viewer";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { reset_claim_all_get_claim_detail } from "../../../action/claim/claim_all_get_claim_detail";
import { claim_all_get_claim_picture, reset_claim_all_get_claim_picture } from "../../../action/claim/claim_all_get_claim_picture";
import { getDateFormat } from "../../../func/getDateFormat";
import { IMLocalized } from "../../../language/IMLocalized";
import { Buffer } from "buffer";
import Swal from "sweetalert2";
import { reset_validate_approve_apply_claim, validate_approve_apply_claim } from "../../../action/claim/validate_approve_apply_claim";
import { reject_apply_claim, reset_reject_apply_claim } from "../../../action/claim/reject_apply_claim";
import { get_apply_claim_list } from "../../../action/claim/get_apply_claim_list";
import { get_all_employee_available_claim } from "../../../action/employee/get_all_employee_available_claim";
import { get_approvals_claim_list } from "../../../action/claim/get_approvals_claim_list";
import '../../../css/image.css';

export default function ModalClaimDetail({ toggle, closeModal}){

    const detail = useSelector(state=>state.claim_all_get_claim_detail.data);
    const isLoading = useSelector(state=>state.claim_all_get_claim_detail.isLoading);
    const picture = useSelector(state=>state.claim_all_get_claim_picture.data);
    const approve_success = useSelector(state=>state.validate_approve_apply_claim.data);
    const reject_success = useSelector(state=>state.reject_apply_claim.data);
    const dispatch = useDispatch();

    const [ activeImg, setActiveImg] = useState(0);
    const [ img_toggle, setImgToggle] = useState(false);
    const [ imglist, setImageList] = useState([]);

    useEffect(()=>{
        if(reject_success != null){
            dispatch(reset_reject_apply_claim());
            dispatch(get_apply_claim_list());
            dispatch(get_all_employee_available_claim());
            dispatch(get_approvals_claim_list());
            closeModal();
        }
    },[reject_success,dispatch,closeModal])

    useEffect(()=>{
        if(approve_success != null){
            dispatch(reset_validate_approve_apply_claim());
            dispatch(get_apply_claim_list());
            dispatch(get_all_employee_available_claim());
            dispatch(get_approvals_claim_list());
            closeModal();
        }
    },[approve_success,dispatch,closeModal])

    useEffect(()=>{
        if(!toggle){
            setImageList([]);
            dispatch(reset_claim_all_get_claim_detail());
            dispatch(reset_claim_all_get_claim_picture());
        }
    },[toggle,dispatch])

    useEffect(()=>{
        if(detail != null && toggle){
            const { images } = detail;
            for(const image of images){
                dispatch(claim_all_get_claim_picture(image));
            }
        }
    },[detail ,dispatch, toggle])

    useEffect(()=>{
        if(picture != null && toggle){
            const img = new Buffer.from(picture,'binary').toString('base64');
            const src = `data:image/png;base64,${img}`;
            setImageList(prevState=>([...prevState,{src}]));
        }
    },[picture,toggle])

    const singleApprove=(bool)=>{
        Swal.fire({
            title: bool ? IMLocalized('approving_claim') : IMLocalized('rejecting_claim'),
            input:'textarea',
            text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then((result)=>{
            if(result.hasOwnProperty('value')){
                const comment = result.value;
                if(bool){
                    dispatch(validate_approve_apply_claim(detail.id,comment))
                }
                else{
                    dispatch(reject_apply_claim(detail.id,comment));
                }
            }
        })
    }

    const approvers = detail ? detail.approvers.filter(item=>item.is_active === true) : [];

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('claim_detail')}</ModalHeader>
            <ModalBody>
            {isLoading && <span>{IMLocalized('loading')}..</span>}
            {!isLoading && detail &&
            <>
            
                <Row>
                    <Col>
                        <Table className="table-bordered" size="sm">
                            <tbody>
                                <tr>
                                    <td className="td-w-30">{IMLocalized('employee_number')}</td>
                                    <td>{detail.employee.employee_number}</td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('employee_name')}</td>
                                    <td>{detail.employee.name}</td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('claim_date')}</td>
                                    <td>{getDateFormat(detail.claim_date)}</td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('claim_type')}</td>
                                    <td>
                                        {detail.claim_type.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('claim_amount')}</td>
                                    <td>
                                        $ {detail.claim_amount}
                                    </td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('status')}</td>
                                    <td>{IMLocalized(detail.status)}</td>
                                </tr>
                                {detail.claim_type.medical && 
                                <>
                                <tr >
                                    <td>{IMLocalized('doctor')}</td>
                                    <td>{detail.medicalclaim.doctor}</td>
                                </tr>
                                <tr >
                                    <td>{IMLocalized('diagnosis')}</td>
                                    <td>{detail.medicalclaim.diagnosis}</td>
                                </tr>
                                <tr>
                                    <td>{IMLocalized('location')}</td>
                                    <td>{detail.medicalclaim.location}</td>
                                </tr>
                                </>}
                                <tr>
                                    <td >{IMLocalized('dependant')}</td>
                                    <td>
                                        <div className='custom-control custom-checkbox'>
                                            <input type="checkbox" id={'empallclaimviewdependant'} name="dependant" className="custom-control-input" checked={detail.dependant} readOnly={true} />
                                            <label className="custom-control-label" htmlFor={'empallclaimviewdependant'}></label>
                                        </div>
                                    </td>
                                </tr>
                                {detail.comments.length !== 0 &&
                                <tr>
                                    <td>{IMLocalized('comment')}</td>
                                    <td className='comment-td'>
                                        {detail.comments.map((item,index)=>{
                                        const word = item.comment;
                                        const employee_name = item.employee_name;
                                        return <div className='comment-td-detail' key={index}><span className='font-weight-bold'>{employee_name}</span><span>: {word}</span></div>
                                        })}
                                    </td>
                                </tr>}
                                {imglist.length !== 0 &&
                                <tr>
                                    <td>{IMLocalized('image')}</td>
                                    <td>
                                        <Row >
                                            {imglist.map((item,index)=>{
                                                return(
                                                    <Col lg="4" key={index} className="mt-1">
                                                        <div className='image-item mr-2' onClick={()=>{setImgToggle(true);setActiveImg(index)}}>
                                                            <div className='image-inner'>
                                                                <img src={item.src} className="img-thumbnail" alt={"This is Claim " +index} />
                                                            </div>
                                                        </div>
                                                    </Col>)
                                            })}
                                        </Row>
                                    </td>
                                </tr>
                                }
                                {approvers.length !== 0 && 
                                <tr>
                                    <td>{IMLocalized('approval_pending')}</td>
                                    <td>
                                        {approvers.map((item,index)=>{
                                            if(item.delegation_application_approval === true){
                                                return <div key={index}><span>{item.employee.name} ({IMLocalized('delegation')}) </span></div>
                                            }
                                            else{
                                                return <div key={index}><span>{item.employee.name}</span></div>
                                            }
                                        })}
                                    </td>
                                </tr>}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {imglist.length !== 0 && <Viewer activeIndex={activeImg} zIndex={1060} visible={img_toggle} onClose={()=>setImgToggle(false)} images={imglist} />}
                </>}
            </ModalBody>
            <ModalFooter>
                {detail?.status === 'PENDING' && <button className='btn btn-primary btn-sm' onClick={()=>singleApprove(true)}>{IMLocalized('approve')}</button>}
                {(detail?.status === 'PENDING' || detail?.status === 'APPROVED') && <button className='btn btn-danger btn-sm' onClick={()=>singleApprove(false)}>{IMLocalized('reject')}</button> }
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
            
        </Modal>
    )
}