import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { create_employee_delegation, reset_create_employee_delegation } from '../../../../action/employee/create_employee_delegation';
import { get_employee_delegation_list } from '../../../../action/employee/get_employee_delegation_list';
import { popError } from '../../../../func/popError';
import { IMLocalized } from '../../../../language/IMLocalized';

const initialState={
    start_date:'',
    end_date:'',
    err_start_date:'',
    err_end_date:'',
    delegation:'',
    err_delegation:'',
}

export default function ModalCreateDelegation({ toggle, closeModal}){

    const dispatch = useDispatch();
    const data = useSelector(state=>state.user_self.data);
    const managerlist = useSelector(state=>state.get_user_manager_list.data);
    const success = useSelector(state=>state.create_employee_delegation.data);
    const errors = useSelector(state=>state.create_employee_delegation.errors);
    const isLoading = useSelector(state=>state.create_employee_delegation.isLoading);

    const [state,setState] = useState(initialState);
    const [emp_id,setEmpID] = useState(null);

    useEffect(()=>{
        if(data.length !== 0){
            const {id} = data;
            setEmpID(id);
        }
    },[data])

    useEffect(()=>{
        if( toggle && success != null){
            closeModal();
            dispatch(get_employee_delegation_list());
            dispatch(reset_create_employee_delegation());
        }
    },[toggle,success,closeModal,dispatch])

    useEffect(()=>{
        if(toggle && errors != null){
            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
            dispatch(reset_create_employee_delegation());
        }
    },[toggle,errors, dispatch])

    useEffect(()=>{
        if(toggle){
            setState(initialState);
        }
    },[toggle])


    const createDelegation=()=>{
        if(state.start_date !== '' && state.end_date !== '' && state.delegation !== ''){
            dispatch(create_employee_delegation(state.start_date,state.end_date,state.delegation));
        }
        else{
            setState(prevState=>({
                ...prevState,
                err_delegation:state.delegation === '' ? IMLocalized('delegation_is_required') :'',
                err_end_date:state.end_date === '' ? IMLocalized('end_date_is_required') : '',
                err_start_date:state.start_date === '' ? IMLocalized('start_date_is_required') : '',
            }))
        }
    }

    const managerlist1 = useMemo(()=>{
        return managerlist.filter(item=>item.id !== emp_id);
    },[managerlist,emp_id])

    return(
        <Modal isOpen={toggle} size="lg" autoFocus={false}>
            <ModalHeader toggle={closeModal}>{IMLocalized('add_delegation')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='start_date'>{IMLocalized('start_date')}</label>
                            <Input size="sm" type="date" id="start_date" value={state.start_date} invalid={state.err_start_date.length >0} autoFocus={true}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({
                                    ...prevState,
                                    start_date:value,
                                    err_start_date:''
                                }))
                            }} 
                            />
                            <FormFeedback>{state.err_start_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='end_date'>{IMLocalized('end_date')}</label>
                            <Input size="sm" type="date" id="end_date" min={state.start_date?? null} value={state.end_date} invalid={state.err_end_date.length >0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({
                                    ...prevState,
                                    end_date:value,
                                    err_end_date:'',
                                }))
                            }}
                            />
                            <FormFeedback>{state.err_end_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='delegation'>{IMLocalized('delegation')}</label>
                            <Input size="sm" type="select" id="delegation" value={state.delegation} invalid={state.err_delegation.length > 0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,delegation:value,err_delegation:''}));
                            }}>
                                <option></option>
                                {managerlist1.map((item)=>{
                                    return <option value={item.id} key={item.id}>{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{state.err_delegation}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button className="btn btn-primary btn-sm" onClick={createDelegation} >{IMLocalized('create')}</button>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}