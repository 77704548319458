import * as contractcostcenter from '../action/create_contract_cost_center';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const create_contract_cost_center = (state= initialState,action)=>{
    switch(action.type){
        case contractcostcenter.CREATECONTRACTCOSTCENTER_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case contractcostcenter.CREATECONTRACTCOSTCENTER_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractcostcenter.CREATECONTRACTCOSTCENTER_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case contractcostcenter.CREATECONTRACTCOSTCENTER_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default create_contract_cost_center;