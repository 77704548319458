import { useSelector } from "react-redux";
import '../../../css/table.css';

export default function ScheduleTable({id,value,onClick}){
    const choices = useSelector(state=>state.schedule_dropdown.calendar_choices);

    if(choices.hasOwnProperty(value)){
        const result = choices[value];

        return <td  id={id} className={`schedule_td cursor-pointer ${result.table}`} onClick={onClick}>{result.name}</td>
    }
    else{
        return null;
    }
}