import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { getDateFormat } from '../../../func/getDateFormat';
import { IMLocalized } from '../../../language/IMLocalized';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { get_employee_list_detail } from '../../../reducers/employee/get_employee_list';
import { get_appraisal_form_list_detail } from '../../../reducers/appraisal/get_appraisal_form_list';

export default function ModalViewAppraisalGroup({ detail, toggle, closeModal }){

    const data = useSelector(state=>get_employee_list_detail(state,detail?.appraiser));
    const form_data = useSelector(state=>get_appraisal_form_list_detail(state,detail?.form));

    return(
        <Modal isOpen={toggle} size="lg">
            {detail &&
            <>
            <ModalHeader toggle={closeModal}>{IMLocalized('view')} {detail.name}</ModalHeader>
            <ModalBody>
                <Table size="sm" className='align-items-center table-bordered' responsive>
                    <tbody>
                        <tr>
                            <td width="25%">{IMLocalized('name')}</td>
                            <td>{detail.name}</td>
                        </tr>
                        <tr>
                            <td width="25%">{IMLocalized('year')}</td>
                            <td>{detail.year}</td>
                        </tr>
                        <tr>
                            <td width="25%">{IMLocalized('from_date')}</td>
                            <td>{getDateFormat(detail.from_date)}</td>
                        </tr>
                        <tr>
                            <td width="25%">{IMLocalized('to_date')}</td>
                            <td>{getDateFormat(detail.to_date)}</td>
                        </tr>
                        <tr>
                            <td width="25%">{IMLocalized('created_date')}</td>
                            <td>{moment(new Date(detail.created)).format('DD/MM/YYYY HH:mm:ss')}</td>
                        </tr>
                        <tr>
                            <td width="25%">{IMLocalized('appraiser_name')}</td>
                            <td>{data?.name ?? '-'}</td>
                        </tr>
                        <tr>
                            <td width="25%">{IMLocalized('form')}</td>
                            <td>{form_data?.name}</td>
                        </tr>
                    </tbody>
                </Table>
                <h4 className='mb-0'>{IMLocalized('appraisal')}</h4>
                <div className='ag-theme-balham' style={{height:'300px', width:'100%' }}>
                    <AgGridReact
                        columnDefs={[
                            {
                                headerName:IMLocalized('agrid_emp_id'),
                                field:'employee.employee_number',
                                editable:false,
                                width:100
                            },
                            {
                                headerName:IMLocalized('agrid_emp_name'),
                                field:'employee.name',
                                editable:false,
                                width:150
                            },
                            {
                                headerName:IMLocalized('agrid_department'),
                                field:'employee.contract_cache.department',
                                editable:'false',
                                width:150
                            },
                            {
                                headerName:IMLocalized('agrid_section'),
                                field:'employee.contract_cache.section',
                                editable:'false',
                                width:150
                            },
                            {
                                headerName:IMLocalized('agrid_job_title'),
                                field:'employee.contract_cache.job_title',
                                editable:'false',
                                width:150
                            },
                            {
                                headerName:IMLocalized('agrid_job_level'),
                                field:'employee.contract_cache.job_level',
                                editable:'false',
                                width:150
                            }
                        ]}
                        enableSorting={true}
                        enableColResize={true}
                        defaultColDef={{
                            sortable:true
                        }}
                        rowData={detail.appraisals}
                    />
                </div>
            </ModalBody>
            </>}
            <ModalFooter>
                <button className='btn btn-danger btn-sm' onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
