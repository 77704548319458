import * as emprelationship from '../../action/employee/get_employee_relationship';

const initialState={
    isLoading:false,
    data:[],
    errors:null,
}

const get_employee_relationship = (state=initialState,action)=>{
    switch(action.type){
        case emprelationship.GETEMPRELATIONSHIP_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[],
                errors:null,
            }
        case emprelationship.GETEMPRELATIONSHIP_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case emprelationship.GETEMPRELATIONSHIP_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_relationship;


export const emp_get_employee_relationship = (state,id)=>{
    const { data } = state.get_employee_relationship;

    return id != null ? data.filter(item=>item.employee === id) : [];
}
