import React, { useEffect, useState } from 'react';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';



const initialState={
    type:'',
    err_type:'',
    title:'',
    err_title:'',
    minRateDescription:'',
    maxRateDescription:'',
    err_min_des:'',
    err_max_des:'',
    rateMax:'5',
    err_rate_max:'',
    isRequired:false,
    index:null,
    name:'',
}

export default function ModalEditAppraisalQuestion({ toggle, detail, updateQuestion, closeModal}){

    const [state,setState]= useState(initialState);


    useEffect(()=>{
        if(toggle && detail){
            setState(prevState=>({
                ...prevState,
                ...detail,
                rateMax:detail?.rateMax ? detail.rateMax.toString() : '5' 
            }))
        }
        else{
            setState(initialState);
        }
    },[toggle,detail])

    const checkInt =(index)=>{
        if(index.indexOf(".") === -1){
            return true;
        }
        else{
            return false;
        }

    }

    const updateQuestion1=()=>{
        if(state.title !== '' && state.type !==''){
            if(state.type === 'rating' && state.maxRateDescription !== '' && state.minRateDescription !== '' && checkInt(state.rateMax) && parseInt(state.rateMax) >=3 && parseInt(state.rateMax) <=20 ){
                updateQuestion({
                    type:state.type,
                    title:state.title,
                    isRequired:state.isRequired,
                    minRateDescription:state.minRateDescription,
                    maxRateDescription:state.maxRateDescription,
                    rateMax:parseInt(state.rateMax),
                    index:state.index,
                    name:state.name,
                });
            }
            else{
                let error = '';
                if(state.rateMax === '' )error = IMLocalized('max_rate_is_required');
                else if (!checkInt(state.rateMax)) error = IMLocalized('max_rate_cant_decimal');
                else if (parseInt(state.rateMax) <3 || parseInt(state.rateMax)>20) error = IMLocalized('max_rate_between_3_20');

                setState(prevState=>({
                    ...prevState,
                    err_max_des:state.maxRateDescription === '' ? IMLocalized('description_is_required') : '',
                    err_min_des:state.minRateDescription === '' ? IMLocalized('description_is_required') : '',
                    err_rate_max:error
                }))
            }   

            if(state.type === 'text'){
                updateQuestion({ name:state.name, index:state.index, type:state.type,title:state.title,isRequired:state.isRequired});
            } 
        }
        else{

            setState(prevState=>({
                ...prevState,
                err_title:state.title === '' ? IMLocalized('title_required') : '',
                err_type:state.type === '' ? IMLocalized('type_required') :'', 
            }))
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('edit_question')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='type'>{IMLocalized('type')}</label>
                            <Input type="select" size="sm" value={state.type} id="type" invalid={state.err_type.length >0}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,type:value,err_type:''}))
                            }}>
                                <option></option>
                                <option value="text">{IMLocalized('text')}</option>
                                <option value="rating">{IMLocalized('rating')}</option>
                            </Input>
                            <FormFeedback>{state.err_type}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='title'>{IMLocalized('title')}</label>
                            <Input value={state.title} size="sm" id="title" type="textarea" invalid={state.err_title.length > 0}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({
                                    ...prevState,
                                    title:value,
                                    err_title:'',
                                }))
                            }}
                            />
                            <FormFeedback>{state.err_title}</FormFeedback>
                        </FormGroup>
                    </Col>
                    {state.type === 'rating' &&
                    <>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='min_des'>{IMLocalized('min_rate_description')}</label>
                            <Input id="min_des" size="sm" value={state.minRateDescription} type="text" invalid={state.err_min_des.length >0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,minRateDescription:value,err_min_des:''}));
                            }}/>
                            <FormFeedback>{state.err_min_des}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='max_des'>{IMLocalized('max_rate_description')}</label>
                            <Input id="max_des" size="sm" value={state.maxRateDescription} type="text" invalid={state.err_max_des.length > 0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,maxRateDescription:value,err_max_des:''}));
                            }}
                            />
                            <FormFeedback>{state.err_max_des}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='rate_max'>{IMLocalized('max_rate')}</label>
                            <Input id="rate_max" size="sm" value={state.rateMax} type="number" min="3" max="20" invalid={state.err_rate_max.length >0} step="1"
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,rateMax:value,err_rate_max:''}))
                            }} />
                            <FormFeedback>{state.err_rate_max}</FormFeedback>
                        </FormGroup>
                    </Col>
                    </>}
                    <Col lg="6" className='d-flex align-items-center'>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="feedbackrequired?" name='isRequired' className="custom-control-input" defaultChecked={state.isRequired} 
                            onChange={(e)=>{
                                const checked = e.target.checked;
                                setState(prevState=>({...prevState,isRequired:checked}));
                            }} 
                            />
                            <label className="custom-control-label" htmlFor="feedbackrequired?">{IMLocalized('is_required')}</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary btn-sm" onClick={()=>updateQuestion1()}>{IMLocalized('update')}</button>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}