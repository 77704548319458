import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { usePrevious } from '../../../hook/usePrevious';
import { authErrors } from '../../../reducers/rootReducer';

export default function ModalOtp({toggle,closeModal,submitOtp}){

    const errors = useSelector(state=>authErrors(state))
    const prevErrors = usePrevious(errors);
    const [otp, setOtp] = useState('');
    const [error_otp,setErrOtp] = useState('');

    useEffect(()=>{
        if(prevErrors !== undefined && prevErrors !== errors && errors.length !== 0){
            if('non_field_errors' in errors){
                const word = errors['non_field_errors'].toString();
                setErrOtp(word);
            }
            if('otp' in errors){
                const word = errors['otp'].toString();
                setErrOtp(word);
            }
        }
    },[errors,prevErrors])

    useEffect(()=>{
        if(toggle){
            setOtp('');
            setErrOtp('');
        }
    },[toggle])

    const onSubmit=(e)=>{
        e.preventDefault();
        if(otp !== ''){
            submitOtp(otp);
        }
        else{
            setErrOtp(IMLocalized('otp_invalid'));
        }
    }

    return(
        <Modal size="sm" isOpen={toggle} autoFocus={false} className="modal-dialog-centered">
            <ModalHeader toggle={closeModal}>{IMLocalized('please_enter_otp')}</ModalHeader>
            <ModalBody>
                <form onSubmit={onSubmit}>
                    <div className='d-flex justify-content-center mt-2'>
                        <OtpInput
                        value={otp}
                        onChange={(value)=>{setOtp(value); setErrOtp('') }}
                        numInputs={6}
                        separator={<span> - </span>}
                        style={{width:'100%'}}
                        inputStyle={{width:'40px',height:'50px'}}
                        shouldAutoFocus={true}
                        />
                    </div>
                    <div className="d-flex justify-content-center pt-2">
                        <small className="text-red">{error_otp}</small>
                    </div>
                    <div className="d-flex justify-content-center pt-1">
                        <button style={{width:'80%'}} type="submit" className="btn btn-primary btn-block" >{IMLocalized('signin')}</button>
                    </div>
                    <div className="d-flex justify-content-center pt-1">
                        <button className="btn btn-danger btn-block" style={{width:'80%'}} onClick={closeModal}>{IMLocalized('close')}</button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}