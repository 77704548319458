import * as shg from '../../action/employee/get_employee_shg';

const initialState={
    data:{},
    errors:[],
    isLoading:false
}

const get_employee_shg = (state=initialState,action)=>{
    switch(action.type){
        case shg.GETEMPSHG_REQUEST:
            return{
                isLoading:true,
                errors:[],
                data:{}
            }
        case shg.GETEMPSHG_SUCCESS:
            return{
                isLoading:false,
                data:action.payload.data,
                errors:[]
            }
        case shg.GETEMPSHG_FAILURE:
            return{
                isLoading:false,
                data:{},
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_shg;