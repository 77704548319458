import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATECOM_SUCCESS = 'CREATECOM_SUCCESS';
export const CREATECOM_REQUEST = 'CREATECOM_REQUEST';
export const CREATECOM_FAILURE = 'CREATECOM_FAILURE';
export const CREATECOM_RESET = 'CREATECOM_RESET';

export const create_company = (name,contact_number,uen,cpf_payment_type,cpf_serial_number,unit_number,sg_postal_code,address)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATECOM_REQUEST'
        });
        
        myaxios.post('company/company/',{
            address:address,
            unit_number:unit_number,
            sg_postal_code:sg_postal_code,
            UEN:uen,
            CPF_payment_type:cpf_payment_type,
            CPF_serial_number:cpf_serial_number,
            contact_number:contact_number,
            name:name,
        })
        .then((response)=>{
            dispatch({
                type:'CREATECOM_SUCCESS',
                payload:response
                
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            })
        })
        .catch((error) =>{
            dispatch({
                type: 'CREATECOM_FAILURE',
                payload: error,
                error:true,
            });
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_company = () => dispatch =>{
    dispatch({
        type:'CREATECOM_RESET'
    });

}