import * as education from '../../action/employee/get_employee_education';


const initialState={
    data:[],
    errors:null,
    isLoading:false
}

const get_employee_education = (state=initialState,action)=>{
    switch(action.type){
        case education.GETEMPEDUCATION_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[],
                errors:null
            }
        case education.GETEMPEDUCATION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case education.GETEMPEDUCATION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_education;

export const emp_get_employee_education = (state,id)=>{
    const { data } = state.get_employee_education;

    return id != null ? data.filter(item=>item.employee === id).sort((a,b)=>a.id - b.id) : [];
}