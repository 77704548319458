import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const IMPORTCAS_REQUEST = 'IMPORTCAS_REQUEST';
export const IMPORTCAS_SUCCESS = 'IMPORTCAS_SUCCESS';
export const IMPORTCAS_FAILURE = 'IMPORTCAS_FAILURE';
export const IMPORTCAS_RESET = ' IMPORTCAS_RESET';

export const import_cas = (department,section,jobtitle,joblevel,costcenter)=>{
    return dispatch=>{
        dispatch({
            type:'IMPORTCAS_REQUEST'
        });
        
        myaxios.post('company/import_cas/',{
            department:department,
            section:section,
            joblevel:joblevel,
            jobtitle:jobtitle,
            costcenter:costcenter
        })
        .then((response)=>{
            dispatch({
                type:'IMPORTCAS_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'IMPORTCAS_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}

export const remove_import_cas = () => dispatch =>{
    dispatch({
        type:'IMPORTCAS_RESET'
    });
}