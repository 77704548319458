import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { delete_pay_component } from '../../../action/payroll/delete_pay_component';
import Swal from 'sweetalert2';
import ModalBonus from '../payslip_modal/ModalBonus';

const mapStateToProps=(state)=>({

})

const mapDispatchToProps=(dispatch)=>({
    delete_pay_component:(id,data)=>{
        dispatch(delete_pay_component(id,data))
    },
})

function BonusTable(props){

    const [toggle,setToggle] = useState(false);

    return(
        <>
        <Card>
            <CardHeader className='p-2 border-0 bg-white d-flex justify-content-between'>
                <h4 className='text-primary pl-2 pt-2'>{IMLocalized('payslip_bonus')}</h4>
                <div className='d-flex align-items-center mr-2'>
                    <button className='btn btn-primary btn-sm' onClick={()=>setToggle(true)}>{IMLocalized('add')}</button>
                </div>
            </CardHeader>
            {props.list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
            {props.list.length !== 0 &&
            <Table size="sm" className="align-items-center" responsive>
                <thead className="text-center thead-light p-2">
                    <tr>
                        <th className="p-2">{IMLocalized('name')}</th>
                        <th className="p-2">{IMLocalized('amount')}</th>
                        <th className="p-2">{IMLocalized('delete')}</th>
                        
                    </tr>
                </thead>
                <tbody className='text-center p-2'>
                    {props.list.map((item,index)=>{
                        return(
                            <tr key={index}>
                                <td className="p-2">Bonus</td>
                                <td className="p-2">$ {item.amount.toFixed(2)}</td>
                                <td className="p-2" align="center" valign="center">
                                    <div className="delete_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-trash text-danger cursor-pointer"
                                            onClick={()=>{
                                                Swal.fire({
                                                    type:"warning",
                                                    title:`${IMLocalized('delete_bonus_confirmation')}`,
                                                    showCancelButton:true,
                                                    confirmButtonText:IMLocalized('yes!'),
                                                    cancelButtonText:IMLocalized('no!')
                                                })
                                                .then((result)=>{
                                                    if(result.value){
                                                        const data = [{id:item.id}];
                                                        props.delete_pay_component(props.id,data);
                                                    }
                                                })
                                            }}  
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>}
        </Card>
        <ModalBonus toggle={toggle} payslipcontract={props.payslipcontract} id={props.id} payslip_id={props.payslip_id} closeModal={()=>setToggle(false)} emp={props.emp} />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(BonusTable);