import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_general_dashboard } from "../action/dashboard/get_general_dashboard";
import { get_employee_list } from "../action/employee/get_employee_list";

export const NewMainContext = createContext();

function getBoolean(val) {
    return !!JSON.parse(String(val).toLowerCase());
}

export default function NewMainContextProvider({children}){
    const dispatch = useDispatch();
    const general = useSelector(state=>state.general_group.data);
    const [ role, setRole] = useState([]);
    const [ current_user, setCurrentUser] = useState(''); 
    const [ action_toggle, setActionToggle] = useState(getBoolean(localStorage.getItem('action_toggle')));
    const [ key, setKey] = useState(JSON.parse(localStorage.getItem('currentRole')));
    const [ currentnavs, setCurrentNavs] = useState(JSON.parse(localStorage.getItem('navtabs')));
    const [ activeTab, setActiveTab] = useState(localStorage.getItem('activeTabs'));
    const [ menu, setMenu] = useState(false); 
    const [ companylist_step, setCompanyListStep] = useState(parseInt(localStorage.getItem('companylist_step')));
    const [ overtime_activeTab, setOvertimeActiveTab] = useState(localStorage.getItem('otal'));
    const [ cas_activeTab, setCasActiveTab] = useState(localStorage.getItem('add_set'));
    const [ employeelist_step, setEmployeeListStep] = useState(localStorage.getItem('employeelist_step'));
    const [ emp_step, setEmpStep] = useState(localStorage.getItem('emp_step'));
    const [ employee_profile_id, setEmpProfileId] = useState(localStorage.getItem('emp_profile_id'));
    const [ emplist_reset, setEmpListReset] = useState(0);
    const [ createemp_reset, setCreateEmpReset] = useState(0);
    const [ holiday_step, setHolidayStep] = useState(localStorage.getItem('holiday_step'));
    const [ holiday_id, setHolidayID] = useState(localStorage.getItem('holiday_id'));
    const [ payroll_step, setPayrollStep] = useState(localStorage.getItem('payroll_step'));
    const [ payroll_id, setPayrollID] = useState(localStorage.getItem('payroll_id')); 
    const [ payslip_emp_id, setPayslipEmpID] = useState(localStorage.getItem('payslip_emp_id'));
    const [ monthly_report_reset, setMonthlyReportReset ] = useState(false);
    const [ payroll_variance_reset, setPayrollVarianceReset] = useState(false);
    const [ payroll_computation_summary_reset, setPayrollComputationSummaryReset] = useState(false);
    const [ payroll_computation_headcount_reset, setPayrollComputationHeadcountReset] = useState(false);
    const [ leave_calendar_apply_detail, setLeaveCalendarApplyDetail] = useState(null);
    const [ leave_calendar_reset, setLeaveCalendarReset] = useState(false);
    const [ leave_calendar_refresh, setLeaveCalendarRefresh] = useState(false);
    const [ leave_table_reset, setLeaveTableReset] = useState(false);
    const [ leave_reset_step, setLeaveResetStep] = useState(localStorage.getItem('leave_reset_step'));
    const [ leave_reset_list_id, setLeaveResetListId] = useState(localStorage.getItem('leave_resetlist_id'));
    const [ claim_all_list_step, setClaimAllListStep] = useState(localStorage.getItem('claim_alllist_step'));
    const [ claim_all_list_pay_id, setClaimAllListPayId] = useState(localStorage.getItem('claim_alllist_pay_id'));
    const [ claim_all_list_reset, setClaimAllListReset] = useState(false);
    const [ create_claim_scheme_reset, setCreateClaimSchemeReset] = useState(false);
    const [ pay_summary_list_reset, setPaySummaryListReset] = useState(false);
    const [ payroll_increment_reset, setPayrollIncrementReset] = useState(false);
    const [ general_history_reset, setGeneralHistoryReset] = useState(false);
    const [ leave_approvals_calendar_reset, setLeaveApprovalsCalendarReset ] = useState(false);
    const [ leave_approvals_calendar_refresh, setLeaveApprovalsCalendarRefresh] = useState(false);
    const [ import_employee_contract_reset, setImportEmployeeContractReset] = useState(false);
    const [ import_additional_setting_reset, setImportAdditionalSettingReset] = useState(false);
    // const [ claim_approvals_list_reset, setClaimApprovalsListReset] = useState(false);
    const [ leave_approval_scheme_reset, setLeaveApprovalSchemeReset] = useState(false);
    const [ claim_approval_scheme_reset, setClaimApprovalSchemeReset] = useState(false);
    const [ appraisal_list_step, setAppraisalListStep] = useState(0);
    const [ appraisal_group_list_step, setAppraisalGroupListStep] = useState(0);
    const [ appraisal_action_step, setAppraisalActionStep] = useState(0);
    const [ iras_step, setIrasStep] = useState(localStorage.getItem('iraslist_step'));
    const [ iras_id, setIrasId] = useState(localStorage.getItem('irasdetail_id'));
    const [ is_redirect, setIsRedirect] = useState(false);
    const [ import_bank_reset, setImportBankReset] = useState(false);
    const [ payroll_payslip_employee_reset, setPayrollPayslipEmployeeReset] = useState(false);
    const [ import_employee_contact_reset, setImportEmployeeContactReset] = useState(false);
    const [ import_employee_passport_reset, setImportEmployeePassportReset] = useState(false);
    const [ import_employee_permit_reset, setImportEmployeePermitReset] = useState(false);
    const [ monthly_report_reset2, setMonthlyReportReset2] = useState(false);
    const [ payroll_increment_step, setPayrollIncrementStep] = useState(0);
    const [ is_edit, setIsEdit] = useState(false);
    const [ user_step,setUserStep] = useState(localStorage.getItem('user_step'));
    const [ user_employee_step,setUserEmployeeStep] = useState(localStorage.getItem('user_emp_step'));
    const [ user_payroll_id,setUserPayrollId] = useState(localStorage.getItem('user_payroll_id'));
    const [ user_leave_id,setUserLeaveId] = useState(localStorage.getItem('user_leave_id'));
    const [ user_claim_id,setUserClaimId] = useState(localStorage.getItem('user_claim_id'));
    const [ manager_appraisal_reset, setManagerAppraisalReset] = useState(false);
    const [ leave_scheme_reset, setLeaveSchemeReset] = useState(false);
    const [ claim_scheme_reset, setClaimSchemeReset] = useState(false);

    useEffect(()=>{
        if(general != null){
            setRole(general);
        }
    },[general])

    useEffect(()=>{
        if(key != null && role.length !== 0){
            const current_user = role[parseInt(key)];
            setCurrentUser(current_user);
            localStorage.setItem('currentRole',key);
        }
    },[key,role])


    const addItem=(id,name)=>{
        setMenu(false);
        const detail = currentnavs.find(item=>item.id === id);
        if(!detail){
            setCurrentNavs(prevState=>([...prevState,{ id, name}]));
            const new_nav = [...currentnavs, {id,name} ];
            localStorage.setItem('navtabs',JSON.stringify(new_nav));
            
        }
        if(id === '1'){
            dispatch(get_general_dashboard());
        }
        localStorage.setItem('activeTabs',id);
        setTimeout(()=>{
            setActiveTab(id);
        },100)
       
    }

    const removeItem=(id)=>{
        
        if(current_user === 'hr'){
            if(id === '2'){
                localStorage.setItem('companylist_step','0');
                setCompanyListStep(0);
            }
            else if(id === '4'){
                localStorage.setItem('add_set','1');
                setCasActiveTab('1');
            }
            else if(id === '5'){
                localStorage.setItem('otal','1');
                setOvertimeActiveTab('1');
            }
            else if(id === '7'){
                localStorage.setItem('employeelist_step','0');
                setEmployeeListStep('0');
                setEmpListReset(true);
            }
            else if(id === '9'){
                setCreateEmpReset(prev=>(prev+1));
            }
            else if(id === '12'){
                setHolidayStep('0');
                localStorage.setItem('holiday_step','0');
            }
            else if(id === '15'){
                setPayrollStep('0');
                localStorage.setItem('payroll_step','0');
            }
            else if(id === '16'){
                setMonthlyReportReset(true);
            }
            else if(id === '17'){
                setPayrollVarianceReset(true);
            }
            else if(id === '18'){
                setPayrollComputationSummaryReset(true);
            }
            else if(id === '19'){
                setPayrollComputationHeadcountReset(true);
            }
            else if(id === '24'){
                setLeaveCalendarReset(true);
            }
            else if(id === '26'){
                setLeaveTableReset(true);
            }
            else if(id === '30'){
                setLeaveSchemeReset(true);
            }
            else if(id === '33'){
                setLeaveResetStep('0');
                localStorage.setItem('leave_reset_step','0');
            }
            else if(id === '34'){
                setClaimAllListStep('0');
                localStorage.setItem('claim_alllist_step','0');
                setClaimAllListReset(true);
            }
            else if(id === '38'){
                setClaimSchemeReset(true);
            }
            else if(id === '39'){
                setCreateClaimSchemeReset(true);
            }
            else if(id === '41'){
                setPaySummaryListReset(true);
            }
            else if(id === '42'){
                setPayrollIncrementReset(true);
            }
            else if(id === '44'){
                setGeneralHistoryReset(true);
            }
            else if(id === '46'){
                setLeaveApprovalsCalendarReset(true);
            }
            else if(id === '47'){
                setImportEmployeeContractReset(true);
            }
            else if(id === '48'){
                setImportAdditionalSettingReset(true);
            }
            // else if(id === '54'){
            //     setClaimApprovalsListReset(true);
            // }
            else if(id === '62'){
                setLeaveApprovalSchemeReset(true);
            }
            else if(id === '63'){
                setClaimApprovalSchemeReset(true);
            }
            else if(id === '68'){
                setAppraisalListStep(0);
            }
            else if ( id === '69'){
                setAppraisalGroupListStep(0);
            }
            else if(id === '70'){
                setAppraisalActionStep(0);
            }
            else if(id === '77'){
                setIrasStep('0');
            }
            else if(id === '79'){
                setImportBankReset(true);
            }
            else if(id === '81'){
                setPayrollPayslipEmployeeReset(true);
            }
            else if(id === '82'){
                setImportEmployeeContactReset(true);
            }
            else if(id === '83'){
                setImportEmployeePassportReset(true);
            }
            else if(id === '84'){
                setImportEmployeePermitReset(true);
            }
            else if(id === '85'){
                setMonthlyReportReset2(true);
            }
            else if(id === '88'){
                setPayrollIncrementStep(0);
            }
            removeIt(id);
        }
        else if (current_user === 'manager' || current_user === 'user'){
            if(id === '2'){
                setUserEmployeeStep('0');
                setUserStep('1')
                localStorage.setItem('user_emp_step','0');
            }
            else if(id === '3'){
                setClaimAllListReset(true);
            }
            else if(id === '4'){
                setLeaveTableReset(true);
            }
            else if(id === '5'){
                setLeaveCalendarReset(true);
            }
            // else if(id === '7'){
            //     setClaimApprovalsListReset(true);
            // }
            else if(id === '9'){
                setLeaveApprovalsCalendarReset(true);
            }
            else if(id === '10'){
                setManagerAppraisalReset(true);
            }
            removeIt(id);
        }
        else if (current_user === 'claim_officer'){
            if(id === '2'){
                setEmpListReset(true);
            }
            else if(id === '3'){
                setClaimAllListReset(true);
            }
            removeIt(id);
        }
        else if (current_user === 'leave_officer'){
            if(id === '2'){
                setEmpListReset(true);
            }
            else if(id === '3'){
                setLeaveTableReset(true);
            }
            else if(id === '4'){
                setLeaveCalendarReset(true);
            }
            removeIt(id);
        }
    }

    const removeIt = (remove_id) =>{
        const updatednavs = currentnavs.filter(item=>item.id !== remove_id);
        if(updatednavs.length !== 0 ){
            const navs = JSON.stringify(updatednavs);
            const detail =  updatednavs[updatednavs.length -1];
            const {id} = detail;
            if(id === '1'){
                if(current_user === 'hr'){
                    dispatch(get_employee_list());
                }
                dispatch(get_general_dashboard());
            }
            setActiveTab(id);
            localStorage.setItem('activeTabs',id);
            localStorage.setItem('navtabs',navs);
            setCurrentNavs(updatednavs);
        }
        else{
            localStorage.setItem('navtabs','[{"id":"1","name":"dashboard"}]');
            localStorage.setItem('activeTabs','1');
            setActiveTab('1');
            setCurrentNavs([{id:"1",name:"dashboard"}]);
            if(current_user === 'hr'){
                dispatch(get_employee_list());
            }
            dispatch(get_general_dashboard());
        }
    }

    const resetTab = () =>{
        localStorage.setItem('navtabs','[{"id":"1","name":"dashboard"}]');
        localStorage.setItem('activeTabs','1');
        setActiveTab('1');
        setCurrentNavs([{id:"1",name:"dashboard"}]);
    }

    return(
        <NewMainContext.Provider value={{
            key,
            action_toggle,
            current_user,
            setCurrentUser,
            setActionToggle,
            setKey,
            role,
            activeTab,
            setActiveTab,
            setCurrentNavs,
            currentnavs,
            menu,
            setMenu,
            addItem,
            removeItem,
            companylist_step,
            setCompanyListStep,
            overtime_activeTab,
            setOvertimeActiveTab,
            cas_activeTab,
            setCasActiveTab,
            employeelist_step,
            setEmployeeListStep,
            employee_profile_id,
            setEmpProfileId,
            emp_step,
            setEmpStep,
            emplist_reset,
            setEmpListReset,
            createemp_reset,
            setCreateEmpReset,
            holiday_id,
            setHolidayID,
            holiday_step,
            setHolidayStep,
            payroll_id,
            payroll_step,
            payslip_emp_id,
            setPayrollID,
            setPayrollStep,
            setPayslipEmpID,
            monthly_report_reset,
            setMonthlyReportReset,
            payroll_variance_reset,
            setPayrollVarianceReset,
            payroll_computation_summary_reset,
            setPayrollComputationSummaryReset,
            payroll_computation_headcount_reset,
            setPayrollComputationHeadcountReset,
            leave_calendar_apply_detail,
            setLeaveCalendarApplyDetail,
            leave_calendar_reset,
            setLeaveCalendarReset,
            leave_calendar_refresh,
            setLeaveCalendarRefresh,
            leave_table_reset,
            setLeaveTableReset,
            leave_reset_list_id,
            setLeaveResetListId,
            leave_reset_step,
            setLeaveResetStep,
            claim_all_list_pay_id,
            claim_all_list_step,
            setClaimAllListPayId,
            setClaimAllListStep,
            claim_all_list_reset,
            setClaimAllListReset,
            create_claim_scheme_reset,
            setCreateClaimSchemeReset,
            pay_summary_list_reset,
            setPaySummaryListReset,
            payroll_increment_reset,
            setPayrollIncrementReset,
            general_history_reset,
            setGeneralHistoryReset,
            leave_approvals_calendar_reset,
            setLeaveApprovalsCalendarReset,
            leave_approvals_calendar_refresh,
            setLeaveApprovalsCalendarRefresh,
            import_employee_contract_reset,
            setImportEmployeeContractReset,
            import_additional_setting_reset,
            setImportAdditionalSettingReset,
            // claim_approvals_list_reset,
            // setClaimApprovalsListReset,
            leave_approval_scheme_reset,
            setLeaveApprovalSchemeReset,
            claim_approval_scheme_reset,
            setClaimApprovalSchemeReset,
            appraisal_list_step,
            setAppraisalListStep,
            appraisal_group_list_step,
            setAppraisalGroupListStep,
            appraisal_action_step,
            setAppraisalActionStep,
            iras_step,
            setIrasStep,
            is_redirect,
            setIsRedirect,
            iras_id,
            setIrasId,
            import_bank_reset,
            setImportBankReset,
            payroll_payslip_employee_reset,
            setPayrollPayslipEmployeeReset,
            import_employee_contact_reset,
            setImportEmployeeContactReset,
            import_employee_passport_reset,
            setImportEmployeePassportReset,
            import_employee_permit_reset,
            setImportEmployeePermitReset,
            monthly_report_reset2,
            setMonthlyReportReset2,
            payroll_increment_step,
            setPayrollIncrementStep,
            resetTab,
            is_edit,
            setIsEdit,
            user_step,
            setUserStep,
            user_employee_step,
            setUserEmployeeStep,
            user_payroll_id,
            setUserPayrollId,
            user_leave_id,
            setUserLeaveId,
            user_claim_id,
            setUserClaimId,
            manager_appraisal_reset,
            setManagerAppraisalReset,
            leave_scheme_reset,
            setLeaveSchemeReset,
            claim_scheme_reset,
            setClaimSchemeReset,
        }}>
            {children}
        </NewMainContext.Provider>
    )
}