import * as claimnoscheme from '../../action/claim/get_claim_employee_noscheme';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const get_claim_employee_noscheme = (state=initialState,action)=>{
    switch(action.type){
        case claimnoscheme.GETCLAIMEMPNOSCHEME_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case claimnoscheme.GETCLAIMEMPNOSCHEME_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case claimnoscheme.GETCLAIMEMPNOSCHEME_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_claim_employee_noscheme;