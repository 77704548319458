import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../../language/IMLocalized';
import {delete_pay_component} from '../../../action/payroll/delete_pay_component';
import ModalBackpay from '../payslip_modal/ModalBackpay';

const mapStateToProps=(state)=>({
    pay_component_type:state.dropdown_list.pay_component_type,
})

const mapDispatchToProps=(dispatch)=>({
    delete_pay_component:(id,data)=>{
        dispatch(delete_pay_component(id,data))
    },
})

function BackpayTable(props){

    const [list,setList] = useState([]);
    const [toggle,setToggle] = useState(false);

    useEffect(()=>{
        if(props.type === 'backpay'){
            const arr = props.list.filter(item=>item.deductable === false);
            setList(arr);
        }
        else{
            
            setList(props.list);
        }
    },[props.list,props.type])

    return(
        <>
        <Card>
            <CardHeader className="p-2 border-0 bg-white d-flex justify-content-between">
                <h4 className="text-primary pl-2 pt-2">
                    {IMLocalized('payslip_other_pay')}
                </h4>
                <div className='d-flex align-items-center mr-2'>
                    <button className='btn btn-primary btn-sm' onClick={()=>setToggle(true)}>{IMLocalized('add')}</button>
                </div>
            </CardHeader>
            {list.length === 0 &&  <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
            {list.length !== 0 &&
            <Table size="sm" className="align-items-center" responsive>
                <thead className="text-center p-2 thead-light">
                    <tr>
                        <th className="p-2">{IMLocalized('name')}</th>
                        <th className="p-2">{IMLocalized('type')}</th>
                        <th className="p-2">{IMLocalized('wage')}</th>
                        <th className="p-2">{IMLocalized('amount')}</th>
                        <th className="p-2">{IMLocalized('delete')}</th>
                    </tr>
                </thead>
                <tbody className="text-center p-2">
                    {list.map((item,index)=>{

                        const pay_detail = props.pay_component_type.find(obj=>obj.value === item.pay_type );
                        const name = pay_detail ? pay_detail.display : '';
                        return(
                            <tr key={index}>
                                <td className="p-2">{item.name}</td>
                                <td className="p-2">{IMLocalized(item.pay_type)}</td>
                                <td>{IMLocalized(item.wage)}</td>
                                <td className="p-2">$ {item.amount.toFixed(2)}</td>
                                <td className="p-2" align="center" valign="center">
                                    <div className="delete_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-trash text-danger cursor-pointer"
                                        onClick={()=>{
                                            Swal.fire({
                                                type:"warning",
                                                title:`${IMLocalized('delete_backpay_confirmation')} (${name}) !`,
                                                showCancelButton:true,
                                                confirmButtonText:IMLocalized('yes!'),
                                                cancelButtonText:IMLocalized('no!')
                                            })
                                            .then((result)=>{
                                                if(result.value){
                                                    props.delete_pay_component(props.id,[{id:item.id}])
                                                }
                                            })
                                        }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>}
        </Card>
        <ModalBackpay toggle={toggle} payslipcontract={props.payslipcontract} id={props.id} payslip_id={props.payslip_id} closeModal={()=>setToggle(false)} />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(BackpayTable);