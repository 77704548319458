import React, { useContext, useEffect, useState } from "react";
import { NewMainContext } from "../../context/NewMainContext";
import ClaimApprovalSchemeCreate from "./ClaimApprovalSchemeCreate";
import ClaimApprovalSchemeEdit from "./ClaimApprovalSchemeEdit";
import ClaimApprovalSchemeList from "./ClaimApprovalSchemeList";



export default function ClaimApprovalScheme(){

    const { claim_approval_scheme_reset, setClaimApprovalSchemeReset} = useContext(NewMainContext);
    const [ step, setStep ] = useState(0);
    const [ edit_id, setEditID] = useState(null);

    useEffect(()=>{
        if(claim_approval_scheme_reset === true){
            setStep(0);
            setClaimApprovalSchemeReset(false);
        }
    },[claim_approval_scheme_reset,setClaimApprovalSchemeReset])

    return(
        <>
        {step === 0 && <ClaimApprovalSchemeList setStep={setStep} setEditID={setEditID} />}
        {step === 1 && <ClaimApprovalSchemeCreate setStep={setStep} />}
        {step === 2 && <ClaimApprovalSchemeEdit setStep={setStep} id={edit_id} />}
        </>
    )
}