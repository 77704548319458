import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATEHOLIDAYLIST_REQUEST1 = 'CREATEHOLIDAYLIST_REQUEST1';
export const CREATEHOLIDAYLIST_SUCCESS1 = 'CREATEHOLIDAYLIST_SUCCESS1';
export const CREATEHOLIDAYLIST_FAILURE1 = 'CREATEHOLIDAYLIST_FAILURE1';

export const wizard_create_holiday_list = (data)=>{
    return dispatch=>{
        dispatch({
            type:'CREATEHOLIDAYLIST_REQUEST1',
        });

        const count = data.length;
        for(let i=0;i<count;i++){
            const y = count-1;
            const name = data[i].name;
            const holiday = data[i].holiday;
            myaxios.post('contract/holidaylist/',{
                name:name,
                holiday:holiday
            })
            .then((response)=>{
                if(i === y){
                    dispatch({
                        type:'CREATEHOLIDAYLIST_SUCCESS1',
                        payload:response
                    });

                    Toast.fire({
                        type: 'success',
                        title: IMLocalized('create_success')
                    });
                }
            })
            .catch((error)=>{
                dispatch({
                    type:'CREATEHOLIDAYLIST_FAILURE1',
                    payload:error
                });

                ErrToast.fire({
                    type:'error',
                    title:IMLocalized('create_failure')
                });
            })
        }
    }
}