import myaxios from '../../axios';

export const LEAVERESETLISTHR_REQUEST = 'LEAVERESETLISTHR_REQUEST';
export const LEAVERESETLISTHR_SUCCESS = 'LEAVERESETLISTHR_SUCCESS';
export const LEAVERESETLISTHR_FAILURE = 'LEAVERESETLISTHR_FAILURE';

export const leave_reset_list =()=>{
    return dispatch=>{
        dispatch({
            type:'LEAVERESETLISTHR_REQUEST'
        });

        myaxios.get('leave/reset/')
        .then((response)=>{
            dispatch({
                type:'LEAVERESETLISTHR_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVERESETLISTHR2_FAILURE',
                payload:error
            })
        })
    }
}