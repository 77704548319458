import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const NEW_DELEMPPASSPORT_REQUEST = 'NEW_DELEMPPASSPORT_REQUEST';
export const NEW_DELEMPPASSPORT_SUCCESS = 'NEW_DELEMPPASSPORT_SUCCESS';
export const NEW_DELEMPPASSPORT_FAILURE = 'NEW_DELEMPPASSPORT_FAILURE';
export const NEW_DELEMPPASSPORT_RESET = 'NEW_DELEMPPASSPORT_RESET';


export const delete_employee_passport =(data)=>{
    return dispatch=>{
        if(data.length !== 0){
            dispatch({
                type:'NEW_DELEMPPASSPORT_REQUEST'
            });
            for(let i=0;i<data.length;i++){
                const {id} = data[i];
                myaxios.delete(`employee/passport/${id}/`)
                .then((response)=>{
                    dispatch({
                        type:'NEW_DELEMPPASSPORT_SUCCESS',
                        payload:response
                    });

                    Toast.fire({
                        type: 'success',
                        title: IMLocalized('delete_success')
                    });
                })
                .catch((error)=>{
                    dispatch({
                        type:'NEW_DELEMPPASSPORT_FAILURE',
                        payload:error
                    });

                    ErrToast.fire({
                        type:'error',
                        title:IMLocalized('delete_failure')
                    });
                })
            }
        }
    }
}

export const reset_delete_employee_passport = ()=>dispatch=>{
    dispatch({
        type:'NEW_DELEMPPASSPORT_RESET'
    });
}