import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const SUBMITPAYROLLRESIGN_REQUEST = 'SUBMITPAYROLLRESIGN_REQUEST';
export const SUBMITPAYROLLRESIGN_SUCCESS = 'SUBMITPAYROLLRESIGN_SUCCESS';
export const SUBMITPAYROLLRESIGN_FAILURE = 'SUBMITPAYROLLRESIGN_FAILURE';

export const submit_payroll_resign = (payroll_date,employees)=>{
    return dispatch=>{
        dispatch({
            type:'SUBMITPAYROLLRESIGN_REQUEST'
        });

        myaxios.post(`payroll/resign/`,{
            payroll_date:payroll_date,
            employees:employees
        })
        .then((response)=>{
            dispatch({
                type:'SUBMITPAYROLLRESIGN_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'SUBMITPAYROLLRESIGN_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}