import myaxios from '../../axios';

export const GETPAYROLLHISTORYLIST_REQUEST = 'GETPAYROLLHISTORYLIST_REQUEST';
export const GETPAYROLLHISTORYLIST_SUCCESS = 'GETPAYROLLHISTORYLIST_SUCCESS';
export const GETPAYROLLHISTORYLIST_FAILURE = 'GETPAYROLLHISTORYLIST_FAILURE';


export const get_payroll_history_list =()=>{
    return dispatch=>{
        dispatch({
            type:'GETPAYROLLHISTORYLIST_REQUEST'
        });

        myaxios.get('history/payroll/')
        .then((response)=>{
            dispatch({
                type:'GETPAYROLLHISTORYLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETPAYROLLHISTORYLIST_FAILURE',
                payload:error
            })
        })
    }
}
