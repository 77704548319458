import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { IMLocalized } from '../../language/IMLocalized';
import { Responsive, WidthProvider } from 'react-grid-layout';
import ClaimTable from './dashboard_component/ClaimTable';
import LeaveTable from './dashboard_component/LeaveTable';
import PayslipTable from './dashboard_component/PayslipTable';
import { update_user_layout } from '../../action/dashboard/update_user_layout';
import { get_general_dashboard } from '../../action/dashboard/get_general_dashboard';
import { usePrevious } from '../../hook/usePrevious';
import { NewMainContext } from '../../context/NewMainContext';
import OverlayLoading from '../loading_component/overlay_loading';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';
const ResponsiveGridLayout = WidthProvider(Responsive);

const mapStateToProps=(state)=>({
    dashboardlist:state.get_general_dashboard.data,
    availablelist:state.user_get_available_leave.data,
    success:state.update_user_layout.data,
    isLoading:state.get_general_dashboard.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    update_user_layout:(layout)=>{
        dispatch(update_user_layout(layout))
    },
    get_general_dashboard:()=>{
        dispatch(get_general_dashboard())
    },
})


const initialState={
    toggle:false,
    drag:false,
    currentBreakpoint:'lg',
    userclaimlist:[],
    leave_availablelist:[],
    userpayslip:[],
    count:0
}

function UserDashboard(props){

    const { addItem, setUserPayrollId, setUserEmployeeStep } = useContext(NewMainContext);
    const [state,setState] = useState(initialState);
    const [layoutlist,setLayout] = useState({
        lg:[
            {i: '3', x: 0 , y: 4, w: 4, h: 7},
            {i: '4', x: 4 , y: 4, w : 4, h: 7},
            {i: '5', x: 0 , y: 0, w : 4, h: 3},
            {i: '7', x: 4 , y: 0, w : 4, h: 3},
            {i: '1', x: 8 , y: 0, w : 4, h: 3}
        ],
        md:[
            {i: '3', x: 5 , y: 4, w: 5, h: 7 },
            {i: '4', x: 0 , y: 8, w: 5, h: 7},
            {i: '5', x: 0 , y: 0, w: 5, h: 3 },
            {i: '7', x: 5 , y: 0, w: 5, h: 3},
            {i: '1', x: 0 , y: 4, w: 5, h: 3},
            
        ],
        sm:[
            {i: '3', x: 0 , y: 12, w: 6, h: 7},
            {i: '4', x: 0 , y: 20, w: 6, h: 7},
            {i: '5', x: 0 , y: 0, w: 6, h: 3},
            {i: '7', x: 0 , y: 4, w: 6, h: 3},
            {i: '1', x: 0 , y: 8, w: 6, h: 3},
        ],
        xs:[
            {i: '3', x: 0 , y: 12, w: 4, h: 7},
            {i: '4', x: 0 , y: 20, w: 4, h: 7},
            {i: '5', x: 0 , y: 0, w: 4, h: 3},
            {i: '7', x: 0 , y: 4, w: 4, h: 3},
            {i: '1', x: 0 , y: 8, w: 4, h: 3},
        ],
        xxs:[
            {i: '3', x: 0 , y: 12, w: 2, h: 7},
            {i: '4', x: 0 , y: 20, w: 2, h: 7},
            {i: '5', x: 0 , y: 0, w: 2, h: 3},
            {i: '7', x: 0 , y: 4, w: 2, h: 3},
            {i: '1', x: 0 , y: 8, w: 2, h: 3},
        ]
    });

    const [defaultlist] = useState({
        lg:[
            {i: '3', x: 0 , y: 4, w: 4, h: 7},
            {i: '4', x: 4 , y: 4, w : 4, h: 7},
            {i: '5', x: 0 , y: 0, w : 4, h: 3},
            {i: '7', x: 4 , y: 0, w : 4, h: 3},
            {i: '1', x: 8 , y: 0, w : 4, h: 3}
        ],
        md:[
            {i: '3', x: 5 , y: 4, w: 5, h: 7 },
            {i: '4', x: 0 , y: 8, w: 5, h: 7},
            {i: '5', x: 0 , y: 0, w: 5, h: 3 },
            {i: '7', x: 5 , y: 0, w: 5, h: 3},
            {i: '1', x: 0 , y: 4, w: 5, h: 3},
            
        ],
        sm:[
            {i: '3', x: 0 , y: 12, w: 6, h: 7},
            {i: '4', x: 0 , y: 20, w: 6, h: 7},
            {i: '5', x: 0 , y: 0, w: 6, h: 3},
            {i: '7', x: 0 , y: 4, w: 6, h: 3},
            {i: '1', x: 0 , y: 8, w: 6, h: 3},
        ],
        xs:[
            {i: '3', x: 0 , y: 12, w: 4, h: 7},
            {i: '4', x: 0 , y: 20, w: 4, h: 7},
            {i: '5', x: 0 , y: 0, w: 4, h: 3},
            {i: '7', x: 0 , y: 4, w: 4, h: 3},
            {i: '1', x: 0 , y: 8, w: 4, h: 3},
        ],
        xxs:[
            {i: '3', x: 0 , y: 12, w: 2, h: 7},
            {i: '4', x: 0 , y: 20, w: 2, h: 7},
            {i: '5', x: 0 , y: 0, w: 2, h: 3},
            {i: '7', x: 0 , y: 4, w: 2, h: 3},
            {i: '1', x: 0 , y: 8, w: 2, h: 3},
        ]
    });
    const {success,get_general_dashboard} = props;
    const prevSuccess = usePrevious(success);

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== success && success != null){
            get_general_dashboard();
            setState(prevState=>({
                ...prevState,
                drag:false,
                toggle:false
            }));
        }
    },[prevSuccess, success, get_general_dashboard])

    useEffect(()=>{
        if(props.dashboardlist.length !== 0 ){
            const { is_admin, user, user_layout} = props.dashboardlist;
            if(!is_admin){
                const {claims,payslip} = user;
                setState(prevState=>({
                    ...prevState,
                    userpayslip:payslip,
                    userclaimlist:claims,
                }));
                if(user_layout != null){
                    setLayout(user_layout);
                }
            }
        }
    },[props.dashboardlist])


    return(
        <>
        {props.isLoading ? <OverlayLoading/> :
        <>
        <Container className='scrollbarstyle1' fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active:true, title:IMLocalized('home')}
                    ]} />
                </Col>
                <Col className='col-auto d-flex align-items-center'>
                    <Dropdown size="sm" isOpen={state.toggle} direction="left" 
                    toggle={()=>{
                        setState(prevState=>({
                            ...prevState,
                            toggle:!prevState.toggle
                        }))
                    }}>
                        <DropdownToggle className="d-flex align-items-center justify-content-center setting_btn" aria-expanded={state.toggle}>
                            <i className="ni ni-settings-gear-65"/>
                        </DropdownToggle>
                        <DropdownMenu>
                            <div className="custom-control custom-checkbox ml-2">
                                <input type="checkbox" id="user_draggable" className="custom-control-input" name="drag" checked={state.drag} onChange={()=>setState(prevState=>({...prevState,drag:!state.drag}))}  />
                                <label className="custom-control-label" htmlFor='user_draggable'>{IMLocalized('is_draggable')}</label>
                            </div>
                            <DropdownItem divider></DropdownItem>
                            <div className="pl-2">
                                {state.toggle === true && 
                                <button className="btn btn-primary btn-sm"
                                onClick={()=>{
                                    props.update_user_layout(layoutlist);
                                    setState(prevState=>({...prevState,drag:false}))
                                }}>{IMLocalized('save')}</button>}
                                <button className="btn btn-secondary btn-sm" 
                                onClick={()=>{
                                   props.update_user_layout(defaultlist);
                                   setState(prevState=>({...prevState,drag:false}))
                                }}>{IMLocalized('default')}</button>
                            </div>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </Row>
            <ResponsiveGridLayout
            layouts={layoutlist}
            measureBeforeMount={true}
            className="layout"
            rowHeight={30}
            isDraggable={state.drag}
            isResizable={false}
            onDragStop={(index)=>{
                setLayout(prevState=>({
                    ...prevState,
                    [state.currentBreakpoint]:index
                }))
            }}
            onBreakpointChange={(breakpoint)=>{
                setState(prevState=>({...prevState,currentBreakpoint:breakpoint}));
            }}
            margin={[10, 10]}
            >
                <div key="3">
                    <ClaimTable list={state.userclaimlist} />
                </div>
                <div key="4">
                    <LeaveTable list={props.availablelist} toUserCalendarApply={(id)=>props.toUserCalendarApply(id)} />
                </div>
                <div key="5">
                    <PayslipTable list={state.userpayslip} drag={state.drag} 
                    viewPayroll={(payroll)=>{
                        addItem('2','profile');
                        setUserEmployeeStep('1')
                        setUserPayrollId(payroll);
                    }} 
                    />
                </div>
            </ResponsiveGridLayout>
        </Container>
        </>}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(UserDashboard);