import * as updateapproval from '../../action/leave/update_leave_approval';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_leave_approval = (state=initialState,action)=>{
    switch(action.type){
        case updateapproval.UPDATELEAVEAPPROVAL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case updateapproval.UPDATELEAVEAPPROVAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updateapproval.UPDATELEAVEAPPROVAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case updateapproval.UPDATELEAVEAPPROVAL_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default update_leave_approval;