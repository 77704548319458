import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { IMLocalized } from "../../../../language/IMLocalized";


const initialState={
    viewlist1: [],
    viewlist2: [],
    viewlist3: [],
    is_manager:false,
}

export default function ModalViewCanViewWhosLeave({ toggle, closeModal}){

    const emp_data = useSelector(state=>state.user_self.data);
    const viewlist = useSelector(state=>state.user_leave_all_apply_detail.data);
    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(toggle && viewlist != null){
            const {leave_approval_group, approver} = viewlist;
            setState(prevState=>({
                ...prevState,
                viewlist1:leave_approval_group?.employees ?? [],
                viewlist2:leave_approval_group?.approvers ?? [], 
                viewlist3:approver}));
        }
        else{
            setState(initialState);
        }
    },[toggle,viewlist])

    useEffect(()=>{
        if(toggle && emp_data.length !== 0){
            const {is_manager} = emp_data;
            setState(prevState=>({...prevState,is_manager}));
        }
    },[toggle,emp_data])


    const columnDefs = useMemo(()=>[
            {
                headerName:IMLocalized('employee_id'),
                field:'employee_number',
                width:180
            },
            {
                headerName:IMLocalized('name'),
                field:'name',
                width:180
            },
            {
                headerName:IMLocalized('department'),
                field:'contract_cache.department',
                width:180
            },
            {
                headerName:IMLocalized('section'),
                field:'contract_cache.section',
                width:180
            },
            {
                headerName:IMLocalized('job_title'),
                field:'contract_cache.job_title',
                width:180
            },
            {
                headerName:IMLocalized('job_level'),
                field:'contract_cache.job_level',
                width:180
            },
            {
                headerName:IMLocalized('leave_approval'),
                field:'metadata.lag',
                width:180,
                cellRenderer: function(params){
                    const lag = params.data.metadata.lag;
                    let string = '---';
                    let arr = {};
                    if(lag !== null){
                        let string1 ='';
                        const approvers = lag.approvers;
                        for(let i=0;i<approvers.length;i++){
                            const approver = approvers[i];
                            for(let j=0;j<approver.length;j++){
                                const name1 = approver[j].name;
                                if(i ===0 && j===0){
                                    if(!(name1 in arr)){
                                        arr[name1] = 1;
                                        const name = ' ● ' + approver[j].name;
                                        string1 = string1 + name;
                                    }
                                }
                                else{
                                    if(!(name1 in arr)){
                                        arr[name1] = 1;
                                        const name = approver[j].name;
                                        string1 = string1+ '<br/> ● ' + name;
                                    }
                                }
                            }
                        }
    
                        const exceptions = lag.exceptions;
                        for(let i=0;i<exceptions.length;i++){
                            const approver1 = exceptions[i].approvers;
                            for(let j=0;j<approver1.length;j++){
                                const approver2 = approver1[j];
                                for(let z=0;z<approver2.length;z++){
                                    const name1 = approver2[z].name;
                                    if(approvers.length === 0 && i=== 0 && j ===0 && z===0){
                                        if(!(name1 in arr)){
                                            arr[name1] = 1;
                                            const name = ' ● ' + approver2[z].name;
                                            string1 = string1 + name;
                                        }
                                    }
                                    else{
                                        if(!(name1 in arr)){
                                            arr[name1] = 1;
                                            const name = approver2[z].name;
                                            string1 = string1+ '<br/> ● ' + name;
                                        }
                                    }
                                }
                                
                            }
                        }
                        string = string1
                    }
                    return string
                },
                autoHeight:true
            }]
    ,[])


    return(
        <Modal size="lg" isOpen={toggle} style={{maxWidth: '1600px', width: '90%'}}>
            <ModalHeader toggle={closeModal}>{IMLocalized('whose_leave_you_can_see')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <h2>{IMLocalized('leave_approval_list1')}</h2>
                        <Row>
                            <Col>
                                <h4>{IMLocalized('employees')}</h4>
                                <div className="ag-theme-balham" style={{height:'400px', width:'100%' }}>
                                    <AgGridReact
                                        enableRangeSelection={true}
                                        columnDefs={columnDefs}
                                        rowData={state.viewlist1}
                                        suppressDragLeaveHidesColumns={true}
                                        suppressSizeToFit={true}
                                        suppressColumnMoveAnimation={false}
                                        overlayNoRowsTemplate={IMLocalized('no_data')}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <h4>{IMLocalized('approvers')}</h4>
                                <div className="ag-theme-balham" style={{height:'400px', width:'100%' }}>
                                    <AgGridReact
                                        enableRangeSelection={true}
                                        columnDefs={columnDefs}
                                        rowData={state.viewlist2}
                                        suppressDragLeaveHidesColumns={true}
                                        suppressSizeToFit={true}
                                        suppressColumnMoveAnimation={false}
                                        overlayNoRowsTemplate={IMLocalized('no_data')}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {state.is_manager &&
                        <Row className="mt-4">
                            <Col>
                                <h2>{IMLocalized('approvers')}</h2>
                                <Row>
                                    <Col>
                                        <h4>{IMLocalized('approvers')}</h4>
                                        <div className="ag-theme-balham" style={{height:'400px', width:'100%' }}>
                                            <AgGridReact
                                                enableRangeSelection={true}
                                                columnDefs={columnDefs}
                                                rowData={state.viewlist3}
                                                suppressDragLeaveHidesColumns={true}
                                                suppressSizeToFit={true}
                                                suppressColumnMoveAnimation={false}
                                                overlayNoRowsTemplate={IMLocalized('no_data')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}