import * as deleteappraisal from '../../action/appraisal/delete_appraisal_list';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const delete_appraisal_list = (state=initialState,action)=>{
    switch(action.type){
        case deleteappraisal.DELETEAPPRAISALLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case deleteappraisal.DELETEAPPRAISALLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case deleteappraisal.DELETEAPPRAISALLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case deleteappraisal.DELETEAPPRAISALLIST_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default delete_appraisal_list;