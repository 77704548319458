import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";
import { useDispatch, useSelector } from "react-redux";
import { wizard_complete } from "../../../action/wizard/wizard_complete";
import Swal from "sweetalert2";
import { usePrevious } from "../../../hook/usePrevious";



export default function ModalPayroll({toggle, closeModal}){
    const dispatch = useDispatch();
    const complete_errors = useSelector(state=>state.wizard_complete.errors);
    const [ payroll, setPayroll] = useState('1');
    const [ claim, setClaim] = useState('1');
    const [ leave, setLeave] = useState('1');
    const prevErrors = usePrevious(complete_errors);


    useEffect(()=>{
        if(prevErrors !== undefined && prevErrors !== complete_errors && complete_errors != null){
            if(complete_errors.hasOwnProperty('non_field_errors')){
                const word = complete_errors['non_field_errors'].toString();
                Swal.fire({
                    title:word,
                    type:'warning',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!'),
                })
                .then((result)=>{
                    window.location.href="/#/";
                })

            }
        }
    },[complete_errors,prevErrors])

    const onConfirm=()=>{
        dispatch(wizard_complete(payroll,leave,claim));
    }


    const data1 = [
        { value: '1', display:IMLocalized('january')},
        { value: '2', display:IMLocalized('february')},
        { value: '3', display:IMLocalized('march')},
        { value: '4', display:IMLocalized('april')},
        { value: '5', display:IMLocalized('may')},
        { value: '6', display:IMLocalized('june')},
        { value: '7', display:IMLocalized('july')},
        { value: '8', display:IMLocalized('august')},
        { value: '9', display:IMLocalized('september')},
        { value: '10', display:IMLocalized('october')},
        { value: '11', display:IMLocalized('november')},
        { value: '12', display:IMLocalized('december')}
    ]

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('setup')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="payroll_start_month">{IMLocalized('payroll_start_month')}</label>
                            <Input size="sm" type="select" id="payroll_start_month" value={payroll} onChange={(e)=>setPayroll(e.target.value)} >
                                {data1.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                    {process.env.REACT_APP_LEAVE === 'true' &&
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="leave_start_month">{IMLocalized('leave_start_month')}</label>
                            <Input size="sm" type="select" id="leave_start_month" value={leave} onChange={(e)=>setLeave(e.target.value)} >
                                {data1.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                        </FormGroup>
                    </Col>}
                    {process.env.REACT_APP_CLAIM === 'true' &&
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="claim_start_month">{IMLocalized('claim_start_month')}</label>
                            <Input size="sm" type="select" id="claim_start_month" value={claim} onChange={(e)=>setClaim(e.target.value)} >
                                {data1.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                        </FormGroup>
                    </Col>}
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary btn-sm" onClick={onConfirm}>{IMLocalized('CONFIRM')}</button>
            </ModalFooter>
        </Modal>
    )
}