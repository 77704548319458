import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELETEAPPRAISALGROUP_REQUEST = 'DELETEAPPRAISALGROUP_REQUEST';
export const DELETEAPPRAISALGROUP_SUCCESS = 'DELETEAPPRAISALGROUP_SUCCESS';
export const DELETEAPPRAISALGROUP_FAILURE = 'DELETEAPPRAISALGROUP_FAILURE';
export const DELETEAPPRAISALGROUP_RESET = 'DELETEAPPRAISALGROUP_RESET';

export const delete_appraisal_group = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELETEAPPRAISALGROUP_REQUEST'
        });

        myaxios.delete(`forms/groups/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELETEAPPRAISALGROUP_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELETEAPPRAISALGROUP_FAILURE',
                payload:error
            })
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_appraisal_group = () => dispatch =>{
    dispatch({
        type:'DELETEAPPRAISALGROUP_RESET'
    });
}