import * as claim_approval2 from '../../action/claim/create_claim_approval2';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const create_claim_approval2 = (state=initialState,action)=>{
    switch(action.type){
        case claim_approval2.CLAIMAPPROVAL2_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case claim_approval2.CLAIMAPPROVAL2_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case claim_approval2.CLAIMAPPROVAL2_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default create_claim_approval2;