import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELCLAIMSCHEME_REQUEST = 'DELCLAIMSCHEME_REQUEST';
export const DELCLAIMSCHEME_SUCCESS = 'DELCLAIMSCHEME_SUCCESS';
export const DELCLAIMSCHEME_FAILURE = 'DELCLAIMSCHEME_FAILURE';
export const DELCLAIMSCHEME_RESET = 'DELCLAIMSCHEME_RESET';

export const delete_claim_scheme = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELCLAIMSCHEME_REQUEST'
        });

        myaxios.delete(`claim/scheme/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELCLAIMSCHEME_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title:  IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELCLAIMSCHEME_FAILURE',
                payload:error
            })
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_claim_scheme = () =>dispatch => {
    dispatch({
        type:'DELCLAIMSCHEME_RESET'
    });
}