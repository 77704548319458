import * as list from '../../action/appraisal/get_manager_appraisal_list';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const get_manager_appraisal_list = (state=initialState,action)=>{
    switch(action.type){
        case list.GETMANAGERAPPRAISALLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[]
            }
        case list.GETMANAGERAPPRAISALLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case list.GETMANAGERAPPRAISALLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_manager_appraisal_list;