import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import {IMLocalized} from '../../../language/IMLocalized';

function ModalPayoutError(props){


    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('payout_error_msg')}</ModalHeader>
            <ModalBody>
                {props.detail.length !== 0 &&
                <Table size="sm" className='align-items-center table-bordered' responsive>
                    <thead className='thead-light'>
                        <tr>
                            <th>{IMLocalized('error')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.detail.map((item,index)=>{
                            return( <tr key={index}>
                                        <td>{item}</td>
                                    </tr>
                            )
                        })}
                    </tbody>
                </Table>}
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-danger btn-sm' onClick={props.closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default ModalPayoutError;