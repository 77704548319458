import React, { useContext,useRef,useState } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";
import LeaveCalendarPendingList from "./component/LeaveCalendarPendingList";
import LeaveCalendarPendingListManager from "./component/LeaveCalendarPendingListManager";
import LeaveCalendarTable from "./component/LeaveCalendarTable";
import LeaveCalendarTableManager from "./component/LeaveCalendarTableManager";
import OverlayLoading from "../loading_component/OverlayLoading";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";

export default function LeaveCalendar(){

    const isLoading = useSelector(state=>state.get_apply_leave_list.isLoading)
    const { addItem } = useContext(NewMainContext);
    const [ view_detail, setViewDetail] = useState(null);
    const ref1 = useRef();
    const { current_user } = useContext(NewMainContext);

    const toggleItem=(item)=>{
        setViewDetail(item);
        setTimeout(()=>ref1.current.scrollIntoView({ behavior:'smooth', block:'start'}),100)
    }

    return(
        <>
        {isLoading ? <OverlayLoading/>:
        <Container fluid>
            <Row  className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('leave_calendar1')}
                    ]}
                    />
                </Col>
            </Row>
            {(current_user === 'manager')?
            <LeaveCalendarPendingListManager setViewDetail={toggleItem} />:
            <LeaveCalendarPendingList setViewDetail={toggleItem} />}
            
            <div ref={ref1}>
            {(current_user === 'manager')?
            <LeaveCalendarTableManager view_detail={view_detail} setViewDetail={(data)=>setViewDetail(data)} />:
            <LeaveCalendarTable view_detail={view_detail} setViewDetail={(data)=>setViewDetail(data)} />}
                
            </div>
            
        </Container>}
        </>
    )
}