import * as updatepaybonus from '../../action/payroll/update_payslip_bonus';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_payslip_bonus = (state=initialState,action)=>{
    switch(action.type){
        case updatepaybonus.UPDATEPAYSLIPBONUS_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case updatepaybonus.UPDATEPAYSLIPBONUS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updatepaybonus.UPDATEPAYSLIPBONUS_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default update_payslip_bonus;