import myaxios from '../../axios';

export const GETYEARLEAVEBALANCE_REQUEST = 'GETYEARLEAVEBALANCE_REQUEST';
export const GETYEARLEAVEBALANCE_SUCCESS = 'GETYEARLEAVEBALANCE_SUCCESS';
export const GETYEARLEAVEBALANCE_FAILURE = 'GETYEARLEAVEBALANCE_FAILURE';


export const get_year_leave_balance = (year)=>{
    return dispatch=>{
        dispatch({
            type:'GETYEARLEAVEBALANCE_REQUEST'
        });

        myaxios.get(`leave/available/?year=${year}`)
        .then((response)=>{
            dispatch({
                type:'GETYEARLEAVEBALANCE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETYEARLEAVEBALANCE_FAILURE',
                payload:error
            })
        })
    }
}