import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELCOM_REQUEST = 'DELCOM_REQUEST';
export const DELCOM_FAILURE = 'DELCOM_FAILURE';
export const DELCOM_SUCCESS = 'DELCOM_SUCCESS';
export const DELCOM_RESET = 'DELCOM_RESET';

export const delete_company = (id)=>{
    return dispatch =>{
        dispatch({
            type: 'DELCOM_REQUEST'
        });
        
        myaxios.delete(`company/company/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELCOM_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'DELCOM_FAILURE',
                payload: error,
                error:true
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            })
        })
    }
}

export const remove_delete_company = () =>dispatch =>{
    dispatch({
        type:'DELCOM_RESET'
    });
}

