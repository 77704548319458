import { Buffer } from "buffer";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Viewer from "react-viewer";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import { get_approvals_leave_list } from "../../../action/approvals/get_approvals_leave_list";
import { emp_detail_array_approve_leave, reset_emp_detail_array_approve_leave } from "../../../action/employee/emp_detail_array_approve_leave";
import { emp_detail_get_leave_picture, reset_emp_detail_get_leave_picture } from "../../../action/employee/emp_detail_get_leave_picture";
import { get_all_employee_available_leave } from "../../../action/employee/get_all_employee_available_leave";
import { reset_hr_employee_all_leave_detail } from "../../../action/employee/get_hr_employee_all_leave_detail";
import { get_apply_leave_list } from "../../../action/get_apply_leave_list";
import { manager_leave_list } from "../../../action/leave/manager_leave_list";
import { NewMainContext } from "../../../context/NewMainContext";
import { getDateFormat } from "../../../func/getDateFormat";
import { IMLocalized } from "../../../language/IMLocalized";


export default function ModalLeaveDetail1({ toggle, closeModal, toprofile}){

    const { current_user, addItem, setEmployeeListStep, setEmpStep, setEmpProfileId } = useContext(NewMainContext);

    const detail = useSelector(state=>state.get_hr_employee_all_leave_detail.data);
    const isLoading = useSelector(state=>state.get_hr_employee_all_leave_detail.isLoading);
    const picture = useSelector(state=>state.emp_detail_get_leave_picture.data);
    const dispatch = useDispatch();
    const success = useSelector(state=>state.emp_detail_array_approve_leave.data);


    const [ activeImg, setActiveImg] = useState(0);
    const [ img_toggle, setImgToggle] = useState(false);
    const [ imglist, setImageList] = useState([]);

    useEffect(()=>{
        if(success != null){
            dispatch(reset_emp_detail_array_approve_leave());
            dispatch(get_apply_leave_list());
            if(current_user === 'hr'){
                dispatch(get_approvals_leave_list());
                dispatch(manager_leave_list());
            }
            dispatch(get_all_employee_available_leave());
            closeModal();
        }
    },[success,dispatch,current_user,closeModal])

    useEffect(()=>{
        if(!toggle){
            setImageList([]);
            dispatch(reset_hr_employee_all_leave_detail());
            dispatch(reset_emp_detail_get_leave_picture());
        }
    },[toggle,dispatch])

    useEffect(()=>{
        if(detail != null && toggle){
            const {leavepic} = detail;
            if(leavepic != null ){
                for(const image of leavepic){
                    dispatch(emp_detail_get_leave_picture(image.id));
                }
            }
        }
    },[detail,toggle,dispatch])

    useEffect(()=>{
        if(picture != null && toggle){
            const img = new Buffer.from(picture,'binary').toString('base64');
            const src = `data:image/png;base64,${img}`;
            setImageList(prevState=>([...prevState,{src}]));
        }
    },[picture,toggle])

    const openPicture=(index)=>{
        setActiveImg(index);
        setImgToggle(true);
    }

    const toViewLeaveBalance = (id)=>{
        addItem('7','employee_list');
        setEmployeeListStep('1');
        setEmpStep('5');
        setEmpProfileId(id);
        closeModal();
    }

    const singleApprove = ( boolean) =>{
        Swal.fire({
            title: boolean ? IMLocalized('approving_leave') : IMLocalized('rejecting_leave'),
            input:'textarea',
            text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then((result)=>{
            if(result.hasOwnProperty('value')){
                const comment = result.value;
                dispatch(emp_detail_array_approve_leave([detail.id],boolean,comment));
            }
        })
    }


    const approvers = detail ? detail.approvers.filter(item=>item.is_active === true) : [];

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('leave_detail')}</ModalHeader>
            {isLoading && 
            <div className="m-4">
                <h4>{IMLocalized('loading')}..</h4>
            </div>}
            {!isLoading && detail && 
            <>
            <ModalBody>
                {detail.days.length !== 0 &&
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Table size="sm" className="align-items-center bg-white table-bordered">
                            <thead className='thead-light'>
                                <tr>
                                    <th className='td-w-30'>{IMLocalized('date')}</th>
                                    <th>{IMLocalized('option')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detail.days.map((item)=>{
                                const date = getDateFormat(item.date);
                                const day = IMLocalized(item.day);
                                    return <tr key={item.date}><td>{date}</td><td>{day}</td></tr>
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>}
                <Row>
                    <Col>
                        <Table className="table-bordered" size="sm">
                            <tbody>
                                <tr>
                                    <td className='td-w-30'>{IMLocalized('employee_number')}</td>
                                    <td>{detail.employee.employee_number}</td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('employee_name')}</td>
                                    <td>{detail.employee.name}</td>
                                </tr>
                                <tr>
                                    <td>{IMLocalized('leave_type')}</td>
                                    <td>{detail.leave_setting.name}</td>
                                </tr>
                                <tr>
                                    <td>{IMLocalized('status')}</td>
                                    <td>{IMLocalized(detail.status)}</td>
                                </tr>
                                {detail.comments.length !== 0 &&
                                <tr>
                                    <td>{IMLocalized('comment')}</td>
                                    <td className='comment-td'>
                                        {detail.comments.map((item,index)=>{
                                            const word = item.comment;
                                            const employee_name = item.employee_name;
                                            return <div className='comment-td-detail' key={index}><span className='font-weight-bold'>{employee_name}</span><span>: {word}</span></div>
                                        })}
                                    </td>
                                </tr>}
                                <tr>
                                    <td>{IMLocalized('leave_amount')}</td>
                                    <td>{detail.amount}</td>
                                </tr>
                                {imglist.length !== 0 &&
                                <tr>
                                    <td>{IMLocalized('image')}</td>
                                    <td>
                                        <Row>
                                            {imglist.map((item,index)=>{
                                                return(<Col lg="4" key={index}>
                                                    <div className="image-item mr-2" key={index} onClick={()=>openPicture(index)}  >
                                                        <div className="image-inner">
                                                            <img src={item.src} className="img-thumbnail" alt={"This is Leave" +index}></img>
                                                        </div>
                                                    </div>
                                                </Col>)
                                            })}
                                        </Row>
                                    </td>
                                </tr>}
                                {approvers.length !== 0 &&
                                <tr>
                                    <td className='td-w-30'>{IMLocalized('approval_pending')}</td>
                                    <td>
                                        {approvers.map((item,index)=>{
                                            if(item.delegation_application_approval === true){
                                                return <div key={index}><span>{item.employee.name} ({IMLocalized('delegation')}) </span></div>
                                            }
                                            else{
                                                return <div key={index}><span>{item.employee.name}</span></div>
                                            }
                                        })}
                                    </td>
                                </tr>}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {toprofile && current_user === 'hr' &&
                    <Row>
                        <Col>
                            <a href="/#" onClick={()=>toViewLeaveBalance(detail.employee.id)}><small>{IMLocalized('view_leave_balance')}</small></a>
                        </Col>
                    </Row>
                }
                {imglist.length !== 0 && <Viewer activeIndex={activeImg} zIndex={1060} visible={img_toggle} onClose={()=>setImgToggle(false)} images={imglist} />}
            </ModalBody>
            </>}
            <ModalFooter>
                {detail?.status === 'PENDING' &&
                <>
                    <button className="btn btn-primary btn-sm" onClick={()=>singleApprove(true)} >{IMLocalized('approve')}</button>
                    <button className="btn btn-danger btn-sm" onClick={()=>singleApprove(false)} >{IMLocalized('reject')}</button>
                </>}
                {detail?.status === 'APPROVED' &&
                <button className="btn btn-danger btn-sm" onClick={()=>singleApprove(false)}>{IMLocalized('reject')}</button>}
                <button className='btn btn-danger btn-sm' onClick={closeModal} >{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}