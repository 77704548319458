import myaxios from '../axios';

export const TEMPLATELIST_REQUEST = 'TEMPLATELIST_REQUEST';
export const TEMPLATELIST_SUCCESS = 'TEMPLATELIST_SUCCESS';
export const TEMPLATELIST_FAILURE = 'TEMPLATELIST_FAILURE';


export const get_contract_template_list =()=>{
    return dispatch=>{
        dispatch({
            type: 'TEMPLATELIST_REQUEST'
        });
        myaxios.get('contract/template/contracts/')
        .then((response)=>{
            dispatch({
                type: 'TEMPLATELIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'TEMPLATELIST_FAILURE',
                payload:error,
            })
        })
    }
}