import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const LEAVEHRAPPROVE_REQUEST ='LEAVEHRAPPROVE_REQUEST';
export const LEAVEHRAPPROVE_SUCCESS ='LEAVEHRAPPROVE_SUCCESS';
export const LEAVEHRAPPROVE_FAILURE ='LEAVEHRAPPROVE_FAILURE';
export const LEAVEHRAPPROVE_RESET = 'LEAVEHRAPPROVE_RESET';


export const array_approve_leave =(id,approve,comment)=>{
    return dispatch=>{
        dispatch({
            type:'LEAVEHRAPPROVE_REQUEST'
        });

        const swal = approve ?  IMLocalized('approve_success') : IMLocalized('reject_success');
        const err_swal = approve ? IMLocalized('approve_failure') : IMLocalized('reject_failure');
        return myaxios.post('leave/employee/approve/',{
            leaves:id,
            approve:approve,
            comment:comment
        })
        .then((response)=>{
            console.log(response + 'leaveApprove')
            dispatch({
                type:'LEAVEHRAPPROVE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: swal
            });
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVEHRAPPROVE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:err_swal,
            })
        })
    }
}

export const reset_array_approve_leave = () => dispatch =>{
    dispatch({
        type:'LEAVEHRAPPROVE_RESET'
    });
}