import * as updateholidaylist from '../action/update_holiday_list';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const update_holiday_list = (state=initialState,action)=>{
    switch(action.type){
        case updateholidaylist.UPDATEHOLIDAYLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case updateholidaylist.UPDATEHOLIDAYLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updateholidaylist.UPDATEHOLIDAYLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case updateholidaylist.UPDATEHOLIDAYLIST_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default update_holiday_list;