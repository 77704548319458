import React from "react";
import { Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { IMLocalized } from "../../../language/IMLocalized";

export default function AdditionalTable({type,list,removeList,onCreate}){

    return(
        <Card>
            <CardHeader>
                <Row>
                    <Col className="d-flex align-items-center">
                        <h4 className='mb-0'>
                            {type === 'department' && IMLocalized('add_department')}
                            {type === 'section' && IMLocalized('add_section')}
                            {type === 'jobtitle' && IMLocalized('add_jobtitle')}
                            {type === 'joblevel' && IMLocalized('add_joblevel')}
                            {type === 'costcenter' && IMLocalized('add_costcenter')}
                        </h4>
                    </Col>
                    <Col className='col-auto'>
                        <button className='btn btn-primary btn-sm' onClick={onCreate}>{IMLocalized('create')}</button>
                    </Col>
                </Row>
            </CardHeader>
            {list.length === 0 ? <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>:
                <ListGroup className="list" flush style={{overflow:'auto',overflowX:'hidden',maxHeight:'300px'}}>
                    {list.map((item,index)=>{
                        const num = index +1;
                        return(
                            <ListGroupItem className='py-2 border-bottom'>
                                <Row>
                                    <Col>
                                        <span>{num}. {item.name}</span>
                                    </Col>
                                    <Col className='col-auto mr-2'>
                                        <div className="delete_btn d-flex align-items-center justify-content-center">
                                            <i className="fas fa-trash text-red cursor-pointer" onClick={()=>removeList(type,index)}  />
                                        </div>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        )
                    })}
                </ListGroup>
            }
        </Card>
    )
}