import { AgGridReact } from "ag-grid-react";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import { filter_leave_reset_list, reset_filter_leave_reset_list } from "../../action/leave/filter_leave_reset_list";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; 
import '../../css/aggrid.css';
import { isFirstColumn } from "../ag-grid_component/isFirstColumn";
import ModalLeaveResetDetailErrorMessage from "./Modal/ModalLeaveResetDetailErrorMessage";
import { popError } from "../../func/popError";
import Swal from "sweetalert2";
import { reset_validate_apply_leave_reset, validate_apply_leave_reset } from "../../action/leave/validate_apply_leave_reset";
import { apply_leave_reset, reset_apply_leave_reset } from "../../action/leave/apply_leave_reset";
import OverlayLoading from '../loading_component/overlay_loading';
import BreadCrumbList from "../breadcrumb/BreadCrumbList";


const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    suppressRowClickSelection:true,
    defaultColDef:{
        resizable:true,
        filter:true,
        sortable:true
    },
}



export default function LeaveResetDetail(){

    const { leave_reset_list_id, action_toggle } = useContext(NewMainContext);
    const dispatch = useDispatch();
    const list = useSelector(state=>state.filter_leave_reset_list.data);
    const isLoading = useSelector(state=>state.filter_leave_reset_list.isLoading);
    const isLoading1 = useSelector(state=>state.validate_apply_leave_reset.isLoading);
    const isLoading2 = useSelector(state=>state.apply_leave_reset.isLoading);
    const validate_success = useSelector(state=>state.validate_apply_leave_reset.data);
    const success = useSelector(state=>state.apply_leave_reset.data);
    const { addItem, setLeaveResetStep } = useContext(NewMainContext); 

    const [ rowData, setRowData ] = useState([]);
    const [ year, setYear ] = useState(null);
    const [ search, setSearch ] = useState('');
    const [ errData, setErrData ] = useState([]);
    const [ err_toggle, setErrToggle] = useState(false);
    const [ validatelist, setValidateList ] = useState([]);

    useEffect(()=>{
        if(success != null){
            dispatch(filter_leave_reset_list(leave_reset_list_id));
            dispatch(reset_apply_leave_reset());
        }
    },[success,dispatch,leave_reset_list_id])

    useEffect(()=>{
        if(validate_success != null){
            dispatch(apply_leave_reset(year,validatelist));
            setErrData([]);
            dispatch(reset_validate_apply_leave_reset());
        }
    },[validate_success,validatelist,dispatch,year])
    
    useEffect(()=>{
        
        if(list !== null){
            setErrData([]);
            const { year, reset_entitlements } = list;
            const arr = reset_entitlements.map((item)=>{
                const { leave_setting, employee, remaining } = item;
                const { leave_type, year_initialization} = leave_setting;
                const { hr_cache } = employee;

                let leave_entitlement = year_initialization !== 'R' ? item.leave_entitlement : null;
                const entitlement = leave_type !== 'G' ? item.entitlement : 0 ;

                const daily_gross = hr_cache?.daily_rate ?? 0;

                if(leave_entitlement !== null){
                    let max_encash_limit = parseFloat(leave_entitlement.max_encash_limit);
                    let year_carry_over_limit = parseFloat(leave_entitlement.year_carry_over_limit);
                    if(remaining === 0){
                        leave_entitlement.encash = 0;
                        leave_entitlement.year_amount = 0;
                        const amount = daily_gross * 0;
                        leave_entitlement.encash_amount = amount.toFixed(2);
                    }
                    else{
                        if( remaining >= (max_encash_limit + year_carry_over_limit)){
                            const amount = daily_gross * max_encash_limit;
                            leave_entitlement.encash = max_encash_limit;
                            leave_entitlement.encash_amount = amount.toFixed(2);
                            leave_entitlement.year_amount = year_carry_over_limit;
                        }
                        else{
                            if(remaining <= year_carry_over_limit){
                                leave_entitlement.year_amount = remaining;
                                leave_entitlement.encash = 0;
                                leave_entitlement.encash_amount = 0 * daily_gross;

                            }
                            else{
                                leave_entitlement.year_amount = year_carry_over_limit;
                                leave_entitlement.encash = remaining;
                                const amount = daily_gross * remaining;
                                leave_entitlement.encash_amount = amount.toFixed(2);
                            }
                        }
                    }
                    leave_entitlement.daily_gross = daily_gross;
                }

                return {...item,leave_entitlement, entitlement, daily_gross: daily_gross, err_entitlement: false, err_encash: false, err_encash_amount: false, err_year_over: false };
            })
            setRowData(arr);
            setYear(year);
        }

    },[list,dispatch])

    useEffect(()=>{
        if(leave_reset_list_id != null){
            dispatch(filter_leave_reset_list(leave_reset_list_id));
        }

        return()=>{
            dispatch(reset_filter_leave_reset_list())
        }
    },[leave_reset_list_id,dispatch])

    const submitReset =()=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0){
            Swal.fire({
                title:IMLocalized('reseting_leave'),
                type:'warning',
                text:IMLocalized('are_you_sure_you_want_to_reset_these_leave'),
                confirmButtonText:IMLocalized('confirm')+'!',
                cancelButtonText:IMLocalized('no!'),
                showCancelButton:true,
            })
            .then((result)=>{
                if(result.hasOwnProperty('value')){
                    const arr = [];

                    for(const item of data){
                        const { leave_entitlement, id, entitlement } = item;
                        const encash = leave_entitlement?.encash ?? 0;
                        const max_encash_limit = leave_entitlement?.max_encash_limit ?? 0;
                        const carry_over_amount = leave_entitlement?.year_amount ?? 0;
                        const encash_amount = leave_entitlement?.encash_amount ?? 0;
                        const year_carry_over_limit =  leave_entitlement?.year_carry_over_limit ?? 0;
                        const employee = item.employee.id;
                        if(encash <= max_encash_limit & carry_over_amount <= year_carry_over_limit){
                            arr.push({employee,id,entitlement,encash,encash_amount,carry_over_amount});
                        }
                    }
                    dispatch(validate_apply_leave_reset(year,arr));
                    setValidateList(arr);
                }
            })
        }
        else{
            popError(IMLocalized('please_select_leave_to_reset'));
        }
    }

    const onCellValueChanged=(params)=>{
        let error = [...errData];
        if(params.oldValue !== params.newValue){
            const column = params.column.colDef.field;
            if(column === 'leave_entitlement.encash'){
                if(!isNaN(parseFloat(params.newValue))){
                    const value = parseFloat(params.newValue);
                    const daily_gross = params.data.leave_entitlement.daily_gross;
                    const amount = parseFloat(daily_gross) * value;
                    params.data.leave_entitlement.encash_amount = amount.toFixed(2);
                    const max_encash_limit = params.data.leave_entitlement.max_encash_limit;
                    params.data.err_encash = value > max_encash_limit ? true : false;
                    
                }
                else{
                    params.data.leave_entitlement.encash = params.oldValue;
                }
            }
            else if( column === 'leave_entitlement.year_amount'){
                if(!isNaN(parseFloat(params.newValue))){
                    const year_carry_over_limit = params.data.leave_entitlement.year_carry_over_limit;
                    const value = parseFloat(params.newValue);
                    params.data.err_year_over = value > year_carry_over_limit ? true : false ;
                }
                else{
                    params.data.leave_entitlement.year_amount = params.oldValue;
                }
            }
            else if( column === 'entitlement'){
                if(isNaN(parseFloat(params.newValue))){
                    params.data.leave_entitlement.encash_amount = params.oldValue;
                }
            }
            else if( column === 'leave_entitlement.encash_amount'){
                if(isNaN(parseFloat(params.newValue))){
                    params.data.leave_entitlement.encash_amount = params.oldValue;
                }
            }
            const id = params.data.id;
            const detail = error.find(item=>item.id === id);
            if(!detail){
                if(params.data.err_year_over === true || params.data.err_encash === true || params.data.err_encash_amount === true || params.data.err_entitlement === true){
                    error.push(params.data);
                    setErrData(error);
                }
            }
            else{
                if(params.data.err_year_over === false && params.data.err_encash === false && params.data.err_encash_amount === false && params.data.err_entitlement === false){
                    const index = error.map(x=>x.id).indexOf(id);
                    error.splice(index,1);
                    setErrData(error);
                }
                else{
                    error.map(item=>{
                        if(item.id === id){
                            return params.data;
                        }
                        return item;
                    })

                    setErrData(error);
                }
            }
        }
        params.api.refreshCells();
    }

    const backMain = ()=>{
        setLeaveResetStep('0'); 
        localStorage.setItem('leave_reset_step','0');
    }

    return(
        <>
        {isLoading ? <OverlayLoading/>:
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('leave_reset'), onClick:()=>backMain()},
                        { active: true, title:year}
                    ]}
                    />
                    <div className="ml-2">
                        <button onClick={()=>{ setLeaveResetStep('0'); localStorage.setItem('leave_reset_step','0') }} className="btn btn-primary btn-sm"><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col className="d-flex align-items-center">
                    <h3 className="mb-0">{IMLocalized('reset_list')} - {year}</h3>
                </Col>
                <Col className="col-auto">
                    <div className="d-flex align-items-center">
                        {isLoading1 || isLoading2 ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> : <button className="btn btn-primary btn-sm" onClick={submitReset}>{IMLocalized('reset')}</button>}
                        {errData.length  !== 0 && <button className='btn btn-danger btn-sm' onClick={()=>setErrToggle(true)}>{IMLocalized('error_msg')}</button> }
                        <InputGroup style={{width:'200px'}} size="sm" className="mr-2">
                            <Input type="text" value={search} onChange={(e)=>{
                                const value = e.target.value;
                                setSearch(value);
                            }}  />
                            <InputGroupAddon addonType="append">
                                <InputGroupText><i className="fas fa-search"/></InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <div className="ag-theme-balham" style={{height: action_toggle ? 'calc(100vh - 300px)' : 'calc(100vh - 264px)' , width:'100%' }}>
                        <AgGridReact
                            enableRangeSelection={true}
                            gridOptions={gridOptions}
                            rowData={rowData}
                            quickFilterText={search}
                            singleClickEdit={true}
                            stopEditingWhenCellsLoseFocus={true}
                            suppressDragLeaveHidesColumns={true}
                            suppressSizeToFit={true}
                            suppressColumnMoveAnimation={false}
                            overlayNoRowsTemplate={IMLocalized('no_data')}
                            onCellValueChanged={onCellValueChanged}
                            columnDefs={[
                                {
                                    headerName:'',
                                    field:'select',
                                    pinned:'left',
                                    headerCheckboxSelection: isFirstColumn,
                                    checkboxSelection: isFirstColumn,
                                    headerCheckboxSelectionFilteredOnly:true,
                                    width:80,
                                    filter:false,
                                    sortable:false,
                                    lockPosition: true
                    
                                },
                                {
                                    headerName:IMLocalized('employee_number'),
                                    field:'employee.employee_number',
                                    filter: 'agTextColumnFilter',
                                    floatingFilter: true,
                                },
                                {
                                    headerName:IMLocalized('name'),
                                    field:'employee.name',
                                    filter: 'agTextColumnFilter',
                                    floatingFilter: true,
                                },
                                {
                                    headerName:IMLocalized('leave_type'),
                                    field:'leave_setting.name',
                                    filter: 'agTextColumnFilter',
                                    floatingFilter: true,
                                },
                                {
                                    headerName:`${parseInt(year)+1} ` + IMLocalized('entitlement'),
                                    field:'entitlement',
                                    editable:function(params){
                                        return params.node.data.leave_setting.leave_type !== 'G'
                                    },
                                    cellClassRules:{
                                        'orange-bg':(params)=>{return params.data.err_entitlement}
                                    },
                                    filter:false,
                                },
                                {
                                    headerName:IMLocalized('remaining'),
                                    field:'remaining',
                                    filter:false,
                                },
                                {
                                    headerName:IMLocalized('daily_gross'),
                                    field:'daily_gross',
                                    filter:false,
                                },
                                {
                                    headerName:IMLocalized('encash'),
                                    field:'leave_entitlement.encash',
                                    editable:function(params){
                                        return params.node.data.leave_entitlement !== null
                                    },
                                    cellClassRules:{
                                        'orange-bg':(params)=>{return params.data.err_encash}
                                    },
                                    filter:false,
                                },
                                {
                                    headerName:IMLocalized('encash_amount'),
                                    field:'leave_entitlement.encash_amount',
                                    editable:function(params){
                                        return params.node.data.leave_entitlement !== null
                                    },
                                    cellClassRules:{
                                        'orange-bg':(params)=>{return params.data.err_encash_amount}
                                    },
                                    filter:false,
                                },
                                {
                                    headerName:IMLocalized('year_carry_over'),
                                    field:'leave_entitlement.year_amount',
                                    editable:function(params){
                                        return params.node.data.leave_entitlement !== null
                                    },
                                    cellClassRules:{
                                        'orange-bg':(params)=>{return params.data.err_year_over}
                                    },
                                    filter:false,
                                },
                                {
                                    headerName:IMLocalized('leave_scheme'),
                                    field:'employee.metadata.leave_scheme.name',
                                    filter: 'agTextColumnFilter',
                                    floatingFilter:true
                                },
                                {
                                    headerName:IMLocalized('section'),
                                    field:'employee.contract_cache.section',
                                    filter: 'agTextColumnFilter',
                                    floatingFilter: true,
                                },
                                {
                                    headerName:IMLocalized('department'),
                                    field:'employee.contract_cache.department',
                                    filter: 'agTextColumnFilter',
                                    floatingFilter: true,
                                }
                            ]}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
        {errData.length !== 0 &&
        <ModalLeaveResetDetailErrorMessage toggle={err_toggle} closeModal={()=>setErrToggle(false)} message={errData} />}
        </>}
        </>
    )
}