import React, { useContext, useState } from "react";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; 
import { Col, Container, Row } from "reactstrap";
import { NewMainContext } from "../../context/NewMainContext";
import { isFirstColumn } from "../ag-grid_component/isFirstColumn";
import { useSelector } from "react-redux";
import { popError } from "../../func/popError";
import { IMLocalized } from "../../language/IMLocalized";
import ModalAssignLeaveMassScheme1 from "./Modal/ModalAssignLeaveMassScheme1";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";

const gridOptions={
    singleClickEdit:true,
    defaultColDef:{
        sortable:true,
        filter:true,
        suppressMenu:true,
        resizable:true
    },
    sideBar:{
        toolPanels:[
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                toolPanelParams: {
                }
            }
        ],
        defaultToolPanel:'filters'
    },
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
}

export default function LeaveMassScheme1(){

    const { addItem, action_toggle } = useContext(NewMainContext);
    const emplist = useSelector(state=>state.get_employee_list.data);
    const [ selected_data, setSelectedData ] = useState([]);
    const [ scheme_toggle, setSchemeToggle ] = useState(false);

    const onSubmit = ()=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0 ){
            const arr = data.map(item=>item.id);
            setSchemeToggle(true);
            setSelectedData(arr);
        }
        else{
            popError(IMLocalized('no_employee_selected'));
        }
    }

    const onClear = ()=>{
        gridOptions.api.deselectAll();
    }

    const rowData = emplist.filter(item=>item.active === true);

    return(
        <>
        <Container fluid>
            <Row className="bg-white border-bottom">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('mass_assign_leave_scheme')}
                    ]}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <button className="btn btn-primary btn-sm" onClick={onSubmit}>{IMLocalized('assign')}</button>
                    <button className="btn btn-primary btn-sm" onClick={onClear}>{IMLocalized('clear')}</button>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <div className="ag-theme-balham" style={{height:action_toggle ? 'calc(100vh - 260px)' : 'calc(100vh - 224px)', width:'100%' }}>
                        <AgGridReact
                            overlayNoRowsTemplate={IMLocalized('no_data')}
                            rowData={rowData}
                            suppressDragLeaveHidesColumns={true}
                            gridOptions={gridOptions}
                            columnDefs={[
                                {
                                    headerName:IMLocalized('agrid_select'),
                                    field:'',
                                    editable:false,
                                    width:120,
                                    lockPosition:true,
                                    pinned:'left',
                                    filter:false,
                                    headerCheckboxSelection: isFirstColumn,
                                    checkboxSelection: isFirstColumn,
                                    headerCheckboxSelectionFilteredOnly:true,
                                },
                                {
                                    headerName:IMLocalized('agrid_emp_id'),
                                    field:'employee_number',
                                    lockPosition:true,
                                    pinned:'left',
                                    width:120,
                                    editable:false,
                                   
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                },
                                {
                                    headerName:IMLocalized('agrid_emp_name'),
                                    field:'name',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                    
                                    lockPosition:true,
                                    pinned:'left',
                                    width:180,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_company'),
                                    field:'metadata.company',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                   
                                    lockPosition:true,
                                    pinned:'left',
                                    width:150,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_department'),
                                    field:'contract_cache.department',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                   
                                    lockPosition:true,
                                    pinned:'left',
                                    width:150,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_section'),
                                    field:'contract_cache.section',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                   
                                    lockPosition:true,
                                    pinned:'left',
                                    width:150,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_job_title'),
                                    field:'contract_cache.job_title',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                   
                                    lockPosition:true,
                                    pinned:'left',
                                    width:150,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_job_level'),
                                    field:'contract_cache.job_level',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                   
                                    lockPosition:true,
                                    pinned:'left',
                                    width:150,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_cost_center'),
                                    field:'contract_cache.cost_center',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                    lockPosition:true,
                                    pinned:'left',
                                    width:150,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_current_leave_scheme'),
                                    field:'metadata.leave_scheme.name',
                                    width:220,
                                    editable:false,
                                }
                            ]}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
        <ModalAssignLeaveMassScheme1 toggle={scheme_toggle} closeModal={()=>{ setSchemeToggle(false); setSelectedData([]) }} selected_data={selected_data} onSuccess={()=>{
            setSchemeToggle(false);
            setSelectedData([]);
            gridOptions.api.deselectAll();
        }}  />
        </>
    )
}


