import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEEMPLOYEERELATION_SUCCESS = 'UPDATEEMPLOYEERELATION_SUCCESS';
export const UPDATEEMPLOYEERELATION_REQUEST = 'UPDATEEMPLOYEERELATION_REQUEST';
export const UPDATEEMPLOYEERELATION_FAILURE = 'UPDATEEMPLOYEERELATION_FAILURE';
export const UPDATEEMPLOYEERELATION_RESET = 'UPDATEEMPLOYEERELATION_RESET';

export const update_employee_relationship = (familydata)=>{
    return dispatch =>{
        dispatch({
            type: 'UPDATEEMPLOYEERELATION_REQUEST'
        });
        if(familydata.length !== 0){
            for (let i =0;i<familydata.length; i++){

                const { name, id, contact_detail, relation} = familydata[i];

                myaxios.patch(`employee/relationship/${id}/`,{
                    name:name,
                    relation:relation,
                    contact_detail:contact_detail,
                })
                .then((response)=>{
                    if(i === familydata.length -1){
                        dispatch({
                            type:'UPDATEEMPLOYEERELATION_SUCCESS',
                            payload:response
                        });

                        Toast.fire({
                            type: 'success',
                            title: IMLocalized('update_success')
                        });
                    }
                })
                .catch((error)=>{
                    dispatch({
                        type: 'UPDATEEMPLOYEERELATION_FAILURE',
                        payload: error,
                        error:true
                    });

                    ErrToast.fire({
                        type:'error',
                        title:IMLocalized('update_failure')
                    });
                })
            }
        }
    }
}

export const reset_update_employee_relationship = () => dispatch =>{
    dispatch({
        type:'UPDATEEMPLOYEERELATION_RESET'
    });
}