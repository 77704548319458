import React from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";


export default function LeaveCalendarStatusCheckbox({ status_toggle, setStatusToggle, status_option, radioChange}){


    return(
        <Dropdown size="sm" direction="down" isOpen={status_toggle} toggle={()=>setStatusToggle(prevState=>!prevState)}>
            <DropdownToggle  className="bg-neutral text-primary" caret aria-expanded={status_toggle} >
                {IMLocalized('filter_by_status')}
            </DropdownToggle>
            <DropdownMenu className="dropdown-scroll">
            <div className="custom-control custom-radio ml-2 mb-1">
                <input className="custom-control-input" id="leavecalendarstatusRadio1" name="leavecalendarstatusRadio" type="radio" value="ALL" checked = {status_option ==='ALL'} onChange={radioChange}/>
                <label className="custom-control-label" htmlFor="leavecalendarstatusRadio1">{IMLocalized('ALL')}</label>
            </div>
            <div className="custom-control custom-radio ml-2 mb-1">
                <input className="custom-control-input" id="leavecalendarstatusRadio2" name="leavecalendarstatusRadio" type="radio" value="PENDING" checked = {status_option ==='PENDING'} onChange={radioChange}/>
                <label className="custom-control-label" htmlFor="leavecalendarstatusRadio2">{IMLocalized('PENDING')}</label>
            </div>
            <div className="custom-control custom-radio ml-2 mb-1">
                <input className="custom-control-input" id="leavecalendarstatusRadio3" name="leavecalendarstatusRadio" type="radio" value="APPROVED" checked = {status_option ==='APPROVED'} onChange={radioChange}/>
                <label className="custom-control-label" htmlFor="leavecalendarstatusRadio3">{IMLocalized('APPROVED')}</label>
            </div>
            <div className="custom-control custom-radio ml-2 mb-1">
                <input className="custom-control-input" id="leavecalendarstatusRadio4" name="leavecalendarstatusRadio" type="radio" value="REJECTED" checked = {status_option ==='REJECTED'} onChange={radioChange}/>
                <label className="custom-control-label" htmlFor="leavecalendarstatusRadio4">{IMLocalized('REJECTED')}</label>
            </div>
            <div className="custom-control custom-radio ml-2 mb-1">
                <input className="custom-control-input" id="leavecalendarstatusRadio5" name="leavecalendarstatusRadio" type="radio" value="CANCELLED" checked = {status_option ==='CANCELLED'} onChange={radioChange}/>
                <label className="custom-control-label" htmlFor="leavecalendarstatusRadio5">{IMLocalized('CANCELLED')}</label>
            </div>
            <div className="custom-control custom-radio ml-2 mb-1">
                <input className="custom-control-input" id="leavecalendarstatusRadio6" name="leavecalendarstatusRadio" type="radio" value="CONSUMED" checked = {status_option ==='CONSUMED'} onChange={radioChange}/>
                <label className="custom-control-label" htmlFor="leavecalendarstatusRadio6">{IMLocalized('CONSUMED')}</label>
            </div>
            </DropdownMenu>
        </Dropdown>
    )
}