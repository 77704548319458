import React,{Component} from 'react';
import {connect} from 'react-redux';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import {dropdown_list} from '../../action/dropdown_list';
import { Container,Row,Col,Modal,ModalBody,ModalFooter,Table,ModalHeader } from 'reactstrap';
import {employee_bank_array_validate} from '../../action/wizard_employee/employee_bank_array_validate';
import {employee_bank_array_save} from '../../action/wizard_employee/employee_bank_array_save';
import Swal from 'sweetalert2';
import {IMLocalized} from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';

const gridOptions={
    singleClickEdit:true,
    defaultColDef:{
        sortable:true,
        filter:true,
        suppressMenu:true,
        resizable:true
    },
    sideBar:{
        toolPanels:[
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                
            }
        ],
        defaultToolPanel:''
    },
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
}

class wizard_employee_bank extends Component{


    constructor(props){
        super(props);
        this.state={
            list:[],
            rowData:[],
            columnsDefs:[],
            count:0,
            step:0,
            validatelist:[],
            error_msg:[],
            err_toggle:false,
            language:localStorage.getItem('language'),
            noRowTemplate:IMLocalized('no_data'),
        }
    }

    componentDidMount(){
        const {list} = this.props;
        this.setState({list});
        this.props.dropdown_list();
    }

    componentDidUpdate(prevProps,prevState){
        if(prevState.list !== this.state.list || prevProps.bank_payee !== this.props.bank_payee || prevState.count !== this.state.count){
            this.getDetail();
        }
        if(prevProps.validate_success !== this.props.validate_success){
            const {validatelist} = this.state;
            this.props.employee_bank_array_save(validatelist);
        }
        if(prevProps.validate_errors !== this.props.validate_errors){
            const {validatelist} = this.state;
            const {validate_errors} = this.props;
            if(validate_errors !== undefined){
                const errors = validate_errors.response.data;
                if(errors !== undefined){
                    if(errors.length !== 0){
                        let arr = [];
                        for(let i=0;i<errors.length;i++){
                            const object1 = errors[i];
                            const name = validatelist[i].name;
                            const number = validatelist[i].employee_number;
                            let arr1 = [];
                            if(object1 !== true){
                                if('non_field_errors' in object1){
                                    
                                    const word = object1['non_field_errors'].toString();
                                    arr1.push(<div><span>● {word}</span></div>)

                                    gridOptions.api.forEachNode((rowNode,index)=>{
                                        if(rowNode.data.employee_number === number){
                                            rowNode.data.err_non = true;
                                            gridOptions.api.refreshCells(rowNode)
                                        }
                                    })
                                }
                                if('bank' in object1){
                                
                                    const word = `${IMLocalized('bank')} : `+ object1['bank'].toString();
                                    arr1.push(<div><span>● {word}</span></div>)

                                    gridOptions.api.forEachNode((rowNode,index)=>{
                                        if(rowNode.data.employee_number === number){
                                            rowNode.data.err_bank = true;
                                            gridOptions.api.refreshCells(rowNode)
                                        }
                                    })
                                }
                                if('account_number' in object1){
                

                                    const word = `${IMLocalized('account_number')} : ` + object1['account_number'].toString();
                                    arr1.push(<div><span>● {word}</span></div>)

                                    gridOptions.api.forEachNode((rowNode,index)=>{
                                        if(rowNode.data.employee_number === number){
                                            rowNode.data.err_account = true;
                                            gridOptions.api.refreshCells(rowNode)
                                        }
                                    })
                                }
                                if('employee' in object1){
                                    
                                    const word = `${IMLocalized('employee')} : ` + object1['employee'].toString();
                                    arr.push(<div><span>● {word}</span></div>)

                                    gridOptions.api.forEachNode((rowNode,index)=>{
                                        if(rowNode.data.employee_number === number){
                                            rowNode.data.err_non = true;
                                            gridOptions.api.refreshCells(rowNode)
                                        }
                                    })
                                }
                                if(arr1.length !== 0){
                                    arr.push(<tr><td>{number}</td><td>{name}</td><td>{arr1}</td></tr>)
                                }
                            }
                        }
                        this.setState({error_msg:arr});
                        this.setState({err_toggle:true})
                    }
                }
                
            }
        }
        if(prevProps.create_success !== this.props.create_success){
            const {rowData} = this.state;
            const data = gridOptions.api.getSelectedRows();
            const idlist = data.map((item)=>{
                return item.employee_number
            }) 
            let arr = rowData.filter(function(item){
                return idlist.includes(item.employee_number) === false
            })
            this.setState({rowData:arr});
            if(arr.length !== 0){
                Swal.fire({
                    type:'warning',
                    title:IMLocalized('do_you_continue_add_bank'),
                    text:IMLocalized('skip_move_to_next_step'),
                    showCancelButton:true,
                    cancelButtonText:IMLocalized('skip'),
                    confirmButtonText:IMLocalized('continue')
                })
                .then((result)=>{
                    if('value' in result){
                        
                        
                    }
                    else{
                        // 0 not show this msg
                        if(arr.length !== 0){
                            Swal.fire({
                                type:'warning',
                                title:`${arr.length} ${IMLocalized('ppl_not_add_bank')}`,
                                showCancelButton:true,
                                cancelButtonText:IMLocalized('cancel'),
                                confirmButtonText:IMLocalized('ok!')
                            })
                            .then((result1)=>{
                                if('value' in result1){
                                    this.props.onNext();
                                }
                            })
                        }
                        else{
                            this.props.onNext();
                        }
                    }
                })
            }
            else{
                this.props.onNext();
            }
        }
    }

    getDetail=()=>{
        let arr = [];
        const {list} = this.state;
        const {bank_payee} = this.props;
        let bank_option = [''];
        if(bank_payee.length !== 0){
            for(let i=0;i<bank_payee.length;i++){
                const name = bank_payee[i].display;
                bank_option.push(name);
            }
        }
        if(list.length !== 0){
            for(let i=0;i<list.length;i++){
                const name = list[i].employee.name;
                const employee_number = list[i].employee.employee_number;
                const account_number = null;
                const bank = null;
                const err_bank = false;
                const err_account = false;
                const err_non = false;
                arr.push({name,employee_number,account_number,bank,err_bank,err_account,err_non});
            }
        }
        this.setState({rowData:arr});

        const columnsDefs =[
            {
                headerName:IMLocalized('agrid_select'),
                field:'',
                editable:false,
                width:120,
                lockPosition:true,
                pinned:'left',
                filter:false,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
            },
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee_number',
                editable:false,
                width:200
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'name',
                editable:false,
                width:200
            },
            {
                headerName:IMLocalized('agrid_bank'),
                field:'bank',
                editable:true,
                width:200,
                cellEditor:'agSelectCellEditor',
                cellEditorParams:{
                    values:bank_option,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_bank}
                },
            },
            {
                headerName:IMLocalized('agrid_account_number'),
                field:'account_number',
                editable:true,
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_account}
                },
            }
        ]
        this.setState({columnsDefs});
    }

    isFirstColumn(params) {
        if(params !== null){
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }   
    }

    getTemplate=()=>{
        const {language} = this.state;
        const rowData = gridOptions.api.getSelectedRows();
        const {bank_payee} = this.props;
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_bank');
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');
        
        ws.columns =[
            {header:IMLocalized('agrid_emp_id'),key:'employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'name'},
            {header:IMLocalized('agrid_bank'),key:'bank'},
            {header:IMLocalized('agrid_account_number'),key:'account_number'}
        ];

        ws3.getCell('A1').value = 'WizardEmployeeBank';
        ws3.getCell('A2').value = language;
        for(let i=0;i<bank_payee.length;i++){
            const y =i+1;
            ws2.getCell('A'+y).value = bank_payee[i].display;
        }
        if(bank_payee.length !== 0){
            for(let i=2;i<2001;i++){
                ws.getCell('C'+i).dataValidation={
                    type:'list',
                    allowBlank:false,
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                    formulae:['=setting!$A$1:$A$'+bank_payee.length]
                }
            }
        }
        if(rowData.length !== 0){
            for(let i=0;i<ws.columns.length;i++){
                const field = ws.columns[i].key;
                for(let x=0;x<rowData.length;x++){
                    const step = 2+x;
                    const data = this.getValue(rowData[x],field);
                    if(field === 'account_number' || field === 'bank'){
                        ws.getCell(this.getColumnName(i)+step).value = null;
                        ws.getCell(this.getColumnName(i)+step).numFmt = '@';
                    }
                    else{
                        ws.getCell(this.getColumnName(i)+step).value = data;
                    }
                    
                }
            }
            ws.columns.forEach(column =>{
                column.width = column.header.length < 15 ? 15 : column.header.length
            })
    
            wb.xlsx.writeBuffer().then((buf)=>{
                
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, 'Wizard_Employee_Bank_Template.xlsx')
            });
        }
        else{
            Swal.fire({
                title:IMLocalized('no_employee_selected'),
                type:'warning',
                confirmButtonText:IMLocalized('ok!')
            })
        }
        
    }

    getValue(object, path) {
        const parts = path.split(".");
       
        return parts.reduce((value, key) => value ? value[key] : value, object)
    }

    getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }

    handleFile = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    skipStep=()=>{
        Swal.fire({
            title:IMLocalized('skip_emp_bank_creation'),
            type:'warning',
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then((result)=>{
            if(result.value){
                this.props.onNext();
            }
        })
    }

    uploadExcel=(e)=>{
        let file = e.target.files[0];
        const reader = new FileReader();
        const {language } = this.state;
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3];
            const ws = wb.Sheets[wsname];
            const columns = {
                'A':'employee_number',
                'B':'name',
                'C':'bank',
                'D':'account_number'
            }
            let rowData=[];
            let rowIndex = 2;
            let idd = '';
            let lang = '';
            if(ws3 !== undefined){
                if(ws3['A1'] !== undefined){
                    idd = ws3['A1'].w;
                    lang = ws3['A2'].w;
                }
            }

            if(idd === 'WizardEmployeeBank'){
                if(lang === language){
                    while(ws['A'+rowIndex]){
                        let row = {};
                        for(let i =0;i<Object.keys(columns).length;i++){
                            const column = Object.keys(columns)[i];
                            if(ws[column+rowIndex] !== undefined){
                                row[columns[column]] = ws[column+rowIndex].v;
                            }
                            else{
                                row[columns[column]] = null;
                            }
                        }
                        // Object.keys(columns).forEach(function(column){
                        //     if(ws[column+rowIndex] !== undefined){
                        //         row[columns[column]] = ws[column+rowIndex].v;
                               
                        //     }
                        //     else{
                        //         row[columns[column]] = null;
                        //     }
                        // })
                        rowData.push(row);
                        rowIndex++;
                    }
                    Promise.resolve(this.getClearAggrid())
                    .then(()=> this.getImportData(rowData))
                }
                else{
                    Swal.fire({
                        title:IMLocalized('wrong_file_selected'),
                        text:IMLocalized('please_choose_correct_language'),
                        type:'error',
                        confirmButtonColor:'#d33',
                        confirmButtonText:IMLocalized('ok!')
                    })
                }
            }
            else{
                Swal.fire({
                    title:IMLocalized('wrong_file_selected'),
                    type:'error',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!')
                })
            }
            
        }
        reader.readAsBinaryString(file);
    }

    getClearAggrid=()=>{
        gridOptions.api.forEachNode((rowNode,index)=>{
            rowNode.setSelected(false);
        })
    }

    getImportData=(data)=>{
        for(let i=0;i<data.length;i++){
            const employee_number = data[i].employee_number;
            const bank = data[i].bank;
            const account_number = data[i].account_number;
            gridOptions.api.forEachNode((rowNode,index)=>{
                const employee_number1 = rowNode.data.employee_number;
                let err_bank = false;
                let err_account = false;
                if(employee_number1 === employee_number){
                    rowNode.data.err_account = err_account;
                    rowNode.data.err_bank = err_bank;
                    rowNode.data.bank = bank;
                    rowNode.data.account_number = account_number;
                    rowNode.setSelected(true);
                    gridOptions.api.refreshCells(rowNode)
                }
            })
        }
    }

    onCellValueChanged=(params)=>{
        if(params.oldValue !== params.newValue){
            const column = params.column.colDef.field;
            if(column === 'bank'){
                params.data.err_bank = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            else if(column === 'account_number'){
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
                params.data.err_account = false;
            }
            params.api.refreshCells();
        }
    }

    onCellFocused=(params)=>{
        if(params !== null){
            if(params.column.colDef !== null){
                if(params.column.colDef.field ==='account_number' || params.column.colDef.field ==='bank'){
                    
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = true;
                }
                else{
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = false;
                }
            }   
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.resetRowHeights();
    };

    clearData=()=>{
        const {count} = this.state;
        const final = count + 1;
        this.setState({rowData:[]});
        this.setState({count:final});
        this.setState({error_msg:[]});
        this.setState({step:0})
    }

    validateBank=()=>{
        const {list} = this.state;
        const {bank_payee} = this.props;
        let arr =[];
        const data = gridOptions.api.getSelectedRows();
        for(let i =0;i<data.length;i++){
            const item = data[i];
            const employee_number = item.employee_number;
            const name = item.name;
            let employee = null;
            const emp_detail =  list.filter(function(item){
                return item.employee.employee_number === employee_number;
            })
            emp_detail.forEach((item1)=>{
                employee = item1.employee.id;
            })

            const bank_name = item.bank;
            const bank_detail = bank_payee.filter(function(item){
                return item.display === bank_name
            })
            let bank = null;
            bank_detail.forEach((item)=>{
                bank = item.value;
            })

            const account_number = item.account_number;
            arr.push({name,employee,account_number,bank,employee_number});
        }
        
        if(arr.length !== 0){
            this.props.employee_bank_array_validate(arr);
            this.setState({validatelist:arr});
        }
        else{
            Swal.fire({
                type:'warning',
                title:IMLocalized('no_data_updated'),
                confirmButtonText:IMLocalized('ok!'),
                confirmButtonColor:'#d33',
            })
        }
    }

    setErrToggle=()=>{
        const toggle = !this.state.err_toggle;
        this.setState({err_toggle:toggle});
    }

    getErrorMsg=()=>{
        const table=(
            <Modal isOpen={this.state.err_toggle} size="lg" >
                <ModalHeader toggle={this.setErrToggle}>{IMLocalized('error_msg')}</ModalHeader>
                <ModalBody>
                    <Table size="sm" className="table-bordered align-items-center" responsive>
                        <thead className="thead-light p-2">
                            <tr>
                                <th>{IMLocalized('employee_number')}</th>
                                <th>{IMLocalized('name')}</th>
                                <th>{IMLocalized('error')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.error_msg}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-danger btn-sm" onClick={this.setErrToggle}>{IMLocalized('close')}</button>
                </ModalFooter>
            </Modal>
        )

        return table;
    }

    goBack=()=>{
        this.props.goBack();
    }

    render(){

        const {rowData}  =this.state;
        let btn = '';
        let func_btn ='';
        if(rowData.length !== 0){
            btn =<button onClick={this.getTemplate} className="btn btn-primary btn-sm">{IMLocalized('get_employee_bank_template')}</button>

            func_btn= <button onClick={this.validateBank} className="btn btn-primary btn-sm">{IMLocalized('create')}</button>
        }
        return(
            <div>
                {this.props.isLoading  ? <OverlayLoading />:
                <div>
                    <Container fluid>
                        <Row className="pt-2">
                            <Col>
                                <div className="d-flex">
                                    <div className="mr-2">
                                        <button className="btn btn-primary btn-sm" onClick={this.goBack}> <i className="fas fa-chevron-left"></i> {IMLocalized('back_to_main_page')}</button>
                                        <button onClick={this.skipStep} className="btn btn-primary btn-sm">{IMLocalized('skip')}</button>
                                        {btn}
                                    </div>
                                    <div className="ml-2 mr-2">
                                        <input type="file" style={{display:'none'}} id="ImportBankcustomFile" className="custom-file-input form-control-sm" onChange={this.uploadExcel.bind(this)} onClick={this.handleFile} accept=".xlsx" />
                                        <label className="btn btn-primary btn-sm" for="ImportBankcustomFile">{IMLocalized('import_from_file')}</label>
                                    </div>
                                    <div>
                                        
                                        <button className="btn btn-primary btn-sm" onClick={this.clearData}>{IMLocalized('clear')}</button>
                                        {func_btn}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <div className="ag-theme-balham" style={{height:'calc(100vh - 200px)', width:'100%' }}>
                                        <AgGridReact
                                        columnDefs={this.state.columnsDefs}
                                        rowData={this.state.rowData}
                                        suppressDragLeaveHidesColumns={true}
                                        suppressSizeToFit={true}
                                        suppressColumnMoveAnimation={false}
                                        singleClickEdit={true}
                                        rowClassRules={{
                                            'sick-days-warning': function (params) {
                                                var numSickDays = params.data.err_non;
                                                return numSickDays ===true
                                                },
                                        
                                        }}
                                        defaultColDef={this.state.defaultColDef}
                                        onGridReady={this.onGridReady}
                                        gridOptions={gridOptions}
                                        stopEditingWhenCellsLoseFocus={true}
                                        overlayNoRowsTemplate = {this.state.noRowTemplate}
                                        onCellFocused={this.onCellFocused}
                                        onCellValueChanged={this.onCellValueChanged}
                                        >
                                        </AgGridReact>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {this.getErrorMsg.call(this)}
                    </Container>
                </div>}
            </div>
        )
    }
}
function mapStateToProps(state){
    return{
        bank_payee:state.dropdown_list.bank_payee,
        validate_success:state.employee_bank_array_validate.data,
        create_success:state.employee_bank_array_save.data,
        validate_errors:state.employee_bank_array_validate.errors,
        isLoading:state.dropdown_list.isLoading,
        // isLoading1:state.employee_bank_array_save.isLoading,
        // isLoading2:state.employee_bank_array_validate.isLoading,

    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        dropdown_list:()=>{
            dispatch(dropdown_list())
        },
        employee_bank_array_validate:(data)=>{
            dispatch(employee_bank_array_validate(data))
        },
        employee_bank_array_save:(data)=>{
            dispatch(employee_bank_array_save(data))
        }
    }  
}


export default connect(mapStateToProps, mapDispatchToProps)(wizard_employee_bank);