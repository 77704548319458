import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const EMPCHILDARRAYSAVE_REQUEST = 'EMPCHILDARRAYSAVE_REQUEST';
export const EMPCHILDARRAYSAVE_SUCCESS = 'EMPCHILDARRAYSAVE_SUCCESS';
export const EMPCHILDARRAYSAVE_FAILURE = 'EMPCHILDARRAYSAVE_FAILURE';
export const EMPCHILDARRAYSAVE_RESET = 'EMPCHILDARRAYSAVE_RESET';


export const employee_child_array_save =(data)=>{
    return dispatch=>{
        dispatch({
            type:'EMPCHILDARRAYSAVE_REQUEST'
        });

        myaxios.post('leave/sg/child/array_save/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'EMPCHILDARRAYSAVE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title:IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'EMPCHILDARRAYSAVE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}

export const reset_employee_child_array_save = () => dispatch =>{
    dispatch({
        type:'EMPCHILDARRAYSAVE_RESET'
    });
}