import * as period from '../action/get_payroll_period';

const initialState={
    data:null,
    errors:null,
    isLoading:false,
}


const get_payroll_period = (state=initialState,action)=>{
    switch(action.type){
        case period.PAYROLLPERIOD_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case period.PAYROLLPERIOD_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case period.PAYROLLPERIOD_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload,
            }
        default:
            return state;
    }
}
export default get_payroll_period;
