import React from 'react';
import { Modal,ModalBody,ModalFooter,ModalHeader } from "reactstrap";
import QRCode from 'react-qr-code';
import {IMLocalized} from '../../../language/IMLocalized';

function ModalQr(props){
    const title = process.env.REACT_APP_TITLE;
    const url = process.env.REACT_APP_API_URL;
    const key = 'aline_hr';

    const object = {title,url,key};

    return(
        <Modal isOpen={props.toggle}  >
            <ModalHeader toggle={()=>props.closeModal()}>{IMLocalized('qr_code_for_mobile_app')}</ModalHeader>
            <ModalBody>
                <div className="d-flex justify-content-center pt-2">
                    <QRCode value={JSON.stringify(object)}></QRCode>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={()=>props.closeModal()}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default ModalQr;