import React from "react";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { getDateFormat } from "../../../func/getDateFormat";
import { IMLocalized } from "../../../language/IMLocalized";



function ResignTable(props){

    return(
        <Row>
            <Col>
                <Card style={{height:'270px'}}>
                    <CardHeader className="border-0 p-2 bg-white">
                        <h3 className="title-color1 pl-2 pt-2">
                            {IMLocalized('resigning_soon')}
                        </h3>
                    </CardHeader>
                    {props.isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                    {!props.isLoading && props.list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                    {!props.isLoading && props.list.length !== 0 && 
                    <Table className="align-items-center" size="sm" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th>{IMLocalized('emp_id')}</th>
                                <th>{IMLocalized('name')}</th>
                                <th>{IMLocalized('resign_date')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.list.map((item,index)=>{

                                return(
                                    <tr key={index}>
                                        <td>{item.employee_number}</td>
                                        <td>{item.name}</td>
                                        <td>{getDateFormat(item.resignation_date)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>}
                </Card>
            </Col>
        </Row>
    )
}
export default ResignTable;