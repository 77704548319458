import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, ModalHeader, Input, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { reset_update_contract_template, update_contract_template } from '../../../action/update_contract_template';
import { IMLocalized } from '../../../language/IMLocalized';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { get_additional_setting} from '../../../action/additional_setting';
import { create_contract_department, reset_create_contract_department} from '../../../action/create_contract_department';
import { create_contract_section, reset_create_contract_section} from '../../../action/create_contract_section';
import { create_contract_job_title, reset_create_contract_job_title} from '../../../action/create_contract_job_title';
import { create_contract_job_level, reset_create_contract_job_level} from '../../../action/create_contract_job_level';
import { create_contract_cost_center, reset_create_contract_cost_center} from '../../../action/create_contract_cost_center';
import { get_contract_template_list} from '../../../action/get_contract_template_list';

const initialState={
    name:'',
    err_name:'',
    lower_pay_range:'',
    err_lower_pay_range:'',
    upper_pay_range:'',
    err_upper_pay_range:'',
    contract_type:'',
    err_contract_type:'',
    contract_rate:'',
    err_contract_rate:'',
    wschedule:'',
    err_wschedule:'',
    is_workman:false,
    hours_day:'',
    err_hours_day:'',
    department:null,
    err_department:'',
    section:null,
    err_section:'',
    job_title:null,
    err_job_title:'',
    job_level:null,
    err_job_level:'',
    cost_center:null,
    err_cost_center:'',
    allowances:null,
    recurring_allowances:null,
    pending_department:false,
    pending_section:false,
    pending_joblevel:false,
    pending_jobtitle:false,
    pending_costcenter:false,
    is_pending:false,
}

function ModalEditContractTemplate({toggle, closeModal, detail}){
   
    const [state,setState]= useState(initialState);
    const dispatch = useDispatch();
    const contract_type_list = useSelector(state=>state.get_contract_template_dropdown.contract_type);
    const contract_rate_list = useSelector(state=>state.get_contract_template_dropdown.contract_rate);
    const schedule_list = useSelector(state=>state.schedule_list.data);
    const departmentlist = useSelector(state=>state.add.department);
    const sectionlist = useSelector(state=>state.add.section);
    const costcenterlist = useSelector(state=>state.add.costcenter);
    const jobtitlelist = useSelector(state=>state.add.jobtitle);
    const joblevellist = useSelector(state=>state.add.joblevel);
    const allowancelist = useSelector(state=>state.add.allowance);
    const recurringlist = useSelector(state=>state.add.recurringallowance);
    const isLoading = useSelector(state=>state.create_contract_template.isLoading);
    const department_success = useSelector(state=>state.create_contract_department.data);
    const section_success = useSelector(state=>state.create_contract_section.data);
    const jobtitle_success = useSelector(state=>state.create_contract_job_title.data);
    const joblevel_success = useSelector(state=>state.create_contract_job_level.data);
    const costcenter_success = useSelector(state=>state.create_contract_cost_center.data);
    const update_errors = useSelector(state=>state.update_contract_template.errors);
    const update_success = useSelector(state=>state.update_contract_template.data);

    useEffect(()=>{
        if( update_errors != null && toggle){
            if(update_errors.hasOwnProperty('name')){
                const word = update_errors['name'].toString();
                setState(prevState=>({...prevState,err_name:word}));
            }
            dispatch( get_additional_setting());
            dispatch(reset_update_contract_template());
        }
    },[update_errors,toggle,dispatch])

    useEffect(()=>{
        if(toggle && update_success != null){
            closeModal();
            dispatch(get_contract_template_list());
            dispatch(get_additional_setting());
            dispatch(reset_update_contract_template());
        }
    },[update_success,toggle,closeModal,dispatch])

    useEffect(()=>{
        if( department_success != null && toggle){
            setState(prevState=>({...prevState,pending_department:false}));
            dispatch(reset_create_contract_department());
        }
    },[toggle,department_success,dispatch])

    useEffect(()=>{
        if( section_success != null && toggle){
            setState(prevState=>({...prevState,pending_section:false}));
            dispatch(reset_create_contract_section());
        }
    },[toggle,section_success,dispatch])

    useEffect(()=>{
        if( joblevel_success != null && toggle){
            setState(prevState=>({...prevState,pending_joblevel:false}));
            dispatch(reset_create_contract_job_level());
        }
    },[toggle,joblevel_success,dispatch])

    useEffect(()=>{
        if( jobtitle_success != null && toggle){
            setState(prevState=>({...prevState,pending_jobtitle:false}));
            dispatch(reset_create_contract_job_title());
        }
    },[toggle,jobtitle_success,dispatch])

    useEffect(()=>{
        if( costcenter_success != null && toggle){
            setState(prevState=>({...prevState,pending_costcenter:false}));
            dispatch(reset_create_contract_cost_center());
        }
    },[toggle,costcenter_success,dispatch])

    useEffect(()=>{
        function onUpdate(){
            const hours = state.contract_rate === 'DAILY' ? state.hours_day : 0;
            const lower = state.lower_pay_range !== '' ?  state.lower_pay_range : null;
            const upper = state.upper_pay_range !== '' ? state.upper_pay_range : null;
            const rate = state.contract_rate !== '' ? state.contract_rate : null;
            const type = state.contract_type !== '' ? state.contract_type : null;

            const dep = state.department?.value ?? null;
            const sec = state.section?.value ?? null;
            const title = state.job_title?.value ?? null;
            const level = state.job_level?.value ?? null;
            const cost = state.cost_center?.value ?? null;

            const allow = state.allowances !== null ? state.allowances.map(item=>item.value) : [];
            const recurring = state.recurring_allowances !== null ? state.recurring_allowances.map(item=>item.value) : [];
            dispatch(update_contract_template(detail.id,state.name,hours,lower,upper,state.is_workman,dep,sec,title,level,allow,recurring,cost,type,rate,state.wschedule));
        }
        if(state.is_pending && detail != null && !state.pending_costcenter && !state.pending_department && !state.pending_joblevel && !state.pending_jobtitle && !state.pending_section){
            onUpdate();
            setState(prevState=>({...prevState,is_pending:false}));
        }
    },[state.pending_costcenter,state.pending_department,state.pending_joblevel,state.pending_jobtitle,state.pending_section,state.is_pending,
    state.name,state.hours_day,state.lower_pay_range,state.upper_pay_range,state.is_workman,state.department,state.section,state.job_title,state.job_level,state.allowances,state.recurring_allowances,state.cost_center,state.contract_type,state.contract_rate,state.wschedule,dispatch,detail])

    useEffect(()=>{
        if(toggle && detail != null){
            setState(prevState=>({
                ...prevState,
                name: detail?.name ?? '',
                lower_pay_range: detail?.lower_pay_range ?? '',
                upper_pay_range: detail?.upper_pay_range ?? '',
                contract_type: detail?.contract_type ?? '',
                contract_rate: detail?.contract_rate ?? '',
                wschedule: detail?.wschedule ?? '',
                is_workman: detail.is_workman,
                hours_day: detail?.hours_day ?? '',
                department: detail?.department ? { value: detail.department, label: detail.department } : null,
                section: detail?.section ? { value:  detail.section, label: detail.section } : null,
                job_title:detail?.job_title ? { value: detail.job_title, label: detail.job_title } : null,
                job_level: detail?.job_level ? { value: detail.job_level, label: detail.job_level } : null,
                cost_center: detail?.cost_center ? { value: detail.cost_center, label: detail.cost_center } : null,
                allowances: detail?.allowances?.length === 0 ? [] : detail.allowances.map(item=>{ return {value:item,label:item }}),
                recurring_allowances: detail?.recurring_allowances?.length === 0 ? [] :  detail.recurring_allowances.map(item=>{ return {value:item,label:item }}),
            }))
        }
        else{
            setState(initialState);
        }
    },[ toggle, detail])

    const updateContract=()=>{
        if(state.name !== '' && state.wschedule !== ''){
            const department = state.department?.value ?? null;
            const section = state.section?.value ?? null;
            const job_level = state.job_level?.value ?? null;
            const job_title = state.job_title?.value ?? null;
            const cost_center = state.cost_center?.value ?? null;
    
            const is_department = departmentlist.find(item=>item.name === department);
            const is_section = sectionlist.find(item=>item.name === section);
            const is_level = joblevellist.find(item=>item.name === job_level);
            const is_title = jobtitlelist.find(item=>item.name === job_title);
            const is_cost = costcenterlist.find(item=>item.name === cost_center);
    
            if(is_department == null && department !== null){
                dispatch(create_contract_department(department));
            }
            if(is_section == null && section !== null){
                dispatch(create_contract_section(section));
            }
            if(is_level == null && job_level !== null){
                dispatch(create_contract_job_level(job_level));
            }
            if(is_title == null && job_title !== null){
                dispatch(create_contract_job_title(job_title));
            }
            if(is_cost == null && cost_center !== null){
                dispatch(create_contract_cost_center(cost_center));
            }
    
            setState(prevState=>({
                ...prevState,
                pending_department:is_department == null && department !== null ? true : false,
                pending_costcenter:is_cost == null && cost_center !== null ? true : false,
                pending_section:is_section == null && section !== null ? true : false,
                pending_joblevel:is_level == null && job_level !== null ? true : false,
                pending_title:is_title == null && job_title !== null ? true : false,
                is_pending:true
            }));
        }
        else{
            setState(prevState=>({
                ...prevState,
                err_name:IMLocalized('name_is_required'),
                err_wschedule:IMLocalized('schedule_is_required'),
            }))
        }
    }

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('contract_template')} - {detail?.name}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('name')}</label>
                            <Input id="name" size="sm" type="text" value={state.name} invalid={state.err_name.length > 0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,name:value,err_name:''}))
                            }}
                            />
                            <FormFeedback>{state.err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='lower_pay_range'>{IMLocalized('lower_pay_range')}</label>
                            <Input size="sm" id="lower_pay_range" type="number" value={state.lower_pay_range} invalid={state.err_lower_pay_range.length >0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,lower_pay_range:value,err_lower_pay_range:''}));
                            }}
                            />
                            <FormFeedback>{state.err_lower_pay_range}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='upper_pay_range'>{IMLocalized('upper_pay_range')}</label>
                            <Input size="sm" id="upper_pay_range" type="number" value={state.upper_pay_range} invalid={state.err_upper_pay_range.length >0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,upper_pay_range:value,err_upper_pay_range:''}))
                            }}
                            />
                            <FormFeedback>{state.err_upper_pay_range}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='contract_type'>{IMLocalized('contract_type')}</label>
                            <Input size="sm" id="contract_type" type="select" value={state.contract_type} invalid={state.err_contract_type.length !== 0 } 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,contract_type:value,err_contract_type:''}))
                            }}>
                                <option></option>
                                {contract_type_list.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{state.err_contract_type}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='contract_rate'>{IMLocalized('contract_rate')}</label>
                            <Input size="sm" id="contract_rate" type="select" value={state.contract_rate} invalid={state.err_contract_rate.length !== 0 } 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,contract_rate:value,err_contract_rate:''}))
                            }}>
                                <option></option>
                                {contract_rate_list.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{state.err_contract_rate}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='wschedule'>{IMLocalized('schedule')}</label>
                            <Input size="sm" id="wschedule" type="select" value={state.wschedule} invalid={state.err_wschedule.length > 0}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,wschedule:value,err_wschedule:''}))
                            }}>
                                <option></option>
                                {schedule_list.map((item,index)=>{
                                    return <option value={item.id} key={index}>{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{state.err_wschedule}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6" className="d-flex align-items-center">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="contracttemplateisworkman" name='is_workman' className="custom-control-input" checked={state.is_workman} 
                            onChange={(e)=>{
                                const checked = e.target.checked;
                                setState(prevState=>({...prevState,is_workman:checked}))
                            }} />
                            <label className="custom-control-label" htmlFor="contracttemplateisworkman">{IMLocalized('is_workman')}</label>
                        </div>
                    </Col>
                    {state.contract_rate === 'DAILY' &&
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='hours_day'>{IMLocalized('hours_per_day')}</label>
                            <Input size="sm" id="hours_day" type="number" value={state.hours_day} invalid={state.err_hours_day.length >0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,hours_day:value,err_hours_day:''}));
                            }}
                            />
                            <FormFeedback>{state.err_hours_day}</FormFeedback>
                        </FormGroup>
                    </Col>}
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='department'>{IMLocalized('department')}</label>
                            <CreatableSelect styles={{
                            control: (provided,) => (state.err_department.length>0 ? {
                            ...provided, borderColor: '#dc3545', 
                            } : provided)
                            }} isClearable 
                            options={departmentlist.map((item)=>{
                                return { value: item.name, label: item.name}
                            })} 
                            value={state.department} 
                            onChange={(value)=>setState(prevState=>({...prevState,department:value,err_department:''}))} />
                            <div>
                                <small className='text-danger'>{state.err_department}</small>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='section'>{IMLocalized('section')}</label>
                            <CreatableSelect styles={{
                            control: (provided,) => (state.err_section.length>0 ? {
                            ...provided, borderColor: '#dc3545', 
                            } : provided)
                            }} isClearable 
                            options={sectionlist.map((item)=>{
                                return { value: item.name, label: item.name}
                            })} 
                            value={state.section} 
                            onChange={(value)=>setState(prevState=>({...prevState,section:value,err_section:''}))} />
                            <div>
                                <small className='text-danger'>{state.err_section}</small>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='job_title'>{IMLocalized('job_title')}</label>
                            <CreatableSelect styles={{
                            control: (provided,) => (state.err_job_title.length>0 ? {
                            ...provided, borderColor: '#dc3545', 
                            } : provided)
                            }} isClearable 
                            options={jobtitlelist.map((item)=>{
                                return { value: item.name, label: item.name}
                            })} 
                            value={state.job_title} 
                            onChange={(value)=>setState(prevState=>({...prevState,job_title:value,err_job_title:''}))} />
                            <div>
                                <small className='text-danger'>{state.err_job_title}</small>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='job_level'>{IMLocalized('job_level')}</label>
                            <CreatableSelect styles={{
                            control: (provided,) => (state.err_job_level.length>0 ? {
                            ...provided, borderColor: '#dc3545', 
                            } : provided)
                            }} isClearable 
                            options={joblevellist.map((item)=>{
                                return { value: item.name, label: item.name}
                            })} 
                            value={state.job_level} 
                            onChange={(value)=>setState(prevState=>({...prevState,job_level:value,err_job_level:''}))} />
                            <div>
                                <small className='text-danger'>{state.err_job_level}</small>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='cost_center'>{IMLocalized('cost_center')}</label>
                            <CreatableSelect styles={{
                            control: (provided,) => (state.err_cost_center.length>0 ? {
                            ...provided, borderColor: '#dc3545', 
                            } : provided)
                            }} isClearable 
                            options={costcenterlist.map((item)=>{
                                return { value: item.name, label: item.name}
                            })} 
                            value={state.cost_center} 
                            onChange={(value)=>setState(prevState=>({...prevState,cost_center:value,err_cost_center:''}))} />
                            <div>
                                <small className='text-danger'>{state.err_cost_center}</small>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='allowance'>{IMLocalized('allowance')}</label>
                            <Select isMulti className="basic-multi-select" classNamePrefix="select"
                            options={allowancelist.map((item)=>{
                                return {value:item.name, label:item.name}
                            })}
                            onChange={(value)=>setState(prevState=>({...prevState,allowances:value}))}
                            value={state.allowances} 
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='recurring_allowances'>{IMLocalized('recurring_allowance')}</label>
                            <Select isMulti className="basic-multi-select" classNamePrefix="select"
                            options={recurringlist.map((item)=>{
                                return {value:item.name, label:item.name}
                            })}
                            onChange={(value)=>setState(prevState=>({...prevState,recurring_allowances:value}))}
                            value={state.recurring_allowances} 
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={()=>updateContract()}>{IMLocalized('update')}</button>
                    <button className="btn btn-danger btn-sm" onClick={()=>closeModal()}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default ModalEditContractTemplate;