import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const EMPBANKARRAYVALIDATE_REQUEST = 'EMPBANKARRAYVALIDATE_REQUEST';
export const EMPBANKARRAYVALIDATE_SUCCESS = 'EMPBANKARRAYVALIDATE_SUCCESS';
export const EMPBANKARRAYVALIDATE_FAILURE = 'EMPBANKARRAYVALIDATE_FAILURE';

export const employee_bank_array_validate = (data)=>{
    return dispatch=>{
        dispatch({
            type:'EMPBANKARRAYVALIDATE_REQUEST'
        });

        myaxios.post('employee/bank/array_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'EMPBANKARRAYVALIDATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPBANKARRAYVALIDATE_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}