import React, { useContext } from "react";
import { NewMainContext } from "../../context/NewMainContext";
import ClaimAllList from "./ClaimAllList";
import ClaimPaySummaryDetail from "./ClaimPaySummaryDetail";

export default function ClaimAll(){

    const { claim_all_list_step } = useContext(NewMainContext);
   
    return(
        <>
        {claim_all_list_step === '0' && <ClaimAllList/>}
        {claim_all_list_step === '1' && <ClaimPaySummaryDetail />}
        </>
    )
}