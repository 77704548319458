import React,{useCallback, useContext, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {AgGridReact} from 'ag-grid-react';
import { Container, Row, Col } from 'reactstrap';
import { IMLocalized } from '../../language/IMLocalized';
import CustomPinnedRowRenderer from '../ag-grid_component/custompinnedrow';
import OverlayLoading from '../loading_component/overlay_loading';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { get_payroll_instance } from '../../action/payroll/get_payroll_instance';
import { payroll_detail_get_aggridheader } from '../../action/payroll/payroll_detail_get_aggridheader';
import { getValue } from '../../func/getValue';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';


const mapStateToProps=(state)=>({
    list:state.get_payroll_instance.data,
    agheader:state.payroll_detail_get_aggridheader.data,
    isLoading:state.get_payroll_instance.isLoading,
    isLoading1:state.payroll_detail_get_aggridheader.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    payroll_detail_get_aggridheader:(id)=>{
        dispatch(payroll_detail_get_aggridheader(id))
    },
    get_payroll_instance:(id)=>{
        dispatch(get_payroll_instance(id))
    }
})

const initialState={
    status:'',
    payroll_date:'',
    columnDefs:[],
    rowData:[]
}

const gridOptions={
    sideBar:{
        toolPanels: [
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                toolPanelParams: {
                },
              },
            ],
    },
    defaultColDef:{
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
    }
}

function PayrollReport(props){
    const {payroll_detail_get_aggridheader,get_payroll_instance} = props;
    const [state,setState] = useState(initialState);
    const [monthNames] = useState([IMLocalized("january"), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")]);
    const { addItem } = useContext(NewMainContext);
    const gridApi = useRef();

    useEffect(()=>{
        if(props.id != null){
            get_payroll_instance(props.id);
            payroll_detail_get_aggridheader(props.id)
        }
    },[props.id,get_payroll_instance,payroll_detail_get_aggridheader])

    useEffect(()=>{
        if(props.list.length !== 0){
            const item = props.list[0];
            const {status,payroll_date,payslips} = item;
            const month1 = new Date(payroll_date).getMonth();
            const month = monthNames[month1];
            const year = new Date(payroll_date).getFullYear();
            const date1 = `${month} ${year}`;

            const arr = payslips.map((element,index)=>{
                let obj = {};
                const pay = element.pay;
                for(const value of pay){
                    if(value.pay_type === 'OT' || value.pay_type === 'FX' || value.pay_type === 'ALLOWANCE'){
                        const name = value.code;
                        const code = name.replace('.','-');
                        obj[code] = value.amount;
                    }
                }
                const emp_bonus = pay.filter((item1)=>item1.pay_type === 'BONUS').reduce((prev,current)=> prev+current.amount,0);

                const cpf_employee = element.sgcpf !== null ? element.sgcpf.cpf_employee : null;
                const cpf_employer = element.sgcpf !== null ? element.sgcpf.cpf_employer : null;
                return {...element,cpf_employee,cpf_employer,emp_bonus,...obj}
            })

            setState(prevState=>({...prevState,status,payroll_date:date1,rowData:arr}))
        }
    },[props.list,monthNames])

    const calculateBottomData=useCallback((target,data)=>{
        const columnsWithAggregation = state.columnDefs.map((item)=>{
            return item.field;
        })
        for(let i =0;i<columnsWithAggregation.length ;i++){
            const field = columnsWithAggregation[i];

            for(let j=0;j<data.length ;j++){
                const rowNode = data[j];
                if(rowNode[field] != null){
                    if(field !== 'employee.contract_cache.job_level' && field !== 'employee.contract_cache.job_title' && field !== 'employee.contract_cache.cost_center' && field !== 'employee.contract_cache.department' && field !== 'employee.contract_cache.section' && field !== 'employee.metadata.company' && field !== 'name' && field !== 'employee_number'){
                        const amount = getValue(rowNode,field);
                        console.log(target[field])
                        console.log(amount);
                        target[field] += isNaN(amount) ? 0 : Number(amount.toFixed(2));
                    }
                }
                else{
                    if(field !== 'employee.contract_cache.job_level' && field !== 'employee.contract_cache.job_title' && field !== 'employee.contract_cache.cost_center' && field !== 'employee.contract_cache.department' && field !== 'employee.contract_cache.section' && field !== 'employee.metadata.company' && field !== 'name' && field !== 'employee_number'){
                        target[field] += 0;
                    }
                }
            }

            if (target[field]){
                if(!isNaN(target[field])){
                    target[field] = `${target[field].toFixed(2)}`
                }
            } 
        }

        
       
        return target;
    },[state.columnDefs])

    const generatePinnedBottomData=useCallback((data)=>{
        let result = {};
        state.columnDefs.forEach((item)=>{
            result[item.field] = null;
        })
        const data1 = calculateBottomData(result,data);
        return [data1];
    },[state.columnDefs,calculateBottomData])

    useEffect(()=>{
        if(state.columnDefs.length !== 0 && state.rowData.length !== 0){
            const pinned = generatePinnedBottomData(state.rowData);
            setState(prevState=>({...prevState,pinnedBottomRowData:pinned}))
        }
        else{
            setState(prevState=>({...prevState,pinnedBottomRowData:[]}))
        }
    },[state.rowData,state.columnDefs,generatePinnedBottomData])

    useEffect(()=>{
        
        let newlist =[
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee.employee_number',
                lockPosition:true,
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinned:'left',
                width:100,
                editable:false,
                sortable:true,
                filter:false,
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'employee.name',
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                lockPosition:true,
                pinned:'left',
                width:200,
                editable:false,
                sortable:true,
                filter:false,
            },
            {
                headerName:IMLocalized('agrid_cost_center'),
                field:'employee.contract_cache.cost_center',
                width:150,
                editable:false,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_department'),
                field:'employee.contract_cache.department',
                filterParams:{
                    clearButton:true
                },
                width:150,
                editable:false,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_section'),
                field:'employee.contract_cache.section',
                width:150,
                editable:false,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_job_title'),
                field:'employee.contract_cache.job_title',
                width:150,
                editable:false,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_job_level'),
                field:'employee.contract_cache.job_level',
                width:150,
                editable:false,
                sortable:true
                
            },
            {
                headerName:IMLocalized('agrid_company'),
                field:'employee.metadata.company',
                width:150,
                editable:false,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_salary'),
                field:'total_wage',
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                width:150,
                editable:false,
                sortable:true,
                filter:false,
            },
            {
                headerName:IMLocalized('agrid_deduction'),
                field:'deductables',
                editable:false,
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                width:150,
                sortable:true,
                filter:false,
            },
            {
                headerName:IMLocalized('agrid_payable'),
                field:'payable_wage',
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                width:150,
                editable:false,
                sortable:true,
                filter:false,
            },
            {
                headerName:IMLocalized('agrid_cpf_employer'),
                field:'cpf_employer',
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                width:150,
                editable:false,
                sortable:true,
                filter:false,
            },
            {
                headerName:IMLocalized('agrid_cpf_employee'),
                field:'cpf_employee',
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                width:150,
                editable:false,
                sortable:true,
                filter:false,
            },
            {
                headerName:IMLocalized('agrid_bonus'),
                field:'emp_bonus',
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                width:150,
                editable:false,
                sortable:true,
                filter:false,
            }
        ]
        if(props.agheader != null){
            const {ag_grid} = props.agheader;
            for(let i=0;i<ag_grid.length;i++){
                const name = ag_grid[i];
                const headerName = name.replace('-','.');
                const field = `${name.replace('.','-')}`;
                const filter = false;
                const pinnedRowCellRenderer= 'customPinnedRowRenderer';
                const width = 150;
                newlist.push({headerName,filter,field,width,pinnedRowCellRenderer});
            }
        }
        setState(prevState=>({...prevState,columnDefs:newlist}))
    },[props.agheader])

    const onFilterChanged=()=>{
        let list=[];
        gridApi.current.forEachNodeAfterFilter(node=>{
            list.push(node.data);
        })
        const pinnedData = generatePinnedBottomData(list);
        setState(prevState=>({...prevState,pinnedBottomRowData:pinnedData}));
        gridApi.current.setPinnedBottomRowData([pinnedData]);
    }

    const exportExcel=()=>{
    
        const getParams = ()=>({
            fileName:'PayrollReport('+state.payroll_date+').xlsx',
            sheetName:'report',
            columnWidth:120
        })

        gridApi.current.exportDataAsExcel(getParams())
    }

    const toPayrollDate=()=>{
        if(state.status === 'PAID'){
            props.handlePayroll({step:'2'})
        }
        else{
            props.handlePayroll({step:'1'})
        }
    }

    return(
        <>
        {props.isLoading || props.isLoading1 ? <OverlayLoading/>:
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title: IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title: IMLocalized('payroll'), onClick:()=>props.handlePayroll({step:'0'})},
                        { active: false, title: state.payroll_date, onClick:()=>toPayrollDate()},
                        { active: true, title: IMLocalized('report')}
                    ]}/>
                    <button className='btn btn-primary btn-sm ml-2' onClick={state.status === 'PAID' ? ()=>props.handlePayroll({step:'2'}) : ()=>props.handlePayroll({step:'1'})}> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                </Col>
            </Row>
            <div className='mt-2'>
                <button className='btn btn-primary btn-sm' onClick={()=>gridApi.current.setFilterModel(null)}>{IMLocalized('clear')}</button>
                <button className='btn btn-success btn-sm' onClick={exportExcel}>{IMLocalized('export')}</button>
            </div>
            <div className='ag-theme-balham mt-2' style={{height:'calc(100vh - 300px)', width:'100%' }}>
                <AgGridReact
                gridOptions={gridOptions}
                suppressDragLeaveHidesColumns={true}
                columnDefs={state.columnDefs}
                rowData={state.rowData}
                pinnedBottomRowData={state.pinnedBottomRowData}
                onGridReady={(params)=>{
                    gridApi.current = params.api;
                }}
                onFilterChanged={onFilterChanged}
                frameworkComponents={{ customPinnedRowRenderer: CustomPinnedRowRenderer }}
                />
            </div>
        </Container>
        </>}
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PayrollReport);
