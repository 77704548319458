import * as updateclaim from '../../action/claim/update_claim';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const update_claim = (state=initialState,action)=>{
    switch(action.type){
        case updateclaim.UPDATECLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case updateclaim.UPDATECLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updateclaim.UPDATECLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data,
            }
        default:
            return state;
    }
}
export default update_claim;