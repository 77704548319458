import React, { useContext } from 'react';
import { NewMainContext } from '../../context/NewMainContext';
import AppraisalCreate from './AppraisalCreate';

import AppraisalTable from './AppraisalTable';


export default function AppraisalList(props){

    const { appraisal_list_step } = useContext(NewMainContext);


    return(
        <>
        {appraisal_list_step === 0 && <AppraisalTable />}
        {appraisal_list_step === 1 && <AppraisalCreate  />}
        </>
    )
}
