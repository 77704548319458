import * as createleave from '../action/create_leave';

const initialState={
    isLoading:false,
    errors:[],
    data:null
}

const create_leave = (state=initialState,action)=>{
    switch(action.type){
        case createleave.CREATELEAVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case createleave.CREATELEAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createleave.CREATELEAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case createleave.CREATELEAVE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default create_leave;