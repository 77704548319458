import myaxios from '../../axios';

export const GETUSEREMPLEAVEDETAIL_REQUEST = 'GETUSEREMPLEAVEDETAIL_REQUEST';
export const GETUSEREMPLEAVEDETAIL_SUCCESS = 'GETUSEREMPLEAVEDETAIL_SUCCESS';
export const GETUSEREMPLEAVEDETAIL_FAILURE = 'GETUSEREMPLEAVEDETAIL_FAILURE';
export const GETUSEREMPLEAVEDETAIL_RESET = 'GETUSEREMPLEAVEDETAIL_RESET';


export const get_user_leave_detail = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETUSEREMPLEAVEDETAIL_REQUEST'
        });

        myaxios.get(`leave/self/apply/${id}/`)
        .then((response)=>{
            dispatch({
                type:'GETUSEREMPLEAVEDETAIL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETUSEREMPLEAVEDETAIL_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_get_user_leave_detail = () => dispatch =>{
    dispatch({
        type:'GETUSEREMPLEAVEDETAIL_RESET'
    });
}