import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELETELEAVEAPPROVAL_REQUEST = 'DELETELEAVEAPPROVAL_REQUEST';
export const DELETELEAVEAPPROVAL_SUCCESS = 'DELETELEAVEAPPROVAL_SUCCESS';
export const DELETELEAVEAPPROVAL_FAILURE = 'DELETELEAVEAPPROVAL_FAILURE';
export const DELETELEAVEAPPROVAL_RESET = 'DELETELEAVEAPPROVAL_RESET';

export const delete_leave_approval = (id) =>{
    return dispatch=>{
        dispatch({
            type:'DELETELEAVEAPPROVAL_REQUEST'
        });

        myaxios.delete(`leave/approval/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELETELEAVEAPPROVAL_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELETELEAVEAPPROVAL_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_leave_approval = () => dispatch=>{
    dispatch({
        type:'DELETELEAVEAPPROVAL_RESET'
    });
}