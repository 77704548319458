import React, { useContext, useEffect, useState } from "react";
import { Col, FormFeedback, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row, Input } from "reactstrap";
import { WizardHolidayContext } from "../../../context/WizardHolidayContext";
import { IMLocalized } from "../../../language/IMLocalized";


export default function ModalEditHolidayList({toggle,closeModal,index}){
    const { list, setList } = useContext(WizardHolidayContext);
    const [ name, setName] = useState('');
    const [ title, setTitle] = useState('');
    const [ err_name, setErrName] = useState('');

    useEffect(()=>{
        if(toggle){
            const detail = list[index];
            setName(detail?.name ?? '');
            setTitle(detail?.name ?? '');
            setErrName('');
        }
    },[toggle,list,index])

    const updateList=()=>{
        if(name !== ''){
            const data = [...list];
            let count = 0;
            for(let i=0; i<list.length; i++){
                const n1 = list[i].name;
                if(i !== index && n1 === name){
                    count++;
                }
            }
    
            if(count === 0){
                data[index].name = name;
                setList(data);
                closeModal();
            }
            else{
                setErrName(IMLocalized('duplicate_name'));
            }
        }
        else{
            setErrName(IMLocalized('name_is_required'));
        }
    }

    return(
        <Modal isOpen={toggle} >
            <ModalHeader toggle={closeModal}>{IMLocalized('edit_holiday_list')} {title !== '' && `- ${title} `}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="name">{IMLocalized('name')}</label>
                            <Input size="sm" id="name" type="text" value={name} invalid={err_name.length >0} onChange={(e)=>{ setName(e.target.value); setErrName('')}} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary btn-sm" onClick={updateList}>{IMLocalized('update')}</button>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}