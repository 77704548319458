import * as userapplyclaim from '../../action/user/claim_detail_user_apply_claim';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const claim_detail_user_apply_claim = (state=initialState,action)=>{
    switch(action.type){
        case userapplyclaim.CLAIMDETAILUSERAPPLYCLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case userapplyclaim.CLAIMDETAILUSERAPPLYCLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case userapplyclaim.CLAIMDETAILUSERAPPLYCLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default claim_detail_user_apply_claim;