import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalCreateFamily from './modal/ModalCreateFamily';
import ModalEditFamily from './modal/ModalEditFamily';
import ModalViewChild from './modal/ModalViewChild';
import ModalViewFamily from './modal/ModalViewFamily';
import ModalCreateChildren from './modal/ModalCreateChildren';
import ModalCreateMaternity from './modal/ModalCreateMaternity';
import {delete_employee_relationship, reset_delete_employee_relationship} from '../../../action/employee/delete_employee_relationship';
import {get_employee_relationship} from '../../../action/employee/get_employee_relationship';
import {delete_sg_child, reset_delete_sg_child} from '../../../action/employee/delete_sg_child';
import {get_employee_sg_child} from '../../../action/employee/get_employee_sg_child';
import {get_employee_maternity_list} from '../../../action/employee/get_employee_maternity_list';
import {get_all_employee_available_leave} from '../../../action/employee/get_all_employee_available_leave';
import { emp_get_employee_relationship } from '../../../reducers/employee/get_employee_relationship';
import { get_employee_list_detail } from '../../../reducers/employee/get_employee_list';
import { emp_get_employee_sg_child } from '../../../reducers/employee/get_employee_sg_child';
import { emp_get_employee_maternity_list } from '../../../reducers/employee/get_employee_maternity_list';
import MaternityTable from './component/MaternityTable';
import NoDataTable from './component/NoDataTable';
import LoadingTable from './component/LoadingTable';
import FamilyTable from './component/FamilyTable';
import ChildTable from './component/ChildTable';

export default function EmployeeFamily({ id }){

    const delete_child_success = useSelector(state=>state.delete_sg_child.data);
    const delete_contact_success = useSelector(state=>state.delete_employee_relationship.data);
    const isLoading = useSelector(state=>state.get_employee_relationship.isLoading);
    const isLoading1 = useSelector(state=>state.get_employee_sg_child.isLoading);
    const isLoading2 = useSelector(state=>state.get_employee_maternity_list.isLoading);
    const dispatch = useDispatch();
    
    const [view_contact_toggle,setViewContactToggle] = useState(false); 
    const [view_detail,setViewDetail] = useState(null);
    const [edit_family_detail,setEditFamilyDetail] = useState(null);
    const [edit_toggle,setEditToggle] = useState(false);
    const [new_toggle,setNewToggle] = useState(false);
    const [children_toggle,setChildrenToggle] = useState(false);
    const [children_id,setChildrenID] = useState(null);
    const [view_child_toggle,setViewChildToggle] = useState(false);
    const [view_child_detail,setViewChildDetail] = useState(null);
    const [maternity_toggle,setMaternityToggle] = useState(false);

    const list = useSelector(state=>emp_get_employee_relationship(state,parseInt(id)));
    const empdata = useSelector(state=>get_employee_list_detail(state,parseInt(id)));
    const childlist = useSelector(state=>emp_get_employee_sg_child(state,parseInt(id)));
    const maternity_list = useSelector(state=>emp_get_employee_maternity_list(state,parseInt(id)));

    useEffect(()=>{
        if(delete_contact_success !== null){
            dispatch(get_employee_relationship());
            dispatch(reset_delete_employee_relationship());
        }
    },[delete_contact_success, dispatch])

    useEffect(()=>{
        if(delete_child_success !== null){
            dispatch(reset_delete_sg_child());
            dispatch(get_employee_sg_child());
        }
    },[delete_child_success,dispatch])

    const successCreateContact=()=>{
        setNewToggle(false);
        setEditToggle(false);
        dispatch(get_employee_relationship());
    }

    const successCreateChildren=()=>{
        setChildrenToggle(false);
        dispatch(get_employee_sg_child());
        dispatch(get_all_employee_available_leave());
    }

    const setViewToggle=(index)=>{
        const toggle = !view_contact_toggle;
        if(toggle === true){
            const data = list[index];
            setViewDetail(data);
        }
        else{
            setViewDetail(null);
        }
        setViewContactToggle(toggle);
    }

    const setEditContactToggle=(index)=>{
        const toggle = !edit_toggle;
        if(toggle){
            const data = list[index];
            setEditFamilyDetail(data);
        }
        else{
            setEditFamilyDetail(null);
        }
        setEditToggle(toggle);
    }

    const deleteFamily = (id)=>{
        const data = list.find((item) =>item.id === id);
        if(data){
            Swal.fire({
                title:`${IMLocalized('delete_family_confirmation')} (${data.name})?`,
                text:IMLocalized('revert_msg'),
                type:'warning',
                showCancelButton:true,
                cancelButtonColor:'#3085d6',
                confirmButtonColor:'#d33',
                confirmButtonText:IMLocalized('yes!'),
                cancelButtonText:IMLocalized('no!'),
            })
            .then((result)=>{
                if(result.value){
                    dispatch(delete_employee_relationship([{id}]));
                }
            })
        }
    }

    const createChildrenToggle = (id = null)=>{
        const toggle = !children_toggle;
        setChildrenToggle(toggle);
        setChildrenID(id);
    }

    const setViewChildren=(index = null)=>{
        const toggle = !view_child_toggle;
        if(toggle === true){
            if(index !== null){
                const data = childlist[index];
                setViewChildDetail(data);
            }
            
        }
        else{
            setViewChildDetail(null);
        }
        setViewChildToggle(toggle);
    }

    const deleteChild = (id)=>{
        const data = childlist.find((item)=>item.id === id);
        Swal.fire({
            title:`${IMLocalized('delete_children_confirmation')} (${data.name})?`,
            text:IMLocalized('revert_msg'),
            type:'warning',
            showCancelButton:true,
            cancelButtonColor:'#3085d6',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_sg_child(id));
            }
        })
    }

    const gender = empdata?.gender ?? null;

    return(
        <>
            <div className='d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title'>
                <div className='d-flex align-items-center'>
                    <i className="far fa-address-book text-primary" />
                    <h4 className="text-primary m-0 pl-2">{IMLocalized('employee_family_other_contact')}</h4>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='text-primary cursor-pointer pr-2' onClick={()=>setNewToggle(true)}>
                        <i className="fas fa-plus-circle mr-1 function_icon" />
                        <small>{IMLocalized('add_family_other_contact')}</small>
                    </div>
                </div>
            </div>   
            {isLoading && <LoadingTable/>}
            {!isLoading && list.length === 0 && <NoDataTable/>}
            {!isLoading && list.length !== 0 && <FamilyTable list={list} setViewToggle={setViewToggle} setEditContactToggle={setEditContactToggle} deleteFamily={deleteFamily} />}
            <div className="d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title">
                <div className="d-flex align-items-center">
                    <i className="fas fa-child text-primary"></i>
                    <h4 className="text-primary m-0 pl-2">{IMLocalized('employee_children')}</h4>
                </div>
                <div className="text-primary pt-2 cursor-pointer pr-2" onClick={()=>createChildrenToggle('no')}>
                    <i className="fas fa-plus-circle mr-1 function_icon"/>
                    <small>{IMLocalized('add_children')}</small>
                </div>
            </div>
            {isLoading1 && <LoadingTable/>}
            {!isLoading1 && childlist.length === 0 && <NoDataTable/>}
            {!isLoading1 && childlist.length !== 0 && <ChildTable list={childlist} deleteChild={deleteChild} setViewChildren={setViewChildren}  />}
            {gender === 'FEMALE' &&
            <div className="d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title">
                <div className="d-flex align-items-center">
                    <i className="far fa-address-book text-primary"/>
                    <h4 className="text-primary m-0 pl-2">{IMLocalized('employee_maternity_leave')}</h4>
                </div>
                <div className="text-primary pt-2 cursor-pointer pr-2" onClick={()=>setMaternityToggle(true)}>
                    <i className="fas fa-plus-circle mr-1 function_icon"/>
                    <small>{IMLocalized('add_maternity')}</small>
                </div>
            </div>}
            {!isLoading2 && gender === 'FEMALE' && maternity_list.length !== 0 && <MaternityTable list={maternity_list} createChildrenToggle={createChildrenToggle} />}
            {!isLoading2 && gender === 'FEMALE' && maternity_list.length === 0 && <NoDataTable/>}
            {isLoading2 && gender === 'FEMALE' && <LoadingTable/>}
            <ModalViewFamily toggle={view_contact_toggle} closeModal={()=>setViewToggle()} detail={view_detail} />
            <ModalCreateFamily toggle={new_toggle} closeModal={()=>setNewToggle(false)} id={id} onSuccess={()=>successCreateContact()} />
            <ModalEditFamily toggle={edit_toggle} closeModal={()=>setEditContactToggle()} detail={edit_family_detail} onSuccess={()=>successCreateContact()} />
            <ModalViewChild toggle={view_child_toggle} closeModal={()=>setViewChildren()} detail={view_child_detail}  />
            <ModalCreateChildren toggle={children_toggle} closeModal={()=>setChildrenToggle(false)} gender={gender} id={id} children_id={children_id} onSuccess={()=>successCreateChildren()} />
            <ModalCreateMaternity toggle={maternity_toggle} closeModal={()=>setMaternityToggle(false)} id={id} onSuccess={()=>{setMaternityToggle(false); dispatch(get_employee_maternity_list())}} />
        </>
    )
}