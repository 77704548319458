import * as general from '../action/general_group';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}

const general_group = (state=initialState,action)=>{
    switch(action.type){
        case general.GENERALGROUP_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[],
                data:[],
            }
        case general.GENERALGROUP_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case general.GENERALGROUP_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default general_group;