import * as emprolelist from '../../action/employee/get_employee_role_list';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}

const get_employee_role_list = (state=initialState,action)=>{
    switch(action.type){
        case emprolelist.GETEMPROLELIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[],
                errors:[],
            }
        case emprolelist.GETEMPROLELIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case emprolelist.GETEMPROLELIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_role_list;