import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const PAYROLLUPDATETIME_REQUEST = 'PAYROLLUPDATETIME_REQUEST';
export const PAYROLLUPDATETIME_SUCCESS = 'PAYROLLUPDATETIME_SUCCESS';
export const PAYROLLUPDATETIME_FAILURE = 'PAYROLLUPDATETIME_FAILURE';



export const update_payroll_update_time = (id,data)=>{
    return dispatch=>{
        dispatch({
            type:'PAYROLLUPDATETIME_REQUEST'
        });

        return myaxios.post(`payroll/payroll/${id}/AddUpdateTime/`,{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'PAYROLLUPDATETIME_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'PAYROLLUPDATETIME_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}