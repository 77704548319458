import myaxios from '../../axios';

export const GETEMPLOYEEPROFILEPICTURE_REQUEST = 'GETEMPLOYEEPROFILEPICTURE_REQUEST';
export const GETEMPLOYEEPROFILEPICTURE_SUCCESS = 'GETEMPLOYEEPROFILEPICTURE_SUCCESS';
export const GETEMPLOYEEPROFILEPICTURE_FAILURE = 'GETEMPLOYEEPROFILEPICTURE_FAILURE';
export const GETEMPLOYEEPROFILEPICTURE_RESET = 'GETEMPLOYEEPROFILEPICTURE_RESET';

export const get_employee_profile_picture = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETEMPLOYEEPROFILEPICTURE_REQUEST'
        });

        myaxios.get(`ob/employee/${id}/thumbnail/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'GETEMPLOYEEPROFILEPICTURE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPLOYEEPROFILEPICTURE_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_get_employee_profile_picture = () => dispatch =>{
    dispatch({
        type:'GETEMPLOYEEPROFILEPICTURE_RESET'
    });
}