import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELEMPDELEGATIONLIST_REQUEST = 'DELEMPDELEGATIONLIST_REQUEST';
export const DELEMPDELEGATIONLIST_SUCCESS = 'DELEMPDELEGATIONLIST_SUCCESS';
export const DELEMPDELEGATIONLIST_FAILURE = 'DELEMPDELEGATIONLIST_FAILURE';


export const delete_employee_delegation_list = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELEMPDELEGATIONLIST_REQUEST'
        });

        myaxios.delete(`employee/delegation/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELEMPDELEGATIONLIST_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELEMPDELEGATIONLIST_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('delete_failure')
            });
        })
    }
}