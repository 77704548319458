import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATEAPPRAISALLIST_REQUEST = 'CREATEAPPRAISALLIST_REQUEST';
export const CREATEAPPRAISALLIST_SUCCESS = 'CREATEAPPRAISALLIST_SUCCESS';
export const CREATEAPPRAISALLIST_FAILURE = 'CREATEAPPRAISALLIST_FAILURE';
export const CREATEAPPRAISALLIST_RESET = 'CREATEAPPRAISALLIST_RESET';

export const create_appraisal_list = (name,type,questions)=>{
    return dispatch=>{
        dispatch({
            type:'CREATEAPPRAISALLIST_REQUEST'
        });

        myaxios.post('forms/forms/',{
            name:name,
            type:type,
            questions:questions
        })
        .then((response)=>{
            dispatch({
                type:'CREATEAPPRAISALLIST_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATEAPPRAISALLIST_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}

export const create_appraisal_list_reset = () => dispatch =>{
    dispatch({
        type:'CREATEAPPRAISALLIST_RESET'
    })
}