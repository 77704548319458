import * as pendingcolor from '../../action/user/get_leave_pending_color';

const initialState={
    data:[],
    errors:[],
    isLoading:false
}

const get_leave_pending_color = (state=initialState,action )=>{
    switch(action.type){
        case pendingcolor.LEAVEPENDINGCOLOR_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case pendingcolor.LEAVEPENDINGCOLOR_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case pendingcolor.LEAVEPENDINGCOLOR_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_leave_pending_color;