import * as cpftest from '../../action/test/submit_cpf_test';

const initialState={
    data:[],
    errors:[],
    isLoading:false
}


const submit_cpf_test=(state=initialState,action)=>{
    switch(action.type){
        case cpftest.SUBMITCPFTEST_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case cpftest.SUBMITCPFTEST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case cpftest.SUBMITCPFTEST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default submit_cpf_test;