import * as create_appraisal_form from '../../action/appraisal/create_appraisal_list';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const create_appraisal_list = (state=initialState,action)=>{
    switch(action.type){
        case create_appraisal_form.CREATEAPPRAISALLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case create_appraisal_form.CREATEAPPRAISALLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case create_appraisal_form.CREATEAPPRAISALLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case create_appraisal_form.CREATEAPPRAISALLIST_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default create_appraisal_list;