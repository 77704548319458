import React,{Component} from 'react';
import {connect} from 'react-redux';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import moment from 'moment';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, Table, ModalFooter } from 'reactstrap';
import {contract_contract_array_validate} from '../../action/contract/contract_contract_array_validate';
import {contract_contract_array_save} from '../../action/contract/contract_contract_array_save';
import {get_employee_list} from '../../action/employee/get_employee_list';
import Swal from 'sweetalert2';
import {getScheduleList} from '../../action/schedule_list';
import {get_contract_template_dropdown} from '../../action/get_contract_template_dropdown';
import {general_currency} from '../../action/general_currency';
import {get_additional_setting} from '../../action/additional_setting';
import {contract_type_list,contract_rate_list} from '../../data/contract_data';
import { IMLocalized } from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';
import 'ag-grid-enterprise';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

const gridOptions={

}

class wizard_create_emp_contract extends Component{

    constructor(props){
        super(props);
        this.state={
            list:[],
            language:localStorage.getItem('language'),
            error_msg:[],
            rowData:[],
            rowData2:[],
            import_lang:'',
            errorlist:[],
            columnsDefs:[],
        }
    }

    componentDidMount(){
        const {list} = this.props;
        this.setState({list});
        this.props.getScheduleList();
        this.props.get_contract_template_dropdown();
        this.props.general_currency();
        this.props.get_additional_setting();
        
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.list !== this.props.list || prevProps.cas !== this.props.cas){
            this.getDetail();
        }
        if(prevProps.validate_success !== this.props.validate_success && this.props.validate_success != null){
            this.setState({error_msg:[]});
            this.createContract();
        }
        if(prevProps.validate_errors !== this.props.validate_errors){
            const errors = this.props.validate_errors;
            const {validatelist,rowData} = this.state;
            if(errors !== undefined){
                if(errors.length !== 0){
                    let arr = [];
                    for(let i=0;i<errors.length;i++){
                        let arr1 = [];
                        const object1 = errors[i];
                        const name = validatelist[i].name;
                        const number = validatelist[i].employee_number;
                        if(object1 !== true){
                            if('non_field_errors' in object1){
                                const word = object1['non_field_errors'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_non = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                            if('pay' in object1){
                                const word = `${IMLocalized('basic_pay')} : ` +object1['pay'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_pay = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                            if('hourly_rate' in object1){
                                
                                const word = `${IMLocalized('hourly_rate')} : ` +object1['hourly_rate'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_hourly_rate = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                            if('start_date' in object1){
                                
                                const word = `${IMLocalized('join_date')} : ` +object1['start_date'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                
                            }
                            if('wschedule' in object1){
                                
                                const word = `${IMLocalized('schedule')} : ` +object1['wschedule'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_wschedule = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                            if('daily_rate' in object1){
                                
                                const word = `${IMLocalized('daily_rate')} : ` +object1['daily_rate'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_daily_rate = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                            if('contract_rate' in object1){
                               
                                const word = `${IMLocalized('contract_rate')} : ` +object1['contract_rate'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_contract_rate = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                            if('contract_type' in object1){
                                
                                const word = `${IMLocalized('contract_type')} : ` +object1['contract_type'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_contract_type = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                            if('hours_day' in object1){
                                
                                const word = `${IMLocalized('hours_per_day')} : ` +object1['hours_day'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_hours_day = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                            if('currency' in object1){
                                
                                const word = `${IMLocalized('currency')} : ` +object1['currency'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_currency = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                            if('department' in object1){
                                
                                const word = `${IMLocalized('department')} : ` +object1['department'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_department = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                            if('section' in object1){
                                
                                const word = `${IMLocalized('section')} : ` +object1['section'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_section = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                            if('job_level' in object1){
                                
                                const word = `${IMLocalized('job_level')} : ` +object1['job_level'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_job_level = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                            if('job_title' in object1){
                                
                                const word = `${IMLocalized('job_title')} : ` +object1['job_title'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_job_title = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                            if('cost_center' in object1){
                                
                                const word = `${IMLocalized('cost_center')} : ` +object1['cost_center'].toString();
                                arr1.push(<div><span>● {word}</span></div>)
                                const new_rowData = rowData.map((item)=>{
                                    const employee_number = item.employee_number;
                                    if(number === employee_number){
                                        item.err_cost_center = true;
                                    }
                                    return item
                                })
                                this.setState({rowData:new_rowData});
                                gridOptions.api.setRowData(new_rowData)
                            }
                        }

                        arr.push({employee_number:number,name,err1:arr1});
                    }

                    const table = arr.map((item)=>{
                        return(
                            <tr>
                                <td style={{verticalAlign:'top'}}><span>{item.employee_number}</span></td>
                                <td style={{verticalAlign:'top'}}>{item.name}</td>
                                <td>{item.err1}</td>
                            </tr>
                        )
                    })
                    this.setState({error_msg:table});
                    this.setState({err_toggle:true});
                }
            }
        }
        if(prevProps.create_success !== this.props.create_success ){
            this.props.onNext()
        }
        if(prevState.errorlist !== this.state.errorlist){
            if(this.state.errorlist.length !== 0){
                this.setError();
            }
            
        }
    }

    setError=()=>{
        const {rowData,errorlist} = this.state;
        for(let i =0;i<errorlist.length;i++){
            const item = errorlist[i];
            const employee_number = item.employee_number;
            const err_pay = item.err_pay;
            const err_hourly_rate = item.err_hourly_rate;
            const err_wschedule = item.err_wschedule;
            const err_daily_rate = item.err_daily_rate;
            const err_contract_rate = item.err_contract_rate;
            const err_contract_type = item.err_contract_type;
            const err_hours_day = item.err_hours_day;
            const err_currency = item.err_currency;
            const err_department = item.err_department;
            const err_section = item.err_section;
            const err_job_title = item.err_job_title;
            const err_job_level = item.err_job_level;
            const err_cost_center = item.err_cost_center;

            const new_rowData = rowData.map((item1)=>{
                const number = item1.employee_number;
                if(number === employee_number){
                    item1.err_pay = err_pay;
                    item1.err_hourly_rate = err_hourly_rate;
                    item1.err_wschedule = err_wschedule;
                    item1.err_daily_rate = err_daily_rate;
                    item1.err_contract_rate = err_contract_rate;
                    item1.err_contract_type = err_contract_type;
                    item1.err_hours_day = err_hours_day;
                    item1.err_currency = err_currency;
                    item1.err_department = err_department;
                    item1.err_section = err_section;
                    item1.err_job_title = err_job_title;
                    item1.err_job_level = err_job_level;
                    item1.err_cost_center = err_cost_center;
                }
                return item1
            })
            this.setState({rowData:new_rowData})
            gridOptions.api.setRowData(new_rowData)
        }
    }

    createContract=()=>{
        const {validatelist} = this.state;
        this.props.contract_contract_array_save(validatelist)
    }

    onCellValueChanged=(params)=>{
        const {schedulelist} = this.props;
        const {import_lang,language} = this.state;
        if(params.oldValue !== params.newValue){
            const column = params.column.colDef.field;
            if(import_lang !== ''){
                if(import_lang === 'zh'){
                    const contract_rate = params.data.contract_rate;
                    if(column === 'contract_rate'){
                        if(contract_rate !== '' && contract_rate !== null){
                            let rate_name = '';
                            const rate_detail = contract_rate_list.filter(function(item){
                                return item.display_zh === contract_rate
                            })
        
                            rate_name = rate_detail[0].value;
                            if(rate_name === 'DAILY'){
                                params.data.pay = 0;
                                params.data.hourly_rate = 0;
                                params.data.daily_rate ='';
                                params.data.hours_day='';
                            }
                            else if(rate_name === 'HOURLY'){
                                params.data.pay = 0;
                                params.data.daily_rate = 0;
                                params.data.hourly_rate ='';
                                params.data.hours_day=0;
                            }
                            else{
                                params.data.hours_day=0;
                                params.data.pay ='';
                                params.data.hourly_rate = '';
                                params.data.daily_rate = '';
                            }
                        }
                    }
                    else if(contract_rate !== '' && contract_rate !== null){
                        let rate_name = '';
                        const rate_detail = contract_rate_list.filter(function(item){
                            return item.display_zh === contract_rate
                        })
    
                        rate_name = rate_detail[0].value;
                        if(rate_name === 'MONTHLY'){
                            if(column === 'wschedule' || column === 'pay'){
                                const pay = params.data.pay;
                                let total_pay = 0;
                                if(pay !=='' && pay!== null){
                                    total_pay = parseFloat(pay);
                                }
                                let work_days_in_week = 0;
                                const wschedule = params.data.wschedule;
                                if(wschedule !== '' && wschedule !== null){
                                    const schedule_detail = schedulelist.filter(function(item){
                                        return item.name === wschedule;
                                    })
                                    if(schedule_detail.length !== 0){
                                        work_days_in_week = schedule_detail[0].work_days_in_week;
                                    }
                                }
                                let total = 0;
                                if(total_pay !== 0 && work_days_in_week !== 0){
                                    const final  = (total_pay * 12) / (52*work_days_in_week);
                                    total  = parseFloat(final.toFixed(4)) 
                                }
                                params.data.daily_rate = total; 
                            }
    
                            if(column === 'pay' || column === 'is_workman'){
                                const pay = params.data.pay;
                                let total_pay = 0;
                                if(pay !== '' && pay !== null){
                                    total_pay = parseFloat(pay);
                                }
                                const is_workman = params.data.is_workman;
                                let hourly_rate = 0;
                                if(total_pay !== 0){
                                    hourly_rate = (pay*12)/(52*44);
                                    if(is_workman === 'true'){
                                        if(total_pay>4500){
                                            hourly_rate = (4500*12)/(52*44);
                                        }
                                    }
                                    else{
                                        if(pay >=2600){
                                            hourly_rate = 13.60;
                                        }
                                    }
                                }
                                params.data.hourly_rate = parseFloat(hourly_rate.toFixed(8));
                            }
                        }
                        else if(rate_name === 'HOURLY'){
                            if(column === 'pay'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('contract_hourly_rate_salary_not_included'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
        
                                params.data.pay = 0;
                            }
                            else if(column === 'daily_rate'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('please_fill_hourly_rate_column'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
        
                                params.data.daily_rate = 0;
                            }
                        }
                        else if(rate_name === 'DAILY'){
                            if(column === 'pay'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('contract_daily_rate_salary_not_included'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
                                params.data.pay = 0;
                            }
                            else if(column === 'hourly_rate'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('please_fill_daily_rate_column'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
        
                                params.data.daily_rate = 0;
                            }
                            
                        }
                        
                    }
                }
                else{
                    const contract_rate = params.data.contract_rate;
                    if(column === 'contract_rate'){
                        if(contract_rate !== '' && contract_rate !== null){
                            let rate_name = '';
                            const rate_detail = contract_rate_list.filter(function(item){
                                return item.display === contract_rate
                            })
        
                            rate_name = rate_detail[0].value;
                            if(rate_name === 'DAILY'){
                                params.data.pay = 0;
                                params.data.hourly_rate = 0;
                                params.data.daily_rate ='';
                                params.data.hours_day='';
                            }
                            else if(rate_name === 'HOURLY'){
                                params.data.pay = 0;
                                params.data.daily_rate = 0;
                                params.data.hourly_rate ='';
                                params.data.hours_day=0;
                            }
                            else{
                                params.data.hours_day=0;
                                params.data.pay ='';
                                params.data.hourly_rate = '';
                                params.data.daily_rate = '';
                            }
                        }
                    }
                    else if(contract_rate !== '' && contract_rate !== null){
                        let rate_name = '';
                        const rate_detail = contract_rate_list.filter(function(item){
                            return item.display === contract_rate
                        })
    
                        rate_name = rate_detail[0].value;
                        if(rate_name === 'MONTHLY'){
                            if(column === 'wschedule' || column === 'pay'){
                                const pay = params.data.pay;
                                let total_pay = 0;
                                if(pay !=='' && pay!== null){
                                    total_pay = parseFloat(pay);
                                }
                                let work_days_in_week = 0;
                                const wschedule = params.data.wschedule;
                                if(wschedule !== '' && wschedule !== null){
                                    const schedule_detail = schedulelist.filter(function(item){
                                        return item.name === wschedule;
                                    })
                                    if(schedule_detail.length !== 0){
                                        work_days_in_week = schedule_detail[0].work_days_in_week;
                                    }
                                }
                                let total = 0;
                                if(total_pay !== 0 && work_days_in_week !== 0){
                                    const final  = (total_pay * 12) / (52*work_days_in_week);
                                    total  = parseFloat(final.toFixed(4)) 
                                }
                                params.data.daily_rate = total; 
                            }
    
                            if(column === 'pay' || column === 'is_workman'){
                                const pay = params.data.pay;
                                let total_pay = 0;
                                if(pay !== '' && pay !== null){
                                    total_pay = parseFloat(pay);
                                }
                                const is_workman = params.data.is_workman;
                                let hourly_rate = 0;
                                if(total_pay !== 0){
                                    hourly_rate = (pay*12)/(52*44);
                                    if(is_workman === 'true'){
                                        if(total_pay>4500){
                                            hourly_rate = (4500*12)/(52*44);
                                        }
                                    }
                                    else{
                                        if(pay >=2600){
                                            hourly_rate = 13.60;
                                        }
                                    }
                                }
                                params.data.hourly_rate = parseFloat(hourly_rate.toFixed(8));
                            }
                        }
                        else if(rate_name === 'HOURLY'){
                            if(column === 'pay'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('contract_hourly_rate_salary_not_included'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
        
                                params.data.pay = 0;
                            }
                            else if(column === 'daily_rate'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('please_fill_hourly_rate_column'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
        
                                params.data.daily_rate = 0;
                            }
                        }
                        else if(rate_name === 'DAILY'){
                            if(column === 'pay'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('contract_daily_rate_salary_not_included'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
                                params.data.pay = 0;
                            }
                            else if(column === 'hourly_rate'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('please_fill_daily_rate_column'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
        
                                params.data.daily_rate = 0;
                            }
                            
                        }
                        
                    }
                }
            }
            else{
                if(language === 'zh'){
                    
                    const contract_rate = params.data.contract_rate;
                    if(column === 'contract_rate'){
                        if(contract_rate !== '' && contract_rate !== null){
                            let rate_name = '';
                            const rate_detail = contract_rate_list.filter(function(item){
                                return item.display_zh === contract_rate
                            })
        
                            rate_name = rate_detail[0].value;
                            if(rate_name === 'DAILY'){
                                params.data.pay = 0;
                                params.data.hourly_rate = 0;
                                params.data.daily_rate ='';
                                params.data.hours_day= '';
                            }
                            else if(rate_name === 'HOURLY'){
                                params.data.pay = 0;
                                params.data.daily_rate = 0;
                                params.data.hourly_rate ='';
                                params.data.hours_day = 0;

                            }
                            else{
                                params.data.hours_day = 0;
                                params.data.pay ='';
                                params.data.hourly_rate = '';
                                params.data.daily_rate = '';
                            }
                            
                        }
                    }
                    else if(contract_rate !== '' && contract_rate !== null){
                        let rate_name = '';
                        const rate_detail = contract_rate_list.filter(function(item){
                            return item.display_zh === contract_rate
                        })
    
                        rate_name = rate_detail[0].value;
                        if(rate_name === 'MONTHLY'){
                            if(column === 'wschedule' || column === 'pay'){
                                const pay = params.data.pay;
                                let total_pay = 0;
                                if(pay !=='' && pay!== null){
                                    total_pay = parseFloat(pay);
                                }
                                let work_days_in_week = 0;
                                const wschedule = params.data.wschedule;
                                if(wschedule !== '' && wschedule !== null){
                                    const schedule_detail = schedulelist.filter(function(item){
                                        return item.name === wschedule;
                                    })
                                    if(schedule_detail.length !== 0){
                                        work_days_in_week = schedule_detail[0].work_days_in_week;
                                    }
                                }
                                let total = 0;
                                if(total_pay !== 0 && work_days_in_week !== 0){
                                    const final  = (total_pay * 12) / (52*work_days_in_week);
                                    total  = parseFloat(final.toFixed(4)) 
                                }
                                params.data.daily_rate = total; 
                            }
    
                            if(column === 'pay' || column === 'is_workman'){
                                const pay = params.data.pay;
                                let total_pay = 0;
                                if(pay !== '' && pay !== null){
                                    total_pay = parseFloat(pay);
                                }
                                const is_workman = params.data.is_workman;
                                let hourly_rate = 0;
                                if(total_pay !== 0){
                                    hourly_rate = (pay*12)/(52*44);
                                    if(is_workman === 'true'){
                                        if(total_pay>4500){
                                            hourly_rate = (4500*12)/(52*44);
                                        }
                                    }
                                    else{
                                        if(pay >=2600){
                                            hourly_rate = 13.60;
                                        }
                                    }
                                }
                                params.data.hourly_rate = parseFloat(hourly_rate.toFixed(8));
                            }
                        }
                        else if(rate_name === 'HOURLY'){
                            if(column === 'pay'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('contract_hourly_rate_salary_not_included'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
        
                                params.data.pay = 0;
                            }
                            else if(column === 'daily_rate'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('please_fill_hourly_rate_column'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
        
                                params.data.daily_rate = 0;
                            }
                        }
                        else if(rate_name === 'DAILY'){
                            if(column === 'pay'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('contract_daily_rate_salary_not_included'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
                                params.data.pay = 0;
                            }
                            else if(column === 'hourly_rate'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('please_fill_daily_rate_column'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
        
                                params.data.hourly_rate = 0;
                            }
                            
                        }
                        
                    }
                }
                else{
                    const contract_rate = params.data.contract_rate;
                    if(column === 'contract_rate'){
                        if(contract_rate !== '' && contract_rate !== null){
                            let rate_name = '';
                            const rate_detail = contract_rate_list.filter(function(item){
                                return item.display === contract_rate
                            })
        
                            rate_name = rate_detail[0].value;
                            if(rate_name === 'DAILY'){
                                params.data.pay = 0;
                                params.data.hourly_rate = 0;
                                params.data.daily_rate ='';
                                params.data.hours_day='';
                            }
                            else if(rate_name === 'HOURLY'){
                                params.data.pay = 0;
                                params.data.daily_rate = 0;
                                params.data.hourly_rate ='';
                                params.data.hours_day=0;

                            }
                            else{
                                params.data.hours_day=0;
                                params.data.pay ='';
                                params.data.hourly_rate = '';
                                params.data.daily_rate = '';
                            }
                        }
                    }
                    else if(contract_rate !== '' && contract_rate !== null){
                        let rate_name = '';
                        const rate_detail = contract_rate_list.filter(function(item){
                            return item.display === contract_rate
                        })
    
                        rate_name = rate_detail[0].value;
                        if(rate_name === 'MONTHLY'){
                            if(column === 'wschedule' || column === 'pay'){
                                const pay = params.data.pay;
                                let total_pay = 0;
                                if(pay !=='' && pay!== null){
                                    total_pay = parseFloat(pay);
                                }
                                let work_days_in_week = 0;
                                const wschedule = params.data.wschedule;
                                if(wschedule !== '' && wschedule !== null){
                                    const schedule_detail = schedulelist.filter(function(item){
                                        return item.name === wschedule;
                                    })
                                    if(schedule_detail.length !== 0){
                                        work_days_in_week = schedule_detail[0].work_days_in_week;
                                    }
                                }
                                let total = 0;
                                if(total_pay !== 0 && work_days_in_week !== 0){
                                    const final  = (total_pay * 12) / (52*work_days_in_week);
                                    total  = parseFloat(final.toFixed(4)) 
                                }
                                params.data.daily_rate = total; 
                            }
    
                            if(column === 'pay' || column === 'is_workman'){
                                const pay = params.data.pay;
                                let total_pay = 0;
                                if(pay !== '' && pay !== null){
                                    total_pay = parseFloat(pay);
                                }
                                const is_workman = params.data.is_workman;
                                let hourly_rate = 0;
                                if(total_pay !== 0){
                                    hourly_rate = (pay*12)/(52*44);
                                    if(is_workman === 'true'){
                                        if(total_pay>4500){
                                            hourly_rate = (4500*12)/(52*44);
                                        }
                                    }
                                    else{
                                        if(pay >=2600){
                                            hourly_rate = 13.60;
                                        }
                                    }
                                }
                                params.data.hourly_rate = parseFloat(hourly_rate.toFixed(8));
                            }
                        }
                        else if(rate_name === 'HOURLY'){
                            if(column === 'pay'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('contract_hourly_rate_salary_not_included'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
        
                                params.data.pay = 0;
                            }
                            else if(column === 'daily_rate'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('please_fill_hourly_rate_column'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
        
                                params.data.daily_rate = 0;
                            }
                        }
                        else if(rate_name === 'DAILY'){
                            if(column === 'pay'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('contract_daily_rate_salary_not_included'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
                                params.data.pay = 0;
                            }
                            else if(column === 'hourly_rate'){
                                Swal.fire({
                                    type:'warning',
                                    title:IMLocalized('please_fill_daily_rate_column'),
                                    confirmButtonText:IMLocalized('ok!')
                                })
        
                                params.data.hourly_rate = 0;
                            }
                            
                        }
                        
                    }
                }
            }

            if(column === 'is_select'){
                
                params.data.err_contract_rate = false;
                params.data.err_contract_type = false;
                params.data.err_pay = false;
                params.data.err_hourly_rate = false;
                params.data.err_wschedule= false;
                params.data.err_daily_rate = false;
                params.data.err_hours_day = false;
                params.data.err_currency = false;
                params.data.err_department = false;
                params.data.err_section = false;
                params.data.err_job_title = false;
                params.data.err_job_level = false;
                params.data.err_cost_center = false;
            }
            else if(column === 'contract_type'){
                params.data.err_contract_type = false;
            }
            else if(column === 'contract_rate'){
                params.data.err_contract_rate = false;
                params.data.err_hourly_rate = false;
                params.data.err_daily_rate = false;
                params.data.err_pay =false;
                params.data.err_hours_day = false;
            }
            else if(column === 'pay'){
                if(params.data.contract_rate === 'Monthly' || params.data.contract_rate === '月薪'){
                    params.data.err_hourly_rate = false;
                    params.data.err_daily_rate = false;
                }
                else if(params.data.contract_rate === 'Daily' || params.data.contract_rate === '日薪'){
                    params.data.err_daily_rate = false;

                }
                else if(params.data.contract_rate === 'Hourly' || params.data.contract_rate === '时薪'){
                    params.data.err_hourly_rate = false;
                }
                params.data.err_pay = false;
            }
            else if(column === 'hourly_rate'){
                params.data.err_hourly_rate = false;
            }
            else if(column === 'wschedule'){
                params.data.err_wschedule = false;
            }
            else if(column === 'daily_rate'){
                params.data.err_daily_rate = false;
            }
            else if(column === 'hours_day'){
                params.data.err_hours_day = false;
            }
            else if(column === 'currency'){
                params.data.err_currency = false;
            }
            else if(column === 'department'){
                params.data.err_department = false;
            }
            else if(column === 'section'){
                params.data.err_section = false;
            }
            else if(column === 'job_title'){
                params.data.err_job_title = false;
            }
            else if(column === 'job_level'){
                params.data.err_job_level = false;
            }
            else if(column === 'cost_center'){
                params.data.err_cost_center = false;
            }
            params.data.err_non = false;
            
            params.api.redrawRows({
                force:true,
                columns:[column],
                rowNodes:[params.node]
            })
            this.setState({step:0});

        }
    }


    getDetail=()=>{
        const {language} = this.state;
        let arr = [];
        const {list,schedulelist,currencylist,departmentlist,sectionlist,joblevellist,jobtitlelist,costcenterlist,allowancelist,recurring_list} = this.props; 
        let schedule_option = [''];
        if(schedulelist.length !== 0){
            for(let i=0;i<schedulelist.length;i++){
                const name = schedulelist[i].name;
                schedule_option.push(name);
            }
        }

        let contract_type_option = [''];
        if(contract_type_list.length !== 0){
            for(let i =0;i<contract_type_list.length;i++){
                if(this.state.language === 'zh'){
                    const name = contract_type_list[i].display_zh;
                    contract_type_option.push(name);
                }
                else{
                    const name = contract_type_list[i].display;
                    contract_type_option.push(name);
                }
            }
        }

        let contract_rate_option = [''];
        if(contract_rate_list.length !== 0){
            for(let i=0;i<contract_rate_list.length;i++){
                if(this.state.language === 'zh'){
                    const name = contract_rate_list[i].display_zh;
                    contract_rate_option.push(name);
                }
                else{
                    const name = contract_rate_list[i].display;
                    contract_rate_option.push(name);
                }
            }
        }

        let currency_option = [''];
        if(currencylist.length !== 0){
            for(let i=0;i<currencylist.length;i++){
                const name = currencylist[i].currency_name;
                currency_option.push(name);
            }
        }

        let department_option = [''];
        if(departmentlist.length !== 0){
            for(let i=0;i<departmentlist.length;i++){
                const name = departmentlist[i].name;
                department_option.push(name);
            }
        }

        let section_option = [''];
        if(sectionlist.length !== 0){
            for(let i=0;i<sectionlist.length;i++){
                const name = sectionlist[i].name;
                section_option.push(name);
            }
        }

        let joblevel_option = [''];
        if(joblevellist.length !== 0){
            for(let i=0;i<joblevellist.length;i++){
                const name = joblevellist[i].name;
                joblevel_option.push(name);
            }
        }

        let jobtitle_option = [''];
        if(jobtitlelist.length !== 0){
            for(let i=0;i<jobtitlelist.length;i++){
                const name = jobtitlelist[i].name;
                jobtitle_option.push(name);
            }
        }

        let costcenter_option = [''];
        if(costcenterlist.length !== 0){
            for(let i=0;i<costcenterlist.length;i++){
                const name = costcenterlist[i].name;
                costcenter_option.push(name);
            }
        }

        if(list.length !== 0){
            for(let i=0;i<list.length; i++){
                let allowance_object ={};
                if(allowancelist.length !== 0){
                    for(let i=0;i<allowancelist.length;i++){
                        const name = allowancelist[i].name;
                        allowance_object[name] = null;
                    }
                }

                let recurring_object = {};
                if(recurring_list.length !== 0){
                    for(let i=0;i<recurring_list.length;i++){
                        const name = recurring_list[i].name;
                        recurring_object[name] = null;
                    }
                }

                const name = list[i].employee.name;
                const employee_number = list[i].employee.employee_number;
                const join_date = this.getDateFormat(list[i].employee.join_date);
                const is_workman = 'false';
                const is_select = 'false';
                const pay = null;
                const wschedule = null;
                const hourly_rate = null;
                const daily_rate = null;
                const contract_type = null;
                const contract_rate = null;
                const hours_day = null;
                const currency = null;
                const department = null;
                const section = null;
                const cost_center = null;
                const job_title = null;
                const job_level = null;
                const err_non = false;
                const err_pay = false;
                const err_hourly_rate = false;
                const err_wschedule= false;
                const err_daily_rate = false;
                const err_contract_rate = false;
                const err_contract_type = false;
                const err_hours_day = false;
                const err_currency = false;
                const err_department = false;
                const err_section = false;
                const err_job_title = false;
                const err_job_level = false;
                const err_cost_center = false;
                arr.push({err_non,err_pay,err_hourly_rate,err_wschedule,err_daily_rate,err_contract_rate,err_contract_type,err_hours_day,err_currency,err_department,err_section,err_job_level,err_job_title,err_cost_center,is_select,name,employee_number,join_date,pay,is_workman,wschedule,hourly_rate,daily_rate,contract_rate,contract_type,hours_day,currency,department,section,cost_center,job_title,job_level,allowance_object,recurring_object});
            }
        }
        this.setState({rowData:arr});
        this.setState({rowData2:arr});

        const columnsDefs=[
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee_number',
                editable:false,
                width:150
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'name',
                editable:false,
                width:200
            },
            {
                headerName:IMLocalized('agrid_join_date'),
                field:'join_date',
                editable:false,
                width:150
            },
            {
                headerName:IMLocalized('agrid_contract_type'),
                field:'contract_type',
                width:200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: contract_type_option,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_contract_type}
                },
            },
            {
                headerName:IMLocalized('agrid_contract_rate'),
                field:'contract_rate',
                width:200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: contract_rate_option,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_contract_rate}
                },
            },
            {
                headerName:IMLocalized('agrid_is_workman'),
                field:'is_workman',
                width:150,
                editable:false,
                cellRenderer:this.checkboxCellRenderer,
                
            },
            {
                headerName:IMLocalized('agrid_schedule'),
                field:'wschedule',
                width:200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: schedule_option,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_wschedule}
                },
            },
            {
                headerName:IMLocalized('agrid_basic_pay'),
                field:'pay',
                editable:true,
                width:200,
                filter: 'agNumberColumnFilter' ,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_pay}
                },
            },
            {
                headerName:IMLocalized('agrid_hourly_rate'),
                field:'hourly_rate',
                width:200,
                editable:true,
                filter: 'agNumberColumnFilter' ,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_hourly_rate}
                },
            },
            {
                headerName:IMLocalized('agrid_daily_rate'),
                field:'daily_rate',
                width:200,
                editable:true,
                filter: 'agNumberColumnFilter',
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_daily_rate}
                },
            },
            
            {
                headerName:IMLocalized('agrid_hours_per_day'),
                field:'hours_day',
                width:200,
                editable:true,
                filter: 'agNumberColumnFilter',
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_hours_day}
                },
            },
            {
                headerName:IMLocalized('agrid_currency'),
                field:'currency',
                width:200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: currency_option,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_currency}
                },
            },
            {
                headerName:IMLocalized('agrid_department'),
                field:'department',
                width:200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: department_option,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_department}
                },
            },
            {
                headerName:IMLocalized('agrid_section'),
                field:'section',
                width:200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: section_option,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_section}
                },
            },
            {
                headerName:IMLocalized('agrid_job_level'),
                field:'job_level',
                width:200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: joblevel_option,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_job_level}
                },
            },
            {
                headerName:IMLocalized('agrid_job_title'),
                field:'job_title',
                width:200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: jobtitle_option,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_job_title}
                },
            },
            {
                headerName:IMLocalized('agrid_cost_center'),
                field:'cost_center',
                width:200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: costcenter_option,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_cost_center}
                },
            },
        ]

        let option;
        if(language === 'zh'){
            option = ['','是','否'];
        }
        else{
            option = ['','Yes','No'];
        }

        for(let i =0;i<allowancelist.length;i++){
            const name = allowancelist[i].name;
            columnsDefs.push({headerName:name,editable:true,field:'allowance_object.'+name,width:200,cellEditor:'agSelectCellEditor',cellEditorParams:{values:option}});
        }

        for(let i =0;i<recurring_list.length;i++){
            const name = recurring_list[i].name;
            columnsDefs.push({headerName:name,editable:true,field:'recurring_object.'+name,width:200,cellEditor:'agSelectCellEditor',cellEditorParams:{values:option}});
        }

        this.setState({columnsDefs});
    }

    checkboxCellRenderer(params){
        if(params.value !== 'true' && params.value !== 'false'){
            params.setValue(params.value === true || params.value === 'true' ? 'true' : 'false');
        }
        else{
            var input = document.createElement("input");
            
            input.type = "checkbox";
            input.value = params.value === true || params.value === 'true' ? 'true' : 'false';
            input.checked = params.value === true || params.value === 'true' ? true : false;
            
            input.onclick = function(){
                params.setValue(this.checked === true ? 'true' : 'false');
            }
           
            return input;
        }
    }

    getTemplate=()=>{
        const {currencylist,departmentlist,sectionlist,jobtitlelist,joblevellist,costcenterlist,allowancelist,recurring_list,schedulelist} = this.props;
        const {language} = this.state;
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_contract',{
            views:[
                {state:'frozen',ySplit:1,xSplit:2}
            ]
        });
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');

        let rowDetail = [];
        this.gridApi.forEachNode(function(rowNode,index){
            const item = rowNode.data;
            rowDetail.push(item)
        })

        if(rowDetail.length !== 0){
            const columns = [
                {header:IMLocalized('agrid_emp_id'),key:'employee_number',type:'employee_number'},
                {header:IMLocalized('agrid_emp_name'),key:'name',type:'name'},
                {header:IMLocalized('agrid_join_date'),key:'join_date',type:'join_date'},
                {header:IMLocalized('agrid_contract_type'),key:'contract_type',type:'contract_type'},
                {header:IMLocalized('agrid_contract_rate'),key:'contract_rate',type:'contract_rate'},
                {header:IMLocalized('agrid_is_workman'),key:'is_workman',type:'is_workman'},
                {header:IMLocalized('agrid_schedule'),key:'wschedule',type:'wschedule'},
                {header:IMLocalized('agrid_basic_pay'),key:'pay',type:'pay'},
                {header:IMLocalized('agrid_hourly_rate'),key:'hourly_rate',type:'hourly_rate'},
                {header:IMLocalized('agrid_daily_rate'),key:'daily_rate',type:'daily_rate'},
                {header:IMLocalized('agrid_hours_per_day'),key:'hours_day',type:'hours_day'},
                {header:IMLocalized('agrid_currency'),key:'currency',type:'currency'},
                {header:IMLocalized('agrid_department'),key:'department',type:'department'},
                {header:IMLocalized('agrid_section'),key:'section',type:'section'},
                {header:IMLocalized('agrid_job_level'), key:'job_level',type:'job_level'},
                {header:IMLocalized('agrid_job_title'),key:'job_title',type:'job_title'},
                {header:IMLocalized('agrid_cost_center'),key:'cost_center',type:'cost_center'},
            ];

            for(let i=0;i<allowancelist.length;i++){
                const name = allowancelist[i].name;
                columns.push({header:name,key:'allowance_object.'+name,type:'allowance'});
            }

            for(let i=0;i<recurring_list.length;i++){
                const name = recurring_list[i].name;
                columns.push({header:name,key:'recurring_object.'+name,type:'recurring_allowance'});
            }

            const workmanlist = [{data:IMLocalized('excel_true')},{data:IMLocalized('excel_false')}];

            for(let i=0;i<workmanlist.length;i++){
                const y = i+1;
                ws2.getCell('A'+y).value = workmanlist[i].data;
            }

            for(let i=0;i<contract_type_list.length;i++){
                const x = i+1;
                if(language === 'zh'){
                    ws2.getCell('B'+x).value = contract_type_list[i].display_zh;
                }
                else{
                    ws2.getCell('B'+x).value = contract_type_list[i].display;
                }
                
            }

            for(let i=0;i<contract_rate_list.length;i++){
                const x = i+1;
                if(language === 'zh'){
                    ws2.getCell('C'+x).value = contract_rate_list[i].display_zh;
                }
                else{
                    ws2.getCell('C'+x).value = contract_rate_list[i].display;
                }
                
            }

            for(let i=0;i<currencylist.length;i++){
                const x = i+1;
                ws2.getCell('D'+x).value = currencylist[i].currency_name;
            }

            for(let i=0;i< departmentlist.length;i++){
                const x = i+1;
                ws2.getCell('E'+x).value = departmentlist[i].name;
            }

            for(let i=0;i< sectionlist.length;i++){
                const x = i+1;
                ws2.getCell('F'+x).value = sectionlist[i].name;
            }

            for(let i=0;i< joblevellist.length;i++){
                const x = i+1;
                ws2.getCell('G'+x).value = joblevellist[i].name;
            }

            for(let i=0;i< jobtitlelist.length;i++){
                const x = i+1;
                ws2.getCell('H'+x).value = jobtitlelist[i].name;
            }

            for(let i=0;i< costcenterlist.length;i++){
                const x = i+1;
                ws2.getCell('I'+x).value = costcenterlist[i].name;
            }

            for(let i=0;i< allowancelist.length;i++){
                const x = i+1;
                ws2.getCell('J'+x).value = allowancelist[i].name;
            }

            for(let i=0;i< recurring_list.length;i++){
                const x = i+1;
                ws2.getCell('K'+x).value = recurring_list[i].name;
            }

            for(let i=0;i<schedulelist.length;i++){
                const x = i+1;
                ws2.getCell('L'+x).value = schedulelist[i].name;
            }

            if(language === 'zh'){
                const option = ['是','否'];
                for(let i=0;i<option.length;i++){
                    const x = i+1;
                    ws2.getCell('M'+x).value = option[i];
                }
            }
            else{
                const option = ['Yes','No'];
                for(let i=0;i<option.length;i++){
                    const x = i+1;
                    ws2.getCell('M'+x).value = option[i];
                }
            }

            ws3.getCell('A1').value = 'WizardEmployeeContract';
            ws3.getCell('A2').value = language;
            if(rowDetail.length !== 0){
                for(let i=0;i<columns.length;i++){
                    const field = columns[i].key;
                    const type = columns[i].type;
                    for(let x=0;x<rowDetail.length;x++){
                        const step = 2+x;
                        const data = this.getValue(rowDetail[x],field);
                        
                        if(type === 'is_workman'){
                            if(workmanlist.length !== 0){
                                ws.getCell(this.getColumnName(i)+step).dataValidation={
                                    type:'list',
                                    allowBlank:false,
                                    error:IMLocalized('excel_valid_in_list'),
                                    errorStyle:'error',
                                    errorTitle:'Error',
                                    showErrorMessage:true,
                                    formulae:['=setting!$A$1:$A$'+workmanlist.length]
                                }
                            }
                            
                            ws.getCell(this.getColumnName(i)+step).value = null;
                        }
                        else if(type === 'contract_type'){
                            if(contract_type_list.length !== 0){
                                ws.getCell(this.getColumnName(i)+step).dataValidation={
                                    type:'list',
                                    allowBlank:false,
                                    error:IMLocalized('excel_valid_in_list'),
                                    errorStyle:'error',
                                    errorTitle:'Error',
                                    showErrorMessage:true,
                                    formulae:['=setting!$B$1:$B$'+contract_type_list.length]
                                }
                            }

                            ws.getCell(this.getColumnName(i)+step).value = null;
                            
                        }
                        else if(type === 'contract_rate'){
                            if(contract_rate_list.length !== 0){
                                ws.getCell(this.getColumnName(i)+step).dataValidation={
                                    type:'list',
                                    allowBlank:false,
                                    error:IMLocalized('excel_valid_in_list'),
                                    errorStyle:'error',
                                    errorTitle:'Error',
                                    showErrorMessage:true,
                                    formulae:['=setting!$C$1:$C$'+contract_rate_list.length]
                                }
                            }
                            ws.getCell(this.getColumnName(i)+step).value = null;
                        }
                        else if(type === 'currency'){
                            if(currencylist.length !== 0){
                                ws.getCell(this.getColumnName(i)+step).dataValidation={
                                    type:'list',
                                    allowBlank:false,
                                    error:IMLocalized('excel_valid_in_list'),
                                    errorStyle:'error',
                                    errorTitle:'Error',
                                    showErrorMessage:true,
                                    formulae:['=setting!$D$1:$D$'+ currencylist.length]
                                }
                            }  

                            ws.getCell(this.getColumnName(i)+step).value = null;
                        }
                        else if(type === 'department'){
                            if(departmentlist.length !== 0){
                                ws.getCell(this.getColumnName(i)+step).dataValidation={
                                    type:'list',
                                    allowBlank:false,
                                    error:IMLocalized('excel_valid_in_list'),
                                    errorStyle:'error',
                                    errorTitle:'Error',
                                    showErrorMessage:true,
                                    formulae:['=setting!$E$1:$E$'+ departmentlist.length]
                                }
                            }

                            ws.getCell(this.getColumnName(i)+step).value = null;
                        }
                        else if(type === 'section'){
                            if(sectionlist.length !== 0){
                                ws.getCell(this.getColumnName(i)+step).dataValidation={
                                    type:'list',
                                    allowBlank:false,
                                    error:IMLocalized('excel_valid_in_list'),
                                    errorStyle:'error',
                                    errorTitle:'Error',
                                    showErrorMessage:true,
                                    formulae:['=setting!$F$1:$F$'+ sectionlist.length]
                                }
                            }
                        }
                        else if(type === 'job_level'){
                            if(joblevellist.length !== 0){
                                ws.getCell(this.getColumnName(i)+step).dataValidation={
                                    type:'list',
                                    allowBlank:false,
                                    error:IMLocalized('excel_valid_in_list'),
                                    errorStyle:'error',
                                    errorTitle:'Error',
                                    showErrorMessage:true,
                                    formulae:['=setting!$G$1:$G$'+ joblevellist.length]
                                }
                            }

                            ws.getCell(this.getColumnName(i)+step).value = null;
                        }
                        else if(type === 'job_title'){
                            if(jobtitlelist.length !== 0){
                                ws.getCell(this.getColumnName(i)+step).dataValidation={
                                    type:'list',
                                    allowBlank:false,
                                    error:IMLocalized('excel_valid_in_list'),
                                    errorStyle:'error',
                                    errorTitle:'Error',
                                    showErrorMessage:true,
                                    formulae:['=setting!$H$1:$H$'+ jobtitlelist.length]
                                }
                            }
                            ws.getCell(this.getColumnName(i)+step).value = null;
                        }
                        else if(type === 'cost_center'){
                            if(costcenterlist.length !== 0){
                                ws.getCell(this.getColumnName(i)+step).dataValidation={
                                    type:'list',
                                    allowBlank:false,
                                    error:IMLocalized('excel_valid_in_list'),
                                    errorStyle:'error',
                                    errorTitle:'Error',
                                    showErrorMessage:true,
                                    formulae:['=setting!$I$1:$I$'+ costcenterlist.length]
                                }
                            }

                            ws.getCell(this.getColumnName(i)+step).value = null;
                        }
                        else if(type === 'allowance'){
                            if(allowancelist.length !== 0){
                                ws.getCell(this.getColumnName(i)+step).dataValidation={
                                    type:'list',
                                    allowBlank:false,
                                    error:IMLocalized('excel_valid_in_list'),
                                    errorStyle:'error',
                                    errorTitle:'Error',
                                    showErrorMessage:true,
                                    formulae:['=setting!$M$1:$M$2']
                                }
                            }
                            
                            ws.getCell(this.getColumnName(i)+step).value = null;
                        }
                        else if(type === 'recurring_allowance' ){
                            if(recurring_list.length !== 0){
                                ws.getCell(this.getColumnName(i)+step).dataValidation={
                                    type:'list',
                                    allowBlank:false,
                                    error:IMLocalized('excel_valid_in_list'),
                                    errorStyle:'error',
                                    errorTitle:'Error',
                                    showErrorMessage:true,
                                    formulae:['=setting!$M$1:$M$2']
                                }
                            }
                            
                            ws.getCell(this.getColumnName(i)+step).value = null;
                        }
                        else if(type === 'wschedule'){
                            if(schedulelist.length !== 0){
                                ws.getCell(this.getColumnName(i)+step).dataValidation={
                                    type:'list',
                                    allowBlank:false,
                                    error:IMLocalized('excel_valid_in_list'),
                                    errorStyle:'error',
                                    errorTitle:'Error',
                                    showErrorMessage:true,
                                    formulae:['=setting!$L$1:$L$'+ schedulelist.length]
                                }
                            }

                            ws.getCell(this.getColumnName(i)+step).value = null;
                        }
                        else if (type === 'hourly_rate' || type === 'daily_rate'){
                            ws.getCell(this.getColumnName(i)+step).value = null;
                        }
                        else{
                            ws.getCell(this.getColumnName(i)+step).value = data;
                        }
                    }
                }
                ws.columns = columns;
                ws.columns.forEach(column =>{
                    column.width = column.header.length < 15 ? 15 : column.header.length
                })
        
                wb.xlsx.writeBuffer().then((buf)=>{
                    var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                    FileSaver.saveAs(file, 'Wizard_Employee_Contract_Template.xlsx')
                });
            }
        }
        else{
            Swal.fire({
                type:'warning',
                title:IMLocalized('select_employee_to_export'),
                confirmButtonText:IMLocalized('ok!')
            })
        }

    }

    getValue(object, path) {
        const parts = path.split(".");
        return parts.reduce((value, key) => value ? value[key] : value, object)
    }

    getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }

    getImportData=(data,lang)=>{
        const {schedulelist,allowancelist,recurring_list}= this.props;
        let arr = [];
        for(let i=0;i<data.length ;i++){
            const item = data[i];
            let allowance_object = {};
            for(let j =0;j<allowancelist.length;j++){
                const name = allowancelist[j].name;
                allowance_object[name] = item['allowance_object.'+name]
            }

            let recurring_object = {};
            for(let j =0;j<recurring_list.length;j++){
                const name = recurring_list[j].name;
                recurring_object[name] = item['recurring_object.'+name]
                
            }

            const contract_rate = item.contract_rate;
            let pay  =item.pay;
            const workman = item.is_workman;
            const hourly_rate1 = item.hourly_rate;
            const daily_rate1 = item.daily_rate;
            let rate_name = '';
            let is_workman = '';
            if(lang === 'zh'){
                item.allowance_object = allowance_object;
                item.recurring_object = recurring_object;
                if(workman === '是' ){
                    is_workman = 'true';
                }
                else{
                    is_workman = 'false';
                }
                if(contract_rate !== null){
                    const rate_detail  = contract_rate_list.filter(function(item){
                        return item.display_zh === contract_rate
                    })

                    if(rate_detail.length !== 0){
                        rate_name = rate_detail[0].value;
                    }

                    if(rate_name === 'MONTHLY'){
                    
    
                        if(pay !== null){
                            pay = parseFloat(pay);
        
                        }
                        else{
                            pay = 0;
                        }
    
                        let hourly_rate = 0;
    
                        if(pay !== 0){
                            hourly_rate = (pay*12)/(52*44);
                            if(is_workman === 'true'){
                                if(pay>4500){
                                    hourly_rate = 13.60;
                                }
                            }
                            else{
                                if(pay >=2600){
                                    hourly_rate = 13.60;
                                }
                            }
                        }
                        if(hourly_rate1 === null){
                            item.hourly_rate =parseFloat(hourly_rate.toFixed(8));
                        }
                        
                        const schedule = item.wschedule;
                        let work_days_in_week = 0;
                        if(schedule !== null){
                            const schedule_detail = schedulelist.filter(function(item){
                                return item.name === schedule
                            })
                            if(schedule_detail.length !== 0){
                                work_days_in_week = schedule_detail[0].work_days_in_week;
                            }
                        }
    
                        let final = 0;
                        if(pay !== 0 && work_days_in_week !== 0){
                            const total = (pay * 12) / (52*work_days_in_week);
                            final = parseFloat(total.toFixed(4));
                        }
                        item.is_select = 'true';
                        item.is_workman = is_workman;
                        if(daily_rate1 === null){
                            item.daily_rate = final;
                        }
                        
                        item.hours_day = 0;
                        arr.push(item)
                    }
                    else if(rate_name === 'HOURLY'){
                        item.pay = 0;
                        item.daily_rate = 0;
                        item.hours_day = 0;
                        item.is_workman = is_workman;
                        item.is_select = 'true';
                        arr.push(item)
                    }
                    else if(rate_name === 'DAILY'){
                        item.pay =0;
                        item.hourly_rate = 0;
                        item.is_workman = is_workman;
                        item.is_select = 'true';
                        arr.push(item)
                    }
                }
                else{
                    item.is_select = 'true';
                    arr.push(item)
                }

            }
            else{
                item.recurring_object = recurring_object;
                item.allowance_object = allowance_object;
                if(workman === 'Yes' ){
                    is_workman = 'true';
                }
                else{
                    is_workman = 'false';
                }
                if(contract_rate !== null){
                    const rate_detail  = contract_rate_list.filter(function(item){
                        return item.display === contract_rate
                    })

                    if(rate_detail.length !== 0){
                        rate_name = rate_detail[0].value;
                    }

                    if(rate_name === 'MONTHLY'){
                    
    
                        if(pay !== null){
                            pay = parseFloat(pay);
        
                        }
                        else{
                            pay = 0;
                        }
    
                        let hourly_rate = 0;
    
                        if(pay !== 0){
                            hourly_rate = (pay*12)/(52*44);
                            if(is_workman === 'true'){
                                if(pay>4500){
                                    hourly_rate = 13.60;
                                }
                            }
                            else{
                                if(pay >=2600){
                                    hourly_rate = 13.60;
                                }
                            }
                        }
                        
                        item.hourly_rate =parseFloat(hourly_rate.toFixed(8));
                        const schedule = item.wschedule;
                        let work_days_in_week = 0;
                        if(schedule !== null){
                            const schedule_detail = schedulelist.filter(function(item){
                                return item.name === schedule
                            })
                            if(schedule_detail.length !== 0){
                                work_days_in_week = schedule_detail[0].work_days_in_week;
                            }
                        }
    
                        let final = 0;
                        if(pay !== 0 && work_days_in_week !== 0){
                            const total = (pay * 12) / (52*work_days_in_week);
                            final = parseFloat(total.toFixed(4));
                        }
                        item.is_select = 'true';
                        item.is_workman = is_workman;
                        item.daily_rate = final;
                        item.hours_day = 0;
                        arr.push(item)
                    }
                    else if(rate_name === 'HOURLY'){
                        item.pay = 0;
                        item.daily_rate = 0;
                        item.hours_day = 0;
                        item.is_workman = is_workman;
                        item.is_select = 'true';
                        arr.push(item)
                    }
                    else if(rate_name === 'DAILY'){
                        item.pay =0;
                        item.hourly_rate = 0;
                        item.is_workman = is_workman;
                        item.is_select = 'true';
                        arr.push(item)
                    }
                }
                else{
                    arr.push(item)
                    item.is_select = 'true';
                }
            }
        }
        this.setState({rowData:arr})
    }

    uploadExcel=(e)=>{
        
        let file = e.target.files[0];
        const {language} = this.state;
        const {recurring_list,allowancelist} = this.props;
        const reader = new FileReader();
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3];
            const columns = {
                'A':'employee_number',
                'B':'name',
                'C':'join_date',
                'D':'contract_type',
                'E':'contract_rate',
                'F':'is_workman',
                'G':'wschedule',
                'H':'pay',
                'I':'hourly_rate',
                'J':'daily_rate',
                'K':'hours_day',
                'L':'currency',
                'M':'department',
                'N':'section',
                'O':'job_level',
                'P':'job_title',
                'Q':'cost_center',
            }

            let number = 17;
            for(let i =0;i<allowancelist.length;i++){
                const header = this.getColumnName(number)
                const name = allowancelist[i].name;
                number = number +1;
                columns[header] = 'allowance_object.' +name;
            }

            for(let i =0;i<recurring_list.length;i++){
                const header = this.getColumnName(number)
                const name = recurring_list[i].name;
                number = number +1;
                columns[header] = 'recurring_object.' +name;
                
            }

            let rowData=[];
            let rowIndex =2;

            let idd = '';
            let lang = '';
            if(ws3 !== undefined){
                if(ws3['A1'] !== undefined){
                    idd = ws3['A1'].w;
                    lang = ws3['A2'].w;
                }
            }
            if(idd === 'WizardEmployeeContract'){
                if(lang === language){
                    while(ws['A'+rowIndex]){
                        let row = {};
                        for(let i =0;i<Object.keys(columns).length;i++){
                            const column = Object.keys(columns)[i];
                            if(ws[column+rowIndex] !== undefined){
                                row[columns[column]] = ws[column+rowIndex].w;
                            }
                            else{
                                row[columns[column]] = null;
                            }
                        }
                        rowData.push(row);
                        rowIndex++;
                    }
                    this.setState({import_lang:lang})
                    this.getImportData(rowData,lang)
                }
                else{
                    Swal.fire({
                        title:IMLocalized('wrong_file_selected'),
                        text:IMLocalized('please_choose_correct_language'),
                        type:'error',
                        confirmButtonColor:'#d33',
                        confirmButtonText:IMLocalized('ok!'),
                    })
                }
            }
            else{
                Swal.fire({
                    title:IMLocalized('wrong_file_selected'),
                    type:'error',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!'),
                })
            }
        }
        reader.readAsBinaryString(file)
    }


    validateData=()=>{
        const {schedulelist,currencylist,list,recurring_list,allowancelist} = this.props;
        const {language} = this.state;
        let arr = [];
        let err =[];
        let err2 = [];
        this.gridApi.forEachNode(function(rowNode,index){
           
            let err1=[];
            let object={employee_number:'',err_contract_rate:false,err_contract_type:false,err_cost_center:false,err_currency:false,err_daily_rate:false,err_department:false,err_hourly_rate:false,err_hours_day:false,err_job_level:false,err_job_title:false,err_pay:false,err_section:false,err_wschedule:false};
            const item = rowNode.data;
            const employee_number = item.employee_number;
            const name = item.name;
            const emp_detail = list.filter(function(item){
                return item.employee.employee_number === employee_number
            })

            let employee;
            if(emp_detail.length !== 0){
                employee = emp_detail[0].employee.id;
            }
            const start_date = this.convertDateFormat(item.join_date);
            const department = item.department;
            const pay = item.pay;
            const is_workman = item.is_workman;
            const hourly_rate = item.hourly_rate;
            const daily_rate = item.daily_rate;
            const hours_day = item.hours_day;
            const section = item.section;
            const job_level = item.job_level;
            const job_title = item.job_title;
            const cost_center = item.cost_center;

            let allowances=[];
                const allowance_object = item.allowance_object;
                const recurring_object = item.recurring_object;
                for(let j=0;j<allowancelist.length;j++){
                    const name = allowancelist[j].name
                    const value = allowance_object[name];
                    if(language === 'zh'){
                        if(value === '是'){
                            allowances.push(name)
                        }
                    }
                    else{
                        if(value === 'Yes'){
                            allowances.push(name)
                        }
                    }
                }
                let recurring_allowances = [];
                for(let j=0;j<recurring_list.length;j++){
                    const name = recurring_list[j].name;
                    const value = recurring_object[name];
                    if(language === 'zh'){
                        if(value === '是'){
                            recurring_allowances.push(name)
                        }
                    }
                    else{
                        if(value === 'Yes'){
                            recurring_allowances.push(name)
                        }
                    }
                }

            const currency_name = item.currency;
            let currency;
            if(currency_name === null || currency_name === ''){
                currency = null;
            }
            else{
                const currency_detail = currencylist.filter(function(item){
                    return item.currency_name === currency_name
                })
                if(currency_detail.length !== 0){
                    currency = currency_detail[0].id;
                }
            }

            const schedule_name = item.wschedule;
            let wschedule =null;
            if(schedule_name !== null && schedule_name !== ''){
                const schedule_detail = schedulelist.filter(function(item){
                    return item.name === schedule_name
                })
                if(schedule_detail.length !== 0){
                    wschedule = schedule_detail[0].id;
                } 
            }
            
            
            
            let contract_type = null;
            let contract_rate = null;
            if(language !== ''){
                if(language === 'zh'){
                    const type = item.contract_type;
                    const rate = item.contract_rate;
                 
                    if(type !== '' && type !== null){
                        const type_detail = contract_type_list.filter(function(item){
                            return item.display_zh === type
                        })

                        contract_type = type_detail[0].value;
                    }
                    if(rate !== '' && rate !== null){
                        const rate_detail = contract_rate_list.filter(function(item){
                            return item.display_zh === rate
                        })

                        contract_rate = rate_detail[0].value;
                    }

                }
                else{
                    const type = item.contract_type;
                    const rate = item.contract_rate;
                    
                    if(type !== '' && type !== null){
                        const type_detail = contract_type_list.filter(function(item){
                            return item.display === type
                        })

                        contract_type = type_detail[0].value;
                    }
                    if(rate !== '' && rate !== null){
                        const rate_detail = contract_rate_list.filter(function(item){
                            return item.display === rate
                        })

                        contract_rate = rate_detail[0].value;
                    }
                }
            }
            // if(is_select ==='true'){
                
                if(contract_type !== null && contract_type !== '' && contract_rate !== null && contract_rate !==''  && wschedule !=='' && wschedule !== null && department !== null && department !== ''){
                
                    arr.push({employee_number,name,employee,pay,start_date,is_workman,wschedule,hourly_rate,daily_rate,contract_rate,contract_type,hours_day,contract_currency:currency,department,section,job_level,job_title,cost_center,allowances,recurring_allowances});
                
                }
                else{
                    object['employee_number'] = employee_number; 
                    if(contract_type === null || contract_type === ''){
                        err1.push(<div><span>● {IMLocalized('contract_type_is_required')}</span></div>)
                        object['err_contract_type'] = true;
                    }
                    if(contract_rate === null || contract_rate === ''){
                        err1.push(<div><span>● {IMLocalized('contract_rate_is_required')}</span></div>)
                        object['err_contract_rate'] = true;
                    }
                    if(wschedule === null || wschedule === ''){
                        err1.push(<div><span>● {IMLocalized('schedule_is_required')}</span></div>)
                        object['err_wschedule'] = true;
                    }
                    if(department === null || department === ''){
                        err1.push(<div><span>● {IMLocalized('department_is_required')}</span></div>)
                        object['err_department'] = true;
                    }

                    err.push({employee_number,name,err1});
                }

                err2.push(object);
            //}

            

        }.bind(this))

        if(err.length !== 0){
            let table = err.map((item)=>{
                return(
                    <tr>
                        <td style={{verticalAlign:'top'}}><span>{item.employee_number}</span></td>
                        <td style={{verticalAlign:'top'}}>{item.name}</td>
                        <td>{item.err1}</td>
                    </tr>
                )
            })
            this.setState({errorlist:err2})
            this.setState({err_toggle:true});
            this.setState({error_msg:table});
        }
        else if(arr.length !== 0){
            this.setState({validatelist:arr});
            this.props.contract_contract_array_validate(arr);
        }
        else{
            Swal.fire({
                type:'warning',
                title:IMLocalized('no_employee_selected'),
                confirmButtonText:IMLocalized('ok!')
            })
        }
        
        
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        
    };

    handleFile = event => {
        const { target = {} } = event || {};
        target.value = "";
    }

    toHome=()=>{
        this.props.toHome('1','dashboard');
    }

    getDateFormat=(date)=>{
        const date1 = moment(date,'YYYY-MM-DD');
        const new_date = date1.format('DD/MM/YYYY');
        return new_date;
    }

    convertDateFormat=(date)=>{
        const date1 = moment(date,'DD/MM/YYYY');
        const new_date = date1.format('YYYY-MM-DD');
        return new_date;
    }

    clearData=()=>{
        const {count} = this.state;
        const final = count + 1;
        this.setState({count:final});
        this.setState({rowData:[]});
        this.setState({error_msg:[]});
        this.setState({step:0});
        this.getDetail();
    }

    getErrorMsg=()=>{
        const table=(
            <Modal isOpen={this.state.err_toggle} size="lg" >
                <ModalHeader toggle={this.setErrToggle}>{IMLocalized('error_msg')}</ModalHeader>
                <ModalBody>
                    <Table size="sm" className="table-bordered align-items-center" responsive>
                        <thead className="thead-light p-2">
                            <tr>
                                <th>{IMLocalized('employee_number')}</th>
                                <th>{IMLocalized('name')}</th>
                                <th>{IMLocalized('error')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.error_msg}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-danger btn-sm" onClick={this.setErrToggle}>{IMLocalized('close')}</button>
                </ModalFooter>
            </Modal>
        )

        return table;
    }

    setErrToggle=()=>{
        const toggle = !this.state.err_toggle;
        this.setState({err_toggle:toggle});
    }

    skipStep=()=>{
        Swal.fire({
            title:IMLocalized('skip_contract_wizard_confirmation'),
            type:'warning',
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then((result)=>{
            if(result.value){
                this.props.onNext();
            }
        })
    }

    goBack=()=>{
        Swal.fire({
            type:'warning',
            title:IMLocalized('skip_emp_wizard_confirmation'),
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then((result)=>{
            if('value' in result){
                this.props.goBack()
            }
        })
        
    }

    render(){
        const {error_msg,rowData} = this.state;
        let btn = '';

        let func_btn= '';
        if(rowData.length !== 0){
           
            func_btn = <button className="btn btn-primary btn-sm" onClick={this.validateData} >{IMLocalized('create')}</button>
            

            btn =<button onClick={this.getTemplate} className="btn btn-primary btn-sm">{IMLocalized('get_employee_contract_template')}</button>
        }
        

        let error_btn ='';
        if(error_msg.length !== 0){
            error_btn = <button className="btn btn-danger btn-sm" onClick={this.setErrToggle}>{IMLocalized('error_msg')}</button>
        }
        return(
            <div>
                {this.props.isLoading1 || this.props.isLoading2 || this.props.isLoading3 || this.props.isLoading4 || this.props.isLoading5 || this.props.isLoading6 || this.props.isLoading7 ? <OverlayLoading/>:
                <div>
                    <Container fluid>
                        <Row className="pt-2">
                            <Col>
                                <div className="d-flex">
                                    <div>
                                        <button className="btn btn-primary btn-sm" onClick={this.goBack}> <i className="fas fa-chevron-left"></i> {IMLocalized('back_to_main_page')}</button>
                                        <button onClick={this.skipStep} className="btn btn-primary btn-sm">{IMLocalized('skip')}</button>
                                        {btn}
                                    </div>
                                    <div className="ml-2 mr-2">
                                        <input type="file" style={{display:'none'}} id="ImportContractcustomFile" className="custom-file-input form-control-sm" onChange={this.uploadExcel.bind(this)} onClick={this.handleFile} accept=".xlsx" />
                                        <label className="btn btn-primary btn-sm" for="ImportContractcustomFile">{IMLocalized('upload_file')}</label>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary btn-sm" onClick={this.clearData}>{IMLocalized('clear')}</button>
                                        {func_btn}
                                        {error_btn}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="pt-2">
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <div className="ag-theme-balham" style={{height:'calc(100vh - 200px)', width:'100%' }}>
                                        <AgGridReact
                                        columnDefs={this.state.columnsDefs}
                                        rowData={this.state.rowData}
                                        suppressDragLeaveHidesColumns={true}
                                        suppressSizeToFit={true}
                                        suppressColumnMoveAnimation={false}
                                        singleClickEdit={true}
                                        rowClassRules={{
                                            'sick-days-warning': function (params) {
                                                var numSickDays = params.data.err_non;
                                                return numSickDays ===true
                                                },
                                        
                                        }}
                                        stopEditingWhenCellsLoseFocus={true}
                                        onCellValueChanged={this.onCellValueChanged}
                                        onGridReady={this.onGridReady}
                                        gridOptions={gridOptions}
                                        
                                        >
                                        </AgGridReact>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {this.getErrorMsg.call(this)}
                    </Container>
                </div>}
            </div>
        )
    }
}
function mapStateToProps(state){
    return{
        schedulelist:state.schedule_list.data,
        
        contract_ratelist:state.get_contract_template_dropdown.contract_rate,
        contract_typelist:state.get_contract_template_dropdown.contract_type,
        currencylist:state.general_currency.data,
        departmentlist:state.add.department,
        cas:state.add.data,
        sectionlist:state.add.section,
        jobtitlelist:state.add.jobtitle,
        joblevellist:state.add.joblevel,
        costcenterlist:state.add.costcenter,
        allowancelist:state.add.allowance,
        recurring_list:state.add.recurringallowance,
        emplist:state.get_employee_list.data,
        validate_success:state.contract_contract_array_validate.data,
        validate_errors:state.contract_contract_array_validate.errors,
        create_success:state.contract_contract_array_save.data,
        create_sucess_data:state.contract_contract_array_save.hello,
        isLoading1:state.schedule_list.isLoading,
        isLoading2:state.get_contract_template_dropdown.isLoading,
        isLoading3:state.general_currency.isLoading,
        isLoading4:state.add.isLoading,
        isLoading5:state.get_employee_list.isLoading,
        isLoading6:state.contract_contract_array_validate.isLoading,
        isLoading7:state.contract_contract_array_save.isLoading,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        contract_contract_array_validate:(data)=>{
            dispatch(contract_contract_array_validate(data))
        },
        contract_contract_array_save:(data)=>{
            dispatch(contract_contract_array_save(data))
        },
        get_employee_list:()=>{
            dispatch(get_employee_list())
        },
        getScheduleList:()=>{
            dispatch(getScheduleList())
        },
        get_contract_template_dropdown:()=>{
            dispatch(get_contract_template_dropdown())
        },
        general_currency:()=>{
            dispatch(general_currency())
        },
        get_additional_setting:()=>{
            dispatch(get_additional_setting())
        },
    }  
}


export default connect(mapStateToProps, mapDispatchToProps)(wizard_create_emp_contract);