import * as update_color from '../action/update_general_color';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const update_general_color = (state=initialState,action)=>{
    switch(action.type){
        case update_color.UPDATEGENERALCOLOR_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case update_color.UPDATEGENERALCOLOR_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case update_color.UPDATEGENERALCOLOR_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case update_color.UPDATEGENERALCOLOR_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default update_general_color;