import {combineReducers} from 'redux';
import auth,* as fromAuth from './auth.js';
import company_list from './company_list';
import create_joblevel from './create_joblevel';
import add from './additional_setting';
import create_costcenter from './create_costcenter';
import create_jobtitle from './create_jobtitle';
import create_department from './create_department';
import create_section from './create_section';
import delete_section from './delete_section';
import delete_joblevel from './delete_joblevel';
import delete_department from './delete_department';
import delete_costcenter from './delete_costcenter';
import delete_jobtitle from './delete_jobtitle';
import delete_overtime from './delete_overtime';
import delete_allowance from './delete_allowance';
import create_allowance from './create_allowance';
import create_overtime from './create_overtime';
import company_dropdown from './company_dropdown';
import sg_address from './sg_address';
import create_company from './create_company';
import delete_schedule from './delete_schedule';
import schedule_list from './schedule_list';
import create_schedule from './create_schedule';
import schedule_dropdown from './schedule_dropdown';
import update_schedule from './update_shedule';
import general_group from './general_group';
import general_first_time from './general_first_time';
import get_claim_list from './claim/get_claim_list';
import get_apply_claim_list from './claim/get_apply_claim_list';
import get_employee_list from './employee/get_employee_list';
import user_self from './user/user_self';
import dropdown_list from './dropdown_list';
import get_payroll_period from './get_payroll_period';
import update_pay_period from './update_pay_period';
import delete_recurring from './delete_recurring';
import create_recurring from './create_recurring';
import create_flat_allowance from './create_flat_allowance';
import delete_flat_allowance from './delete_flat_allowance';
import get_employee_probation_list from './get_employee_probation_list';
import approve_employee_probation from './approve_employee_probation';
import extend_employee_probation from './extend_employee_probation';
import get_holiday_list from './get_holiday_list';
import get_leave_list from './get_leave_list';
import leave_hr_approve from './leave_hr_approve';
import delete_holiday from './delete_holiday';
import update_holiday from './update_holiday';
import create_holiday from './create_holiday';
import get_base_setting from './get_base_setting';
import update_general_setting from './update_general_setting';
import general_currency from './general_currency';
import delete_general_currency from './delete_general_currency';
import create_general_currency from './create_general_currency';
import update_general_currency from './update_general_currency';
import get_contract_template_list from './get_contract_template_list';
import delete_contract_template from './delete_contract_template';
import get_contract_template_dropdown from './get_contract_template_dropdown';
import create_contract_department from './create_contract_department';
import create_contract_section from './create_contract_section';
import create_contract_job_title from './create_contract_job_title';
import create_contract_job_level from './create_contract_job_level';
import create_contract_cost_center from './create_contract_cost_center';
import update_contract_template from './update_contract_template';
import create_contract_template from './create_contract_template';
import get_leave_option from './get_leave_option';
import update_leave_option from './update_leave_option';
import delete_leave from './delete_leave';
import update_leave from './update_leave';
import create_leave from './create_leave';
import get_leave_scheme_list from './get_leave_scheme_list';
import delete_leave_scheme from './delete_leave_scheme';
import create_leave_scheme from './create_leave_scheme';
import get_apply_leave_list from './get_apply_leave_list';
import general_daterange from './general_daterange';
import hr_calendar_array_approve_leave from './hr_calendar/array_approve_leave'
import hr_calendar_array_approve_leave_manager from './hr_calendar/hr_calendar_array_approve_leave_manager'
import get_claim_option from './claim/get_claim_option';
import update_claim_option from './claim/update_claim_option';
import delete_claim from './claim/delete_claim';
import update_claim from './claim/update_claim';
import create_claim from './claim/create_claim';
import get_claim_scheme_list from './claim/get_claim_scheme_list';
import delete_claim_scheme from './claim/delete_claim_scheme';
import create_claim_scheme from './claim/create_claim_scheme';
import get_pay_summary_list from './claim/get_pay_summary_list';
import get_employee_picture_list from './employee/get_employee_picture_list';
import get_employee_picture from './employee/get_employee_picture';
import get_latest_employee from './employee/get_latest_employee';
import get_branch_list from './get_branch_list';
import get_employee_postal_code from './employee/get_employee_postal_code';
import create_employee from './employee/create_employee';
import create_new_employee_permit from './employee/create_new_employee_permit';
import get_nextprev_employee from './employee/get_nextprev_employee';
import get_employee_profile_picture from './employee/get_employee_profile_picture';
import update_employee_profile_picture from './employee/update_employee_profile_picture';
import get_employee_profile_picture_list from './employee/get_employee_profile_picture_list';
import update_employee_profile from './employee/update_employee_profile';
import get_employee_address from './employee/get_employee_address';
import create_employee_address from './employee/create_employee_address';
import update_employee_address from './employee/update_employee_address';
import get_employee_passport from './employee/get_employee_passport';
import delete_employee_passport from './employee/delete_employee_passport';
import update_employee_passport from './employee/update_employee_passport';
import create_employee_passport from './employee/create_employee_passport';
import get_employee_bank from './employee/get_employee_bank';
import delete_employee_bank from './employee/delete_employee_bank';
import create_employee_bank from './employee/create_employee_bank';
import update_employee_bank from './employee/update_employee_bank';
import setdefault_employee_bank from './employee/setdefault_employee_bank';
import get_employee_contract_status from './employee/get_employee_contract_status';
import get_payroll_list from './payroll/get_payroll_list';
import get_payroll_available_date from './payroll/get_payroll_available_date';
import create_new_payroll from './payroll/create_new_payroll';
import get_payroll_instance from './payroll/get_payroll_instance';
import payroll_detail_get_aggridheader from './payroll/payroll_detail_get_aggridheader';
import payroll_detail_update_payment_count from './payroll/payroll_detail_update_payment_count';
import payroll_confirmation from './payroll/payroll_confirmation';
import payment_update_payroll_payment from './payroll/payment_update_payroll_payment';
import payslip_update_payroll_count from './payroll/payslip_update_payroll_count';
import create_payslip_backpay from './payroll/create_payslip_backpay';
import create_payslip_bonus from './payroll/create_payslip_bonus';
import delete_pay_component from './payroll/delete_pay_component';
import regenerate_payslip from './payroll/regenerate_payslip';
import payslip_get_emp_picture from './payroll/payslip_get_emp_picture';
import regenerate_payroll from './payroll/regenerate_payroll';
import monthly_report_get_aggridheader from './report/monthly_report_get_aggridheader';
import monthly_payroll_instance from './report/monthly_payroll_instance';
import wizard_get_sg_postal from './wizard/wizard_get_sg_postal';
import company_validate from './wizard/company_validate';
import wizard_complete from './wizard/wizard_complete';
import get_general_dashboard from './dashboard/get_general_dashboard';
import delete_company from './company/delete_company';
import delete_branch from './company/delete_branch';
import update_branch from './company/update_branch';
import branch_get_sg_postal from './company/branch_get_sg_postal';
import create_branch from './company/create_branch';
import update_company from './company/update_company';
import resign_employee from './employee/resign_employee';
import employee_contract_additional_cost from './employee/employee_contract_additional_cost';
import update_employee_profile_picture2 from './employee/update_employee_profile_picture2';
import employee_contract_department from './employee/employee_contract_department';
import employee_contract_section from './employee/employee_contract_section';
import employee_contract_costcenter from './employee/employee_contract_costcenter';
import employee_contract_joblevel from './employee/employee_contract_joblevel';
import employee_contract_jobtitle from './employee/employee_contract_jobtitle';
import get_employee_relationship from './employee/get_employee_relationship'; 
import update_employee_relationship from './employee/update_employee_relationship';
import delete_employee_relationship from './employee/delete_employee_relationship';
import create_employee_relationship from './employee/create_employee_relationship';
import get_employee_sg_child from './employee/get_employee_sg_child';
import delete_sg_child from './employee/delete_sg_child';
import create_employee_contract from './employee/create_employee_contract';
import payroll_variance_employee_payroll from './report/payroll_variance_employee_payroll';
import payroll_variance_get_aggridheader from './report/payroll_variance_get_aggridheader';
import payroll_variance_get_aggridheader2 from './report/payroll_variance_get_aggridheader2';
import payroll_computation_payroll_instance from './report/payroll_computation_payroll_instance';
import payroll_computation_get_aggridheader from './report/payroll_computation_get_aggridheader';
import headcount_payroll_instance from './report/headcount_payroll_instance';
import headcount_get_aggridheader from './report/headcount_get_aggridheader';
import update_dashboard_widget from './dashboard/update_dashboard_widget';
import create_sg_child from './employee/create_sg_child';
import get_employee_maternity_list from './employee/get_employee_maternity_list';
import create_sg_maternity from './employee/create_sg_maternity';
import create_sg_maternity_actual from './employee/create_sg_maternity_actual';
import get_all_employee_available_leave from './employee/get_all_employee_available_leave';
import employee_get_available_leave_options from './employee/employee_get_available_leave_options';
import employee_user_apply_leave from './employee/employee_user_apply_leave';
import employee_award_leave from './employee/employee_award_leave';
import get_leave_award_list from './employee/get_leave_award_list';
import create_contract_increment_pay from './payroll/create_contract_increment_pay';
import create_contract_increment_pay_validate from './payroll/create_contract_increment_pay_validate';
import leave_reset_list from './leave/leave_reset_list';
import create_new_leave_reset from './leave/create_new_leave_reset';
import filter_leave_reset_list from './leave/filter_leave_reset_list';
import create_leave_approval from './leave/create_leave_approval';
import employee_contract_department1 from './employee/employee_contract_department1';
import employee_contract_section1 from './employee/employee_contract_section1';
import employee_contract_costcenter1 from './employee/employee_contract_costcenter1';
import employee_contract_joblevel1 from './employee/employee_contract_joblevel1';
import employee_contract_jobtitle1 from './employee/employee_contract_jobtitle1';
import update_employee_contract from './employee/update_employee_contract';
import leave_table_hr_approve from './leave/leave_table_hr_approve';
import get_all_employee_available_claim from './employee/get_all_employee_available_claim';
import get_medical_claim_autocomplete from './claim/get_medical_claim_autocomplete';
import employee_claim_apply_validate from './employee/employee_claim_apply_validate';
import employee_apply_claim from './employee/employee_apply_claim';
import create_employee_unique_check from './employee/create_employee_unique_check';
import create_employee_array_validate from './employee/create_employee_array_validate';
import create_employee_array_create from './employee/create_employee_array_create';
import validate_apply_leave_reset from './leave/validate_apply_leave_reset';
import apply_leave_reset from './leave/apply_leave_reset';
import get_employee_permit from './employee/get_employee_permit';
import delete_employee_workpermit from './employee/delete_employee_workpermit';
import create_employee_permit from './employee/create_employee_permit';
import update_employee_workpermit from './employee/update_employee_workpermit';
import get_employee_payroll from './employee/get_employee_payroll';
import claim_all_get_claim_picture from './claim/claim_all_get_claim_picture';
import array_reject_apply_claim from './claim/array_reject_apply_claim';
import array_approve_apply_claim from './claim/array_approve_apply_claim';
import validate_approve_apply_claim from './claim/validate_approve_apply_claim';
import reject_apply_claim from './claim/reject_apply_claim';
import validate_claim_pay from './claim/validate_claim_pay';
import get_pay_summary_detail from './claim/get_pay_summary_detail';
import get_history_list from './history/get_history_list';
import emp_all_array_approve_leave from './employee/emp_all_array_approve_leave';
import get_payroll_history_list from './history/get_payroll_history_list';
import save_contract_template from './employee/save_contract_template';
import emp_detail_array_approve_leave from './employee/emp_detail_array_approve_leave';
import emp_detail_get_leave_picture from './employee/emp_detail_get_leave_picture';
import emp_all_array_approve_apply_claim from './employee/emp_all_array_approve_apply_claim';
import emp_all_array_reject_apply_claim from './employee/emp_all_array_reject_apply_claim';
import emp_all_reject_apply_claim from './employee/emp_all_reject_apply_claim';
import emp_all_validate_claim_pay from './employee/emp_all_validate_claim_pay';
import user_get_available_leave from './user/user_get_available_leave';
import get_self_available_leave_options from './user/get_self_available_leave_options';
import user_apply_leave_self from './user/user_apply_leave_self';
import get_approvals_leave_list from './approvals/get_approvals_leave_list';
import calendar_leave_manager_approve from './approvals/calendar_leave_manager_approve';
import calendar_approvals_get_leave_picture from './approvals/calendar_approvals_get_leave_picture';
import get_employee_list_withoutcontract from './employee/get_employee_list_withoutcontract';
import contract_contract_array_validate from './contract/contract_contract_array_validate';
import contract_contract_array_save from './contract/contract_contract_array_save';
import user_get_available_claim from './user/user_get_available_claim';
import validate_user_apply_claim from './user/validate_user_apply_claim';
import user_apply_claim from './user/user_apply_claim';
import get_self_payroll from './user/get_self_payroll';
import update_resign from './employee/update_resign';
import user_apply_claim_list from './user/user_apply_claim_list';
import payroll_update_rates from './payroll/payroll_update_rates';
import monthly_report_date_filter from './report/monthly_report_date_filter';
import user_apply_leave_list from './user/user_apply_leave_list';
import user_get_all_leave_list from './user/user_get_all_leave_list';
import get_leave_pending_color from './user/get_leave_pending_color';
import user_holiday_list from './user/user_holiday_list';
import user_leave_get_leave_picture from './user/user_leave_get_leave_picture';
import user_leave_cancel_apply_leave from './user/user_leave_cancel_apply_leave';
import wizard_create_company from './wizard/wizard_create_company';
import wizard_validate_overtime from './wizard/wizard_validate_overtime';
import wizard_create_overtime from './wizard/wizard_create_overtime';
import wizard_validate_allowance from './wizard/wizard_validate_allowance';
import wizard_create_allowance from './wizard/wizard_create_allowance';
import wizard_validate_recurring from './wizard/wizard_validate_recurring';
import wizard_create_recurring from './wizard/wizard_create_recurring';
import wizard_validate_flat from './wizard/wizard_validate_flat';
import wizard_create_flat from './wizard/wizard_create_flat';
import wizard_create_department from './wizard/wizard_create_department';
import wizard_create_section from './wizard/wizard_create_section';
import wizard_create_joblevel from './wizard/wizard_create_joblevel';
import wizard_create_jobtitle from './wizard/wizard_create_jobtitle';
import wizard_create_costcenter from './wizard/wizard_create_costcenter';
import wizard_validate_department from './wizard/wizard_validate_department';
import wizard_validate_section from './wizard/wizard_validate_section';
import wizard_validate_joblevel from './wizard/wizard_validate_joblevel';
import wizard_validate_jobtitle from './wizard/wizard_validate_jobtitle';
import wizard_validate_costcenter from './wizard/wizard_validate_costcenter';
import import_cas from './company/import_cas';
import user_claim_detail_get_claim_picture from './user/user_claim_detail_get_claim_picture';
import user_claim_detail_cancel_apply_claim from './user/user_claim_detail_cancel_apply_claim';
import claim_detail_validate_user_apply_claim from './user/claim_detail_validate_user_apply_claim';
import claim_detail_user_apply_claim from './user/claim_detail_user_apply_claim';
import leave_calendar_get_self_available_leave_options from './user/leave_calendar_get_self_available_leave_options';
import leave_calendar_user_apply_leave_self from './user/leave_calendar_user_apply_leave_self';
import employee_bank_array_validate from './wizard_employee/employee_bank_array_validate';
import employee_bank_array_save from './wizard_employee/employee_bank_array_save';
import employee_relationship_array_validate from './wizard_employee/employee_relationship_array_validate';
import employee_relationship_array_save from './wizard_employee/employee_relationship_array_save';
import employee_child_array_validate from './wizard_employee/employee_child_array_validate';
import employee_child_array_save from './wizard_employee/employee_child_array_save';
import get_employee_role_list from './employee/get_employee_role_list';
import add_employee_role from './employee/add_employee_role';
import update_employee_role from './employee/update_employee_role';
import update_user_layout from './dashboard/update_user_layout';
import get_payslip_employee_contract from './payroll/get_payslip_employee_contract';
import update_payslip_bonus_validate from './payroll/update_payslip_bonus_validate';
import update_payslip_bonus from './payroll/update_payslip_bonus';
import get_employee_leave_scheme from './employee/get_employee_leave_scheme';
import payroll_basic_payout from './payroll/payroll_basic_payout';
import payroll_downloadbankpayment from './payroll/payroll_downloadbankpayment';
import get_employee_detail from './employee/get_employee_detail';
import create_claim_approval from './claim/create_claim_approval';
import create_claim_approval2 from './claim/create_claim_approval2';
import delete_holiday_list from './delete_holiday_list';
import create_holiday_list from './create_holiday_list';
import update_holiday_list from './update_holiday_list';
import get_approvals_claim_list from './claim/get_approvals_claim_list';
import claim_manager_approve from './approvals/claim_manager_approve';
import claim_approvals_get_claim_picture from './approvals/claim_approvals_get_claim_picture';
import get_user_manager_list from './approvals/get_user_manager_list';
import get_apply_leave_list2 from './leave/get_apply_leave_list2';
import get_apply_claim_list2 from './claim/get_apply_claim_list2';
import employeelist2_get_employe_picture from './employee/employeelist2_get_employee_picture';
import mass_assign_leave_scheme from './leave/mass_assign_leave_scheme';
import get_employee_education from './employee/get_employee_education';
import add_employee_education from './employee/add_employee_education';
import delete_employee_education from './employee/delete_employee_education';
import get_employee_education_picture from './employee/get_employee_education_picture';
import update_employee_education from './employee/update_employee_education';
import get_employee_certification from './employee/get_employee_certification';
import delete_employee_certification from './employee/delete_employee_certification';
import add_employee_certification from './employee/add_employee_certification';
import get_employee_certification_picture from './employee/get_employee_certification_picture';
import delete_employee_certification_picture from './employee/delete_employee_certification_picture';
import delete_employee_education_picture from './employee/delete_employee_education_picture';
import update_employee_certification from './employee/update_employee_certification';
import get_otp_list from './user/get_otp_list';
import get_otp_initial from './user/get_otp_initial';
import add_otp_list from './user/add_otp_list';
import otp_check from './otp_check';
import mass_assign_leave_group_create from './leave/mass_assign_leave_group_create';
import mass_assign_leave_group_validate from './leave/mass_assign_leave_group_validate';
import delete_otp_list from './user/delete_otp_list';
import add_leave_group_template from './leave/add_leave_group_template';
import get_leave_group_template from './leave/get_leave_group_template';
import get_claim_group_template from './claim/get_claim_group_template';
import add_claim_group_template from './claim/add_claim_group_template';
import update_general_logo from './update_general_logo';
import get_general_logo from './get_general_logo';
import update_general_letter from './update_general_letter';
import get_general_letter from './get_general_letter';
import update_self_password from './employee/update_self_password';
import delete_claim_mass_group_template from './claim/delete_claim_mass_group_template';
import delete_leave_mass_group_template from './leave/delete_leave_mass_group_template';
import update_payroll_update_time_validate from './payroll/update_payroll_update_time_validate';
import update_payroll_update_time from './payroll/update_payroll_update_time';
import create_task_list from './task/create_task_list';
import get_task_list from './task/get_task_list';
import mark_task from './task/mark_task';
import get_hr_employee_all_leave_detail from './employee/get_hr_employee_all_leave_detail'
import get_calendar_approvals_leave_detail from './approvals/get_calendar_approvals_leave_detail';
import emp_all_get_report_day from './employee/emp_all_get_report_day';
import report_get_report_day from './report/report_get_report_day';
import get_general_color from './get_general_color';
import update_general_color from './update_general_color';
import get_employee_delegation_list from './employee/get_employee_delegation_list';
import create_employee_delegation from './employee/create_employee_delegation';
import delete_employee_delegation_list from './employee/delete_employee_delegation_list';
import update_employee_delegation_list from './employee/update_employee_delegation_list';
import get_user_picture from './user/get_user_picture';
import create_leave_approval1 from './leave/create_leave_approval1';
import get_leave_approval from './leave/get_leave_approval';
import delete_leave_approval from './leave/delete_leave_approval';
import update_leave_approval from './leave/update_leave_approval';
import manager_leave_list from './leave/manager_leave_list';
import assign_leave_approval from './approvals/assign_leave_approval';
import get_leave_employee_noscheme from './leave/get_leave_employee_noscheme';
import calendar_approvals_get_leave_picture1 from './approvals/calendar_approvals_get_leave_picture1';
import get_calendar_approvals_leave_detail1 from './approvals/get_calendar_approvals_leave_detail1';
import leave_calendar_get_self_available_leave_options1 from './user/leave_calendar_get_self_available_leave_options1';
import leave_calendar_user_apply_leave_self1 from './user/leave_calendar_user_apply_leave_self1';
import leave_assign_scheme from './leave/leave_assign_scheme';
import get_user_leave_detail from './user/get_user_leave_detail';
import get_user_leave_detail2 from './user/get_user_leave_detail2';
import mass_assign_leave_approval from './approvals/mass_assign_leave_approval';
import leave_assign_scheme1 from './leave/leave_assign_scheme1';
import array_create_employee_passport from './employee/array_create_employee_passport';
import create_claim_approval1 from './claim/create_claim_approval1';
import get_claim_approval from './claim/get_claim_approval';
import delete_claim_approval from './claim/delete_claim_approval';
import update_claim_approval from './claim/update_claim_approval';
import assign_claim_approval from './approvals/assign_claim_approval';
import claim_assign_scheme1 from './claim/claim_assign_scheme1';
import mass_assign_claim_approval from './approvals/mass_assign_claim_approval';
import claim_assign_scheme from './claim/claim_assign_scheme';
import create_employee_passport1 from './employee/create_employee_passport1';
import leave_assign_scheme2 from './leave/leave_assign_scheme2';
import claim_assign_scheme2 from './claim/claim_assign_scheme2';
import claim_all_get_claim_detail from './claim/claim_all_get_claim_detail';
import claim_approvals_get_claim_detail from './approvals/claim_approvals_get_claim_detail';
import claim_detail_get_claim_detail from './user/claim_detail_get_claim_detail';
import employee_permit_array_save from './wizard_employee/employee_permit_array_save';
import get_employee_contract_list from './employee/get_employee_contract_list';
import get_claim_employee_noscheme from './claim/get_claim_employee_noscheme';
import get_appraisal_form_list from './appraisal/get_appraisal_form_list';
import create_appraisal_list from './appraisal/create_appraisal_list';
import delete_appraisal_list from './appraisal/delete_appraisal_list';
import get_appraisal_group_list from './appraisal/get_appraisal_group_list';
import create_appraisal_group from './appraisal/create_appraisal_group';
import delete_appraisal_group from './appraisal/delete_appraisal_group';
import submit_appraisal from './appraisal/submit_appraisal';
import get_otp_code_hr from './get_otp_code_hr.js';
import submit_user_newpass from './submit_user_newpass.js';
import reset_otp_password from './reset_otp_password.js';
import user_reset_password from './user_reset_password.js';
import mass_assign_leave_approval1 from './approvals/mass_assign_leave_approval1.js';
import mass_assign_claim_approval1 from './approvals/mass_assign_claim_approval1.js';
import create_general_aggrid_template from './report/create_general_aggrid_template.js';
import get_general_aggrid_template from './report/get_general_aggrid_template.js';
import delete_general_aggrid_template from './report/delete_general_aggrid_template.js';
import wizard_update_pay_period from './wizard/wizard_update_pay_period.js';
import wizard_create_holiday_list from './wizard/wizard_create_holiday_list.js';
import wizard_create_schedule from './wizard/wizard_create_schedule.js';
import claim_assign_scheme3 from './claim/claim_assign_scheme3.js';
import leave_assign_scheme3 from './leave/leave_assign_scheme3.js';
import change_emp_password from './employee/change_emp_password.js';
import user_get_all_apply_leave_list from './user/user_get_all_apply_leave_list.js';
import get_iras_list from './iras/get_iras_list.js';
import get_iras_detail from './iras/get_iras_detail.js';
import get_iras_login from './iras/get_iras_login.js';
import get_iras_nationality from './get_iras_nationality.js';
import submit_iras from './iras/submit_iras.js';
import auth1 from './auth1';
import get_otp_blacklist from './get_otp_blakclist.js';
import flush_otp_blacklist from './flush_otp_blacklist.js';
import remove_otp_blacklist from './remove_otp_blacklist.js';
import submit_cpf_test from './test/submit_cpf_test.js';
import get_key_events from './dashboard/get_key_events';
import get_employee_resign_today from './employee/get_employee_resign_today';
import submit_payroll_resign from './payroll/submit_payroll_resign.js';
import employee_payslip_date_filter from './report/employee_payslip_date_filter.js';
import get_year_leave_balance from './report/get_year_leave_balance.js';
import get_manager_appraisal_list from './appraisal/get_manager_appraisal_list.js';
import manager_submit_appraisal from './appraisal/manager_submit_appraisal.js';
import user_leave_all_apply_detail from './user/user_leave_all_apply_detail';
import monthly_report_date_filter2 from './report/monthly_report_date_filter2.js';
import get_employee_shg  from './employee/get_employee_shg.js';
import first_time_password from './first_time_password.js';
import create_company_bank  from './company/create_company_bank.js';
import get_company_bank from './company/get_company_bank.js';
import delete_company_bank from './company/delete_company_bank.js';
import get_payment_uob from './payroll/get_payment_uob.js';
import set_company_bank_default from './company/set_company_bank_default.js';
import update_employee_shg from './employee/update_employee_shg.js';
import get_employee_quick_view from './employee/get_employee_quick_view.js';
import payroll_variance_company_payroll from './report/payroll_variance_company_payroll';
import get_min_resign_date from './employee/get_min_resign_date';
import get_payroll_increment_list from './payroll/get_payroll_increment_list.js';
import get_payroll_increment_detail from './payroll/get_payroll_increment_detail.js';
import user_claim_approval_group from './user/user_claim_approval_group.js';
import user_leave_approval_group from './user/user_leave_approval_group.js';
import user_modal_cancel_claim from './user/user_modal_cancel_claim.js';
import get_employee_self_help from './employee/get_employee_self_help.js';
import update_employee_self_help from './employee/update_employee_self_help.js';
import get_employee_department from './employee/get_employee_department.js';

export default combineReducers({
    auth,
    add,
    auth1,
    company_list,
    create_joblevel,
    create_costcenter,
    create_department,
    create_jobtitle,
    create_section,
    delete_section,
    delete_joblevel,
    delete_department,
    delete_jobtitle,
    delete_costcenter,
    create_overtime,
    create_allowance,
    delete_allowance,
    delete_overtime,
    company_dropdown,
    sg_address,
    create_company,
    delete_schedule,
    schedule_list,
    create_schedule,
    schedule_dropdown,
    update_schedule,
    general_group,
    general_first_time,
    //claim
    get_claim_list,
    get_apply_claim_list,
    get_employee_list,
    dropdown_list,
    get_payroll_period,
    update_pay_period,
    delete_recurring,
    delete_flat_allowance,
    create_recurring,
    create_flat_allowance,
    get_employee_probation_list,
    approve_employee_probation,
    extend_employee_probation,
    get_holiday_list,
    get_leave_list,
    leave_hr_approve,
    delete_holiday,
    update_holiday,
    create_holiday,
    get_base_setting,
    update_general_setting,
    general_currency,
    delete_general_currency,
    create_general_currency,
    update_general_currency,
    get_contract_template_list,
    delete_contract_template,
    get_contract_template_dropdown,
    create_contract_department,
    create_contract_section,
    create_contract_job_title,
    create_contract_job_level,
    create_contract_cost_center,
    update_contract_template,
    create_contract_template,
    get_leave_option,
    update_leave_option,
    delete_leave,
    update_leave,
    create_leave,
    get_leave_scheme_list,
    delete_leave_scheme,
    create_leave_scheme,
    get_apply_leave_list,
    general_daterange,
    hr_calendar_array_approve_leave,
    hr_calendar_array_approve_leave_manager,
    get_claim_option,
    update_claim_option,
    delete_claim,
    update_claim,
    create_claim,
    get_claim_scheme_list,
    delete_claim_scheme,
    create_claim_scheme,
    get_pay_summary_list,
    get_employee_picture_list,
    get_employee_picture,
    get_latest_employee,
    get_branch_list,
    get_employee_postal_code,
    create_employee,
    create_new_employee_permit,
    get_nextprev_employee,
    get_employee_profile_picture,
    update_employee_profile_picture,
    get_employee_profile_picture_list,
    update_employee_profile,
    get_employee_address,
    create_employee_address,
    update_employee_address,
    get_employee_passport,
    delete_employee_passport,
    update_employee_passport,
    create_employee_passport,
    get_employee_bank,
    delete_employee_bank,
    create_employee_bank,
    update_employee_bank,
    setdefault_employee_bank,
    get_employee_contract_status,
    get_payroll_available_date,
    get_payroll_list,
    create_new_payroll,
    get_payroll_instance,
    payroll_detail_get_aggridheader,
    payroll_detail_update_payment_count,
    payroll_confirmation,
    payment_update_payroll_payment,
    payslip_update_payroll_count,
    create_payslip_backpay,
    create_payslip_bonus,
    delete_pay_component,
    regenerate_payslip,
    payslip_get_emp_picture,
    regenerate_payroll,
    monthly_report_get_aggridheader,
    monthly_payroll_instance,
    wizard_get_sg_postal,
    company_validate,
    wizard_complete,
    get_general_dashboard,
    delete_company,
    delete_branch,
    update_branch,
    branch_get_sg_postal,
    create_branch,
    update_company,
    update_employee_profile_picture2,
    resign_employee,
    employee_contract_additional_cost,
    employee_contract_costcenter,
    employee_contract_joblevel,
    employee_contract_jobtitle,
    employee_contract_section,
    employee_contract_department,
    get_employee_department,
    get_employee_relationship,
    update_employee_relationship,
    delete_employee_relationship,
    create_employee_relationship,
    get_employee_sg_child,
    delete_sg_child,
    create_employee_contract,
    payroll_variance_employee_payroll,
    payroll_variance_get_aggridheader,
    payroll_variance_get_aggridheader2,
    payroll_computation_payroll_instance,
    payroll_computation_get_aggridheader,
    headcount_get_aggridheader,
    headcount_payroll_instance,
    update_dashboard_widget,
    create_sg_child,
    get_employee_maternity_list,
    create_sg_maternity,
    create_sg_maternity_actual,
    get_all_employee_available_leave,
    employee_get_available_leave_options,
    employee_user_apply_leave,
    employee_award_leave,
    get_leave_award_list,
    create_contract_increment_pay,
    create_contract_increment_pay_validate,
    leave_reset_list,
    create_new_leave_reset,
    filter_leave_reset_list,
    create_leave_approval,
    employee_contract_costcenter1,
    employee_contract_joblevel1,
    employee_contract_jobtitle1,
    employee_contract_section1,
    employee_contract_department1,
    update_employee_contract,
    leave_table_hr_approve,
    get_all_employee_available_claim,
    get_medical_claim_autocomplete,
    employee_claim_apply_validate,
    employee_apply_claim,
    create_employee_unique_check,
    create_employee_array_validate,
    create_employee_array_create,
    validate_apply_leave_reset,
    apply_leave_reset,
    get_employee_permit,
    delete_employee_workpermit,
    create_employee_permit,
    update_employee_workpermit,
    get_employee_payroll,
    claim_all_get_claim_picture,
    array_reject_apply_claim,
    array_approve_apply_claim,
    validate_approve_apply_claim,
    reject_apply_claim,
    validate_claim_pay,
    get_pay_summary_detail,
    get_history_list,
    emp_all_array_approve_leave,
    get_payroll_history_list,
    save_contract_template,
    emp_detail_array_approve_leave,
    emp_detail_get_leave_picture,
    emp_all_array_approve_apply_claim,
    emp_all_array_reject_apply_claim,
    emp_all_reject_apply_claim,
    emp_all_validate_claim_pay,
    get_approvals_leave_list,
    calendar_leave_manager_approve,
    calendar_approvals_get_leave_picture,
    get_employee_list_withoutcontract,
    contract_contract_array_validate,
    contract_contract_array_save,
    update_resign,
    monthly_report_date_filter,
    wizard_create_company,
    wizard_validate_overtime,
    wizard_create_overtime,
    wizard_validate_allowance,
    wizard_create_allowance,
    wizard_create_recurring,
    wizard_validate_recurring,
    wizard_create_flat,
    wizard_validate_flat,
    wizard_create_department, 
    wizard_create_section, 
    wizard_create_joblevel, 
    wizard_create_jobtitle, 
    wizard_create_costcenter, 
    wizard_validate_department,
    wizard_validate_section, 
    wizard_validate_joblevel,
    wizard_validate_jobtitle,
    wizard_validate_costcenter,
    import_cas,
    add_employee_role,
    get_employee_detail,
    create_claim_approval,
    create_claim_approval2,
    //user
    user_self,
    user_get_available_leave,
    get_self_available_leave_options,
    user_apply_leave_self,
    user_get_available_claim,
    validate_user_apply_claim,
    user_apply_claim,
    get_self_payroll,
    user_apply_claim_list,
    payroll_update_rates,
    user_apply_leave_list,
    user_get_all_leave_list,
    get_leave_pending_color,
    user_holiday_list,
    user_leave_get_leave_picture,
    user_leave_cancel_apply_leave,
    user_claim_detail_get_claim_picture,
    user_claim_detail_cancel_apply_claim,
    claim_detail_validate_user_apply_claim,
    claim_detail_user_apply_claim,
    leave_calendar_get_self_available_leave_options,
    leave_calendar_user_apply_leave_self,
    employee_bank_array_validate,
    employee_bank_array_save,
    employee_relationship_array_save,
    employee_relationship_array_validate,
    employee_child_array_save,
    employee_child_array_validate,
    get_employee_role_list,
    update_employee_role,
    update_user_layout,
    get_payslip_employee_contract,
    update_payslip_bonus_validate,
    update_payslip_bonus,
    get_employee_leave_scheme,
    payroll_basic_payout,
    payroll_downloadbankpayment,
    delete_holiday_list,
    create_holiday_list,
    update_holiday_list,
    get_approvals_claim_list,
    claim_manager_approve,
    claim_approvals_get_claim_picture,
    get_user_manager_list,
    get_apply_leave_list2,
    get_apply_claim_list2,
    employeelist2_get_employe_picture,
    mass_assign_leave_scheme,
    get_employee_education,
    add_employee_education,
    delete_employee_education,
    get_employee_education_picture,
    update_employee_education,
    get_employee_certification,
    delete_employee_certification,
    add_employee_certification,
    get_employee_certification_picture,
    delete_employee_certification_picture,
    delete_employee_education_picture,
    update_employee_certification,
    get_otp_list,
    get_otp_initial,
    add_otp_list,
    otp_check,
    mass_assign_leave_group_validate,
    mass_assign_leave_group_create,
    delete_otp_list,
    add_leave_group_template,
    get_leave_group_template,
    get_claim_group_template,
    add_claim_group_template,
    update_general_logo,
    get_general_logo,
    update_general_letter,
    get_general_letter,
    update_self_password,
    delete_claim_mass_group_template,
    delete_leave_mass_group_template,
    update_payroll_update_time_validate,
    update_payroll_update_time,
    create_task_list,
    get_task_list,
    mark_task,
    get_hr_employee_all_leave_detail,
    get_calendar_approvals_leave_detail,
    emp_all_get_report_day,
    report_get_report_day,
    get_general_color,
    update_general_color,
    get_employee_delegation_list,
    create_employee_delegation,
    delete_employee_delegation_list,
    update_employee_delegation_list,
    get_user_picture,
    create_leave_approval1,
    get_leave_approval,
    delete_leave_approval,
    update_leave_approval,
    manager_leave_list,
    assign_leave_approval,
    get_leave_employee_noscheme,
    calendar_approvals_get_leave_picture1,
    get_calendar_approvals_leave_detail1,
    leave_calendar_get_self_available_leave_options1,
    leave_calendar_user_apply_leave_self1,
    leave_assign_scheme,
    get_user_leave_detail,
    get_user_leave_detail2,
    mass_assign_leave_approval,
    leave_assign_scheme1,
    array_create_employee_passport,
    create_claim_approval1,
    get_claim_approval,
    delete_claim_approval,
    update_claim_approval,
    assign_claim_approval,
    claim_assign_scheme1,
    claim_assign_scheme,
    mass_assign_claim_approval,
    create_employee_passport1,
    leave_assign_scheme2,
    claim_assign_scheme2,
    claim_all_get_claim_detail,
    claim_approvals_get_claim_detail,
    claim_detail_get_claim_detail,
    employee_permit_array_save,
    get_employee_contract_list,
    get_claim_employee_noscheme,
    get_appraisal_form_list,
    create_appraisal_list,
    delete_appraisal_list,
    get_appraisal_group_list,
    create_appraisal_group,
    delete_appraisal_group,
    submit_appraisal,
    get_otp_code_hr,
    submit_user_newpass,
    reset_otp_password,
    user_reset_password,
    mass_assign_leave_approval1,
    mass_assign_claim_approval1,
    create_general_aggrid_template,
    get_general_aggrid_template,
    delete_general_aggrid_template,
    wizard_update_pay_period,
    wizard_create_holiday_list,
    wizard_create_schedule,
    claim_assign_scheme3,
    leave_assign_scheme3,
    change_emp_password,
    user_get_all_apply_leave_list,
    get_iras_list,
    get_iras_detail,
    get_iras_nationality,
    get_iras_login,
    submit_iras,
    get_otp_blacklist,
    flush_otp_blacklist,
    remove_otp_blacklist,
    submit_cpf_test,
    get_key_events,
    get_employee_resign_today,
    submit_payroll_resign,
    employee_payslip_date_filter,
    get_year_leave_balance,
    get_manager_appraisal_list,
    manager_submit_appraisal,
    user_leave_all_apply_detail,
    monthly_report_date_filter2,
    get_employee_shg,
    first_time_password,
    create_company_bank,
    get_company_bank,
    delete_company_bank,
    get_payment_uob,
    set_company_bank_default,
    update_employee_shg,
    get_employee_quick_view,
    payroll_variance_company_payroll,
    get_min_resign_date,
    get_payroll_increment_list,
    get_payroll_increment_detail,
    user_claim_approval_group,
    user_leave_approval_group,
    user_modal_cancel_claim,
    get_employee_self_help,
    update_employee_self_help,
});



export const isAuthenticated = state => fromAuth.isAuthenticated(state.auth);
export const authErrors = state =>fromAuth.errors(state.auth);

