import * as sg_address from '../../action/company/branch_get_sg_postal';

const initialState={
    data:null,
    errors:null,
    isLoading:false,
    address:null
}

const sgaddress = (state=initialState,action)=>{
    switch(action.type){
        case sg_address.BRANCHSGADDDRESS_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
                address:null
            }
        case sg_address.BRANCHSGADDDRESS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                address:action.payload?.data?.results,
            }
        case sg_address.BRANCHSGADDDRESS_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload,
            }
        case sg_address.BRANCHSGADDDRESS_RESET:
            return{
                ...state,
                errors:null,
                data:null,
                address:null
            }
        default:
            return state;
    }
}

export default sgaddress;