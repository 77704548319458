import * as avaclaim from '../../action/employee/get_all_employee_available_claim';

const initialState={
    data:[],
    errors:[],
    isLoading:false,

}

const get_all_employee_available_claim = (state = initialState,action)=>{
    switch(action.type){
        case avaclaim.GETALLEMPAVACLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case avaclaim.GETALLEMPAVACLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case avaclaim.GETALLEMPAVACLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_all_employee_available_claim;


export const get_all_employee_available_claim_detail = (state,id,claim_type)=>{
    const { data } = state.get_all_employee_available_claim;

    return  data.length !== 0 && data.find((item)=>(item.employee === id && item.claim_type === claim_type));
}

export const emp_get_employee_available_claim = (state,id)=>{
    const { data } = state.get_all_employee_available_claim;

    return  data.length !== 0 && data.filter((item)=>(item.employee === id));
}
