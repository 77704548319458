import React, { useContext } from "react";
import { NewMainContext } from "../../context/NewMainContext";
import ClaimOfficerTabContent from "./tab_component/ClaimOfficerTabContent";
import HrTabContent from "./tab_component/HrTabContent";
import LeaveOfficerTabContent from "./tab_component/LeaveOfficerTabContent";
import UserTabContent from "./tab_component/UserTabContent";


export default function MainTabContent(){

    const {current_user} = useContext(NewMainContext);

    if(current_user === 'hr'){
        return(
            <HrTabContent/>
        )
    }
    else if(current_user === 'user' || current_user === 'manager'){
        return(
            <UserTabContent/>
        )
    }
    else if(current_user === 'claim_officer'){
        return(
            <ClaimOfficerTabContent/>
        )
    }
    else if(current_user === 'leave_officer'){
        return(
            <LeaveOfficerTabContent/>
        )
    }
    return null;

}