import * as contractjobtitle from '../../action/employee/employee_contract_jobtitle';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const employee_contract_jobtitle = (state=initialState,action)=>{
    switch(action.type){
        case contractjobtitle.EMPCONTRACTJOBTITLE_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case contractjobtitle.EMPCONTRACTJOBTITLE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractjobtitle.EMPCONTRACTJOBTITLE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_contract_jobtitle;