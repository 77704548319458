import * as emp2employeeimage from '../../action/employee/employeelist2_get_employee_picture';

const initialState={
    isLoading:false,
    data:[],
    errors:[],
    hello:'',
}

const employeelist2_get_employee_picture = (state=initialState,action)=>{
    switch(action.type){
        case emp2employeeimage.EMPLIST2GETEMPLOYEEPICTURE_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case emp2employeeimage.EMPLIST2GETEMPLOYEEPICTURE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                hello:action.payload,
                data:action.payload.data
            }
        case emp2employeeimage.EMPLIST2GETEMPLOYEEPICTURE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employeelist2_get_employee_picture;