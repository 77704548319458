import * as assignscheme3 from '../../action/leave/leave_assign_scheme3';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const leave_assign_scheme3 =(state=initialState,action)=>{
    switch(action.type){
        case assignscheme3.LEAVEASSIGNSCHEME3_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case assignscheme3.LEAVEASSIGNSCHEME3_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case assignscheme3.LEAVEASSIGNSCHEME3_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case assignscheme3.LEAVEASSIGNSCHEME3_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default leave_assign_scheme3;