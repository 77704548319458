import * as daterange from '../action/general_daterange';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}

const general_daterange = (state=initialState,action)=>{
    switch(action.type){
        case daterange.GENERALDATERANGE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case daterange.GENERALDATERANGE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case daterange.GENERALDATERANGE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default general_daterange;