import { surveyLocalization } from "survey-core";

export var italianSurveyStrings = {
  pagePrevText: "Precedente",
  pageNextText: "Successivo",
  completeText: "Salva",
  previewText: "Anteprima",
  editText: "Modifica",
  startSurveyText: "Inizio",
  otherItemText: "Altro (descrivi)",
  noneItemText: "Nessuno",
  selectAllItemText: "Seleziona tutti",
  progressText: "Pagina {0} di {1}",
  panelDynamicProgressText: "Record di {0} di {1}",
  questionsProgressText: "Risposte a {0}/{1} domande",
  emptySurvey: "Non ci sono pagine o domande visibili nel questionario.",
  completingSurvey: "Grazie per aver completato il questionario!",
  completingSurveyBefore:
    "I nostri records mostrano che hai già completato questo questionario.",
  loadingSurvey: "Caricamento del questionario in corso...",
  optionsCaption: "Scegli...",
  value: "valore",
  requiredError: "Campo obbligatorio",
  requiredErrorInPanel: "Per Favore, rispondi ad almeno una domanda.",
  requiredInAllRowsError: "Completare tutte le righe",
  numericError: "Il valore deve essere numerico",
  textMinLength: "Inserire almeno {0} caratteri",
  textMaxLength: "Lunghezza massima consentita {0} caratteri",
  textMinMaxLength:
    "Inserire una stringa con minimo {0} e massimo {1} caratteri",
  minRowCountError: "Completare almeno {0} righe.",
  minSelectError: "Selezionare almeno {0} varianti.",
  maxSelectError: "Selezionare massimo {0} varianti.",
  numericMinMax:
    "'{0}' deve essere uguale o superiore a {1} e uguale o inferiore a {2}",
  numericMin: "'{0}' deve essere uguale o superiore a {1}",
  numericMax: "'{0}' deve essere uguale o inferiore a {1}",
  invalidEmail: "Inserire indirizzo mail valido",
  invalidExpression: "L'espressione: {0} dovrebbe tornare 'vero'.",
  urlRequestError: "La richiesta ha risposto con un errore '{0}'. {1}",
  urlGetChoicesError:
    "La richiesta ha risposto null oppure il percorso non è corretto",
  exceedMaxSize: "Il file non può eccedere {0}",
  otherRequiredError: "Inserire il valore 'altro'",
  uploadingFile:
    "File in caricamento. Attendi alcuni secondi e riprova",
  loadingFile: "Caricamento...",
  chooseFile: "Selezionare file(s)...",
  fileDragAreaPlaceholder: "Trascina un file qui o fai clic sul pulsante in basso per caricare il file.",

  noFileChosen: "Nessun file selezionato",
  confirmDelete: "Sei sicuro di voler elminare il record?",
  keyDuplicationError: "Questo valore deve essere univoco.",
  addColumn: "Aggiungi colonna",
  addRow: "Aggiungi riga",
  removeRow: "Rimuovi riga",
  addPanel: "Aggiungi riga",
  removePanel: "Elimina",
  choices_Item: "Elemento",
  matrix_column: "Colonna",
  matrix_row: "Riga",
  savingData: "Salvataggio dati sul server...",
  savingDataError: "Si è verificato un errore e non è stato possibile salvare i risultati.",
  savingDataSuccess: "I risultati sono stati salvati con successo!",
  saveAgainButton: "Riprova",
  timerMin: "min",
  timerSec: "sec",
  timerSpentAll: "Hai impiegato {0} su questa pagina e {1} in totale.",
  timerSpentPage: "Hai impiegato {0} su questa pagina.",
  timerSpentSurvey: "Hai impiegato {0} in totale.",
  timerLimitAll:
    "Hai impiegato {0} di {1} su questa pagina e {2} di {3} in totale.",
  timerLimitPage: "Hai impiegato {0} di {1} su questa pagina.",
  timerLimitSurvey: "Hai impiegato {0} di {1} in totale.",
  cleanCaption: "Pulisci",
  clearCaption: "Cancella",
  chooseFileCaption: "Scegliere il file",
  removeFileCaption: "Rimuovere questo file",
  booleanCheckedLabel: "Sì",
  booleanUncheckedLabel: "No",
  confirmRemoveFile: "Sei sicuro di voler elminare questo file: {0}?",
  confirmRemoveAllFiles: "Sei sicuro di voler elminare tutti i files?",
  questionTitlePatternText: "Titolo della domanda",
  ratingOptionsCaption: "Tocca qui per valutare...",
  emptyRowsText: "Non ci sono righe.",
  filteredTextPlaceholder: "Digita per cercare...",
  indexText: "{0} da {1}",
  minError: "Il valore non deve essere inferiore a {0}",
  maxError: "Il valore non deve essere maggiore di {0}",
  modalApplyButtonText: "Applicare",
  modalCancelButtonText: "Annulla",
  multipletext_itemname: "testo",
  noEntriesText: "Non ci sono ancora voci.\nFai clic sul pulsante qui sotto per aggiungere una nuova voce.",
  signaturePlaceHolder: "Firmare qui",
};

surveyLocalization.locales["it"] = italianSurveyStrings;
surveyLocalization.localeNames["it"] = "italiano";
