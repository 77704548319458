import * as delcostcenter from '../action/delete_costcenter';

const initialState ={
    isLoading:false,
    errors:null,
    data:null
}


const delete_costcenter = (state=initialState,action) =>{
    switch(action.type) {
        case delcostcenter.DELCOSTCENTER_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case delcostcenter.DELCOSTCENTER_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case delcostcenter.DELCOSTCENTER_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case delcostcenter.DELCOSTCENTER_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default delete_costcenter;


