import * as contractdepartment from '../action/create_contract_department';

const initialstate={
    isLoading:false,
    errors:null,
    data:null
}

const create_contract_department =(state=initialstate,action)=>{
    switch(action.type){
        case contractdepartment.CREATECONTRACTDEPARTMENT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case contractdepartment.CREATECONTRACTDEPARTMENT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractdepartment.CREATECONTRACTDEPARTMENT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case contractdepartment.CREATECONTRACTDEPARTMENT_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default create_contract_department;