import * as validateuserapplyclaim from '../../action/user/claim_detail_validate_user_apply_claim';

const initialState={
    data:[],
    errors:[],
    isLoading:false
}

const claim_detail_validate_user_apply_claim = (state=initialState,action)=>{
    switch(action.type){
        case validateuserapplyclaim.CLAIMDETAILVALIDATEUSERAPPLYCLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case validateuserapplyclaim.CLAIMDETAILVALIDATEUSERAPPLYCLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case validateuserapplyclaim.CLAIMDETAILVALIDATEUSERAPPLYCLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default claim_detail_validate_user_apply_claim;