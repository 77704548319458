import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { getScheduleDropdown } from "../../action/schedule_dropdown";
import { IMLocalized } from "../../language/IMLocalized";
import ModalAddScheduleList from "./modal/ModalAddScheduleList";
import ModalEditScheduleList from "./modal/ModalEditScheduleList";


export default function ScheduleDetail({holiday_list,setData}){
    const dispatch = useDispatch()
    const [ list, setList ] = useState([]);
    const [ add_toggle, setAddToggle ] = useState(false);
    const [ edit_detail, setEditDetail] = useState(null);
    const [ edit_toggle, setEditToggle] = useState(false);
    const [ edit_index, setEditIndex] = useState(null);

    useEffect(()=>{
        dispatch(getScheduleDropdown())
    },[dispatch])

    const deleteList=(index)=>{
        const data = [...list];
        data.splice(index,1);
        setList(data);
    }

    const editList=(index)=>{
        const data = list[index];
        setEditDetail(data);
        setEditIndex(index);
        setEditToggle(true);
    }

    return(
        <>
        <Container className="mt-2">
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h4 className="mb-0">{IMLocalized('schedule_list')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm"  onClick={()=>setAddToggle(true)}>{IMLocalized('create')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        {list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {list.length !== 0 && 
                        <ListGroup className="list" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                            {list.map((item,index)=>{
                                const number = index +1;
                                return(
                                    <ListGroupItem className="py-2" key={index} >
                                        <Row className="align-items-center">
                                            <Col>
                                                <span>{number}. {item.name}</span>
                                            </Col>
                                            <Col className="col-auto mr-1">
                                                <button className="btn btn-info btn-sm" onClick={()=>editList(index)}>{IMLocalized('detail')}</button>
                                                <button className="btn btn-danger btn-sm" onClick={()=>deleteList(index)}>{IMLocalized('delete')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                                
                            })}
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
            <div className='create_btn_parent'>
                <i className='fas fa-arrow-circle-right text-success cursor-pointer create_btn' onClick={()=>setData(list)}  />
            </div>
        </Container>
        <ModalAddScheduleList toggle={add_toggle} holiday_list={holiday_list} closeModal={()=>setAddToggle(false)} addList={(item)=>{setList(prevState=>([...prevState,item])); setAddToggle(false)}} />
        <ModalEditScheduleList toggle={edit_toggle} holiday_list={holiday_list} closeModal={(e)=>{ setEditDetail(null); setEditToggle(false) }} detail={edit_detail} index={edit_index} list={list} updateList={(item)=>{ setList(item); setEditToggle(false) }} />
        </>
    )
}