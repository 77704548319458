import myaxios from '../../axios';

export const SUBMITIRAS_REQUEST = 'SUBMITIRAS_REQUEST';
export const SUBMITIRAS_SUCCESS = 'SUBMITIRAS_SUCCESS';
export const SUBMITIRAS_FAILURE = 'SUBMITIRAS_FAILURE';


export const submit_iras = (id)=>{
    return dispatch=>{
        dispatch({
            type:'SUBMITIRAS_REQUEST'
        });

        myaxios.post(`payroll/iras/${id}/send/`)
        .then((response)=>{
            dispatch({
                type:'SUBMITIRAS_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'SUBMITIRAS_FAILURE',
                payload:error
            })
        })
    }
}