import myaxios from '../../axios';

export const USERGETALLLEAVE_REQUEST = 'USERGETALLLEAVE_REQUEST';
export const USERGETALLLEAVE_SUCCESS = 'USERGETALLLEAVE_SUCCESS';
export const USERGETALLLEAVE_FAILURE = 'USERGETALLLEAVE_FAILURE';

export const user_get_all_leave_list=()=>{
    return dispatch=>{
        dispatch({
            type:'USERGETALLLEAVE_REQUEST'
        });

        myaxios.get('leave/self/apply/')
        .then((response)=>{
            dispatch({
                type:'USERGETALLLEAVE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERGETALLLEAVE_FAILURE',
                payload:error
            })
        })
    }
}