import React, { useContext } from 'react';
import classnames from 'classnames';
import { Container, Row, Col, Nav, NavItem , NavLink, TabContent, TabPane} from 'reactstrap';
import { IMLocalized } from '../../language/IMLocalized';
import DepartmentContent from './content/DepartmentContent';
import SectionContent from './content/SectionContent';
import JoblevelContent from './content/JoblevelContent';
import JobtitleContent from './content/JobTitleContent';
import CostcenterContent from './content/CostcenterContent';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

function AdditionalSetting(){

    const { addItem, cas_activeTab, setCasActiveTab } = useContext(NewMainContext);

    const setActiveTab=(index)=>{
        setCasActiveTab(index);
        localStorage.setItem('add_set',index);
    }

    return(

        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        {active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        {active: false, title:IMLocalized('company'), onClick:()=>addItem('2','company_list')},
                        {active: true, title:IMLocalized('additional_setting')}
                    ]}/>
                </Col>
            </Row>
        </Container>
        <Container className='mt-2'>
            <div className='d-flex justify-content-between'>
                <h3 className='mb-0'>{IMLocalized('company_additional_setting')}</h3>
                <button className="btn btn-info btn-sm" onClick={()=>addItem('5','overtime_allowance')}>{IMLocalized('overtime_allowance')}</button>
            </div>
            <Nav tabs>
                <NavItem className="cursor-pointer">
                    <NavLink className={classnames({active:cas_activeTab === '1'})} onClick={()=>setActiveTab('1')}>
                        {IMLocalized('department')}
                    </NavLink>
                </NavItem>
                <NavItem className="cursor-pointer">
                    <NavLink className={classnames({active:cas_activeTab === '2'})} onClick={()=>setActiveTab('2')}>
                        {IMLocalized('section')}
                    </NavLink>
                </NavItem>
                <NavItem className="cursor-pointer">
                    <NavLink className={classnames({active:cas_activeTab === '3'})} onClick={()=>setActiveTab('3')}>
                    {IMLocalized('job_level')}
                    </NavLink>
                </NavItem>
                <NavItem className="cursor-pointer">
                    <NavLink className={classnames({active:cas_activeTab === '4'})} onClick={()=>setActiveTab('4')}>
                    {IMLocalized('job_title')}
                    </NavLink>
                </NavItem>
                <NavItem className="cursor-pointer">
                    <NavLink className={classnames({active:cas_activeTab === '5'})} onClick={()=>setActiveTab('5')}>
                    {IMLocalized('cost_center')}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={cas_activeTab}>
                <TabPane tabId="1">
                    <DepartmentContent/>
                </TabPane>
                <TabPane tabId="2">
                    <SectionContent/>
                </TabPane>
                <TabPane tabId="3">
                    <JoblevelContent/>
                </TabPane>
                <TabPane tabId="4">
                    <JobtitleContent/>
                </TabPane>
                <TabPane tabId="5">
                    <CostcenterContent/>
                </TabPane>
            </TabContent>
        </Container>
        </>
    )
}
export default AdditionalSetting;