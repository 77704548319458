import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { get_all_employee_available_leave } from "../../../action/employee/get_all_employee_available_leave";
import { get_employee_list } from "../../../action/employee/get_employee_list";
import { leave_assign_scheme3, reset_leave_assign_scheme3 } from "../../../action/leave/leave_assign_scheme3";
import { popError } from "../../../func/popError";
import { IMLocalized } from "../../../language/IMLocalized";

export default function ModalAssignLeaveMassScheme1({ toggle, closeModal, selected_data, onSuccess }){

    const daterange = useSelector(state=>state.general_daterange.data);
    const schemelist = useSelector(state=>state.get_leave_scheme_list.data);
    const isLoading = useSelector(state=>state.leave_assign_scheme3.isLoading);
    const success = useSelector(state=>state.leave_assign_scheme3.data);
    const errors = useSelector(state=>state.leave_assign_scheme3.errors);

    const [ leave_scheme, setLeaveScheme ] = useState('');
    const [ err_leave_scheme, setErrLeaveScheme] = useState('');
    const [ year, setYear] = useState('');
    const [ err_year, setErrYear] = useState('');
    const [ full_proration, setFullProration] = useState('');
    const dispatch = useDispatch(); 

    useEffect(()=>{
        if(errors != null){
            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
            dispatch(reset_leave_assign_scheme3());
        }
    },[errors, dispatch])

    useEffect(()=>{
        if(success != null){
            dispatch(get_employee_list());
            dispatch(get_all_employee_available_leave());
            dispatch(reset_leave_assign_scheme3());
            onSuccess();
        }
    },[success,onSuccess,dispatch])

    useEffect(()=>{
        if(toggle){
            setFullProration(false);
            setErrYear('');
            setErrLeaveScheme('');
            setLeaveScheme('');
            if(daterange.length !== 0){
                const {date_range} = daterange;
                setYear(date_range?.leave?.year ?? '');
            }
        }
    },[toggle,daterange])

    const onAssign=()=>{
        let valid = false;
        const date_reg = /^(19[5-9]\d|20[0-4]\d|2050)$/;
        if(year === ''){
            setErrYear(IMLocalized('year_is_required'));
        }
        else if(!(date_reg.test(year))){
            setErrYear(IMLocalized('invalid_year'));
        }
        else if((date_reg.test(year))){
            valid = true;
        }

        if(valid && leave_scheme !== ''){
            dispatch(leave_assign_scheme3(leave_scheme,selected_data,full_proration,year));
        }
        else{
            if(leave_scheme === ''){
                setErrLeaveScheme(IMLocalized('leave_scheme_is_required'));
            }
        }
    }

    return(
        <Modal isOpen={toggle} size="md">
            <ModalHeader toggle={closeModal}>{IMLocalized('select_leave_scheme')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="assign_leave_mass_scheme">{IMLocalized('leave_scheme')}</label>
                            <Input type="select" size="sm" value={leave_scheme} id="assign_leave_mass_scheme" invalid={err_leave_scheme.length > 0} onChange={(e)=>{
                                const value = e.target.value;
                                setLeaveScheme(value);
                                setErrLeaveScheme('');
                            }}>
                                <option></option>
                                {schemelist.map((item)=>{
                                    return <option value={item.id} key={item.id}>{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_leave_scheme}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="assign_leave_mass_scheme_year">{IMLocalized('year')}</label>
                            <Input type="text" id="assign_leave_mass_scheme_year" size="sm" value={year} invalid={err_year.length >0} onChange={(e)=>{
                                const value = e.target.value;
                                setYear(value);
                                setErrYear('');
                            }}  />
                            <FormFeedback>{err_year}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id={'modal_assign_leave_mass_scheme1_full'} name="full_proration" className="custom-control-input" checked={full_proration} onChange={(e)=>setFullProration(e.target.checked)} />
                            <label className="custom-control-label" htmlFor={'modal_assign_leave_mass_scheme1_full'}>{IMLocalized('full_proration')}</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ?<button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> :
                <>
                <button className="btn btn-primary btn-sm" onClick={onAssign}>{IMLocalized('assign')}</button>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}

