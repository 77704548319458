import React,{useEffect, useState} from 'react';
import { DropdownItem } from 'reactstrap';
import { useDispatch, useSelector} from 'react-redux';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';
import moment from 'moment';
import { IMLocalized } from '../../language/IMLocalized';
import { get_employee_role_list} from '../../action/employee/get_employee_role_list';
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';

function EmployeeRoleReport(){
    const dispatch = useDispatch();
    const list = useSelector(state=>state.get_employee_role_list.data);
    const isLoading = useSelector(state=>state.get_employee_role_list.isLoading);
    const [count,setCount] = useState(0);


    useEffect(()=>{
        if(!isLoading && count !== 0){
            const arr = list.map((item)=>{
                const role_hr = item.roles.includes('role_hr');
                const role_claim_officer = item.roles.includes('role_claim_officer');
                const role_leave_officer = item.roles.includes('role_leave_officer');
                const role_manager = item.roles.includes('role_manager');

                return {
                    employee_number:item.employee_number,
                    name:item.name,
                    role_claim_officer,
                    role_hr,
                    role_leave_officer,
                    role_manager
                }
            })

            if(arr.length !== 0 ){
                const wb = new ExcelJs.Workbook();
                const ws = wb.addWorksheet('Employee Role Report');
                const today = moment().format('DD/MM/YYYY');
                const time = moment().format('HH:mm:ss');
                const day =moment().format("DD");
                const month =moment().format("MM");

                const title_name = `EmployeeRoleReport_${day}-${month}`;
                ws.getCell('A1').value = 'Employee Role Report';
                ws.getCell('A2').value = `Printed On : ${today} ${time}`;
                const column = [
                    {header:'Emp ID', key:'employee_number'},
                    {header:'Name', key:'name'},
                    {header:'Role Hr',key:'role_hr'},
                    {header:'Role Manager',key:'role_manager'},
                    {header:'Role Leave Officer',key:'role_leave_officer'},
                    {header:'Role Claim Officer',key:'role_claim_officer'}
                ]

                ws.columns=[
                    {width:15},
                    {width:20},
                    {width:20},
                    {width:20},
                    {width:20},
                    {width:20},
                ]

                for(let j=0;j<column.length;j++){
                    const headerName = column[j].header;
                    const field = (column[j].key);
                    ws.getCell(getColumnName(j)+'5').value = headerName;
                    for(let x=0;x<arr.length;x++){
                        const step = 6+x;
                        const item = getValue(arr[x],field);
                        if(item !== null && item !== '' && item !== undefined){
                            if(item === true){
                                ws.getCell(getColumnName(j)+step).value = 'Yes';
                            }
                            else if (item === false){
                                ws.getCell(getColumnName(j)+step).value = '-';
                            }
                            else{
                                ws.getCell(getColumnName(j)+step).value = item;
                            }
                        }
                        else{
                            ws.getCell(getColumnName(j)+step).value ='-';
                        }
                    }
                }
                
                const file_name = `${title_name}.xlsx`;
                wb.xlsx.writeBuffer().then((buf)=>{
                    var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                    FileSaver.saveAs(file, file_name)
                });
                setCount(0);
            }
        }
    },[list,isLoading,count])

    return(
        <DropdownItem className="cursor-pointer" 
        onClick={isLoading || count !== 0 ? null : ()=>{
            dispatch(get_employee_role_list());
            setCount(count+1);
        }}
        >
            {isLoading || count !== 0 ? `${IMLocalized('loading')}..` : IMLocalized('employee_role_report')}
        </DropdownItem>
    )
}
export default EmployeeRoleReport;