import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATEDEPARTMENT_SUCCESS = 'CREATEDEPARTMENT_SUCCESS';
export const CREATEDEPARTMENT_REQUEST = 'CREATEDEPARTMENT_REQUEST';
export const CREATEDEPARTMENT_FAILURE = 'CREATEDEPARTMENT_FAILURE';
export const CREATEDEPARTMENT_RESET = 'CREATEDEPARTMENT_RESET';

export const create_department = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATEDEPARTMENT_REQUEST'
        });
        
        myaxios.post(`company/department/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'CREATEDEPARTMENT_SUCCESS',
                payload:response
                
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            })
        })
        .catch((error) =>{
            dispatch({
                type: 'CREATEDEPARTMENT_FAILURE',
                payload: error,
                error:true
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_success')
            })
        })
    }
}

export const reset_create_department = () => dispatch =>{
    dispatch({
        type:'CREATEDEPARTMENT_RESET'
    });
}
