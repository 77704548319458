import * as delcertpic from '../../action/employee/delete_employee_certification_picture';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const delete_employee_certification_picture= (state= initialState,action)=>{
    switch(action.type){
        case delcertpic.DELEMPCERTPIC_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case delcertpic.DELEMPCERTPIC_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delcertpic.DELEMPCERTPIC_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default delete_employee_certification_picture;