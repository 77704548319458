import React from "react";
import { Table } from "reactstrap";
import { IMLocalized } from "../../../../language/IMLocalized";



export default function CertificationTable({ list, toView, editCertification, deleteCert }){

    return(
        <Table size="sm" className="mt-2 align-items-center table-bordered" responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th className="w-50">{IMLocalized('name')}</th>
                    <th className="w-25">{IMLocalized('edit')} / {IMLocalized('delete')}</th>
                </tr>
            </thead>
            <tbody className="text-center">
                {list.map((item)=>{
                    return(
                        <tr key={item.id}>
                            <td>
                                <span className="text-primary cursor-pointer" onClick={()=>toView(item.id)}>{item.name}</span>
                            </td>
                            <td align="center" valign="center" >
                                <div className='d-flex justify-content-center'>
                                    <div className="primary_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-pen text-primary cursor-pointer" onClick={()=>editCertification(item.id)}/>
                                    </div>
                                    <div className="delete_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-trash text-red cursor-pointer" onClick={()=>deleteCert(item.id)} />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}