import myaxios from '../../axios';

export const PAYROLLLIST_REQUEST = 'PAYROLLLIST_FAILURE';
export const PAYROLLLIST_SUCCESS = 'PAYROLLLIST_SUCCESS';
export const PAYROLLLIST_FAILURE = 'PAYROLLLIST_FAILURE';

export const get_payroll_list = ()=>{
    return dispatch=>{
        dispatch({
            type: 'PAYROLLLIST_REQUEST'
        });
        myaxios.get('payroll/payroll/')
        .then((response)=>{
            dispatch({
                type:'PAYROLLLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'PAYROLLLIST_FAILURE',
                payload:error
            })
        })
    }
}