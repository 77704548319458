import myaxios from '../../axios';

export const GETEMPPAYROLL_REQUEST = 'GETEMPPAYROLL_REQUEST';
export const GETEMPPAYROLL_SUCCESS = 'GETEMPPAYROLL_SUCCESS';
export const GETEMPPAYROLL_FAILURE = 'GETEMPPAYROLL_FAILURE';

export const get_employee_payroll =(id)=>{
    return dispatch=>{
        dispatch({
            type:'GETEMPPAYROLL_REQUEST'
        });

        myaxios.get(`payroll/employee/${id}/`)
        .then((response)=>{
            dispatch({
                type:'GETEMPPAYROLL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPPAYROLL_FAILURE',
                payload:error
            })
        })
    }
}