import myaxios from '../../axios';

export const CALENDARAPPROVALSGETLEAVEPICTURE_REQUEST = 'CALENDARAPPROVALSGETLEAVEPICTURE_REQUEST';
export const CALENDARAPPROVALSGETLEAVEPICTURE_SUCCESS = 'CALENDARAPPROVALSGETLEAVEPICTURE_SUCCESS';
export const CALENDARAPPROVALSGETLEAVEPICTURE_FAILURE = 'CALENDARAPPROVALSGETLEAVEPICTURE_FAILURE';

export const calendar_approvals_get_leave_picture =(id)=>{
    return dispatch=>{
        dispatch({
            type:'CALENDARAPPROVALSGETLEAVEPICTURE_REQUEST'
        });

        myaxios.get(`ob/leave/${id}/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'CALENDARAPPROVALSGETLEAVEPICTURE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'CALENDARAPPROVALSGETLEAVEPICTURE_FAILURE',
                payload:error
            })
        })
    }
}