import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELETEOTPLIST_REQUEST = 'DELETEOTPLIST_REQUEST';
export const DELETEOTPLIST_SUCCESS = 'DELETEOTPLIST_SUCCESS';
export const DELETEOTPLIST_FAILURE = 'DELETEOTPLIST_FAILURE';

export const delete_otp_list = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELETEOTPLIST_REQUEST'
        });

        myaxios.post('otp/delete/',{
            id:id
        })
        .then((response)=>{
            dispatch({
                type:'DELETEOTPLIST_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELETEOTPLIST_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('delete_failure')
            });
        })
    }
}