import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CLAIMAPPROVAL_REQUEST = 'CLAIMAPPROVAL_REQUEST';
export const CLAIMAPPROVAL_SUCCESS = 'CLAIMAPPROVAL_SUCCESS';
export const CLAIMAPPROVAL_FAILURE = 'CLAIMAPPROVAL_FAILURE';


export const create_claim_approval=(data)=>{
    return dispatch=>{
        dispatch({
            type:'CLAIMAPPROVAL_REQUEST'
        });

        return myaxios.post('employee/approval/create/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'CLAIMAPPROVAL_SUCCESS',
                payload:response
            });

            Toast.fire({
                type:'success',
                title:IMLocalized('create_success')
            });

        })
        .catch((error)=>{
            dispatch({
                type:'CLAIMAPPROVAL_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}