import * as downloadbank from '../../action/payroll/payroll_downloadbankpayment';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const payroll_downloadbankpayment =(state = initialState,action)=>{
    switch(action.type){
        case downloadbank.DOWNLOADBANKPAYMENT_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case downloadbank.DOWNLOADBANKPAYMENT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case downloadbank.DOWNLOADBANKPAYMENT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default payroll_downloadbankpayment;