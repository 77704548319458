import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELCONTRACTTEMPLATE_REQUEST = 'DELCONTRACTTEMPLATE_REQUEST';
export const DELCONTRACTTEMPLATE_SUCCESS = 'DELCONTRACTTEMPLATE_SUCCESS';
export const DELCONTRACTTEMPLATE_FAILURE = 'DELCONTRACTTEMPLATE_FAILURE';
export const DELCONTRACTTEMPLATE_RESET = 'DELCONTRACTTEMPLATE_RESET';

export const delete_contract_template = (id)=>{
    return dispatch=>{
        dispatch({
            type: 'DELCONTRACTTEMPLATE_REQUEST'
        });
        
        myaxios.delete(`contract/template/contracts/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELCONTRACTTEMPLATE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title:IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELCONTRACTTEMPLATE_FAILURE',
                payload:error,
                error:true
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_contract_template = ()=> dispatch =>{
    dispatch({
        type:'DELCONTRACTTEMPLATE_RESET'
    });
}