import * as applyleavelist from '../action/get_apply_leave_list';

const initialState={
    isLoading:false,
    errors:[],
    data:[]
}

const get_apply_leave_list = (state=initialState,action)=>{
    switch(action.type){
        case applyleavelist.GETAPPLYLEAVELIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[],
                errors:[]
            }
        case applyleavelist.GETAPPLYLEAVELIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case applyleavelist.GETAPPLYLEAVELIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            // console.log(state)
            return state;
    }
}
export default get_apply_leave_list;


export const emp_get_apply_leave_list = (state,id)=>{
    const {data} = state.get_apply_leave_list;

    return id != null ? data.filter(item=>item.employee.id === id) : [];
}