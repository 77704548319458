import * as deleteempbank from '../../action/employee/delete_employee_bank';


const initialState={
    isLoading:false,
    data:null,
    errors:null,
}

const delete_employee_bank = (state=initialState,action)=>{
    switch(action.type){
        case deleteempbank.DELEMPLOYEEBANK_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case deleteempbank.DELEMPLOYEEBANK_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case deleteempbank.DELEMPLOYEEBANK_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case deleteempbank.DELEMPLOYEEBANK_RESET:
            return{
                ...state,
                isLoading:false,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default delete_employee_bank;