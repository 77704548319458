import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Container,NavLink, Input, Nav, NavItem, Row, TabContent, UncontrolledTooltip,TabPane } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";
import {get_nextprev_employee} from '../../../action/employee/get_nextprev_employee';
import {get_employee_detail, reset_get_employee_detail} from '../../../action/employee/get_employee_detail';
import moment from "moment";
import Select from 'react-select';
import '../../../css/employee_profile.css';
import '../../../css/button.css';
import {get_employee_profile_picture} from '../../../action/employee/get_employee_profile_picture';
import { usePrevious } from "../../../hook/usePrevious";
import blank_picture from '../../../images/blank_picture.png';
import { Buffer } from "buffer";
import { getDateFormat } from "../../../func/getDateFormat";
import classnames from 'classnames';
import Profile from './EmployeeProfile';
import Contract from './EmployeeContract';
import Family from './EmployeeFamily';
import Bank from './EmployeeBank';
import Leave from './EmployeeLeave';
import Claim from './EmployeeClaim';
import Permit from './EmployeePermit';
import Payroll from './EmployeePayroll';
import Education from './EmployeeEducation';
import Certification from './EmployeeCertification';
import ModalResign from "./modal/ModalResign";
import { get_employee_list } from "../../../action/employee/get_employee_list";
import { get_employee_resign_today } from "../../../action/employee/get_employee_resign_today";
import ModalAssignRole from "./modal/ModalAssignRole";
import { get_employee_role_list } from "../../../action/employee/get_employee_role_list";
import { get_user_manager_list } from "../../../action/approvals/get_user_manager_list";
import ModalAssignLeaveApproval from "./modal/ModalAssignLeaveApproval";
import { get_leave_employee_noscheme } from "../../../action/leave/get_leave_employee_noscheme";
import ModalAssignClaimApproval from "./modal/ModalAssignClaimApproval";
import { get_claim_employee_noscheme } from "../../../action/claim/get_claim_employee_noscheme";
import ModalUpdatePassword from "./modal/ModalUpdatePassword";
import { update_employee_profile_picture } from "../../../action/employee/update_employee_profile_picture";
import { update_employee_profile_picture2 } from "../../../action/employee/update_employee_profile_picture2";
import {get_employee_profile_picture_list} from '../../../action/employee/get_employee_profile_picture_list';
import {get_user_picture} from '../../../action/user/get_user_picture';
import OverlayLoading from "../../loading_component/overlay_loading";
import { NewMainContext } from "../../../context/NewMainContext";
import BreadCrumbList from "../../breadcrumb/BreadCrumbList";

const mapStateToProps=(state)=>({
    emplist:state.get_employee_list.data,
    emp_data:state.get_employee_detail.data,
    nexturl:state.get_nextprev_employee.nextid,
    prevurl:state.get_nextprev_employee.previd,
    nextname:state.get_nextprev_employee.nextname,
    prevname:state.get_nextprev_employee.prevname,
    searchlist:state.get_nextprev_employee.searchlist,
    picturelist:state.get_employee_profile_picture_list.data,
    picture1:state.get_employee_profile_picture.data,
    update_picture_success:state.update_employee_profile_picture.data,
    update_picture2_success:state.update_employee_profile_picture2.data,
    isLoading:state.get_employee_list.isLoading,
    isLoading1:state.get_nextprev_employee.isLoading,
    isLoading2:state.get_employee_detail.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    get_employee_detail:(id)=>{
        dispatch(get_employee_detail(id))
    },
    get_nextprev_employee:(id)=>{
        dispatch(get_nextprev_employee(id))
    },
    get_employee_profile_picture:(id)=>{
        dispatch(get_employee_profile_picture(id))
    },
    get_employee_list:()=>{
        dispatch(get_employee_list())
    },
    get_employee_resign_today:()=>{
        dispatch(get_employee_resign_today())
    },
    get_employee_role_list:()=>{
        dispatch(get_employee_role_list())
    },
    get_user_manager_list:()=>{
        dispatch(get_user_manager_list())
    },
    get_leave_employee_noscheme:()=>{
        dispatch(get_leave_employee_noscheme())
    },
    get_claim_employee_noscheme:()=>{
        dispatch(get_claim_employee_noscheme())
    },
    update_employee_profile_picture:(id,data)=>{
        dispatch(update_employee_profile_picture(id,data));
    },
    update_employee_profile_picture2:(id,data)=>{
        dispatch(update_employee_profile_picture2(id,data));
    },
    get_employee_profile_picture_list:()=>{
        dispatch(get_employee_profile_picture_list())
    },
    get_user_picture:()=>{
        dispatch(get_user_picture())
    },
    reset_get_employee_detail:()=>{
        dispatch(reset_get_employee_detail())
    }
})

function EmployeeProfile(props){
    const {get_nextprev_employee,getEmployeeInfo,get_employee_detail,picture1,get_employee_profile_picture,update_picture2_success,update_picture_success,get_employee_profile_picture_list,get_user_picture}  = props;
    const { setEmployeeListStep, addItem, emp_step, setEmpStep } = useContext(NewMainContext);
    const [emp_name,setEmpName] = useState(''); 
    const [id,setID] = useState('');
    const [emp_number,setEmpNumber] = useState('');
    const [nationality,setNationality] = useState('');
    const [is_pr,setPr] = useState(false);
    const [join_date,setJoinDate] = useState('');
    const [contact_number,setContactNumber] = useState('');
    const [basic_pay ,setBasicPay] = useState(0);
    const [leave_group,setLeaveGroup] = useState(null);
    const [claim_group,setClaimGroup] = useState(null);
    const [is_active,setIsActive] = useState(false);
    const [resign,setResign] = useState(null);
    const [today_birthday,setTodayBirth] = useState(false);
    const [upcoming_birthday,setUpcomingBirth] = useState(false);
    const [picture,setPicture] = useState(null);
    const [emp_user,setEmpUser] = useState(null);
    const [department,setDepartment] = useState(null);
    const [job_title,setJobTitle] = useState(null);
    const [job_level,setJobLevel] = useState(null);
    const [resign_toggle,setResignToggle] = useState(false);
    const [assign_toggle,setAssignToggle] = useState(false);
    const [assign_leave_toggle,setAssignLeaveToggle] = useState(false);
    const [assign_claim_toggle,setAssignClaimToggle] = useState(false);
    const [password_toggle,setPasswordToggle] = useState(false);

    const prevPicture = usePrevious(picture1);
    const prevUpload = usePrevious(update_picture_success);
    const prevUpload2 = usePrevious(update_picture2_success);
    const prevID = usePrevious(id);

    useEffect(()=>{
        setID(props.id);
    },[props.id])

    useEffect(()=>{
        if( id != null &&id !== '' && prevID !== id){
            get_nextprev_employee(id);
            getEmployeeInfo(id);
            get_employee_detail(id);
        }
    },[prevID,id,get_employee_detail,get_nextprev_employee,getEmployeeInfo])

    useEffect(()=>{
        if(props.emp_data != null ){
            const {hr_cache} = props.emp_data;
            if(hr_cache !== null){
                setBasicPay(hr_cache.pay ? hr_cache.pay : 0);
            }
            else{
                setBasicPay(0);
            }

            if(emp_step === '8'){
                if(props.emp_data.nationality === 'SG'){
                    setEmpStep('1');
                    localStorage.setItem('emp_step','1');
                }
                else{
                    if(props.emp_data.is_pr === true){
                        localStorage.setItem('emp_step','1');
                        setEmpStep('1');
                    }
                }
            }

            if('lag' in props.emp_data.metadata){
                const lag = props.emp_data.metadata['lag'];
                if(lag !== null){
                    setLeaveGroup(lag.name);
                }
                else{
                    setLeaveGroup(null);
                }
            }

            if('cag' in props.emp_data.metadata){
                const cag = props.emp_data.metadata['cag'];
                if(cag !== null){
                    setClaimGroup(cag.name);
                }
                else{
                    setClaimGroup(null);
                }
            }

            setIsActive(props.emp_data.active);
            if('resign' in props.emp_data){
                setResign(props.emp_data.resign);
            }
            else{
                setResign(null);
            }

            setEmpUser(props.emp_data.user);
            setDepartment(props.emp_data.contract_cache? props.emp_data.contract_cache.department : null);
            setJobLevel(props.emp_data.contract_cache ? props.emp_data.contract_cache.job_level : null);
            setJobTitle(props.emp_data.contract_cache ? props.emp_data.contract_cache.job_title : null);

            if(props.emp_data.date_of_birth !== null){
                const birth_date = new Date(props.emp_data.date_of_birth);
                const birth_month = birth_date.getMonth();
                const birth_day = birth_date.getDate();

                const date1 = moment();
                const new_date = date1.format('YYYY-MM-DD');
                const date1_date = new Date(new_date);
                const date1_month = date1_date.getMonth();
                const date1_day = date1_date.getDate();

                const end_date = moment(new_date,'YYYY-MM-DD').add(1,'M').endOf('month');
                const date2 = end_date.format('YYYY-MM-DD');
                const date2_date = new Date(date2);
                const date2_month = date2_date.getMonth();

                if(birth_month === date1_month || birth_month === date2_month){
                    if(birth_month === date1_month){
                        if(birth_day === date1_day){
                            setTodayBirth(true);
                            setUpcomingBirth(false);
                        }
                        else if (birth_day > date1_day){
                            setTodayBirth(false);
                            setUpcomingBirth(true);
                        }
                        else{
                            setTodayBirth(false);
                            setUpcomingBirth(false);
                        }
                    }
                    else{
                        setTodayBirth(false);
                        setUpcomingBirth(true);
                    }
                }
                else{
                    setTodayBirth(false);
                    setUpcomingBirth(false);
                }
            }

            setEmpName(props.emp_data.name);
            setEmpNumber(props.emp_data.employee_number);
            setContactNumber(props.emp_data.contact_number);
            setNationality(props.emp_data.nationality);
            setPr(props.emp_data.is_pr);
            setJoinDate(props.emp_data.join_date);
        }

    },[props.emp_data,emp_step,setEmpStep])

    useEffect(()=>{
        if(props.picturelist.length !== 0 ){
            const data = props.picturelist.find((item)=>item.employee === parseInt(id));
            if(data){
                get_employee_profile_picture(id);
            }
        }
        setPicture(null);
    },[props.picturelist,id,get_employee_profile_picture])

    useEffect(()=>{
        if(prevPicture !== undefined && prevPicture !== picture1){

            const img = new Buffer.from(picture1,'binary').toString('base64');
            const src = `data:image/png;base64,${img}`;
            setPicture(src);
        }
    },[prevPicture,picture1])

    useEffect(()=>{
        if(prevUpload !== undefined && prevUpload !== update_picture_success && update_picture_success !== null){
            get_employee_profile_picture_list();
            get_user_picture();
        }
    },[prevUpload,update_picture_success,get_employee_profile_picture_list,get_user_picture])

    useEffect(()=>{
        if(prevUpload2 !== undefined && prevUpload2 !== update_picture2_success && update_picture2_success !== null){
            get_employee_profile_picture_list();
            get_user_picture();
        }
    },[prevUpload2,update_picture2_success,get_employee_profile_picture_list,get_user_picture])


    let searchlist = props.searchlist.sort((a,b) =>a.number - b.number);

    return(
        <>
            {props.isLoading || props.isLoading1 || props.isLoading2 ? <OverlayLoading /> :
            <>
            {props.emp_data != null &&
            <>
            <Container fluid>
                <Row className="border-bottom bg-white">
                    <Col className="p-2">
                        <BreadCrumbList list={[
                            { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                            { active: false, title:IMLocalized('employee'), onClick:()=>setEmployeeListStep('0')},
                            { active: true, title:emp_name}
                        ]}/>
                        <Row>
                            <Col lg="2">
                                <button className="btn btn-primary btn-sm ml-2" onClick={()=>{props.toEmployeeList('0'); props.reset_get_employee_detail() }}><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                            </Col>
                            <Col>
                                <div className="d-flex justify-content-center mt-2">
                                    <button className="btn btn-primary nextprev_btn" id="profile_prevname" style={{marginRight:0}} onClick={()=>{props.getEmployeeInfo(localStorage.getItem('prevurl')); localStorage.setItem('emp_profile_id',localStorage.getItem('prevurl'))}}>{props.prevname} 
                                        <UncontrolledTooltip delay={0} placement="bottom" target="profile_prevname">
                                            {props.prevname}
                                        </UncontrolledTooltip>
                                    </button>
                                    <Select styles={{control:(base,_state) =>({...base,minHeight:'43px',width:'200px',height:'43px' })}} options={searchlist.map((item)=>{return({value:item.id, label:item.name})})} value={null} onChange={(value)=>{props.getEmployeeInfo(value.value); localStorage.setItem('emp_profile_id',value.value)}} ></Select>
                                    <button className="btn btn-primary nextprev_btn" id="profile_nextname" onClick={()=>{props.getEmployeeInfo(localStorage.getItem('nexturl')); localStorage.setItem('emp_profile_id',localStorage.getItem('nexturl'))}}>{props.nextname} 
                                        <UncontrolledTooltip delay={0} placement="bottom" target="profile_nextname">
                                            {props.nextname}
                                        </UncontrolledTooltip>
                                    </button>
                                </div>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="bg-white" style={{minHeight:'calc(100vh - 193px)'}}>
                <Row>
                    <Col xs="12" sm="4" md="3" lg="2" className="pt-2 bg_title border-left" >
                        <label htmlFor={picture === null ? "newimg" : 'imgupload'}><img src={picture ?? blank_picture} alt={picture ? "user_picture" : "blank"} className="img-thumbnail" style={{height:'150px' ,width:'150px'}} /></label>
                        <Input className="d-none" type='file' id="imgupload" onChange={(e)=>props.update_employee_profile_picture2(id,e.target.files[0])} accept="image/*" ></Input>
                        <Input className="d-none" type='file' id="newimg" onChange={(e)=>props.update_employee_profile_picture(id,e.target.files[0])} accept="image/*" ></Input>
                    </Col>
                    <Col xs="12" sm="8" md="9" lg="10" className=" border-right profile_border_left" >
                        <div className="mt-3 title_name">
                            <h1>{emp_name}</h1>
                        </div>
                        <div className="d-flex justify-content-between">
                            <label>{emp_number}</label>
                            <div className="d-flex justify-content-end align-items-center mr-2">
                                {!is_active && <span className="text-red">{IMLocalized('employee_resigned')}</span>}
                                {is_active && resign !== null && <span className="text-red">{IMLocalized('employee_resigned')}</span> }
                                {is_active && resign === null && 
                                <>
                                    <Button color="danger" outline size="sm" onClick={()=>setResignToggle(true)} >{IMLocalized('resign')}</Button>
                                    {emp_user === null ? <Button color="primary" outline size="sm" onClick={()=>setAssignToggle(true)}>{IMLocalized('assign_a_role')}</Button>:
                                    <Button color="primary" outline size="sm" onClick={()=>setPasswordToggle(true)}>{IMLocalized('update_password')}</Button>}
                                </>}
                            </div>
                        </div>
                        {today_birthday && <><i className="fas fa-birthday-cake text-info"></i> <span>{IMLocalized('today_birthday')}</span></>}
                        {upcoming_birthday && <><i className="fas fa-birthday-cake text-info"></i> <span>{IMLocalized('birthday_soon')}</span></>}
                    </Col>
                </Row>
                <Row className="information_table">
                    <Col lg="2" className="pt-2 bg_title border-left d-none d-lg-block">
                        <div className="information" id="tooltipdepartment">
                            <UncontrolledTooltip target="tooltipdepartment" delay={0} placement="bottom" >
                                {IMLocalized('department')}
                            </UncontrolledTooltip>
                            <i className="fas fa-building fa-black pr-2"></i>
                            <small>{department ?? '-' }</small>
                        </div>
                        <div className="information" id="tooltipjobtitle">
                            <UncontrolledTooltip target="tooltipjobtitle" delay={0} placement="bottom">
                            {IMLocalized('job_title')}
                            </UncontrolledTooltip>
                            <i className="fas fa-address-card fa-black pr-2"></i>
                            <small>{job_title ?? '-'}</small>
                        </div>
                        <div className="information" id="tooltipjoblevel">
                            <UncontrolledTooltip target="tooltipjoblevel" delay={0} placement="bottom">
                            {IMLocalized('job_level')}
                            </UncontrolledTooltip>
                            <i className="fas fa-address-book fa-black pr-2"></i>
                            <small>{job_level ?? '-'}</small>
                        </div>
                        <hr className="m-1"/>
                        <div className="information" id="tooltipcontactnumber">
                            <UncontrolledTooltip target="tooltipcontactnumber" delay={0} placement="bottom">
                            {IMLocalized('contact_number')}
                            </UncontrolledTooltip>
                            <i className="fas fa-mobile-alt fa-black pr-2"></i>
                            <small> {contact_number ?? '-'}</small>
                        </div>
                        <hr className="m-1"/>
                        <div className="information">
                            <h5 className="m-0">{IMLocalized('join_date')}</h5>
                            <small className="pl-2">{join_date !== '' ? getDateFormat(join_date) : '-'}</small>
                        </div>
                        <hr className="m-1" />
                        <div className="information">
                            <h5 className="mb-0">{IMLocalized('leave_approval')}</h5>
                            <small className="pl-2">{leave_group ? leave_group : IMLocalized('none')}</small>
                        </div>
                        <Button className="w-100" color="primary" outline size="sm" onClick={()=>setAssignLeaveToggle(true)}>{IMLocalized('assign_approval')}</Button>
                        <hr className="m-1" />
                        <div className="information">
                            <h5 className="mb-0">{IMLocalized('claim_approval')}</h5>
                            <small className="pl-2">{claim_group ? claim_group : IMLocalized('none')}</small>
                        </div>
                        <Button className="w-100" color="primary" outline size="sm" onClick={()=>setAssignClaimToggle(true)} >{IMLocalized('assign_approval')}</Button>
                        <hr className="m-1" />
                    </Col>
                    <Col lg="10" md="12" className="border-right border-left">
                        <Nav tabs>
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: emp_step === '1' })} 
                                onClick={()=>setEmpStep('1')}
                                >
                                    {IMLocalized('profile')}
                                </NavLink>
                            </NavItem>
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: emp_step === '2' })} 
                                onClick={()=>setEmpStep('2')}
                                >
                                    {IMLocalized('contract')}
                                </NavLink>
                            </NavItem>
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: emp_step === '3' })} 
                                onClick={()=>setEmpStep('3')}
                                >
                                    {IMLocalized('family_contact')}
                                </NavLink>
                            </NavItem>
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: emp_step === '4' })} 
                                onClick={()=>setEmpStep('4')}
                                >
                                    {IMLocalized('bank')}
                                </NavLink>
                            </NavItem>
                            {process.env.REACT_APP_LEAVE === 'true' &&
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: emp_step === '5' })} 
                                onClick={()=>setEmpStep('5')}
                                >
                                {IMLocalized('leave')}
                                </NavLink>
                            </NavItem>}
                            {process.env.REACT_APP_CLAIM === 'true' &&
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: emp_step === '6' })} 
                                onClick={()=>setEmpStep('6')}
                                >
                                {IMLocalized('claim')}
                                </NavLink>
                            </NavItem>}
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: emp_step === '7' })} 
                                onClick={()=>setEmpStep('7')}
                                >
                                    {IMLocalized('payroll')}
                                </NavLink>
                            </NavItem>
                            {nationality !=='SG' && nationality !== '' && is_pr === false &&
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: emp_step === '8' })} 
                                onClick={()=>setEmpStep('8')}
                                >
                                    {IMLocalized('workpermit')}
                                </NavLink>
                            </NavItem>}
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: emp_step === '9' })} 
                                onClick={()=>setEmpStep('9')}
                                >
                                    {IMLocalized('education')}
                                </NavLink>
                            </NavItem>
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: emp_step === '10' })} 
                                onClick={()=>setEmpStep('10')}
                                >
                                    {IMLocalized('certification')}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={emp_step}>
                            <TabPane tabId="1">
                                <Profile id={id}  />
                            </TabPane>
                            <TabPane tabId="2">
                                <Contract id={id} />
                            </TabPane>
                            <TabPane tabId="3">
                                <Family id={id}  />
                            </TabPane>
                            <TabPane tabId="4">
                                <Bank id={id} />
                            </TabPane>
                            {process.env.REACT_APP_LEAVE === 'true' &&
                            <TabPane tabId="5">
                                <Leave id={id} toAwardList={(value)=>props.toAwardList(value)} toViewDetail={(i,j)=>props.toViewDetail(i,j)} toViewAll={(i)=>props.toViewAll(i)} />
                            </TabPane>}
                            {process.env.REACT_APP_CLAIM === 'true' &&
                            <TabPane tabId="6">
                                <Claim id={id} toViewClaimDetail={(i,j)=>props.toViewClaimDetail(i,j)} toViewClaimAll={(i)=>props.toViewClaimAll(i)} />
                            </TabPane>}
                            <TabPane tabId="7">
                                <Payroll id={id} toPayrollPayslip={(id,payroll_id,count)=>props.toPayrollPayslip(id,payroll_id,count)}/>
                            </TabPane>
                            {nationality !== 'SG' && nationality !== '' && is_pr === false &&
                            <TabPane tabId="8">
                                <Permit id={id} />
                            </TabPane>}
                            <TabPane tabId="9">
                                <Education id={id} />
                            </TabPane>
                            <TabPane tabId="10">
                                <Certification id={id} />
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </Container>
            <ModalResign toggle={resign_toggle} closeModal={()=>setResignToggle(false)} basic_pay={basic_pay} id={id} onSuccess={()=>{setResignToggle(false); props.get_employee_list(); props.get_employee_resign_today()}} />
            <ModalAssignRole toggle={assign_toggle} closeModal={()=>setAssignToggle(false)} id={id} name={emp_name} onSuccess={()=>{setAssignToggle(false); props.get_employee_list(); props.get_employee_role_list(); props.get_user_manager_list()}}   />
            <ModalAssignLeaveApproval toggle={assign_leave_toggle} id={id} closeModal={()=>setAssignLeaveToggle(false)} onSuccess={()=>{setAssignLeaveToggle(false); props.get_employee_list(); props.get_leave_employee_noscheme()}} type="employee_profile" join_date={null} />
            <ModalAssignClaimApproval toggle={assign_claim_toggle} id={id} closeModal={()=>setAssignClaimToggle(false)} onSuccess={()=>{setAssignClaimToggle(false); props.get_employee_list(); props.get_claim_employee_noscheme()}} type="employee_profile" join_date={null} />
            <ModalUpdatePassword toggle={password_toggle} closeModal={()=>setPasswordToggle(false)} id={id} name={emp_name} onSuccess={()=>{setPasswordToggle(false)}}  />
            </>}
            </>}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(EmployeeProfile);
