import * as delcom from '../../action/company/delete_company';

const initialState ={
   isLoading:false,
   data:null,
   errors:null,
}


const delete_company = (state=initialState,action) =>{
    switch(action.type) {
        case delcom.DELCOM_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case delcom.DELCOM_SUCCESS:
            return{
                ...state,
            isLoading:false,
            data:action.payload
            }
        case delcom.DELCOM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
            default:
                    return state;
        }
}
export default delete_company;