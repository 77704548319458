import React,{useEffect, useState} from 'react';
import { connect } from 'react-redux';
import ExcelJs from 'exceljs';
import moment from 'moment';
import FileSaver from 'file-saver';
import {IMLocalized} from '../../language/IMLocalized';
import { Modal ,DropdownItem,ModalBody,ModalHeader,ModalFooter,Input,FormGroup,FormFeedback,Row,Col } from 'reactstrap';
import {get_year_leave_balance} from '../../action/report/get_year_leave_balance';
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';
import { popError } from '../../func/popError';

const mapStateToProps=(state)=>({
    list:state.get_year_leave_balance.data,
    leavelist:state.get_leave_list.data,
    isLoading1:state.get_leave_list.isLoading,
    isLoading:state.get_year_leave_balance.isLoading,
    isLoading2:state.get_employee_list.isLoading,
    emplist:state.get_employee_list.data,
})

const mapDispatchToProps=(dispatch)=>({
    get_year_leave_balance:(year)=>{
        dispatch(get_year_leave_balance(year))
    }
})

const initialState={
    year:'',
    err_year:'',
    toggle:false,
    readonly:false,
    is_pending:false,
}

function LeaveBalanceReport(props){

    const [state,setState] = useState(initialState);

    useEffect(()=>{
        function generateExcel(rowData){
            if(rowData.length !== 0){
                const wb = new ExcelJs.Workbook();
                const ws = wb.addWorksheet('Leave Balance Report');
                const today = moment(new Date()).format('DD/MM/YYYY');
                const time = moment().format('HH:mm:ss');
                const title_name = 'LeaveBalance_' + state.year;
                ws.getCell('A1').value = `Leave Balance Report - ${state.year}`;
                ws.getCell('A2').value = `Printed On : ${today} ${time}`;
                const column = [
                    {header:'EMP ID',key:'employee_number'},
                    {header:'EMP NAME',key:'name'},
                ]
                const width = [
                    {width:15},
                    {width:20},
                ]
                for( const value of props.leavelist){
                    column.push({header:`${value.code} - ${value.name}`,key:`leave_detail.${value.code}.amount`});
                    width.push({width:20});
                }

                for(let j=0;j<column.length;j++){
                    const headerName = column[j].header;
                    const field = (column[j].key);
                    ws.getCell(getColumnName(j)+'5').value = headerName;
                    for(let x=0; x<rowData.length;x++){
                        const step = 6+x;
                        const item = getValue(rowData[x],field);
                        ws.getCell(getColumnName(j)+step).value = item;
                    }
                }
                ws.columns = width;
                const file_name = title_name+'.xlsx';
                wb.xlsx.writeBuffer().then((buf)=>{
                    var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                    FileSaver.saveAs(file, file_name)
                })
                setState(prevState=>({...prevState,readonly:false,toggle:false,is_pending:false}))

            }
            else{
                popError(IMLocalized('no_data'));
                setState(prevState=>({...prevState,readonly:false}));
            }
        }

        const year1 = new Date().getFullYear();
        const month1 = new Date().getMonth();
        let same = false;
        if(year1 === state.year){
            same = true;
        }

        if(props.list.length !== 0 && props.emplist.length && state.is_pending){
            const emp = [];
            let lookup = {};
            for(const value of props.list){
                const employee = value.employee;
                if(!(employee in lookup)){
                    lookup[employee] = 1;
                    emp.push({employee});
                }
            }
            for(const item of emp){
                const detail = props.list.filter(el=>el.employee === item.employee );
                const empdetail = props.emplist.find(el=>el.id === item.employee);
                item.name = empdetail?.name ?? '-';
                item.employee_number = empdetail?.employee_number ?? '-'; 
                const leaves =  {};
                for(const leave of props.leavelist){
                    leaves[leave.code] = {amount:'-'}
                }
                for(const data of detail ){
                    const setting = props.leavelist.find(el=>el.id === data.leave_setting);
                    const code = setting.code ?? null;
                    if(data.leave_type === 'E'){
                        const amount = same ? data.remaining_monthly[month1] : data.remaining_monthly[data.remaining_monthly.length -1];
                        leaves[code] = { amount };
                        item.leave_detail = leaves;
                    }
                    else{
                        const amount = data.remaining;
                        leaves[code] = { amount };
                        item.leave_detail = leaves;
                    }
                }
            }

            generateExcel(emp)
        }

    },[props.list,props.leavelist,props.emplist,state.year,state.is_pending])

    const setToggle=()=>{
        const year = new Date().getFullYear();
        const readonly = !state.toggle ? false : true; 
        setState(prevState=>({...prevState,year,err_year:'',readonly,toggle:!prevState.toggle}));
    }

    return(
        <>
        <DropdownItem className="cursor-pointer" 
        onClick={()=>setToggle()}>{IMLocalized('leave_balance_report')}</DropdownItem>
        <Modal isOpen={state.toggle}>
            <ModalHeader toggle={()=>setToggle()}>{IMLocalized('leave_balance_report')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='year'>{IMLocalized('year')}</label>
                            <Input type="text" size="sm" name="year" id="year" value={state.year} invalid={state.err_year.length >0} readOnly={state.readonly} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,year:value,err_year:''}))
                            }} />
                            <FormFeedback>{state.err_year}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {props.isLoading || props.isLoading1 || props.isLoading2 ?  <button className="btn btn-success btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> :
                <>
                <button className='btn btn-success btn-sm' 
                onClick={()=>{
                    const test1 = new RegExp(/^(19[5-9]\d|20[0-4]\d|2050)$/);
                    if(test1.test(state.year)){
                        props.get_year_leave_balance(state.year);
                        setState(prevState=>({...prevState,readonly:true,is_pending:true}))
                    }
                    else{
                        setState(prevState=>({...prevState,err_year:IMLocalized('invalid_year')}));
                    }
                }} 
                >{IMLocalized('export_report')}</button>
                <button className='btn btn-danger btn-sm' onClick={()=>setToggle()}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(LeaveBalanceReport);