import myaxios from '../../axios';

export const GETTASKLIST_REQUEST = 'GETTASKLIST_REQUEST';
export const GETTASKLIST_SUCCESS = 'GETTASKLIST_SUCCESS';
export const GETTASKLIST_FAILURE = 'GETTASKLIST_FAILURE';


export const get_task_list = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETTASKLIST_REQUEST'
        });

        return myaxios.get('tasks/')
        .then((response)=>{
            dispatch({
                type:'GETTASKLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETTASKLIST_FAILURE',
                payload:error
            })
        })
    }
}