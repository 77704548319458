import myaxios from '../../axios';
import FormData from 'form-data';
import { IMLocalized } from '../../language/IMLocalized';
import { Toast } from '../../swal/SwalToast';

export const USERAPPLYCLAIM_REQUEST = 'USERAPPLYCLAIM_REQUEST';
export const USERAPPLYCLAIM_SUCCESS = 'USERAPPLYCLAIM_SUCCESS';
export const USERAPPLYCLAIM_FAILURE = 'USERAPPLYCLAIM_FAILURE';
export const USERAPPLYCLAIM_RESET = 'USERAPPLYCLAIM_RESET';

export const user_apply_claim = (claim_type,original_amount,claim_date,dependant,set_pending,notes,file,doctor,diagnosis,location)=>{
    return dispatch=>{
        dispatch({
            type:'USERAPPLYCLAIM_REQUEST'
        });
        let formData = new FormData();
        if(file.length !==0){
            for(let i=0;i<file.length;i++){
                formData.append('upload_images',file[i],file[i].name);
            }
        }
        formData.append('claim_type',claim_type);
        formData.append('original_amount',original_amount);
        formData.append('dependant',dependant);
        formData.append('claim_date',claim_date);
        formData.append('set_pending',set_pending);
        formData.append('comment',notes);
        formData.append('medicalclaim.doctor',doctor);
        formData.append('medicalclaim.diagnosis',diagnosis);
        formData.append('medicalclaim.location',location)

        myaxios.post('claim/self/apply/',formData,{headers:{'Content-Type':'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'USERAPPLYCLAIM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('apply_success')
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERAPPLYCLAIM_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_user_apply_claim = () => dispatch =>{
    dispatch({
        type:'USERAPPLYCLAIM_RESET'
    });
}