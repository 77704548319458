import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, CardBody, ListGroupItem, Card, CardHeader,ListGroup} from 'reactstrap';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../language/IMLocalized';
import {delete_general_currency, reset_delete_general_currency} from '../../action/delete_general_currency';
import { general_currency } from '../../action/general_currency';
import ModalCreateCurrency from './modal/ModalCreateCurrency';
import ModalUpdateCurrency from './modal/ModalUpdateCurrency';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

function GeneralCurrency(){
    const { addItem } = useContext(NewMainContext);
    const currencylist = useSelector(state=>state.general_currency.data);
    const isLoading = useSelector(state=>state.general_currency.isLoading);
    const delete_success = useSelector(state=>state.delete_general_currency.data);

    const [create_toggle,setCreateToggle] = useState(false);
    const [edit_toggle,setEditToggle] = useState(false);
    const [edit_detail,setEditDetail] = useState(null);

    const dispatch = useDispatch();

    useEffect(()=>{
        if( delete_success !== null ){
            dispatch(reset_delete_general_currency());
            dispatch(general_currency());
        }
    },[delete_success,dispatch])

    const toEdit=(id)=>{
        const detail = currencylist.find((item)=>item.id === id);
        setEditDetail(detail ? detail :null);
        setEditToggle(detail ? true: false);
    }

    const removeCurrency=(id,name)=>{
        Swal.fire({
            type:"warning",
            title:`${IMLocalized('do_you_really_want_to_delete')} \n ${IMLocalized('currency')} (${name}) !`,
            showCancelButton:true,
            confirmButtonColor:'red',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!')
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_general_currency(id));
            }
        })
    }

    return(
        <>
            <Container fluid>
                <Row className="border-bottom bg-white">
                    <Col className="p-2">
                        <BreadCrumbList list={[
                            { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                            { active: true, title:IMLocalized('foreign_currency')}
                        ]}/>               
                    </Col>
                </Row>
            </Container>
            <Container>
                <Card className='mt-2'>
                    <CardHeader className='d-flex justify-content-between align-items-center'>
                        <h4 className="mb-0">{IMLocalized('foreign_currency_list')}</h4>
                        <button onClick={()=>setCreateToggle(true)} className="btn btn-primary btn-sm">{IMLocalized('create')}</button>
                    </CardHeader>
                    {isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                    {!isLoading && currencylist.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                    {!isLoading && currencylist.length !== 0 &&
                    <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                        {currencylist.map((item,index)=>{
                            return(
                                <ListGroupItem className="py-2" key={index}>
                                    <Row className="align-items-center">
                                        <Col>
                                            {index+1}. {item.currency_name}
                                        </Col>
                                        <Col className='col-auto mr-2'>
                                            <button onClick={()=>toEdit(item.id)} className="btn btn-info btn-sm">{IMLocalized('edit')}</button>
                                            <button onClick={()=>removeCurrency(item.id,item.currency_name)} className="btn btn-danger btn-sm" >{IMLocalized('delete')}</button>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            )
                        })}
                    </ListGroup>}
                </Card>
            </Container>
            <ModalCreateCurrency toggle={create_toggle} closeModal={()=>setCreateToggle(false)} />
            <ModalUpdateCurrency toggle={edit_toggle} closeModal={()=>{setEditToggle(false); setEditDetail(null)}} detail={edit_detail} />
        </>
    )
}
export default GeneralCurrency;