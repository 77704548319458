import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { reset_update_holiday, update_holiday } from "../../../action/update_holiday";
import { getHolidayList } from "../../../action/get_holiday_list";
import { IMLocalized } from "../../../language/IMLocalized";

const initialState={
    name:'',
    err_name:'',
    date:'',
    err_date:'',
    day:'f',
    err_day:'',
    title:'',
    id:null,
}

function ModalEditHoliday({toggle, closeModal, detail }){

    const dispatch = useDispatch();
    const dropdown = useSelector(state=>state.dropdown_list.schedule_holiday_options);
    const isLoading = useSelector(state=>state.update_holiday.isLoading);
    const success = useSelector(state=>state.update_holiday.data);
    const errors = useSelector(state=>state.update_holiday.errors);

    const [state,setState] = useState(initialState);
    

    useEffect(()=>{
        if(toggle && errors != null){
            if(errors.hasOwnProperty('date')){
                const word = errors['date'].toString();
                setState(prevState=>({...prevState,err_date:word}));
            }
            if(errors.hasOwnProperty('name')){
                const word = errors['name'].toString();
                setState(prevState=>({...prevState,err_name:word}));
            }
            dispatch(reset_update_holiday());
        }
    },[errors,toggle,dispatch])

    useEffect(()=>{
        if(toggle && success != null){
            closeModal();
            dispatch(getHolidayList());
            dispatch(reset_update_holiday());
        }
    },[toggle,success,closeModal,dispatch])


    useEffect(()=>{
        if(toggle && detail){
            setState(prevState=>({
                ...prevState,
                name:detail?.name ?? '',
                date:detail?.date ?? '',
                day:detail?.day ?? '',
                title:detail?.name ?? '',
                id:detail?.id ?? null,
            }))
        }
        else{
            setState(initialState);
        }
    },[toggle,detail])


    const onUpdate=()=>{
        if(state.name !== '' && state.date !== '' && state.day !== ''){
            dispatch(update_holiday(state.id,state.name,state.date,state.day));
        }
        else{
            setState(prevState=>({
                ...prevState,
                err_name:state.name === '' ? IMLocalized('name_is_required') :'',
                err_date:state.date === '' ? IMLocalized('date_is_required') :'',
            }))
        }
    }

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('edit_holiday')} - {state.title}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="name">{IMLocalized('name')}</label>
                            <Input type="text" id="name" value={state.name} size="sm" invalid={state.err_name.length > 0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,name:value,err_name:''}))
                            }}/>
                            <FormFeedback>{state.err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="date">{IMLocalized('date')}</label>
                            <Input type="date" id="date" value={state.date} size="sm" invalid={state.err_date.length > 0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,date:value,err_date:''}))
                            }}/>
                            <FormFeedback>{state.err_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="day">{IMLocalized('date')}</label>
                            <Input type="select" id="day" value={state.day} size="sm" invalid={state.err_day.length > 0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,day:value,err_day:''}))
                            }}>
                                {dropdown.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{state.err_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={()=>onUpdate()} >{IMLocalized('update')}</button>
                    <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default ModalEditHoliday;