

export const contract_type_list =[
    {
        "value": "FULLTIME",
        "display": "FullTime",
        "display_zh":'全职'
    },
    {
        "value": "PARTTIME",
        "display": "PartTime",
        "display_zh":'兼职'
    },
    {
        "value": "TEMPORARY",
        "display": "Temporary",
        "display_zh":'临时员工',
    },
    {
        "value": "CONTRACT",
        "display": "Contract",
        "display_zh":'合约'
    }
]


export const contract_rate_list = [
    {
        "value": "MONTHLY",
        "display": "Monthly",
        "display_zh":"月薪"
    },
    {
        "value": "HOURLY",
        "display": "Hourly",
        "display_zh":"时薪"
    },
    {
        "value": "DAILY",
        "display": "Daily",
        "display_zh":"日薪"
    }
]
