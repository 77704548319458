import myaxios from '../../axios';


export const GETGENERALAGGRIDTEM_REQUEST = 'GETGENERALAGGRIDTEM_REQUEST';
export const GETGENERALAGGRIDTEM_SUCCESS = 'GETGENERALAGGRIDTEM_SUCCESS';
export const GETGENERALAGGRIDTEM_FAILURE = 'GETGENERALAGGRIDTEM_FAILURE';

export const get_general_aggrid_template = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETGENERALAGGRIDTEM_REQUEST'
        })

        myaxios.get('general/ag_grid_profile/')
        .then((response)=>{
            dispatch({
                type:'GETGENERALAGGRIDTEM_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETGENERALAGGRIDTEM_FAILURE',
                payload:error
            })
        })
    }
}