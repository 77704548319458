import myaxios from '../axios';

export const GETGENERALLOGO_REQUEST = 'GETGENERALLOGO_REQUEST'; 
export const GETGENERALLOGO_SUCCESS = 'GETGENERALLOGO_SUCCESS';
export const GETGENERALLOGO_FAILURE = 'GETGENERALLOGO_FAILURE';

export const get_general_logo = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETGENERALLOGO_REQUEST'
        });

        myaxios.get('general/logo/',{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'GETGENERALLOGO_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETGENERALLOGO_FAILURE',
                payload:error
            })
        })
    }
}