import React, { useContext, useEffect, useState } from "react";
import { DateRangePicker } from "react-dates";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Input, Row } from "reactstrap";
import { IMLocalized } from "../../language/IMLocalized";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import LeaveTableAggrid from "./component/LeaveTableAggrid";
import ModalReportLeave from "../employee/hr_employee/modal/ModalReportLeave";
import moment from "moment";
import OverlayLoading from "../loading_component/overlay_loading";
import { NewMainContext } from "../../context/NewMainContext";
import ModalLeaveDetail1 from "./Modal/ModalLeaveDetail1";
import { get_hr_employee_all_leave_detail } from "../../action/employee/get_hr_employee_all_leave_detail";
import { get_apply_leave_list } from "../../action/get_apply_leave_list";
import { manager_leave_list } from "../../action/leave/manager_leave_list";
import { get_approvals_leave_list } from "../../action/approvals/get_approvals_leave_list";
import { reset_leave_table_hr_approve } from "../../action/leave/leave_table_hr_approve";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";

const initialState={
    selected:'5',
    focusedInput:null,
    dateFormat:'DD/MM/YYYY',
    endDate:moment().endOf('month'),
    startDate:moment().startOf('month'),
    start_date:moment().startOf('month').format('YYYY-MM-DD'),
    end_date:moment().endOf('month').format('YYYY-MM-DD'),
    report_toggle:false,
    detail_toggle:false,
    imglist:[],
    detail_id:null,
    detail_list:null,
    detail_img:[],
    count:0,
    reset_grid:0
}

export default function LeaveTable(){

    const isLoading = useSelector(state=>state.get_apply_leave_list.isLoading);
    const success = useSelector(state=>state.leave_table_hr_approve.data);
    const dispatch = useDispatch();
    const [state,setState] = useState(initialState);
    const { addItem, leave_table_reset, setLeaveTableReset } = useContext(NewMainContext);

    useEffect(()=>{
        if( success != null){
            dispatch(get_apply_leave_list());
            dispatch(manager_leave_list());
            dispatch(get_approvals_leave_list());
            dispatch(reset_leave_table_hr_approve());
            setState(prevState=>({...prevState,detail_toggle:false,detail_id:null,reset_grid:prevState.reset_grid+1}));

        }
    },[success,dispatch])

    useEffect(()=>{
        if(leave_table_reset === true){
            setState(initialState);
            setLeaveTableReset(false)
        }
    },[leave_table_reset,setLeaveTableReset])


    const onDatesChange = ({startDate,endDate}) =>{
        let start_date = null;
        if(startDate !== null){
            let startdate =startDate._d;
            start_date = moment(startdate).format('YYYY-MM-DD');
        }
        let end_date = null;
        if(endDate !== null){
            let enddate = endDate._d;
            end_date = moment(enddate).format('YYYY-MM-DD');
        }
        setState(prevState=>({
            ...prevState,
            startDate,
            endDate,
            end_date,
            start_date
        }))
    }

    return(
        <>
        {isLoading ? <OverlayLoading/>:
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title: IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title: IMLocalized('leave_table')}
                    ]}/>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col className="d-flex justify-content-end">
                    <Input style={{width:'150px'}} type="select" size="sm" className="mr-2" name="selected" value={state.selected} 
                    onChange={(e)=>{
                        const value = e.target.value;
                        setState(prevState=>({...prevState,selected:value}));
                    }} >
                        <option value="5">{IMLocalized('ALL')}</option>
                        <option value='0'>{IMLocalized('PENDING')}</option>
                        <option value="1">{IMLocalized('APPROVED')}</option>
                        <option value="2">{IMLocalized('REJECTED')}</option>
                        <option value="3">{IMLocalized('CANCELLED')}</option>
                        <option value="4">{IMLocalized('CONSUMED')}</option>
                    </Input>
                    <DateRangePicker 
                        startDate={state.startDate}
                        isOutsideRange={()=>false}
                        small = {true}
                        startDateId="unique_start_date_id"
                        endDate={state.endDate}
                        endDateId="unique_end_date_id"
                        minimumNights={0}
                        focusedInput={state.focusedInput}
                        onFocusChange={(value)=>setState(prevState=>({...prevState,focusedInput:value}))}
                        displayFormat={state.dateFormat}
                        onDatesChange={ onDatesChange }
                    />
                </Col>
            </Row>
            <LeaveTableAggrid selected={state.selected} end_date={state.end_date} start_date={state.start_date} openReport={()=>setState(prevState=>({...prevState,report_toggle:true}))} toDetail={(id)=>{ dispatch(get_hr_employee_all_leave_detail(id)); setState(prevState=>({...prevState,detail_toggle:true}))}} reset_grid={state.reset_grid} />
        </Container>
        <ModalReportLeave toggle={state.report_toggle} closeModal={()=>setState(prevState=>({...prevState,report_toggle:false}))} endDate={state.endDate} startDate={state.startDate} end_date={state.end_date} start_date={state.start_date} />
        <ModalLeaveDetail1 toggle={state.detail_toggle} closeModal={()=>setState(prevState=>({...prevState,detail_toggle:false}))} toprofile={true} />
        </>}
        </>
    )
}