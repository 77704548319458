import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EmployeeLeaveDetail from '../hr_employee/component/EmployeeLeaveDetail';

const mapStateToProps=(state)=>({
    availablelist:state.get_all_employee_available_leave.data,
    leavelist:state.get_leave_list.data,
})

function EmployeeLeave(props){

    const [availablelist,setAvailable] = useState([]);
    const [has_award,setHasAward] = useState(false);

    useEffect(()=>{
        if(props.id !== '' && props.availablelist.length !== 0){
            const data = props.availablelist.filter(function(item){
                return item.employee === parseInt(props.id);
            })

            const award = data.some(function(item){
                return item.leave_type === 'G'
            })

            setHasAward(award);
            setAvailable(data);
        }
        else{
            setAvailable([]);
        }
    },[props.availablelist,props.id])


    const toViewDetail = (leave_setting)=>{
        props.toViewDetail(props.id,leave_setting)
    }

    return(
        <div className='mt-2'>
            <EmployeeLeaveDetail list={availablelist} has_award={has_award} toAwardList={props.toAwardList} toViewAll={props.toViewAll} id={props.id} toViewDetail={toViewDetail}  />
        </div>
    )
}
export default connect(mapStateToProps,null)(EmployeeLeave);