import React from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';

const mapStateToProps=(state)=>({
    pay_component_type:state.dropdown_list.pay_component_type,
})

function SummaryTable(props){

    const getData =(pay_type)=>{

        const detail = props.pay_component_type.find(item=>item.value === pay_type);

        return detail ? detail.display : '';
    }

    const summary_arr = [];
    const employee_arr = [];
    if(props.emp_payslip !== null){
        const {payslipcontract,sdl,sgcpf,sgshg,payable_wage,total_wage,deductables,additional_wage,ordinary_wage} = props.emp_payslip;
        const gross_month_amount = payslipcontract.reduce((prev,current)=>prev+current.gross_month,0);
        if(payslipcontract.length >0){
            summary_arr.push({name:IMLocalized('gross_month_total'),amount:`$${gross_month_amount.toFixed(2)}`});
        }

        props.overtimelist.forEach((element)=>{
            if(element.is_gross === false && element.amount > 0){
                summary_arr.push({name:element.name,amount:`$${element.amount.toFixed(2)}`});
            }
        })

        props.allowancelist.forEach((element)=>{
            if(element.is_gross === false && element.amount > 0 ){
                summary_arr.push({name:element.name,amount:`$${element.amount.toFixed(2)}`});
            }
        })

        props.recurringlist.forEach((element)=>{
            if(element.is_gross === false && element.amount > 0){
                summary_arr.push({name:element.name,amount:`$${element.amount.toFixed(2)}`});
            }
        })

        props.flatlist.forEach((element)=>{
            if(element.is_gross === false && element.amount > 0){
                summary_arr.push({name:element.code,amount:`$${element.amount.toFixed(2)}`})
            }
        })

        props.backpaylist.forEach((element)=>{
            if(element.is_gross === false && element.deductable === false && element.amount > 0){
                summary_arr.push({name:getData(element.pay_type),amount:`$${element.amount.toFixed(2)}`});
            }
        })

        props.bonuslist.forEach((element)=>{
            if(element.is_gross === false && element.amount >0){
                summary_arr.push({name:getData(element.pay_type),amount:`$${element.amount.toFixed(2)}`});
            }
        })

        props.nopaylist.forEach((element)=>{
            summary_arr.push({name:element.code,amount:`($${element.amount.toFixed(2)})`});
        })

        props.nationlist.forEach((element)=>{
            summary_arr.push({name:element.code,amount:`($${element.amount.toFixed(2)})`});
        })

        props.absentlist.forEach((element)=>{
            if(element.is_gross === false && element.amount > 0){
                summary_arr.push({name:`${getData(element.pay_type)} (AWOL)`,amount:`($${element.amount.toFixed(2)})`});
            }
        })

        props.backpaylist.forEach((element)=>{
            if(element.is_gross === false && element.deductable === true && element.amount > 0){
                summary_arr.push({name:getData(element.pay_type),amount:`($${element.amount.toFixed(2)})`});
            }
        })

        const claim_arr = props.claimlist.map((element)=>{
            return {name:IMLocalized('total_claim'),amount:`$${element.amount.toFixed(2)}`}
        })

        const shg_arr = [];
        if(sgcpf !== null){
            shg_arr.push({name:'CPF',amount:`$(${(sgcpf.cpf_employee).toFixed(2)})`});
            employee_arr.push({name:IMLocalized('payslip_employer_total'),amount:`$${(sgcpf.cpf_employer).toFixed(2)}`});
        }

        if(sgshg != null && sgshg.length !== 0){
            sgshg.forEach((element)=>{
                shg_arr.push({name:element.shg,amount:`($${(element.amount).toFixed(2)})`});
            })
        }

        employee_arr.push({name:'SDL',amount:`$${sdl.toFixed(2)}`});

        summary_arr.push(
            {name:IMLocalized('payslip_ordinary_wage'),amount:`$${(ordinary_wage).toFixed(2)}`},
            {name:IMLocalized('payslip_additional_wage'),amount:`${(additional_wage).toFixed(2)}`},
            {name:IMLocalized('payslip_deductables'),amount:`($${(deductables).toFixed(2)})`},
            {name:IMLocalized('payslip_total_wage'),amount:`$${(total_wage).toFixed(2)}`},
            ...shg_arr,
            ...claim_arr,
            {name:IMLocalized('payslip_payable'),amount:`$${(payable_wage).toFixed(2)}`}
        );

    }

    return(
        <div className='mb-2' style={{position:'sticky',top:'10px'}}>
            <h2 className='title-color1'>{IMLocalized('summary')}</h2>
            <Card>
                <CardHeader className='p-2 border-0 bg-white'>
                    <h4 className='pl-2 pt-2 text-primary'>{IMLocalized('summary')}</h4>
                </CardHeader>
                <Table className='align-items-center' size="sm" responsive>
                    <thead className='p-2 thead-light'>
                        <tr>
                            <th>{IMLocalized('name')}</th>
                            <th width="40%">{IMLocalized('amount')}</th>
                        </tr>
                    </thead>
                    <tbody className='p-2'>
                        {summary_arr.map((item,index)=>{
                            return(
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.amount}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Card>
            <Card>
                <CardHeader className='p-2 border-0 bg-white'>
                    <h4 className='text-primary pl-2 pt-2'>
                        {IMLocalized('payslip_employer_summary')}
                    </h4>
                </CardHeader>
                <Table size="sm" className='align-items-center' responsive>
                    <tbody className="p-2">
                        {employee_arr.map((item,index)=>{
                            return (
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.amount}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Card>
        </div>
    )
}
export default connect(mapStateToProps,null)(SummaryTable);