import React from "react";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";



export default function ModalClaimMassApprovalError({ toggle, closeModal , detail}){

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('error_msg')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Table size="sm" className="table-bordered align-items-center">
                            <thead>
                                <tr>
                                    <th>{IMLocalized('employee_number')}</th>
                                    <th>{IMLocalized('name')}</th>
                                    <th>{IMLocalized('error')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detail.map((item,index)=>{
                                    const { arr1 } = item;
                                    return(
                                        <tr key={item.number}>
                                            <td>{item.number}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                {arr1.map((el,index1)=>{
                                                    return(
                                                        <div key={index1}>
                                                           <span>● {el.msg}</span> 
                                                        </div>
                                                    )
                                                })}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}