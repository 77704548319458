import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const NEW_CREATEEMPPERMIT_REQUEST = 'NEW_CREATEEMPPERMIT_REQUEST';
export const NEW_CREATEEMPPERMIT_SUCCESS = 'NEW_CREATEEMPPERMIT_SUCCESS';
export const NEW_CREATEEMPPERMIT_FAILURE = 'NEW_CREATEEMPPERMIT_FAILURE';
export const NEW_CREATEEMPPERMIT_RESET = 'NEW_CREATEEMPPERMIT_RESET';

export const create_employee_permit = (data,employee,identification)=>{
    return dispatch=>{
        dispatch({
            type:'NEW_CREATEEMPPERMIT_REQUEST'
        });

        myaxios.patch(`employee/employees/${employee}/`,{
            identification_number :identification,
            
        })
        .then(()=>{
            const {employee, permit_type, permit, man_year_entitlement, expiry_date, issued_date, application_date} = data[0];

            myaxios.post(`employee/workpermit/`,{
                permit:permit,
                permit_type:permit_type,
                man_year_entitlement:man_year_entitlement,
                expiry_date:expiry_date,
                issued_date:issued_date,
                application_date:application_date,
                employee:employee
            })
            .then((response)=>{
                dispatch({
                    type:'NEW_CREATEEMPPERMIT_SUCCESS',
                    payload:response
                });

                Toast.fire({
                    type: 'success',
                    title: IMLocalized('create_success')
                });
            })
            .catch((error)=>{
                dispatch({
                    type:'NEW_CREATEEMPPERMIT_FAILURE',
                    payload:error
                });

                ErrToast.fire({
                    type:'error',
                    title:IMLocalized('create_failure')
                });
            })
        })
        .catch((error)=>{
            dispatch({
                type:'NEW_CREATEEMPPERMIT_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure'),
            });
        })
        
    }
}

export const reset_create_employee_permit = () => dispatch =>{
    dispatch({
        type:'NEW_CREATEEMPPERMIT_RESET'
    });
}
