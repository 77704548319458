import * as payrollresign from '../../action/payroll/submit_payroll_resign';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const submit_payroll_resign = (state=initialState,action)=>{
    switch(action.type){
        case payrollresign.SUBMITPAYROLLRESIGN_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case payrollresign.SUBMITPAYROLLRESIGN_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case payrollresign.SUBMITPAYROLLRESIGN_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default submit_payroll_resign;