import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATEHOLIDAYLIST_REQUEST = 'CREATEHOLIDAYLIST_REQUEST';
export const CREATEHOLIDAYLIST_SUCCESS = 'CREATEHOLIDAYLIST_SUCCESS';
export const CREATEHOLIDAYLIST_FAILURE = 'CREATEHOLIDAYLIST_FAILURE';
export const CREATEHOLIDAYLIST_RESET = 'CREATEHOLIDAYLIST_RESET';



export const create_holiday_list = (name,holiday)=>{
    return dispatch=>{
        dispatch({
            type:'CREATEHOLIDAYLIST_REQUEST',
        });

        myaxios.post('contract/holidaylist/',{
            name:name,
            holiday:holiday
        })
        .then((response)=>{
            dispatch({
                type:'CREATEHOLIDAYLIST_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATEHOLIDAYLIST_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}


export const reset_create_holiday_list = () => dispatch =>{
    dispatch({
        type:'CREATEHOLIDAYLIST_RESET'
    })
}