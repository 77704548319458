

export function getColumnName(n){
    let ordA = 'A'.charCodeAt(0);
    let ordZ = 'Z'.charCodeAt(0);
    let len = ordZ - ordA +1;

    let s = '';
    while(n >=0){
        s = String.fromCharCode(n % len + ordA )+s;
        n = Math.floor(n/len) -1;
    }
    return s;
}