import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Nav, NavbarBrand } from 'reactstrap';
import { IMLocalized } from '../../language/IMLocalized';
import { NewMainContext } from '../../context/NewMainContext';
import '../../css/navbar.css';
import { connect } from 'react-redux';
import { Buffer } from 'buffer';
import blank_picture from '../../images/blank_picture.png';
import UserNavbar from './navbar_component/UserNavbar';
import HrNavbar from './navbar_component/HrNavbar';
import LeaveOfficerNavbar from './navbar_component/LeaveOfficerNavbar';
import ClaimOfficerNavbar from './navbar_component/ClaimOfficerNavbar';

const mapStateToProps=(state)=>({
    logo:state.get_general_logo.data,
    user_picture:state.get_user_picture.data,
    self:state.user_self.data,
})

const mapDispatchToProps=(dispatch)=>({

})

function NavBar(props){

    const { menu, setMenu, setActionToggle, current_user, action_toggle, role, key, setKey, resetTab } = useContext(NewMainContext);
    const [ logo, setLogo ] = useState(null);
    const [ dropdown, setDropdown ] = useState(false);
    const [ name, setName ] = useState('');
    const [ user_picture, setUserPicture] = useState(null);


    useEffect(()=>{
        if(props.logo.length !== 0){
            const url = process.env.REACT_APP_API_URL;
            document.getElementById("favicon").href = `https://${url}/api/general/logo/`;
            const picture = props.logo.data;
            const byte = picture.byteLength;
            if(byte !== 0){
                const img = new Buffer.from(picture,'binary').toString('base64');
                const src = `data:image/png;base64,${img}`;
                setLogo(src);
            } 
            else{
                setLogo(null);
            }
        }
    },[props.logo])

    useEffect(()=>{
        if(props.user_picture.length !== 0){
            const img = new Buffer.from(props.user_picture,'binary').toString('base64');
            const src = `data:image/png;base64,${img}`;
            setUserPicture(src);
        }
        else{
            setUserPicture(null);
        }
    },[props.user_picture])

    useEffect(()=>{
        if(props.self.length !== 0 ){
            const {name} = props.self;
            let string = '';
            if(name.length >17){
                string = `${name.substring(0,14)}...`;
            }
            else{
                string = name;
            }
            setName(string);
        }
    },[props.self])

    return(
        <>
        <nav className='navbar fixed-top navbar-expand-lg navbar-dark bg-primary py-1 shadow-sm yamm body-nav'>
            <a href='/#' className='navbar-brand font-weight-bold d-block d-lg-none text-white'>{process.env.REACT_APP_TITLE}</a>
            <button type='button' date-toggle="collapse" date-target="#navbarContent" aria-controls="navbars" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler" onClick={()=>setMenu(prevState=>!prevState)} >
                <span className="navbar-toggler-icon text-white"></span>
            </button>
            <Collapse isOpen={menu} className="scrollable-nav" navbar>
                {logo !== null && 
                <NavbarBrand className='d-none d-xl-block'>
                    <img alt="company-logo" className='navbar-img' src={logo} />
                </NavbarBrand> }
                {current_user === 'user' || current_user === 'manager' ?
                <UserNavbar/>: null
                }
                {current_user === 'hr' && <HrNavbar />}
                {current_user === 'leave_officer' && <LeaveOfficerNavbar/>}
                {current_user === 'claim_officer' && <ClaimOfficerNavbar/>}
                <Nav className='ml-auto'>
                    <Dropdown nav isOpen={dropdown} toggle={()=>setDropdown(prevState=>!prevState)}>
                        <DropdownToggle nav className='text-white font-text' >
                            <div className="d-flex align-items-center">
                                <small className="text-white font-small cursor-pointer mr-2" >{name}</small>
                                <i className="fas fa-angle-down"></i>
                            </div>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <div className='d-flex justify-content-center'>
                                <img src={user_picture !== null ? user_picture : blank_picture} alt="user-thumbnail" className='navbar-user-img'  />
                            </div>
                            <div className='d-flex justify-content-center mt-2'>
                                <span className='font-weight-normal'>{name}</span>
                            </div>
                            <DropdownItem divider />
                            <div className='mx-2'>
                                <FormGroup className='mb-0'>
                                    <label className='form-control-label' htmlFor="userkey">{IMLocalized('current_role')}</label>
                                    <Input type="select" size="sm" id="userkey" value={key} 
                                    onChange={(e)=>{
                                        const value = e.target.value;
                                        setKey(value);
                                        setDropdown(false);
                                        setMenu(false);
                                        resetTab();
                                    }}>
                                        {role.map((item,index)=>{
                                            return <option value={index} key={index}>{IMLocalized(item)}</option>
                                        })}
                                    </Input>
                                </FormGroup>
                            </div>
                            <DropdownItem divider />
                            <div className='ml-3 d-flex alin-items-center'>
                                <label className='custom-toggle'>
                                    <input type="checkbox" checked={action_toggle} 
                                    onChange={(e)=>{ 
                                        const checked = e.target.checked;
                                        setActionToggle(checked);
                                        localStorage.setItem('action_toggle',checked)
                                    }} />
                                    <span className="custom-toggle-slider rounded-circle" />
                                </label>
                                <span className="pl-2 font-text">{IMLocalized('short_cut_key')}</span>
                            </div>
                            <DropdownItem divider/>
                            <DropdownItem onClick={()=>props.setQrToggle(true)}><i className='ni ni-calendar-grid-58' /><span>{IMLocalized('qr_code_for_mobile_app')}</span></DropdownItem>
                            <DropdownItem divider/>
                            <DropdownItem onClick={()=>props.setPasswordToggle(true)}><i className='ni ni-key-25'/><span>{IMLocalized('change_password')}</span></DropdownItem>
                            <DropdownItem divider/>
                            <DropdownItem onClick={()=>props.logOut()}><i className='ni ni-user-run' /><span>{IMLocalized('signout')}</span></DropdownItem>
                            <DropdownItem divider/>
                            <DropdownItem className="noti-title" header tag="div">
                                <h6 className='text-overflow m-0'> Version 0.1.34</h6>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Nav>
            </Collapse>
        </nav>
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(NavBar);