import axios from 'axios';

export const SUBMITUSERNEWPASS_REQUEST = 'SUBMITUSERNEWPASS_REQUEST';
export const SUBMITUSERNEWPASS_SUCCESS = 'SUBMITUSERNEWPASS_SUCCESS';
export const SUBMITUSERNEWPASS_FAILURE = 'SUBMITUSERNEWPASS_FAILURE';


export const submit_user_newpass = (code,employee_number,date_of_birth,password)=>{
    return dispatch=>{
        dispatch({
            type:'SUBMITUSERNEWPASS_REQUEST'
        });

        axios.post('https://'+process.env.REACT_APP_API_URL+'/api/otp/code/newpass/',{
            code:code,
            employee_number:employee_number,
            date_of_birth:date_of_birth,
            password:password
        })
        .then((response)=>{
            dispatch({
                type:'SUBMITUSERNEWPASS_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'SUBMITUSERNEWPASS_FAILURE',
                payload:error
            })
        })
    }
}