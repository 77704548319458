import * as leave_hr_approve from '../../action/employee/emp_detail_array_approve_leave';

const initialState={
    errors:null,
    isLoading:false,
    data:null
}

const emp_detail_array_approve_leave =(state=initialState,action)=>{
    switch(action.type){
        case leave_hr_approve.EMPDETAILLEAVEHRAPPROVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case leave_hr_approve.EMPDETAILLEAVEHRAPPROVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case leave_hr_approve.EMPDETAILLEAVEHRAPPROVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case leave_hr_approve.EMPDETAILLEAVEHRAPPROVE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default emp_detail_array_approve_leave;