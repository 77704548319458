import React,{useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {get_claim_option} from '../../action/claim/get_claim_option';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardHeader, CardBody, FormFeedback, FormGroup, Input } from 'reactstrap';
import {reset_update_claim_option, update_claim_option} from '../../action/claim/update_claim_option';
import {IMLocalized} from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';
import { NewMainContext } from '../../context/NewMainContext';

const initialState={
    err_claim_reset_day:'',
    claim_reset_day:'',
    proration_option:'',
    err_proration_option:'',
    cutoff_day:'',
    err_cutoff_day:'',
    after_cutoff_leave:'',
    err_after_cutoff_leave:'',
    rounding:'',
    err_rounding:'',
    decimal_places:'',
    err_decimal_places:'',
    allow_dependant:false,
}

function ClaimBaseSetting(){
    const [state,setState]  = useState(initialState);
    const dispatch = useDispatch();
    const {addItem} = useContext(NewMainContext);
    const data = useSelector(state=>state.get_claim_option.data);
    const claim_decimal_places = useSelector(state=>state.dropdown_list.claim_decimal_places);
    const claim_rounding = useSelector(state=>state.dropdown_list.claim_rounding);
    const claim_reset_day = useSelector(state=>state.dropdown_list.leave_reset_day);
    const proration_option = useSelector(state=>state.dropdown_list.proration_option);
    const update_success = useSelector(state=>state.update_claim_option.data);
    const isLoading1 = useSelector(state=>state.get_claim_option.isLoading);
    
    useEffect(()=>{
        if( update_success!= null){
            dispatch(get_claim_option());
            dispatch(reset_update_claim_option());
        }
    },[update_success,dispatch])
    
    useEffect(()=>{
        if(data != null){
            const {allow_dependant,claim_reset_day,proration_option,cutoff_day,after_cutoff_leave,rounding,decimal_places} = data;
            setState(prevState=>({
                ...prevState,
                err_claim_reset_day:'',
                claim_reset_day,
                proration_option,
                err_proration_option:'',
                cutoff_day,
                err_cutoff_day:'',
                after_cutoff_leave,
                err_after_cutoff_leave:'',
                rounding,
                err_rounding:'',
                decimal_places,
                err_decimal_places:'',
                allow_dependant
            }))
        }
    },[data])


    const onUpdate=()=>{
        if(state.claim_reset_day !== '' && state.proration_option !== '' && state.cutoff_day !== ''&& state.after_cutoff_leave !== '' && state.rounding !== '' && state.decimal_places !== ''){
            dispatch(update_claim_option(state.allow_dependant,state.claim_reset_day,state.proration_option,state.cutoff_day,state.after_cutoff_leave,state.rounding,state.decimal_places));
        }
        else{
            setState(prevState=>({
                ...prevState,
                err_claim_reset_day:state.claim_reset_day === '' ? IMLocalized('claim_reset_day_is_required') : '',
                err_proration_option:state.proration_option === '' ? IMLocalized('proration_option_is_required') : '',
                err_cutoff_day:state.cutoff_day === '' ? IMLocalized('cutoff-day is required') : '',
                err_after_cutoff_leave:state.after_cutoff_leave === '' ? IMLocalized('after_cutoff_leave_is_required') : '',
                err_rounding:state.rounding === '' ? IMLocalized('rounding_is_required')  : '' ,
                err_decimal_places:state.decimal_places === '' ? IMLocalized('decimal_places_is_required') : '',
            }))
        }
    }

    return(
        <>
        {isLoading1 ? <OverlayLoading/> :
        <>
        <Container fluid>
            <Row className='bg-white border-bottom'>
                <Col className='p-2'>
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('claim_option')}</BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </Container>
        <Container className='mt-2'>
            <Row>
                <Col>
                    <Card>
                        <CardHeader className='d-flex align-items-center justify-content-between'>
                            <h4 className='mb-0'>{IMLocalized('claim_option')}</h4>
                            <button  className="btn btn-primary btn-sm" onClick={()=>onUpdate()}>{IMLocalized('update')}</button>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='claim_reset_day'>{IMLocalized('claim_reset_day')}</label>
                                        <Input size="sm" type="select" id="claim_reset_day" value={state.claim_reset_day} invalid={state.err_claim_reset_day.length>0}
                                        onChange={(e)=>{
                                            const value = e.target.value;
                                            setState(prevState=>({
                                                ...prevState,
                                                claim_reset_day:value,
                                                err_claim_reset_day:''
                                            }))
                                        }}
                                        >
                                            <option></option>
                                            {claim_reset_day.map((item,index)=>{
                                                return <option value={item.value} key={index}>{item.display}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{state.err_claim_reset_day}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='proration_option'>{IMLocalized('proration_option')}</label>
                                        <Input size="sm" type="select" id="proration_option" value={state.proration_option} invalid={state.err_proration_option.length>0}
                                        onChange={(e)=>{
                                            const value = e.target.value;
                                            setState(prevState=>({
                                                ...prevState,
                                                proration_option:value,
                                                err_proration_option:''
                                            }))
                                        }}
                                        >
                                            <option></option>   
                                            {proration_option.map((item,index)=>{
                                                return <option value={item.value} key={index}>{item.display}</option>
                                            })}                                 
                                        </Input>
                                        <FormFeedback>{state.err_proration_option}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='cutoff_day'>{IMLocalized('cutoff_day')}</label>
                                        <Input size="sm" type="number" min="0" id="cutoff_day" value={state.cutoff_day} invalid={state.err_cutoff_day.length>0}
                                        onChange={(e)=>{
                                            const value = e.target.value;
                                            setState(prevState=>({
                                                ...prevState,
                                                cutoff_day:value,
                                                err_cutoff_day:''
                                            }))
                                        }} 
                                        />
                                        <FormFeedback>{state.err_cutoff_day}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor='after_cutoff_leave'>{IMLocalized('after_cutoff_leave')}</label>
                                        <Input size="sm" type="number" min="0" id="after_cutoff_leave" value={state.after_cutoff_leave} invalid={state.err_after_cutoff_leave.length>0}
                                        onChange={(e)=>{
                                            const value = e.target.value;
                                            setState(prevState=>({
                                                ...prevState,
                                                after_cutoff_leave:value,
                                                err_after_cutoff_leave:''
                                            }))
                                        }}
                                        />
                                        <FormFeedback>{state.err_after_cutoff_leave}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor='rounding'>{IMLocalized('rounding')}</label>
                                        <Input size="sm" type="select" id="rounding" value={state.rounding} invalid={state.err_rounding.length>0}
                                        onChange={(e)=>{
                                            const value = e.target.value;
                                            setState(prevState=>({
                                                ...prevState,
                                                rounding:value,
                                                err_rounding:''
                                            }))
                                        }}
                                        >
                                            <option></option> 
                                            {claim_rounding.map((item,index)=>{
                                                return <option value={item.value} key={index}>{item.display}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{state.err_rounding}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor='decimal_places'>{IMLocalized('decimal_places')}</label>
                                        <Input size="sm" type="select" id="decimal_places" value={state.decimal_places} invalid={state.err_decimal_places.length>0}
                                        onChange={(e)=>{
                                            const value = e.target.value;
                                            setState(prevState=>({
                                                ...prevState,
                                                decimal_places:value,
                                                err_decimal_places:''
                                            }))
                                        }}
                                        >
                                            <option></option> 
                                            {claim_decimal_places.map((item,index)=>{
                                                return <option value={item.value} key={index}>{item.display}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{state.err_decimal_places}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="allowdependant" name='allow_dependant' className="custom-control-input" checked={state.allow_dependant} 
                                        onChange={(e)=>{
                                            const checked = e.target.checked;
                                            setState(prevState=>({
                                                ...prevState,
                                                allow_dependant:checked
                                            }))
                                        }} 
                                        />
                                        <label className="custom-control-label" htmlFor="allowdependant">{IMLocalized('allow_dependant')}</label>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>}
        </>
    )
}
export default ClaimBaseSetting;