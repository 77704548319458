import myaxios from '../../axios';
export const EMPCONTRACTSECTION_SUCCESS = 'EMPCONTRACTSECTION_SUCCESS';
export const EMPCONTRACTSECTION_REQUEST = 'EMPCONTRACTSECTION_REQUEST';
export const EMPCONTRACTSECTION_FAILURE = 'EMPCONTRACTSECTION_FAILURE';


export const employee_contract_section = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'EMPCONTRACTSECTION_REQUEST'
        });
        
        myaxios.post(`company/section/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'EMPCONTRACTSECTION_SUCCESS',
                payload:response
            }
        )})
        .catch((error) =>{
            dispatch({
                type: 'EMPCONTRACTSECTION_FAILURE',
                payload: error,
            })
        })
    }
}