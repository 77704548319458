import * as updashboard from '../../action/dashboard/update_dashboard_widget';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_dashboard_widget = (state=initialState,action)=>{
    switch(action.type){
        case updashboard.UPDATEDASHBOARDWIDGET_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case updashboard.UPDATEDASHBOARDWIDGET_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updashboard.UPDATEDASHBOARDWIDGET_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default update_dashboard_widget;