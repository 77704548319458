import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const MANAGERSUBMITAPPRAISAL_REQUEST = 'MANAGERSUBMITAPPRAISAL_REQUEST';
export const MANAGERSUBMITAPPRAISAL_SUCCESS = 'MANAGERSUBMITAPPRAISAL_SUCCESS';
export const MANAGERSUBMITAPPRAISAL_FAILURE = 'MANAGERSUBMITAPPRAISAL_FAILURE';
export const MANAGERSUBMITAPPRAISAL_RESET = 'MANAGERSUBMITAPPRAISAL_RESET';

export const manager_submit_appraisal =(id,employee,response)=>{
    return dispatch=>{
        dispatch({
            type:'MANAGERSUBMITAPPRAISAL_REQUEST'
        })

        myaxios.post(`forms/manager/${id}/submit/`,{
            employee:employee,
            response:response
        })
        .then((response)=>{
            dispatch({
                type:'MANAGERSUBMITAPPRAISAL_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('submit_appraisal_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'MANAGERSUBMITAPPRAISAL_FAILURE',
                payload:error
            })
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('submit_appraisal_failure')
            });
        })
    }
}

export const reset_manager_submit_appraisal = () => dispatch=>{
    dispatch({
        type:'MANAGERSUBMITAPPRAISAL_RESET'
    });
}