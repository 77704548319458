import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATECLAIM_REQUEST = 'UPDATECLAIM_REQUEST';
export const UPDATECLAIM_SUCCESS = 'UPDATECLAIM_SUCCESS';
export const UPDATECLAIM_FAILURE = 'UPDATECLAIM_FAILURE';



export const update_claim = (id,code,name,medical,minimum_images,maximum_images)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATECLAIM_REQUEST'
        });

        myaxios.patch(`claim/type/${id}/`,{
            code:code,
            name:name,
            medical:medical,
            maximum_images:maximum_images,
            minimum_images:minimum_images
        })
        .then((response)=>{
            dispatch({
                type:'UPDATECLAIM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type:'success',
                title:IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATECLAIM_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}