import * as pay_period from '../../action/wizard/wizard_update_pay_period';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const wizard_update_pay_period = (state=initialState,action)=>{
    switch(action.type){
        case pay_period.UPDATEPAYPERIOD_REQUEST1:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case pay_period.UPDATEPAYPERIOD_SUCCESS1:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data,
            }
        case pay_period.UPDATEPAYPERIOD_FAILURE1:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default wizard_update_pay_period;