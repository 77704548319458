import myaxios from '../../axios';

export const EMPLIST2GETEMPLOYEEPICTURE_REQUEST = 'EMPLIST2GETEMPLOYEEPICTURE_REQUEST';
export const EMPLIST2GETEMPLOYEEPICTURE_SUCCESS = 'EMPLIST2GETEMPLOYEEPICTURE_SUCCESS';
export const EMPLIST2GETEMPLOYEEPICTURE_FAILURE = 'EMPLIST2GETEMPLOYEEPICTURE_FAILURE';

export const get_employee_picture = (id)=>{
    return dispatch=>{
        dispatch({
            type:'EMPLIST2GETEMPLOYEEPICTURE_REQUEST'
        });

        myaxios.get(`ob/employee/${id}/thumbnail/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'EMPLIST2GETEMPLOYEEPICTURE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPLIST2GETEMPLOYEEPICTURE_FAILURE',
                payload:error
            })
        })
    }
}