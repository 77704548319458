import myaxios from '../axios';

export const GETPROBATIONLIST_REQUEST = 'GETPROBATIONLIST_REQUEST';
export const GETPROBATIONLIST_SUCCESS = 'GETPROBATIONLIST_SUCCESS';
export const GETPROBATIONLIST_FAILURE = 'GETPROBATIONLIST_FAILURE';

export const get_employee_probation_list = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETPROBATIONLIST_REQUEST'
        });

        myaxios.get('employee/probation/')
        .then((response)=>{
            dispatch({
                type:'GETPROBATIONLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETPROBATIONLIST_FAILURE',
                payload:error
            })
        })
    }
}