import myaxios from '../../axios';

export const GETCLAIMAPPROVAL_REQUEST = 'GETCLAIMAPPROVAL_REQUEST';
export const GETCLAIMAPPROVAL_SUCCESS = 'GETCLAIMAPPROVAL_SUCCESS';
export const GETCLAIMAPPROVAL_FAILURE = 'GETCLAIMAPPROVAL_FAILURE';


export const get_claim_approval = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETCLAIMAPPROVAL_REQUEST'
        })

        myaxios.get('claim/approval/')
        .then((response)=>{
            dispatch({
                type:'GETCLAIMAPPROVAL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETCLAIMAPPROVAL_FAILURE',
                payload:error
            })
        })
    }
}