import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import Swal from "sweetalert2";
import { delete_holiday, reset_delete_holiday } from "../../action/delete_holiday";
import { getHolidayList } from "../../action/get_holiday_list";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";
import ModalCreateHoliday from "./modal/ModalCreateHoliday";
import ModalEditHoliday from "./modal/ModalEditHoliday";

function HolidayDetail({ id }){

    const holidaylist = useSelector(state=>state.get_holiday_list.data);
    const isLoading = useSelector(state=>state.get_holiday_list.isLoading);
    const delete_success = useSelector(state=>state.delete_holiday.data);

    const dispatch = useDispatch();
    const { addItem, setHolidayStep } = useContext(NewMainContext);

    const [list,setList] = useState([]);
    const [name,setName] = useState('');
    const [add_toggle,setAddToggle] = useState(false);
    const [edit_toggle,setEditToggle] = useState(false);
    const [edit_detail,setEditDetail] = useState(null);

    useEffect(()=>{
        if( delete_success != null){
            dispatch(getHolidayList());
            dispatch(reset_delete_holiday());
        }
    },[delete_success,dispatch])

    useEffect(()=>{
        if(id != null && holidaylist.length !== 0 ){
            const detail = holidaylist.find(item=>item.id === parseInt(id));
            setName(detail?.name ?? '');
            setList(detail?.holiday ?? []);
        }   
    },[id,holidaylist])

    const deleteHoliday=(id,name)=>{
        Swal.fire({
            type:"warning",
            title:`${IMLocalized('delete_holiday_confirmation')} (` + name +') !',
            showCancelButton:true,
            confirmButtonColor:'red',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!')
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_holiday(id));
            }
        })
    }

    const goBack = ()=>{
        setHolidayStep('0'); 
        localStorage.setItem('holiday_step','0')
    }

    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title: IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title: IMLocalized('holiday_list'), onClick:()=>goBack()},
                        { active: true, title: name}
                    ]}/>
                    <div className="ml-2">
                        <button className="btn btn-primary btn-sm"  onClick={()=>{setHolidayStep('0'); localStorage.setItem('holiday_step','0')}}><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between"> 
                            <h4 className="mb-0">{IMLocalized('holiday')} - {name}</h4>
                            <button className="btn btn-primary btn-sm" onClick={()=>setAddToggle(true)} >{IMLocalized('add')}</button>
                        </CardHeader>
                        {isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                        {!isLoading && list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {!isLoading && list.length !== 0 && 
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 320px)'}}>
                            {list.map((item,index)=>{
                                return(
                                    <ListGroupItem className='py-2 d-flex align-items-center justify-content-between' key={index}>
                                        <span>{index+1}. {item.name} </span>
                                        <div className='mr-1'>
                                            <button className="btn btn-info btn-sm" onClick={()=>{setEditToggle(true); setEditDetail(item)}} >{IMLocalized('edit')}</button>
                                            <button className="btn btn-danger btn-sm" onClick={()=>deleteHoliday(item.id,item.name)} >{IMLocalized('delete')}</button>
                                        </div>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
        <ModalCreateHoliday toggle={add_toggle} closeModal={()=>setAddToggle(false)} id={id} />
        <ModalEditHoliday toggle={edit_toggle} closeModal={()=>{setEditDetail(null); setEditToggle(false)}} detail={edit_detail} />
        </>
    )
}
export default HolidayDetail;