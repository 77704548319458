import myaxios from '../../axios';

export const HEADCOUNTAGGRIDHEADER_REQUEST = 'HEADCOUNTAGGRIDHEADER_REQUEST';
export const HEADCOUNTAGGRIDHEADER_SUCCESS = 'HEADCOUNTAGGRIDHEADER_SUCCESS';
export const HEADCOUNTAGGRIDHEADER_FAILURE = 'HEADCOUNTAGGRIDHEADER_FAILURE';


export const headcount_get_aggridheader = (id)=>{
    return dispatch=>{
        dispatch({
            type:'HEADCOUNTAGGRIDHEADER_REQUEST'
        });

        myaxios.get(`payroll/payroll/${id}/ReportGridHeader/`)
        .then((response)=>{
            dispatch({
                type:'HEADCOUNTAGGRIDHEADER_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'HEADCOUNTAGGRIDHEADER_FAILURE',
                payload:error
            })
        })
    }
}