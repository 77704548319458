import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const USERLEAVECANCELAPPLYLEAVE_REQUEST = 'USERLEAVECANCELAPPLYLEAVE_REQUEST';
export const USERLEAVECANCELAPPLYLEAVE_SUCCESS = 'USERLEAVECANCELAPPLYLEAVE_SUCCESS';
export const USERLEAVECANCELAPPLYLEAVE_FAILURE = 'USERLEAVECANCELAPPLYLEAVE_FAILURE';
export const USERLEAVECANCELAPPLYLEAVE_RESET = 'USERLEAVECANCELAPPLYLEAVE_RESET';

export const user_leave_cancel_apply_leave = (use_day_leave,comment)=>{
    return dispatch=>{
        dispatch({
            type:'USERLEAVECANCELAPPLYLEAVE_REQUEST'
        });

        myaxios.post('leave/self/apply/cancel_array/',{
            leaves:use_day_leave,
            comment:comment
        })
        .then((response)=>{
            dispatch({
                type:'USERLEAVECANCELAPPLYLEAVE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('cancel_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'USERLEAVECANCELAPPLYLEAVE_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('cancel_failure')
            });
        })
    }
}

export const reset_user_leave_cancel_apply_leave = () => dispatch=>{
    dispatch({
        type:'USERLEAVECANCELAPPLYLEAVE_RESET'
    });
} 