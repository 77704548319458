import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody, FormGroup, Input,FormFeedback,UncontrolledTooltip,  } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import moment from 'moment';
import {get_employee_postal_code} from '../../../action/employee/get_employee_postal_code';
import { usePrevious } from '../../../hook/usePrevious';
import { popError } from '../../../func/popError';
import {create_employee} from '../../../action/employee/create_employee';
import ModalCreateContract from './modal/ModalCreateContract';
import Swal from 'sweetalert2';
import ModalCreatePassport from './modal/ModalCreatePassport';
import ModalCreatePermit from './modal/ModalCreatePermit';
import ModalCreateBank from './modal/ModalCreateBank';
import ModalAssignLeaveApproval from './modal/ModalAssignLeaveApproval';
import ModalAssignClaimScheme from './modal/ModalAssignClaimScheme';
import ModalAssignClaimApproval from './modal/ModalAssignClaimApproval';
import ModalAssignLeaveScheme from './modal/ModalAssignLeaveScheme';
import ModalCreateFamily from './modal/ModalCreateFamily';
import ModalCreateChildren from './modal/ModalCreateChildren';
import {get_employee_relationship} from '../../../action/employee/get_employee_relationship';
import {get_leave_employee_noscheme} from '../../../action/leave/get_leave_employee_noscheme';
import {get_all_employee_available_leave} from '../../../action/employee/get_all_employee_available_leave';
import {get_employee_bank} from '../../../action/employee/get_employee_bank';
import {get_employee_passport} from '../../../action/employee/get_employee_passport';
import {get_employee_permit} from '../../../action/employee/get_employee_permit';
import {get_additional_setting} from '../../../action/additional_setting';
import {get_employee_list_withoutcontract} from '../../../action/employee/get_employee_list_withoutcontract';
import {get_employee_probation_list} from '../../../action/get_employee_probation_list';
import {get_employee_list} from '../../../action/employee/get_employee_list';
import {get_latest_employee} from '../../../action/employee/get_latest_employee';
import { NewMainContext } from '../../../context/NewMainContext';
import { get_employee_sg_child } from '../../../action/employee/get_employee_sg_child';
import BreadCrumbList from '../../breadcrumb/BreadCrumbList';
import OverlayLoading from '../../loading_component/OverlayLoading';


const mapStateToProps=(state)=>({
    latestlist:state.get_latest_employee.data,
    salutationlist:state.dropdown_list.salutation,
    nationalitylist:state.dropdown_list.countries,
    sg_identification_type:state.dropdown_list.s_g_identification_type,
    relationship:state.dropdown_list.relationship,
    racelist:state.dropdown_list.race,
    religionlist:state.dropdown_list.religion,
    daylist:state.dropdown_list.days_range_selector,
    genderlist:state.dropdown_list.gender,
    companylist:state.company_list.data,
    branchlist:state.get_branch_list.data,
    payment:state.dropdown_list.payment,
    base_data:state.get_base_setting.data,
    success:state.create_employee.data,
    errors:state.create_employee.errors,
    sg_address:state.get_employee_postal_code.address,
    permitlist:state.dropdown_list.work_permit,
    permit_success:state.create_new_employee_permit.data,
    permit_errors:state.create_new_employee_permit.errors,
    schemelist:state.get_leave_scheme_list.data,
    bank_payee:state.dropdown_list.bank_payee,
    daterange:state.general_daterange.data,
    leave_approval_list:state.get_leave_approval.data,
    claimschemelist:state.get_claim_scheme_list.data,
    claim_scheme_success:state.claim_assign_scheme2.data,
    claim_scheme_errors:state.claim_assign_scheme2.errors,
    claim_approval_list:state.get_claim_approval.data,
    relationshiplist:state.dropdown_list.user_profile_relationship,
    sgpr_employee_cpf_payment:state.dropdown_list.sgpr_employee_cpf_payment,
    isLoading:state.create_employee.isLoading,

    isLoading1:state.get_branch_list.isLoading,
    isLoading2:state.company_list.isLoading,
    isLoading3:state.dropdown_list.isLoading,
    isLoading4:state.get_latest_employee.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    get_employee_postal_code:(id)=>{
        dispatch(get_employee_postal_code(id))
    },
    create_employee: (salutation,name,employee_number,badge_number,email,nationality,id_type,identification_number,contact_number,gender,race,religion,relationship,company,branch,payment,is_pr,pr_startdate,date_of_birth,join_date,probation_end_date,country_of_residence,sg_postal_code,block_number,street_name,level_number,unit_number,postal_code,address,pr_cpf_contribution_type) => {
        dispatch(create_employee(salutation,name,employee_number,badge_number,email,nationality,id_type,identification_number,contact_number,gender,race,religion,relationship,company,branch,payment,is_pr,pr_startdate,date_of_birth,join_date,probation_end_date,country_of_residence,sg_postal_code,block_number,street_name,level_number,unit_number,postal_code,address,pr_cpf_contribution_type))
    },
    get_employee_list:()=>{
        dispatch(get_employee_list())
    },
    get_employee_probation_list:()=>{
        dispatch(get_employee_probation_list())
    },
    get_latest_employee:()=>{
        dispatch(get_latest_employee())
    },
    get_employee_list_withoutcontract:()=>{
        dispatch(get_employee_list_withoutcontract())
    },
    get_additional_setting:()=>{
        dispatch(get_additional_setting())
    },
    get_employee_permit:()=>{
        dispatch(get_employee_permit())
    },
    get_employee_passport:()=>{
        dispatch(get_employee_passport())
    },
    get_employee_bank:()=>{
        dispatch(get_employee_bank())
    },
    get_all_employee_available_leave:()=>{
        dispatch(get_all_employee_available_leave())
    },
    get_leave_employee_noscheme:()=>{
        dispatch(get_leave_employee_noscheme())
    },
    get_employee_relationship:()=>{
        dispatch(get_employee_relationship())
    },
    get_employee_sg_child:()=>{
        dispatch(get_employee_sg_child())
    }
})

const initialState={
    latest_emp:'',
    latest_badge:'',
    salutation:'',
    err_salutation:'',
    name:'',
    err_name:'',
    employee_number:'',
    err_employee_number:'',
    badge_number:'',
    err_badge_number:'',
    email:'',
    err_email:'',
    nationality:'',
    err_nationality:'',
    id_type:'',
    err_id_type:'',
    id_number:'',
    err_id_number:'',
    contact_number:'',
    err_contact_number:'',
    gender:'',
    err_gender:'',
    race:'',
    err_race:'',
    religion:'',
    err_religion:'',
    relationship:'',
    err_relationship:'',
    company:'',
    err_company:'',
    branch:'',
    err_branch:'',
    payment:'',
    err_payment:'',
    is_pr:false,
    pr_start_date:'',
    err_pr_start_date:'',
    pr_cpf_contribution_type:'STD',
    date_of_birth:'',
    err_date_of_birth:'',
    join_date:'',
    err_join_date:'',
    probation_end_date:'',
    err_probation_end_date:'',
    country_of_residence:'',
    err_country:'',
    sg_postal_code:'',
    err_sg_postal_code:'',
    block_number:'',
    err_block_number:'',
    street_name:'',
    err_street_name:'',
    unit_number:'',
    err_unit_number:'',
    level_number:'',
    err_level_number:'',
    postal_code:'',
    err_postal_code:'',
    address:'',
    err_address:'', 
    validate_ic:false,
}

function CreateEmployee(props){

    const { addItem, createemp_reset, setCreateEmpReset } = useContext(NewMainContext);

    const {sg_address,errors,success} = props;
    const [data,setData] = useState(initialState);
    const [require_bank,setRequireBank] = useState(false);
    const [require_permit,setRequirePermit] = useState(false);
    const [fin_number,setFinNumber] = useState('');
    const [success_id,setSuccessID] = useState(null);
    const [contract_toggle,setContractToggle] = useState(false);
    const [contract_start_date,setContractStartDate] = useState('');
    const [emp_gender,setEmpGender] = useState('');
    const [permit_toggle,setPermitToggle] = useState(false);
    const [passport_toggle,setPassportToggle] = useState(false);
    const [bank_toggle,setBankToggle] = useState(false);
    const [leave_scheme_toggle,setLeaveSchemeToggle] = useState(false);
    const [leave_approval_toggle,setLeaveApprovalToggle] = useState(false);
    const [claim_scheme_toggle,setClaimSchemeToggle] = useState(false);
    const [claim_approval_toggle,setClaimApprovalToggle] = useState(false);
    const [family_toggle,setFamilyToggle] = useState(false);
    const [child_toggle,setChildToggle] = useState(false);
    const [clear_child_step,setChildClearStep] = useState(false);
    const [default_amount,setDefaultAmount] = useState('');
    const [err_default_amount, setErrDefaultAmount] = useState('');
    const [default_choice,setDefaultChoice] = useState('');

    const prevErrors = usePrevious(errors);
    const prevSuccess = usePrevious(success);
    const prevSgAddress = usePrevious(sg_address);

    const badgeRef = useRef();
    const nameRef= useRef();
    const nationalityRef = useRef();
    const idnumberRef = useRef();
    const genderRef = useRef();
    const religionRef = useRef();
    const companyRef = useRef();
    const prstartdateRef = useRef();
    const paymentRef = useRef();
    const joindateRef = useRef();
    const addressRef = useRef();

    useEffect(()=>{
        if(props.latestlist !== null){
            setData(prevState=>({...prevState,
                latest_emp:props.latestlist.employee_number,
                latest_badge:props.latestlist.badge_number
            }));
        }
    },[props.latestlist])

    useEffect(()=>{
        if(props.base_data !== null){
            
            setDefaultChoice(props.base_data.default_probation_choice);
            setDefaultAmount(props.base_data.default_probation_amount);
            setErrDefaultAmount('');
        }
    },[props.base_data])

    useEffect(()=>{
        if(data.join_date !== '' && default_amount !== ''){
            if(default_choice === 'DAY'){
                const date = moment(data.join_date,'YYYY-MM-DD').add(parseInt(default_amount),'days').format('YYYY-MM-DD');
                setData(prevState=>({...prevState,
                    probation_end_date:date,
                    err_probation_end_date:'',
                }));
            }
            else if(default_choice === 'WEEK'){
                const date = moment(data.join_date,'YYYY-MM-DD').add((parseInt(default_amount)*7),'days').format('YYYY-MM-DD');
                setData(prevState=>({...prevState,
                    probation_end_date:date,
                    err_probation_end_date:'',
                }));
            }
            else{
                const date = moment(data.join_date,'YYYY-MM-DD').add(parseInt(default_amount),'months').format('YYYY-MM-DD');
                setData(prevState=>({...prevState,
                    probation_end_date:date,
                    err_probation_end_date:'',
                }));
            }
        }
    },[data.join_date,default_amount,default_choice])

    useEffect(()=>{
        if(prevSgAddress !== undefined && prevSgAddress !== sg_address){
            if(sg_address.length !== 0){
                setData(prevState =>({...prevState,
                    street_name:sg_address[0].ROAD_NAME,
                    err_street_name:'',
                    block_number:sg_address[0].BLK_NO,
                    err_block_number:''
                }));
            }
            else{
                popError(IMLocalized('address_not_found'))
            }
        }
    },[sg_address,prevSgAddress])

    useEffect(()=>{
        if(prevErrors !== undefined && prevErrors !== errors && errors !== null){
            if('name' in errors){
                setData(prevState =>({...prevState,
                    err_name:errors['name'].toString()
                }));
            }
            if('employee_number' in errors){
                setData(prevState =>({...prevState,
                    err_employee_number:errors['employee_number'].toString()
                }));

            }
            if('badge_number' in errors){
                setData(prevState =>({...prevState,
                    err_badge_number:errors['badge_number'].toString()
                }));
            }
            if('identification_number' in errors){
                setData(prevState =>({...prevState,
                    err_id_number:errors['identification_number'].toString()
                }));
            }
            if('pr_start_date' in errors){
                setData(prevState =>({...prevState,
                    err_pr_start_date:errors['pr_start_date'].toString()
                }));
            }
            if('email' in errors){
                setData(prevState =>({...prevState,
                    err_email:errors['email'].toString()
                }));
            }
            nameRef.current.scrollIntoView({block:'start',behavior:'smooth'});
            
        }
    },[prevErrors,errors])

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== success && success !== null){
            if(success.nationality !== 'SG'){
                setRequirePermit(!success.is_pr ? true : false);
                setFinNumber(!success.is_pr ? success.identification_number : '');
            }
            setRequireBank(success.payment === 'BANK' ? true : false);
            setContractToggle(true);
            setContractStartDate(success.join_date);
            setSuccessID(success.id);
            setEmpGender(success.gender);
        }
    },[prevSuccess,success])

    useEffect(()=>{
        if(createemp_reset !== 0){
            setData(initialState);
            setFinNumber('');
            setSuccessID(null);
            setContractToggle(false);
            setContractStartDate('');
            setEmpGender('');
            setDefaultAmount(props.base_data?.default_probation_amount ?? '');
            setDefaultChoice(props.base_data?.default_probation_choice ?? '');
            setErrDefaultAmount('');
            setCreateEmpReset(0);
        }
    },[createemp_reset,setCreateEmpReset,props.base_data])

    const autoFill = ()=>{
        const regex = /^[0-9]{6}$/;
        if(!regex.test(data.sg_postal_code)){
            setData(prevState=>({...prevState,err_sg_postal_code:IMLocalized('invalid_sg_postal_code')}));
        }
        else{
            props.get_employee_postal_code(data.sg_postal_code);
        }
    }

    const validateID=()=>{
        const disable_nric = process.env.REACT_APP_DISABLE_NRIC;
        let identification_number = data.id_number;
        identification_number = identification_number.toUpperCase();
        let i;
        let icArray =[];
        for(i=0;i<9;i++){
            icArray[i] = identification_number.charAt(i);
        }

        icArray[1] = parseInt(icArray[1],10)*2;
        icArray[2] = parseInt(icArray[2], 10) * 7;
        icArray[3] = parseInt(icArray[3], 10) * 6;
        icArray[4] = parseInt(icArray[4], 10) * 5;
        icArray[5] = parseInt(icArray[5], 10) * 4;
        icArray[6] = parseInt(icArray[6], 10) * 3;
        icArray[7] = parseInt(icArray[7], 10) * 2;

        let weight = 0;
        for(i=1;i<8;i++){
            weight += icArray[i];
        }

        var offset = (icArray[0] === 'T' || icArray[0] === 'G') ? 4:0;
        var temp = (offset+weight) % 11;
        var st = ["J","Z","I","H","G","F","E","D","C","B","A"];
        var fg = ["X","W","U","T","R","Q","P","N","M","L","K"];

        var theAlpha;
        if(icArray[0] === 'S' || icArray[0] === 'T'){
            theAlpha = st[temp];
        }
        else if (icArray[0] === 'F' || icArray[0] === 'G'){
            theAlpha = fg[temp];
        }

        if(identification_number.length !== 9){
            setData(prevState =>({...prevState,
                err_id_number:IMLocalized('invalid_identification_number'),
                validate_ic:false
            }));
        }
        else if(icArray[8] !== theAlpha && disable_nric === 'false'){
            setData(prevState =>({...prevState,
                validate_ic:false
            }));
        }
        else{
            setData(prevState =>({...prevState,
                validate_ic:true
            }));
        }
        
    }

    const onComplete=()=>{
        Swal.fire({
            title:IMLocalized('setup_is_completed'),
            type:'success',
            confirmButtonColor:'green',
            confirmButtonText:IMLocalized('ok!')
        })
        .then((result)=>{
            props.get_employee_relationship();
            props.get_employee_list();
            props.get_leave_employee_noscheme();
            props.get_all_employee_available_leave();
            props.get_employee_permit();
            props.get_employee_bank();
            props.get_employee_passport();
            props.get_employee_list_withoutcontract();
            props.get_additional_setting();
            props.get_latest_employee();
            props.get_employee_list();
            props.get_employee_probation_list();
            props.get_employee_sg_child();
            setData(initialState);
            setFinNumber('');
            setSuccessID(null);
            setContractToggle(false);
            setContractStartDate('');
            setEmpGender('');
            setDefaultAmount(props.base_data?.default_probation_amount ?? '');
            setDefaultChoice(props.base_data?.default_probation_choice ?? '');
            setErrDefaultAmount('');
        })
    }

    const onSubmit=()=>{
        let pr_start = null;
        let is_valid = true;
        let unit_number1 = data.unit_number;
        let level_number1 = data.level_number;
        let address1 = data.address;
        let postal_code1 = data.postal_code;
        let block_number1 = data.block_number;
        let street_name1 = data.street_name;
        let sg_postal_code1 = data.sg_postal_code;
        const regex = /^[0-9]{6}$/;

        if(data.country_of_residence !== ''){
            if(data.country_of_residence === 'SG'){
                if(unit_number1 === ''){
                    unit_number1 = null;
                }
                if(level_number1 === ''){
                    level_number1 = null;
                }

                if(sg_postal_code1 === ''){
                    setData(prevState =>({...prevState,
                        err_sg_postal_code:IMLocalized('sg_postal_code_is_required'),
                    }));
                    is_valid = false;
                }
                else if(!regex.test(data.sg_postal_code)){
                    setData(prevState =>({...prevState,
                        err_sg_postal_code:IMLocalized('invalid_sg_postal_code'),
                    }));
                    is_valid = false;
                }
                else{
                    address1  = null;
                    postal_code1 = null;
                }
            }
            else{
                if(address1 !== '' && postal_code1 !== ''){
                    sg_postal_code1= null;
                    block_number1 = null;
                    street_name1 = null;
                    unit_number1 = null;
                    level_number1 = null;
                }
                else{
                    if(address1 === ''){
                        setData(prevState =>({...prevState,
                            err_address:IMLocalized('address_is_required'),
                        }));
                        addressRef.current.scrollIntoView({block:'start',behavior:'smooth'})
                    }
                    if(postal_code1 === ''){
                        setData(prevState =>({...prevState,
                            err_postal_code:IMLocalized('postal_code_is_required'),
                        }));
                        addressRef.current.scrollIntoView({block:'start',behavior:'smooth'})
                    }
                }
            }
        }
        else{
            is_valid = false;
            setData(prevState =>({...prevState,
                err_country:IMLocalized('country_of_residence_is_required'),
            }));
            addressRef.current.scrollIntoView({block:'start',behavior:'smooth'})
        }

        if(is_valid === true){
            if(data.salutation !== '' && data.name !== '' && data.employee_number !== '' && data.badge_number !== '' && data.nationality !=='' && data.id_type !== '' && data.id_number !== ''  && data.gender !== '' && data.race !=='' && data.company !== '' && data.payment !== '' && data.date_of_birth !=='' && data.join_date !==''  ){
                let valid = true;
                if(data.is_pr === true){
                    if(data.pr_start_date !== ''){
                        pr_start = data.pr_start_date;
                    }
                    else{
                        setData(prevState =>({...prevState,
                            err_pr_start_date:IMLocalized('pr_start_date_is_required'),
                        }));
                        valid = false;
                    }
                }

                if(data.validate_ic === false){
                    setData(prevState =>({...prevState,
                        err_id_number:IMLocalized('invalid_identification_number'),
                    }));
                    valid = false;
                }

                if(valid === true){
                    props.create_employee(data.salutation,data.name,data.employee_number,data.badge_number,data.email,data.nationality,data.id_type,data.id_number,data.contact_number,data.gender,data.race,data.religion,data.relationship,data.company,data.branch,data.payment,data.is_pr,pr_start,data.date_of_birth,data.join_date,data.probation_end_date,data.country_of_residence,sg_postal_code1,block_number1,street_name1,level_number1,unit_number1,postal_code1,address1,data.pr_cpf_contribution_type);
                }
            }
            else{

                setData(prevState =>({...prevState,
                    err_join_date:data.join_date === '' ? IMLocalized('join_date_is_required'):'',
                    err_salutation:data.salutation === '' ? IMLocalized('salutation_is_required') : '',
                    err_name:data.name === '' ? IMLocalized('name_is_required') : '',
                    err_employee_number: data.employee_number === '' ? IMLocalized('employee_number_is_required') : '',
                    err_badge_number:data.badge_number === '' ? IMLocalized('badge_number_is_required') :'',
                    err_nationality:data.nationality === '' ? IMLocalized('nationality_is_required') : '',
                    err_id_number:data.id_number === '' ? IMLocalized('identification_number_is_required') :'',
                    // err_contact_number:data.contact_number === '' ?  IMLocalized('contact_number_is_required') : '',
                    err_gender:data.gender === '' ?  IMLocalized('gender_is_required') : '',
                    err_race:data.race === '' ? IMLocalized('race_is_required') : '',
                    err_company:data.company === '' ? IMLocalized('company_is_required') : '',
                    err_payment:data.payment === '' ? IMLocalized('payment_is_required') : '',
                    err_date_of_birth:data.date_of_birth === '' ? IMLocalized('date_of_birth_is_required') : '',
                    err_id_type:data.id_type === '' ? IMLocalized('id_type_is_required') : '',
                
                }));
                
                if(data.salutation === '' || data.name === ''){
                    nameRef.current.scrollIntoView({block:'start',behavior:'smooth'});
                }
                else if(data.employee_number === '' || data.badge_number === ''){
                    badgeRef.current.scrollIntoView({block:'start',behavior:'smooth'})
                }
                else if(data.id_type === '' || data.id_number === ''){
                    idnumberRef.current.scrollIntoView({block:'start',behavior:'smooth'})
                }
                else if(data.contact_number === '' || data.gender === ''){
                    genderRef.current.scrollIntoView({block:'start',behavior:'smooth'})
                }
                else if(data.race === ''){
                    religionRef.current.scrollIntoView({block:'start',behavior:'smooth'})
                }
                else if(data.company === ''){
                    companyRef.current.scrollIntoView({block:'start',behavior:'smooth'})
                }
                else if(data.payment === ''){
                    paymentRef.current.scrollIntoView({block:'start',behavior:'smooth'})
                }
                else if(data.join_date === ''){
                    joindateRef.current.scrollIntoView({block:'start',behavior:'smooth'});
                }
            }
        }
    }

    return(
        <>
            {props.isLoading1 || props.isLoading2 || props.isLoading3 || props.isLoading4 ? <OverlayLoading/> :
            <>
            <Container fluid>
                <Row className='border-bottom bg-white'>
                    <Col className='p-2'>
                        <BreadCrumbList list={[
                            { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                            { active: false, title:IMLocalized('employee'), onClick:()=>addItem('7','employee_list')},
                            { active: true, title:IMLocalized('create')}
                        ]}/>
                    </Col>
                </Row>
            </Container>
            <Container className='mt-2'>
                <Card>
                    <CardHeader><h4 className='mb-0'>{IMLocalized('new_employee')}</h4></CardHeader>
                    <CardBody>
                        <Row>
                            <Col lg="3">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='salutation'>{IMLocalized('salutation')}</label>
                                    <Input id="salutation" size="sm" type="select" value={data.salutation} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,salutation:value,err_salutation:''})) }} invalid={data.err_salutation.length > 0}>
                                        <option></option>
                                        {props.salutationlist.map((item,index)=>{
                                            return <option value={item.value} key={index}>{item.display}</option>
                                        })}
                                    </Input>
                                    <FormFeedback>{data.err_salutation}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg="9">
                                <div ref={nameRef} >
                                <FormGroup >
                                    <label className='form-control-label' htmlFor='name'>{IMLocalized('name')}</label>
                                    <Input type="text" id="name" size="sm" value={data.name} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,name:value,err_name:''})) }} invalid={data.err_name.length >0} />
                                    <FormFeedback>{data.err_name}</FormFeedback>
                                </FormGroup>
                                </div>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='employee_number'>{IMLocalized('employee_number')} <span className="text-danger">*</span> {data.latest_emp.length !== 0 && <span>({IMLocalized('last_employee_number')}: {data.latest_emp})</span>}  </label>
                                    <Input id="employee_number" type="text" size="sm" value={data.employee_number} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,employee_number:value,err_employee_number:''})) }} invalid={data.err_employee_number.length >0}/>
                                    <FormFeedback>{data.err_employee_number}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <div ref={badgeRef}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='badge_number'>{IMLocalized('badge_number')} <span className="text-danger">*</span> {data.latest_badge.length !== 0 && <span>({IMLocalized('last_badge_number')}: {data.latest_badge})</span>} </label>
                                        <Input id="badge_number" type="text" size="sm" value={data.badge_number} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,badge_number:value,err_badge_number:''})) }} invalid={data.err_badge_number.length >0}/>
                                        <FormFeedback>{data.err_badge_number}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='email' id="tooltipemail">{IMLocalized('email_address')}
                                        <UncontrolledTooltip target="tooltipemail" delay={0} placement="top" >
                                            {IMLocalized('email_tooltip')}
                                        </UncontrolledTooltip>
                                    </label>
                                    <Input size="sm" id="email" type="email" value={data.email} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,email:value,err_email:''})) }} invalid={data.err_email.length >0} />
                                    <FormFeedback>{data.err_email}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <div ref={nationalityRef}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='nationality'>{IMLocalized('nationality')}</label>
                                        <Input type="select" size="sm" id="nationality" value={data.nationality} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,nationality:value,err_nationality:''})) }} invalid={data.err_nationality.length >0}>
                                            <option></option>
                                            {props.nationalitylist.map((item,index)=>{
                                                return <option value={item.value} key={index}>{item.display_name}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{data.err_nationality}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='id_type'>{IMLocalized('id_type')}</label>
                                    <Input size="sm" id="id_type" type="select" value={data.id_type} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,id_type:value,err_id_type:''})) }} invalid={data.err_id_type.length >0}>
                                        <option></option>
                                        {props.sg_identification_type.map((item,index)=>{
                                            return <option value={item.value} key={index}>{item.display}</option>
                                        })}
                                    </Input>
                                    <FormFeedback>{data.err_id_type}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <div ref={idnumberRef}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='id_number'>{IMLocalized('identification_number')}</label>
                                        <Input size="sm" id="id_number" type="text" value={data.id_number} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,id_number:value,err_id_number:''})) }} invalid={data.err_id_number.length >0} onBlur={validateID} />
                                        <FormFeedback>{data.err_id_number}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </Col>
                            {data.nationality !== 'SG' && data.nationality !== '' &&
                            <Col lg="6" className="d-flex align-items-center">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id="pr_check" name='is_pr' className="custom-control-input" checked={data.is_pr} onChange={(e)=>{const checked = e.target.checked  ; setData(prevState=>({...prevState,is_pr:checked})) }} />
                                    <label className="custom-control-label" htmlFor="pr_check"><strong>{IMLocalized('is_pr')}?</strong></label>
                                </div>
                            </Col>}
                            {data.is_pr === true &&
                            <>
                            <Col lg="6">
                                <div ref={prstartdateRef}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='pr_start_date'>{IMLocalized('pr_start_date')}</label>
                                        <Input size="sm" type="date" value={data.pr_start_date} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,pr_start_date:value,err_pr_start_date:''})) }} invalid={data.err_pr_start_date.length >0}/>
                                        <FormFeedback>{data.err_pr_start_date}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='pr_cpf_contribution_type'>{IMLocalized('pr_cpf_contribution_type')}</label>
                                    <Input size="sm" type="select"  value={data.pr_cpf_contribution_type} id="pr_cpf_contribution_type" onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,pr_cpf_contribution_type:value})) }}>
                                        <option></option>
                                        {props.sgpr_employee_cpf_payment.map((item,index)=>{
                                            return <option value={item.value} key={index}>{item.display}</option>
                                        })}
                                    </Input>
                                </FormGroup>
                            </Col>
                            </>}
                            <Col lg="6">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='contact_number'>{IMLocalized('contact_number')}</label>
                                    <Input size="sm" type="tel" id="contact_number" value={data.contact_number} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,contact_number:value,err_contact_number:''})) }} invalid={data.err_contact_number.length >0} />
                                    <FormFeedback>{data.err_contact_number}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col  lg="6">
                                <div ref={genderRef}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='gender'>{IMLocalized('gender')}</label>
                                        <Input type="select" size="sm" id="gender" value={data.gender} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,gender:value,err_gender:''})) }} invalid={data.err_gender.length >0} >
                                            <option></option>
                                            {props.genderlist.map((item,index)=>{
                                                return <option value={item.value} key={index}>{item.display}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{data.err_gender}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='race'>{IMLocalized('race')}</label>
                                    <Input size="sm" id="race" type="select" value={data.race} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,race:value,err_race:''})) }} invalid={data.err_race.length >0} >
                                        <option></option>
                                        {props.racelist.map((item,index)=>{
                                            return <option value={item.value} key={index}>{item.display}</option>
                                        })}
                                    </Input>
                                    <FormFeedback>{data.err_race}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <div ref={religionRef}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='religion'>{IMLocalized('religion')}</label>
                                        <Input size="sm" id="religion" type="select" value={data.religion} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,religion:value,err_religion:''})) }} invalid={data.err_religion.length >0} >
                                            <option></option>
                                            {props.religionlist.map((item,index)=>{
                                                return <option value={item.value} key={index}>{item.display}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{data.err_religion}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='relationship'>{IMLocalized('relationship')}</label>
                                    <Input size="sm" id="relationship" type="select" value={data.relationship} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,relationship:value,err_relationship:''})) }} invalid={data.err_relationship.length >0} >
                                        <option></option>
                                        {props.relationship.map((item,index)=>{
                                            return <option value={item.value} key={index}>{item.display}</option>
                                        })}
                                    </Input>
                                    <FormFeedback>{data.err_relationship}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <div ref={companyRef}>
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor='company'>{IMLocalized('company')}</label>
                                        <Input size="sm" type="select" id="company" value={data.company} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,company:value,err_company:''})) }} invalid={data.err_company.length>0} >
                                            <option></option>
                                            {props.companylist.map((item,index)=>{
                                                return <option value={item.id} key={index}>{item.name}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{data.err_company}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor='branch'>{IMLocalized('branch')}</label>
                                    <Input size="sm" type="select" id="branch" value={data.branch} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,branch:value,err_branch:''})) }} invalid={data.err_branch.length>0}>
                                        <option></option>
                                        {props.branchlist.map((item,index)=>{
                                            return <option value={item.id} key={index}>{item.name}</option>
                                        })}
                                    </Input>
                                    <FormFeedback>{data.err_branch}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <div ref={paymentRef}>
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor='payment'>{IMLocalized('payment')}</label>
                                        <Input size="sm" type="select" id="payment" value={data.payment} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,payment:value,err_payment:''})) }} invalid={data.err_payment.length >0} >
                                            <option></option>
                                            {props.payment.map((item,index)=>{
                                                return <option value={item.value} key={index}>{item.display}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{data.err_payment}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='date_of_birth'>{IMLocalized('date_of_birth')}</label>
                                    <Input size="sm" type="date" id="date_of_birth" value={data.date_of_birth} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,date_of_birth:value,err_date_of_birth:''})) }} invalid={data.err_date_of_birth.length >0} max={moment(new Date()).format('YYYY-MM-DD')} />
                                    <FormFeedback>{data.err_date_of_birth}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader><h4 className='mb-0'>{IMLocalized('date')}</h4></CardHeader>
                    <CardBody>
                        <Row>
                            <Col lg="4">
                                <div ref={joindateRef}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='join_date'>{IMLocalized('employee')} {IMLocalized('join_date')}</label>
                                        <Input size="sm" type="date" id="join_date" value={data.join_date} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,join_date:value,err_join_date:''})) }} invalid={data.err_join_date.length >0} />
                                        <FormFeedback>{data.err_join_date}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='probation_period'>{IMLocalized('probation_period')}</label>
                                    <div className='input-group input-group-sm'>
                                        <Input type="number" size="sm" min="0" name="default_amount" id="probation_period" value={default_amount} onChange={(e)=>{const value = e.target.value ; setDefaultAmount(value) }} invalid={err_default_amount.length >0} />
                                        <div className='input-group-prepend'>
                                            <Input type="select" size="sm" value={default_choice} id="default_choice" onChange={(e)=>{const value = e.target.value ;setDefaultChoice(value) }}>
                                                {props.daylist.map((item,index)=>{
                                                    return <option value={item.value} key={index }>{IMLocalized(item.value)}</option>
                                                })}
                                            </Input>
                                        </div>
                                    </div>
                                    <FormFeedback>{err_default_amount}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='probation_end_date'>{IMLocalized('probation_end_date')}</label>
                                    <Input size="sm" type="date" id="probation_end_date" value={data.probation_end_date} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,probation_end_date:value,err_probation_end_date:''})) }} invalid={data.err_probation_end_date.length >0} />
                                    <FormFeedback>{data.err_probation_end_date}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader><h4 className="mb-0">{IMLocalized('employee_address')}</h4></CardHeader>
                    <CardBody>
                        <Row>
                            <Col lg="6">
                                <div ref={addressRef}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='country_of_residence' >{IMLocalized('country_of_residence')}</label>
                                        <Input size="sm" type="select" id="country_of_residence" value={data.country_of_residence} onChange={(e)=>{ const value = e.target.value ;setData(prevState => ({...prevState,country_of_residence:value,err_country:''}))}} invalid={data.err_country.length >0}> 
                                            <option></option>
                                            {props.nationalitylist.map((item,index)=>{
                                                return <option value={item.value} key={index}>{item.display_name}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{data.err_country}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </Col>
                            {data.country_of_residence ==='SG' &&
                            <>
                            <Col lg="6">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='sg_postal_code'>{IMLocalized('sg_postal_code')}</label>
                                    <div className='input-group'>
                                        <Input size="sm" id="sg_postal_code" type="number" value={data.sg_postal_code} onChange={(e)=>{ const value = e.target.value; setData(prevState=>({...prevState,sg_postal_code:value,err_sg_postal_code:''}))}} invalid={data.err_sg_postal_code.length >0} />
                                        <div className='input-group-append'>
                                            <button className="btn btn-primary btn-sm" onClick={()=>autoFill()}>{IMLocalized('autofill')}</button>
                                        </div>
                                        <FormFeedback>{data.err_sg_postal_code}</FormFeedback>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='block_number'>{IMLocalized('block_number')}</label>
                                    <Input size="sm" id="block_number" type="text" value={data.block_number} onChange={(e)=>{ const value = e.target.value; setData(prevState=>({...prevState,block_number:value,err_block_number:''}))}} invalid={data.err_block_number.length >0} />
                                    <FormFeedback>{data.err_block_number}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='street_name'>{IMLocalized('street_name')}</label>
                                    <Input size="sm" id="street_name" type="text" value={data.street_name} onChange={(e)=>{const value = e.target.value ;setData(prevState=>({...prevState,street_name:value,err_street_name:''}))}} invalid={data.err_street_name.length >0} />
                                    <FormFeedback>{data.err_street_name}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='level_number'>{IMLocalized('level_number')}</label>
                                    <Input size="sm" id="level_number" type="text" value={data.level_number} onChange={(e)=>{const value = e.target.value ;setData(prevState=>({...prevState,level_number:value,err_level_number:''}))}} invalid={data.err_level_number.length >0} />
                                    <FormFeedback>{data.err_level_number}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='unit_number'>{IMLocalized('unit_number')}</label>
                                    <Input size="sm" id="unit_number" type="text" value={data.unit_number} onChange={(e)=>{const value = e.target.value ;setData(prevState=>({...prevState,unit_number:value,err_unit_number:''}))}} invalid={data.err_unit_number.length >0} />
                                    <FormFeedback>{data.err_unit_number}</FormFeedback>
                                </FormGroup>
                            </Col>
                            </>}
                            {data.country_of_residence !== 'SG' && data.country_of_residence !== '' &&
                            <>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor='postal_code'>{IMLocalized('postal_code')}</label>
                                    <Input size="sm" type="number" id="postal_code" value={data.postal_code} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,postal_code:value,err_postal_code:''})) }} invalid={data.err_postal_code.length>0} />
                                    <FormFeedback>{data.err_postal_code}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor='address'>{IMLocalized('address')}</label>
                                    <Input size="sm" id="address" type="textarea" value={data.address} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,address:value,err_address:''})) }} invalid={data.err_address.length>0} />
                                    <FormFeedback>{data.err_address}</FormFeedback>
                                </FormGroup>
                            </Col>
                            </>}
                        </Row>
                    </CardBody>
                </Card>
                <div className='create_btn_parent'>
                    {props.isLoading ? <i className="fas fa-spinner fa-spin text-success create_btn "/> :
                    <i className='fas fa-plus-circle text-success cursor-pointer create_btn' onClick={()=>onSubmit()}  />}
                </div>
            </Container>
            <ModalCreateContract path="create_employee" is_new={true} toggle={contract_toggle} id={success_id} join_date={contract_start_date} 
            closeModal={()=>{
                Swal.fire({
                    type:"warning",
                    title:IMLocalized('do_you_really_want_to_skip_employee_contract'),
                    showCancelButton:true,
                    confirmButtonText:IMLocalized('yes!'),
                    cancelButtonText:IMLocalized('no!')
                })
                .then((result)=>{
                    if(result.value){
                        setContractToggle(false); 
                        if(require_permit === true){
                            setPermitToggle(true);
                        }
                        else{
                            setPassportToggle(true);
                        } 
                        props.get_employee_list_withoutcontract();
                    }
                })
            }} 
            onSuccess={()=>{
                if(contract_toggle === true){
                    setContractToggle(false); 
                    if(require_permit === true){
                        setPermitToggle(true);
                    }
                    else{
                        setPassportToggle(true);
                    } 
                }
            }}   
            />
            <ModalCreatePermit id={success_id} toggle={permit_toggle} fin_number={fin_number} 
            onSuccess={()=>{
                if(permit_toggle === true){
                    setPassportToggle(true);
                    setRequirePermit(false);
                    setPermitToggle(false);
                    setFinNumber('');
                }
                
            }} 
            closeModal={()=>{
                Swal.fire({
                    type:"warning",
                    title:IMLocalized('do_you_really_want_to_skip_employee_permit'),
                    showCancelButton:true,
                    confirmButtonText:IMLocalized('yes!'),
                    cancelButtonText:IMLocalized('no!')
                })
                .then((result)=>{
                    if(result.value){
                        setPermitToggle(false);
                        setPassportToggle(true);
                        setRequirePermit(false);
                        setFinNumber('');
                    }
                })
            }} 
            />
            <ModalCreatePassport id={success_id} toggle={passport_toggle} 
            onSuccess={()=>{
                if(passport_toggle === true){
                    setPassportToggle(false);
                    if(require_bank === true){
                        setBankToggle(true);
                    }
                    else{
                        setLeaveSchemeToggle(true);
                    }
                }
            }} 
            closeModal={()=>{
                Swal.fire({
                    type:"warning",
                    title:IMLocalized('do_you_really_want_to_skip_employee_passport'),
                    showCancelButton:true,
                    confirmButtonColor:IMLocalized('yes!'),
                    cancelButtonText:IMLocalized('no!')
                })
                .then((result)=>{
                    if(result.value){
                        setPassportToggle(false);
                        if(require_bank === true){
                            setBankToggle(true);
                        }
                        else{
                            setLeaveSchemeToggle(true);
                        }
                    }
                })
            }}  
            />
            <ModalCreateBank toggle={bank_toggle} id={success_id}  
            onSuccess={()=>{
                if(bank_toggle === true){
                    setBankToggle(false);
                    setLeaveSchemeToggle(true);
                    setRequireBank(false);
                }
            }}
            closeModal={()=>{
                Swal.fire({
                    type:"warning",
                    title:IMLocalized('do_you_really_want_to_skip_employee_bank'),
                    showCancelButton:true,
                    confirmButtonText:IMLocalized('yes!'),
                    cancelButtonText:IMLocalized('no!')
                })
                .then((result)=>{
                    if(result.value){
                        setBankToggle(false);
                        setLeaveSchemeToggle(true);
                        setRequireBank(false);
                    }
                })
            }}  
            />
            <ModalAssignLeaveScheme toggle={leave_scheme_toggle} id={success_id} 
            onSuccess={()=>{
                if(leave_scheme_toggle === true){
                    setLeaveApprovalToggle(true); 
                    setLeaveSchemeToggle(false);
                }
            }}
            closeModal={()=>{
                Swal.fire({
                    type:"warning",
                    title:IMLocalized('do_you_really_want_to_skip_employee_leave_scheme'),
                    showCancelButton:true,
                    confirmButtonText:IMLocalized('yes!'),
                    cancelButtonText:IMLocalized('no!')
                })
                .then((result)=>{
                    if(result.value){
                        setLeaveApprovalToggle(true); 
                        setLeaveSchemeToggle(false);
                    }
                })
            }}    
            />
            <ModalAssignLeaveApproval toggle={leave_approval_toggle} id={success_id} join_date={contract_start_date} type="create_employee"  
            onSuccess={()=>{
                if(leave_approval_toggle === true){
                    setLeaveApprovalToggle(false); 
                    setClaimSchemeToggle(true);
                }
            }} 
            closeModal={()=>{
                Swal.fire({
                    type:"warning",
                    title:IMLocalized('do_you_really_want_to_skip_assign_leave_approval'),
                    showCancelButton:true,
                    confirmButtonText:IMLocalized('yes!'),
                    cancelButtonText:IMLocalized('no!')
                })
                .then((result)=>{
                    if(result.value){
                        setLeaveApprovalToggle(false); 
                        setClaimSchemeToggle(true);
                    }
                })
            }}
            />
            <ModalAssignClaimScheme toggle={claim_scheme_toggle} id={success_id} onSuccess={()=>{setClaimSchemeToggle(false); setClaimApprovalToggle(true)}} 
            closeModal={()=>{
                Swal.fire({
                    type:"warning",
                    title:IMLocalized('do_you_really_want_to_skip_employee_claim_scheme'),
                    showCancelButton:true,
                    confirmButtonText:IMLocalized('yes!'),
                    cancelButtonText:IMLocalized('no!')
                })
                .then((result)=>{
                    if(result.value){
                        setClaimSchemeToggle(false); 
                        setClaimApprovalToggle(true);
                    }
                })
            }}
            />
            <ModalAssignClaimApproval toggle={claim_approval_toggle} id={success_id} join_date={contract_start_date} type="create_employee" 
            onSuccess={()=>{
                if(claim_approval_toggle === true){
                    setClaimApprovalToggle(false); 
                    setFamilyToggle(true);
                }
            }} 
            closeModal={()=>{
                Swal.fire({
                    type:"warning",
                    title:IMLocalized('do_you_really_want_to_skip_assign_claim_approval'),
                    showCancelButton:true,
                    confirmButtonText:IMLocalized('yes!'),
                    cancelButtonText:IMLocalized('no!')
                })
                .then((result)=>{
                    if(result.value){
                        setClaimApprovalToggle(false); 
                        setFamilyToggle(true);
                    }
                })
            }}
            />
            <ModalCreateFamily toggle={family_toggle} id={success_id} 
            onSuccess={()=>{
                if(family_toggle === true){
                    setChildToggle(true);
                    setFamilyToggle(false);
                }
            }} 
            closeModal={()=>{
                Swal.fire({
                    type:"warning",
                    title:IMLocalized('do_you_really_want_to_skip_family_contact'),
                    showCancelButton:true,
                    confirmButtonText:IMLocalized('yes!'),
                    cancelButtonText:IMLocalized('no!')
                })
                .then((result)=>{
                    if(result.value){
                        setChildToggle(true);
                        setFamilyToggle(false);
                    }
                })
            }}
            />
            <ModalCreateChildren toggle={child_toggle} id={success_id} clear_step={clear_child_step} gender={emp_gender} setChildClearStep={setChildClearStep}
            onSuccess={()=>{
                if(child_toggle === true){
                    Swal.fire({
                        type:"warning",
                        title:IMLocalized('do_you_really_want_to_continue_create_employee_child'),
                        showCancelButton:true,
                        confirmButtonText:IMLocalized('yes!'),
                        cancelButtonText:IMLocalized('no!')
                    })
                    .then((result)=>{
                        if(!result.value){
                            setChildToggle(false);
                            onComplete();
                        }
                        else{
                            setChildClearStep(true);
                        }
                    })
                }
            }}  
            closeModal={()=>{
                Swal.fire({
                    type:"warning",
                    title:IMLocalized('do_you_really_want_to_skip_employee_child'),
                    showCancelButton:true,
                    cancelButtonText:IMLocalized('no!'),
                    confirmButtonText:IMLocalized('yes!')
                })
                .then((result)=>{
                    if(result.value){
                        setChildToggle(false);
                        onComplete();
                    }
                })
            }} 
            />
            </>}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(CreateEmployee);