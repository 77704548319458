import * as validate_pay from '../../action/claim/validate_claim_pay';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const validate_claim_pay = (state=initialState,action)=>{
    switch(action.type){
        case validate_pay.VALIDATECLAIMPAY_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case validate_pay.VALIDATECLAIMPAY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case validate_pay.VALIDATECLAIMPAY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case validate_pay.VALIDATECLAIMPAY_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default validate_claim_pay;

