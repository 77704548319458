import * as first from '../action/first_time_password';

const initialState={
    isLoading:false,
    data:null,
    errors:[]
}

const first_time_password = (state=initialState,action)=>{
    switch(action.type){
        case first.FIRSTTIMEPASS_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
            }
        case first.FIRSTTIMEPASS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case first.FIRSTTIMEPASS_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default first_time_password;