import myaxios from '../../axios';


export const GETEMPCERTIFICATION_REQUEST = 'GETEMPCERTIFICATION_REQUEST';
export const GETEMPCERTIFICATION_SUCCESS = 'GETEMPCERTIFICATION_SUCCESS';
export const GETEMPCERTIFICATION_FAILURE = 'GETEMPCERTIFICATION_FAILURE';

export const get_employee_certification = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETEMPCERTIFICATION_REQUEST'
        });

        myaxios.get('employee/certification/')
        .then((response)=>{
            dispatch({
                type:'GETEMPCERTIFICATION_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPCERTIFICATION_FAILURE',
                payload:error
            })
        })
    }
}