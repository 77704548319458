import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Col, Container, Input, Row } from 'reactstrap';
import { user_apply_leave_list } from '../../../action/user/user_apply_leave_list';
import { user_get_all_leave_list } from '../../../action/user/user_get_all_leave_list';
import { user_get_available_leave } from '../../../action/user/user_get_available_leave';
import { reset_user_leave_cancel_apply_leave, user_leave_cancel_apply_leave } from '../../../action/user/user_leave_cancel_apply_leave';
import { isFirstColumn } from '../../ag-grid_component/isFirstColumn';
import { getDateFormat } from '../../../func/getDateFormat';
import { popError } from '../../../func/popError';
import { IMLocalized } from '../../../language/IMLocalized';
import OverlayLoading from '../../loading_component/overlay_loading';
import ModalUserApplyLeave from './modal/ModalUserApplyLeave';
import Swal from 'sweetalert2';
import ModalViewLeaveDetail from './modal/ModalViewLeaveDetail';
import { get_user_leave_detail } from '../../../action/user/get_user_leave_detail';
import { NewMainContext } from '../../../context/NewMainContext';
import { LeaveStatusRenderer } from '../../../func/LeaveStatusRenderer';
import { user_get_all_apply_leave_list } from '../../../action/user/user_get_all_apply_leave_list';

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    suppressRowClickSelection:true,
    defaultColDef:{
        resizable:true,
        sortable:true,
    }
}

const initialState={
    selected:'5',
    datalist:[],
    detail_id:null,
    detail_toggle:false,

}

function UserLeaveTable(){

    const applylist = useSelector(state=>state.user_apply_leave_list.data);
    const cancel_success = useSelector(state=>state.user_leave_cancel_apply_leave.data);
    const isLoading = useSelector(state=>state.user_apply_leave_list.isLoading);
    const isLoading1 = useSelector(state=>state.get_general_color.isLoading);

    const dispatch = useDispatch();
    const {action_toggle, addItem, leave_table_reset, setLeaveTableReset} = useContext(NewMainContext);
    const [state,setState] = useState(initialState);
    const [apply_toggle,setApplyToggle] = useState(false);
    const [is_pending,setIsPending] = useState(false);


    useEffect(()=>{
        if(leave_table_reset === true){
            setState(initialState);
            setLeaveTableReset(false);
        }
    },[leave_table_reset,setLeaveTableReset])

    useEffect(()=>{
        if(is_pending && cancel_success != null){
            dispatch(user_apply_leave_list());
            dispatch(user_get_available_leave());
            dispatch(user_get_all_leave_list());
            dispatch(user_get_all_apply_leave_list());
            setIsPending(false);
            dispatch(reset_user_leave_cancel_apply_leave());

        }
    },[is_pending,cancel_success,dispatch])

    useEffect(()=>{

        const arr = applylist.map((item)=>{

            const leave_date = item.from_date === item.to_date ? getDateFormat(item.from_date) : `${getDateFormat(item.from_date)} - ${getDateFormat(item.to_date)}`;
            const leave_name = item.leave_setting.name;
            return {...item,leave_name,leave_date}
        }).sort((a,b)=> new Date(b.created_date).getTime() - new Date(a.created_date).getTime());

        setState(prevState=>({...prevState,datalist:arr}));

    },[applylist])


    const onCellFocused=(params)=>{
        if(params?.column?.colDef?.field != null && params.column.colDef.field !== 'select'){
            const focusedCell = gridOptions.api.getFocusedCell();
            const cellValue = gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex)
            const id = cellValue.data.id;
            const rowPinned = focusedCell.rowPinned;
            if(rowPinned !== 'bottom'){
                setState(prevState=>({
                    ...prevState,
                    detail_toggle:true
                }))
                dispatch(get_user_leave_detail(id))
            }                         
        }
    }

    const cancelPending=()=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0){
            Swal.fire({
                title:IMLocalized('cancelling_leave'),
                input:'textarea',
                text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
                showCancelButton:true,
            })
            .then(result=>{
                if('value' in result){
                    let memo = result.value;
                    const arr = data.map(item=>item.id);
                    setIsPending(true);
                    dispatch(user_leave_cancel_apply_leave(arr,memo));
                }
            })
        }
        else{
            popError(IMLocalized('select_leave_to_cancel'));
        }
    }

    let columnDefs = [];
    if(state.selected === '0' || state.selected === '1' ){
        columnDefs = [
            {
                headerName:IMLocalized('select'),
                pinned:'left',
                field:'select',
                width:150,
                headerCheckboxSelection: isFirstColumn,
                checkboxSelection: isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
            },
            {
                headerName:IMLocalized('leave_date'),
                field:'leave_date',
                width:250,
                filter:false,
                comparator:(valueA,ValueB,nodeA,nodeB)=>{
                    const date1 = new Date(nodeA.data.from_date) 
                    const date2 = new Date(nodeB.data.from_date);
                    return date2 -date1
                }
            },
            {
                headerName:IMLocalized('leave_type'),
                field:'leave_setting.name',
                width:250
            },
            {   
                headerName:IMLocalized('leave_amount'),
                field:'amount',
                width:250
            },
            {
                headerName:IMLocalized('status'),
                field:'status',
                width:250,
                cellRendererFramework:LeaveStatusRenderer
            }
        ]
    }
    else{
        columnDefs=[
            {
                headerName:IMLocalized('leave_date'),
                field:'leave_date',
                width:250,
                filter:false,
                comparator:(valueA,ValueB,nodeA,nodeB)=>{
                    const date1 = new Date(nodeA.data.from_date) 
                    const date2 = new Date(nodeB.data.from_date);
                    return date2 -date1
                }
            },
            {
                headerName:IMLocalized('leave_type'),
                field:'leave_setting.name',
                width:250
            },
            {   
                headerName:IMLocalized('leave_amount'),
                field:'amount',
                width:250
            },
            {
                headerName:IMLocalized('status'),
                field:'status',
                width:250,
                cellRendererFramework:LeaveStatusRenderer
            }
        ]
    }

    const row1 = state.datalist.filter(item=>{
        if(state.selected === '0'){
            return item.status === 'PENDING'
        }
        else if(state.selected === '1'){
            return item.status === 'APPROVED'
        }
        else if(state.selected === '2'){
            return item.status === 'REJECTED'
        }
        else if(state.selected === '3'){
            return item.status === 'CANCELLED'
        }
        else if(state.selected === '4'){
            return item.status === 'CONSUMED'
        }
        return item;
    })

    const pinnedData = [];
    if(row1.length !== 0){
        const amount = row1.reduce((sum,item)=>{
            return sum+item.amount;
        },0);

        pinnedData.push({amount,leave_date:IMLocalized('total')})
    }

    return(
        <>
        {isLoading || isLoading1 ? <OverlayLoading/> :
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('leave_table')}</BreadcrumbItem>
                    </Breadcrumb>
                    <div className='ml-2'>
                        <button className='btn btn-primary btn-sm' onClick={()=>setApplyToggle(true)}>{IMLocalized('apply_leave')}</button>
                    </div>
                </Col>
            </Row>
            <Row className='mt-2 justify-content-center'>
                <Col className='d-flex align-items-center'>
                    <h3 className='mb-0'>
                        {state.selected === '5' && IMLocalized('all_leave_list')}
                        {state.selected === '0' && IMLocalized('pending_leave_list')}
                        {state.selected === '1' && IMLocalized('approved_leave_list')}
                        {state.selected === '2' && IMLocalized('rejected_leave_list')}
                        {state.selected === '3' && IMLocalized('cancelled_leave_list')}
                        {state.selected === '4' && IMLocalized('consumed_leave_list')}
                    </h3>
                </Col>
                <Col className='col-auto d-flex'>
                    {state.selected === '0' || state.selected === '1' ?  <div className='mr-2'><button className='btn btn-danger btn-sm'  onClick={()=>cancelPending()}>{IMLocalized('cancel')}</button></div> : null}
                    <Input type="select" size="sm" name="selected" value={state.selected} 
                    onChange={(e)=>{
                        const value = e.target.value;
                        setState(prevState=>({...prevState,selected:value}));
                    }}>
                        <option value="5">{IMLocalized('ALL')}</option>
                        <option value='0'>{IMLocalized('PENDING')}</option>
                        <option value="1">{IMLocalized('APPROVED')}</option>
                        <option value="2">{IMLocalized('REJECTED')}</option>
                        <option value="3">{IMLocalized('CANCELLED')}</option>
                        <option value="4">{IMLocalized('CONSUMED')}</option>
                    </Input>
                </Col>
            </Row>
            <div className='mt-2 ag-theme-balham' style={{height: action_toggle ? 'calc(100vh - 300px)' : 'calc(100vh - 264px)',width:'100%'}}>
                <AgGridReact
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                pinnedBottomRowData={pinnedData}
                rowData={row1}
                suppressDragLeaveHidesColumns={true}
                suppressSizeToFit={true}
                suppressColumnMoveAnimation={false}
                overlayNoRowsTemplate = {IMLocalized('no_data')}
                onCellFocused={onCellFocused}
                />
            </div>
        </Container>
        <ModalUserApplyLeave toggle={apply_toggle} closeModal={()=>setApplyToggle(false)}/>
        <ModalViewLeaveDetail toggle={state.detail_toggle} 
        closeModal={()=>{
            setState(prevState=>({
                ...prevState,
                detail_toggle:false,
            })) 
            gridOptions.api.clearFocusedCell();
        }} />
        </>}
        </>
    )
}
export default UserLeaveTable;