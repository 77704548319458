import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const WIZARDVALIDATESECTION_REQUEST ='WIZARDVALIDATESECTION_REQUEST';
export const WIZARDVALIDATESECTION_SUCCESS ='WIZARDVALIDATESECTION_SUCCESS';
export const WIZARDVALIDATESECTION_FAILURE ='WIZARDVALIDATESECTION_FAILURE';


export const wizard_validate_section = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDVALIDATESECTION_REQUEST'
        });

        myaxios.post('company/section/array_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDVALIDATESECTION_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDVALIDATESECTION_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}