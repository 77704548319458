import * as applyclaim from '../../action/employee/employee_apply_claim';

const initialState={
    errors:null,
    isLoading:false,
    data:null
}

const employee_apply_claim = (state=initialState,action)=>{
    switch(action.type){
        case applyclaim.EMPAPPLYCLAIM_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case applyclaim.EMPAPPLYCLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case applyclaim.EMPAPPLYCLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_apply_claim;
