import * as managerapprove from '../../action/approvals/calendar_leave_manager_approve';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const calendar_leave_manager_approve = (state=initialState,action)=>{
    switch(action.type){
        case managerapprove.CALENDARMANAGERAPPROVE_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case managerapprove.CALENDARMANAGERAPPROVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case managerapprove.CALENDARMANAGERAPPROVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default calendar_leave_manager_approve;