import * as shg from '../../action/employee/update_employee_shg';

const initialState={
    isLoading:false,
    data:null,
    errors:null,
}

const update_employee_shg = (state=initialState,action)=>{
    switch(action.type){
        case shg.UPDATEEMPSHG_REQUEST:
            return{
                isLoading:true,
                data:null,
                errors:null
            }
        case shg.UPDATEEMPSHG_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case shg.UPDATEEMPSHG_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default update_employee_shg;