import * as variance from '../../action/report/payroll_variance_company_payroll';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const payroll_variance_company_payroll = (state=initialState,action)=>{
    switch(action.type){
        case variance.VARIANCECOMPANYPAYROLL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null
            }
        case variance.VARIANCECOMPANYPAYROLL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case variance.VARIANCECOMPANYPAYROLL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default payroll_variance_company_payroll;