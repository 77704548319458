import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';


const mapStateToProps=(state)=>({
    schedulelist:state.schedule_list.data,
})

function ModalViewContractTemplate({toggle,detail,closeModal,schedulelist}){


    let schedule_name = '';
    if(detail?.wschedule != null && schedulelist.length !== 0 ){
        const schedule_detail = schedulelist.find(item=>item.id === detail.wschedule);
        schedule_name = schedule_detail?.name ?? '-';
    }

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('contract_template')} - {detail?.name ?? ''}</ModalHeader>
            <ModalBody>
                {detail !== null && 
                <>
                <Table className='align-items-center table-bordered' size="sm" responsive>
                    <tbody>
                        <tr>
                            <th className='td-w-30'>{IMLocalized('name')}</th>
                            <td>{detail?.name ?? '-'}</td>
                        </tr>
                        <tr>
                            <th>{IMLocalized('lower_pay_range')}</th>
                            <td>{detail?.lower_pay_range ?? '-' }</td>
                        </tr>
                        <tr>
                            <th>{IMLocalized('upper_pay_range')}</th>
                            <td>{detail?.upper_pay_range ?? '-' }</td>
                        </tr>
                        <tr>
                            <th>{IMLocalized('contract_type')}</th>
                            <td>{detail?.contract_type ? IMLocalized(detail.contract_type) : '-' }</td>
                        </tr>
                        <tr>
                            <th>{IMLocalized('contract_rate')}</th>
                            <td>{detail?.contract_rate ? IMLocalized(detail.contract_rate) : '-' }</td>
                        </tr>
                        <tr>
                            <th>{IMLocalized('schedule')}</th>
                            <td>{schedule_name }</td>
                        </tr>
                        <tr>
                            <th>{IMLocalized('lower_pay_range')}</th>
                            <td>{detail?.lower_pay_range ?? '-' }</td>
                        </tr>
                        <tr>
                            <th>{IMLocalized('is_workman')}</th>
                            <td>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id="viewcontracttemplateisworkman" name='is_workman' className="custom-control-input" defaultChecked={detail.is_workman} />
                                    <label className="custom-control-label" htmlFor="viewcontracttemplateisworkman"></label>
                                </div>
                            </td>
                        </tr>
                        {detail?.contract_rate === 'DAILY' &&
                        <tr>
                            <th>{IMLocalized('hours_per_day')}</th>
                            <td>{detail?.hours_day ?? '-'}</td>
                        </tr>}
                        <tr>
                            <th>{IMLocalized('department')}</th>
                            <td>{detail?.department ?? '-' }</td>
                        </tr>
                        <tr>
                            <th>{IMLocalized('section')}</th>
                            <td>{detail?.section ?? '-' }</td>
                        </tr>
                        <tr>
                            <th>{IMLocalized('job_title')}</th>
                            <td>{detail?.job_title ?? '-' }</td>
                        </tr>
                        <tr>
                            <th>{IMLocalized('job_level')}</th>
                            <td>{detail?.job_level ?? '-' }</td>
                        </tr>
                        <tr>
                            <th>{IMLocalized('cost_center')}</th>
                            <td>{detail?.cost_center ?? '-' }</td>
                        </tr>
                        <tr>
                            <th>{IMLocalized('allowance')}</th>
                            <td>
                                {detail?.allowances?.length !== 0 && 
                                detail.allowances.map((item,index)=>{
                                    return <div key={index}>● {item}</div>
                                })}
                                {detail?.allowances?.length === 0 && '-'}
                            </td>
                        </tr>
                        <tr>
                            <th>{IMLocalized('recurring_allowance')}</th>
                            <td>
                                {detail?.recurring_allowances?.length !== 0 && 
                                detail.recurring_allowances.map((item,index)=>{
                                    return <div key={index}>● {item}</div>
                                })}
                                {detail?.recurring_allowances?.length === 0 && '-'}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                </>}
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-danger btn-sm' onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,null)(ModalViewContractTemplate);