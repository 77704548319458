import React from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';


const mapStateToProps=(state)=>({
    pay_component_type:state.dropdown_list.pay_component_type,
})


function ClaimTable(props){

    return(
        <Card>
            <CardHeader className='p-2 border-0 bg-white'>
                <h4 className="text-primary pl-2 pt-2">{IMLocalized('claim')}</h4>
            </CardHeader>
            <Table size="sm" className="align-items-center" responsive>
                <thead className="text-center p-2 thead-light">
                    <tr>
                        <th className="p-2">{IMLocalized('code')}</th>
                        <th className="p-2">{IMLocalized('name')}</th>
                        <th className="p-2">{IMLocalized('amount')}</th>
                    </tr>
                </thead>
                <tbody className="text-center p-2">
                {props.list.map((item,index)=>{
                    const pay_detail = props.pay_component_type.find(el=>el.value === item.pay_type );
                    const pay_name = pay_detail?.display ?? '';

                    return(
                        <tr key={index}>
                            <td className="p-2">{item.code}</td>
                            <td className="p-2">{pay_name}</td>
                            <td className="p-2">${item.amount.toFixed(2)}</td>
                        </tr>
                    )
                    })}
                </tbody>
            </Table>  
            
        </Card>
    )
}
export default connect(mapStateToProps,null)(ClaimTable);