import myaxios from '../../axios';
export const EMPCONTRACTDEPARTMENT1_SUCCESS = 'EMPCONTRACTDEPARTMENT1_SUCCESS';
export const EMPCONTRACTDEPARTMENT1_REQUEST = 'EMPCONTRACTDEPARTMENT1_REQUEST';
export const EMPCONTRACTDEPARTMENT1_FAILURE = 'EMPCONTRACTDEPARTMENT1_FAILURE';


export const employee_contract_department1 = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'EMPCONTRACTDEPARTMENT1_REQUEST'
        });
        
        myaxios.post(`company/department/`,{
            name:name   
        })
        .then((response)=>{
            dispatch({
                type:'EMPCONTRACTDEPARTMENT1_SUCCESS',
                payload:response
            }
        )})
        .catch((error) =>{
            dispatch({
                type: 'EMPCONTRACTDEPARTMENT1_FAILURE',
                payload: error,
            })
        })
    }
}