import * as cancelapplyleave from '../../action/user/user_leave_cancel_apply_leave';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const user_leave_cancel_apply_leave = (state=initialState,action)=>{
    switch(action.type){
        case cancelapplyleave.USERLEAVECANCELAPPLYLEAVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case cancelapplyleave.USERLEAVECANCELAPPLYLEAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case cancelapplyleave.USERLEAVECANCELAPPLYLEAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case cancelapplyleave.USERLEAVECANCELAPPLYLEAVE_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default user_leave_cancel_apply_leave;