import * as create_contracttemplate from '../action/create_contract_template';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const create_contract_template = (state=initialState,action)=>{
    switch(action.type){
        case create_contracttemplate.CREATECONTRACTTEMPLATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            };
        case create_contracttemplate.CREATECONTRACTTEMPLATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case create_contracttemplate.CREATECONTRACTTEMPLATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case create_contracttemplate.CREATECONTRACTTEMPLATE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default create_contract_template;