import React, { useContext, useState } from 'react';
import {Row,Col,Card,CardHeader,Table} from 'reactstrap';
import { getDateFormat } from '../../../../func/getDateFormat';
import { IMLocalized } from '../../../../language/IMLocalized';
import ModalCalculate from '../../../payroll/payslip_modal/ModalCalculate';
import { UserContext } from '../UserPayrollDetail';
import OvertimeTable from './OvertimeTable';
import AllowanceTable from './AllowanceTable';
import RecurringTable from './RecurringTable';
import FlatTable from './FlatTable';
import ClaimTable from './ClaimTable';
import BackpayTable from './BackpayTable';
import BonusTable from './BonusTable';


function EarningTable(props){

    const [calculate_toggle,setCalculateToggle] = useState(false);
    const [calculate_detail,setCalculateDetail] = useState(null);

    const user = useContext(UserContext);


    const col1 = [];
    const col2 = [];
    let col1_amount = 0;
    let col2_amount = 0;

    if(user.overtimelist.length !== 0 ){
        col1.push(<OvertimeTable list={user.overtimelist} />);
        col1_amount += user.overtimelist.length + 2;
    }

    if(user.allowancelist.length !== 0 ){
        if(col2_amount < col1_amount){
            col2.push(<AllowanceTable list={user.allowancelist} />);
            col2_amount += user.allowancelist.length + 2;
        }
        else{
            col1.push(<AllowanceTable list={user.allowancelist} />);
            col1_amount += user.allowancelist.length +2;
        }
    }

    if(user.recurringlist.length !== 0){
        if(col2_amount < col1_amount){
            col2.push(<RecurringTable list={user.recurringlist} />);
            col2_amount += user.recurringlist.length + 2;
        }
        else{
            col1.push(<RecurringTable list={user.recurringlist} />);
            col1_amount += user.recurringlist.length + 2;
        }
    }

    if(user.flatlist.length !== 0){
        if(col2_amount < col1_amount){
            col2.push(<FlatTable list={user.flatlist} />);
            col2_amount += user.flatlist.length + 2;
        }
        else{
            col1.push(<FlatTable list={user.flatlist} />);
            col1_amount += user.flatlist.length + 2;
        }
    }

    if(user.claimlist.length !== 0 ){
        if(col2_amount < col1_amount){
            col2.push(<ClaimTable list={user.claimlist} />);
            col2_amount += user.claimlist.length + 2;
        }
        else{
            col1.push(<ClaimTable list={user.claimlist} />);
            col1_amount += user.claimlist.length + 2;
        }
    }

    if(user.backpaylist.length !== 0 ){
        if(col2_amount < col1_amount){
            col2.push(<BackpayTable list={user.backpaylist} />);
            col2_amount += user.backpaylist.length + 2;
        }
        else{
            col1.push(<BackpayTable list={user.backpaylist} />);
            col1_amount += user.backpaylist.length + 2;
        }
    }

    if(user.bonuslist.length !== 0){
        if(col2_amount < col1_amount){
            col2.push(<BonusTable list={user.bonuslist} />);
            col2_amount += user.bonuslist.length + 2;
        }
        else{
            col1.push(<BonusTable list={user.bonuslist} />);
            col1_amount += user.bonuslist.length + 2;
        }
    }

    return(
        <>
        <Row>
            <Col lg="12">
                <div className="d-flex justify-content-between align-items-center pl-2 emp-tabcontent-title">
                    <h2 className="title-color1 mb-0">{IMLocalized('payslip_title_earnings')}</h2>
                </div>
            </Col>
        </Row>
        <Row className='mt-2'>
            <Col lg="12">
                <Card>
                    <CardHeader className="p-2 border-0 bg-white">
                        <h4 className="text-primary pl-2 pt-2">{IMLocalized('payslip_basic')}</h4>
                    </CardHeader>
                    {user.payslipcontract.length !== 0 &&
                    <Table size="sm" className='align-items-center' responsive>
                        <thead className="text-center p-2 thead-light">
                            <tr>
                                <th className="p-2">{IMLocalized('from_date')}</th>
                                <th className="p-2">{IMLocalized('to_date')}</th>
                                <th className="p-2">{IMLocalized('department')}</th>
                                <th className="p-2">{IMLocalized('section')}</th>
                                <th className="p-2">{IMLocalized('job_title')}</th>
                                <th className="p-2">{IMLocalized('job_level')}</th>
                                <th className="p-2">{IMLocalized('cost_center')}</th>
                                <th className="p-2">{IMLocalized('basic_pay')}</th>
                                <th className="p-2">{IMLocalized('payslip_gross_daily')}</th>
                                <th className="p-2">{IMLocalized('payslip_gross_month')}</th>
                                <th className="p-2">{IMLocalized('payslip_actual_work_days')}</th>
                            </tr>
                        </thead>
                        <tbody className='text-center p-2'>
                            {user.payslipcontract.map((item,index)=>{
                                return(
                                    <tr key={index}>
                                        <td className="p-2">{getDateFormat(item.from_date)}</td>
                                        <td className="p-2">{getDateFormat(item.to_date)}</td>
                                        <td className="p-2">{item.contract?.department ?? '-'}</td>
                                        <td className="p-2">{item.contract?.section ?? '-'}</td>
                                        <td className="p-2">{item.contract?.job_title ?? '-'}</td>
                                        <td className="p-2">{item.contract?.job_level ?? '-'}</td>
                                        <td className="p-2">{item.contract?.cost_center ?? '-'}</td>
                                        <td className="p-2">{item.contract?.pay ? `$${(item.contract.pay).toFixed(2)}` : '-'}</td>
                                        <td className="p-2">{item.gross_daily ? `$${(item.gross_daily).toFixed(2)}` : '-'}</td>
                                        <td className="p-2">
                                            {item.gross_ow ? `$${(item.gross_ow).toFixed(2)}` : '-'} <i className="fas fa-info-circle text-primary cursor-pointer" 
                                            onClick={()=>{
                                                const {actual_work_days,month_total_work_days,gross_month} = user.payslipcontract[index];
                                                setCalculateDetail({actual_work_days,month_total_work_days,gross_month});
                                                setCalculateToggle(true);
                                            }}
                                        />
                                        </td>
                                        <td className="p-2">{item.actual_work_days}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>}
                </Card>
            </Col>
            <Col lg="6">
                {col1}
            </Col>
            <Col lg="6">
                {col2}
            </Col>
        </Row>
        <ModalCalculate toggle={calculate_toggle} detail={calculate_detail} closeModal={()=>{setCalculateToggle(false); setCalculateDetail(null)}} />
        </>
    )
}
export default EarningTable;