import * as detail from '../../action/approvals/claim_approvals_get_claim_detail';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const claim_approvals_get_claim_detail = (state=initialState,action)=>{
    switch(action.type){
        case detail.CLAIMAPPROVALSGETCLAIMDETAIL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case detail.CLAIMAPPROVALSGETCLAIMDETAIL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case detail.CLAIMAPPROVALSGETCLAIMDETAIL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case detail.CLAIMAPPROVALSGETCLAIMDETAIL_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default claim_approvals_get_claim_detail;