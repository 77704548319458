import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import ImportAdditionalError from '../content/ImportAdditionalError';



export default function ModalErrorImportAdditional({toggle,list,closeModal}){


    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('existing_data')}</ModalHeader>
            <ModalBody>
                {list &&
                <Table size="sm" className='table-bordered align-items-center' responsive>
                    <thead className='thead-light text-center'>
                        <tr>
                            {list?.department ? <th width="20%">{IMLocalized('department')}</th> :null }
                            {list?.section ? <th width="20%">{IMLocalized('section')}</th> :null }
                            {list?.jobtitle ? <th width="20%">{IMLocalized('job_title')}</th> :null }
                            {list?.joblevel ? <th width="20%">{IMLocalized('job_level')}</th> :null }
                            {list?.costcenter ? <th width="20%">{IMLocalized('cost_center')}</th> :null }
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        <tr>
                            {list?.department && 
                            <td >
                                <ImportAdditionalError list={list.department}/>
                            </td>}
                            {list?.section && 
                            <td >
                                <ImportAdditionalError list={list.section}/>
                            </td>}
                            {list?.jobtitle && 
                            <td>
                                <ImportAdditionalError list={list.jobtitle}/>
                            </td>}
                            {list?.joblevel && 
                            <td >
                                <ImportAdditionalError list={list.joblevel}/>
                            </td>}
                            {list?.costcenter && 
                            <td >
                                <ImportAdditionalError list={list.costcenter}/>
                            </td>}
                        </tr>
                    </tbody>
                </Table>}
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-danger btn-sm' onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}