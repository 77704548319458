import * as create_scheme from '../action/create_leave_scheme';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const create_leave_scheme = (state=initialState,action)=>{
    switch(action.type){
        case create_scheme.CREATESCHEME_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case create_scheme.CREATESCHEME_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case create_scheme.CREATESCHEME_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case create_scheme.CREATESCHEME_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default create_leave_scheme;