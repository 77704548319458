import myaxios from '../../axios';

export const GETOTPLIST_REQUEST = 'GETOTPLIST_REQUEST';
export const GETOTPLIST_SUCCESS = 'GETOTPLIST_SUCCESS';
export const GETOTPLIST_FAILURE = 'GETOTPLIST_FAILURE';


export const get_otp_list=()=>{
    return dispatch=>{
        dispatch({
            type:'GETOTPLIST_REQUEST'
        });

        myaxios.get('/otp/list/')
        .then((response)=>{
            dispatch({
                type:'GETOTPLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETOTPLIST_FAILURE',
                payload:error
            })
        })
    }
}