import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Col, Container, FormFeedback, Input, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import { NewMainContext } from "../../context/NewMainContext";
import { popError } from "../../func/popError";
import { IMLocalized } from "../../language/IMLocalized";
import ModalUpdateClaimApprovalName from "./modal/ModalUpdateClaimApprovalName";


const initialState={
    employee1:'',
    employee2:'',
    employee3:'',
    employee4:'',
    employee5:'',
    employee6:'',
    employee7:'',
    employee8:'',
    employee9:'',
    ordering1:'',
    ordering2:'',
    ordering3:'',
    ordering4:'',
    ordering5:'',
    ordering6:'',
    ordering7:'',
    ordering8:'',
    ordering9:'',
    err_ordering1:'',
    err_ordering2:'',
    err_ordering3:'',
    err_ordering4:'',
    err_ordering5:'',
    err_ordering6:'',
    err_ordering7:'',
    err_ordering8:'',
    err_ordering9:'',
}

export default function ClaimApprovalSchemeEdit({ setStep, id }){

    const { addItem } = useContext(NewMainContext);
    const list = useSelector(state=>state.get_claim_approval.data);
    const claimlist = useSelector(state=>state.get_claim_list.data);
    const managerlist = useSelector(state=>state.get_user_manager_list.data);
    const [title, setTitle] = useState('');
    const [ state, setState] = useState(initialState);
    const [ claim_groups, setClaimGroups] = useState([]);
    const [ master, setMaster] = useState(null);
    const [ v_exceptions, setVExceptions] = useState([]);
    const [ v_approvers, setVApprovers] = useState([]);
    const [ date_toggle,setDateToggle] = useState(false);

    useEffect(()=>{

        function getDetail(data){
            let arr = claimlist.map((item)=>{
                return { ...item, color:'#0253cc', group:'group1' }
            })

            const {exceptions,approvers, approval_master} = data;

            for(let i=0; i<approvers.length; i++){
                const {employee, ordering} = approvers[i];
                if(i === 0){
                    setState(prevState=>({
                        ...prevState,
                        employee1:employee,
                        ordering1:ordering
                    }))
                }
                else if(i === 1){
                    setState(prevState=>({
                        ...prevState,
                        employee2:employee,
                        ordering2:ordering
                    }))
                }
                else if(i === 2){
                    setState(prevState=>({
                        ...prevState,
                        employee3:employee,
                        ordering3:ordering
                    }))
                }
            }

            for(let i=0; i<exceptions.length; i++){
                const app = exceptions[i].approvers;
                const excep = exceptions[i].exceptions;
                if( i === 0){
                    
                    for(let j=0; j<app.length; j++){
                        const {employee, ordering} = app[j];
                        if(j === 0){
                            setState(prevState=>({
                                ...prevState,
                                employee4:employee,
                                ordering4:ordering
                            }))
                        }
                        else if(j === 1){
                            setState(prevState=>({
                                ...prevState,
                                employee5:employee,
                                ordering5:ordering
                            }))
                        }
                        else if(j === 2){
                            setState(prevState=>({
                                ...prevState,
                                employee6:employee,
                                ordering6:ordering
                            }))
                        }
                    }
                    

                    for(let j = 0; j < excep.length; j++){
                        const {claim_type } = excep[j];
                        const {id} = claim_type;
                        arr = arr.map((item)=>{
                            if(item.id === id){
                                return {...item, group:'group2'}
                            }
                            return item;
                        })
                    }

                }
                else if( i === 1){
                    for(let j=0; j<app.length ;j++){
                        const {employee,ordering} = app[j];
                        
                        if(j === 0){
                            setState(prevState=>({
                                ...prevState,
                                employee7:employee,
                                ordering7:ordering
                            }))
                        }
                        else if(j === 1){
                            setState(prevState=>({
                                ...prevState,
                                employee8:employee,
                                ordering8:ordering
                            }))
                        }
                        else if(j === 2){
                            setState(prevState=>({
                                ...prevState,
                                employee9:employee,
                                ordering9:ordering
                            }))
                        }
                    }

                    for(let j=0; j<excep.length ;j++){
                        const {claim_type} = excep[j];
                        const {id} = claim_type;
                        arr = arr.map((item)=>{
                            if(item.id === id){
                                return {...item, group:'group3'}
                            }
                            else{
                                return item
                            }
                        })
                    }
                }
            }
            setClaimGroups(arr);
            setMaster(approval_master);
        }

        if(list.length !== 0 && id != null){
            const detail = list.find(item=>item.id === id);
            setTitle(detail?.name ?? '');
            if(detail != null){
                getDetail(detail);
            }
        }
    },[list,id,claimlist])

    const onDragStart=(ev,id)=>{
        ev.dataTransfer.setData("id",id);
    }

    const onDragOver=(ev)=>{
        ev.preventDefault();

    }

    const onDrop = (ev,cat)=>{
        let id = ev.dataTransfer.getData("id");
        let groups = claim_groups.filter((task)=>{
            if(task.name === id){
                task.group = cat;
            }
            return task;
        });
        setClaimGroups(groups);
    }

    const resetClaim = () =>{
        const group = claim_groups.map((item)=>{
            return {...item, group: 'initial'}
        })
        setClaimGroups(group);
    }

    const moveRemaining=(name)=>{
        const groups = claim_groups.filter((task)=>{
            if(task.group === 'initial'){
                task.group = name;
            }
            return task;
        });
        setClaimGroups(groups);
    }

    const setDate = () =>{
        let err_msg = '';
        let group2 = [];
        let group3 = [];
        let initial = [];
        let approvers = [];
        let exceptions = [];
        let count = 0;
        if(claim_groups.length !== 0){
            group2 = claim_groups.filter(item=>item.group === 'group2');
            group3 = claim_groups.filter(item=> item.group === 'group3');
            initial = claim_groups.filter(item=> item.group === 'initial');
        }

        if(state.employee1 !== ''){
            if(state.ordering1 !== ''){
                approvers.push({ employee: state.employee1, ordering: state.ordering1 });
            }
            else{
                setState(prevState=>({
                    ...prevState,
                    err_ordering1:IMLocalized('ordering_is_required')
                }))
                count++;
            }
        }

        if(state.employee2 !== ''){
            if(state.ordering2 !== ''){
                approvers.push({ employee: state.employee2, ordering: state.ordering2 });
            }
            else{
                setState(prevState=>({
                    ...prevState,
                    err_ordering2: IMLocalized('ordering_is_required')
                }))
                count++;
            }
        }

        if(state.employee3 !== ''){
            if(state.ordering3 !== ''){
                approvers.push({ employee: state.employee3, ordering: state.ordering3 });
            }
            else{
                setState(prevState=>({
                    ...prevState,
                    err_ordering3: IMLocalized('ordering_is_required')
                }))
                count++;
            }
        }

        if(group2.length !==  0 || group3.length !== 0){
            for(let i = 0; i < 2; i++ ){
                let exception  = [];
                let approver = [];
                if( i === 0 ){
                    if(group2.length !== 0){
                        for( let j=0; j<group2.length ;j++){
                            exception.push(group2[j].id);
                        }

                        if(state.employee4 !== '' || state.employee5 !== '' || state.employee6 !== ''){
                            if(state.employee4 !== ''){
                                if(state.ordering4 !== ''){
                                    approver.push({ employee: state.employee4, ordering: state.ordering4 });
                                }
                                else{
                                    setState(prevState=>({
                                        ...prevState,
                                        err_ordering4: IMLocalized('ordering_is_required')
                                    }))
                                    count++;
                                }
                            }
                    
                            if(state.employee5 !== ''){
                                if(state.ordering5 !== ''){
                                    approver.push({ employee: state.employee5, ordering: state.ordering5 });
                                }
                                else{
                                    setState(prevState=>({
                                        ...prevState,
                                        err_ordering5: IMLocalized('ordering_is_required')
                                    }))
                                    count++;
                                }
                            }
                    
                            if(state.employee6 !== ''){
                                if(state.ordering6 !== ''){
                                    approver.push({ employee: state.employee6, ordering: state.ordering6 });
                                }
                                else{
                                    setState(prevState=>({
                                        ...prevState,
                                        err_ordering6:IMLocalized('ordering_is_required')
                                    }))
                                    count++;
                                }
                            }
                            exceptions.push({ exceptions: exception, approvers:approver });
                        }
                        else{
                            err_msg = `${IMLocalized('claim_group2_employee_required')} \n`;
                        }
                        
                    }
                }
                else if ( i === 1){
                    if(group3.length !== 0 ){
                        for( let j=0; j<group3.length ;j++){
                            exception.push(group3[j].id);
                        }

                        if(state.employee7 !== '' || state.employee8 !== '' || state.employee9 !== ''){
                            if(state.employee7 !== ''){
                                if(state.ordering7 !== ''){
                                    approver.push({ employee: state.employee7, ordering: state.ordering7 });
                                }
                                else{
                                    setState(prevState=>({
                                        ...prevState,
                                        err_ordering7: IMLocalized('ordering_is_required')
                                    }))
                                    count++;
                                }
                            }
                    
                            if(state.employee8 !== ''){
                                if(state.ordering8 !== ''){
                                    approver.push({ employee: state.employee8, ordering: state.ordering8 });
                                }
                                else{
                                    setState(prevState=>({
                                        ...prevState,
                                        err_ordering8: IMLocalized('ordering_is_required')
                                    }))
                                    count++;
                                }
                            }
                    
                            if(state.employee9 !== ''){
                                if(state.ordering9 !== ''){
                                    approver.push({ employee: state.employee9, ordering: state.ordering9 });
                                }
                                else{
                                    setState(prevState=>({
                                        ...prevState,
                                        err_ordering9:IMLocalized('ordering_is_required')
                                    }))
                                    count++;
                                }
                            }
                            exceptions.push({ exceptions: exception, approvers:approver });
                        }
                        else{
                            err_msg = `${IMLocalized('claim_group3_employee_required')} \n`;
                        }
                    }
                }
            }
        }

        if(err_msg.length !== 0){
            popError(err_msg);
        }
        else{
            if( count === 0){
                if(initial.length !== 0 ){
                    Swal.fire({
                        type:'info',
                        title:IMLocalized('unselect_claim_to_group1'),
                        showCancelButton:true,
                        showConfirmButton:true,
                        cancelButtonText:IMLocalized('no!'),
                        confirmButtonText:IMLocalized('yes!')
                    })
                    .then((result)=>{
                        if(result.value){
                            setVExceptions(exceptions);
                            setVApprovers(approvers);
                            setDateToggle(true);
                        }
                    })
                }
                else{
                    setVExceptions(exceptions);
                    setVApprovers(approvers);
                    setDateToggle(true);
                }
            }
            else{
                popError(IMLocalized('ordering_is_required'));
            }
        }
    }

    const option1 =  [ { value:0, display:1 }, { value:1, display:2 }, { value:2, display:3 }];

    const groups ={
        initial:[],
        group1:[],
        group2:[],
        group3:[],
    }

    claim_groups.forEach((t)=>{
        groups[t.group].push(
            <div key={t.name} className="draggable" onDragStart={(e)=>onDragStart(e,t.name)} draggable style={{backgroundColor:t.color,padding:'0.25rem',margin:'0.25rem'}}>
                <span className="text-white">{t.code} - {t.name} </span>
            </div>
        )
    })
    
    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2"> 
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem><a href="/#" onClick={()=>setStep(0)}>{IMLocalized('claim_approval_list2')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{title}</BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('edit')}</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="ml-2">
                        <button onClick={()=>setStep(0)} className="btn btn-primary btn-sm"> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                        <button onClick={setDate} className="btn btn-primary btn-sm">{IMLocalized('update')}</button>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col lg="4">
                    <div style={{position:'sticky',top:'20px'}}>
                        <div>
                            <Card className="group_initial" onDragOver={(e)=>onDragOver(e)} onDrop={(e)=>onDrop(e,'initial')}>
                                <CardHeader>
                                    <Row>
                                        <Col><h4 className="mb-0">{IMLocalized('claim_type')}</h4></Col>
                                        <Col className="col-auto">
                                            <button className="btn btn-primary btn-sm" onClick={resetClaim}>{IMLocalized('reset')}</button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className="drag-card">
                                    {groups.initial}
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Col>
                <Col lg="8">
                    <Row>
                        <Col lg="6">
                            <Card className="group1" onDragOver={(e)=>onDragOver(e)} onDrop={(e)=>onDrop(e,'group1')} >
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <h4 className="mb-0">{IMLocalized('group')} 1</h4>
                                        </Col>
                                        <Col className="col-auto">
                                            <button className="btn btn-primary btn-sm" onClick={()=>moveRemaining('group1')}>{IMLocalized('add_remaining_claim')}</button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className="drag-card">
                                    {groups.group1}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Table size="sm" className="bg-white table-bordered align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th width="75%">{IMLocalized('employee')}</th>
                                        <th>{IMLocalized('ordering')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.employee1} onChange={(e)=>{
                                                const { value } = e.target;
                                                setState(prevState=>({
                                                    ...prevState,
                                                    employee1: value
                                                }));
                                            }}>
                                                <option></option>
                                                {managerlist.map((item)=>{
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </td>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.ordering1} invalid={state.err_ordering1} onChange={(e)=>{
                                                const {value} = e.target;
                                                setState(prevState=>({
                                                    ...prevState, 
                                                    ordering1: value, 
                                                    err_ordering1:''
                                                }));
                                            }}>
                                                <option></option>
                                                {option1.map((item)=>{
                                                    return (
                                                        <option value={item.value} key={item.value}>{item.display}</option>
                                                    )
                                                })}
                                            </Input>
                                            <FormFeedback>{state.err_ordering1}</FormFeedback>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.employee2} onChange={(e)=>{
                                                const { value } = e.target;
                                                setState(prevState=>({
                                                    ...prevState,
                                                    employee2: value
                                                }));
                                            }}>
                                                <option></option>
                                                {managerlist.map((item)=>{
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </td>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.ordering2} invalid={state.err_ordering2} onChange={(e)=>{
                                                const {value} = e.target;
                                                setState(prevState=>({
                                                    ...prevState, 
                                                    ordering2: value, 
                                                    err_ordering2:''
                                                }));
                                            }}>
                                                <option></option>
                                                {option1.map((item)=>{
                                                    return (
                                                        <option value={item.value} key={item.value}>{item.display}</option>
                                                    )
                                                })}
                                            </Input>
                                            <FormFeedback>{state.err_ordering2}</FormFeedback>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.employee3} onChange={(e)=>{
                                                const { value } = e.target;
                                                setState(prevState=>({
                                                    ...prevState,
                                                    employee3: value
                                                }));
                                            }}>
                                                <option></option>
                                                {managerlist.map((item)=>{
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </td>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.ordering3} invalid={state.err_ordering3} onChange={(e)=>{
                                                const {value} = e.target;
                                                setState(prevState=>({
                                                    ...prevState, 
                                                    ordering3: value, 
                                                    err_ordering3:''
                                                }));
                                            }}>
                                                <option></option>
                                                {option1.map((item)=>{
                                                    return (
                                                        <option value={item.value} key={item.value}>{item.display}</option>
                                                    )
                                                })}
                                            </Input>
                                            <FormFeedback>{state.err_ordering3}</FormFeedback>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <Card className="group2" onDragOver={(e)=>onDragOver(e)} onDrop={(e)=>onDrop(e,'group2')} >
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <h4 className="mb-0">{IMLocalized('group')} 2</h4>
                                        </Col>
                                        <Col className="col-auto">
                                            <button className="btn btn-primary btn-sm" onClick={()=>moveRemaining('group2')}>{IMLocalized('add_remaining_claim')}</button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className="drag-card">
                                    {groups.group2}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Table size="sm" className="bg-white table-bordered align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th width="75%">{IMLocalized('employee')}</th>
                                        <th>{IMLocalized('ordering')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.employee4} onChange={(e)=>{
                                                const { value } = e.target;
                                                setState(prevState=>({
                                                    ...prevState,
                                                    employee4: value
                                                }));
                                            }}>
                                                <option></option>
                                                {managerlist.map((item)=>{
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </td>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.ordering4} invalid={state.err_ordering4} onChange={(e)=>{
                                                const {value} = e.target;
                                                setState(prevState=>({
                                                    ...prevState, 
                                                    ordering4: value, 
                                                    err_ordering4: ''
                                                }));
                                            }}>
                                                <option></option>
                                                {option1.map((item)=>{
                                                    return (
                                                        <option value={item.value} key={item.value}>{item.display}</option>
                                                    )
                                                })}
                                            </Input>
                                            <FormFeedback>{state.err_ordering4}</FormFeedback>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.employee5} onChange={(e)=>{
                                                const { value } = e.target;
                                                setState(prevState=>({
                                                    ...prevState,
                                                    employee5: value
                                                }));
                                            }}>
                                                <option></option>
                                                {managerlist.map((item)=>{
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </td>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.ordering5} invalid={state.err_ordering5} onChange={(e)=>{
                                                const {value} = e.target;
                                                setState(prevState=>({
                                                    ...prevState, 
                                                    ordering5: value, 
                                                    err_ordering5:''
                                                }));
                                            }}>
                                                <option></option>
                                                {option1.map((item)=>{
                                                    return (
                                                        <option value={item.value} key={item.value}>{item.display}</option>
                                                    )
                                                })}
                                            </Input>
                                            <FormFeedback>{state.err_ordering5}</FormFeedback>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.employee6} onChange={(e)=>{
                                                const { value } = e.target;
                                                setState(prevState=>({
                                                    ...prevState,
                                                    employee6: value
                                                }));
                                            }}>
                                                <option></option>
                                                {managerlist.map((item)=>{
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </td>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.ordering6} invalid={state.err_ordering6} onChange={(e)=>{
                                                const {value} = e.target;
                                                setState(prevState=>({
                                                    ...prevState, 
                                                    ordering6: value, 
                                                    err_ordering6:''
                                                }));
                                            }}>
                                                <option></option>
                                                {option1.map((item)=>{
                                                    return (
                                                        <option value={item.value} key={item.value}>{item.display}</option>
                                                    )
                                                })}
                                            </Input>
                                            <FormFeedback>{state.err_ordering6}</FormFeedback>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <Card className="group3" onDragOver={(e)=>onDragOver(e)} onDrop={(e)=>onDrop(e,'group3')} >
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <h4 className="mb-0">{IMLocalized('group')} 3</h4>
                                        </Col>
                                        <Col className="col-auto">
                                            <button className="btn btn-primary btn-sm" onClick={()=>moveRemaining('group3')}>{IMLocalized('add_remaining_claim')}</button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className="drag-card">
                                    {groups.group3}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Table size="sm" className="bg-white table-bordered align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th width="75%">{IMLocalized('employee')}</th>
                                        <th>{IMLocalized('ordering')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.employee7} onChange={(e)=>{
                                                const { value } = e.target;
                                                setState(prevState=>({
                                                    ...prevState,
                                                    employee7: value
                                                }));
                                            }}>
                                                <option></option>
                                                {managerlist.map((item)=>{
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </td>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.ordering7} invalid={state.err_ordering7} onChange={(e)=>{
                                                const {value} = e.target;
                                                setState(prevState=>({
                                                    ...prevState, 
                                                    ordering7: value, 
                                                    err_ordering7: ''
                                                }));
                                            }}>
                                                <option></option>
                                                {option1.map((item)=>{
                                                    return (
                                                        <option value={item.value} key={item.value}>{item.display}</option>
                                                    )
                                                })}
                                            </Input>
                                            <FormFeedback>{state.err_ordering7}</FormFeedback>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.employee8} onChange={(e)=>{
                                                const { value } = e.target;
                                                setState(prevState=>({
                                                    ...prevState,
                                                    employee8: value
                                                }));
                                            }}>
                                                <option></option>
                                                {managerlist.map((item)=>{
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </td>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.ordering8} invalid={state.err_ordering8} onChange={(e)=>{
                                                const {value} = e.target;
                                                setState(prevState=>({
                                                    ...prevState, 
                                                    ordering8: value, 
                                                    err_ordering8:''
                                                }));
                                            }}>
                                                <option></option>
                                                {option1.map((item)=>{
                                                    return (
                                                        <option value={item.value} key={item.value}>{item.display}</option>
                                                    )
                                                })}
                                            </Input>
                                            <FormFeedback>{state.err_ordering8}</FormFeedback>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.employee9} onChange={(e)=>{
                                                const { value } = e.target;
                                                setState(prevState=>({
                                                    ...prevState,
                                                    employee9: value
                                                }));
                                            }}>
                                                <option></option>
                                                {managerlist.map((item)=>{
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </td>
                                        <td>
                                            <Input size="sm" className="my-2" type="select" value={state.ordering9} invalid={state.err_ordering9} onChange={(e)=>{
                                                const {value} = e.target;
                                                setState(prevState=>({
                                                    ...prevState, 
                                                    ordering9: value, 
                                                    err_ordering9:''
                                                }));
                                            }}>
                                                <option></option>
                                                {option1.map((item)=>{
                                                    return (
                                                        <option value={item.value} key={item.value}>{item.display}</option>
                                                    )
                                                })}
                                            </Input>
                                            <FormFeedback>{state.err_ordering9}</FormFeedback>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        <ModalUpdateClaimApprovalName toggle={date_toggle} closeModal={()=>{ setDateToggle(false); setVApprovers([]); setVExceptions([]); }} master={master} approvers={v_approvers} exceptions={v_exceptions} name={title} onSuccess={()=>{
            setDateToggle(false);
            setStep(0);
        }} />
        </>
    )
}