import myaxios from '../../axios';

export const GETPAYROLLINCREMENT_REQUEST = 'GETPAYROLLINCREMENT_REQUEST';
export const GETPAYROLLINCREMENT_SUCCESS = 'GETPAYROLLINCREMENT_SUCCESS';
export const GETPAYROLLINCREMENT_FAILURE = 'GETPAYROLLINCREMENT_FAILURE';

export const get_payroll_increment_list = () =>{
    return dispatch=>{
        dispatch({
            type:'GETPAYROLLINCREMENT_REQUEST',
        });

        return myaxios.get('contract/incview')
        .then((response)=>{
            dispatch({
                type:'GETPAYROLLINCREMENT_SUCCESS',
                payload:response
            })
        })
        .catch((errors)=>{
            dispatch({
                type:'GETPAYROLLINCREMENT_FAILURE',
                payload:errors
            })
        })
    }
}