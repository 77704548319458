import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATEPERMIT_REQUEST = 'CREATEPERMIT_REQUEST';
export const CREATEPERMIT_SUCCESS = 'CREATEPERMIT_SUCCESS';
export const CREATEPERMIT_FAILURE = 'CREATEPERMIT_FAILURE';


export const create_new_employee_permit = (data,employee,identification)=>{
    return dispatch=>{
        dispatch({
            type:'CREATEPERMIT_REQUEST'
        });

        myaxios.patch(`employee/employees/${employee}/`,{
            identification_number:identification
        })
        .then(()=>{

            const { employee, permit_type, permit, man_year_entitlement, expiry_date, issued_date,application_date} = data[0];

            return myaxios.post(`employee/workpermit/`,{
                permit:permit,
                permit_type:permit_type,
                man_year_entitlement:man_year_entitlement,
                expiry_date:expiry_date,
                issued_date:issued_date,
                application_date:application_date,
                employee:employee
            })
            .then((response)=>{
                dispatch({
                    type:'CREATEPERMIT_SUCCESS',
                    payload:response
                });

                Toast.fire({
                    type: 'success',
                    title: IMLocalized('create_success')
                })
            })
            .catch((error)=>{
                dispatch({
                    type:'CREATEPERMIT_FAILURE',
                    payload:error
                });

                ErrToast.fire({
                    type:'error',
                    title:IMLocalized('create_failure')
                });
            })
        })
        .catch((error)=>{
            dispatch({
                type:'CREATEPERMIT_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
