import * as maternity_actual from '../../action/employee/create_sg_maternity_actual';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const create_sg_maternity_actual = (state=initialState,action)=>{
    switch(action.type){
        case maternity_actual.LEAVESGMATERNITYACTUAL_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case maternity_actual.LEAVESGMATERNITYACTUAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case maternity_actual.LEAVESGMATERNITYACTUAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case maternity_actual.LEAVESGMATERNITYACTUAL_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default create_sg_maternity_actual;