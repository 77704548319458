import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { getDateFormat } from '../../../func/getDateFormat';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalViewEducation from '../hr_employee/modal/ModalViewEducation';


const mapStateToProps=(state)=>({
    data:state.user_self.data,
    isLoading:state.user_self.isLoading,
    education_level:state.dropdown_list.education_level,
})

function UserEmployeeEducation(props){

    const [list,setList] = useState([]);
    const [toggle,setToggle] = useState(false);
    const [detail,setDetail] = useState(null);

    useEffect(()=>{
        if(props.data.length !== 0){
            const {education} = props.data;
            setList(education);
        }
    },[props.data])

    const toView=(id)=>{
        const data = list.find((item)=>item.id === id);
        setDetail(data);
        setToggle(true);
    }

    return(
        <>
        <div className='d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title'>
            <div className='d-flex align-items-center'>
                <i className="fas fa-user-graduate text-primary"></i>
                <h4 className="text-primary pl-2 m-0">{IMLocalized('employee_education')}</h4>
            </div>
        </div> 
        {props.isLoading && <div className='p-2'><h4>{IMLocalized('loading')}..</h4></div>}
        {!props.isLoading && list.length === 0 && <div className='p-2'><h4>{IMLocalized('no_data')}</h4></div>}
        {!props.isLoading && list.length !== 0 && 
        <Table size="sm" className='align-items-center table-bordered mt-2'>
            <thead className='thead-light text-center'>
                <tr>
                    <th>{IMLocalized('education_level')}</th>
                    <th>{IMLocalized('graduation_date')}</th>
                </tr>
            </thead>
            <tbody className='text-center'>
                {list.map((item,index)=>{
                    const level_detail = props.education_level.find(el =>el.value === item.level);
                     const level_name = level_detail?.display ?? '-';
                    return(
                        <tr key={index}>
                            <td><span className='text-primary cursor-pointer' onClick={()=>toView(item.id)}>{level_name}</span></td>
                            <td>{getDateFormat(item.graduation_date)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>}
        <ModalViewEducation type="user" toggle={toggle} detail={detail} closeModal={()=>{setToggle(false); setDetail(null)}} />
        </>
    )
}
export default connect(mapStateToProps,null)(UserEmployeeEducation);
