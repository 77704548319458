import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const EMPALLVALIDATECLAIMPAY_REQUEST = 'EMPALLVALIDATECLAIMPAY_REQUEST';
export const EMPALLVALIDATECLAIMPAY_SUCCESS = 'EMPALLVALIDATECLAIMPAY_SUCCESS';
export const EMPALLVALIDATECLAIMPAY_FAILURE = 'EMPALLVALIDATECLAIMPAY_FAILURE';
export const EMPALLVALIDATECLAIMPAY_RESET = 'EMPALLVALIDATECLAIMPAY_RESET';

export const emp_all_validate_claim_pay = (input_claim_ids,pay_date,send_to_payroll) =>{
    return dispatch=>{
        dispatch({
            type:'EMPALLVALIDATECLAIMPAY_REQUEST'
        });

        myaxios.post('claim/pay/validate/',{
            input_claim_ids:input_claim_ids,
            pay_date:pay_date,
            send_to_payroll:send_to_payroll
        })
        .then(()=>{
            myaxios.post('claim/pay/',{
                input_claim_ids:input_claim_ids,
                pay_date:pay_date,
                send_to_payroll:send_to_payroll
            })
            .then((response)=>{
                dispatch({
                    type:'EMPALLVALIDATECLAIMPAY_SUCCESS',
                    payload:response
                });

                Toast.fire({
                    type:'success',
                    title:IMLocalized('paid_success')
                });
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPALLVALIDATECLAIMPAY_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('paid_failure')
            });
        })
    }
}

export const reset_emp_all_validate_claim_pay = ()=> dispatch =>{
    dispatch({
        type:'EMPALLVALIDATECLAIMPAY_RESET'
    });
}