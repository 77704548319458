import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const EMPAWARDLEAVE_REQUEST = 'EMPAWARDLEAVE_REQUEST';
export const EMPAWARDLEAVE_SUCCESS = 'EMPAWARDLEAVE_SUCCESS';
export const EMPAWARDLEAVE_FAILURE = 'EMPAWARDLEAVE_FAILURE';
export const EMPAWARDLEAVE_RESET = 'EMPAWARDLEAVE_RESET';


export const employee_award_leave =(employee,leave_setting,from_date,to_date,amount,notes)=>{
    return dispatch=>{
        dispatch({
            type:'EMPAWARDLEAVE_REQUEST'
        });

        myaxios.post('leave/given/',{
            employee:employee,
            leave_setting:leave_setting,
            from_date:from_date,
            to_date:to_date,
            amount:amount,
            notes:notes
        })
        .then((response)=>{
            dispatch({
                type:'EMPAWARDLEAVE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('award_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'EMPAWARDLEAVE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('award_failure')
            });
        })
    }
}

export const reset_employee_award_leave = ()=> dispatch=>{
    dispatch({
        type:'EMPAWARDLEAVE_RESET'
    })
}