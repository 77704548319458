import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const ARRAYREJECTCLAIM_REQUEST = 'ARRAYREJECTCLAIM_REQUEST';
export const ARRAYREJECTCLAIM_SUCCESS = 'ARRAYREJECTCLAIM_SUCCESS';
export const ARRAYREJECTCLAIM_FAILURE = 'ARRAYREJECTCLAIM_FAILURE';
export const ARRAYREJECTCLAIM_RESET = 'ARRAYREJECTCLAIM_RESET';

export const array_reject_apply_claim = (data)=>{
    return dispatch=>{
        dispatch({
            type:'ARRAYREJECTCLAIM_REQUEST'
        });

        myaxios.post('claim/apply/array_reject/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'ARRAYREJECTCLAIM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type:'success',
                title:IMLocalized('reject_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'ARRAYREJECTCLAIM_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('reject_failure')
            });
        })
    }
}

export const reset_array_reject_apply_claim = () => dispatch =>{
    dispatch({
        type:'ARRAYREJECTCLAIM_RESET'
    });
}