import myaxios from '../../axios';
import FormData from 'form-data';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';


export const VALIDATEUSERAPPLYCLAIM_REQUEST = 'VALIDATEUSERAPPLYCLAIM_REQUEST';
export const VALIDATEUSERAPPLYCLAIM_SUCCESS = 'VALIDATEUSERAPPLYCLAIM_SUCCESS';
export const VALIDATEUSERAPPLYCLAIM_FAILURE = 'VALIDATEUSERAPPLYCLAIM_FAILURE';
export const VALIDATEUSERAPPLYCLAIM_RESET = 'VALIDATEUSERAPPLYCLAIM_RESET';

export const validate_user_apply_claim = (claim_type,original_amount,claim_date,dependant,set_pending,notes,file,doctor,diagnosis,location)=>{
    return dispatch=>{
        dispatch({
            type:'VALIDATEUSERAPPLYCLAIM_REQUEST'
        });
        let formData = new FormData();
        if(file.length !==0){
            for(let i=0;i<file.length;i++){
                formData.append('upload_images',file[i],file[i].name);
            }
        }
        
        formData.append('claim_type',claim_type);
        formData.append('original_amount',original_amount);
        formData.append('dependant',dependant);
        formData.append('claim_date',claim_date);
        formData.append('set_pending',set_pending);
        formData.append('comment',notes);
        formData.append('medicalclaim.doctor',doctor);
        formData.append('medicalclaim.diagnosis',diagnosis);
        formData.append('medicalclaim.location',location)
        

        myaxios.post('claim/self/apply/validate/',formData,{headers:{'Content-Type':'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'VALIDATEUSERAPPLYCLAIM_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'VALIDATEUSERAPPLYCLAIM_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('apply_failure')
            });
        })
        
    }
}

export const reset_validate_user_apply_claim = () =>dispatch =>{
    dispatch({
        type:'VALIDATEUSERAPPLYCLAIM_RESET'
    });
}