import myaxios from '../axios';


export const CREATECONTRACTCOSTCENTER_SUCCESS = 'CREATECONTRACTCOSTCENTER_SUCCESS';
export const CREATECONTRACTCOSTCENTER_REQUEST = 'CREATECONTRACTCOSTCENTER_REQUEST';
export const CREATECONTRACTCOSTCENTER_FAILURE = 'CREATECONTRACTCOSTCENTER_FAILURE';
export const CREATECONTRACTCOSTCENTER_RESET = 'CREATECONTRACTCOSTCENTER_RESET';

export const create_contract_cost_center = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATECONTRACTCOSTCENTER_REQUEST'
        });
        
        myaxios.post(`company/costcenter/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'CREATECONTRACTCOSTCENTER_SUCCESS',
                payload:response        
            })
        })
        .catch((error) =>{
            dispatch({
                type: 'CREATECONTRACTCOSTCENTER_FAILURE',
                payload: error,
            })
        })
    }
}

export const reset_create_contract_cost_center = ()=>dispatch =>{
    dispatch({
        type:'CREATECONTRACTCOSTCENTER_RESET'
    });
}