import * as grouplist from '../../action/appraisal/get_appraisal_group_list';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const get_appraisal_group_list = (state=initialState,action)=>{
    switch(action.type){
        case grouplist.GETAPPRAISALGROUPLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case grouplist.GETAPPRAISALGROUPLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case grouplist.GETAPPRAISALGROUPLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_appraisal_group_list;
