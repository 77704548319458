import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';


export const LEAVESGMATERNITYACTUAL_REQUEST = 'LEAVESGMATERNITYACTUAL_REQUEST';
export const LEAVESGMATERNITYACTUAL_SUCCESS = 'LEAVESGMATERNITYACTUAL_SUCCESS';
export const LEAVESGMATERNITYACTUAL_FAILURE = 'LEAVESGMATERNITYACTUAL_FAILURE';
export const LEAVESGMATERNITYACTUAL_RESET = 'LEAVESGMATERNITYACTUAL_RESET';

export const create_sg_maternity_actual = (employee,estimated_delivery_date,block_leave_start_date,birth_times,is_sg,flexible_leave)=>{
    return dispatch=>{
        dispatch({
            type:'LEAVESGMATERNITYACTUAL_REQUEST',
        });

        myaxios.post('leave/sg/maternity/',{
            employee:employee,
            estimated_delivery_date:estimated_delivery_date,
            block_leave_start_date:block_leave_start_date,
            birth_times:birth_times,
            flexible_leave:flexible_leave,
            is_sg:is_sg
        })
        .then((response)=>{
            dispatch({
                type:'LEAVESGMATERNITYACTUAL_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVESGMATERNITYACTUAL_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_sg_maternity_actual = () => dispatch =>{
    dispatch({
        type:'LEAVESGMATERNITYACTUAL_RESET'
    });
}