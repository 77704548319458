import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import Swal from 'sweetalert2';
import '../../../css/otp.css';
import { IMLocalized } from '../../../language/IMLocalized';
import {delete_otp_list} from '../../../action/user/delete_otp_list';
import { usePrevious } from '../../../hook/usePrevious';
import { get_otp_list } from '../../../action/user/get_otp_list';
import ModalCreateToken from './modal/ModalCreateToken';

const mapStateToProps=(state)=>({
    list:state.get_otp_list.data,
    isLoading:state.get_otp_list.isLoading,
    delete_success:state.delete_otp_list.data,
})

const mapDispatchToProps=(dispatch)=>({
    delete_otp_list:(id)=>{
        dispatch(delete_otp_list(id))
    },
    get_otp_list:()=>{
        dispatch(get_otp_list())
    }
})


function UserEmployeeToken(props){
    const {delete_success,get_otp_list} = props;
    const [toggle,setToggle] = useState(false);
    const prevDelete = usePrevious(delete_success);

    useEffect(()=>{
        if(prevDelete !== undefined && prevDelete !== delete_success && delete_success !== null){
            get_otp_list();
        }
    },[prevDelete,delete_success,get_otp_list])

    const deleteToken=(id,name)=>{
        Swal.fire({
            title: `${IMLocalized('delete_otp_msg')} (` +name +")?",
            text:IMLocalized('revert_msg'),
            type:'warning',
            showCancelButton:true,
            cancelButtonColor:'#3085d6',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
        })
        .then((result)=>{
            if(result.value){
                props.delete_otp_list(id);
            }
        })
    }

    return(
        <>
        <div className='d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title'>
            <div className='d-flex align-items-center'>
                <i className="far fa-id-card text-primary"></i>
                <h4 className="text-primary pl-2 m-0">{IMLocalized('otp')}</h4>
            </div>
            <div className='text-primary pt-2 cursor-pointer pr-2' onClick={()=>setToggle(true)}>
                <i className='fas fa-plus-circle mr-1 function_icon' />
                <small>{IMLocalized('add_otp')}</small>
            </div>
        </div> 
        {props.isLoading && <div className='p-2'><h4>{IMLocalized('loading')}..</h4></div>}
        {!props.isLoading && props.list.length === 0 && <div className='p-2'><h4>{IMLocalized('no_device_setup')}</h4></div>}
        {!props.isLoading && props.list.length !== 0 &&
        <Table size="sm" className='align-items-center table-bordered mt-2' responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th>{IMLocalized('device_name')}</th>
                    <th>{IMLocalized('date')}</th>
                    <th>{IMLocalized('delete')}</th>
                </tr>
            </thead>
            <tbody className='text-center'>
                {props.list.map((item,index)=>{
                    const date = new Date(item.created);
                    let month = date.getMonth()+1;
                    if(month<10){
                        month = '0'+month;
                    }
                    let day = date.getDate();
                    if(day<10){
                        day = '0'+day;
                    }

                    const that_date = day+'/'+month+'/'+date.getFullYear();
                    return(
                        <tr key={index}>
                            <td>{item.device}</td>
                            <td>{that_date}</td>
                            <td align="center" valign="center">
                                <div className="delete_btn d-flex align-items-center justify-content-center">
                                    <i className="fas fa-trash text-red cursor-pointer" onClick={()=>deleteToken(item.id,item.device)} />
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>}
        <ModalCreateToken toggle={toggle} closeModal={()=>setToggle(false)} />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(UserEmployeeToken);