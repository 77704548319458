import React from 'react';



export default function ImportAdditionalError({list}){


    return(
        <>
        {list.map((item,index)=>{
            return(
                <div key={index}>
                    <span>{item}</span>
                </div>
            )
        })}
        </>
    )
}