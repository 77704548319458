import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELCOSTCENTER_REQUEST = 'DELCOSTCENTER_REQUEST';
export const DELCOSTCENTER_FAILURE = 'DELCOSTCENTER_FAILURE';
export const DELCOSTCENTER_SUCCESS = 'DELCOSTCENTER_SUCCESS';
export const DELCOSTCENTER_RESET = 'DELCOSTCENTER_RESET';

export const delCostcenter = (id)=>{
    return dispatch =>{
        dispatch({
            type: 'DELCOSTCENTER_REQUEST'
        });
        
        myaxios.delete(`company/costcenter/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELCOSTCENTER_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'DELCOSTCENTER_FAILURE',
                payload: error,
            })
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            })
        })
    }
}

export const reset_delete_costcenter = () => dispatch =>{
    dispatch({
        type:'DELCOSTCENTER_RESET'
    });
}






