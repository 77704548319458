import myaxios from '../axios';


export const GETLEAVELIST_REQUEST = 'GETLEAVELIST_REQUEST';
export const GETLEAVELIST_SUCCESS = 'GETLEAVELIST_SUCCESS';
export const GETLEAVELIST_FAILURE = 'GETLEAVELIST_FAILURE';

export const get_leave_list =()=>{
    return dispatch=>{
        dispatch({
            type:'GETLEAVELIST_REQUEST'
        });

        myaxios.get('leave/setting/')
        .then((response)=>{
            dispatch({
                type:'GETLEAVELIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETLEAVELIST_FAILURE',
                payload:error
            })
        })
    }
}