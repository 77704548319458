import * as filterresetlist from '../../action/leave/filter_leave_reset_list';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}

const filter_leave_reset_list = (state= initialState,action)=>{
    switch(action.type){
        case filterresetlist.FILTERLEAVERESETLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case filterresetlist.FILTERLEAVERESETLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data,
            }
        case filterresetlist.FILTERLEAVERESETLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case filterresetlist.FILTERLEAVERESETLIST_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default filter_leave_reset_list;