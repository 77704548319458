import React, { useContext, useState } from "react";
import { Card, CardHeader, Col, ListGroup, ListGroupItem, Row, CardBody, Container } from "reactstrap";
import { WizardHolidayContext } from "../../context/WizardHolidayContext";
import { IMLocalized } from "../../language/IMLocalized";
import ModalAddHolidayList from "./modal/ModalAddHolidayList";
import ModalEditHolidayList from "./modal/ModalEditHolidayList";

export default function HolidayList(){

    const { list, setList, setHolidayID, setStep, setData } = useContext(WizardHolidayContext);
   
    const [ toggle, setToggle] = useState(false);
    const [ edit_toggle, setEditToggle] = useState(false);
    const [ edit_index, setEditIndex] = useState(null);

    const deleteList=(index)=>{
        const data = [...list];
        data.splice(index,1);
        setList(data);
    }

    const editList=(index)=>{
        setEditIndex(index);
        setEditToggle(true);
    }

    const viewList=(index)=>{
        setHolidayID(index);
        setStep(1);
    }

    return(
        <>
        <Container className="mt-2">
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h4 className="mb-0">{IMLocalized('holiday_list')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm" onClick={()=>setToggle(true)}>{IMLocalized('create')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        {list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody> }
                        {list.length !== 0 && 
                        <ListGroup className="list" style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                            {list.map((item,index)=>{
                                return(
                                    <ListGroupItem className="py-2" key={index}>
                                        <Row>
                                            <Col>
                                                <span>{index +1 }. {item.name}</span>
                                            </Col>
                                            <Col className="col-auto mr-1">
                                                <button className="btn btn-primary btn-sm" onClick={()=>viewList(index)}>{IMLocalized('detail')}</button>
                                                <button className="btn btn-info btn-sm" onClick={()=>editList(index)}>{IMLocalized('edit')}</button>
                                                <button className="btn btn-danger btn-sm" onClick={()=>deleteList(index)}>{IMLocalized('delete')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
            <div className='create_btn_parent'>
                <i className='fas fa-arrow-circle-right text-success cursor-pointer create_btn' onClick={()=>setData(list)}  />
            </div>
        </Container>
        <ModalAddHolidayList toggle={toggle} closeModal={()=>setToggle(false)} />
        <ModalEditHolidayList toggle={edit_toggle} closeModal={()=>{ setEditToggle(false); setEditIndex(null) }} index={edit_index} />
        </>
    )
}