import myaxios from '../../axios';

export const HEADCOUNTPAYROLLINSTANCE_REQUEST = 'HEADCOUNTPAYROLLINSTANCE_REQUEST';
export const HEADCOUNTPAYROLLINSTANCE_SUCCESS = 'HEADCOUNTPAYROLLINSTANCE_SUCCESS';
export const HEADCOUNTPAYROLLINSTANCE_FAILURE = 'HEADCOUNTPAYROLLINSTANCE_FAILURE';

export const headcount_payroll_instance = (id) =>{
    return dispatch=>{
        dispatch({
            type: 'HEADCOUNTPAYROLLINSTANCE_REQUEST'
        });

        myaxios.get(`payroll/payroll/${id}/`)
        .then((response)=>{
            dispatch({
                type:'HEADCOUNTPAYROLLINSTANCE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'HEADCOUNTPAYROLLINSTANCE_FAILURE',
                payload:error.response
            })
        })
    }
}