import myaxios from '../../axios';
import FormData from 'form-data';
import { IMLocalized } from '../../language/IMLocalized';
import { Toast } from '../../swal/SwalToast';


export const LEAVECALENDARSELFAPPLYLEAVE_REQUEST = 'LEAVECALENDARSELFAPPLYLEAVE_REQUEST';
export const LEAVECALENDARSELFAPPLYLEAVE_SUCCESS = 'LEAVECALENDARSELFAPPLYLEAVE_SUCCESS';
export const LEAVECALENDARSELFAPPLYLEAVE_FAILURE = 'LEAVECALENDARSELFAPPLYLEAVE_FAILURE';

export const leave_calendar_user_apply_leave_self = (leave_options,require_confirmation,file,leave_entitlement,comment)=>{
    return dispatch=>{
        dispatch({
            type: 'LEAVECALENDARSELFAPPLYLEAVE_REQUEST'
        });
        
        let formData  = new FormData();
        if(file.length!==0){
            for(let i=0;i<file.length;i++){
                formData.append('upload_images',file[i],file[i].name);
            }
        }
        if(leave_options.length !== 0){
            for(let i=0;i<leave_options.length;i++){
                formData.append(`leave_options[${i}]day`,leave_options[i].day);
                formData.append(`leave_options[${i}]date`,leave_options[i].date );
            }
        }
        formData.append('pending',require_confirmation);
        formData.append('leave_entitlement',leave_entitlement);
        formData.append('comment',comment);

        myaxios.post('leave/self/apply/',formData,{headers:{'Content-Type':'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'LEAVECALENDARSELFAPPLYLEAVE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('apply_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVECALENDARSELFAPPLYLEAVE_FAILURE',
                payload:error
            });
        })
    }
}
