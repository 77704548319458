import React from 'react';
import {IMLocalized} from '../../../language/IMLocalized';

function ApprovalTable(props){


    const arr = props.list.filter(item=>item.leave_status === 'PENDING');
    return(
        <div className="card-single cursor-pointer bg-success" onClick={(!props.drag) ? ()=>props.toApprovalLeave() :null} >
            <div>
                <h1 className="text-white">{arr.length}</h1>
                <span className="text-white">{IMLocalized('leave_pending_for_approval')}</span>
            </div>
            <div>
                <i className="far fa-calendar-alt text-white dashboard_text" />
            </div>
        </div>
    )
}
export default ApprovalTable;