import myaxios from '../axios';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';

export const doLogin = (username,password,otp)=>{
    return dispatch =>{
        dispatch({
            type: 'AUTH_REQUEST'
        });
        
        myaxios.post('otp/login/',{
            username:username,
            password:password,
            otp:otp
        })
        .then((response)=>{
            if('token' in response.data){
                localStorage.setItem("tkn",response.data.token);
                localStorage.setItem('navtabs','[{"id":"1","name":"dashboard"}]');
                localStorage.setItem('companylist_step','0');
                localStorage.setItem('activeTabs','1');
                localStorage.setItem('currentRole',0);
                localStorage.setItem('emp_step','1');
                localStorage.setItem('payroll_step','0');
                localStorage.setItem('otal','1');
                localStorage.setItem('add_set','1');
                localStorage.setItem('user_step','1');
                localStorage.setItem('user_emp_step','0');
                localStorage.setItem('leave_reset_step','0');
                localStorage.setItem('claim_alllist_step','0');
                localStorage.setItem('employeelist_step',0);
                localStorage.setItem('leave_emp_step','0');
                localStorage.setItem('claim_emp_step','0');
                localStorage.setItem('holiday_step','0');
                localStorage.setItem('action_toggle',true);
                localStorage.setItem('iraslist_step','0');
            }
            return response.data
        })
        .then((value)=>{
            dispatch({
                type:'AUTH_LOGIN',
                payload:value
                
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'AUTH_FAILURE',
                payload: error,
                error:true
            })
        })
    }
}


export const refreshAccessToken = (token)=>{
    return dispatch =>{
        myaxios.post('/token/refresh/',
        {
            refresh:token
        },
        {
            'Content-Type':'application/json',
             
        })
        .then((response)=>{
            dispatch({
                type:TOKEN_SUCCESS,
                payload:response
                
            },console.log('qweqweqwe'))
        })
        
    }
}