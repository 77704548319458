import * as updateeducation from '../../action/employee/update_employee_education';

const initialState={
    isLoading:false,
    data:null,
    errors:null,
}

const update_employee_education = (state=initialState,action)=>{
    switch(action.type){
        case updateeducation.UPDATEEMPEDUCATION_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case updateeducation.UPDATEEMPEDUCATION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updateeducation.UPDATEEMPEDUCATION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case updateeducation.UPDATEEMPEDUCATION_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default update_employee_education;