import * as arrayapproveclaim from '../../action/employee/emp_all_array_approve_apply_claim';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const emp_all_array_approve_apply_claim = (state=initialState,action)=>{
    switch(action.type){
        case arrayapproveclaim.EMPALLARRAYAPPROVECLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case arrayapproveclaim.EMPALLARRAYAPPROVECLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case arrayapproveclaim.EMPALLARRAYAPPROVECLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case arrayapproveclaim.EMPALLARRAYAPPROVECLAIM_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
            
    }
}
export default emp_all_array_approve_apply_claim;