import * as assignscheme2 from '../../action/claim/claim_assign_scheme2';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const claim_assign_scheme2 =(state=initialState,action)=>{
    switch(action.type){
        case assignscheme2.CLAIMASSIGNSCHEME2_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case assignscheme2.CLAIMASSIGNSCHEME2_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case assignscheme2.CLAIMASSIGNSCHEME2_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default claim_assign_scheme2;