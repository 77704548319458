import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEDASHBOARDWIDGET_REQUEST = 'UPDATEDASHBOARDWIDGET_REQUEST';
export const UPDATEDASHBOARDWIDGET_SUCCESS = 'UPDATEDASHBOARDWIDGET_SUCCESS';
export const UPDATEDASHBOARDWIDGET_FAILURE = 'UPDATEDASHBOARDWIDGET_FAILURE';


export const update_dashboard_widget =(layout)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEDASHBOARDWIDGET_REQUEST'
        });

        myaxios.post('general/dashboard/',{
            data:layout
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEDASHBOARDWIDGET_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('save_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEDASHBOARDWIDGET_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('save_failure'),
            });
        })

    }
}