import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input,Col, FormFeedback, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { create_sg_maternity, reset_create_sg_maternity } from '../../../../action/employee/create_sg_maternity';
import { create_sg_maternity_actual, reset_create_sg_maternity_actual } from '../../../../action/employee/create_sg_maternity_actual';
import { popError } from '../../../../func/popError';

function ModalCreateMaternity({ toggle, closeModal, id, onSuccess }){

    const maternity_success = useSelector(state=>state.create_sg_maternity_actual.data);
    const isLoading = useSelector(state=>state.create_sg_maternity_actual.isLoading);
    const isLoading1 = useSelector(state=>state.create_sg_maternity.isLoading);
    const validate_success = useSelector(state=>state.create_sg_maternity.data);
    const validate_errors = useSelector(state=>state.create_sg_maternity.errors);

    const dispatch = useDispatch();

    const [estimated_delivery_date,setDate] = useState('');
    const [err_estimated_delivery_date,setErrDate] = useState('');
    const [block_leave_start_date,setBlockDate] = useState('');
    const [err_block_leave_start_date,setErrBlockDate] = useState('');
    const [birth_times,setBirthTimes] = useState(0);
    const [err_birth_times,setErrBirthTimes] = useState('');
    const [is_sg,setSg] = useState(false);
    const [flexible_leave,setFlexible] = useState(false);
    

    useEffect(()=>{
        if( toggle && validate_success != null){
            const warning_msg = validate_success.warning_message;
            if(warning_msg.length !== 0){
                const word = 'This leave is '+ validate_success['warning_message'].toString();
                popError(word);
            }
            else{
                dispatch(create_sg_maternity_actual(id,estimated_delivery_date,block_leave_start_date,birth_times,is_sg,flexible_leave));
            }
            dispatch(reset_create_sg_maternity());
        }
    },[validate_success,toggle,birth_times,block_leave_start_date,estimated_delivery_date,flexible_leave,is_sg,id,dispatch])

    useEffect(()=>{
        if( maternity_success != null && toggle){
            onSuccess();
            dispatch(reset_create_sg_maternity_actual());
        }
    },[maternity_success,toggle,onSuccess, dispatch])

    useEffect(()=>{
        if( validate_errors !== null && toggle){
            if(validate_errors.hasOwnProperty('non_field_errors')){
                const word = validate_errors['non_field_errors'].toString();
                popError(word);
            }
            else if(validate_errors.hasOwnProperty('block_leave_start_date')){
                const word = `${IMLocalized('block_leave_start_date')} :\n` + validate_errors['block_leave_start_date'].toString();
                popError(word)
            }
            else if(validate_errors.hasOwnProperty('birth_times')){
                const word = `${IMLocalized('birth_times')} :\n`+ validate_errors['birth_times'].toString();
                popError(word)
            }
            if(validate_errors.hasOwnProperty('employee')){
                const word = validate_errors['employee'].toString();
                popError(word);
            }
            dispatch(reset_create_sg_maternity());
        } 
    },[validate_errors,toggle, dispatch])

    const applyMaternity=()=>{
        if(estimated_delivery_date !== '' && block_leave_start_date !== '' && birth_times !== ''){
            dispatch(create_sg_maternity(id,estimated_delivery_date,block_leave_start_date,birth_times,is_sg,flexible_leave));
        }
        else{
            if(estimated_delivery_date === ''){
                setErrDate(IMLocalized('estimated_delivery_date_is_required'))
            }
            if(block_leave_start_date === ''){
                setErrBlockDate(IMLocalized('block_leave_start_date_is_required'));
            }
            if(birth_times === ''){
                setErrBirthTimes(IMLocalized('birth_times_is_required'));
            }
        }
    }
    
    let table;
    if(estimated_delivery_date === ''){
        table = <Input size="sm" name="block_leave_start_date" type="date" id="block_leave_start_date" value={block_leave_start_date} onChange={(e)=>{setBlockDate(e.target.value); setErrBlockDate('')}} invalid={err_block_leave_start_date.length >0} />
    }
    else{
        let initial_date = new Date(estimated_delivery_date);
        initial_date.setDate(initial_date.getDate()-28);
        let ini_month = (initial_date.getMonth()+1);
        if(ini_month <10){
            ini_month= '0'+ini_month;
        }
        let ini_day = initial_date.getDate();
        if(ini_day <10){
            ini_day = '0'+ini_day;
        }
        let ini_date = initial_date.getFullYear()+'-'+ini_month+'-'+ini_day;              
        table= <Input size="sm" type="date" min={ini_date} max={estimated_delivery_date} id="block_leave_start_date" name="block_leave_start_date" value={block_leave_start_date} onChange={(e)=>{setBlockDate(e.target.value); setErrBlockDate('')}} invalid={err_block_leave_start_date.length>0} />
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('employee_maternity_leave')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor="date">{IMLocalized('estimated_delivery_date')}</label>
                            <Input size="sm" type="date" id="date" name="estimated_delivery_date" value={estimated_delivery_date} onChange={(e)=>{setDate(e.target.value); setErrDate('')}} invalid={err_estimated_delivery_date.length >0} />
                            <FormFeedback>{err_estimated_delivery_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label'>{IMLocalized('block_leave_start_date')}</label>
                            {table}
                            <FormFeedback>{err_block_leave_start_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' id="birth_times">{IMLocalized('birth_times')}</label>
                            <Input id="birth_times" size="sm" min='0' type="number" name="birth_times" value={birth_times} onChange={(e)=>{setBirthTimes(e.target.value); setErrBirthTimes('')}} invalid={err_birth_times.length >0} />
                            <FormFeedback>{err_birth_times}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="12">
                        <div className="custom-control custom-checkbox mt-1">
                            <input type="checkbox" id="maternity_sg" name='is_sg' className="custom-control-input" checked={is_sg} onChange={(e)=>setSg(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="maternity_sg">{IMLocalized('is_sg')}</label>
                        </div>
                        <div className="custom-control custom-checkbox mt-1">
                            <input type="checkbox" id="maternity_flexible" name='flexible_leave' className="custom-control-input" checked={flexible_leave} onChange={(e)=>{setFlexible(e.target.checked)}} />
                            <label className="custom-control-label" htmlFor="maternity_flexible">{IMLocalized('flexible_leave')}</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading || isLoading1 ?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button onClick={applyMaternity} className="btn btn-primary btn-sm">{IMLocalized('apply')}</button>
                    <button onClick={closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>
                }
            </ModalFooter>
        </Modal>
    )
}
export default ModalCreateMaternity;