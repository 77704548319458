import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem } from 'reactstrap';
import { get_employee_probation_list } from '../../action/get_employee_probation_list';
import { IMLocalized } from '../../language/IMLocalized';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';
import { getDateFormat } from '../../func/getDateFormat';
import { popError } from '../../func/popError';
import moment from 'moment';

function EmployeeProbationReport(){

    const dispatch = useDispatch();
    const list = useSelector(state=>state.get_employee_probation_list.data);
    const isLoading = useSelector(state=>state.get_employee_probation_list.isLoading);

    const [count,setCount] = useState(0);

    useEffect(()=>{
        if(count !== 0 && !isLoading){
            const wb = new ExcelJs.Workbook();
            const ws = wb.addWorksheet('Employee Probation Report');
            const today = moment().format('DD/MM/YYYY');
            const time = moment().format('HH:mm:ss');
            const day =moment().format("DD");
            const month =moment().format("MM");
            const title_name = `EmployeeProbationReport_${day}-${month}`;
            ws.getCell('A1').value = 'Employee Probation Report';
            ws.getCell('A2').value = `Printed On : ${today} ${time}`;
            const column =[
                {header:'Emp ID',key:'employee_number'},
                {header:'Name',key:'name'},
                {header:'Probation End Date',key:'probation_end_date'}
            ]
            ws.columns = [
                {width:15},
                {width:20},
                {width:25}
            ];

            for(let j=0;j<column.length;j++){
                const headerName = column[j].header;
                const field = (column[j].key);
                ws.getCell(getColumnName(j)+'5').value = headerName;
                for(let x=0;x<list.length;x++){
                    const step = 6+x;
                    const item = getValue(list[x],field);
                    if(field === 'probation_end_date'){
                        if(item !== null && item !== '' && item !== undefined){
                            ws.getCell(getColumnName(j)+step).value = getDateFormat(item);
                        }
                        else{
                            ws.getCell(getColumnName(j)+step).value = '-';
                        }
                    }
                    else{
                        if(item !== null && item !== '' && item !== undefined){
                            ws.getCell(getColumnName(j)+step).value = item;
                        }
                        else{
                            ws.getCell(getColumnName(j)+step).value = '-';
                        }
                    }
                } 
            }

            if(list.length !== 0 ){
                const file_name = title_name +'.xlsx';
                wb.xlsx.writeBuffer().then((buf)=>{
                    var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                    FileSaver.saveAs(file, file_name)
                });
                setCount(0);
            }
            else{
                popError(IMLocalized('no_data'));
            }
        }
    },[count,list,isLoading])

    return(
        <DropdownItem className="cursor-pointer" 
        onClick={isLoading || count !== 0 ? null :()=>{
            dispatch(get_employee_probation_list());
            setCount(count+1);
        }}>
        {isLoading || count !== 0 ? `${IMLocalized('loading')}..` : IMLocalized('employee_probation_report')}
        </DropdownItem>
    )
}
export default EmployeeProbationReport;