import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import Swal from 'sweetalert2';
import { delOvertime, reset_delete_overtime } from '../../../action/delete_overtime';
import { get_additional_setting } from '../../../action/additional_setting';
import ModalCreateOvertime from '../modal/ModalCreateOvertime';

function OvertimeContent(){

    const [create_toggle,setCreateToggle] = useState(false);
    const dispatch = useDispatch();
    const overtimelist = useSelector(state=>state.add.overtime);
    const isLoading = useSelector(state=>state.add.isLoading);
    const success = useSelector(state=>state.delete_overtime.data);

    useEffect(()=>{
        if( success !== null){
            dispatch(get_additional_setting());
            dispatch(reset_delete_overtime());
        }
    },[success,dispatch])

    const removeOvertime=(code,id)=>{
        Swal.fire({
            type:'warning',
            title:`${IMLocalized('do_you_really_want_to_delete')} \n ${IMLocalized('overtime')} (`+ code + `)`,
            showCancelButton:true,
            confirmButtonColor:'red'
        })
        .then((result)=>{
            if(result.value){
                dispatch(delOvertime(id));
            }
        })
    }

    return(
        <>
        <Card>
            <CardHeader className='d-flex justify-content-between align-items-center'>
                <h4 className="mb-0">{IMLocalized('overtime')}</h4>
                <button className="btn btn-primary btn-sm" onClick={()=>setCreateToggle(true)}>{IMLocalized('add')}</button>
            </CardHeader>
            {isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
            {!isLoading && overtimelist.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
            {!isLoading && overtimelist.length !== 0 &&
            <Table size="sm" className='align-items-center' responsive>
                <thead className='text-center'>
                    <tr>
                        <th>{IMLocalized('code')}</th>
                        <th>{IMLocalized('multiplier')}</th>
                        <th>{IMLocalized('delete')}</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {overtimelist.map((item,index)=>{
                        return(
                            <tr key={index}>
                                <td>{item.code}</td>
                                <td>{item.multiplier}</td>
                                <td align="center" valign="center">
                                    <div className="delete_btn d-flex align-items-center justify-content-center">
                                        <i onClick={()=>removeOvertime(item.code,item.id)} className="fas fa-trash text-red cursor-pointer"/>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>}
        </Card>
        <ModalCreateOvertime toggle={create_toggle} closeModal={()=>setCreateToggle(false)} />
        </>
    )
}
export default OvertimeContent;