import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { get_additional_setting } from '../../../action/additional_setting';
import { create_recurring, reset_create_recurring} from '../../../action/create_recurring';
import { IMLocalized } from '../../../language/IMLocalized';

const initialState={
    code:'',
    err_code:'',
    name:'',
    err_name:'',
    amount:'',
    err_amount:'',
    type:'',
    err_type:'',
    gross:false,

}

function ModalCreateRecurring({toggle,closeModal}){
    const isLoading = useSelector(state=>state.create_recurring.isLoading);
    const success = useSelector(state=>state.create_recurring.data);
    const errors = useSelector(state=>state.create_recurring.errors);
    const typelist = useSelector(state=>state.dropdown_list.allowance_type);
    const [data,setData] = useState(initialState);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(toggle){
            setData(initialState);
        }
    },[toggle])

    useEffect(()=>{
        if( toggle && success !== null ){
            closeModal();
            dispatch(get_additional_setting());
            dispatch(reset_create_recurring());
        }
    },[success,toggle,closeModal,dispatch])


    useEffect(()=>{
        if( toggle && errors !== null){
            setData(prevState=>({
                ...prevState,
                err_name:errors.hasOwnProperty('name') ? errors['name'].toString() : '',
                err_code:errors.hasOwnProperty('non_field_errors') ? errors['non_field_errors'].toString() : '',
            }));
            dispatch(reset_create_recurring());
        }
    },[errors,toggle,dispatch])

    const createRecurring=()=>{
        if(data.name !== '' && data.amount !== '' && data.code !== '' && data.type !== ''){
            dispatch(create_recurring(data.code,data.name,data.amount,data.gross,data.type));
        }
        else{
            setData(prevState=>({
                ...prevState,
                err_code:data.code === '' ? IMLocalized('code_is_required') : '',
                err_name:data.name === '' ? IMLocalized('name_is_required') : '',
                err_type:data.type === '' ? IMLocalized('type_is_required') : '',
                err_amount:data.amount === '' ? IMLocalized('amount_is_required') : '',
            }))
        }
    }

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={()=>closeModal()}>{IMLocalized('add_recurring_allowance')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='code'>{IMLocalized('code')}</label>
                            <Input type="text" size="sm" id="code" value={data.code} invalid={data.err_code.length >0}
                            onChange={(e)=>{
                                const value = e.target.value; 
                                setData(prevState=>({
                                    ...prevState,
                                    code:value,
                                    err_code:''}))
                            }}  
                            />
                            <FormFeedback>{data.err_code}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name' >{IMLocalized('name')}</label>
                            <Input type="text" size="sm" id="name" value={data.name} invalid={data.err_name.length >0}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setData(prevState=>({
                                    ...prevState,
                                    name:value,
                                    err_name:''
                                }))
                            }}
                            />
                            <FormFeedback>{data.err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='type'>{IMLocalized('type')}</label>
                            <Input type="select" size="sm" id="type" value={data.type} invalid={data.err_type.length >0}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setData(prevState=>({
                                    ...prevState,
                                    type:value,
                                    err_type:'',
                                }))
                            }}>
                                <option></option>
                                {typelist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{data.err_type}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='amount'>{IMLocalized('amount')}</label>
                            <Input type="number" size="sm" step='1' min="0" id="amount" value={data.amount} invalid={data.err_amount.length > 0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setData(prevState=>({
                                    ...prevState,
                                    amount:value,
                                    err_amount:''
                                }))
                            }}
                            />
                            <FormFeedback>{data.err_amount}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="12" className="d-flex align-items-center">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="company_recurringgross" name='recurring_gross' className="custom-control-input" checked={data.gross} 
                            onChange={(e)=>{
                                const checked = e.target.checked;
                                setData(prevState=>({
                                    ...prevState,
                                    gross:checked
                                }))
                            }} />
                            <label className="custom-control-label" htmlFor="company_recurringgross">{IMLocalized('is_included_in_gross')}</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button className="btn btn-primary btn-sm" onClick={()=>createRecurring()}>{IMLocalized('add')}</button>
                <button className="btn btn-danger btn-sm" onClick={()=>closeModal()}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default ModalCreateRecurring;