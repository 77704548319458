import React from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { IMLocalized } from "../../../../language/IMLocalized";

export default function PayrollTable({ toDetail }){

    const payroll_list = useSelector(state=>state.get_employee_payroll.data);
    
    const monthNames = [IMLocalized('january'), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")]
    
    return(
        <Table size="sm" className="mt-2 align-items-center table-bordered" responsive>
            <thead className='thead-light'>
                <tr>
                    <th>{IMLocalized('payroll_list')}</th>
                </tr>
            </thead>
            <tbody>
                {payroll_list.map((item)=>{
                    const date = item.payroll_date;
                    const month = new Date(date).getMonth();
                    const month1 = monthNames[month];
                    const year = new Date(date).getFullYear();
                    return(
                        <tr key={item.id}>
                            <td><a href='/#' onClick={()=>toDetail(item.payroll.id)}>{month1} {year}</a></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}