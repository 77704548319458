import * as updateresign from '../../action/employee/update_resign';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_resign = (state=initialState,action)=>{
    switch(action.type){
        case updateresign.UPDATERESIGNEMPLOYEE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case updateresign.UPDATERESIGNEMPLOYEE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updateresign.UPDATERESIGNEMPLOYEE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case updateresign.UPDATERESIGNEMPLOYEE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default update_resign;