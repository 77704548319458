import * as list from '../../action/iras/get_iras_list';


const initialState={
    data:[],
    errors:[],
    isLoading:false
}


const get_iras_list = (state=initialState,action)=>{
    switch(action.type){
        case list.GETIRASLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[],
            }
        case list.GETIRASLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case list.GETIRASLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default get_iras_list;