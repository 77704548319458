import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody, FormGroup,Input,FormFeedback } from 'reactstrap';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../language/IMLocalized';
import { reset_update_general_logo, update_general_logo } from '../../action/update_general_logo';
import { reset_update_general_letter, update_general_letter } from '../../action/update_general_letter';
import { popError } from '../../func/popError';
import { get_general_logo } from '../../action/get_general_logo';
import { get_general_letter } from '../../action/get_general_letter';
import { reset_update_general_color, update_general_color} from '../../action/update_general_color';
import { get_general_color} from '../../action/get_general_color';
import { get_base_setting } from '../../action/get_base_setting';
import {reset_update_general_setting, update_general_setting} from '../../action/update_general_setting';
import { NewMainContext } from '../../context/NewMainContext';
import OverlayLoading from '../loading_component/overlay_loading';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

const initialState={
    default_probation_amount:'',
    err_default_probation_amount:'',
    default_probation_choice:'MONTH',
    base_currency_symbol:'',
    err_base_currency_symbol:'',
    base_currency_name:'',
    err_base_currency_name:'',
    pay_period:'',
    err_pay_period:'',
    end_day:'',
    err_end_day:'',
}

const initialColor={
    pending_color:'#ffffff',
    pending_no_action_color:'#ffffff',
    approved_color:'#ffffff',
    rejected_color:'#ffffff',
    cancelled_color:'#ffffff',
    consumed_color:'#ffffff',
    resubmit_color:'#ffffff',
    paid_color:'#ffffff',
}

function GeneralSetting(){

    const [data,setData] = useState(initialState);
    const [colors,setColors] = useState(initialColor);
   
    const daylist = useSelector(state=>state.dropdown_list.days_range_selector);
    const dropdown = useSelector(state=>state.dropdown_list.payroll_choice);
    const basesetting = useSelector(state=>state.get_base_setting.data);
    const isLoading = useSelector(state=>state.get_base_setting.isLoading);
    const colorlist = useSelector(state=>state.get_general_color.data);
    const payrollperiod = useSelector(state=>state.get_payroll_period.data);
    const logo_success = useSelector(state=>state.update_general_logo.data);
    const letter_success = useSelector(state=>state.update_general_letter.data);
    const color_success = useSelector(state=>state.update_general_color.data);
    const base_success = useSelector(state=>state.update_general_setting.data);

    const dispatch = useDispatch();
    const { addItem } = useContext(NewMainContext);
    
    useEffect(()=>{ 
        if(basesetting !== null){
            setData(prevState=>({
                ...prevState,
                default_probation_amount: basesetting.default_probation_amount,
                default_probation_choice: basesetting.default_probation_choice,
                base_currency_name: basesetting.base_currency_name,
                base_currency_symbol: basesetting.base_currency_symbol,
                err_base_currency_name:'',
                err_base_currency_symbol:'',
                err_default_probation_amount:'',
            }))
        }
    },[basesetting])

    useEffect(()=>{
        if(payrollperiod !== null){
            setData(prevState=>({
                ...prevState,
                pay_period: payrollperiod.pay_period,
                end_day: payrollperiod.end_day,
                err_end_day:'',
                err_pay_period:'',
            }))
        }
    },[payrollperiod])

    useEffect(()=>{
        if(colorlist.length !== 0){
            
            setColors(prevState=>({
                ...prevState,
                pending_color: colorlist.data.PENDING,
                pending_no_action_color:colorlist.data.PENDING_NO_ACTION,
                approved_color: colorlist.data.APPROVED,
                rejected_color: colorlist.data.REJECTED,
                cancelled_color: colorlist.data.CANCELLED,
                consumed_color: colorlist.data.CONSUMED,
                resubmit_color: colorlist.data.RESUBMIT,
                paid_color: colorlist.data.PAID,
            }))
        }
    },[colorlist])

    useEffect(()=>{
        if( logo_success !== null){
            dispatch(get_general_logo());   
            dispatch(reset_update_general_logo());
        }
    },[logo_success,dispatch])

    useEffect(()=>{
        if( letter_success !== null){
            dispatch(get_general_letter());
            dispatch(reset_update_general_letter());
        }
    },[letter_success,dispatch])

    useEffect(()=>{
        if( color_success !== null){
            dispatch(get_general_color());
            dispatch(reset_update_general_color());
        }
    },[color_success,dispatch])

    useEffect(()=>{
        if( base_success !== null){
            dispatch(get_base_setting());
            dispatch(reset_update_general_setting());
        }
    },[base_success,dispatch])

    const onUpdate=()=>{
        if(data.default_probation_amount !=='' && data.base_currency_name !== '' && data.base_currency_symbol !== ''){
            dispatch(update_general_setting(data.default_probation_choice,data.default_probation_amount,data.base_currency_name,data.base_currency_symbol));
        }
        else{
            setData(prevState=>({
                ...prevState,
                err_default_probation_amount:IMLocalized('probation_amount_is_required'),
                err_base_currency_name:IMLocalized('base_currency_name_is_required'),
                err_base_currency_symbol:IMLocalized('base_currency_symbol_is_required'),
            }))
        }
    }

    const updateColor=()=>{
        dispatch(update_general_color(colors.pending_color,colors.pending_no_action_color,colors.approved_color,colors.rejected_color,colors.cancelled_color,colors.consumed_color,colors.resubmit_color,colors.paid_color));
    }

    return(
        <>
        {isLoading ? <OverlayLoading/> :
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('general_setting')}
                    ]}/>
                </Col>
            </Row>
        </Container>
        <Container className='mt-2'>
            <Card>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <h4 className="mb-0">{IMLocalized('general_setting')}</h4>
                    <button onClick={()=>onUpdate()} className="btn btn-primary btn-sm">{IMLocalized('update')}</button>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className='form-control-label' htmlFor='default_probation_amount'>{IMLocalized('default_probation')}</label>
                                <div className='input-group input-group-sm'>
                                    <Input type="number" id="default_probation_amount" size="sm" min="0" step="1" value={data.default_probation_amount} invalid={data.err_default_probation_amount.length > 0}
                                    onChange={(e)=>{
                                        const value = e.target.value;
                                        setData(prevState=>({
                                            ...prevState,
                                            default_probation_amount:parseInt(value),
                                            err_default_probation_amount:'',
                                        }))
                                    }}  
                                    />
                                    <div className='input-group-prepend'>
                                        <Input type="select" size="sm" value={data.default_probation_choice} id="default_probation_choice" 
                                        onChange={(e)=>{
                                            const value = e.target.value;
                                            setData(prevState=>({
                                                ...prevState,
                                                default_probation_choice:value
                                            }))
                                        }}>
                                            {daylist.map((item,index)=>{
                                                return <option value={item.value} key={index}>{item.display}</option>
                                            })}
                                        </Input>
                                    </div>
                                    <FormFeedback>{data.err_default_probation_amount}</FormFeedback>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className='form-control-label' htmlFor='base_currency_name'>{IMLocalized('base_currency_name')}</label>
                                <Input type="text" id="base_currency_name" size="sm" value={data.base_currency_name} invalid={data.err_base_currency_name.length >0} 
                                onChange={(e)=>{
                                    const value = e.target.value;
                                    setData(prevState=>({
                                        ...prevState,
                                        base_currency_name:value,
                                        err_base_currency_name:'',
                                    }))
                                }}
                                />
                                <FormFeedback>{data.err_base_currency_name}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className='form-control-label' htmlFor='base_currency_symbol'>{IMLocalized('base_currency_symbol')}</label>
                                <Input type="text" size="sm" id="base_currency_symbol" value={data.base_currency_symbol} invalid={data.err_base_currency_symbol.length >0} 
                                onChange={(e)=>{
                                    const value = e.target.value;
                                    setData(prevState=>({
                                        ...prevState,
                                        base_currency_symbol:value,
                                        err_base_currency_symbol:'',
                                    }))
                                }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <h4 className="mb-0">{IMLocalized('period_setting')}</h4>
                    <button  className="btn btn-primary btn-sm" 
                    onClick={()=>popError(IMLocalized('please_contact_vendor_change_payroll_period'))}
                    >{IMLocalized('info')}</button>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className='form-control-label' htmlFor='pay_period'>{IMLocalized('pay_period')}</label>
                                <Input size="sm" type="select" id="pay_period" value={data.pay_period} invalid={data.err_pay_period.length >0} readOnly={true}>
                                    <option></option>
                                    {dropdown.map((item,index)=>{
                                        return <option value={item.value} key={index}>{item.display}</option>
                                    })}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className='form-control-label' htmlFor='end_day'>{IMLocalized('end_day')}</label>
                                <Input size="sm" id="end_day" type="text" value={data.end_day ?? ''} invalid={data.err_end_day.length > 0} onChange={(e)=>{const value = e.target.value; setData(prevState=>({...prevState,end_day:value,err_end_day:''}))}} readOnly={true} />
                                <FormFeedback>{data.err_end_day}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardHeader className="d-flex align-items-center">
                    <h4 className="mb-0">{IMLocalized('logo_picture')}</h4>
                </CardHeader>
                <CardBody>
                    <input type="file" id="ImportLogoFile" accept="image/*"
                    onClick={(event)=>{
                        const { target = {} } = event || {};
                        target.value = "";
                    }}
                    onChange={(e)=>{
                        let file = e.target.files[0];
                        Swal.fire({
                            type:'warning',
                            title:IMLocalized('are_you_sure_upload_photo'),
                            showCancelButton:true,
                            confirmButtonText:IMLocalized('yes!'),
                            cancelButtonText:IMLocalized('no!')
                        })
                        .then((result)=>{
                            if(result.value){
                                dispatch(update_general_logo(file));
                            }
                        })
                    }}
                    />
                </CardBody>
            </Card>
            <Card>
                <CardHeader className="d-flex align-items-center">
                    <h4 className="mb-0">{IMLocalized('letter_head')}</h4>
                </CardHeader>
                <CardBody>
                    <input type="file" id="ImportLetterFile" accept="image/*"
                    onClick={(event)=>{
                        const { target = {} } = event || {};
                        target.value = "";
                    }}
                    onChange={(e)=>{
                        let file = e.target.files[0];
                        Swal.fire({
                            type:'warning',
                            title:IMLocalized('are_you_sure_upload_photo'),
                            showCancelButton:true,
                            confirmButtonText:IMLocalized('yes!'),
                            cancelButtonText:IMLocalized('no!')
                        })
                        .then((result)=>{
                            if(result.value){
                                dispatch(update_general_letter(file))
                            }
                        })
                    }}
                    />
                </CardBody>
            </Card>
            <Card>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <h4 className="mb-0">{IMLocalized('general_color')}</h4>
                    <button className="btn btn-primary btn-sm" onClick={()=>updateColor()}>{IMLocalized('update')}</button>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='pending_color'>{IMLocalized('pending_color')}</label>
                                <Input  size="sm" type="color" id="pending_color" value={colors.pending_color} 
                                onChange={(e)=>{
                                    const value = e.target.value;
                                    setColors(prevState=>({
                                        ...prevState,
                                        pending_color:value
                                    }))
                                }} />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='pending_no_action_color'>{IMLocalized('pending_no_action_color')}</label>
                                <Input size="sm" type="color" id="pending_no_action_color" value={colors.pending_no_action_color} 
                                onChange={(e)=>{
                                    const value = e.target.value;
                                    setColors(prevState=>({
                                        ...prevState,
                                        pending_no_action_color:value
                                    }))
                                }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='approved_color'>{IMLocalized('approved_color')}</label>
                                <Input size="sm" type="color" id="approved_color" value={colors.approved_color} 
                                onChange={(e)=>{
                                    const value = e.target.value;
                                    setColors(prevState=>({
                                        ...prevState,
                                        approved_color:value
                                    }))
                                }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='rejected_color'>{IMLocalized('rejected_color')}</label>
                                <Input size="sm" type="color" id="rejected_color" value={colors.rejected_color} 
                                onChange={(e)=>{
                                    const value = e.target.value;
                                    setColors(prevState=>({
                                        ...prevState,
                                        rejected_color:value
                                    }))
                                }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='cancelled_color'>{IMLocalized('cancelled_color')}</label>
                                <Input size="sm" type="color" id="cancelled_color" value={colors.cancelled_color} 
                                onChange={(e)=>{
                                    const value = e.target.value;
                                    setColors(prevState=>({
                                        ...prevState,
                                        cancelled_color:value
                                    }))
                                }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='consumed_color'>{IMLocalized('consumed_color')}</label>
                                <Input size="sm" type="color" id="consumed_color" value={colors.consumed_color} 
                                onChange={(e)=>{
                                    const value = e.target.value;
                                    setColors(prevState=>({
                                        ...prevState,
                                        consumed_color:value
                                    }))
                                }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='resubmit_color'>{IMLocalized('resubmit_color')}</label>
                                <Input size="sm" type="color" id="resubmit_color" value={colors.resubmit_color} 
                                onChange={(e)=>{
                                    const value = e.target.value;
                                    setColors(prevState=>({
                                        ...prevState,
                                        resubmit_color:value
                                    }))
                                }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='paid_color'>{IMLocalized('paid_color')}</label>
                                <Input size="sm" type="color" id="paid_color" value={colors.paid_color} 
                                onChange={(e)=>{
                                    const value = e.target.value;
                                    setColors(prevState=>({
                                        ...prevState,
                                        paid_color:value
                                    }))
                                }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
        </>}
        </>
    )
}
export default GeneralSetting;