import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const NEW_UPDATEEMPPASSPORT_REQUEST = 'NEW_UPDATEEMPPASSPORT_REQUEST';
export const NEW_UPDATEEMPPASSPORT_SUCCESS = 'NEW_UPDATEEMPPASSPORT_SUCCESS';
export const NEW_UPDATEEMPPASSPORT_FAILURE = 'NEW_UPDATEEMPPASSPORT_FAILURE';
export const NEW_UPDATEEMPPASSPORT_RESET = 'NEW_UPDATEEMPPASSPORT_RESET';


export const update_employee_passport = (data) =>{
    return dispatch=>{
        dispatch({
            type:'NEW_UPDATEEMPPASSPORT_REQUEST'
        });

        if(data.length !== 0){
            for(let i =0;i<data.length;i++){

                const {id, passport, issued_date, expiry_date} = data[i];

                myaxios.patch(`employee/passport/${id}/`,{
                    passport:passport,
                    issued_date:issued_date,
                    expiry_date:expiry_date
                })
                .then((response)=>{
                    if(i === data.length -1){
                        dispatch({
                            type:'NEW_UPDATEEMPPASSPORT_SUCCESS',
                            payload:response
                        });
    
                        Toast.fire({
                            type: 'success',
                            title: IMLocalized('update_success')
                        });
                    }
                })
                .catch((error)=>{
                    dispatch({
                        type:'NEW_UPDATEEMPPASSPORT_FAILURE',
                        payload:error
                    });

                    ErrToast.fire({
                        type:'error',
                        title:IMLocalized('update_failure')
                    });
                })
            }
        }
    }
}

export const reset_update_employee_passport = () => dispatch =>{
    dispatch({
        type:'NEW_UPDATEEMPPASSPORT_RESET'
    });
}

