import React, { useEffect, useState } from 'react';
import {Input, Container,FormFeedback,Row,Col} from 'reactstrap';
import { general_group } from '../../action/general_group';
import { otp_check } from '../../action/otp_check';
import '../../css/login2.css';
import {connect} from 'react-redux';
import {get_general_logo} from '../../action/get_general_logo';
import a_line from '../../images/aline.png';
import {doLogin} from '../../action/auth';
import {authErrors,isAuthenticated} from '../../reducers/rootReducer';
import { IMLocalized, init } from '../../language/IMLocalized';
import {reset_otp_password} from '../../action/reset_otp_password';
import OverlayLoading from '../loading_component/overlay_loading';
import Swal from 'sweetalert2';
import { Buffer } from 'buffer';
import ModalEmail from './modal/ModalEmail';
import ModalQr from '../navbar/modal/ModalQr';
import FirstTimeLogin from './modal/FirstTimeLogin';
import { usePrevious } from '../../hook/usePrevious';
import ModalOtp from './modal/ModalOtp';

function Login(props){
    const {get_general_logo,isLoading,logo1,doLogin,sent_success,success,errors} = props;
    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');
    const [password,setPassword] = useState('');
    const [err_password,setErrPassword] = useState('');
    const [err_msg,setErrMsg] = useState('');
    const [open,setOpen] = useState(false);
    const [focus,setFocus] = useState(true);
    const [select_language,setSelectLanguage] = useState(localStorage.getItem('language'));
    const [selection,setSelection] = useState('0');
    const [reset_toggle,setResetToggle] = useState(false);
    const [err_msg2,setErrMsg2] = useState('');
    const [logo,setLogo] = useState(null);
    const [qr_toggle,setQrToggle] = useState(false);
    const [first_toggle,setFirstToggle] = useState(false);
    const [first_token, setFirstToken] = useState(null);

    const prevSuccess = usePrevious(success);
    const prevErrors = usePrevious(errors);
    const prevSentSuccess = usePrevious(sent_success);
    

    useEffect(()=>{
        async function getDetail(){
            const accesstok = localStorage.getItem('tkn');
            if(accesstok !== null && accesstok !== undefined){
                window.location.href="/#/";
            }
            else{
                get_general_logo();
                const title = process.env.REACT_APP_TITLE;
                document.title = title;
                getLanguage();
            }
        }
        getDetail();
        
    },[get_general_logo])

    useEffect(()=>{
        if(logo1 !== null && logo1.length !== 0){
            const picture = logo1.data;
            const byte = picture.byteLength;
            if(byte === 0){
                setLogo(null);
                document.getElementById("favicon").href = a_line;
            }
            else{
                const img = new Buffer.from(picture,'binary').toString('base64');
                const src = 'data:image/png;base64,'+img;
                setLogo(src)
                const url =  process.env.REACT_APP_API_URL
                document.getElementById("favicon").href = `https://${url}/api/general/logo/`;
            }
        }
    },[logo1])

    useEffect(()=>{
        if( prevSuccess !== undefined && prevSuccess !== success){
            if(success.hasOwnProperty('token')){
                window.location.href="/#/";
            }
            else{
                if(success.hasOwnProperty('first_time')){
                    const { first_time } = success;
                    setFirstToggle(true);
                    setFirstToken(first_time);
                }
                else if(success.hasOwnProperty('has_otp')){
                    const {has_otp} = success;
                    if(has_otp){
                        setOpen(true);
                        setFocus(false);
                    }
                }
            }
        }
    },[success,prevSuccess])

    useEffect(()=>{
        if(prevErrors !== undefined && prevErrors !== errors && errors.length !== 0){
            if('auth' in errors){
                setErrMsg(IMLocalized('invalid_credential'));
                const count = errors['count'].toString();
                const max = errors['max'].toString();
                if(count === 'True'){
                    setErrMsg2(IMLocalized('last_attempt'));
                }
                else{
                    setErrMsg2(IMLocalized('login_attempt_number',{count,max}));
                }
            }
            if('blacklist' in errors){
                const expire1 = errors['expire'].toString();
                const expire = parseInt(expire1);
                const minutes = Math.floor(expire / 60);
                const second = expire - minutes * 60;
                setErrMsg(IMLocalized('login_blacklist',{minutes,second}));
                setErrMsg2(IMLocalized('login_too_many'));
            }
        }
    },[errors,prevErrors])

    useEffect(()=>{
        if(prevSentSuccess !== undefined && prevSentSuccess !== sent_success){
            Swal.fire({
                type:'success',
                title:IMLocalized('email_sent'),
                text:IMLocalized('please_check_your_email'),
                confirmButtonText:IMLocalized('ok!')
            })
            .then(()=>setResetToggle(false));
        }
    },[prevSentSuccess,sent_success])

    const getLanguage = ()=>{
        const language = localStorage.getItem('language');
        if(language){
            if(language === 'zh'){
                setSelection('1');
                setSelectLanguage('zh');
            }
            else{
                setSelection('0');
                setSelectLanguage('en');
            }
        }
        else{
            const current  = window.navigator.language;
            const word = current.substring(0,2);
            if(word === 'zh'){
                setSelection('1');
                setSelectLanguage('zh');
                localStorage.setItem('language','zh');
            }
            else{
                setSelection('0');
                setSelectLanguage('en');
                localStorage.setItem('language','en');
            }
        }
    }

    const onSubmitEmail=(email,verify_token)=>{
        props.reset_otp_password(email,verify_token);
    }

    const onSubmit=(e)=>{
        e.preventDefault();
        if(name !== '' && password !== ''){
           doLogin(name,password);
        }
        else{
            if(name === ''){
                setErrName(IMLocalized('login_name_required'))
            }
            if(password === ''){
                setErrPassword(IMLocalized('login_password_required'))
            }
        }
        setFocus(false);
    }

    const submitOtp=(otp)=>{
        doLogin(name,password,otp);
    }

    const handleName=(e)=>{
        setName(e.target.value);
        setErrName('');
        setErrMsg('');
        setErrMsg2('');
    }

    const handlePassword=(e)=>{
        setPassword(e.target.value);
        setErrPassword('');
        setErrMsg('');
        setErrMsg2('');
    }

    const handleSelection=(e)=>{
        const value = e.target.value;
        setSelection(value);
        let string = 'en';
        if(value ==='1'){
            string  = 'zh';     
        }
        Promise.resolve(localStorage.setItem('language',string))
        .then(()=>window.location.reload())
    }

    if(select_language){
        init(select_language);
    }
    else{
        const current  = window.navigator.language;
        const word = current.substring(0,2);
        if(word === 'zh'){
            init(word)
        }
        else{
            init('en')
        }
    }
    return(
        <>
            {isLoading ? <OverlayLoading/>:
            <div className='login2_main'>
                <Container className="login2_contaner">
                    <Row className="d-flex justify-content-center">
                        <Col lg="4" className="login2_left d-none d-lg-block" >
                            <div className="pt-2">
                                {logo ? <img id="profile-img"  src={logo} alt="A login lai de" style={{width:188,height:150}}></img>
                                : <img id="profile-img" src={a_line} alt="A login lai de" style={{width:188,height:150}}></img>}
                                <div className="pt-4">
                                    <h1>{IMLocalized('welcometo')} {process.env.REACT_APP_TITLE}</h1>
                                </div>
                            </div>
                        </Col>
                        <Col lg="8" md="12" sm="12" xs="12" className="login2_sec">
                            <div className="login2_right">
                                <h2 className="text-center">{IMLocalized('signin')}</h2>
                                <form className="form-signin mb-2" onSubmit={onSubmit}>
                                    <div>
                                        <div className="form-group">
                                            <Input type="text" id="inputEmail" placeholder={IMLocalized('employee_number')} name="name" value={name} onChange={handleName} invalid={err_name.length >0} autoFocus={focus}></Input>
                                            <FormFeedback>{err_name}</FormFeedback>
                                        </div>
                                        <div className="form-group">
                                            <Input type="password" id="inputPassword" placeholder={IMLocalized('password')} name="password" value={password} onChange={handlePassword} invalid={err_password.length >0} />
                                            <FormFeedback>{err_password}</FormFeedback>
                                        </div>
                                        <div>
                                            <small className="text-danger">{err_msg}</small>
                                        </div>
                                        <div>
                                            <small className="text-danger">{err_msg2}</small>
                                        </div>
                                        <button className="btn btn-lg btn-block btn2-login" type="submit">{IMLocalized('signin')}</button>
                                    </div>
                                </form>
                                <Row className="mb-1">
                                    <Col className="pt-2">
                                        <span className='text-primary cursor-pointer' onClick={()=>setResetToggle(true)}><small>{IMLocalized('forgot_password')}</small></span>
                                        <div>
                                            <span className='text-primary cursor-pointer' onClick={()=>setQrToggle(true)}><small>{IMLocalized('qr_code')}</small></span>
                                        </div>
                                        
                                    </Col>
                                    <Col className="col-auto pt-2">
                                        <Input style={{width:'120px'}} size="sm" type="select" onChange={handleSelection} name="selection" value={selection} >
                                            <option value='0'>English</option>
                                            <option value="1">中文</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <FirstTimeLogin toggle={first_toggle} closeModal={()=>{setFirstToggle(false); setName(''); setPassword('')}} token={first_token} />
                <ModalEmail toggle={reset_toggle} closeModal={()=>setResetToggle(false)} submitEmail={onSubmitEmail} />
                <ModalQr toggle={qr_toggle} closeModal={()=>setQrToggle(false)} />
                <ModalOtp toggle={open} closeModal={()=>setOpen(false)} submitOtp={submitOtp}  />
            </div>
            }
        </>
    )
}
const mapStateToProps = (state) => ({
    errors: authErrors(state),
    isAuthenticated: isAuthenticated(state),
    err:state.auth.errors,
    success:state.auth.data,
    logo1:state.get_general_logo.data,
    isLoading:state.get_general_logo.isLoading,
    sent_success:state.reset_otp_password.data,
    isLoading1:state.reset_otp_password.isLoading,
})
    
const mapDispatchToProps = (dispatch) => ({
    doLogin: (username, password,otp) => {
        dispatch(doLogin(username, password,otp))
    },
    general_group:()=>{
        dispatch(general_group())
    },
    otp_check:(name,password)=>{
        dispatch(otp_check(name,password))
    },
    get_general_logo:()=>{
        dispatch(get_general_logo())
    },
    reset_otp_password:(email,capcha)=>{
        dispatch(reset_otp_password(email,capcha))
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(Login);