import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {Row,Col,Container,CardBody,Card,Breadcrumb,BreadcrumbItem, ListGroupItem, ListGroup, CardHeader} from 'reactstrap';
import Swal from 'sweetalert2';
import {IMLocalized} from '../../language/IMLocalized';
import {get_appraisal_form_list} from '../../action/appraisal/get_appraisal_form_list';
import { delete_appraisal_list } from '../../action/appraisal/delete_appraisal_list';
import { usePrevious } from '../../hook/usePrevious';
import ModalViewAppraisalDetail from './modal/ModalViewAppraisalDetail';
import { NewMainContext } from '../../context/NewMainContext';

const mapStateToProps=(state)=>({
    list:state.get_appraisal_form_list.data,
    isLoading:state.get_appraisal_form_list.isLoading,
    delete_success:state.delete_appraisal_list.data,
})

const mapDispatchToProps=(dispatch)=>({
    get_appraisal_form_list:()=>{
        dispatch(get_appraisal_form_list())
    },
    delete_appraisal_list:(id)=>{
        dispatch(delete_appraisal_list(id))
    }
})


function AppraisalTable(props){

    const { addItem, setAppraisalListStep } = useContext(NewMainContext);
    const {get_appraisal_form_list} = props;
    const prevDelete = usePrevious(props.delete_success);
    const [toggle,setToggle] = useState(false);
    const [detail,setDetail] = useState(null);

    useEffect(()=>{
        if(prevDelete !== undefined && prevDelete !== props.delete_success && props.delete_success != null){
            get_appraisal_form_list();
        }
    },[prevDelete,props.delete_success,get_appraisal_form_list])

    const deleteAppraisal=(id,name)=>{
        Swal.fire({
            type:'warning',
            title:`${IMLocalized('do_you_really_want_to_delete')} (` + name+') ',
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then(result=>{
            if(result.value){
                props.delete_appraisal_list(id);
            }
        })
    }

    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('appraisal_form_list')}</BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </Container>
        <Container >
            <Row className='mt-2'>
                <Col>
                    <Card>
                        <CardHeader className='d-flex justify-content-between'>
                            <h4 className='mb-0'>{IMLocalized('appraisal_form_list')}</h4>
                            <button className='btn btn-primary btn-sm' onClick={()=>setAppraisalListStep(1)}>{IMLocalized('create')}</button>
                        </CardHeader>
                        {props.isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                        {!props.isLoading && props.list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {!props.isLoading && props.list.length !== 0 &&
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                            {props.list.map((item,index)=>{

                                return(
                                    <ListGroupItem className="py-2" key={index}>
                                        <Row className="align-items-center">
                                            <Col>
                                                {index + 1}. {item.name}
                                            </Col>
                                            <Col className="col-auto mr-1">
                                                <button className="btn btn-info btn-sm" onClick={()=>{setToggle(true); setDetail(item)}} >{IMLocalized('detail')}</button>
                                                <button className="btn btn-danger btn-sm" onClick={()=>deleteAppraisal(item.id,item.name)}>{IMLocalized('delete')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
        </Container>
        <ModalViewAppraisalDetail toggle={toggle} detail={detail} closeModal={()=>setToggle(false)} />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(AppraisalTable);