import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader,Row,Col,FormFeedback,Input,FormGroup, ModalFooter } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { branch_get_sg_postal, reset_branch_get_sg_postal} from '../../../action/company/branch_get_sg_postal';
import { create_company, reset_create_company} from '../../../action/create_company';
import { popError } from '../../../func/popError';


function ModalCreateCompany(props){
    const {sg_address,errors,success,onSuccess,reset_branch_get_sg_postal,reset_create_company} = props;
    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');
    const [contact_number,setContactNumber] = useState('');
    const [err_contact_number,setErrContactNumber] = useState('');
    const [uen,setUen] = useState('');
    const [err_uen,setErrUen] = useState('');
    const [cpf_payment_type ,setPaymentType] = useState('');
    const [err_payment_type,setErrPaymentType] = useState('');
    const [cpf_serial_number,setSerialNumber] = useState('');
    const [err_serial_number,setErrSerialNumber] = useState('');
    const [sg_postal_code,setPostal] = useState('');
    const [err_sg_postal_code,setErrPostal] = useState('');
    const [address,setAddress] = useState('');
    const [err_address,setErrAddress] = useState('');
    const [unit_number,setUnitNumber] = useState('');
    const [err_unit_number,setErrUnitNumber] = useState('');

    useEffect(()=>{
        if(props.toggle){
            setName('');
            setContactNumber('');
            setPaymentType('');
            setUnitNumber('');
            setPostal('');
            setAddress('');
            setSerialNumber('');
            setUen('');
            setErrName('');
            setErrAddress('');
            setErrContactNumber('');
            setErrPaymentType('');
            setErrPostal('');
            setErrSerialNumber('');
            setErrUen('');
            setErrUnitNumber('');
            setErrSerialNumber('');

        }
    },[props.toggle])

    useEffect(()=>{

        if(props.toggle && sg_address != null){
            if(sg_address.length !== 0){
                const address = sg_address[0].ADDRESS;
                setAddress(address);
                setErrAddress('');
            }
            else{
                const word = IMLocalized('address_not_found');
                popError(word);
            }
            reset_branch_get_sg_postal();
        }

    },[sg_address,props.toggle,reset_branch_get_sg_postal])


    useEffect(()=>{
        if(props.toggle && errors !== null){
            if('name' in errors){
                const word = errors['name'].toString();
                setErrName(word);
            }
            if('contact_number' in errors){
                const word = errors['contact_number'].toString();
                setErrContactNumber(word);
            }
            if('UEN' in errors){
                const word = errors['UEN'].toString();
                setErrUen(word);
            }
            if('CPF_payment_type' in errors){
                const word = errors['CPF_payment_type'].toString();
                setErrPaymentType(word);
            }
            if('CPF_serial_number' in errors){
                const word = errors['CPF_serial_number'].toString();
                setErrSerialNumber(word);
            }
            if('unit_number' in errors){
                const word = errors['unit_number'].toString();
                setErrUnitNumber(word);
            }
            if('sg_postal_code' in errors){
                const word = errors['sg_postal_code'].toString();
                setErrPostal(word);
            }
            if('address' in errors){
                const word = errors['address'].toString();
                setErrAddress(word);
            }
            reset_create_company();
        }
    },[errors,props.toggle,reset_create_company])

    useEffect(()=>{
        if(props.toggle && success !== null){
            reset_create_company();
            onSuccess();
            
        }
    },[props.toggle,success,onSuccess,reset_create_company])

    const onSearch=()=>{
        var reg = /^[0-9]{6}$/;
        if(!reg.test(sg_postal_code)){
            setErrPostal(IMLocalized('invalid_sg_postal_code'))
        }
        else{
            props.branch_get_sg_postal(sg_postal_code)
        }
    }

    const onCreate =()=>{
        if(name !== '' && contact_number !== '' && uen !== '' && cpf_payment_type !== '' && cpf_serial_number !== '' && sg_postal_code !== '' && address !== '' ){
            var reg = /^[0-9]{6}$/;
            if(!reg.test(sg_postal_code)){
                setErrPostal(IMLocalized('invalid_sg_postal_code'));
            }
            else{
                props.create_company(name,contact_number,uen,cpf_payment_type,cpf_serial_number,unit_number,sg_postal_code,address);
            }
        }
        else{
            if(name ===''){
                setErrName(IMLocalized('name_is_required'));
            }
            if(contact_number ===''){
                setErrContactNumber(IMLocalized('contact_number_is_required'));
            }
            if(uen === ''){
                setUen(IMLocalized('uen_is_required'));
            }
            if(cpf_payment_type === ''){
                setPaymentType(IMLocalized('cpf_payment_type_is_required'));
            }
            if(cpf_serial_number === ''){
                setSerialNumber(IMLocalized('cpf_serial_number_is_required'));
            }
            if(unit_number === ''){
                setUnitNumber(IMLocalized('unit_number_is_required'));
            }
            if(sg_postal_code === ''){
                setPostal(IMLocalized('sg_postal_code_is_required'));
            }
            if(address === ''){
                setAddress(IMLocalized('address_is_required'));
            }
        }
    }

    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('create_company')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='name'>{IMLocalized('name')}</label>
                            <Input size="sm" name="name" id="name" type="text" value={name} onChange={(e)=>{setName(e.target.value); setErrName('')}} invalid={err_name.length >0} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='contact_number'>{IMLocalized('contact_number')}</label>
                            <Input size="sm" name="contact_number" id="contact_number" type="text" value={contact_number} onChange={(e)=>{setContactNumber(e.target.value);setErrContactNumber('')}} invalid={err_contact_number.length >0} />
                            <FormFeedback>{err_contact_number}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='uen'>UEN</label>
                            <Input size="sm" name="uen" id="uen" type="text" value={uen} onChange={(e)=>{setUen(e.target.value) ; setErrUen('')}} invalid ={err_uen.length > 0} />
                            <FormFeedback>{err_uen}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='cpf_payment_type'>CPF payment type</label>
                            <Input size="sm" name="cpf_payment_type" id="cpf_payment_type" type="select" value={cpf_payment_type} onChange={(e)=>{setPaymentType(e.target.value) ; setErrPaymentType('')}} invalid={err_payment_type.length > 0} >
                                <option></option>
                                {props.dropdown.map((item,index)=>{
                                    return(
                                        <option value={item.value} key={index}>{item.display}</option>
                                    )
                                })}
                            </Input>
                            <FormFeedback>{err_payment_type}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='cpf_serial_number'>CPF serial number</label>
                            <Input size="sm" min="0" name="cpf_serial_number" id="cpf_serial_number" type="number" value={cpf_serial_number} onChange={(e)=>{setSerialNumber(e.target.value); setErrSerialNumber('')}} invalid={err_serial_number.length >0} />
                            <FormFeedback>{err_serial_number}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='sg_postal_code'>{IMLocalized('sg_postal_code')}</label>
                            <div className="input-group">
                            <Input size="sm" name="sg_postal_code" id="sg_postal_code" type="number"  value={sg_postal_code} onChange={(e)=>{setPostal(e.target.value); setErrPostal('')}} invalid={err_sg_postal_code.length >0} />
                            <div className="input-group-append">
                                <button className="btn btn-primary btn-sm" onClick={onSearch}>{IMLocalized('autofill')}</button>
                            </div>
                                <FormFeedback>{err_sg_postal_code}</FormFeedback>
                            </div>                            
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='address'>{IMLocalized('address')}</label>
                            <Input size="sm" name="address" id="address" type="textarea" value={address} onChange={(e)=>{setAddress(e.target.value); setErrAddress('')}} invalid={err_address.length >0} />
                            <FormFeedback>{err_address}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='unit_number'>{IMLocalized('unit_number')}</label>
                            <Input size="sm" name="unit_number" id="unit_number" type="text" value={unit_number}  onChange={(e)=>{setUnitNumber(e.target.value); setErrUnitNumber('')}} invalid={err_unit_number.length >0} />
                            <FormFeedback>{err_unit_number}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {props.isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button onClick={onCreate} className="btn btn-primary btn-sm">{IMLocalized('create')}</button>
                    <button onClick={props.closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>
                }
            </ModalFooter>
        </Modal>
    )
}

function mapStateToProps(state){
    return{
        dropdown:state.company_dropdown.cpf,
        isLoading:state.create_company.isLoading,
        sg_address:state.branch_get_sg_postal.address,
        errors:state.create_company.errors,
        success:state.create_company.data,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        branch_get_sg_postal:(code)=>{
            dispatch(branch_get_sg_postal(code))
        },
        create_company:(name,contact_number,uen,cpf_payment_type,cpf_serial_number,unit_number,sg_postal_code,address)=>{
            dispatch(create_company(name,contact_number,uen,cpf_payment_type,cpf_serial_number,unit_number,sg_postal_code,address))
        },
        reset_branch_get_sg_postal:()=>{
            dispatch(reset_branch_get_sg_postal())
        },
        reset_create_company:()=>{
            dispatch(reset_create_company())
        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalCreateCompany);