import * as relationarrayvalidate from '../../action/wizard_employee/employee_relationship_array_validate';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const employee_relationship_array_validate = (state=initialState,action)=>{
    switch(action.type){
        case relationarrayvalidate.EMPRELATIONARRAYVALIDATE_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case relationarrayvalidate.EMPRELATIONARRAYVALIDATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case relationarrayvalidate.EMPRELATIONARRAYVALIDATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_relationship_array_validate;