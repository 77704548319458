import myaxios from '../../axios';

export const GETSELFPAYROLL_REQUEST = 'GETSELFPAYROLL_REQUEST';
export const GETSELFPAYROLL_SUCCESS = 'GETSELFPAYROLL_SUCCESS';
export const GETSELFPAYROLL_FAILURE = 'GETSELFPAYROLL_FAILURE';

export const get_self_payroll =()=>{
    return dispatch=>{
        dispatch({
            type:'GETSELFPAYROLL_REQUEST'
        });
        
        myaxios.get('payroll/employee/self/')
        .then((response)=>{
            dispatch({
                type:'GETSELFPAYROLL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETSELFPAYROLL_FAILURE',
                payload:error
            })
        })
    }
}