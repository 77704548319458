import myaxios from '../../axios';

export const EMPDETAILGETLEAVEPICTURE_REQUEST = 'EMPDETAILGETLEAVEPICTURE_REQUEST';
export const EMPDETAILGETLEAVEPICTURE_SUCCESS = 'EMPDETAILGETLEAVEPICTURE_SUCCESS';
export const EMPDETAILGETLEAVEPICTURE_FAILURE = 'EMPDETAILGETLEAVEPICTURE_FAILURE';
export const EMPDETAILGETLEAVEPICTURE_RESET = 'EMPDETAILGETLEAVEPICTURE_RESET';


export const emp_detail_get_leave_picture =(id)=>{
    return dispatch=>{
        dispatch({
            type:'EMPDETAILGETLEAVEPICTURE_REQUEST'
        });

        myaxios.get(`ob/leave/${id}/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'EMPDETAILGETLEAVEPICTURE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPDETAILGETLEAVEPICTURE_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_emp_detail_get_leave_picture = () => dispatch =>{
    dispatch({
        type:'EMPDETAILGETLEAVEPICTURE_RESET'
    });
}