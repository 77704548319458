import * as contractdepartment from '../../action/employee/get_employee_department';

const initialstate={
    isLoading:false,
    errors:[],
    data:[]
}

const get_employee_department = (state=initialstate,action)=>{
    switch(action.type){
        case contractdepartment.EMPCONTRACTDEPARTMENT_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case contractdepartment.EMPCONTRACTDEPARTMENT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractdepartment.EMPCONTRACTDEPARTMENT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_department;