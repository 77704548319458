import myaxios from '../../axios';

export const MASSASSIGNLEAVEGROUPVALIDATE_REQUEST = 'MASSASSIGNLEAVEGROUPVALIDATE_REQUEST';
export const MASSASSIGNLEAVEGROUPVALIDATE_SUCCESS = 'MASSASSIGNLEAVEGROUPVALIDATE_SUCCESS';
export const MASSASSIGNLEAVEGROUPVALIDATE_FAILURE = 'MASSASSIGNLEAVEGROUPVALIDATE_FAILURE';


export const mass_assign_leave_group_validate =(leave_scheme,year,entitlements,groups)=>{
    return dispatch=>{
        dispatch({
            type:'MASSASSIGNLEAVEGROUPVALIDATE_REQUEST'
        });

        myaxios.post('leave/mass/group/validate/',{
            leave_scheme:leave_scheme,
            year:year,
            entitlements:entitlements,
            groups:groups
        })
        .then((response)=>{
            dispatch({
                type:'MASSASSIGNLEAVEGROUPVALIDATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'MASSASSIGNLEAVEGROUPVALIDATE_FAILURE',
                payload:error
            })
        })
    }
}
