import * as massassigngroupcreate from '../../action/leave/mass_assign_leave_group_create';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const mass_assign_leave_group_create = (state=initialState,action)=>{
    switch(action.type){
        case massassigngroupcreate.MASSASSIGNLEAVEGROUPCREATE_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case massassigngroupcreate.MASSASSIGNLEAVEGROUPCREATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case massassigngroupcreate.MASSASSIGNLEAVEGROUPCREATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default mass_assign_leave_group_create;