import * as submit1 from '../../action/appraisal/manager_submit_appraisal';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const manager_submit_appraisal = (state=initialState,action)=>{
    switch(action.type){
        case submit1.MANAGERSUBMITAPPRAISAL_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case submit1.MANAGERSUBMITAPPRAISAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case submit1.MANAGERSUBMITAPPRAISAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default manager_submit_appraisal;