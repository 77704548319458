import myaxios from '../axios';
export const CREATECONTRACTSECTION_SUCCESS = 'CREATECONTRACTSECTION_SUCCESS';
export const CREATECONTRACTSECTION_REQUEST = 'CREATECONTRACTSECTION_REQUEST';
export const CREATECONTRACTSECTION_FAILURE = 'CREATECONTRACTSECTION_FAILURE';
export const CREATECONTRACTSECTION_RESET = 'CREATECONTRACTSECTION_RESET';

export const create_contract_section = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATECONTRACTSECTION_REQUEST'
        });
        
        myaxios.post(`company/section/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'CREATECONTRACTSECTION_SUCCESS',
                payload:response
            }
        )})
        .catch((error) =>{
            dispatch({
                type: 'CREATECONTRACTSECTION_FAILURE',
                payload: error,
            })
        })
    }
}

export const reset_create_contract_section = () => dispatch =>{
    dispatch({
        type:'CREATECONTRACTSECTION_RESET'
    });
}