import * as deldep from '../action/delete_department';

const initialState ={
    isLoading:false,
    errors:null,
    data:null,
}


const delete_department = (state=initialState,action) =>{
    switch(action.type) {
        case deldep.DELDEPARTMENT_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case deldep.DELDEPARTMENT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case deldep.DELDEPARTMENT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case deldep.DELDEPARTMENT_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default delete_department;


