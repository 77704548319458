import * as empaddress from '../../action/employee/get_employee_address';

const initialState ={
    data:[],
    errors:null,
    isLoading:false,
}


const get_employee_address = (state=initialState,action) =>{
    switch(action.type) {
        case empaddress.REQUEST_EMPADDRESS:
            return{
                ...state,
                isLoading: true,
                errors:null,
                data:[]
            }
        case empaddress.GET_EMPADDRESS:
            return{
                ...state,
                data:action.payload?.data,
                isLoading:false
            }
        case empaddress.FAILURE_EMPADDRESS:
            return{
                ...state,
                errors:action.payload,
                isLoading:false
            }
        default:
            return state;
    }
}
export default get_employee_address;


export const emp_get_employee_address = (state,id) =>{
    const { data } = state.get_employee_address;

    return data.find(item=>item.employee === id);
}