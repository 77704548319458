import myaxios from "../../axios";

export const SETCOMBANKDEFAULT_REQUEST = 'SETCOMBANKDEFAULT_REQUEST';
export const SETCOMBANKDEFAULT_SUCCESS = 'SETCOMBANKDEFAULT_SUCCESS';
export const SETCOMBANKDEFAULT_FAILURE = 'SETCOMBANKDEFAULT_FAILURE';
export const SETCOMBANKDEFAULT_RESET = 'SETCOMBANKDEFAULT_RESET';

export const set_company_bank_default = (id)=>{
    return dispatch=>{
        dispatch({
            type:'SETCOMBANKDEFAULT_REQUEST'
        })

        myaxios.get(`company/bank/${id}/default/`)
        .then((res)=>res.data)
        .then((json)=>{
            dispatch({
                type:'SETCOMBANKDEFAULT_SUCCESS',
                payload:json
            })
        })
        .catch((errors)=>{
            dispatch({
                type:'SETCOMBANKDEFAULT_FAILURE',
                payload:errors
            })
        })
    }
}


export const reset_company_bank_default = () => dispatch=>{
    dispatch({
        type:'SETCOMBANKDEFAULT_RESET'
    });
}