import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader ,Row , Col , Table , Card, Input, FormGroup, FormFeedback, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { popError } from '../../../../func/popError';
import Swal from 'sweetalert2';
import CreatableSelect from 'react-select/creatable';
import { IMLocalized } from '../../../../language/IMLocalized';
import Select from 'react-select';
import { employee_contract_additional_cost } from '../../../../action/employee/employee_contract_additional_cost';
import { employee_contract_department } from '../../../../action/employee/employee_contract_department';
import { employee_contract_section } from '../../../../action/employee/employee_contract_section';
import { employee_contract_costcenter } from '../../../../action/employee/employee_contract_costcenter';
import { employee_contract_joblevel } from '../../../../action/employee/employee_contract_joblevel';
import { employee_contract_jobtitle } from '../../../../action/employee/employee_contract_jobtitle';
import { create_employee_contract } from '../../../../action/employee/create_employee_contract';
import { get_additional_setting } from '../../../../action/additional_setting';
import { usePrevious } from '../../../../hook/usePrevious';

const mapStateToProps = (state)=>({
    templatelist:state.get_contract_template_list.data,
    contract_typelist:state.get_contract_template_dropdown.contract_type,
    contract_ratelist:state.get_contract_template_dropdown.contract_rate,
    schedulelist:state.schedule_list.data,
    currencylist:state.general_currency.data,
    departmentlist:state.add.department,
    sectionlist:state.add.section,
    jobtitlelist:state.add.jobtitle,
    joblevellist:state.add.joblevel,
    costcenterlist:state.add.costcenter,
    allowancelist:state.add.allowance,
    recurring_list:state.add.recurringallowance,
    additionalcost:state.employee_contract_additional_cost.data,
    department_success:state.employee_contract_department.data,
    section_success:state.employee_contract_section.data,
    joblevel_success:state.employee_contract_joblevel.data,
    jobtitle_success:state.employee_contract_jobtitle.data,
    costcenter_success:state.employee_contract_costcenter.data,
    create_errors:state.create_employee_contract.errors,
    create_success:state.create_employee_contract.data,
    isLoading:state.create_employee_contract.isLoading,
})

const mapDispatchToProps = (dispatch)=>({
    employee_contract_additional_cost:(id,pay)=>{
        dispatch(employee_contract_additional_cost(id,pay))
    },
    employee_contract_section:(value)=>{
        dispatch(employee_contract_section(value))
    },
    employee_contract_department:(value)=>{
        dispatch(employee_contract_department(value))
    },
    employee_contract_joblevel:(value)=>{
        dispatch(employee_contract_joblevel(value))
    },
    employee_contract_jobtitle:(value)=>{
        dispatch(employee_contract_jobtitle(value))
    },
    employee_contract_costcenter:(value)=>{
        dispatch(employee_contract_costcenter(value))
    },
    create_employee_contract:(data)=>{
        dispatch(create_employee_contract(data))
    },
    get_additional_setting:()=>{
        dispatch(get_additional_setting())
    }
})

function ModalCreateContract(props){
    const {onSuccess,create_success,additionalcost,employee_contract_additional_cost,department_success,section_success,costcenter_success,joblevel_success,jobtitle_success,employee_contract_costcenter,employee_contract_department,employee_contract_joblevel,employee_contract_jobtitle,employee_contract_section,create_employee_contract,create_errors,get_additional_setting} = props;
    const [contract_rate,setRate] = useState('');
    const [contract_type,setType] = useState('');
    const [hourly_rate,setHourlyRate] = useState(0);
    const [daily_rate,setDailyRate] = useState(0);
    const [pay,setPay] = useState('');
    const [is_workman,setWorkman] = useState(false);
    const [schedule,setSchedule] = useState(null);
    const [currency,setCurrency] = useState(null);
    const [currency_toggle,setCurrencyToggle] = useState(false);
    const [job_level,setJobLevel] = useState(null);
    const [job_title,setJobTitle] = useState(null);
    const [cost_center,setCostCenter] = useState(null);
    const [department,setDepartment] = useState(null);
    const [section,setSection] = useState(null);
    const [allowance,setAllowance] = useState([]);
    const [recurring_allowance,setRecurring] = useState([]);
    const [select_template,setSelectTemplate] = useState('');
    const [start_date,setStartDate] = useState('');
    const [err_start_date,setErrStartDate] = useState('');
    const [err_contract_type,setErrType] = useState('');
    const [notes,setNotes] = useState('');
    const [err_notes,setErrNotes] =  useState('');
    const [err_contract_rate,setErrRate] = useState('');
    const [err_pay,setErrPay] = useState('');
    const [err_hourly_rate,setErrHourlyRate] = useState('');
    const [salary_msg,setSalaryMsg] = useState('');
    const [warning,setWarning] = useState('');
    const [err_schedule,setErrSchedule] = useState('');
    const [err_daily_rate,setErrDailyRate] = useState('');
    const [err_hours_day,setErrHoursDay] = useState('');
    const [hours_day,setHoursDay] = useState('');
    const [err_currency,setErrCurrency] = useState('');
    const [err_department,setErrDepartment] = useState('');
    const [err_section,setErrSection] = useState('');
    const [err_cost_center,setErrCostCenter] = useState('');
    const [err_job_level,setErrJobLevel] = useState('');
    const [err_job_title,setErrJobTitle] = useState('');
    const [total_pay,setTotal] = useState('');
    const [additional,setAdditional] = useState(null);
    const [upper,setUpper] = useState('');
    const [lower,setLower] = useState('');
    const prevAdditionalCost = usePrevious(additionalcost);
    const prevDepartment = usePrevious(department_success);
    const prevSection = usePrevious(section_success);
    const prevJobTitle = usePrevious(jobtitle_success);
    const prevJobLevel = usePrevious(joblevel_success);
    const prevCostCenter = usePrevious(costcenter_success);
    const prevErrors = usePrevious(create_errors);
    const prevSuccess = usePrevious(create_success);

    useEffect(()=>{
        if(props.toggle){
            if(props.list && props.list.length !== 0){
                Swal.fire({
                    type:'question',
                    title:IMLocalized('copy_current_contract'),
                    confirmButtonText:IMLocalized('yes'),
                    cancelButtonText:IMLocalized('no'),
                    showCancelButton:true,
                    allowEnterKey:true,
                    allowOutsideClick:false
                })
                .then((result)=>{
                    if(result.value){
                        const active = props.list.active;
                        setRate(active.contract_rate);
                        setType(active.contract_type);
                        setHourlyRate(active.hourly_rate);
                        setDailyRate(active.daily_rate);
                        setPay(active.pay);
                        setWorkman(active.is_workman);
                        setSchedule(active.wschedule);
                        if(active.currency){
                            setCurrency(active.currency);
                            setCurrencyToggle(true);
                        }
                        else{
                            setCurrency(null);
                            setCurrencyToggle(false);
                        }
                        if(active.job_title !== null){
                            setJobTitle({value:active.job_title,label:active.job_level});
                        }
                        else{
                            setJobTitle(null);
                        }

                        if(active.job_level !== null){
                            setJobLevel({value:active.job_level,label:active.job_level});
                        }
                        else{
                            setJobLevel(null);
                        }

                        if(active.department !== null){
                            setDepartment({value:active.department,label:active.department});
                        }
                        else{
                            setDepartment(null);
                        }

                        if(active.section !== null){
                            setSection({value:active.section,label:active.section});
                        }
                        else{
                            setSection(null);
                        }

                        if(active.cost_center !== null){
                            setCostCenter({value:active.cost_center,label:active.cost_center});
                        }
                        else{
                            setCostCenter(null);
                        }

                        if(active.allowances.length !== 0){
                            const arr = active.allowances.map((item)=>{
                                return {label:item,value:item}
                            })
                            setAllowance(arr);
                        }
                        else{
                           setAllowance([]);
                        }

                        if(active.recurring_allowances.length !== 0){
                            const arr = active.recurring_allowances.map((item)=>{
                                return {label:item,value:item}
                            })
                            setRecurring(arr);
                        }
                        else{
                           setRecurring([]);
                        }
                    }
                })
            }
            else{
                setStartDate(props.join_date)
            }
        }
    },[props.toggle,props.list,props.join_date])

    useEffect(()=>{
        if(props.toggle && props.additionalcost && prevAdditionalCost !== undefined){
            setAdditional(props.additionalcost);
        }
        else{
            setAdditional(null);
        }
    },[props.additionalcost,props.toggle,prevAdditionalCost])

    const onCalculate= useCallback(()=>{
        if(pay !== ''){
            let recurring_amount = 0;
            if(recurring_allowance.length !== 0){
                recurring_allowance.forEach(function(item){
                    const value = item.value;
                    const detail = props.recurring_list.find((item1)=>item1.name === value);
                    if(detail){
                        recurring_amount += parseFloat(detail.amount);
                    } 
                })
            }
            const total = recurring_amount + parseFloat(pay)
            setTotal(total);
            employee_contract_additional_cost(props.id,total);
        }
        else{
            setTotal(0);
        }
    },[recurring_allowance,pay,employee_contract_additional_cost,props.id,props.recurring_list])

    useEffect(()=>{
        if(props.toggle && props.additionalcost && prevAdditionalCost !== undefined){
            setAdditional(props.additionalcost);
        }
        else{
            setAdditional(null);
        }
    },[props.additionalcost,props.toggle,prevAdditionalCost])

    useEffect(()=>{
        onCalculate();
    },[pay,recurring_allowance,onCalculate])

    useEffect(()=>{
        if(upper !== '' && lower !== '' && upper !== null && lower !== null){
            setSalaryMsg(`(SGD ${lower} - SGD ${upper} )`);
           if(pay !== ''){
                if(parseFloat(pay) >  parseFloat(upper)){
                    setWarning(IMLocalized('pay_higher_than_expected'));
                }
                else if (parseFloat(pay) < parseFloat(lower)){
                    setWarning(IMLocalized('pay_lower_than_expected'));
                }
                else{
                    setWarning();
                }
           }
        }
    },[upper,lower,pay])

    const createContract=useCallback(()=>{
        let department1 = null;
        if(department !== null){
            department1 = department.value;
        }

        let section1 = null;
        if(section !== null){
            section1 = section.value;
        }

        let joblevel1 = null;
        if(job_level !== null){
            joblevel1 = job_level.value;
        }

        let jobtitle1 = null;
        if(job_title !== null){
            jobtitle1 = job_title.value;
        }

        let costcenter1 = null;
        if(cost_center !== null){
            costcenter1 = cost_center.value;
        }

        const recurring_allowances = recurring_allowance.map((item)=>{
            return item.value
        })

        const allowances = allowance.map((item)=>{
            return item.value
        })

        const data = {daily_rate,currency,start_date,pay,hourly_rate,schedule,job_level:joblevel1,job_title:jobtitle1,department:department1,section:section1,cost_center:costcenter1,allowances,contract_rate,contract_type,notes,employee:props.id,recurring_allowances,hours_day};
        create_employee_contract(data);

    },[create_employee_contract,daily_rate,currency,start_date,pay,hourly_rate,schedule,job_level,job_title,department,section,cost_center,allowance,contract_rate,contract_type,notes,props.id,recurring_allowance])

    const checkCostCenter =useCallback(()=>{
        if(cost_center !== null){
            const found = props.costcenterlist.some((item1)=>item1.name === cost_center.value);
            if(!found){
                employee_contract_costcenter(cost_center.value);
            }
            else{
                createContract();
            }
        }
        else{
            createContract();
        }
    },[employee_contract_costcenter,createContract,props.costcenterlist,cost_center])

    const checkJobTitle = useCallback(()=>{
        if(job_title !== null){
            const found = props.jobtitlelist.some((item1)=>item1.name === job_title.value);
            if(!found){
                employee_contract_jobtitle(job_title.value);
            }
            else{
                checkCostCenter();
            }
        }
        else{
            checkCostCenter();
        }
    },[employee_contract_jobtitle,checkCostCenter,props.jobtitlelist,job_title])

    const checkJobLevel =useCallback(()=>{
        if(job_level !== null){
            const found = props.joblevellist.some((item1)=>item1.name === job_level.value);
            if(!found){
                employee_contract_joblevel(job_level.value);
            }
            else{
                checkJobTitle();
            }
        }
        else{
            checkJobTitle();
        }
    },[employee_contract_joblevel,checkJobTitle,props.joblevellist,job_level])

    const checkSection=useCallback(()=>{
        if(section !== null){
            const found = props.sectionlist.some((item1)=>item1.name === section.value);
            if(!found){
                employee_contract_section(section.value);
            }
            else{
                checkJobLevel();
            }
        }
        else{
            checkJobLevel();
        }
    },[employee_contract_section,checkJobLevel,props.sectionlist,section])

    const checkDepartment=useCallback(()=>{
        if(department !== null){
            const found = props.departmentlist.some((item1)=>item1.name === department.value);
            if(!found){
                employee_contract_department(department.value);
            }
            else{
                checkSection();
            }
        }
        else{
            checkSection();
        }
    },[employee_contract_department,checkSection,props.departmentlist,department])

    useEffect(()=>{
        if(prevDepartment !== undefined && department_success !== prevDepartment && props.toggle){
            checkSection();
        }
    },[prevDepartment,department_success,props.toggle,checkSection])

    useEffect(()=>{
        if(prevSection !== undefined && section_success !== prevSection && props.toggle){
            checkJobLevel();
        }
    },[prevSection,section_success,props.toggle,checkJobLevel])

    useEffect(()=>{
        if(prevJobLevel !== undefined && joblevel_success !== prevJobLevel && props.toggle){
            checkJobTitle();
        }
    },[prevJobLevel,joblevel_success,props.toggle,checkJobTitle])

    useEffect(()=>{
        if(prevJobTitle !== undefined && jobtitle_success !== prevJobTitle && props.toggle){
            checkCostCenter();
        }
    },[prevJobTitle,jobtitle_success,props.toggle,checkCostCenter])

    useEffect(()=>{
        if(prevCostCenter !== undefined && costcenter_success !== prevCostCenter && props.toggle){
            createContract();
        }
    },[prevCostCenter,costcenter_success,props.toggle,createContract])

    useEffect(()=>{
        if(prevErrors && create_errors !== prevErrors && create_errors !== undefined && create_errors.length !==0){
            const errors = create_errors.response.data;
            if('non_field_errors' in errors){
                const word = errors['non_field_errors'].toString();
                popError(word)
            }
            if('daily_rate' in errors){
                const word = errors['daily_rate'].toString();
                this.setState({err_daily_rate:word});
            }
            if('hourly_rate' in errors){
                const word = errors['hourly_rate'].toString();
                this.setState({err_hourly_rate:word});
            }
            get_additional_setting();
        }
        
    },[create_errors,prevErrors,get_additional_setting])

    useEffect(()=>{
        if(prevSuccess && create_success !== prevSuccess && create_success !== undefined && create_success.length !==0){
            onSuccess();
        }
    },[create_success,prevSuccess,onSuccess])

    const onSelectTemplate=()=>{
        let name ='';
        let word = '';

        if(select_template !== ''){
            const data = props.templatelist[select_template];
            name = data.name;
            word = `${IMLocalized('do_you_want_to_select_this_template')} ?\n`+name;
        }
        else{
            name = 'clear content';
            word = IMLocalized('do_you_want_to_clear_all_content');
        }

        Swal.fire({
            title:word,
            text:IMLocalized('all_information_set_to_default'),
            type:'warning',
            showCancelButton:true,
            cancelButtonColor:'#d33',
            confirmButtonColor:'#3085d6',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
        })
        .then((result)=>{
            if(result.value){
                if(select_template !== ''){
                    const data = props.templatelist[select_template];
                    const type = data.contract_type;
                    const rate = data.contract_rate;
                    setType(type);
                    setRate(rate);
                    const level = data.job_level;
                    if(level){
                        setJobLevel({value:level,label:level});
                    }
                    else{
                        setJobLevel(null);
                    }

                    const depart = data.department;
                    if(depart){
                        setDepartment({value:depart,label:depart});
                    }
                    else{
                        setDepartment(null);
                    }

                    const sect = data.section;
                    if(sect){
                        setSection({value:depart,label:depart});
                    }
                    else{
                        setSection(null);
                    }

                    const title = data.job_title;
                    if(title){
                        setJobTitle({value:title,label:title});
                    }
                    else{
                        setJobTitle(null);
                    }

                    const cost = data.cost_center;
                    if(cost){
                        setCostCenter({value:cost,label:cost});
                    }
                    else{
                        setCostCenter(null);
                    }

                    setWorkman(data.is_workman);
                    setSchedule(data.wschedule);
                    setUpper(data.upper_pay_range);
                    setLower(data.lower_pay_range);
                    setPay('');
                    const allowance_data = data.allowances.map((item)=>{
                        return {value:item,label:item}
                    })
                    setAllowance(allowance_data)

                    const recurring_data = data.recurring_allowances.map((item)=>{
                        return {value:item,label:item}
                    })
                    setRecurring(recurring_data)

                    if(rate === 'DAILY'){
                        setHoursDay(data.hours_day);
                    }
                    else{
                        setHoursDay(0);
                    }
                }
                else{
                    setHoursDay(0);
                    setRate('');
                    setType('');
                    setSection(null);
                    setDepartment(null);
                    setJobLevel(null);
                    setJobTitle(null);
                    setCostCenter(null);
                    setWorkman(false);
                    setSchedule('');
                    setUpper('');
                    setLower('');
                    setWarning('');
                    setSalaryMsg('');
                    setRecurring([]);
                    setAllowance([]);
                    setPay('');
                }
                setErrStartDate('');
                setErrPay('');
                setErrHourlyRate('');
                setErrHoursDay('');
                setErrRate('');
                setErrType('');
                setErrNotes('');
                setErrSchedule('');
                setErrJobLevel('');
                setErrJobTitle('');
                setErrCostCenter('');
                setErrDepartment('');
                setErrSection('');
                setErrNotes('');
                onCalculate();
            }
        })
    }

    const handlePay=(e)=>{
        const target = e.target;
        const value = target.value;
        let hourly_rate1  = (value*12)/(52*44);
        if(is_workman){
            if(value >4500){
                hourly_rate1 = (4500*12)/(52*44);  
            }
        }
        else{
            if(value >=2600){
                hourly_rate1 = 13.60;
            }
        }
        setHourlyRate(hourly_rate1.toFixed(8));
        setPay(value);
        setErrPay('');
        setErrHourlyRate('');
    }

    //Daily Rate
    const handlePay1=(e)=>{
        const target = e.target;
        const value = target.value;
        let hourly_rate1  = value/hours_day;
        // if(is_workman){
        //     if(value >4500){
        //         hourly_rate1 = (4500*12)/(52*44);  
        //     }
        // }
        // else{
        //     if(value >=2600){
        //         hourly_rate1 = 13.60;
        //     }
        // }
        setHourlyRate(hourly_rate1.toFixed(8));
        setPay(0);
        setErrPay('');
        setErrHourlyRate('');
    }

    //Hours per day
    const handlePay2=(e)=>{
        const target = e.target;
        const value = target.value;
        let hourly_rate1  = daily_rate/value;
        // if(is_workman){
        //     if(value >4500){
        //         hourly_rate1 = (4500*12)/(52*44);  
        //     }
        // }
        // else{
        //     if(value >=2600){
        //         hourly_rate1 = 13.60;
        //     }
        // }
        setHoursDay(value);
        setHourlyRate(hourly_rate1.toFixed(8));
        setPay(0);
        setErrPay('');
        setErrHourlyRate('');
    }

    const handleCurrency = (e)=>{
        const target = e.target;
        const checked = target.checked;
        if(!checked){
            setCurrency(null);
            setErrCurrency('');
        }
        setCurrencyToggle(checked);
    }

    const handleBlur=()=>{
        calculateDaily();
        onCalculate();
    }

    const handleCheckbox=(e)=>{
        const target = e.target;
        const checked = target.checked;
        if(pay !== ''){
            let rate = (parseFloat(pay)*12)/(52*44);
            if(checked === true){
                if(pay > 4500){
                    rate = (4500*12)/(52*44);
                }
            }
            else{
                if(pay >=2600){
                    rate = 13.60;
                }
            }
            setHourlyRate(rate.toFixed(8));
            setErrHourlyRate('');
        }
        setWorkman(checked);
    }

    const calculateDaily=()=>{
        if(pay !== '' && schedule !== ''){
            let work_days_in_week = 0;
            const detail = props.schedulelist.find((item1)=>item1.id === parseInt(schedule));
            if(detail){
                work_days_in_week = detail.work_days_in_week;
            }
            if(pay !== 0 && work_days_in_week !== 0){
                const total = (pay*12)/(52*work_days_in_week);
                const num = parseFloat(total.toFixed(4));
                setDailyRate(num);
                setErrDailyRate('');
            }
        }
        else{
            setDailyRate(0.0000);
        }
    }

    const handleRate = (rate)=>{
        if(rate !== ''){
            if(rate === 'MONTHLY'){
                setDailyRate('');
                setHoursDay(0);
                setPay('');
                setHourlyRate('');
                setErrDailyRate('');
                setErrHoursDay('');
                setErrPay('');
                setErrHourlyRate('');
            }
            else if (rate === 'HOURLY'){
                setDailyRate(0);
                setHoursDay(0);
                setPay(0);
                setAllowance([]);
                setRecurring([]);
                setHourlyRate('');
                setErrDailyRate('');
                setErrHoursDay('');
                setErrPay('');
                setErrHourlyRate('');
            }
            else if ( rate === 'DAILY'){
                setHourlyRate('');
                // setHourlyRate(0);
                setDailyRate('');
                setHoursDay('');
                setPay(0);
                setAllowance([]);
                setRecurring([]);
                setErrHourlyRate('');
                setErrDailyRate('');
                setErrHoursDay('');
                setErrPay('');
            }
        }
    }

    const onCreate= ()=>{
        if(contract_rate !== ''){
            if(contract_rate === 'MONTHLY'){
                if(pay !== '' && start_date !== '' && hourly_rate !== '' && schedule !== '' && contract_type !== '' && department !== null ){
                   Promise.resolve(()=>setHoursDay(0))
                   .then(()=>checkDepartment())
                }
                else{
                    if(pay === ''){
                        setErrPay(IMLocalized('pay_is_required'))
                    }
                    if(start_date === ''){
                        setErrStartDate(IMLocalized('start_date_is_required'));
                    }
                    if(schedule === ''){
                        setErrSchedule(IMLocalized('scheudule_is_required'));
                    }
                    if(contract_type === ''){
                        setErrRate(IMLocalized('contract_type_is_required'));
                    }
                    if(hourly_rate === ''){
                        setErrHourlyRate(IMLocalized('hourly_rate_is_required'));
                    }
                    if(daily_rate === ''){
                        setErrDailyRate(IMLocalized('daily_rate_is_required'));
                    }
                    if(department === null){
                        setErrDepartment(IMLocalized('department_is_required'));
                    }
                }
            }
            else if (contract_rate === 'HOURLY'){
                if(start_date !== '' && contract_type !== '' && schedule !== '' && hourly_rate !=='' && department !== null ){
                    Promise.resolve(()=>{
                        setPay(0);
                        setDailyRate(0);
                        setHoursDay(0);
                    })
                    .then(()=>checkDepartment())
                }
                else{
                    if(start_date === ''){
                        setErrStartDate(IMLocalized('start_date_is_required'));
                    }
                    if(schedule === ''){
                        setErrSchedule(IMLocalized('scheudule_is_required'));
                    }
                    if(contract_type === ''){
                        setErrRate(IMLocalized('contract_type_is_required'));
                    }
                    if(hourly_rate === ''){
                        setErrHourlyRate(IMLocalized('hourly_rate_is_required'));
                    }
                    if(department === null){
                        setErrDepartment(IMLocalized('department_is_required'));
                    }
                }
            }
            else if(contract_rate === 'DAILY'){
                if(hours_day !== '' && start_date !== '' && contract_type !== '' && schedule !== '' && daily_rate !== '' && department !== null){
                    Promise.resolve(()=>{
                        setPay(0);
                        // setHourlyRate(0);
                    })
                    .then(()=>checkDepartment())
                }
                else{
                    if(hours_day === ''){
                        setErrHoursDay(IMLocalized('hours_per_day_required'));
                    }
                    if(start_date === ''){
                        setErrStartDate(IMLocalized('start_date_is_required'));
                    }
                    if(schedule === ''){
                        setErrSchedule(IMLocalized('scheudule_is_required'));
                    }
                    if(contract_type === ''){
                        setErrRate(IMLocalized('contract_type_is_required'));
                    }
                    if(daily_rate === ''){
                        setErrDailyRate(IMLocalized('daily_rate_is_required'));
                    }
                    if(department === null){
                        setErrDepartment(IMLocalized('department_is_required'));
                    }
                }
            }
        }
        else{
            setErrRate(IMLocalized('contract_rate_is_required'));
        }
    }

    

    return(
        <Modal isOpen={props.toggle} className="modal-dialog-centered" style={{maxWidth: '1600px', width: '90%'}}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('add_new_contract')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="9">
                        <Card>
                            <CardBody style={{backgroundColor:'#e9eff6'}}>
                                <div className='d-flex justify-content-end'>
                                    <div className="input-group" style={{maxWidth:'300px'}}>
                                        <Input name="selected_template" className="form-control-sm"  type="select" value={select_template} onChange={(e)=>setSelectTemplate(e.target.value)}>
                                            <option value=''>{IMLocalized('select_template')}</option>
                                            {props.templatelist.map((item1,index)=>{
                                                return (
                                                    <option value={index} key={index}>{item1.name}</option>
                                                )
                                            })}
                                        </Input>
                                        <span className="input-group-btn">
                                            <button onClick={onSelectTemplate} className="btn btn-warning btn-sm">{IMLocalized('confirm')}</button>
                                        </span>
                                    </div>
                                </div>
                                <Row className="mt-4">
                                    {!props.is_new && 
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='notes'>{IMLocalized('note')}</label>
                                            <Input size="sm" type="textarea" name="notes" id="notes" value={notes} onChange={(e)=>{setNotes(e.target.value); setErrNotes('')}} invalid={err_notes.length > 0 } />
                                            <FormFeedback>{err_notes}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    }
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='start_date'>{IMLocalized('start_date')}</label>
                                            <Input size="sm" type="date" name="start_date" id="start_date" value={start_date} onChange={(e)=>{setStartDate(e.target.value); setErrStartDate('')}}  invalid={err_start_date.length >0} readOnly={props.is_new} />
                                            <FormFeedback>{err_start_date}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='contract_type'>{IMLocalized('contract_type')}</label>
                                            <Input size="sm" type="select" name="contract_type" id="contract_type" value={contract_type} onChange={(e)=>{setType(e.target.value); setErrType('')}}  invalid={err_contract_type.length >0}>
                                                <option></option>
                                                {props.contract_typelist.map((item,index)=>{
                                                    return <option value={item.value} key={index}>{item.display}</option>
                                                })}
                                            </Input>
                                            <FormFeedback>{err_contract_type}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='contract_rate'>{IMLocalized('contract_rate')}</label>
                                            <Input size="sm" type="select" name="contract_rate" id="contract_rate" value={contract_rate} onChange={(e)=>{setRate(e.target.value); setErrRate(''); handleRate(e.target.value)}}  invalid={err_contract_rate.length >0}>
                                                <option></option>
                                                {props.contract_ratelist.map((item,index)=>{
                                                    return <option value={item.value} key={index}>{item.display}</option>
                                                })}
                                            </Input>
                                            <FormFeedback>{err_contract_rate}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    {contract_rate === 'MONTHLY' &&
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='basic_pay'>{IMLocalized('basic_pay')}</label>
                                            <Input size="sm" type="number" value={pay} onChange={(e)=>handlePay(e)} name="basic_pay" id="basic_pay" invalid={err_pay.length >0} onBlur={handleBlur} />
                                            <FormFeedback>{err_pay}</FormFeedback>
                                            <div>
                                                <small className='text-red'>{salary_msg}</small>
                                            </div>
                                            <div>
                                                <small className='text-red'>{warning}</small>
                                            </div>
                                        </FormGroup>
                                    </Col>}
                                    <Col lg="6" className="d-flex align-items-center">
                                        <div className='custom-control custom-checkbox checkboxes'>
                                            <input type="checkbox" id='createcontract_isworkman' name='is_workman' className="custom-control-input" checked={is_workman} onChange={(e)=>handleCheckbox(e)} />
                                            <label className="custom-control-label" htmlFor="createcontract_isworkman">{IMLocalized('is_workman')}</label>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='schedule'>{IMLocalized('schedule')}</label>
                                            <Input type="select" size="sm"  name="schedule" id="schedule" value={schedule} onChange={(e)=>{setSchedule(e.target.value); setErrSchedule('')}} invalid={err_schedule.length>0} onBlur={calculateDaily} >
                                                <option></option>
                                                {props.schedulelist.map((item,index)=>{
                                                    return <option value={item.id} key={index}>{item.name}</option>
                                                })}
                                            </Input>
                                            <FormFeedback>{err_schedule}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    {contract_rate === 'MONTHLY' &&
                                    <>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='hourly_rate'>{IMLocalized('hourly_rate')}</label>
                                            <Input size="sm" name="hourly_rate" id="hourly_rate" value={hourly_rate} onChange={(e)=>{setHourlyRate(e.target.value); setErrHourlyRate('')}} invalid={err_hourly_rate.length >0} />
                                            <FormFeedback>{err_hourly_rate}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='daily_rate'>{IMLocalized('daily_rate')}</label>
                                            <Input size="sm" name="daily_rate" id="daily_rate" value={daily_rate} onChange={(e)=>{setDailyRate(e.target.value); setErrDailyRate('')}} invalid={err_daily_rate.length >0} />
                                            <FormFeedback>{err_daily_rate}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    </>}
                                    {contract_rate === 'DAILY' &&
                                    <>
                                    {/* TEST */}
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='hours_per_day'>{IMLocalized('hours_per_day')}</label>
                                            <Input size="sm" type="number" name="hours_day" id="hours_per_day" value={hours_day} onChange={(e)=>{setHoursDay(e.target.value); setErrHoursDay('');handlePay2(e)}} invalid={err_hours_day.length>0} />
                                            <FormFeedback>{err_hours_day}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='daily_rate'>{IMLocalized('daily_rate')}</label>
                                            <Input size="sm" name="daily_rate" id="daily_rate" value={daily_rate} onChange={(e)=>{setDailyRate(e.target.value); setErrDailyRate('');handlePay1(e)}} invalid={err_daily_rate.length >0} />
                                            <FormFeedback>{err_daily_rate}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='hourly_rate'>{IMLocalized('hourly_rate')}</label>
                                            <Input size="sm" name="hourly_rate" id="hourly_rate" value={hourly_rate} onChange={(e)=>{setHourlyRate(e.target.value); setErrHourlyRate('')}} invalid={err_hourly_rate.length >0} />
                                            <FormFeedback>{err_hourly_rate}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    </>}
                                    {contract_rate === 'HOURLY' &&
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='hourly_rate'>{IMLocalized('hourly_rate')}</label>
                                            <Input size="sm" name="hourly_rate" id="hourly_rate" value={hourly_rate} onChange={(e)=>{setHourlyRate(e.target.value); setErrHourlyRate('')}} invalid={err_hourly_rate.length >0} />
                                            <FormFeedback>{err_hourly_rate}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    }
                                    <Col lg="6" className="d-flex align-items-center">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id='currency_toggle' name='curreny_toggle' className="custom-control-input" checked={currency_toggle} onChange={(e)=>{handleCurrency(e)}} />
                                            <label className="custom-control-label" htmlFor="currency_toggle">{IMLocalized('foreign_currency')}?</label>
                                        </div>
                                    </Col>
                                    {currency_toggle &&
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='currency'>{IMLocalized('currency')}</label>
                                            <Input type="select" size="sm"  name="currency" value={currency} onChange={(e)=>{setCurrency(e.target.value); setErrCurrency('')}} invalid={err_currency.length>0} >
                                                <option></option>
                                                {props.currencylist.map((item,index)=>{
                                                    return (
                                                        <option value={item.id} key={index}>{item.currency_name}</option>
                                                    )
                                                })}
                                            </Input>
                                            <FormFeedback>{err_currency}</FormFeedback>
                                        </FormGroup>
                                    </Col>}
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='department'>{IMLocalized('department')}</label>
                                            <CreatableSelect id="department" styles={{
                                            control: (provided, state) => (err_department.length >0 ? {
                                            ...provided, borderColor: '#dc3545', 
                                            } : provided)
                                            }} isClearable placeholder={IMLocalized('select1')} 
                                            options={props.departmentlist.map(item=>{return {value:item.name,label:item.name}})} 
                                            value={department} 
                                            onChange={(value)=>{setDepartment(value); setErrDepartment('')}} />
                                            <div style={{color:'#dc3545',fontSize:'12px'}}>{err_department}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='section'>{IMLocalized('section')}</label>
                                            <CreatableSelect id="section" styles={{
                                            control: (provided, state) => (err_section.length >0 ? {
                                            ...provided, borderColor: '#dc3545', 
                                            } : provided)
                                            }} isClearable placeholder={IMLocalized('select1')} 
                                            options={props.sectionlist.map(item=>{return {value:item.name,label:item.name}})} 
                                            value={section} 
                                            onChange={(value)=>{setSection(value);setErrSection('')}} />
                                            <div style={{color:'#dc3545',fontSize:'12px'}}>{err_section}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='cost_center'>{IMLocalized('cost_center')}</label>
                                            <CreatableSelect id="cost_center" styles={{
                                            control: (provided, state) => (err_cost_center.length >0 ? {
                                            ...provided, borderColor: '#dc3545', 
                                            } : provided)
                                            }} isClearable placeholder={IMLocalized('select1')} 
                                            options={props.costcenterlist.map(item=>{return {value:item.name,label:item.name}})} 
                                            value={cost_center} 
                                            onChange={(value)=>{setCostCenter(value);setErrCostCenter('')}} />
                                            <div style={{color:'#dc3545',fontSize:'12px'}}>{err_cost_center}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='job_title'>{IMLocalized('job_title')}</label>
                                            <CreatableSelect id="job_title" styles={{
                                            control: (provided, state) => (err_job_title.length >0 ? {
                                            ...provided, borderColor: '#dc3545', 
                                            } : provided)
                                            }} isClearable placeholder={IMLocalized('select1')} 
                                            options={props.jobtitlelist.map(item=>{return {value:item.name,label:item.name}})} 
                                            value={job_title} 
                                            onChange={(value)=>{setJobTitle(value);setErrJobTitle('')}} />
                                            <div style={{color:'#dc3545',fontSize:'12px'}}>{err_job_title}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='job_level'>{IMLocalized('job_level')}</label>
                                            <CreatableSelect id="cost_center" styles={{
                                            control: (provided, state) => (err_job_level.length >0 ? {
                                            ...provided, borderColor: '#dc3545', 
                                            } : provided)
                                            }} isClearable placeholder={IMLocalized('select1')} 
                                            options={props.joblevellist.map(item=>{return {value:item.name,label:item.name}})} 
                                            value={job_level} 
                                            onChange={(value)=>{setJobLevel(value);setErrJobLevel('')}} />
                                            <div style={{color:'#dc3545',fontSize:'12px'}}>{err_job_level}</div>
                                        </FormGroup>
                                    </Col>
                                    {contract_rate === 'MONTHLY' &&
                                    <>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='allowance'>{IMLocalized('allowance')}</label>
                                            <Select id="allowance"  isMulti placeholder={IMLocalized('select1')}  
                                            options={props.allowancelist.map(item=>{
                                                return {value:item.name,label:item.name}
                                            })} className="basic-multi-select" classNamePrefix="select"  onChange={(value)=>{setAllowance(value)}} value={allowance} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='recurring_allowance'>{IMLocalized('recurring_allowance')}</label>
                                            <Select id="recurring_allowance"  isMulti placeholder={IMLocalized('select1')}  
                                            options={props.recurring_list.map(item=>{
                                                return {value:item.name,label:item.name}
                                            })} className="basic-multi-select" classNamePrefix="select"  onChange={(value)=>{setRecurring(value)}} value={recurring_allowance} />
                                        </FormGroup>
                                    </Col>
                                    </>}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3">
                        <Card>
                            <CardBody style={{backgroundColor:'#e9eff6'}}>
                                <div><h4>{IMLocalized('summary')}</h4></div>
                                <Table  size="sm">
                                    <tbody>
                                        <tr>
                                            <td>{IMLocalized('cost_center')}</td>
                                            <td>{cost_center ? cost_center.value : '---'}</td>
                                        </tr>
                                        <tr>
                                            <td>{IMLocalized('gross_salary')}</td>
                                            {contract_rate === 'MONTHLY' ?
                                            <>
                                            <td>{total_pay !== '' ? '$ ' + total_pay : '---'}</td>
                                            </>
                                            :
                                            <>
                                            <td>---</td>
                                            </>
                                            }
                                        </tr>
                                        {additional && 'total' in additional && pay!== '' && 
                                        <tr>
                                            <td>{IMLocalized('annual_salary')}</td>
                                            {contract_rate === 'MONTHLY' ?
                                            <td>$ { (parseFloat(additional.total)*12).toFixed(2)}</td>
                                            :
                                            <td>---</td>
                                            }
                                        </tr>
                                        }
                                        {additional && 'cpf_employer' in additional &&  pay!== '' && 
                                        <tr>
                                            <td>Employer CPF</td>
                                            <td>$ { (parseFloat(additional.cpf_employer)*12).toFixed(2)}</td>
                                        </tr>
                                        }
                                        {additional && 'sdl' in additional && pay!== '' && 
                                        <tr>
                                            <td>SDL</td>
                                            <td>$ { (parseFloat(additional.sdl)*12).toFixed(2)}</td>
                                        </tr>
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
            {props.isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
            <>
                <button onClick={onCreate} className='btn btn-primary btn-sm'>{IMLocalized('add')}</button>
                <button onClick={props.closeModal} className='btn btn-danger btn-sm'>{IMLocalized('close')}</button>
            </>
            }
            </ModalFooter>
        </Modal>
    )

}
export default connect(mapStateToProps,mapDispatchToProps)(ModalCreateContract);