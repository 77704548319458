import myaxios from '../../axios';

export const CLAIMALLGETCLAIMPICTURE_REQUEST = 'CLAIMALLGETCLAIMPICTURE_REQUEST';
export const CLAIMALLGETCLAIMPICTURE_SUCCESS = 'CLAIMALLGETCLAIMPICTURE_SUCCESS';
export const CLAIMALLGETCLAIMPICTURE_FAILURE = 'CLAIMALLGETCLAIMPICTURE_FAILURE';
export const CLAIMALLGETCLAIMPICTURE_RESET = 'CLAIMALLGETCLAIMPICTURE_RESET';

export const claim_all_get_claim_picture = (id)=>{
    return dispatch =>{
        dispatch({
            type:'CLAIMALLGETCLAIMPICTURE_REQUEST'
        });

        myaxios.get(`ob/claim/${id}/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'CLAIMALLGETCLAIMPICTURE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'CLAIMALLGETCLAIMPICTURE_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_claim_all_get_claim_picture = () => dispatch =>{
    dispatch({
        type:'CLAIMALLGETCLAIMPICTURE_RESET'
    });
}
