import React, { useContext } from "react";
import ClaimOfficerEmployeeContextProvider,{ClaimOfficerEmployeeContext} from "../../../context/ClaimOfficerEmployeeContext";
import EmployeeList from "../hr_employee/EmployeeList";
import EmpAllClaimDetail from "./EmpAllClaimDetail";
import EmpClaimDetail from "./EmpClaimDetail";
import EmployeeProfile from "./EmployeeProfile";


export default function EmployeeMain(){

    return(
        <ClaimOfficerEmployeeContextProvider>
            <AppStance/>
        </ClaimOfficerEmployeeContextProvider>
    )
}

const AppStance = ()=>{

    const {step, goProfile} = useContext(ClaimOfficerEmployeeContext);

    return(
        <>
        {step === '0' && <EmployeeList goProfile={goProfile}/>}
        {step === '1' && <EmployeeProfile/>}
        {step === '2' && <EmpClaimDetail/>}
        {step === '3' && <EmpAllClaimDetail/>}
        </>
    )
}