import myaxios from "../../axios"

export const GETEMPSELFHELP_REQUEST = 'GETEMPSELFHELP_REQUEST';
export const GETEMPSELFHELP_SUCCESS = 'GETEMPSELFHELP_SUCCESS';
export const GETEMPSELFHELP_FAILURE = 'GETEMPSELFHELP_FAILURE';


export const get_employee_self_help = () =>{
    return dispatch=>{
        dispatch({
            type:'GETEMPSELFHELP_REQUEST'
        });

        myaxios.get('employee/shg/')
        .then((response)=>{
            dispatch({
                type:'GETEMPSELFHELP_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPSELFHELP_FAILURE',
                payload:error
            })
        })
    }
}