import * as reset from '../action/reset_otp_password';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const reset_otp_password = (state=initialState,action)=>{
    switch(action.type){
        case reset.RESETOTPPASS_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case reset.RESETOTPPASS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case reset.RESETOTPPASS_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default reset_otp_password;