import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { FormFeedback, FormGroup, Modal, ModalFooter, ModalHeader, Row,ModalBody,Col,Input } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { create_employee_passport, reset_create_employee_passport } from '../../../../action/employee/create_employee_passport';


export default function ModalCreatePassport({ toggle, closeModal, onSuccess, id}){

    const create_success = useSelector(state=>state.create_employee_passport.data);
    const isLoading = useSelector(state=>state.create_employee_passport.isLoading);
    const dispatch = useDispatch();
    const [passport,setPassport] = useState('');
    const [err_passport,setErrPassport] = useState('');
    const [issued_date,setIssued] = useState('');
    const [err_issued_date,setErrIssued] = useState('');
    const [expiry_date,setExpiry] = useState('');
    const [err_expiry_date,setErrExpiry] = useState('');

    useEffect(()=>{
        if(toggle){
            setPassport('');
            setErrPassport('');
            setIssued('');
            setErrIssued('');
            setExpiry('');
            setErrExpiry('');
        }
    },[toggle])

    useEffect(()=>{
        if(create_success != null && toggle){
           onSuccess();
           dispatch(reset_create_employee_passport());
        }
    },[create_success, toggle, onSuccess, dispatch])

    const addPassport=()=>{
        if(expiry_date !== '' && issued_date !== '' && passport !== ''){
            const date = new Date(expiry_date);
            const date1 = new Date(issued_date);
            if(date < date1){
                setErrExpiry(IMLocalized('expiry_date_small_than_issued_date'))
            }
            else{
                const data = [{passport,issued_date,expiry_date,employee:id}];
                dispatch(create_employee_passport(data));
            }
        }
        else{
            if(passport === ''){
                setErrPassport(IMLocalized('passport_number_is_required'))
            }
            if(expiry_date === ''){
                setErrExpiry(IMLocalized('expiry_date_is_required'));
            }
            if(issued_date === ''){
                setErrIssued(IMLocalized('issued_date_is_required'));
            }
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('add_passport')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='passport'>{IMLocalized('passport_number')}</label>
                            <Input size="sm" type="text" id="passport" name="passport" value={passport} onChange={(e)=>{setPassport(e.target.value); setErrPassport('')}} invalid={err_passport.length > 0} />
                            <FormFeedback>{err_passport}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='issued_date'>{IMLocalized('issued_date')}</label>
                            <Input id="issued_date" size="sm" type="date" name="issued_date" value={issued_date} onChange={(e)=>{setIssued(e.target.value); setErrIssued('')}} invalid={err_issued_date.length >0} />
                            <FormFeedback>{err_issued_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='expiry_date'>{IMLocalized('expiry_date')}</label>
                            <Input id="expiry_date" size="sm" type="date" name="expiry_date" value={expiry_date} onChange={(e)=>{setExpiry(e.target.value); setErrExpiry('')}} invalid={err_expiry_date.length >0} />
                            <FormFeedback>{err_expiry_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading? 
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={addPassport}>{IMLocalized('add')}</button>
                    <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>
                }
            </ModalFooter>
        </Modal>
    )
}