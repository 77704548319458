import myaxios from '../axios';

export const GETIRASNATIONALITY_REQUEST = 'GETIRASNATIONALITY_REQUEST';
export const GETIRASNATIONALITY_SUCCESS = 'GETIRASNATIONALITY_SUCCESS';
export const GETIRASNATIONALITY_FAILURE = 'GETIRASNATIONALITY_FAILURE';

export const get_iras_nationality = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETIRASNATIONALITY_REQUEST'
        });

        myaxios.get('payroll/iras/nationality/')
        .then((response)=>{
            dispatch({
                type:'GETIRASNATIONALITY_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETIRASNATIONALITY_FAILURE',
                payload:error
            })
        })
    }
}