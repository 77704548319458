import * as regeneratepayroll from '../../action/payroll/regenerate_payroll';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const regenerate_payroll = (state=initialState,action)=>{
    switch(action.type){
        case regeneratepayroll.REGENERATEPAYROLL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case regeneratepayroll.REGENERATEPAYROLL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case regeneratepayroll.REGENERATEPAYROLL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default regenerate_payroll;
