import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMLocalized } from '../../../../language/IMLocalized';
import { Modal,ModalHeader,ModalBody,ModalFooter,Input,Row,Col,FormGroup,FormFeedback } from 'reactstrap';
import { reset_update_employee_workpermit, update_employee_workpermit } from '../../../../action/employee/update_employee_workpermit';

export default function ModalEditPermit({ toggle, closeModal, onSuccess, fin_number:fin_number1, detail, id}){

    const isLoading = useSelector(state=>state.update_employee_workpermit.isLoading);
    const errors = useSelector(state=>state.update_employee_workpermit.errors);
    const success = useSelector(state=>state.update_employee_workpermit.data);
    const permitlist = useSelector(state=>state.dropdown_list.work_permit);
    const dispatch = useDispatch();

    const [fin_number,setFin] = useState('');
    const [err_fin_number,setErrFin] = useState('');
    const [permit_type,setType] = useState('');
    const [err_permit_type,setErrType] = useState('');
    const [permit,setPermit] = useState('');
    const [err_permit,setErrPermit] = useState(''); 
    const [application_date,setApplication] = useState('');
    const [err_application_date,setErrApplication] = useState('');
    const [issued_date,setIssued] = useState('');
    const [err_issued_date,setErrIssued] = useState('');
    const [expiry_date,setExpiry] = useState('');
    const [err_expiry_date,setErrExpiry] = useState('');
    const [man_year_entitlement,setMan] = useState(false);

    useEffect(()=>{
        if(toggle && detail){
            setFin(fin_number1);
            setErrFin('');
            setType(detail.permit_type);
            setErrType('');
            setPermit(detail.permit);
            setErrPermit(''); 
            setApplication(detail.application_date);
            setErrApplication('');
            setIssued(detail.issued_date);
            setErrIssued('');
            setExpiry(detail.expiry_date);
            setErrExpiry('');
            setMan(detail.man_year_entitlement);
        }
    },[toggle,detail,fin_number1])

    useEffect(()=>{
        if(toggle && errors != null){
            if(errors.hasOwnProperty('identification_number')){
                const word = errors['identification_number'].toString();
                setErrFin(word);
            }
            dispatch(reset_update_employee_workpermit());
        }
    },[errors,toggle,dispatch])

    useEffect(()=>{
        if( toggle && success != null){
            onSuccess();
            dispatch(reset_update_employee_workpermit());
        }
    },[success,toggle,onSuccess, dispatch])

    const updatePermit=()=>{
        if(permit !== '' && permit_type !== '' && application_date !== '' && issued_date !== '' && application_date !== '' && expiry_date !== '' && fin_number !== null && fin_number !== '' ){
            
            const new_application = new Date(application_date);
            const new_expiry_date = new Date(expiry_date);
            const new_issued_date = new Date(issued_date);
            let count= 0;
            if(new_application >= new_expiry_date){
                setErrExpiry(IMLocalized('expiry_date_less_than_application_date'))
                count++;
            }
            if(new_application> new_issued_date){
                setErrIssued(IMLocalized('issued_date_less_than_application_date'))
                count++;
            }
            if(count === 0){
                const data = [{id: detail.id,permit_type,permit,man_year_entitlement,expiry_date,application_date,issued_date,employee: id}];
                dispatch(update_employee_workpermit(data,id,fin_number));
            }
        }
        else{
            if(permit === ''){
                setErrPermit(IMLocalized('permit_number_is_required'));
            }

            if(permit_type === ''){
                setErrType(IMLocalized('permit_type_is_required'));
            }

            if(application_date === ''){
                setErrApplication(IMLocalized('application_date_is_required'));
            }

            if(expiry_date === ''){
                setErrExpiry(IMLocalized('expiry_date_is_required'));
            }

            if(issued_date === ''){
                setErrIssued(IMLocalized('issued_date_is_required'));
            }

            if(fin_number === '' || fin_number === null){
                setErrFin(IMLocalized('fin_number_is_required'));
            }
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('edit_employee_permit')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='fin_number'>Fin Number</label>
                            <Input size="sm" id="fin_number" name="fin_number" value={fin_number} onChange={(e)=>{setFin(e.target.value); setErrFin('') }} invalid={err_fin_number.length >0} />
                            <FormFeedback>{err_fin_number}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='permit_type'>{IMLocalized('permit_type')}</label>
                            <Input size="sm" type="select" id="permit_type" name="permit_type" value={permit_type} onChange={(e)=>{setType(e.target.value); setErrType('') }} invalid={err_permit_type.length >0}>
                                <option></option>
                                {permitlist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_permit_type}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='permit_number'>{IMLocalized('permit_number')}</label>
                            <Input size="sm" id="permit_number" type="text" name="permit" value={permit} onChange={(e)=>{setPermit(e.target.value); setErrPermit('') }} invalid={err_permit.length >0} />
                            <FormFeedback>{err_permit}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='application_date'>{IMLocalized('application_date')}</label>
                            <Input size="sm" type="date" name="application_date" id="application_date" value={application_date} onChange={(e)=>{setApplication(e.target.value); setErrApplication('')}} invalid={err_application_date.length >0} />
                            <FormFeedback>{err_application_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='expiry_date'>{IMLocalized('expiry_date')}</label>
                            <Input size="sm" id="expiry_date" name="expiry_date" type="date" value={expiry_date} onChange={(e)=>{setExpiry(e.target.value); setErrExpiry('')}} invalid={err_expiry_date.length >0 } />
                            <FormFeedback>{err_expiry_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='issued_date'>{IMLocalized('issued_date')}</label>
                            <Input size="sm" id="issued_date" name="issued_date" type="date" value={issued_date} onChange={(e)=>{setIssued(e.target.value); setErrIssued('')}} invalid={err_issued_date.length >0 } />
                            <FormFeedback>{err_issued_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="create_permit_customCheck" name='man_year_entitlement' className="custom-control-input" checked={man_year_entitlement} onChange={(e)=>setMan(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="create_permit_customCheck">{IMLocalized('man_year_entitlement')}</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button onClick={updatePermit} className="btn btn-primary btn-sm">{IMLocalized('update')}</button>
                    <button onClick={closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>
                }
            </ModalFooter>
        </Modal>
    )
}