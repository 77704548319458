import myaxios from '../../axios';
export const EMPCONTRACTDEPARTMENT_SUCCESS = 'EMPCONTRACTDEPARTMENT_SUCCESS';
export const EMPCONTRACTDEPARTMENT_REQUEST = 'EMPCONTRACTDEPARTMENT_REQUEST';
export const EMPCONTRACTDEPARTMENT_FAILURE = 'EMPCONTRACTDEPARTMENT_FAILURE';


export const employee_contract_department = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'EMPCONTRACTDEPARTMENT_REQUEST'
        });
        
        myaxios.post(`company/department/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'EMPCONTRACTDEPARTMENT_SUCCESS',
                payload:response
            }
        )})
        .catch((error) =>{
            dispatch({
                type: 'EMPCONTRACTDEPARTMENT_FAILURE',
                payload: error,
            })
        })
    }
}