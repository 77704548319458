import * as emppayroll from '../../action/employee/get_employee_payroll';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}


const get_employee_payroll = (state=initialState,action)=>{
    switch(action.type){
        case emppayroll.GETEMPPAYROLL_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case emppayroll.GETEMPPAYROLL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case emppayroll.GETEMPPAYROLL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_payroll;