import * as contractjoblevel from '../action/create_contract_job_level';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}


const create_contract_job_level = (state=initialState,action)=>{
    switch(action.type){
        case contractjoblevel.CREATECONTRACTJOBLEVEL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case contractjoblevel.CREATECONTRACTJOBLEVEL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractjoblevel.CREATECONTRACTJOBLEVEL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case contractjoblevel.CREATECONTRACTJOBLEVEL_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default create_contract_job_level;