import * as userapplylist from '../../action/user/user_apply_leave_list';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
}


const user_apply_leave_list = (state=initialState,action)=>{
    switch(action.type){
        case userapplylist.GETUSERAPPLYLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case userapplylist.GETUSERAPPLYLIST_SUCCESS:
            return{
                ...state,
                data:action.payload.data,
                isLoading:false,
            }
        case userapplylist.GETUSERAPPLYLIST_FAILURE:
            return{
                ...state,
                errors:action.payload,
                isLoading:false
            }
        default:
            return state;
    }
}
export default user_apply_leave_list;