import * as massassign1 from '../../action/approvals/mass_assign_claim_approval1';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const mass_assign_claim_approval1 = (state=initialState,action)=>{
    switch(action.type){
        case massassign1.MASSASSIGNCLAIMAPPROVAL1_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case massassign1.MASSASSIGNCLAIMAPPROVAL1_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case massassign1.MASSASSIGNCLAIMAPPROVAL1_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case massassign1.MASSASSIGNCLAIMAPPROVAL1_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state
    }
}
export default mass_assign_claim_approval1;