import * as delotplist from '../../action/user/delete_otp_list';

const initialState={
    data:null,
    errors:[],
    isLoading:false
}


const delete_otp_list = (state =initialState,action)=>{
    switch(action.type){
        case delotplist.DELETEOTPLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
            }
        case delotplist.DELETEOTPLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delotplist.DELETEOTPLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default delete_otp_list;