import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const ADDLEAVEGROUPTEMPLATE_REQUEST = 'ADDLEAVEGROUPTEMPLATE_REQUEST';
export const ADDLEAVEGROUPTEMPLATE_SUCCESS = 'ADDLEAVEGROUPTEMPLATE_SUCCESS';
export const ADDLEAVEGROUPTEMPLATE_FAILURE = 'ADDLEAVEGROUPTEMPLATE_FAILURE';
export const ADDLEAVEGROUPTEMPLATE_RESET = 'ADDLEAVEGROUPTEMPLATE_RESET';


export const add_leave_group_template = (name,groups)=>{
    return dispatch=>{
        dispatch({
            type:'ADDLEAVEGROUPTEMPLATE_REQUEST'
        });

        myaxios.post('leave/mass/template/',{
            name:name,
            groups:groups
        })
        .then((response)=>{
            dispatch({
                type:'ADDLEAVEGROUPTEMPLATE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type:'success',
                title:IMLocalized('add_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'ADDLEAVEGROUPTEMPLATE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('add_failure')
            });
        })
    }
}

export const reset_add_leave_group_template = ()=> dispatch =>{
    dispatch({
        type:'ADDLEAVEGROUPTEMPLATE_RESET'
    });
}