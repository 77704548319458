import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const NEW_CREATECONTRACT_REQUEST = 'NEW_CREATECONTRACT_REQUEST';
export const NEW_CREATECONTRACT_SUCCESS = 'NEW_CREATECONTRACT_SUCCESS';
export const NEW_CREATECONTRACT_FAILURE = 'NEW_CREATECONTRACT_FAILURE';

export const create_employee_contract =(data)=>{
    return dispatch=>{
        dispatch({
            type:'NEW_CREATECONTRACT_REQUEST'
        });

        const {hours_day,contract_rate,contract_type,is_workman,start_date,employee,
        pay,hourly_rate,notes,job_level,job_title,section,department,cost_center,
        schedule,allowances,recurring_allowances,currency,daily_rate} = data;
        

        myaxios.post('contract/contracts/',{
            start_date:start_date,
            pay:pay,
            hourly_rate:hourly_rate,
            hours_day:hours_day,
            contract_type:contract_type,
            contract_rate:contract_rate,
            is_workman:is_workman,
            notes:notes,
            job_level:job_level,
            department:department,
            section:section,
            job_title:job_title,
            cost_center:cost_center,
            wschedule:schedule,
            allowances:allowances,
            employee:employee,
            recurring_allowances:recurring_allowances,
            contract_currency:currency,
            daily_rate:daily_rate
        })
        .then((response)=>{
            dispatch({
                type:'NEW_CREATECONTRACT_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'NEW_CREATECONTRACT_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}