import myaxios from '../../axios';


export const GETEMPCONTRACTLIST_REQUEST = 'GETEMPCONTRACTLIST_REQUEST';
export const GETEMPCONTRACTLIST_SUCCESS = 'GETEMPCONTRACTLIST_SUCCESS';
export const GETEMPCONTRACTLIST_FAILURE = 'GETEMPCONTRACTLIST_FAILURE';

export const get_employee_contract_list = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETEMPCONTRACTLIST_REQUEST'
        });

        myaxios.get('employee/contract/')
        .then((response)=>{
            dispatch({
                type:'GETEMPCONTRACTLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPCONTRACTLIST_FAILURE',
                payload:error
            })
        })
    }
}