import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, NavItem, UncontrolledDropdown, NavLink, Nav } from 'reactstrap';
import { get_additional_setting } from '../../../action/additional_setting';
import { get_claim_list } from '../../../action/claim/get_claim_list';
import { get_general_dashboard } from '../../../action/dashboard/get_general_dashboard';
import { dropdown_list } from '../../../action/dropdown_list';
import { get_all_employee_available_leave } from '../../../action/employee/get_all_employee_available_leave';
import { get_employee_list } from '../../../action/employee/get_employee_list';
import { get_employee_picture_list } from '../../../action/employee/get_employee_picture_list';
import { general_daterange } from '../../../action/general_daterange';
import { get_apply_leave_list } from '../../../action/get_apply_leave_list';
import { get_general_color } from '../../../action/get_general_color';
import { getHolidayList } from '../../../action/get_holiday_list';
import { get_leave_list } from '../../../action/get_leave_list';
import { get_leave_pending_color } from '../../../action/user/get_leave_pending_color';
import { user_get_available_leave } from '../../../action/user/user_get_available_leave';
import { user_self } from '../../../action/user/user_self';
import { NewMainContext } from '../../../context/NewMainContext';
import { IMLocalized } from '../../../language/IMLocalized';


export default function LeaveOffcerNavbar(props){

    const { addItem, currentnavs } = useContext(NewMainContext);
    const dispatch = useDispatch();

    useEffect(()=>{
        let mounted =true;
        function idExists(arr){
            let count = 0;
            for(let i =0 ;i<arr.length; i++){
                const bool = currentnavs.some(item=>item.id === arr[i]);
                if(bool === true){
                    count++;
                }
            }
            return count > 0 ? true : false; 
        }

        if(mounted){
            if(currentnavs.length !== 0){
                dispatch(user_self());
                dispatch(get_employee_list());
                dispatch(get_leave_list());
                dispatch(general_daterange());
                dispatch(get_all_employee_available_leave());
                dispatch(dropdown_list());

                if(idExists(['4','5'])){ 
                    dispatch(get_leave_pending_color());
                }

                if(idExists(['2'])){
                    dispatch(get_additional_setting());
                    dispatch(get_employee_picture_list());
                }

                if(idExists(['4'])){
                    dispatch(getHolidayList());
                }

                if(idExists(['2','3','4','5'])){
                    dispatch(get_apply_leave_list());
                    dispatch(get_general_color());
                }

                if(idExists(['1'])){
                    dispatch(get_general_dashboard());
                    dispatch(get_claim_list());
                    dispatch(user_get_available_leave());
                }
            }
        }
    },[currentnavs,dispatch])

    return(
        <Nav navbar>
            <NavItem className="cursor-pointer">
                <NavLink className="text-white font-text" onClick={()=>addItem('1','dashboard')}><span className="font-text">{IMLocalized('dashboard')}</span></NavLink>
            </NavItem>
            {process.env.REACT_APP_LEAVE === 'true' &&
            <UncontrolledDropdown nav inNavbar className="nav-item dropdown yamm-fw">
                <DropdownToggle nav caret className="text-white font-text">
                    <span className="font-text">{IMLocalized('leave')}</span>
                </DropdownToggle>
                <DropdownMenu className="scrollable-menu" left="true">
                    <div className="yamm-content">
                        <div className="row">
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('leave_list')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('2','employee_list')}>{IMLocalized('employee_list')}</DropdownItem>
                            </ul>
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('leave_view')}</h4>
                                </li>
                                <hr className="m-1"/>
                                {/* <DropdownItem className="cursor-pointer" onClick={()=>addItem('4','leave_calendar1')}>{IMLocalized('leave_calendar1')}</DropdownItem> */}
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('24','leave_calendar')}>{IMLocalized('leave_calendar')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('3','leave_table')}>{IMLocalized('leave_table')}</DropdownItem>
                            </ul>
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>}
        </Nav>
    )
}