import myaxios from '../../axios';

export const EMPADDITIONALCOST_REQUEST = 'EMPADDITIONALCOST_REQUEST';
export const EMPADDITIONALCOST_SUCCESS = 'EMPADDITIONALCOST_SUCCESS';
export const EMPADDITIONALCOST_FAILURE = 'EMPADDITIONALCOST_FAILURE';

export const employee_contract_additional_cost = (employee,amount) =>{
    return dispatch=>{
        dispatch({
            type:'EMPADDITIONALCOST_REQUEST'
        });
        myaxios.post(`contract/contracts/get_additional_cost/`,{
            employee:employee,
            amount:amount
        })
        .then((response)=>{
            dispatch({
                type:'EMPADDITIONALCOST_SUCCESS',
                payload:response.data
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPADDITIONALCOST_FAILURE',
                payload:error,
                error:true
            })
        })
    }
}