import React, { useContext, useState } from 'react';
import { Card, CardHeader, Col, Container, ListGroup, ListGroupItem, Row, CardBody } from 'reactstrap';
import { WizardHolidayContext } from '../../context/WizardHolidayContext';
import { getDateFormat } from '../../func/getDateFormat';
import { IMLocalized } from '../../language/IMLocalized';
import ModalAddHolidayDetail from './modal/ModalAddHolidayDetail';
import ModalEditHolidayDetail from './modal/ModalEditHolidayDetail';

export default function HolidayTable(){

    const { list, holiday_id, setStep, setHolidayID, setList, setData } = useContext(WizardHolidayContext);
    const [ add_toggle, setAddToggle] = useState(false);
    const [ edit_toggle, setEditToggle] = useState(false);
    const [ edit_index, setEditIndex] = useState(null);


    const goBack=()=>{
        setHolidayID(null);
        setStep(0);
    }

    const deleteDetail=(index)=>{
        const data = [...list];
        data[holiday_id].holiday.splice(index,1);
        setList(data);
    }

    const editDetail=(index)=>{
        setEditIndex(index);
        setEditToggle(true);
    }

    const name = list[holiday_id]?.name ?? '';
    const holidays = list[holiday_id]?.holiday ?? [];
    return(
        <>
        <Container className='mt-2'>
            <Row>
                <Col>
                    <button className="btn btn-primary btn-sm" onClick={goBack}><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                    <Card className='mt-2'>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h4 className='mb-0'>{IMLocalized('holiday')} - {name}</h4>
                                </Col>
                                <Col className='col-auto'>
                                    <button className='btn btn-primary btn-sm' onClick={()=>setAddToggle(true)}>{IMLocalized('create')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        {holidays.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {holidays.length !== 0 && 
                        <ListGroup className='list' flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                            {holidays.map((item,index)=>{
                                const number = index +1;
                                return(
                                    <ListGroupItem className='py-2' key={index}>
                                        <Row>
                                            <Col>
                                                <span>{number}. {item.name}  ({getDateFormat(item.date)})</span>
                                            </Col>
                                            <Col className='col-auto mr-1'>
                                                <button className='btn btn-info btn-sm' onClick={()=>editDetail(index)}>{IMLocalized('edit')}</button>
                                                <button className='btn btn-danger btn-sm' onClick={()=>deleteDetail(index)}>{IMLocalized('delete')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
            <div className='create_btn_parent'>
                <i className='fas fa-arrow-circle-right text-success cursor-pointer create_btn' onClick={()=>setData(list)}  />
            </div>
        </Container>
        <ModalAddHolidayDetail toggle={add_toggle} closeModal={()=>setAddToggle(false)} />
        <ModalEditHolidayDetail toggle={edit_toggle} closeModal={()=>{setEditToggle(false); setEditIndex(null)}} index={edit_index} />
        </>
    )
}