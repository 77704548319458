import myaxios from '../../axios';

export const COMPUTATIONAGGRIDHEADER_REQUEST = 'COMPUTATIONAGGRIDHEADER_REQUEST';
export const COMPUTATIONAGGRIDHEADER_SUCCESS = 'COMPUTATIONAGGRIDHEADER_SUCCESS';
export const COMPUTATIONAGGRIDHEADER_FAILURE = 'COMPUTATIONAGGRIDHEADER_FAILURE';


export const payroll_computation_get_aggridheader = (id)=>{
    return dispatch=>{
        dispatch({
            type:'COMPUTATIONAGGRIDHEADER_REQUEST'
        });

        myaxios.get(`payroll/payroll/${id}/ReportGridHeader/`)
        .then((response)=>{
            dispatch({
                type:'COMPUTATIONAGGRIDHEADER_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'COMPUTATIONAGGRIDHEADER_FAILURE',
                payload:error
            })
        })
    }
}