import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { payroll_printpayout } from '../../../action/payroll/payroll_printpayout';

const mapStateToProps=(state)=>({
    list:state.get_payroll_instance.data,
})

const mapDispatchToProps=(dispatch)=>({
    payroll_printpayout:(id,idd,title)=>{
        dispatch(payroll_printpayout(id,idd,title))
    },
})

function ModalViewPayout(props){

    const [list,setList] = useState([]);
    const [monthNames] = useState([IMLocalized("january"), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")])

    useEffect(()=>{
        if(props.toggle && props.list.length !== 0){
            const {payout} = props.list[0];
            setList(payout);
        }
        else{
            setList([]);
        }
    },[props.list,props.toggle])

    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>
                {IMLocalized('payout')}
            </ModalHeader>
            <ModalBody>
                {list.length === 0 && <span>{IMLocalized('no_data')}</span>}
                {list.length !== 0 &&
                <Table size="sm" className='align-items-center table-bordered' responsive>
                    <thead className='thead-light text-center'>
                        <tr>
                            <th>{IMLocalized('date')}</th>
                            <th>{IMLocalized('payment')}</th>
                            <th>{IMLocalized('view')} / {IMLocalized('download')}</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {list.map((item,index)=>{

                            const date = new Date(item.date);
                            let month = date.getMonth()+1;
                            if(month<10){
                                month = '0'+month;
                            }
                            let day = date.getDate();
                            if(day<10){
                                day = '0'+day;
                            }
                            let min = date.getMinutes();
                            if(min<10){
                                min = '0'+min;
                            }
                            let sec = date.getSeconds();
                            if(sec<10){
                                sec = '0'+sec;
                            }
                            let hour = date.getHours();
                            if(hour <10){
                                hour = '0'+hour;
                            }
                            let that_date = day+'/'+month+'/'+date.getFullYear();
                            let time = hour +':'+min +":"+sec;

                            return(
                                <tr key={index}>
                                    <td>{that_date} {time}</td>
                                    <td>{item.first ? 'Basic Pay' : '--'}</td>
                                    <td>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <div className='primary_btn d-flex align-items-center justify-content-center'>
                                                <i className='far fa-eye text-primary cursor-pointer' onClick={()=>props.toViewDetail(item.id)} />
                                            </div>
                                            <div className='primary_btn d-flex align-items-center justify-content-center' >
                                                <i className='fas fa-download text-primary cursor-pointer' onClick={()=>props.payroll_printpayout(item.payroll,item.id,`${date.getFullYear()}-${monthNames[date.getMonth()]}-${item.first === true ? 'BasePay' : 'FinalPay'} `)} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                }
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={props.closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalViewPayout);