import * as createcostcenter from '../action/create_costcenter';


const initialState ={
    isLoading:false,
    errors:null,
    data:null
}


const create_costcenter = (state=initialState,action) =>{

    switch(action.type) {
        case createcostcenter.CREATECOSTCENTER_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case createcostcenter.CREATECOSTCENTER_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case createcostcenter.CREATECOSTCENTER_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case createcostcenter.CREATECOSTCENTER_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
        }
}
export default create_costcenter;