import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELSECTION_REQUEST = 'DELSECTION_REQUEST';
export const DELSECTION_FAILURE = 'DELSECTION_FAILURE';
export const DELSECTION_SUCCESS = 'DELSECTION_SUCCESS';
export const DELSECTION_RESET = 'DELSECTION_RESET';

export const delSection = (id)=>{
    return dispatch =>{
        dispatch({
            type: 'DELSECTION_REQUEST'
        });
        
        myaxios.delete(`company/section/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELSECTION_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'DELSECTION_FAILURE',
                payload: error,
                error:true
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            })
        })
    }
}

export const reset_delete_section = () => dispatch =>{
    dispatch({
        type:'DELSECTION_RESET'
    });
}




