import * as historylist from '../../action/history/get_history_list';

const initialState={
    data:[],
    errors:[],
    isLoading:false
}

const get_history_list = (state=initialState,action)=>{
    switch(action.type){
        case historylist.HISTORYLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case historylist.HISTORYLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case historylist.HISTORYLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_history_list;