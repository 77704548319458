import React, { useEffect, useState } from 'react';
import { DropdownItem } from 'reactstrap';
import {connect} from 'react-redux';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';
import moment from 'moment';
import {IMLocalized} from '../../language/IMLocalized';
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';


const mapStateToProps=(state)=>({
    list:state.get_leave_list.data,
    isLoading:state.get_leave_list.isLoading,
})

function LeaveListReport(props){

    const [fixedlist,setFixed] = useState([]);
    const [userlist,setUser] = useState([]);

    useEffect(()=>{
        const sort = props.list.sort((a,b)=>a.id - b.id);
        const fixed = sort.filter(item=>item.fixed === true);
        const list = sort.filter(item=>item.fixed === false);
        setFixed(fixed);
        setUser(list);
    },[props.list])

    const generateExcel=()=>{
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('Leave List Report');
        const today = moment().format('DD/MM/YYYY');
        const time = moment().format('HH:mm:ss');
        const day =moment().format("DD");
        const month =moment().format("MM");
        const title_name = `LeaveListReport_${day}-${month}`;
        ws.getCell('A1').value = 'Leave List Report';
        ws.getCell('A2').value = `Printed On : ${today} ${time}`;
        let count = 0;
        const column=[
            {header:'No.',key:'no'},
            {header:'Leave Type',key:'name'}
        ]
        ws.columns = [
            {width: 15},
            {width: 20}, 
            {width: 15}
        ];
        ws.getCell('A4').value = 'Statutory Leave';

        for(let j=0;j<column.length;j++){
            const headerName = column[j].header;
            const field = (column[j].key);
            ws.getCell(getColumnName(j)+'5').value = headerName;
            for(let x=0;x<fixedlist.length;x++){
                const step = 6+x;
                const number = x+1;
                const item = getValue(fixedlist[x],field);
                if(field === 'no'){
                    ws.getCell(getColumnName(j)+step).value = number.toString();
                }
                else{
                    ws.getCell(getColumnName(j)+step).value = item;
                }
                count = step +3;
            }
        }

        ws.getCell('A'+count).value = 'User Define Leave';
        for(let j =0;j<column.length;j++){
            const headerName = column[j].header;
            const field = (column[j].key);
            const user_step = count +1;
            ws.getCell(getColumnName(j)+user_step).value = headerName;
            for(let x=0;x<userlist.length;x++){
                const step = user_step +1+x;
                const number = x+1;
                const item = getValue(userlist[x],field);
                if(field === 'no'){
                    ws.getCell(getColumnName(j)+step).value = number.toString();
                }
                else{
                    ws.getCell(getColumnName(j)+step).value = item;
                }
            }
        }

        const file_name = title_name+'.xlsx';
        wb.xlsx.writeBuffer().then((buf)=>{
            var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
            FileSaver.saveAs(file, file_name)
        });
    }

    return(
        <DropdownItem className="cursor-pointer" onClick={generateExcel}>{IMLocalized('leave_list_report')}</DropdownItem>
    )
}
export default connect(mapStateToProps,null)(LeaveListReport);