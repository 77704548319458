import * as empworkpermit from '../../action/employee/get_employee_permit';

const initialState ={
    data:[],
    errors:[],
    isLoading:false,
}


const get_employee_permit = (state=initialState,action) =>{

    switch(action.type) {
        case empworkpermit.REQUEST_EMPWORKPERMIT:
            return{
                ...state,
                isLoading: true,
                errors:[]
            }
        case empworkpermit.GET_EMPWORKPERMIT:
            return{
                ...state,
            data: action.payload.data,
            errors:[],
            isLoading:false,
            isLoaded:true
        }
        case empworkpermit.FAILURE_EMPWORKPERMIT:
            return{
                ...state,
                errors:action.payload,
                isLoading:false
            }
        default:
            return state;
    }
}
export default get_employee_permit;
