import * as delclaim from '../../action/claim/delete_claim';

const initialState={
    errors:null,
    isLoading:false,
    data:null
}

const delete_claim = (state= initialState,action)=>{
    switch(action.type){
        case delclaim.DELCLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case delclaim.DELCLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delclaim.DELCLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case delclaim.DELCLAIM_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default delete_claim;