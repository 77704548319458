import myaxios from '../axios';
export const COMPANYLIST_REQUEST = 'COMPANYLIST_REQUEST';
export const COMPANYLIST_SUCCESS = 'COMPANYLIST_SUCCESS';
export const COMPANYLIST_FAILURE = 'COMPANYLIST_FAILURE';


export const get_company_list = ()=>{
    return dispatch =>{
        dispatch({
            type: 'COMPANYLIST_REQUEST'
        });
        
        myaxios.get('company/company/')
        .then((response)=>{
            dispatch({
                type: 'COMPANYLIST_SUCCESS',
                payload:response
                
            })
            
        })
        .catch((error) =>
        dispatch({
            type: 'COMPANYLIST_FAILURE',
            payload: error,
            error:true
        })
        )
    }
}
