import { AgGridReact } from "ag-grid-react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Input, Row } from "reactstrap";
import { payroll_computation_get_aggridheader } from "../../action/report/payroll_computation_get_aggridheader";
import { payroll_computation_payroll_instance } from "../../action/report/payroll_computation_payroll_instance";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";
import OverlayLoading from '../loading_component/overlay_loading';
import ExcelJs from 'exceljs';
import moment from "moment";
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';
import FileSaver from 'file-saver';
import CustomPinnedRowRenderer from "../ag-grid_component/custompinnedrow";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";

const gridOptions={
    defaultColDef:{
        suppressMenu:true,
        resizable:true,
        filter:true,
        editable:false
    },
    sideBar:{
        toolPanels:[
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                    suppressColumnFilter: false,
                    suppressColumnSelectAll: false,
                    suppressColumnExpandAll: false,
                },
            },
        ],
        defaultToolPanel:'columns'
    },
}

export default function PayrollComputationSummary(){

    const payrolllist = useSelector(state=>state.get_payroll_list.data);
    const payroll_data = useSelector(state=>state.payroll_computation_payroll_instance.data);
    const agheader = useSelector(state=>state.payroll_computation_get_aggridheader.data);
    const selflist = useSelector(state=>state.dropdown_list.self_help_group);

    const isLoading = useSelector(state=>state.payroll_computation_payroll_instance.isLoading);
    const isLoading1 = useSelector(state=>state.payroll_computation_get_aggridheader.isLoading);
    const isLoading2 = useSelector(state=>state.get_payroll_list.isLoading);

    const agRef = useRef();

    const dispatch = useDispatch();
    const { addItem, action_toggle, setPayrollComputationSummaryReset, payroll_computation_summary_reset } = useContext(NewMainContext);
    const [ select_payroll, setSelectPayroll] = useState('');
    const [ select_option, setSelectOption] = useState('');
    const [ data1, setData1] = useState([]);
    const [ rowData, setRowData] = useState([]);
    const [ bottomData, setBottomData] = useState([]);
    const monthNames1 = [IMLocalized('january'), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")];

    const payroll_option = payrolllist.map((item)=>{
        const { payroll_date } = item;
        const month1 = new Date(payroll_date).getMonth();
        const year = new Date(payroll_date).getFullYear();
        const month = monthNames1[month1];
        return { value: item.id, display: `${month} ${year}` }
    })

    useEffect(()=>{
        if(payroll_computation_summary_reset === true){
            setPayrollComputationSummaryReset(false);
            setRowData([]);
            setData1([]);
            setBottomData([]);
            setSelectOption('');
            setSelectPayroll('');
        }
    },[setPayrollComputationSummaryReset,payroll_computation_summary_reset])

    useEffect(()=>{
        if(select_payroll !== ''){
            dispatch(payroll_computation_get_aggridheader(select_payroll));
            dispatch(payroll_computation_payroll_instance(select_payroll));
        }   
        setSelectOption('');

    },[select_payroll,dispatch])

    useEffect(()=>{
        const arr = [];
        if( payroll_data != null && payroll_data.length !== 0 ){
            const { payslips } = payroll_data[0];
            for(const item of payslips){
                const { pay, payslipcontract, sgcpf, sgshg } = item;
                
                const cpf_employee_amount = sgcpf?.cpf_employee ?? null;
                const cpf_employer_amount = sgcpf?.cpf_employer ?? null;
                const basic_pay = payslipcontract.reduce((total,element)=>  parseFloat((total + element.gross_month).toFixed(2)), 0);
                let aggrid = {};
                for(const pay_item of pay){
                    const { code, amount, id } = pay_item;
                    const name = code.replace('.','-');
                    aggrid[name] = { amount, id };
                }

                let shg1 = {};
                for(const shg_item of sgshg){
                    const { shg, amount, id } = shg_item;
                    shg1[shg] = {amount, id};
                }

                const total_overtime = pay.filter(element=>element.pay_type === 'OT').reduce((total,element)=>total + element.amount, 0);
                const total_allowance = pay.filter(element=>element.pay_type === 'ALLOWANCE').reduce((total,element)=> total + element.amount, 0);
                const total_fixed = pay.filter(element=>element.pay_type === 'FX').reduce((total,element)=> total + element.amount , 0);

                arr.push({...item, total_fixed, shg:shg1, total_allowance, total_overtime, aggrid, basic_pay, cpf_employee:cpf_employee_amount, cpf_employer:cpf_employer_amount });
            }
        }
        setData1(arr);
    },[payroll_data])

    useEffect(()=>{

        function getBottomData(items){
            let aggrid = {};
            let shg = {};
            if(agheader?.ag_grid != null ){
                const { ag_grid } = agheader;
                for(const grid of ag_grid){
                    const name = grid.replace('.','-');
                    aggrid[name] = { amount: 0 };
                }
            }

            for(const self_item of selflist){
                shg[self_item.name] = { amount : 0};
            }

            const basic_pay = items.reduce((total,element)=> parseFloat(( total + element.basic_pay).toFixed(2)),0);
            const payable_wage = items.reduce((total,element)=> parseFloat(( total + element.payable_wage).toFixed(2)), 0);
            const total_overtime = items.reduce((total,element)=> parseFloat(( total + element.total_overtime).toFixed(2)), 0);
            const total_allowance = items.reduce((total,element)=> parseFloat((total + element.total_allowance).toFixed(2)),0);
            const total_fixed = items.reduce((total,element)=> parseFloat((total + element.total_fixed).toFixed(2)),0);
            const cpf_employer = items.reduce((total,element)=> parseFloat((total + element.cpf_employer).toFixed(2)),0);
            const cpf_employee = items.reduce((total,element)=> parseFloat((total + element.cpf_employee).toFixed(2)),0);
            const sdl = items.reduce((total,element)=> parseFloat((total + element.sdl).toFixed(2)), 0);
            const total_employer = items.reduce((total,element)=> parseFloat(( total + element.total_employer).toFixed(2)),0);

            for(const item of items){
                const emp_aggrid = item.aggrid;
                const aggrid_length = Object.keys(aggrid).length;
                if(Object.keys(emp_aggrid).length !== 0 ){
                    for(let i=0 ;i<aggrid_length; i++){
                        const name = Object.keys(aggrid)[i];
                        const aggrid_value = aggrid[name];
                        if(emp_aggrid[name]){
                            const { amount } = aggrid_value;
                            const number = emp_aggrid[name].amount;
                            const total = parseFloat(( number + amount ).toFixed(2));
                            aggrid[name] = { amount: total};
                        }
                    }
                }

                const emp_shg = item.shg;
                const shg_length = Object.keys(shg).length;
                if(Object.keys(emp_shg).length !== 0 ){
                    for(let i=0; i< shg_length; i++){
                        const name = Object.keys(shg)[i];
                        const shg_value = shg[name];
                        if(emp_shg[name]){
                            const { amount } = shg_value;
                            const number = emp_shg[name].amount;
                            const total = parseFloat(( amount + number ).toFixed(2));
                            shg[name] = { amount : total};
                        }
                    }
                }
            }
            
            return [ { shg, aggrid, option:'Total', basic_pay, payable_wage, total_overtime, total_allowance, total_fixed, cpf_employee, cpf_employer, sdl, total_employer }];
        }   

        function getData(items){
            const arr = [];
            for( const item of items){
                const { name } = item;
                const detail = data1.filter(item=>item.employee?.contract_cache?.[select_option] ? item.employee.contract_cache[select_option] === name : false)

                if(detail.length !== 0 ){

                    let test = {};
                    let self = {};
                    if(agheader?.ag_grid != null ){
                        const { ag_grid } = agheader;
                        for(const grid of ag_grid){
                            const name = grid.replace('.','-');
                            test[name] = { amount: 0 };
                        }
                    }

                    for(const selfitem of selflist){
                        const name = selfitem.value;
                        self[name] = { amount :0 };

                    }

                    const total_basic_pay = detail.reduce((total,element)=> parseFloat((total + element.basic_pay).toFixed(2)),0);
                    const total_nett_salary = detail.reduce((total,element)=> parseFloat((total + element.payable_wage).toFixed(2)) ,0);
                    const total_overtime = detail.reduce((total,element)=> parseFloat((total + element.total_overtime).toFixed(2)), 0);
                    const total_allowance = detail.reduce((total,element)=> parseFloat((total + element.total_allowance).toFixed(2)), 0);
                    const total_fixed = detail.reduce((total,element)=>parseFloat((total + element.total_fixed).toFixed(2)),0);
                    const total_sdl = detail.reduce((total,element)=> parseFloat((total + element.sdl).toFixed(2)),0);
                    const total_employer = detail.reduce((total,element)=> parseFloat((total + element.total_employer).toFixed(2)), 0);
                    const cpf_employee  = detail.reduce((total,element)=>parseFloat((total + element.cpf_employee).toFixed(2)),0);
                    const cpf_employer  = detail.reduce((total,element)=>parseFloat((total + element.cpf_employer).toFixed(2)),0);

                    const test_length = Object.keys(test).length;
                    const self_length = Object.keys(self).length;

                    for( const data of detail){
                        const { aggrid, shg } = data;
                        for( let i = 0; i < test_length; i++){
                            const test_name = Object.keys(test)[i];
                            if(aggrid[test_name]){
                                const { amount } = aggrid[test_name];
                                const obj = Object.values(test)[i];
                                const test_amount = obj.amount;
                                const total_amount  = parseFloat((test_amount + amount).toFixed(2));
                                test[test_name].amount = total_amount;
                            }
                        }

                        for( let i = 0; i< self_length; i++){
                            const self_name = Object.keys(self)[i];
                            if(shg[self_name]){
                                const { amount } = shg[self_name];
                                const obj = Object.values(self)[i];
                                const self_amount = obj.amount;
                                const total_amount = parseFloat((self_amount + amount).toFixed(2));
                                self[self_name].amount = total_amount;
                            }
                        }
                    }
                    arr.push({ option:name, basic_pay: total_basic_pay, payable_wage: total_nett_salary, total_overtime, total_allowance, total_fixed, cpf_employee, cpf_employer, aggrid:test, total_employer, sdl:total_sdl, shg: self });
                }
            }

            return arr;
        }

        const arr = [];
        let lookup = {};
        if(select_option !== ''){
            for( const item of data1 ){
                const { contract_cache } = item.employee;
                if(contract_cache != null){
                    const name = contract_cache[select_option];
                    if(name != null && !(name in lookup)){
                        lookup[name] = 1;
                        arr.push({ name });
                    }
                }
            }
            const rowData = getData(arr);
            const bottomData = getBottomData(rowData);
            setRowData(rowData);
            setBottomData(bottomData);
        }

    },[select_option, data1, agheader, selflist  ])


    const generateExcel=()=>{
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('Payroll-Computation-Summary');
        const detail = payroll_option.find(item=>item.value === select_payroll);
        const payroll_name = detail?.display ?? '';

        let title = '';
        if(select_option === 'department'){
            title = 'Department';
        }
        else if(select_option === 'section'){
            title = 'Section'
        }
        else if(select_option === 'cost_center'){
            title = 'Cost-Center';
        }

        const today = moment().format('DD/MM/YYYY HH:mm:ss');
        ws.getCell('A1').value = `Payroll Computation Summary For ${payroll_name} - All Payments` ;
        ws.getCell('A2').value = `Printed On : ${today}`;

        const state = agRef.current.getColumnState();
        const column = [];

        for(const columnDef of columnDefs){
            const { field } = columnDef;
            const detail = state.find(item=>item.colId === field);
            const hide = detail?.hide ?? false;
            if(!hide){
                column.push(columnDef);
            }
        }

        ws.columns = column.map((item)=>{
            const {length} = item.headerName;
            if(item.headerName.length > 20){
                return { width:length };
            }
            return { width:20 }
        })

        for(let i= 0; i<column.length; i++){
            const { headerName, field, hide } = column[i];
            const length = rowData.length + 1;
            if(hide === false){
                ws.getCell(getColumnName(i)+'4').value = headerName;
                for( let x=0; x<length; x++){
                    const step = 5+x;
                    if( x < length -1){
                        const data = getValue(rowData[x],field);
                        if(field === 'option'){
                            ws.getCell(getColumnName(i)+step).value = data ?? '-';  
                        }
                        else{
                            ws.getCell(getColumnName(i)+step).value = data != null ? `$${data.toFixed(2)}` : '-'; 
                        }
                    }
                    else if( x === length -1){
                        const data = getValue(bottomData[0],field);
                        if(field === 'option'){
                            ws.getCell(getColumnName(i)+step).value = data;
                        }
                        else{
                            ws.getCell(getColumnName(i)+step).value = data != null ? `$${data.toFixed(2)}` : '-';
                        }
                    }
                }
            }
        }

        const filename = `PayrollComputationSummaryBy${title}.xlsx`;
        wb.xlsx.writeBuffer().then((buf)=>{
            const file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
            FileSaver.saveAs(file, filename)
        });
    }

    const columnDefs = useMemo(()=>{
        const pinnedRowCellRenderer = 'customPinnedRowRenderer';
        const newlist = [];
        if(select_option !== ''){
            if(select_option === 'department'){
                newlist.push({ headerName:'DEPARTMENT', field:'option', hide:false, editable:false, pinnedRowCellRenderer, width:150});
            }
            else if(select_option === 'section'){
                newlist.push({ headerName:'SECTION', field:'option', hide:false, editable:false, width:150});
            }
            else if(select_option === 'cost_center'){
                newlist.push({ headerName:'COST CENTER', field:'option', hide:false, editable:false, width:150});
            }
    
            newlist.push(
                { headerName:'GROSS SALARY', field:'basic_pay', width:180, editable:false, hide:false, pinnedRowCellRenderer},
                { headerName:'NETT SALARY', field:'payable_wage', width:180, editable:false, hide:false, pinnedRowCellRenderer},
                { headerName:'TOTAL OVERTIME', field:'total_overtime', width:180, editable:false, hide:false, pinnedRowCellRenderer},
                { headerName:'TOTAL ALLOWANCE', field:'total_allowance', width:180, editable:false, hide:false, pinnedRowCellRenderer},
                { headerName:'TOTAL FIXED RATE', field:'total_fixed', width:180, editable:false, hide:false, pinnedRowCellRenderer},    
            )

            if(agheader?.ag_grid != null){
                const { ag_grid } = agheader;
                for( const grid_item of ag_grid){
                    const headerName = grid_item.replace('-','.');
                    const field = `aggrid.${grid_item.replace('.','-')}.amount`;
                    const width = 120;
                    const hide = false;
                    newlist.push({ headerName, field, width, hide, pinnedRowCellRenderer});
                }
            }
            newlist.push({ headerName: 'CPF EMPLOYEE', field: 'cpf_employee', width: 180, hide: false, pinnedRowCellRenderer });

            for(const self of selflist){
                const headerName = self.value
                const field = `shg.${headerName}.amount`;
                const width = 120;
                const hide = false;
                newlist.push({ headerName, field, width, hide, pinnedRowCellRenderer});
            }

            newlist.push(
                { headerName: 'CPF EMPLOYER', field:'cpf_employer', width: 120, hide: false, pinnedRowCellRenderer },
                { headerName: 'SDL', field: 'sdl', width: 120, hide: false, pinnedRowCellRenderer  },
                { headerName: 'TOTAL EMPLOYER', field: 'total_employer', width: 180, hide: false, pinnedRowCellRenderer}
            )
 
        }
        return newlist;
    },[select_option,agheader,selflist])

    

    return(
        <>
            {isLoading || isLoading1 || isLoading2 ? <OverlayLoading/> :
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title: IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title: IMLocalized('payroll_computation_summary')}
                    ]}/>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col className="d-flex">
                    <div>
                        <Input type="select" size="sm" value={select_payroll} className="width-200px"
                        onChange={(e)=>{
                            const value = e.target.value;
                            setSelectPayroll(value);
                        }}>
                            <option value="">{IMLocalized('select_payroll')}</option>
                            {payroll_option.map((item)=>{
                                return <option value={item.value} key={item.value}>{item.display}</option>
                            })}
                        </Input>
                    </div>
                    {select_payroll !== '' && 
                    <div className="ml-2">
                        <Input type="select" size="sm" value={select_option}
                        onChange={(e)=>{
                            const value = e.target.value;
                            setSelectOption(value);
                        }}>
                            <option value="">{IMLocalized('select_option')}</option>
                            <option value="department">{IMLocalized('department')}</option>
                            <option value="section">{IMLocalized('section')}</option>
                            <option value="cost_center">{IMLocalized('cost_center')}</option>
                        </Input>
                    </div>}
                    {select_option !== '' && rowData.length !== 0 &&
                    <div className="ml-2">
                        <button onClick={generateExcel} className="btn btn-success btn-sm">{IMLocalized('export')}</button>
                    </div>}
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <div className="ag-theme-balham" style={{height:action_toggle ? 'calc(100vh - 260px)' : 'calc(100vh - 224px)', width:'100%' }}>
                        <AgGridReact
                        enableRangeSelection={true}
                        suppressDragLeaveHidesColumns={true}
                        columnDefs={columnDefs}
                        rowData={rowData}
                        gridOptions={gridOptions}
                        overlayNoRowsTemplate={IMLocalized('no_data')}
                        pinnedBottomRowData={bottomData}
                        onGridReady={(params)=>{
                            agRef.current = params.columnApi;
                        }}
                        frameworkComponents={
                            {
                            customPinnedRowRenderer:CustomPinnedRowRenderer
                        }}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
        </>}
        </>
    )
}