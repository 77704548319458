import * as appraisalgroup from '../../action/appraisal/create_appraisal_group';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const create_appraisal_group = (state=initialState,action)=>{
    switch(action.type){
        case appraisalgroup.CREATEAPPRAISALGROUP_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case appraisalgroup.CREATEAPPRAISALGROUP_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case appraisalgroup.CREATEAPPRAISALGROUP_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data,
            }
        default:
            return state
    }
} 
export default create_appraisal_group;