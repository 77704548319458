import myaxios from '../../axios';
import FormData from 'form-data';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEEMPPROFILEPICTURE_REQUEST = 'UPDATEEMPPROFILEPICTURE_REQUEST';
export const UPDATEEMPPROFILEPICTURE_SUCCESS = 'UPDATEEMPPROFILEPICTURE_SUCCESS';
export const UPDATEEMPPROFILEPICTURE_FAILURE = 'UPDATEEMPPROFILEPICTURE_FAILURE';


export const update_employee_profile_picture =(data1,data2)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEEMPPROFILEPICTURE_REQUEST'
        });
        
        const formData = new FormData();
        formData.append('picture',data2,data2.filename);
        formData.append('employee',data1)

        myaxios.post('/ob/employee/',formData,{headers: {'Content-Type': 'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'UPDATEEMPPROFILEPICTURE_SUCCESS',
                payload:response
            });
            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEEMPPROFILEPICTURE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}