import * as emp_pass from '../../action/employee/change_emp_password';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const change_emp_password = (state=initialState,action)=>{
    switch(action.type){
        case emp_pass.CHANGEEMPPASS_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case emp_pass.CHANGEEMPPASS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case emp_pass.CHANGEEMPPASS_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case emp_pass.CHANGEEMPPASS_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default change_emp_password;