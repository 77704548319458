import * as leavegroup from '../../action/leave/add_leave_group_template';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const add_leave_group_template = (state=initialState,action)=>{
    switch(action.type){
        case leavegroup.ADDLEAVEGROUPTEMPLATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case leavegroup.ADDLEAVEGROUPTEMPLATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case leavegroup.ADDLEAVEGROUPTEMPLATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case leavegroup.ADDLEAVEGROUPTEMPLATE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default add_leave_group_template;