import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const NEW_CREATEEMPPASSPORT_REQUEST = 'NEW_CREATEEMPPASSPORT_REQUEST';
export const NEW_CREATEEMPPASSPORT_SUCCESS = 'NEW_CREATEEMPPASSPORT_SUCCESS';
export const NEW_CREATEEMPPASSPORT_FAILURE = 'NEW_CREATEEMPPASSPORT_FAILURE';
export const NEW_CREATEEMPPASSPORT_RESET = 'NEW_CREATEEMPPASSPORT_RESET';


export const create_employee_passport = (data)=>{
    return dispatch=>{
        dispatch({
            type:'NEW_CREATEEMPPASSPORT_REQUEST'
        });

        if(data.length !== 0){
            for(let i=0;i<data.length;i++){
                const { passport, expiry_date, issued_date, employee } = data[i];
                myaxios.post('employee/passport/',{
                    employee:employee,
                    passport:passport,
                    expiry_date:expiry_date,
                    issued_date:issued_date
                })
                .then((response)=>{
                    if(i === data.length -1){
                        dispatch({
                            type:'NEW_CREATEEMPPASSPORT_SUCCESS',
                            payload:response
                        });
                        
                        Toast.fire({
                            type: 'success',
                            title: IMLocalized('create_success')
                        });
                    }
                    
                })
                .catch((error)=>{
                    dispatch({
                        type:'NEW_CREATEEMPPASSPORT_FAILURE',
                        payload:error
                    });

                    ErrToast.fire({
                        type:'error',
                        title:IMLocalized('create_failure')
                    });
                })
            }
        }
    }
}

export const reset_create_employee_passport = () => dispatch =>{
    dispatch({
        type:'NEW_CREATEEMPPASSPORT_RESET'
    });
}