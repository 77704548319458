import * as leave_detail from '../../action/user/user_leave_all_apply_detail';

const initialize={
    data:null,
    isLoading:false
}

const user_leave_all_apply_detail = (state=initialize,action)=>{
    switch(action.type){
        case leave_detail.USERLEAVEALLAPPLYDETAIL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case leave_detail.USERLEAVEALLAPPLYDETAIL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data,
            }
        case leave_detail.USERLEAVEALLAPPLYDETAIL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default user_leave_all_apply_detail;