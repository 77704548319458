import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATELEAVEAPPROVAL1_REQUEST = 'CREATELEAVEAPPROVAL1_REQUEST';
export const CREATELEAVEAPPROVAL1_SUCCESS = 'CREATELEAVEAPPROVAL1_SUCCESS';
export const CREATELEAVEAPPROVAL1_FAILURE = 'CREATELEAVEAPPROVAL1_FAILURE';
export const CREATELEAVEAPPROVAL1_RESET = 'CREATELEAVEAPPROVAL1_RESET';

export const create_leave_approval1 = (name,exceptions,approvers) =>{
    return dispatch=>{
        dispatch({
            type:'CREATELEAVEAPPROVAL1_REQUEST'
        });

        myaxios.post('leave/approval/',{
            name:name,
            exceptions:exceptions,
            approvers:approvers,
            start_date:null,
            approval_master:null,
        })
        .then((response)=>{
            dispatch({
                type:'CREATELEAVEAPPROVAL1_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type:'success',
                title:IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATELEAVEAPPROVAL1_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_leave_approval1 = () => dispatch =>{
    dispatch({
        type:'CREATELEAVEAPPROVAL1_RESET'
    });
}