import myaxios from '../axios';

export const GETSCHEMELIST_REQUEST = 'GETSCHEMELIST_REQUEST';
export const GETSCHEMELIST_SUCCESS = 'GETSCHEMELIST_SUCCESS';
export const GETSCHEMELIST_FAILURE = 'GETSCHEMELIST_FAILURE';

export const get_leave_scheme_list =()=>{
    return dispatch=>{
        dispatch({
            type:'GETSCHEMELIST_REQUEST'
        });

        myaxios.get('leave/scheme/')
        .then((response)=>{
            dispatch({
                type:'GETSCHEMELIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETSCHEMELIST_FAILURE',
                payload:error
            })
        })
    }
}