import React from 'react';
import '../../../css/employee_profile.css';
import UserEmployeeDetail from './UserEmployeeDetail';
import UserEmployeeAddress from './UserEmployeeAddress';
import UserEmployeePassport from './UserEmployeePassport';
import UserEmployeeShg from './UserEmployeeShg';
export default function UserEmployeeProfile(props){

    return(
        <>
        <UserEmployeeDetail/>
        <UserEmployeeAddress/>
        <UserEmployeePassport/>
        <UserEmployeeShg/>
        </>
    )
}
