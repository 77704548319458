import React,{Component} from 'react';
import {connect} from 'react-redux';
import { Container, Row, Col,Input, FormGroup, FormFeedback, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; 
import Swal from 'sweetalert2';
import {get_employee_list} from '../../action/employee/get_employee_list';
import {get_leave_employee_noscheme} from '../../action/leave/get_leave_employee_noscheme';
import {get_all_employee_available_leave} from '../../action/employee/get_all_employee_available_leave';
import {leave_assign_scheme} from '../../action/leave/leave_assign_scheme';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import moment from 'moment';
import {mass_assign_leave_approval} from '../../action/approvals/mass_assign_leave_approval';
import { IMLocalized } from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';


const gridOptions={
    singleClickEdit:true,
    defaultColDef:{
        sortable:true,
        filter:true,
        suppressMenu:true,
        resizable:true
    },
    sideBar:{
        toolPanels:[
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                
            }
        ],
        defaultToolPanel:''
    },
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
}

class leave_mass_scheme extends Component{

    constructor(props){
        super(props);
        this.state={
            noRowsTemplate:IMLocalized('no_data'),
            lodingtemplate:IMLocalized('loading'),
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowData:[],
            idlist:[],
            columnDefs:[],
            err_msg:[],
            err_toggle:false,
            step:0,
            select_employee:'',
            assignlist:[],
            leave_scheme:'',
            err_leave_scheme:'',
            select_year:'',
            err_select_year:'',
            full_proration:false,
            scheme_toggle:false,
        }
    }

    componentDidMount(){
        const {emplist,approvallist} = this.props;
        if(emplist.length !== 0 && approvallist.length !== 0){
            Promise.resolve(this.getDetail())
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.emplist !== this.props.emplist || prevProps.approvallist !== this.props.approvallist || prevState.step !== this.state.step){
            Promise.resolve(this.getDetail())
        }
        if(prevProps.errors !== this.props.errors){
            const {errors} = this.props;
            if(errors !== undefined){
                const data = errors.response.data;
                if('non_field_errors' in data){
                    const word = data['non_field_errors'].toString();
                    this.popError(word);
                }
                else if('approval_group' in data ){
                    const word = `${IMLocalized('approval_group')} :` +data['approval_group'].toString();
                    this.popError(word);
                }
            }
        }
        if(prevProps.update_errors !== this.props.update_errors){
            const {update_errors} = this.props;
            if(update_errors !== undefined){
                const data = update_errors.response.data;
                if('non_field_errors' in data){
                    const word = data['non_field_errors'].toString();
                    this.popError(word);
                    this.setState({scheme_step:1});
                }
            }
        }
        if(prevProps.daterange !== this.props.daterange){
            const {daterange} = this.props;
            if(daterange.length !== 0){
                const date_range = daterange.date_range;
                const leave = date_range.leave;
                const year = leave.year;
                this.setState({select_year:year});
            }
        }
        if(prevProps.success !== this.props.success){
            this.props.leave_assign_scheme(this.state.leave_scheme,this.state.idlist,this.state.full_proration,this.state.select_year);
        }
        if(prevProps.update_success !== this.props.update_success){
            Promise.resolve(this.setState({rowData:[]}))
            .then(this.setState({rowData2:[]}))
            .then(this.props.get_employee_list())
            .then(this.props.get_all_employee_available_leave())
            .then(this.clearContent())
            .then(this.setSchemeToggle())
        }
    }

    getDetail=()=>{
        const {emplist} = this.props;
        let arr = [];
        for(let i=0;i<emplist.length;i++){
            const item = emplist[i];
            const contract_cache = item.contract_cache;
            const name = item.name;
            const employee_number = item.employee_number;
            const metadata = item.metadata;
            const start_date = 'd/M/yy';
            const group = null;
            const err_group = false;
            const err_start_date = false;
            const id = item.id;
            const active = item.active;
            if(active){
                arr.push({name,contract_cache,employee_number,metadata,start_date,group,err_group,err_start_date,id});
            }
        }
        this.setState({rowData:arr});
        

        const {approvallist} = this.props;
        let approval_detail = [''];


        for(let i=0;i<approvallist.length ;i++){
            const name = approvallist[i].name;
            approval_detail.push(name)
        }

        let columnDefs=[
            {
                headerName:IMLocalized('agrid_select'),
                field:'',
                editable:false,
                width:120,
                lockPosition:true,
                pinned:'left',
                filter:false,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
            },
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee_number',
                lockPosition:true,
                pinned:'left',
                width:100,
                editable:false,
               
                filterParams:{
                    buttons:['clear']
                },
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'name',
                filterParams:{
                    buttons:['clear']
                },
                
                lockPosition:true,
                pinned:'left',
                width:150,
                editable:false,
            },
            {
                headerName:IMLocalized('agrid_company'),
                field:'metadata.company',
                filterParams:{
                    buttons:['clear']
                },
               
                lockPosition:true,
                pinned:'left',
                width:120,
                editable:false,
            },
            {
                headerName:IMLocalized('agrid_department'),
                field:'contract_cache.department',
                filterParams:{
                    buttons:['clear']
                },
               
                lockPosition:true,
                pinned:'left',
                width:120,
                editable:false,
            },
            {
                headerName:IMLocalized('agrid_section'),
                field:'contract_cache.section',
                filterParams:{
                    buttons:['clear']
                },
               
                lockPosition:true,
                pinned:'left',
                width:120,
                editable:false,
            },
            {
                headerName:IMLocalized('agrid_job_title'),
                field:'contract_cache.job_title',
                filterParams:{
                    buttons:['clear']
                },
               
                lockPosition:true,
                pinned:'left',
                width:120,
                editable:false,
            },
            {
                headerName:IMLocalized('agrid_job_level'),
                field:'contract_cache.job_level',
                filterParams:{
                    buttons:['clear']
                },
               
                lockPosition:true,
                pinned:'left',
                width:120,
                editable:false,
            },
            {
                headerName:IMLocalized('agrid_cost_center'),
                field:'contract_cache.cost_center',
                filterParams:{
                    buttons:['clear']
                },
                
                lockPosition:true,
                pinned:'left',
                width:120,
                editable:false,
            },
            {
                headerName:IMLocalized('agrid_current_leave_scheme'),
                field:'metadata.leave_scheme.name',
                width:200,
                editable:false,
            },
            {
                headerName:IMLocalized('agrid_start_date'),
                field:'start_date',
                editable:true,
                width:120,
                filter:false,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_start_date}
                },
            },
            {
                headerName:IMLocalized('agrid_approval_group'),
                field:'group',
                width:180,
                editable:true,
                cellEditor:'agSelectCellEditor',
                cellEditorParams:{
                    values:approval_detail
                },
                filter:false,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_group}
                },
            },
            
        ]
        this.setState({columnDefs})
        this.setState({err_msg:[]});
    }

    handleInput=(e)=>{
        const target = e.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]:value
        })

        if(name === 'select_year'){
            this.setState({err_select_year:''});
        }

        if(name === 'leave_scheme'){
            this.setState({err_leave_scheme:''});
        }
    }

    handleProration=(e)=>{
        const target = e.target;
        const checked = target.checked;
        const name = target.name;
        this.setState({
            [name]:checked
        })
    }

    clearData=()=>{
        const {step} = this.state;
        const step1 = step +1;
        Promise.resolve(this.setState({rowData:[]}))
        .then(()=>this.setState({step:step1}))
        
    }

    generateExcel=()=>{
        const {approvallist} = this.props;
        const rowData = gridOptions.api.getSelectedRows();
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('Leave Approval Group');
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');
        ws3.getCell('A1').value = 'LeaveApprovalGroup';
        ws.columns = [
            {header:IMLocalized('agrid_emp_id'),key:'employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'name'},
            {header:IMLocalized('agrid_start_date'),key:'start_date'},
            {header:IMLocalized('agrid_approval_group'),key:'group'}
        ];

        const columns = [
            {header:IMLocalized('agrid_emp_id'),key:'employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'name'},
            {header:IMLocalized('agrid_start_date'),key:'start_date'},
            {header:IMLocalized('agrid_approval_group'),key:'group'}
        ];

        for(let i =2;i<=2001;i++){
            ws.getCell('C'+i).numFmt = 'd/M/yy';
        }

        for(let i=0;i<approvallist.length ;i++){
            let x=i+1;
            ws2.getCell('A'+x).value = approvallist[i].name;
        }

        if(rowData.length !== 0){
            for(let i=0;i<columns.length;i++){
                const field = columns[i].key;
                for(let x =0;x<rowData.length;x++){
                    const step = 2+x;
                    const data = this.getValue(rowData[x],field);
                    ws.getCell(this.getColumnName(i)+step).value = data;
                    if(field === 'start_date'){
                        ws.getCell(this.getColumnName(i)+step).numFmt = 'd/M/yy';
                        ws.getCell(this.getColumnName(i)+step).value = null;
                    }
                    else if(field ==='group'){
                        if(approvallist.length !== 0){
                            ws.getCell(this.getColumnName(i)+step).dataValidation={
                                type:'list',
                                allowBlank:true,
                                formulae:['=setting!$A$1:$A$'+approvallist.length],
                                error:IMLocalized('excel_valid_in_list'),
                                errorStyle:'error',
                                errorTitle:'Error',
                                showErrorMessage:true,
                            };
                        }
                        ws.getCell(this.getColumnName(i)+step).value = null;
                    }
                    else{
                        ws.getCell(this.getColumnName(i)+step).value = data;
                    }
                }
            }

            ws.columns.forEach(column =>{
                column.width = column.header.length < 15 ? 15 : column.header.length
            });
    
    
            wb.xlsx.writeBuffer().then((buf)=>{
                
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, 'Leave_Approval_Template.xlsx')
            });
        }
        else{
            Swal.fire({
                title:IMLocalized('no_employee_selected'),
                type:'error',
                confirmButtonColor:'#d33',
                confirmButtonText:IMLocalized('ok!'),
            })
        }
    }

    getValue(object, path) {
        const parts = path.split(".");
        return parts.reduce((value, key) => value ? value[key] : value, object)
    }

    getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }

    isFirstColumn(params) {
        if(params !== null){
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }   
    }

    handleFile = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    uploadExcel=(e)=>{
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3];

            const columns = {
                'A':'employee_number',
                'B':'name',
                'C':'start_date',
                'D':'group',
            }

            let rowData1 = [];
            let rowIndex =2;
            let idd = '';
            if(ws3 !== undefined){
                if(ws3['A1'] !== undefined){
                    idd = ws3['A1'].w;
                }
            }

            if(idd === 'LeaveApprovalGroup'){
                while(ws['A'+rowIndex]){
                    let row ={};
                    for(let i =0;i<Object.keys(columns).length;i++){
                        const column = Object.keys(columns)[i];
                        const index = column + rowIndex;
                        if(ws[index] !== undefined){
                            row[columns[column]] = ws[index].w;
                        }
                        else{
                            row[columns[column]] = null;
                        }
                    }
                    rowData1.push(row);
                    rowIndex++;
                }
                Promise.resolve(this.getClearAggrid())
                .then(()=> this.getImportData(rowData1))
               
            }
            else{
                Swal.fire({
                    title:IMLocalized('wrong_file_selected'),
                    type:'error',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!'),
                })
            }
        }
        reader.readAsBinaryString(file)
    }

    getClearAggrid=()=>{
        gridOptions.api.forEachNode((rowNode,index)=>{
            rowNode.setSelected(false);
        })
    }

    getImportData=(data)=>{
        for(let i=0;i<data.length;i++){
            const employee_number = data[i].employee_number;
            const group = data[i].group;
            const start_date = data[i].start_date;
            gridOptions.api.forEachNode((rowNode,index)=>{
                const employee_number1 = rowNode.data.employee_number;
                let err_start_date = false;
                let err_group = false;
                if(employee_number1 === employee_number){
                    rowNode.data.group = group;
                    rowNode.data.start_date = start_date;
                    rowNode.data.err_group = err_group;
                    rowNode.data.err_start_date = err_start_date;
                    rowNode.setSelected(true);
                    gridOptions.api.refreshCells(rowNode)
                }
            })
        }
    }

    onAssign=()=>{
        this.setState({err_msg:[]});
        const rowData = gridOptions.api.getSelectedRows();
        const {approvallist} = this.props;
        let arr = [];
        let arr2= [];
        let idlist = [];
        if(rowData.length !== 0){
            for(let i=0;i<rowData.length;i++){
                const item = rowData[i];
                const group = item.group;
                const name = item.name;
                const number = item.employee_number;
                let approval_master = null;
                let approval_group = null;
                approvallist.forEach((data)=>{
                    if(data.name === group){
                        approval_master = data.approval_master;
                        approval_group = data.id;
                    }
                })

                const employee = item.id;
                let start_date = null;
                const date = item.start_date;
                if(date !== null && date !== '' && date !== 'd/M/yy' &&date !== undefined){
                    const date1 = moment(date,'dd/M/YY');
                    start_date = date1.format('YYYY-MM-DD');
                    if(start_date === 'Invalid date'){
                        arr2.push({employee,start_date,approval_group,name,number,approval_master});
                    }
                }
                else{
                    arr2.push({employee,start_date,approval_group,name,number,approval_master});
                }
                arr.push({employee,start_date,approval_group,name,number,approval_master});
                idlist.push(employee);
            }

            if(arr2.length !== 0){
                this.handleError(arr2);
            }
            else{
                this.setState({assignlist:arr});
                this.setState({idlist});
                this.setSchemeToggle();
                //this.props.mass_assign_leave_approval(arr);
            }
        }
        else{
            this.popError(IMLocalized('no_employee_selected'));
        }
    }

    onAssign2=()=>{
        this.setState({err_msg:[]});
        const {select_year,leave_scheme,scheme_step} = this.state;
        var date_regex = /^(19[5-9]\d|20[0-4]\d|2050)$/;
        let valid = false;
        if(select_year === ''){
            this.setState({err_select_year:IMLocalized('year_is_required')})
        }
        else if (!(date_regex.test(select_year))){
            this.setState({err_select_year:IMLocalized('invalid_year')})
        }
        else if ((date_regex.test(select_year))) {
           valid = true;
        }
        
        if(valid === true && leave_scheme !== ''){
            if(scheme_step === 1){
                this.onAssign4();
            }
            else{
                this.onAssign3();
            }
        }
        else{
            if(leave_scheme === ''){
                this.setState({err_leave_scheme:IMLocalized('leave_scheme_is_required')})
            }
        }
    }

    onAssign4=()=>{
        const {leave_scheme,idlist,full_proration,select_year} = this.state;
        this.props.leave_assign_scheme(leave_scheme,idlist,full_proration,select_year);
    }

    onAssign3=()=>{
        const {assignlist} = this.state;
        this.props.mass_assign_leave_approval(assignlist);
    }

    setSchemeToggle=()=>{
        const rowData = gridOptions.api.getSelectedRows();
        const toggle = !this.state.scheme_toggle;
        if(toggle === true){
            if(rowData.length !== 0){
                this.setState({scheme_toggle:toggle});
            }
            else{
                Swal.fire({
                    title:IMLocalized('no_employee_selected'),
                    type:'error',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!'),
                })
            }
        }
        else{
            this.setState({scheme_toggle:toggle});
        }
        
        
        this.setState({err_leave_scheme:''});
        this.setState({leave_scheme:''});
        this.setState({scheme_step:0});
        const {daterange} = this.props;
        if(daterange.length !== 0){
            const date_range = daterange.date_range;
            const leave = date_range.leave;
            const year = leave.year;
            this.setState({select_year:year});
            
        }
        this.setState({full_proration:false});

    }

    clearContent=()=>{
        this.setState({idlist:[]});
        this.setState({err_msg:[]})
        
    }

    handleError=(data)=>{
        let arr = [];
        for(let i=0;i<data.length;i++){
            const date = data[i].start_date;
            const approval_group = data[i].approval_group;
            const name = data[i].name;
            const number = data[i].number;
            const id = data[i].employee;

            let arr1 = [];

            if(date === 'Invalid date'){
                arr1.push(
                    <div>
                        <span>● {IMLocalized('start_date')} : {IMLocalized('invalid_date')}</span>
                    </div>
                )

                gridOptions.api.forEachNode((rowNode,index)=>{
                    if(rowNode.data.id === id){
                        rowNode.data.err_start_date = true;
                        gridOptions.api.refreshCells(rowNode)
                    }
                })
            }
            else if (date === null){
                arr1.push(
                    <div>
                        <span>● {IMLocalized('start_date_is_required')}</span>
                    </div>
                )

                gridOptions.api.forEachNode((rowNode,index)=>{
                    if(rowNode.data.id === id){
                        rowNode.data.err_start_date = true;
                        gridOptions.api.refreshCells(rowNode)
                    }
                })
            }
            if(approval_group === null){
                arr1.push(
                    <div>
                        <span>● {IMLocalized('approval_group_is_required')}</span>
                    </div>
                )

                gridOptions.api.forEachNode((rowNode,index)=>{
                    if(rowNode.data.id === id){
                        rowNode.data.err_group = true;
                        gridOptions.api.refreshCells(rowNode)
                    }
                })
            }
            if(arr1.length !== 0){
                arr.push(<tr><td>{number}</td><td>{name}</td><td>{arr1}</td></tr>)

            }
        }
        this.setState({err_msg:arr});
        this.setState({err_toggle:true});
    }

    popError=(msg)=>{
        Swal.fire({
            title:msg,
            type:'warning',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('ok!')
        })
    }

    onCellValueChanged=(params)=>{
        if(params.colDef.field === 'start_date'){
            const focusedCell = gridOptions.api.getFocusedCell();
            gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                if(index === focusedCell.rowIndex){
                    rowNode.setSelected(true);
                }
            })
            params.data.err_start_date = false;
        }
        else if (params.colDef.field === 'group'){
            const focusedCell = gridOptions.api.getFocusedCell();
            gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                if(index === focusedCell.rowIndex){
                    rowNode.setSelected(true);
                }
            })
            params.data.err_group = false;
        }
        params.api.refreshCells();
    }

    onCellFocused=(params)=>{
        if(params !== null){
            if(params.column !== null){
                if(params.column.colDef !== null){
                    if(params.column.colDef.field ==='group' || params.column.colDef.field ==='start_date'){
                        
                        params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = true;
                    }
                    else{
                        params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = false;
                    }
                }   
            }
        }
    }

    closeError=()=>{
        const toggle = !this.state.err_toggle;
        this.setState({err_toggle:toggle});
    }

    toHome=()=>{
        this.context.addItem('1','dashboard');
    }

    render(){

        const {err_msg} = this.state;
        let err_btn ='';
        if(err_msg.length !== 0){
            err_btn = <button className="btn btn-danger btn-sm" onClick={this.closeError}>{IMLocalized('error')}</button>
        }

        let assign_btn =<button className="btn btn-primary btn-sm" onClick={this.onAssign}>{IMLocalized('assign')}</button>;
        
        if(this.props.isLoading3){
            assign_btn=<button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>
        }

        

        const {action_toggle}  = this.context;
        let currentHeight = 'calc(100vh - 260px)';
        if(action_toggle === false){
            currentHeight = 'calc(100vh - 224px)';
        }

        const {schemelist} = this.props;
        const scheme_option = schemelist.map((item,index)=>{
            return <option value={item.id} key={index}>{item.name}</option>
        })

        let btn1=(
            <div>
                <button className="btn btn-primary btn-sm" onClick={this.onAssign2}>{IMLocalized('assign')}</button>
                <button className="btn btn-danger btn-sm" onClick={this.setSchemeToggle}>{IMLocalized('close')}</button>
            </div>
        )

        if(this.props.isLoading3 ||this.props.isLoading4){
            btn1 =<button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>;
        }

        return(
            <div>
                {this.props.isLoading || this.props.isLoading1 || this.props.isLoading2 ? <OverlayLoading/>:
                <div>
                    <Container fluid>
                        <Row className="bg-white border-bottom">
                            <Col className="p-2">
                                <BreadCrumbList list={[
                                    { active: false, title:IMLocalized('home'), onClick:()=>this.toHome()},
                                    { active: true, title:IMLocalized('mass_assign_leave_approval_group')}
                                ]}
                                />
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col>
                                <div className="d-flex">
                                    <div>
                                        <button className="btn btn-primary btn-sm" onClick={this.generateExcel}>{IMLocalized('get_template')}</button>
                                    </div>
                                    <div className="ml-2 mr-2">
                                            <label className="btn btn-primary btn-sm" htmlFor="massleavescheme_file">{IMLocalized('import_from_file')}</label>
                                    </div>
                                    <div className="ml-2">
                                            <button onClick={this.clearData} className="btn btn-primary btn-sm">{IMLocalized('clear')}</button>
                                            {assign_btn}
                                            {err_btn}
                                            
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <div className="ag-theme-balham" style={{height:currentHeight, width:'100%' }}>
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            overlayNoRowsTemplate={this.state.noRowsTemplate}
                                            rowData={this.state.rowData}
                                            overlayLoadingTemplate={this.state.lodingtemplate}
                                            suppressDragLeaveHidesColumns={true}
                                            loadingCellRenderer={this.state.loadingCellRenderer}
                                            loadingCellRendererParams={this.state.loadingCellRendererParams}
                                            gridOptions={gridOptions}
                                            onCellFocused={this.onCellFocused}
                                            onCellValueChanged={this.onCellValueChanged}
                                            stopEditingWhenCellsLoseFocus={true}
                                            >
                                        </AgGridReact>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <input type="file" style={{display:'none'}} id="massleavescheme_file" className="custom-file-input form-control-sm" accept=".xlsx"  onChange={this.uploadExcel} onClick={this.handleFile} />
                    </Container>
                    <Modal isOpen={this.state.err_toggle} size="lg">
                        <ModalHeader toggle={this.closeError}>{IMLocalized('error_msg')}</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <Table size="sm" className="table-bordered align-items-center">
                                        <thead>
                                            <tr>
                                                <th>{IMLocalized('employee_number')}</th>
                                                <th>{IMLocalized('name')}</th>
                                                <th>{IMLocalized('error')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.err_msg}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-danger btn-sm" onClick={this.closeError}>{IMLocalized('close')}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.scheme_toggle} size="md">
                        <ModalHeader toggle={this.setSchemeToggle}>{IMLocalized('select_leave_scheme')}</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label className="form-control-label">{IMLocalized('leave_scheme')}</label>
                                        <Input type="select" size="sm" value={this.state.leave_scheme} name="leave_scheme" onChange={this.handleInput} invalid={this.state.err_leave_scheme.length >0}>
                                            <option></option>
                                            {scheme_option}
                                        </Input>
                                        <FormFeedback>{this.state.err_leave_scheme}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label className="form-control-label">{IMLocalized('year')}</label>
                                        <Input type="text" size="sm" value={this.state.select_year} name="select_year" onChange={this.handleInput} invalid={this.state.err_select_year.length >0}/>
                                        <FormFeedback>{this.state.err_select_year}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id={'emp5proration'} name="full_proration" className="custom-control-input" checked={this.state.full_proration} onChange={this.handleProration} />
                                            <label className="custom-control-label" htmlFor={'emp5proration'}>{IMLocalized('full_proration')}</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            {btn1}
                        </ModalFooter>
                    </Modal>
                </div>}
            </div>
        )
    }
}
function mapStateToProps(state,ownProps){
    return{
        daterange:state.general_daterange.data,
        emplist:state.get_employee_list.data,
        schemelist:state.get_leave_scheme_list.data,
        isLoading:state.get_employee_list.isLoading,
        isLoading1:state.get_leave_scheme_list.isLoading,
        isLoading3:state.leave_assign_scheme.isLoading,
        isLoading2:state.get_leave_approval.isLoading,
        isLoading4:state.mass_assign_leave_approval.isLoading,
        update_success:state.leave_assign_scheme.data,
        update_errors:state.leave_assign_scheme.errors,
        approvallist:state.get_leave_approval.data,
        success:state.mass_assign_leave_approval.data,
        errors:state.mass_assign_leave_approval.errors,
    }
}

const mapDispatchToProps =(dispatch)=>{
    return{
        leave_assign_scheme:(scheme,employees,full_proration,year)=>{
            dispatch(leave_assign_scheme(scheme,employees,full_proration,year))
        },
        get_all_employee_available_leave:()=>{
            dispatch(get_all_employee_available_leave())
        },
        get_leave_employee_noscheme:()=>{
            dispatch(get_leave_employee_noscheme())
        },
        get_employee_list:()=>{
            dispatch(get_employee_list())
        },
        mass_assign_leave_approval:(data)=>{
            dispatch(mass_assign_leave_approval(data))
        },
    }
}
leave_mass_scheme.contextType = NewMainContext;
export default connect(mapStateToProps,mapDispatchToProps)(leave_mass_scheme);