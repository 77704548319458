import myaxios from '../../axios';

export const FILTERLEAVERESETLIST_REQUEST = 'FILTERLEAVERESETLIST_REQUEST';
export const FILTERLEAVERESETLIST_SUCCESS = 'FILTERLEAVERESETLIST_SUCCESS';
export const FILTERLEAVERESETLIST_FAILURE = 'FILTERLEAVERESETLIST_FAILURE';
export const FILTERLEAVERESETLIST_RESET = 'FILTERLEAVERESETLIST_RESET'

export const filter_leave_reset_list = (id)=>{
    return dispatch=>{
        dispatch({
            type:'FILTERLEAVERESETLIST_REQUEST'
        });

        myaxios.get(`leave/reset/${id}/`)
        .then((response)=>{
            dispatch({
                type:'FILTERLEAVERESETLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'FILTERLEAVERESETLIST_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_filter_leave_reset_list = () => dispatch =>{
    dispatch({
        type:'FILTERLEAVERESETLIST_RESET'
    });
}