import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const UPDATEHOLIDAYLIST_REQUEST = 'UPDATEHOLIDAYLIST_REQUEST';
export const UPDATEHOLIDAYLIST_SUCCESS = 'UPDATEHOLIDAYLIST_SUCCESS';
export const UPDATEHOLIDAYLIST_FAILURE = 'UPDATEHOLIDAYLIST_FAILURE';
export const UPDATEHOLIDAYLIST_RESET = 'UPDATEHOLIDAYLIST_RESET';



export const update_holiday_list = (id,name)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEHOLIDAYLIST_REQUEST'
        })

        myaxios.patch(`contract/holidaylist/${id}/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEHOLIDAYLIST_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEHOLIDAYLIST_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            })
        })
    }
}

export const reset_update_holiday_list = () => dispatch =>{
    dispatch({
        type:'UPDATEHOLIDAYLIST_RESET'
    });
}