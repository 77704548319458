import React, { useEffect, useState } from 'react';
import { Modal ,ModalBody,ModalFooter,ModalHeader,Row,Col,Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { getDateFormat } from '../../../../func/getDateFormat';
import { connect } from 'react-redux';
import { employee_contract_additional_cost } from '../../../../action/employee/employee_contract_additional_cost';

const mapStateToProps = (state)=>({
    contract_typelist:state.get_contract_template_dropdown.contract_type,
    contract_ratelist:state.get_contract_template_dropdown.contract_rate,
    schedulelist:state.schedule_list.data,
    recurring_list:state.add.recurringallowance,
    additionalcost:state.employee_contract_additional_cost.data,
})

const mapDispatchToProps = (dispatch)=>({
    employee_contract_additional_cost:(id,totalpay)=>{
        dispatch(employee_contract_additional_cost(id,totalpay))
    },
})

function ModalViewContract(props){
    const {employee_contract_additional_cost} = props;
    const [total_pay,setTotal] = useState(0); 
    const [additional,setAdditional] = useState(null);

    useEffect(()=>{
        if(props.toggle){
            let recurring_amount = 0;
            if(props.detail.recurring_allowances.length !== 0){
                props.detail.recurring_allowances.forEach(function(item){
                    const item1 = props.recurring_list.find((data)=>data.name === item);
                    if(item1){
                        recurring_amount += item1.amount; 
                    }
                })
            }
            const total = props.detail.pay + recurring_amount;
            setTotal(total);
            employee_contract_additional_cost(props.id,total);
        }
        else{
            setTotal(0);
        }
    },[props.toggle,props.detail.pay,props.detail.recurring_allowances,props.recurring_list,employee_contract_additional_cost,props.id])

    useEffect(()=>{
        if(props.toggle && props.additionalcost){
            setAdditional(props.additionalcost);
        }
        else{
            setAdditional(null);
        }
    },[props.additionalcost,props.toggle])

    let type = '---';
    if(props.detail.contract_type){
        const item = props.contract_typelist.find((item)=>item.value === props.detail.contract_type);
        if(item){
            type = item.display;
        }
    }

    let rate = '---';
    if(props.detail.contract_rate){
        const item = props.contract_ratelist.find((item)=>item.value === props.detail.contract_rate);
        if(item){
            rate = item.display;
        }
    }
    
    let schedule = '---';
    if(props.detail.wschedule){
        const item = props.schedulelist.find((item)=>item.id === props.detail.wschedule);
        if(item){
            schedule = item.name;
        }
    }

    return(
        <Modal size="lg" isOpen={props.toggle} className="modal-dialog-centered" style={{maxWidth: '1600px', width: '70%'}}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('view_contract')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="8">
                        <div>
                            <h4>{IMLocalized('contract_detail')}</h4>
                        </div>
                        <Table size="sm" className="table-bordered">
                            <tbody>
                                <tr>
                                    <td className="w-25">{IMLocalized('start_date')}</td>
                                    <td>{getDateFormat(props.detail.start_date)}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('contract_type')}</td>
                                    <td>{type}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('contract_rate')}</td>
                                    <td>{rate}</td>
                                </tr>
                                {props.detail.contract_rate === 'MONTHLY' && 
                                <tr>
                                    <td className="w-25">{IMLocalized('basic_pay')}</td>
                                    <td>$ {props.detail.pay}</td>
                                </tr>
                                }
                                <tr>
                                    <td className="w-25">{IMLocalized('is_workman')}</td>
                                    <td>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id={'hrempisworkman'} className="custom-control-input" name="is_workman" checked={props.detail.is_workman} readOnly/>
                                            <label className="custom-control-label" htmlFor={'hrempisworkman'}></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('schedule')}</td>
                                    <td>{schedule}</td>
                                </tr>
                                {props.detail.contract_rate === 'HOURLY' &&
                                <>
                                <tr>
                                    <td className="w-25">{IMLocalized('hourly_rate')}</td>
                                    <td>{props.detail.hourly_rate}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('hours_per_day')}</td>
                                    <td>{props.detail.hours_day}</td>
                                </tr>
                                </>
                                }
                                {props.detail.contract_rate === 'DAILY' &&
                                <tr>
                                    <td className="w-25">{IMLocalized('daily_rate')}</td>
                                    <td>{props.detail.daily_rate}</td>
                                </tr>}
                                {props.detail.contract_rate === 'MONTHLY' &&
                                <>
                                    <tr>
                                        <td className="w-25">{IMLocalized('hourly_rate')}</td>
                                        <td>{props.detail.hourly_rate}</td>
                                    </tr>
                                    <tr>
                                        <td className="w-25">{IMLocalized('daily_rate')}</td>
                                        <td>{props.detail.daily_rate}</td>
                                    </tr>
                                </>}
                                <tr>
                                    <td className="w-25">{IMLocalized('foreign_currency')}</td>
                                    <td>{props.detail.currency ? props.detail.currency.currency_name : '---'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('department')}</td>
                                    <td>{props.detail.department ? props.detail.department : '---'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('section')}</td>
                                    <td>{props.detail.section ? props.detail.section : '---'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('cost_center')}</td>
                                    <td>{props.detail.cost_center ? props.detail.cost_center : '---'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('job_title')}</td>
                                    <td>{props.detail.job_title ? props.detail.job_title : '---'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('job_level')}</td>
                                    <td>{props.detail.job_level ? props.detail.job_level : '---'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('allowance')}</td>
                                    <td>
                                        {props.detail.allowances.length > 0 ?
                                        props.detail.allowances.map((item)=>{
                                            return <div><span className='mr-2'>●</span>{item}</div>
                                        }): '---'}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('recurring_allowance')}</td>
                                    <td>
                                        {props.detail.recurring_allowances.length > 0 ?
                                        props.detail.recurring_allowances.map((item)=>{
                                            return <div><span className='mr-2'>●</span>{item}</div>
                                        }): '---'}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('note')}</td>
                                    <td>{props.detail.notes ? props.detail.notes : '---'} </td>
                                </tr>
                            </tbody>
                        </Table>
                        {props.detail.payinc.length > 0 &&
                        <>
                        <div><h4>{IMLocalized('increment')}</h4></div>
                        <Table size="sm" className="table-bordered">
                            <thead>
                                <tr>
                                    <td>{IMLocalized('effective_date')}</td>
                                    <td>{IMLocalized('old_amount')}</td>
                                    <td>{IMLocalized('new_amount')}</td>
                                    <td>{IMLocalized('amount')}</td>
                                    <td>{IMLocalized('percentage')} (%)</td>
                                </tr>
                            </thead>
                            <tbody>
                                {props.detail.payinc.map((item)=>{
                                    return(
                                        <tr>
                                            <td>
                                                {getDateFormat(item.effective_date)}
                                            </td>
                                            <td>{item.old_amount}</td>
                                            <td>{item.new_amount}</td>
                                            <td>{item.amount}</td>
                                            <td>{item.percent}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        </>    
                        }
                    </Col>
                    <Col lg="4">
                        <div><h4>{IMLocalized('summary')}</h4></div>
                        <Table className="table-bordered" size="sm">
                            <tbody>
                                <tr>
                                    <td>{IMLocalized('cost_center')}</td>
                                    <td>{props.detail.cost_center ? props.detail.cost_center : '---'}</td>
                                </tr>
                                <tr>
                                    <td>{IMLocalized('gross_salary')}</td>
                                    <td>$ {total_pay}</td>
                                </tr>
                                {additional && 'total' in additional && 
                                <tr>
                                    <td>{IMLocalized('annual_salary')}</td>
                                    <td>$ { (parseFloat(additional.total)*12).toFixed(2)}</td>
                                </tr>
                                }
                                {additional && 'cpf_employer' in additional && 
                                <tr>
                                    <td>Employer CPF</td>
                                    <td>$ { (parseFloat(additional.cpf_employer)*12).toFixed(2)}</td>
                                </tr>
                                }
                                {additional && 'sdl' in additional && 
                                <tr>
                                    <td>SDL</td>
                                    <td>$ { (parseFloat(additional.sdl)*12).toFixed(2)}</td>
                                </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button onClick={props.saveAsTemplate}  className="btn btn-success btn-sm">{IMLocalized('copy_template')}</button>
                <button onClick={props.closeModal}  className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalViewContract);