import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const NEW_UPDATEEMPADDRESS_REQUEST = 'NEW_UPDATEEMPADDRESS_REQUEST';
export const NEW_UPDATEEMPADDRESS_SUCCESS = 'NEW_UPDATEEMPADDRESS_SUCCESS';
export const NEW_UPDATEEMPADDRESS_FAILURE = 'NEW_UPDATEEMPADDRESS_FAILURE';
export const NEW_UPDATEEMPADDRESS_RESET = 'NEW_UPDATEEMPADDRESS_RESET';

export const update_employee_address = (data)=>{
    return dispatch=>{
        dispatch({
            type:'NEW_UPDATEEMPADDRESS_REQUEST'
        });

        if(data.length !== 0){
            for(let i =0;i<data.length;i++){

                const {id, address, block_number, country_of_residence, level_number, sg_postal_code, street_name, unit_number, postal_code} = data[i];
                
                myaxios.patch(`employee/address/${id}/`,{
                    address:address,
                    block_number:block_number,
                    country_of_residence:country_of_residence,
                    level_number:level_number,
                    sg_postal_code:sg_postal_code,
                    street_name:street_name,
                    unit_number:unit_number,
                    postal_code:postal_code
                })
                .then((response)=>{
                    dispatch({
                        type:'NEW_UPDATEEMPADDRESS_SUCCESS',
                        payload:response
                    });
                    
                    Toast.fire({
                        type: 'success',
                        title: IMLocalized('update_success')
                    });
                })
                .catch((error)=>{
                    dispatch({
                        type:'NEW_UPDATEEMPADDRESS_FAILURE',
                        payload:error
                    });
                    
                    ErrToast.fire({
                        type:'error',
                        title:IMLocalized('update_failure')
                    });
                })
            }
        }
    }
}

export const reset_update_employee_address = () => dispatch =>{
    dispatch({
        type:'NEW_UPDATEEMPADDRESS_RESET'
    });
}