import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATESELFPASSWORD_REQUEST = 'UPDATESELFPASSWORD_REQUEST';
export const UPDATESELFPASSWORD_SUCCESS = 'UPDATESELFPASSWORD_SUCCESS';
export const UPDATESELFPASSWORD_FAILURE = 'UPDATESELFPASSWORD_FAILURE';

export const update_self_password = (password,new_password) =>{
    return dispatch=>{
        dispatch({
            type:'UPDATESELFPASSWORD_REQUEST'
        });
    
        myaxios.post('otp/password/self/',{
            current_password:password,
            password:new_password
        })
        .then((response)=>{
            dispatch({
                type:'UPDATESELFPASSWORD_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATESELFPASSWORD_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}