import * as tasklist from '../../action/task/get_task_list';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const get_task_list = (state=initialState,action)=>{
    switch(action.type){
        case tasklist.GETTASKLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case tasklist.GETTASKLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case tasklist.GETTASKLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_task_list;