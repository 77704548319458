import * as submit from '../../action/appraisal/submit_appraisal';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const submit_appraisal = (state=initialState,action)=>{
    switch(action.type){
        case submit.SUBMITAPPRAISAL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case submit.SUBMITAPPRAISAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case submit.SUBMITAPPRAISAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case submit.SUBMITAPPRAISAL_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default submit_appraisal;