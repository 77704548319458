import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get_employee_contract_status } from '../../../action/employee/get_employee_contract_status';
import { Table} from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { getDateFormat } from '../../../func/getDateFormat';
import UserModalViewContract from './modal/UserModalViewContract';


const mapStateToProps =(state)=>({
    isLoading:state.get_employee_contract_status.isLoading,
    list:state.get_employee_contract_status.data,

})

const mapDispatchToProps=(dispatch)=>({
    get_employee_contract_status:(id)=>{
        dispatch(get_employee_contract_status(id));
    }
})

function UserEmployeeContract(props){
    const {get_employee_contract_status} = props;

    const [toggle,setToggle] = useState(false);
    const [view_detail,setViewDetail] = useState(null);


    useEffect(()=>{
        if(props.id != null ){
            get_employee_contract_status(props.id);
        }
    },[props.id,get_employee_contract_status])


    const toView=(name,index)=>{
        setToggle(true);
        let data;
        if(name === 'active' || name === 'upcoming'){
            data = props.list[name];
        }
        else{
            data = props.list[name][index];
        }
        setViewDetail(data);
    }

    return(
        <>
        <div className='d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title'>
            <div className='d-flex align-items-center'>
                <i className='fas fa-money-check-alt text-primary' />
                <h4 className='text-primary m-0 pl-2'>{IMLocalized('employee_contract')}</h4>
            </div>
        </div>
        {props.list.length === 0 &&
        <div className='p-2'>
            <h4>{IMLocalized('no_data')}</h4>
        </div>}
        {props.list.length !== 0 && props.list?.active != null &&
        <div className='p-2'>
            <h4>{IMLocalized('current_contract')}</h4>
            <Table className='table-bordered align-items-center' size="sm" responsive>
                <thead className='text-center thead-light'>
                    <tr>
                        <th className='w-25'>{IMLocalized('start_date')}</th>
                        <th className='w-25'>{IMLocalized('job_level')}</th>
                        <th className='w-25'>{IMLocalized('job_title')}</th>
                        <th className='w-25'>{IMLocalized('view')}</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    <tr>
                        <td>{getDateFormat(props.list.active.start_date)}</td>
                        <td>{props.list.active.job_level ?? '-'}</td>
                        <td>{props.list.active.job_title ?? '-'}</td>
                        <td align='center' valign='center'>
                            <div className='d-flex justify-content-center'>
                                <div className='primary_btn d-flex align-items-center justify-content-center'>
                                    <i className='far fa-eye text-primary cursor-pointer' onClick={()=>toView('active')} />
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>}
        {props.list.length !== 0 && props.list?.upcoming != null &&
        <div className='p-2'>
            <h4>{IMLocalized('upcoming_contract')}</h4>
            <Table className='table-bordered align-items-center' size="sm" responsive>
                <thead className='text-center thead-light'>
                    <tr>
                        <th className='w-25'>{IMLocalized('start_date')}</th>
                        <th className='w-25'>{IMLocalized('job_level')}</th>
                        <th className='w-25'>{IMLocalized('job_title')}</th>
                        <th>{IMLocalized('view')} </th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    <tr>
                        <td>{getDateFormat(props.list.upcoming.start_date)}</td>
                        <td>{props.list.upcoming.job_level ??  '-'}</td>
                        <td>{props.list.upcoming.job_title ?? '-'}</td>
                        <td align='center' valign='center'>
                            <div className='d-flex justify-content-center'>
                                <div className="primary_btn d-flex align-items-center justify-content-center">
                                    <i className="far fa-eye text-primary cursor-pointer" onClick={()=>toView('upcoming')} />
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>}
        {props.list.length !== 0 && props.list?.history != null && props.list.history.length !== 0 &&
        <div className='p-2'>
            <h4>{IMLocalized('previous_contract')}</h4>
            <Table className='table-bordered align-items-center' size="sm" responsive>
                <thead className='text-center thead-light'>
                    <tr>
                        <th className="w-25">{IMLocalized('start_date')}</th>
                        <th className="w-25">{IMLocalized('job_level')}</th>
                        <th className="w-25">{IMLocalized('job_title')}</th>
                        <th>{IMLocalized('view')}</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {props.list.history.map((item,index)=>{
                        return(
                            <tr key={index}>
                                <td>{getDateFormat(item.start_date)}</td>
                                <td>{item.job_level??'-'}</td>
                                <td>{item.job_title ??'-'}</td>
                                <td align='center' valign='center'>
                                    <div className='d-flex justify-content-center'>
                                        <div className="primary_btn d-flex align-items-center justify-content-center">
                                            <i className="far fa-eye text-primary cursor-pointer" onClick={()=>toView('history',index)}/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>
        }
        <UserModalViewContract toggle={toggle} closeModal={()=>{setToggle(!toggle); setViewDetail(null)}} detail={view_detail} />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(UserEmployeeContract);