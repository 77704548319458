import * as resign from '../../action/employee/get_employee_resign_today';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const get_employee_resign_today = (state=initialState,action)=>{
    switch(action.type){
        case resign.GETEMPLOYEERESIGNTODAY_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[],
            }
        case resign.GETEMPLOYEERESIGNTODAY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case resign.GETEMPLOYEERESIGNTODAY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_resign_today;