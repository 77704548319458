import * as createpermit from '../../action/employee/create_new_employee_permit';

const initialState={
    isLoading:false,
    errors:[],
    data:[]
}

const create_new_employee_permit =(state=initialState,action)=>{
    switch(action.type){
        case createpermit.CREATEPERMIT_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case createpermit.CREATEPERMIT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                errors:[],
                data:action.payload
            }
        case createpermit.CREATEPERMIT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default create_new_employee_permit;