import myaxios from '../../axios';

export const CLAIMAPPROVALSGETCLAIMPICTURE_REQUEST = 'CLAIMAPPROVALSGETCLAIMPICTURE_REQUEST';
export const CLAIMAPPROVALSGETCLAIMPICTURE_SUCCESS = 'CLAIMAPPROVALSGETCLAIMPICTURE_SUCCESS';
export const CLAIMAPPROVALSGETCLAIMPICTURE_FAILURE = 'CLAIMAPPROVALSGETCLAIMPICTURE_FAILURE';
export const CLAIMAPPROVALSGETCLAIMPICTURE_RESET = 'CLAIMAPPROVALSGETCLAIMPICTURE_RESET';

export const claim_approvals_get_claim_picture = (id)=>{
    return dispatch =>{
        dispatch({
            type:'CLAIMAPPROVALSGETCLAIMPICTURE_REQUEST'
        });

        myaxios.get(`ob/claim/${id}/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'CLAIMAPPROVALSGETCLAIMPICTURE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'CLAIMAPPROVALSGETCLAIMPICTURE_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_claim_approvals_get_claim_picture = () => dispatch=>{
    dispatch({
        type:'CLAIMAPPROVALSGETCLAIMPICTURE_RESET'
    });
    
} 
