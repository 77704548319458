import * as contractsection from '../../action/employee/employee_contract_section';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const employee_contract_section = (state=initialState,action)=>{
    switch(action.type){
        case contractsection.EMPCONTRACTSECTION_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case contractsection.EMPCONTRACTSECTION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractsection.EMPCONTRACTSECTION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_contract_section;