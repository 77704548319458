import myaxios from '../../axios';


export const GETEMPLEAVESCHEME_REQUEST = 'GETEMPLEAVESCHEME_REQUEST';
export const GETEMPLEAVESCHEME_SUCCESS = 'GETEMPLEAVESCHEME_SUCCESS';
export const GETEMPLEAVESCHEME_FAILURE = 'GETEMPLEAVESCHEME_FAILURE';


export const get_employee_leave_scheme =()=>{
    return dispatch=>{
        dispatch({
            type:'GETEMPLEAVESCHEME_REQUEST'
        })

        myaxios.get('leave/current/scheme/')
        .then((response)=>{
            dispatch({
                type:'GETEMPLEAVESCHEME_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPLEAVESCHEME_FAILURE',
                payload:error
            })
        })
    }
}