import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const WIZARDVALIDATEOVERTIME_REQUEST ='WIZARDVALIDATEOVERTIME_REQUEST';
export const WIZARDVALIDATEOVERTIME_SUCCESS ='WIZARDVALIDATEOVERTIME_SUCCESS';
export const WIZARDVALIDATEOVERTIME_FAILURE ='WIZARDVALIDATEOVERTIME_FAILURE';


export const wizard_validate_overtime = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDVALIDATEOVERTIME_REQUEST'
        });

        myaxios.post('company/overtime/array_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDVALIDATEOVERTIME_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDVALIDATEOVERTIME_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('validate_failure')
            });
        })
    }
}