import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CALENDARMANAGERAPPROVE_REQUEST = 'CALENDARMANAGERAPPROVE_REQUEST';
export const CALENDARMANAGERAPPROVE_SUCCESS = 'CALENDARMANAGERAPPROVE_SUCCESS';
export const CALENDARMANAGERAPPROVE_FAILURE = 'CALENDARMANAGERAPPROVE_FAILURE';


export const calendar_leave_manager_approve = (id,approve,comment)=>{
    return dispatch=>{
        dispatch({
            type:'CALENDARMANAGERAPPROVE_REQUEST'
        });


        const swal = approve ?  IMLocalized('approve_success') : IMLocalized('reject_success');
        const err_swal = approve ? IMLocalized('approve_failure') : IMLocalized('reject_failure');

        myaxios.post('leave/manager/approve/',{
            approvals:id,
            approve:approve,
            comment:comment
        })
        .then((response)=>{
            dispatch({
                type:'CALENDARMANAGERAPPROVE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: swal
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CALENDARMANAGERAPPROVE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:err_swal,
            });
        })
    }
}