import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import Swal from "sweetalert2";
import { delete_appraisal_group } from "../../action/appraisal/delete_appraisal_group";
import { get_appraisal_group_list } from "../../action/appraisal/get_appraisal_group_list";
import { usePrevious } from "../../hook/usePrevious";
import { IMLocalized } from "../../language/IMLocalized";
import ModalViewAppraisalGroup from "./modal/ModalViewAppraisalGroup";

const mapStateToProps=(state)=>({
    list:state.get_appraisal_group_list.data,
    isLoading:state.get_appraisal_group_list.isLoading,
    delete_success:state.delete_appraisal_group.data,
})

const mapDispatchToProps=(dispatch)=>({
    get_appraisal_group_list:()=>{
        dispatch(get_appraisal_group_list())
    },
    delete_appraisal_group:(id)=>{
        dispatch(delete_appraisal_group(id))
    }
})

function AppraisalGroupTable(props){
    const {get_appraisal_group_list} = props;
    const prevDelete = usePrevious(props.delete_success);

    const [view_toggle, setViewToggle] = useState(false);
    const [view_detail, setViewDetail] = useState(null);

    useEffect(()=>{
        if(prevDelete !== undefined && prevDelete !== props.delete_success && props.delete_success != null){
            get_appraisal_group_list();
        }
    },[prevDelete,props.delete_success,get_appraisal_group_list])

    const deleteGroup =(id,name)=>{
        Swal.fire({
            type:'warning',
            title:`${IMLocalized('do_you_really_want_to_delete')} (${name})`,
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then(result=>{
            if(result.value){
                props.delete_appraisal_group(id);
            }
        })
    }

    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>props.toHome()}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('appraisal_group_list')}</BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">{IMLocalized('appraisal_group_list')}</h4>
                            <button className="btn btn-primary btn-sm" onClick={()=>props.setStep()}>{IMLocalized('create')}</button>
                        </CardHeader>
                        {props.isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                        {!props.isLoading && props.list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {!props.isLoading && props.list.length !== 0 &&
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 300px)'}}>
                            {props.list.map((item,index)=>{
                                return(
                                    <ListGroupItem className="py-2 d-flex align-items-center justify-content-between" key={index}>
                                        <span>{index+1}. {item.name}</span>
                                        <div>
                                            <button className="btn btn-info btn-sm" onClick={()=>{setViewToggle(true); setViewDetail(item)}}>{IMLocalized('detail')}</button>
                                            <button className="btn btn-danger btn-sm" onClick={()=>deleteGroup(item.id,item.name)}>{IMLocalized('delete')}</button>
                                        </div>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
        </Container>
        <ModalViewAppraisalGroup toggle={view_toggle} closeModal={()=>{setViewDetail(null) ; setViewToggle(false)}} detail={view_detail} />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(AppraisalGroupTable);