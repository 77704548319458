import myaxios from '../../axios';

export const GETAPPLYCLAIMLIST_REQUEST = 'GETAPPLYCLAIMLIST_REQUEST';
export const GETAPPLYCLAIMLIST_SUCCESS = 'GETAPPLYCLAIMLIST_SUCCESS';
export const GETAPPLYCLAIMLIST_FAILURE = 'GETAPPLYCLAIMLIST_FAILURE';

export const get_apply_claim_list =()=>{
    return dispatch=>{
        dispatch({
            type:'GETAPPLYCLAIMLIST_REQUEST'
        });

        myaxios.get('claim/apply/')
        .then((response)=>{
            dispatch({
                type:'GETAPPLYCLAIMLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETAPPLYCLAIMLIST_FAILURE',
                payload:error
            })
        })
    }
}