import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Col, Container, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import { IMLocalized } from "../../language/IMLocalized";
import { AgGridReact } from "ag-grid-react";
import { isFirstColumn } from "../ag-grid_component/isFirstColumn";
import { create_appraisal_group} from '../../action/appraisal/create_appraisal_group';
import { get_appraisal_group_list } from "../../action/appraisal/get_appraisal_group_list";
import { usePrevious } from "../../hook/usePrevious";
import { popError } from "../../func/popError";


const mapStateToProps=(state)=>({
    emplist:state.get_employee_list.data,
    formlist:state.get_appraisal_form_list.data,
    create_errors:state.create_appraisal_group.errors,
    create_success:state.create_appraisal_group.data,

})

const mapDispatchToProps=(dispatch)=>({
    create_appraisal_group:(name,form,appraier,year,from_date,to_date,employees)=>{
        dispatch(create_appraisal_group(name,form,appraier,year,from_date,to_date,employees))
    },
    get_appraisal_group_list:()=>{
        dispatch(get_appraisal_group_list())
    }
})

const initialState={
    name:'',
    err_name:'',
    form:'',
    err_form:'',
    appraiser:'',
    err_appraiser:'',
    year:'',
    err_year:'',
    from_date:'',
    err_from_date:'',
    to_date:'',
    err_to_date:'',
}

const gridOptions={
    defaultColDef:{
        sortable:true,
        resizable:true,
    }
}

function AppraisalGroupCreate(props){
    const {setStep,get_appraisal_group_list} = props;
    const [state, setState] = useState(initialState);
    const employeeRef = useRef();
    const prevSuccess = usePrevious(props.create_success);
    const prevErrors = usePrevious(props.create_errors);

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== props.create_success && props.create_success != null){
            setStep();
            get_appraisal_group_list();
        }
    },[props.create_success,prevSuccess,setStep,get_appraisal_group_list])

    useEffect(()=>{
        if(prevErrors !== undefined && prevErrors !== props.create_errors && props.create_errors != null){
            if(props.create_errors.hasOwnProperty('name')){
                const word = props.create_errors['name'].toString();
                setState(prevState=>({...prevState,err_name:word}));
            }
            if(props.create_errors.hasOwnProperty('employees')){
                const word = props.create_errors['employees'].toString();
                popError(word);
            }
            
        }
    },[prevErrors,props.create_errors])


    const createGroup=()=>{
        if( state.name !== '' && state.form !=='' && state.appraiser !=='' && state.year !== '' && state.from_date !=='' && state.to_date !==''){
            const arr = gridOptions.api.getSelectedRows();
            if(arr.length !== 0 ){
                const arr1 = arr.map(item=>item.id);
                props.create_appraisal_group(state.name,state.form,state.appraiser,state.year,state.from_date,state.to_date,arr1);
            }
            else{
                employeeRef.current.scrollIntoView({block:'start',behavior:'smooth'});
                popError(IMLocalized('no_employee_selected'));
                
            }
        }
        else{
            setState(prevState=>({
                ...prevState,
                err_name:state.name === '' ? IMLocalized('name_is_required') : '',
                err_form:state.form === '' ? IMLocalized('form_is_required') : '',
                err_appraiser:state.appraiser === '' ? IMLocalized('appraiser_is_required') : '',
                err_year:state.year === '' ? IMLocalized('year_is_required') : '',
                err_from_date:state.from_date === '' ? IMLocalized('from_date_is_required') : '',
                err_to_date: state.to_date === '' ? IMLocalized('to_date_is_required') : '',
            }))
        }
    }

    const row = props.emplist.filter(item=>item.active === true);

    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>props.toHome()}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem><a href="/#" onClick={()=>props.setStep()} >{IMLocalized('appraisal_group_list')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('create')}</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="ml-2">
                        <button className="btn btn-primary btn-sm" onClick={()=>props.setStep()}><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                        <button className="btn btn-primary btn-sm" onClick={()=>createGroup()}>{IMLocalized('create')}</button>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <h4 className="mb-0">{IMLocalized('new_appraisal_group')}</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="appraisal_name">{IMLocalized('name')}</label>
                                        <Input size="sm" type="text" id="appraisal_name" value={state.name} invalid={state.err_name.length >0} 
                                        onChange={(e)=>{
                                            const value = e.target.value;
                                            setState(prevState=>({...prevState,name:value,err_name:''}))
                                        }}
                                        />
                                        <FormFeedback>{state.err_name}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="form">{IMLocalized('form')}</label>
                                        <Input size="sm" id="form" type="select" value={state.form} invalid={state.err_form.length > 0}
                                        onChange={(e)=>{
                                            const value = e.target.value;
                                            setState(prevState=>({...prevState,form:value,err_form:''}))
                                        }}
                                        >
                                            <option></option>
                                            {props.formlist.map((item,index)=>{
                                                return <option value={item.id} key={index}>{item.name}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{state.err_form}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="appraiser_name">{IMLocalized('appraiser_name')}</label>
                                        <Input size="sm" type="select" id="appraiser_name" value={state.appraiser} invalid={state.err_appraiser.length > 0}
                                        onChange={(e)=>{
                                            const value = e.target.value;
                                            setState(prevState=>({
                                                ...prevState,
                                                appraiser:value,
                                                err_appraiser:''
                                            }))
                                        }}>
                                            <option></option>
                                            {props.emplist.filter(item=>item.is_manager === true).map((item,index)=>{
                                                return <option value={item.id} key={index}>{item.name}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{state.err_appraiser}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="appraiser_year">{IMLocalized('year')}</label>
                                        <Input size="sm" type="text" id="appraiser_year" value={state.year} invalid={state.err_year} 
                                        onChange={(e)=>{
                                            const value = e.target.value;
                                            setState(prevState=>({...prevState,year:value,err_year:''}));
                                        }}
                                        />
                                        <FormFeedback>{state.err_year}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="appraiser_from_date">{IMLocalized('from_date')}</label>
                                        <Input size="sm" type="date" id="appraiser_from_date" max={state.to_date !== '' ? state.to_date : null} value={state.from_date} invalid={state.err_from_date.length > 0} 
                                        onChange={(e)=>{
                                            const value = e.target.value;
                                            setState(prevState=>({...prevState,from_date:value,err_from_date:''}))
                                        }}/>
                                        <FormFeedback>{state.err_from_date}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="appraiser_to_date">{IMLocalized('to_date')}</label>
                                        <Input size="sm" type="date" id="appraiser_to_date" min={state.from_date !== '' ? state.from_date:  null} value={state.to_date} invalid={state.err_to_date.length > 0} 
                                        onChange={(e)=>{
                                            const value = e.target.value;
                                            setState(prevState=>({...prevState,to_date:value,err_to_date:''}))
                                        }}/>
                                        <FormFeedback>{state.err_to_date}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <div ref={employeeRef}>
                                <h4>{IMLocalized('select_employee')}</h4>
                            </div>
                        </CardHeader>
                        <div className="ag-theme-balham" style={{height:'calc(100vh - 350px)', width:'100%' }}>
                            <AgGridReact
                                enableRangeSelection={true}
                                gridOptions={gridOptions}
                                rowData={row}
                                enableSorting={true}
                                enableColResize={true}
                                suppressDragLeaveHidesColumns={true}
                                columnDefs={[
                                    {
                                        headerName:IMLocalized('agrid_select'),
                                        field:'',
                                        editable:false,
                                        headerCheckboxSelection: isFirstColumn,
                                        checkboxSelection: isFirstColumn,
                                        headerCheckboxSelectionFilteredOnly:true,
                                        filter:false,
                                        lockPosition: true,
                                        width:150
                                    },
                                    {
                                        headerName:IMLocalized('agrid_emp_id'),
                                        field:'employee_number',
                                        editable:false,
                                        width:100
                                    },
                                    {
                                        headerName:IMLocalized('agrid_emp_name'),
                                        field:'name',
                                        editable:false,
                                        width:200
                                    },
                                    {
                                        headerName:IMLocalized('agrid_department'),
                                        field:'contract_cache.department',
                                        editable:'false',
                                        width:150
                                    },
                                    {
                                        headerName:IMLocalized('agrid_section'),
                                        field:'contract_cache.section',
                                        editable:'false',
                                        width:150
                                    },
                                    {
                                        headerName:IMLocalized('agrid_job_title'),
                                        field:'contract_cache.job_title',
                                        editable:'false',
                                        width:150
                                    },
                                    {
                                        headerName:IMLocalized('agrid_job_level'),
                                        field:'contract_cache.job_level',
                                        editable:'false',
                                        width:150
                                    }
                                ]}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(AppraisalGroupCreate);