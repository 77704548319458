import myaxios from '../../axios';
export const EMPCONTRACTCOSTCENTER1_SUCCESS = 'EMPCONTRACTCOSTCENTER1_SUCCESS';
export const EMPCONTRACTCOSTCENTER1_REQUEST = 'EMPCONTRACTCOSTCENTER1_REQUEST';
export const EMPCONTRACTCOSTCENTER1_FAILURE = 'EMPCONTRACTCOSTCENTER1_FAILURE';


export const employee_contract_costcenter1 = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'EMPCONTRACTCOSTCENTER1_REQUEST'
        });
        
        myaxios.post(`company/costcenter/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'EMPCONTRACTCOSTCENTER1_SUCCESS',
                payload:response
                
            }
        )})
        .catch((error) =>{
            dispatch({
                type: 'EMPCONTRACTCOSTCENTER1_FAILURE',
                payload: error,
                
            })
        })
    }
}