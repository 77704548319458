import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import Swal from 'sweetalert2';
import {get_employee_education} from '../../../action/employee/get_employee_education';
import {delete_employee_education, reset_delete_employee_education} from '../../../action/employee/delete_employee_education';
import {IMLocalized} from '../../../language/IMLocalized';
import ModalViewEducation from './modal/ModalViewEducation';
import ModalCreateEducation from './modal/ModalCreateEducation';
import ModalEditEducation from './modal/ModalEditEducation';
import { emp_get_employee_education } from '../../../reducers/employee/get_employee_education';
import { getDateFormat } from '../../../func/getDateFormat';


export default function EmployeeEducation({ id }){
    
    const dispatch = useDispatch();
    const delete_success = useSelector(state=>state.delete_employee_education.data);
    const isLoading1 = useSelector(state=>state.get_employee_education.isLoading);

    const [add_toggle,setAddToggle] = useState(false);
    const [view_toggle,setViewToggle] = useState(false);
    const [view_detail,setViewDetail] = useState(null);
    const [edit_detail,setEditDetail] = useState(null);
    const [edit_toggle,setEditToggle] = useState(false);

    const list = useSelector(state=>emp_get_employee_education(state,parseInt(id)));


    useEffect(()=>{
        if(delete_success !== null){
            dispatch(reset_delete_employee_education());
            dispatch(get_employee_education());
        }
    },[delete_success,dispatch])

    const toView=(id)=>{
        const toggle = !view_toggle;
        if(toggle === true){
            const data = list.find((item)=>item.id === id);
            if(data){
                setViewDetail(data);
            }
        }
        else{
            setViewDetail(null);
        }

        setViewToggle(toggle);
    }

    const editEducation = (id)=>{
        const data = list.find((item)=>item.id === id);
        setEditDetail(data);
        setEditToggle(true);
    }

    const deleteEducation=(id)=>{
        const data = list.find((item)=>item.id === id);
        if(data){
            Swal.fire({
                title:`${IMLocalized('delete_education_confirmation')} (${IMLocalized(data.level)})?`,
                text:IMLocalized('revert_msg'),
                type:'warning',
                showCancelButton:true,
                cancelButtonColor:'#3085d6',
                confirmButtonColor:'#d33',
                confirmButtonText:IMLocalized('yes!'),
                cancelButtonText:IMLocalized('no!'),
            })
            .then((result)=>{
                if(result.value){
                    dispatch(delete_employee_education(id));
                }
            })
        }
    }

    return(
        <>
            <div className="d-flex justify-content-between pl-2 mt-2 emp-tabcontent-title">
                <div className="d-flex align-items-center">
                    <i className="fas fa-user-graduate text-primary"></i>
                    <h4 className="text-primary m-0 pl-2">{IMLocalized('employee_education')}</h4>
                </div>
                <div className="text-primary pt-2 cursor-pointer pr-2" onClick={()=>setAddToggle(true)}>
                    <i className="fas fa-plus-circle mr-1 function_icon"/>
                    <small>{IMLocalized('add_education')}</small>
                </div>
            </div>
            {isLoading1 && <div className='m-2'><h4>{IMLocalized('loading')}..</h4></div>}
            {!isLoading1 && list.length === 0 && <div className='m-2'><h4>{IMLocalized('no_data')}</h4></div>}
            {!isLoading1 && list.length !== 0 &&
            <Table size="sm" className="mt-2 align-items-center table-bordered" responsive>
                <thead className='thead-light text-center'>
                    <tr>
                        <th>{IMLocalized('education_level')}</th>
                        <th>{IMLocalized('graduation_date')}</th>
                        <th>{IMLocalized('edit')} / {IMLocalized('delete')}</th>
                    </tr>
                </thead>
                <tbody className='thead-light text-center'>
                    {list.map((item,index)=>{
                        return(
                            <tr key={index}>
                                <td><a href="/#" onClick={()=>toView(item.id)}>{IMLocalized(item.level)}</a></td>
                                <td>{getDateFormat(item.graduation_date)}</td>
                                <td align='center' valign='center'>
                                    <div className='d-flex justify-content-center'>
                                        <div className="primary_btn d-flex align-items-center justify-content-center">
                                            <i className="fas fa-pen text-primary cursor-pointer" onClick={()=>editEducation(item.id)} />
                                        </div>
                                        <div className="delete_btn d-flex align-items-center justify-content-center">
                                            <i className="fas fa-trash text-red cursor-pointer" onClick={()=>deleteEducation(item.id)} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>}
            <ModalCreateEducation toggle={add_toggle} closeModal={()=>setAddToggle(false)} id={id} onSuccess={()=>{dispatch(get_employee_education()); setAddToggle(false)}}  />
            <ModalViewEducation type="hr" toggle={view_toggle} closeModal={()=>toView()} detail={view_detail} />
            <ModalEditEducation toggle={edit_toggle} closeModal={()=>{setEditDetail(null); setEditToggle(false)}} detail={edit_detail} onSuccess={()=>{setEditDetail(null); setEditToggle(false); dispatch(get_employee_education())}} />
        </>
    )
}
