import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const MASSASSIGNCLAIMAPPROVAL1_REQUEST = 'MASSASSIGNCLAIMAPPROVAL1_REQUEST';
export const MASSASSIGNCLAIMAPPROVAL1_SUCCESS = 'MASSASSIGNCLAIMAPPROVAL1_SUCCESS';
export const MASSASSIGNCLAIMAPPROVAL1_FAILURE = 'MASSASSIGNCLAIMAPPROVAL1_FAILURE';
export const MASSASSIGNCLAIMAPPROVAL1_RESET = 'MASSASSIGNCLAIMAPPROVAL1_RESET';

export const mass_assign_claim_approval1 = (data)=>{
    return dispatch=>{
        dispatch({
            type:'MASSASSIGNCLAIMAPPROVAL1_REQUEST'
        });

        myaxios.post('claim/employeeapproval/array_create/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'MASSASSIGNCLAIMAPPROVAL1_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('assign_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'MASSASSIGNCLAIMAPPROVAL1_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('assign_failure')
            });
        })
    }
}

export const reset_mass_assign_claim_approval1 = () => dispatch =>{
    dispatch({
        type:'MASSASSIGNCLAIMAPPROVAL1_RESET'
    });
}