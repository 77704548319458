import React,{createContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import 'jspdf-autotable';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {get_employee_profile_picture} from '../../../action/employee/get_employee_profile_picture';
import {get_general_letter} from '../../../action/get_general_letter';
import {IMLocalized} from '../../../language/IMLocalized';
import OverlayLoading from '../../loading_component/overlay_loading';
import EarningTable from './payslip_component/EarningTable';
import InformationTable from './payslip_component/InformationTable';
import DeductionTable from './payslip_component/DeductionTable';
import SummaryTable from './payslip_component/SummaryTable';

const mapStateToProps=(state)=>({
    payroll_list:state.get_self_payroll.data,
    picturelist:state.get_employee_picture_list.data,
    picture:state.get_employee_profile_picture.data,
    letterhead:state.get_general_letter.data,
    pay_component_type:state.dropdown_list.pay_component_type,
    wagelist:state.dropdown_list.wage,
    paymentlist:state.dropdown_list.payment,
    isLoading:state.get_self_payroll.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    get_employee_profile_picture:(id)=>{
        dispatch(get_employee_profile_picture(id));
    },
    get_general_letter:()=>{
        dispatch(get_general_letter())
    }
})

const initialState={
    next_item:{ payroll:'' ,payroll_name:''},
    prev_item:{ payroll:'',payroll_name:''},
    current_name:'',
    emp_payslip:null,
    allowancelist:[],
    overtimelist:[],
    recurringlist:[],
    flatlist:[],
    backpaylist:[],
    absentlist:[],
    bonuslist:[],
    basicpaylist:[],
    deductablelist:[],
    claimlist:[],
    nationlist:[],
    nopaylist:[],
    sgshg:[],
    sgcpf:null,
    payslipcontract:[],
}

export const UserContext = createContext();

function UserPayrollDetail(props){
    const {get_general_letter} = props;
    const [state,setState] = useState(initialState);
    const [monthName] = useState([IMLocalized('january'), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")]);



    useEffect(()=>{
        get_general_letter();
    },[get_general_letter])

    useEffect(()=>{
        if(props.payroll_id !== '' && props.payroll_list.length !== 0 ){
            const data = props.payroll_list.find(item=>item.id === props.payroll_id);
            if(data){
                const {pay,sgcpf,sgshg,payslipcontract} = data;
                const overtimelist = pay.filter(item=>item.pay_type === 'OT' && item.amount >0 );
                const allowancelist = pay.filter(item=>item.pay_type === 'ALLOWANCE' && item.amount >0);
                const recurringlist = pay.filter(item=>item.pay_type === 'RA' && item.amount >0);
                const flatlist = pay.filter(item=>item.pay_type === 'FX' && item.amount >0);
                const backpaylist = pay.filter(item=>item.pay_type === 'BACKPAY' || item.pay_type === 'COMM' || item.pay_type === 'GRATUITY' || item.pay_type === 'INSURANCE' || item.pay_type === 'SHARES' || item.pay_type === 'DIRECTORS' || item.pay_type === 'PENSION' || item.pay_type === 'RETIREMENT' || item.pay_type === 'OTHERS').filter(item=>item.deductable === false && item.amount >0);
                const absentlist = pay.filter(item=>item.pay_type === 'ABS' && item.amount >0);
                const bonuslist = pay.filter(item=>item.pay_type === 'BONUS' && item.amount >0);
                const basicpaylist = pay.filter(item=>item.pay_type === 'BASIC' && item.amount >0);
                const deductablelist = pay.filter(item=>item.pay_type === 'DEDUCT' && item.deductable === true && item.amount >0)
                const claimlist = pay.filter(item=>item.pay_type === 'CLAIM' && item.deductable === false && item.amount >0);
                const nationlist = pay.filter(item=>item.pay_type === 'NSMUP' && item.amount >0);
                const nopaylist = pay.filter(item=>item.pay_type === 'NPL' && item.amount >0);
                setState(prevState=>({
                    ...prevState,
                    overtimelist,
                    allowancelist,
                    recurringlist,
                    flatlist,
                    backpaylist,
                    absentlist,
                    bonuslist,
                    basicpaylist,
                    deductablelist,
                    claimlist,
                    nationlist,
                    nopaylist,
                    sgshg,
                    sgcpf,
                    payslipcontract,
                }))
            }
        }
    },[props.payroll_list,props.payroll_id])

    useEffect(()=>{

        function getDateName(date){
            const month = new Date(date).getMonth();
            const year = new Date(date).getFullYear();
            return `${monthName[month]} ${year}`;
        }

        if(props.payroll_list.length !== 0 && props.payroll_id !== ''){
            const index = props.payroll_list.findIndex(item=>item.id === props.payroll_id);
            let next_item = { payroll:'' ,payroll_name:''};
            let prev_item = { payroll:'' ,payroll_name:''};
            let current_data = props.payroll_list[index];
            const current_name = current_data?.payroll_date ? getDateName(current_data.payroll_date)  :''; 
            if(index !== 0 ){
                const next_data = props.payroll_list[index -1];
                next_item.payroll = next_data?.id??'';
                next_item.payroll_name = next_data?.payroll_date ? getDateName(next_data.payroll_date) :'';
            }
            if(index !== props.payroll_list.length -1){
                const prev_data  = props.payroll_list[index +1];
                prev_item.payroll = prev_data?.id ?? '';
                prev_item.payroll_name = prev_data?.payroll_date ? getDateName(prev_data.payroll_date) :'' ;
            }
            setState(prevState=>({...prevState,next_item,prev_item,current_name,emp_payslip:current_data}));
        }
    },[props.payroll_list,props.payroll_id,monthName])


    return(
        <>
        {props.isLoading ? <OverlayLoading/> :
        <>
        <UserContext.Provider value={state}>
            <Container fluid>
                <Row className='border-bottom bg-white'>
                    <Col className='p-2'>
                        <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                            <BreadcrumbItem><a href="/#" onClick={()=>props.toHome()}>{IMLocalized('home')}</a></BreadcrumbItem>
                            <BreadcrumbItem><a href="/#" onClick={()=>props.toProfile()}>{IMLocalized('profile')}</a></BreadcrumbItem>
                            <BreadcrumbItem active>{state.current_name}</BreadcrumbItem>
                    </Breadcrumb>
                    <div className='ml-2'>
                        <button onClick={()=>props.toProfile()} className="btn btn-primary btn-sm"><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                        {state.prev_item.payroll !== '' &&
                        <button className='btn btn-primary btn-sm' onClick={()=>props.toPayrollDetail(state.prev_item.payroll)}>{state.prev_item.payroll_name}</button>}
                        {state.next_item.payroll !== '' &&
                        <button className='btn btn-primary btn-sm' onClick={()=>props.toPayrollDetail(state.next_item.payroll)}>{state.next_item.payroll_name}</button>}
                    </div>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col lg="9" md="12">
                    <InformationTable />
                    <EarningTable />
                    <DeductionTable/>
                </Col>
                <Col lg="3">
                    <SummaryTable/>
                </Col>
            </Row>
        </Container>
        </UserContext.Provider>
        </>}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(UserPayrollDetail);