import * as createsection from '../action/create_section';

const initialState ={
    isLoading:false,
    errors:null,
    data:null
}

const create_section = (state=initialState,action) =>{

    switch(action.type) {
        case createsection.CREATESECTION_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case createsection.CREATESECTION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case createsection.CREATESECTION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data,
            }
        case createsection.CREATESECTION_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default create_section;