import React,{Component} from 'react';
import {connect} from 'react-redux';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';
import {DateRangePicker} from 'react-dates';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {  Row, Col,DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter,Input } from 'reactstrap';
import {report_get_report_day} from '../../action/report/report_get_report_day';
import {get_apply_leave_list2} from '../../action/leave/get_apply_leave_list2';
import moment from 'moment';
import Swal from 'sweetalert2';
import {IMLocalized} from '../../language/IMLocalized';
class leave_detail_report extends Component{

    constructor(props){
        super(props);
        this.state={
            applylist:[],
            departmentlist:[],
            list:[],
            startDate:null,
            endDate:null,
            start_date:null,
            end_date:null,
            dateFormat:'DD/MM/YYYY',
            focusedInput:null,
            leavedetail_toggle:false,
            use_day:false,
            monday:false,
            tuesday:false,
            wednesday:false,
            thursday:false,
            friday:false,
            saturday:false,
            sunday:false,
            select_emp:'',
        }
    }

    componentDidMount(){
        const start_date = moment().startOf('month').format('YYYY-MM-DD');
        const end_date   = moment().endOf('month').format('YYYY-MM-DD');
        const startDate = moment(start_date);
        const endDate = moment(end_date);
            
        this.setState({startDate});
        this.setState({start_date});
        this.setState({endDate});
        this.setState({end_date});
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.reportlist !== this.props.reportlist){
            const {reportlist} = this.props;
            const data = reportlist.data;
            this.getDetail(data);
        }
    }

    onDatesChange({startDate,endDate}){
        this.setState({startDate,endDate})
        let start_date = null;
        if(startDate !== null){
            let startdate =startDate._d;
            let startmonth = (startdate.getMonth()+1);
            if(startmonth <10){
                startmonth = '0'+startmonth;
            }
            let startday = startdate.getDate();
            if(startday<10){
                startday = '0'+startday
            }
            start_date = startdate.getFullYear()+'-' + startmonth+'-' +startday
          
        }
        
        let end_date = null;
        if(endDate !== null){
            let enddate = endDate._d;
            let endmonth =(enddate.getMonth()+1);
            if(endmonth <10){
                endmonth = '0'+endmonth;
            }
            let endday = enddate.getDate();
            if(endday<10){
                endday = '0'+endday
            }
            end_date = enddate.getFullYear()+'-'+endmonth+'-'+endday
        }
        this.setState({end_date});
        this.setState({start_date});
    }

    onFocusChange=(focusedInput)=>{
        this.setState({focusedInput})
    }

    modal=()=>{
        const toggle = !this.state.leavedetail_toggle;
        if(toggle === false){
            
            const start_date = moment().startOf('month').format('YYYY-MM-DD');
            const end_date   = moment().endOf('month').format('YYYY-MM-DD');
            const startDate = moment(start_date);
            const endDate = moment(end_date);
            this.setState({startDate});
            this.setState({start_date});
            this.setState({endDate});
            this.setState({end_date});
        }
        this.setState({monday:false});
        this.setState({tuesday:false});
        this.setState({wednesday:false});
        this.setState({thursday:false});
        this.setState({friday:false});
        this.setState({saturday:false});
        this.setState({sunday:false});
        this.setState({use_day:false});
        this.setState({leavedetail_toggle:toggle});
        this.setState({select_emp:''});
    }

    getDetail=(detail)=>{
        const {select_emp} = this.state;

        let department = [];
        if(select_emp !== ''){
            const emp_detail = detail.filter(function(item){
                return item.employee.id === parseInt(select_emp)
            })

            if(emp_detail.length !== 0){
                for(let i =0;i<emp_detail.length;i++){
                    const employee = emp_detail[i].employee;
                    const contract_cache = employee.contract_cache;
                    if(contract_cache !== null){
                        const emp_department = contract_cache.department;
                        if(department.includes(emp_department) === false){
                            department.push(emp_department);
                        }
                    }
                }
            }
            this.setState({departmentlist:department});
            this.setState({list:emp_detail});
            this.generateExcel(department,emp_detail)

        }
        else{
            if(detail.length !== 0){
                for(let i =0;i<detail.length ;i++){
                    const employee = detail[i].employee;
                    const contract_cache = employee.contract_cache;
                    if(contract_cache !== null){
                        const emp_department = contract_cache.department;
                        if(department.includes(emp_department) === false){
                            department.push(emp_department);
                        }
                    }
                }
            }

            this.setState({departmentlist:department});
            this.setState({list:detail});
            this.generateExcel(department,detail)
        }
        
        
    }

    getData=()=>{
        this.props.report_get_report_day(this.state.start_date,this.state.end_date,this.state.monday,this.state.tuesday,this.state.wednesday,this.state.thursday,this.state.friday,this.state.saturday,this.state.sunday,this.state.use_day);
    }

    generateExcel=(departmentlist,list)=>{

        const {start_date,end_date} = this.state;
        const {optionlist} = this.props;
        const wb = new ExcelJs.Workbook();
        const day =moment(new Date()).format("DD");
        const month =moment(new Date()).format("MM");
        if(departmentlist.length !== 0){
            for(let i=0;i<departmentlist.length;i++){
                const department = departmentlist[i];
                const ws = wb.addWorksheet(department);
                const today = moment(new Date()).format('DD/MM/YYYY');
                const time = moment().format('HH:mm:ss');
                
                ws.getCell('A1').value = 'Leave Detail Report - Department';
                ws.getCell('A2').value = 'Start Date : '+this.getDateFormat(start_date)+'          End Date : '+this.getDateFormat(end_date)+'     Printed On : '+today+' '+time;
                ws.getCell('A3').value = 'Department : '+department;
                ws.columns = [
                    {width: 15},
                    {width: 15},
                    {width: 20}, 
                    {width: 15}
                   ];
                let count =3;
                const detail = list.filter(function(item){
                    return item.employee.contract_cache.department === department
                })
                let emplist =[];
                if(detail.length !== 0){
                    for(let j=0;j<detail.length;j++){
                        const employee = detail[j].employee.name;
                        if(emplist.includes(employee) === false){
                            emplist.push(employee);
                        }
                    }
                }
                if(emplist.length !== 0){
                    for(let x=0;x<emplist.length ;x++){
                        const name = emplist[x];
                        const emp_detail = detail.filter(function(item){
                            return item.employee.name === name
                        })
                    
                        if(emp_detail.length !== 0){
                            const column = [
                                {header:'Date',key:'date'},
                                {header:'Leave Code',key:'metadata.leave_code'},
                                {header:'Leave Type',key:'metadata.leave_name'},
                                {header:'Option',key:'day'}
                            ]
                            
                            for(let u =0;u<emp_detail.length;u++){
                                if(u === 0){
                                    const count1 = count +2;
                                    const employee_number = emp_detail[u].employee.employee_number;
                                    const name = emp_detail[u].employee.name;
                                    ws.getCell('A'+count1).value = {richText: [
                                        {
                                          font: {
                                            bold:true
                                          },
                                          text: 'Emp ID : '+employee_number
                                        }]};
                                    ws.getCell('B'+count1).value = {richText: [
                                        {
                                          font: {
                                            bold:true
                                          },
                                          text: 'Name : '+name
                                        }]};
                                    const count2  = count1 +1;
                                    for(let z=0;z<column.length;z++){
                                        const headerName = column[z].header;
                                        ws.getCell(this.getColumnName(z)+count2).value = headerName;
                                    }
                                    count = count2+1; 
                                }
                                for(let z=0;z<column.length;z++){
                                    const field = (column[z].key);
                                    const item = this.getValue(emp_detail[u],field);
                                    if(field === 'date'){
                                        ws.getCell(this.getColumnName(z)+count).value = this.getDateFormat(item);
                                    }
                                    else if(field === 'day'){
                                        let item1 = '';
                                        const detail = optionlist.filter(function(data){
                                            return data.value === item
                                        })
                                        if(detail.length !== 0){
                                            item1 = detail[0].display;
                                        }
                                        ws.getCell(this.getColumnName(z)+count).value = item1;
                                    }
                                    else{
                                        ws.getCell(this.getColumnName(z)+count).value = item;
                                    }
                                    
                                    
                                }
                                
                                count = count+1;
                            }
                            ['A'+count, 'B'+count, 'C'+count, 'D'+count, 'E'+count, 'F'+count, 'G'+count].forEach(key => {
                                ws.getCell(key).border = {bottom: {style:'thick'}}
                            });
                        }
                       
                    }
                }
            }
            
        }

        if(departmentlist.length !== 0){
            const title_name = 'LeaveDetailReportByDept_'+day+'-'+month;
            const file_name = title_name +'.xlsx';
            wb.xlsx.writeBuffer().then((buf)=>{
              
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, file_name)
            });
            this.modal();
        }
        else{
            Swal.fire({
                type:'error',
                title:IMLocalized('no_data'),
                confirmButtonText:IMLocalized('ok!')
            })
        }
    }

    getDateFormat=(date)=>{
        const date1 = moment(date,'YYYY-MM-DD');
        const new_date = date1.format('DD/MM/YYYY');
        return new_date;
    }

    getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }
    getValue(object, path) {
        const parts = path.split(".");
        return parts.reduce((value, key) => value ? value[key] : value, object)
    }

    radioExport=(e)=>{
        const target = e.target;
        const name = target.name;
        const value = target.value;
        let toggle =false;
        if(value === 'true'){
            toggle = true;
        }
        this.setState({
            [name]:toggle
        })
    }

    radioWeek=(e)=>{
        const target = e.target;
        const checked = target.checked;
        const name = target.name;
        this.setState({[name]:checked})
    }

    handleInput=(e)=>{
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({[name]:value})
    }

    render(){

        const {use_day} = this.state;
        let ttable ='';
        if(use_day === true){
            ttable = (
                <Row className="mt-2">
                    <Col>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="report_sunday"
                                type="checkbox"
                                checked ={this.state.sunday}
                                onChange={this.radioWeek}
                                name="sunday"
                            />
                            <label className="custom-control-label" htmlFor="report_sunday">
                                {IMLocalized('SUNDAY')}
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="report_monday"
                                type="checkbox"
                                checked ={this.state.monday}
                                onChange={this.radioWeek}
                                name="monday"
                            />
                            <label className="custom-control-label" htmlFor="report_monday">
                                {IMLocalized('MONDAY')}
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="report_tuesday"
                                type="checkbox"
                                checked ={this.state.tuesday}
                                onChange={this.radioWeek}
                                name="tuesday"
                            />
                            <label className="custom-control-label" htmlFor="report_tuesday">
                                {IMLocalized('TUESDAY')}
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="report_wednesday"
                                type="checkbox"
                                checked ={this.state.wednesday}
                                onChange={this.radioWeek}
                                name="wednesday"
                            />
                            <label className="custom-control-label" htmlFor="report_wednesday">
                                {IMLocalized('WEDNESDAY')}
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="report_thursday"
                                type="checkbox"
                                checked ={this.state.thursday}
                                onChange={this.radioWeek}
                                name="thursday"
                            />
                            <label className="custom-control-label" htmlFor="report_thursday">
                                {IMLocalized('THURSDAY')}
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="report_friday"
                                type="checkbox"
                                checked ={this.state.friday}
                                onChange={this.radioWeek}
                                name="friday"
                            />
                            <label className="custom-control-label" htmlFor="report_friday">
                                {IMLocalized('FRIDAY')}
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="report_saturday"
                                type="checkbox"
                                checked ={this.state.saturday}
                                onChange={this.radioWeek}
                                name="saturday"
                            />
                            <label className="custom-control-label" htmlFor="report_saturday">
                                {IMLocalized('SATURDAY')}
                            </label>
                        </div>
                    </Col>
                </Row>
            )
        }
        const {emplist} = this.props;

        const emp_option = emplist.map((item)=>{
            return <option value={item.id} key={item.id}>{item.employee_number} - {item.name}</option>
        })
        return(
            <div>
                <DropdownItem onClick={this.modal} className="cursor-pointer">{IMLocalized('leave_detail_report')}</DropdownItem>
                <Modal size="lg" isOpen={this.state.leavedetail_toggle}>
                    <ModalHeader toggle={this.modal}>{IMLocalized('leave_detail_report')}</ModalHeader>
                    <ModalBody>
                        <Row className="pt-2">
                            <Col className="d-flex">
                                <div className="mr-2">
                                    <DateRangePicker 
                                        startDate={this.state.startDate}
                                        isOutsideRange={()=>false}
                                        small = {true}
                                        startDateId="unique_start_date_id"
                                        endDate={this.state.endDate}
                                        endDateId="unique_end_date_id"
                                        onDatesChange={this.onDatesChange.bind(this)}
                                        focusedInput={this.state.focusedInput}
                                        displayFormat={this.state.dateFormat}
                                        onFocusChange={this.onFocusChange} 
                                    />
                                </div>
                                <div className="form-group">
                                    <Input type="select" size="sm" style={{width:'250px'}} onChange={this.handleInput} value={this.state.select_emp} name="select_emp" >
                                        <option value="">{IMLocalized('all_employee')}</option>
                                        {emp_option}
                                    </Input>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="d-flex">
                                <div className="custom-control custom-radio mr-3">
                                    <input
                                        className="custom-control-input"
                                        id="reportexport_customRadio1"
                                        name="use_day"
                                        type="radio"
                                        value="false"
                                        checked ={this.state.use_day === false}
                                        onClick={this.radioExport}
                                    />
                                    <label className="custom-control-label" htmlFor="reportexport_customRadio1">
                                       {IMLocalized('default')}
                                    </label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                    <input
                                        className="custom-control-input"
                                        id="reportexport_customRadio2"
                                        name="use_day"
                                        type="radio"
                                        value='true'
                                        checked = {this.state.use_day === true}
                                        onClick={this.radioExport}
                                    />
                                    <label className="custom-control-label" htmlFor="reportexport_customRadio2">
                                        {IMLocalized('CUSTOM')}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        {ttable}
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={this.getData} className="btn btn-primary btn-sm">{IMLocalized('export_leave_detail_report')}</button>
                        <button className="btn btn-danger btn-sm" onClick={this.modal}>{IMLocalized('close')}</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
function mapStateToProps(state,ownProps){
    return{
        period:state.get_payroll_period.data,
        applylist:state.get_apply_leave_list2.data,
        leavelist:state.get_leave_list.data,
        optionlist:state.dropdown_list.schedule_all_options,
        reportlist:state.report_get_report_day.data,
        emplist:state.get_employee_list.data,
        isLoading3:state.get_employee_list.isLoading,
        isLoading:state.get_payroll_period.isLoading,
        isLoading1:state.get_apply_leave_list2.isLoading,
        isLoading2:state.get_leave_list.isLoading,
    }
}

const mapDispatchToProps =(dispatch)=>{
    return{
        get_apply_leave_list2:()=>{
            dispatch(get_apply_leave_list2())
        },
        report_get_report_day:(from_date,to_date,monday,tuesday,wednesday,thursday,friday,saturday,sunday,use_day)=>{
            dispatch(report_get_report_day(from_date,to_date,monday,tuesday,wednesday,thursday,friday,saturday,sunday,use_day))
        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(leave_detail_report);