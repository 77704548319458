import myaxios from "../../axios";

export const GETPAYMENTUOB_REQUEST = 'GETPAYMENTUOB_REQUEST';
export const GETPAYMENTUOB_SUCCESS = 'GETPAYMENTUOB_SUCCESS';
export const GETPAYMENTUOB_FAILURE = 'GETPAYMENTUOB_FAILURE';

export const get_payment_uob = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETPAYMENTUOB_REQUEST'
        });

        return myaxios.get(`payroll/payroll/${id}/GetPaymentUOB/`)
        .then((res)=>res.data)
        .then((json)=>{
            dispatch({
                type:'GETPAYMENTUOB_SUCCESS',
                payload:json
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETPAYMENTUOB_FAILURE',
                payload:error
            })
        })
    }
}