export function filterDropdown(arr,type){

    const detail = arr.find(item=>item.value === type);
    return detail?.display ?? '-';
}

export function filterCountryDropdown(arr,type){

    const detail = arr.find(item=>item.value === type);
    return detail?.display_name ?? '-';
}

export function filterName(arr,id){

    const detail = arr.find(item=>item.id === id);
    return detail?.name ?? '-';
}