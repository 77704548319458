import * as arrayapproveclaim from '../../action/claim/array_approve_apply_claim';

const initialState={
    isLoading:false,
    errors:[],
    data:[]
}

const array_approve_apply_claim = (state=initialState,action)=>{
    switch(action.type){
        case arrayapproveclaim.ARRAYAPPROVECLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case arrayapproveclaim.ARRAYAPPROVECLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case arrayapproveclaim.ARRAYAPPROVECLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
            
    }
}
export default array_approve_apply_claim;