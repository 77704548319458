import * as aggridheader from '../../action/report/payroll_variance_get_aggridheader';


const initialState={
    data:null,
    isLoading:false,
    error:null
}

const payroll_variance_get_aggridheader = (state=initialState,action)=>{
    switch(action.type){
        case aggridheader.VARIANCEAGGRIDHEADER_REQUEST:
            return{
                ...state,
                isLoading:true,
                error:null,
                data:null,
            }
        case aggridheader.VARIANCEAGGRIDHEADER_SUCCESS:
            return{
                ...state,
                data:action.payload?.data,
                isLoading:false,
                error:null,
                
            }
        case aggridheader.VARIANCEAGGRIDHEADER_FAILURE:
            return{
                ...state,
                isLoading:false,
                error:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default payroll_variance_get_aggridheader;
