import myaxios from '../../axios';

export const GETOTPINITIAL_REQUEST = 'GETOTPINITIAL_REQUEST';
export const GETOTPINITIAL_SUCCESS = 'GETOTPINITIAL_SUCCESS';
export const GETOTPINITIAL_FAILURE = 'GETOTPINITIAL_FAILURE';


export const get_otp_initial =()=>{
    return dispatch=>{
        dispatch({
            type:'GETOTPINITIAL_REQUEST'
        });

        myaxios.get('otp/initial/')
        .then((response)=>{
            dispatch({
                type:'GETOTPINITIAL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETOTPINITIAL_FAILURE',
                payload:error
            })
        })
    }
}