import axios from 'axios';


export const SGADDDRESS_REQUEST = 'SGADDDRESS_REQUEST';
export const SGADDDRESS_SUCCESS = 'SGADDDRESS_SUCCESS';
export const SGADDDRESS_FAILURE = 'SGADDDRESS_FAILURE';

export const get_employee_postal_code = (pos) =>{
    return dispatch=>{
        dispatch({
            type : 'SGADDDRESS_REQUEST'
        });
        
        axios.get(`https://developers.onemap.sg/commonapi/search?searchVal=${pos}&returnGeom=Y&getAddrDetails=Y&pageNum=1/`)
        .then((response)=>{
            dispatch({
                type:'SGADDDRESS_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'SGADDDRESS_FAILURE',
                payload:error
            })
        })
    }
}