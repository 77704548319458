import * as updatecontract from '../../action/employee/update_employee_contract';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_employee_contract = (state=initialState,action)=>{
    switch(action.type){
        case updatecontract.NEW_UPDATEEMPCONTRACT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case updatecontract.NEW_UPDATEEMPCONTRACT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updatecontract.NEW_UPDATEEMPCONTRACT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case updatecontract.NEW_UPDATEEMPCINTRACT_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default update_employee_contract;