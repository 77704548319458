import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const UPDATEGENERALCOLOR_REQUEST = 'UPDATEGENERALCOLOR_REQUEST';
export const UPDATEGENERALCOLOR_SUCCESS = 'UPDATEGENERALCOLOR_SUCCESS';
export const UPDATEGENERALCOLOR_FAILURE = 'UPDATEGENERALCOLOR_FAILURE';
export const UPDATEGENERALCOLOR_RESET = 'UPDATEGENERALCOLOR_RESET';

export const update_general_color = (pending,pending_no_action,approved,rejected,cancelled,consumed,resubmit,paid)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEGENERALCOLOR_REQUEST'
        })

        myaxios.post('general/color/',{
            PENDING:pending,
            PENDING_NO_ACTION:pending_no_action,
            APPROVED:approved,
            REJECTED:rejected,
            CANCELLED:cancelled,
            CONSUMED:consumed,
            RESUBMIT:resubmit,
            PAID:paid
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEGENERALCOLOR_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEGENERALCOLOR_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            })
        })
    }
}

export const reset_update_general_color = () => dispatch =>{
    dispatch({
        type:'UPDATEGENERALCOLOR_RESET'
    });
}