import * as deleteapproval from '../../action/claim/delete_claim_approval';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const delete_claim_approval = (state=initialState,action)=>{
    switch(action.type){
        case deleteapproval.DELETECLAIMAPPROVAL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case deleteapproval.DELETECLAIMAPPROVAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case deleteapproval.DELETECLAIMAPPROVAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case deleteapproval.DELETECLAIMAPPROVAL_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default delete_claim_approval;