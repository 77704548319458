import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { getDateFormat } from '../../../func/getDateFormat';
import { IMLocalized } from '../../../language/IMLocalized';
import ExcelJs from 'exceljs';
import { getValue } from '../../../func/getValue';
import { getColumnName } from '../../../func/getColumnName';
import FileSaver from 'file-saver';
import { popError } from '../../../func/popError';
import XLSX from 'xlsx';
import { update_payslip_bonus } from '../../../action/payroll/update_payslip_bonus';
import { update_payslip_bonus_validate} from '../../../action/payroll/update_payslip_bonus_validate';
import { usePrevious } from '../../../hook/usePrevious';
import Swal from 'sweetalert2';
import { get_payroll_history_list } from '../../../action/history/get_payroll_history_list';
import { get_payslip_employee_contract } from '../../../action/payroll/get_payslip_employee_contract';
import { get_payroll_instance } from '../../../action/payroll/get_payroll_instance';


const mapStateToProps=(state)=>({
    contractlist:state.get_payslip_employee_contract.data,
    validate_success:state.update_payslip_bonus_validate.data,
    validate_errors:state.update_payslip_bonus_validate.errors,
    success:state.update_payslip_bonus.data,
    isLoading1:state.update_payslip_bonus_validate.isLoading,
    isLoading2:state.update_payslip_bonus.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    update_payslip_bonus_validate:(id,data)=>{
        dispatch(update_payslip_bonus_validate(id,data))
    },
    update_payslip_bonus:(id,data)=>{
        dispatch(update_payslip_bonus(id,data))
    },
    get_payroll_history_list:()=>{
        dispatch(get_payroll_history_list())
    },
    get_payslip_employee_contract:(id)=>{
        dispatch(get_payslip_employee_contract(id))
    },
    get_payroll_instance:(id)=>{
        dispatch(get_payroll_instance(id))
    }
})


function ModalUploadBonus(props){
    const {get_payroll_instance,validate_success,validate_errors,success,closeModal,get_payroll_history_list,get_payslip_employee_contract,update_payslip_bonus} = props;
    const [select_type,setSelectType] = useState('contract');
    const [bonus_list,setBonusList] = useState([]);
    const [language] = useState(localStorage.getItem('language'));
    const [bonusValidate,setBonusValidate] = useState([]);
    const [err_msg,setErrMsg] = useState([]);

    const prevValidate = usePrevious(validate_success);
    const prevErrors = usePrevious(validate_errors);
    const prevSuccess = usePrevious(success);

    useEffect(()=>{

        const arr = props.contractlist.map((item)=>{

            const contracts = item.employee.contracts.map((item1,index)=>{
                if(index === 0){
                    return {pay_name:IMLocalized('current_contract'),recurring_name:IMLocalized('current_contract'),...item1};
                }
                else{
                    let pay_name = '';
                    let recurring_name = '';
                    if(item1.pay !== 'amount'){
                        pay_name = `${getDateFormat(item1.start_date)} - ${item1.pay}`;
                    }
                    else{
                        pay_name = language === 'zh' ? '金额' : 'Amount';
                    }

                    if(item1.pay_with_recurring !== 'amount'){
                        recurring_name = `${getDateFormat(item1.start_date)} - ${item1.pay_with_recurring}`;
                    }
                    else{
                        recurring_name = language === 'zh' ? '金额' : 'Amount';
                    }
                    return {pay_name,recurring_name,...item1};
                }
            });
            return {employee:item.employee,contracts,bonus_name:item.bonus?IMLocalized('yes'):IMLocalized('no'), multiplier:null,amount:null,contract:null,bonus_code:null,id:item.id}
        })

        setBonusList(arr);
        setErrMsg([]);
        setBonusValidate([]);
        setSelectType('contract');
    },[props.contractlist,language,props.toggle])

    useEffect(()=>{
        if(prevValidate !== undefined && prevValidate !== validate_success && validate_success != null ){
            setErrMsg([]);
            Swal.fire({
                title:IMLocalized('do_you_confirm_upload_bonus'),
                type:'warning',
                showCancelButton:true,
                cancelButtonColor:'#d33',
                confirmButtonColor:'#3085d6',
                confirmButtonText:IMLocalized('yes!'),
                cancelButtonText:IMLocalized('no!'),
            })
            .then((result)=>{
                if(result.value){
                    update_payslip_bonus(props.id,bonusValidate);
                }
                else{
                    setBonusValidate([]);
                  
                }
            })
        }
    },[prevValidate,validate_success,bonusValidate,props.id,update_payslip_bonus])

    useEffect(()=>{
        if(prevErrors !== undefined && prevErrors !== validate_errors && validate_errors != null){
            let arr = [];
            for(let i=0;i<validate_errors.length;i++){
                const data = validate_errors[i];
                let msg = [];
                if(data !== true){
                    if(data.hasOwnProperty('count')){
                        msg.push(`${IMLocalized('multiplier')} : ${data['count'].toString()}`);
                    }
                    if(data.hasOwnProperty('amount')){
                        msg.push(`${IMLocalized('amount')} : ${data['amount'].toString()}`);
                    }
                    const name = bonusValidate[i].employee.name;
                    arr.push({name,msg});
                }
            }
            setErrMsg(arr);
        }
    },[prevErrors,validate_errors,bonusValidate])

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== success && success != null){
            get_payroll_history_list();
            closeModal();
            get_payslip_employee_contract(props.id);
            get_payroll_instance(props.id);
        }
    },[success,prevSuccess,props.id,get_payroll_history_list,get_payslip_employee_contract,closeModal,get_payroll_instance])


    const uploadBonusExcel=(e)=>{
        let file = e.target.files[0];
        const reader = new FileReader();
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3];
            const ws = wb.Sheets[wsname];
            const columns={
                'A':'employee_number',
                'B':'name',
                'C':'bonus_name',
                'D':'contract',
                'E':'value',
            }
            let bonus_data=[];
            let rowIndex = 2;
            let id = '';
            let lang = '';
            if(ws3 !== undefined){
                if(ws3['A1'] !== undefined){
                    id = ws3['A1'].w;
                    lang = ws3['A2'].w;
                }
            }

            while(ws['A'+rowIndex]){
                let row ={};
                for(let i =0;i<Object.keys(columns).length;i++){
                    const column = Object.keys(columns)[i];
                    const index = column + rowIndex;
                    if(ws[index] !== undefined){
                        row[columns[column]] = ws[index].w;
                    }
                    else{
                        row[columns[column]] = null;
                    }
                }
                bonus_data.push(row);
                rowIndex++;
            }

            if(id === 'Bonus1' || id === 'Bonus0'){
                if(lang === language){
                    if(bonus_data.length !== 0){
                        const arr = bonus_list.map((item,i)=>{
                            const detail = bonus_data.find((element)=>element.employee_number === item.employee.employee_number);

                            const contract_data = detail ? detail.contract : null;
                            const value = detail ? detail.value : null;
                            let bonus_code = null;
                            let contract = null;
                            let multiplier = 0;
                            let amount = 0;
                            if(language === 'zh'){
                                if(contract_data !== '金额' && contract_data !== null){
                                    if(id === 'Bonus0'){
                                        const contract_detail = item.contracts.find((element)=>{
                                            return element.pay_name === contract_data;
                                        })
                                        contract = contract_detail ? contract_detail.id : undefined ;
                                        bonus_code = 'C';
                                    }
                                    else{
                                        const contract_detail = item.contracts.find((element)=>{
                                            return element.recurring_name === contract_data;
                                        })
                                        contract = contract_detail ? contract_detail.id : undefined ;
                                        bonus_code = 'CA';
                                    }
                                    multiplier = value;
                                }
                                else if(contract_data === '金额' && contract_data !== null){
                                    bonus_code = 'A';
                                    amount = value;
                                }
                            }
                            else{
                                if(contract_data !== 'Amount' && contract_data !== null){
                                    if(id === 'Bonus0'){
                                        const contract_detail = item.contracts.find((element)=>{
                                            return element.pay_name === contract_data;
                                        })
                                        contract = contract_detail ? contract_detail.id : undefined;
                                        bonus_code = 'C';
                                        
                                    }
                                    else if(id === 'Bonus1'){
                                        const contract_detail = item.contracts.find((element)=>{
                                            return element.recurring_name === contract_data;
                                        })
                                        contract = contract_detail ? contract_detail.id : undefined;
                                        bonus_code = 'CA';
                                    }
                                    multiplier = value;
                                }
                                else if(contract_data === 'Amount' && contract_data !== null){
                                    bonus_code = 'A';
                                    amount = value;
                                }
                            }
                            return {...item,amount,contract,multiplier,bonus_code}
                        })
                        validateBonusData(arr);
                    }
                    else{
                        popError(IMLocalized('no_data_updated'))
                    }
                }
                else{
                    popError(IMLocalized('wrong_file_selected'),IMLocalized('please_choose_correct_language'))
                }
            }
            else{
                popError(IMLocalized('wrong_file_selected'));
            }

        }
        reader.readAsBinaryString(file);
    }

    const getBonusTemplate=()=>{
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_bonus');
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');
        ws.columns = [
            {header:IMLocalized('agrid_emp_id'),key:'employee.employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'employee.name'},
            {header:IMLocalized('agrid_bonus_updated'),key:'bonus_name'},
            {header:IMLocalized('agrid_contracts'),key:'contracts'},
            {header:IMLocalized('agrid_multiplier_amount'),key:'multiplier'},
        ]

        if(bonus_list.length !== 0){
            ws.columns.forEach((column,i)=>{
                const field = column.key;
                bonus_list.forEach((item,x)=>{
                    const step  = 2+x;
                    if(field !=='contracts'){
                        ws.getCell(getColumnName(i)+step).value = getValue(item,field);
                    }
                    else{
                        if(item.contracts.length !== 0){
                            item.contracts.forEach((contract,z)=>{
                                const y = z+1;
                                ws2.getCell(getColumnName(x)+y).value = select_type === 'contract' ? contract.pay_name : contract.recurring_name;
                            })

                            const name = getColumnName(x);
                            ws.getCell(getColumnName(i)+step).dataValidation={
                                type:'list',
                                allowBlank:false,
                                error:IMLocalized('excel_valid_in_list'),
                                errorStyle:'error',
                                errorTitle:'Error',
                                showErrorMessage:true,
                                formulae:[`=setting!$`+name+`$1:$`+name+`$`+item.contracts.length]
                            }
                        }
                    }
                })
            })
            ws3.getCell('A1').value = select_type === 'contract' ? 'Bonus0' : 'Bonus1';
            ws3.getCell('A2').value = language; 

            ws.columns.forEach(column =>{
                column.width = column.header.length < 15 ? 15 : column.header.length
            })

            const title = select_type === 'contract' ? 'Contract' : 'ContractWithRecurring';

            wb.xlsx.writeBuffer().then((buf)=>{
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, `Employee_Bonus_Template_${title}.xlsx`);
            });
        }
    }

    const validateBonusData=(bonus_data)=>{
        let arr = [];
        let count = 0;
        if(bonus_data.length !== 0){
            for(let i=0;i<bonus_data.length;i++){
                if(bonus_data[i].contract === undefined){
                    count = count +1;
                }
            }
        }
        if(count === 0){
            if(bonus_data.length !== 0){
                for(let i=0;i<bonus_data.length;i++){
                    const item = bonus_data[i];

                    if(item.bonus_code !== null){
                        if(item.bonus_code === 'CA' || item.bonus_code === 'C'){
                            if(item.multiplier !== null){
                                arr.push({employee:item.employee,payslip:item.id,amount:item.amount,bonus_code:item.bonus_code,contract:item.contract,count:item.multiplier});
                            }
                        }
                        else if(item.bonus_code === 'A'){
                            if(item.amount !== null){
                                arr.push({employee:item.employee,payslip:item.id,amount:item.amount,bonus_code:item.bonus_code,contract:item.contract,count:item.multiplier});
                            }
                        }
                    }
                }
            }
        }
        if(arr.length !== 0){
            props.update_payslip_bonus_validate(props.id,arr);
            setBonusValidate(arr);
        }
        else{
            popError(IMLocalized('no_data_updated'));
        }
    }

    return(
        <Modal size="lg" isOpen={props.toggle}> 
            <ModalHeader toggle={props.closeModal}>{IMLocalized('upload_bonus')}</ModalHeader>
            <ModalBody>
                <div className="d-flex">
                    <div className="custom-control custom-radio mr-3">
                        <input className="custom-control-input" id="payrollBonusRadio1" name="payrollBonusRadioPay" type="radio" value="contract" checked = {select_type ==='contract'} onChange={(e)=>{const value = e.target.value; setSelectType(value); }}/>
                        <label className="custom-control-label" htmlFor="payrollBonusRadio1">Contract</label>
                    </div>
                    <div className="custom-control custom-radio mr-3">
                        <input className="custom-control-input" id="payrollBonusRadio2" name="payrollBonusRadioPay" type="radio" value="contract_recurring" checked = {select_type ==='contract_recurring'} onChange={(e)=>{const value = e.target.value; setSelectType(value); }}/>
                        <label className="custom-control-label" htmlFor="payrollBonusRadio2">Contract with recurring </label>
                    </div>
                </div>
                <div className='mt-2 d-flex'>
                    <button className='btn btn-primary btn-sm' onClick={getBonusTemplate}>{IMLocalized('get_bonus_template')}</button>
                    <input type="file" id="PayrollImportBonusFile" className="custom-file-input form-control-sm d-none" onChange={(e)=>uploadBonusExcel(e)} 
                    onClick={(e)=>{
                        const {target = {} } = e || {};
                        target.value = '';
                    }} accept=".xlsx" />
                    <label className="btn btn-primary btn-sm mb-0" htmlFor="PayrollImportBonusFile"> {IMLocalized('upload_file')}</label>
                </div>
                {err_msg.length !== 0 &&
                <Table size="sm" className='mt-2 table-bordered' responsive>
                    <thead className='thead-light'>
                        <tr>
                            <th> {IMLocalized('name')}</th>
                            <th> {IMLocalized('error')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {err_msg.map((item,index)=>{
                            return(
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>
                                        {item.msg.map((element,index1)=>{
                                            return(
                                                <div key={index1}><span>{element}</span></div>
                                            )
                                        })}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                }
            </ModalBody>
            <ModalFooter>
                {props.isLoading1 || props.isLoading2 ? <button className="btn btn-danger btn-sm"><i className="fas fa-spinner fa-spin"></i>  {IMLocalized('loading')}</button>:
                <button className="btn btn-danger btn-sm" onClick={props.closeModal}> {IMLocalized('close')}</button>}
                
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalUploadBonus);