import React from "react";
import { Table } from "reactstrap";
import { IMLocalized } from "../../../../language/IMLocalized";



export default function ClaimRemainingTable({ list, detail }){

    const monthNames =[IMLocalized("JAN"), IMLocalized("FEB"), IMLocalized("MAR"), IMLocalized("APR"), IMLocalized("MAY"), IMLocalized("JUN"),
    IMLocalized("JUL"), IMLocalized("AUG"), IMLocalized("SEP"), IMLocalized("OCT"), IMLocalized("NOV"), IMLocalized("DEC")];

    if(list?.is_monthly === true){
        return(
            <>
            <h3>{detail?.name ?? ''}</h3>
            <Table size="sm" className='align-items-center table-bordered bg-white' responsive>
                <thead className='thead-light text-center'>
                    <tr>
                        <th>{IMLocalized('MONTH')}</th>
                        {list.remaining_monthly.map((item,index)=>{
                            return(
                                <th key={index}>
                                    {monthNames[index]} {item.year}
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody className='text-center'>
                    <tr>
                        <td>{IMLocalized('balance')}</td>
                        {list.remaining_monthly.map((item,index)=>{
                            return(
                                <td key={index}>
                                    $ {item}
                                </td>
                            )
                        })}
                    </tr>
                </tbody>
            </Table>
            </>
        )
    }
    else if(list?.is_monthly === false){
        return(
            <>
                <h3>{detail?.name ?? ''}</h3>
                <Table size="sm" className="table-bordered bg-white align-items-center w-25">
                    <tbody className='text-center'>
                        <tr>
                            <td>{IMLocalized('claim_balance')}</td>
                            <td>$ {list.remaining}</td>
                        </tr>
                    </tbody>
                </Table>
            </>
        )
    }

    return null;
}