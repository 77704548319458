import * as wizarvalidatecostcenter from '../../action/wizard/wizard_validate_costcenter';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const wizard_validate_costcenter = (state=initialState,action)=>{
    switch(action.type){
        case wizarvalidatecostcenter.WIZARDVALIDATECOSTCENTER_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case wizarvalidatecostcenter.WIZARDVALIDATECOSTCENTER_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case wizarvalidatecostcenter.WIZARDVALIDATECOSTCENTER_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default wizard_validate_costcenter;