import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATE_EMPBANK_REQUEST = 'CREATE_EMPBANK_REQUEST';
export const CREATE_EMPBANK_SUCCESS = 'CREATE_EMPBANK_SUCCESS';
export const CREATE_EMPBANK_FAILURE = 'CREATE_EMPBANK_FAILURE';
export const CREATE_EMPBANK_RESET = 'CREATE_EMPBANK_RESET';

export const create_employee_bank = (data)=>{
    return dispatch=>{
        dispatch({
            type: 'CREATE_EMPBANK_REQUEST'
        });
        
        if(data.length !== 0){
            for(let i=0;i<data.length;i++){
                const {bank, account_number, bank_default, employee} = data[i];
    
                myaxios.post('employee/bank/',{
                    bank:bank,
                    account_number:account_number,
                    employee:employee
                })
                .then((response)=>{
                    if(bank_default !== null){
                        let idd = response.data.id;
                        myaxios.get(`employee/bank/${idd}/setdefault/`)
                    }
                    
                    dispatch({
                        type:'CREATE_EMPBANK_SUCCESS',
                        payload:response
                    });

                    Toast.fire({
                        type: 'success',
                        title: IMLocalized('create_success')
                    });
                })
                .catch((error)=>{
                    dispatch({
                        type:'CREATE_EMPBANK_FAILURE',
                        payload:error
                    });

                    ErrToast.fire({
                        type:'error',
                        title:IMLocalized('create_failure')
                    });
                })
            }
        }
    }
}

export const reset_create_employee_bank = () => dispatch =>{
    dispatch({
        type:'CREATE_EMPBANK_RESET'
    });
}