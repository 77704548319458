import * as wizardcreateovertime from '../../action/wizard/wizard_create_overtime';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const wizard_create_overtime = (state=initialState,action)=>{
    switch(action.type){
        case wizardcreateovertime.WIZARDCREATEOVERTIME_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case wizardcreateovertime.WIZARDCREATEOVERTIME_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case wizardcreateovertime.WIZARDCREATEOVERTIME_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default wizard_create_overtime;