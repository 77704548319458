import React from "react";
import { IMLocalized } from "../../../../language/IMLocalized";



export default function NoDataTable(){

    return(
        <div className="m-2">
            <h4>{IMLocalized('no_data')}</h4>
        </div>
    )
}