import myaxios from '../../axios';

export const USERCLAIMDETAILGETCLAIMPICTURE_REQUEST = 'USERCLAIMDETAILGETCLAIMPICTURE_REQUEST';
export const USERCLAIMDETAILGETCLAIMPICTURE_SUCCESS = 'USERCLAIMDETAILGETCLAIMPICTURE_SUCCESS';
export const USERCLAIMDETAILGETCLAIMPICTURE_FAILURE = 'USERCLAIMDETAILGETCLAIMPICTURE_FAILURE';
export const USERCLAIMDETAILGETCLAIMPICTURE_RESET = 'USERCLAIMDETAILGETCLAIMPICTURE_RESET';


export const user_claim_detail_get_claim_picture = (id)=>{
    return dispatch =>{
        dispatch({
            type:'USERCLAIMDETAILGETCLAIMPICTURE_REQUEST'
        });

        myaxios.get(`ob/claim/${id}/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'USERCLAIMDETAILGETCLAIMPICTURE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERCLAIMDETAILGETCLAIMPICTURE_FAILURE',
                payload:error
            })
        })
    }
}


export const reset_user_claim_detail_get_claim_picture = () => dispatch =>{
    dispatch({
        type:'USERCLAIMDETAILGETCLAIMPICTURE_RESET'
    });
}