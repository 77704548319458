import React from 'react';
import { IMLocalized } from '../../language/IMLocalized';
import '../../css/loading2.css';

export default function OverlayLoading(props){

    return(
        <div className="overlay1">
            <div className='timer-loader' />
            <br/>
            <div>
            <span style={{color:'#009387'}}>{IMLocalized('loading')}...</span>
            </div>
        </div>
    )
}