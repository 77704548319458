import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Swal from "sweetalert2";
import {flush_otp_blacklist, flush_otp_blacklist_reset} from '../../action/flush_otp_blacklist';
import {get_otp_blacklist} from '../../action/get_otp_blacklist';
import {remove_otp_blacklist, remove_otp_blacklist_reset} from '../../action/remove_otp_blacklist';
import { IMLocalized } from "../../language/IMLocalized";
import classnames from "classnames";
import OtpUserBlackList from "./content/OtpUserBlackList";
import OtpIPBlackList from "./content/OtpIPBlackList";
import { NewMainContext } from "../../context/NewMainContext";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";

function OtpBlacklist(){
    const [activeTab ,setActiveTab] = useState('1');
    const dispatch = useDispatch();
    const items = useSelector(state=>state.get_otp_blacklist.data);
    const isLoading = useSelector(state=>state.get_otp_blacklist.isLoading);
    const flush_success = useSelector(state=>state.flush_otp_blacklist.data);
    const isLoading1 = useSelector(state=>state.flush_otp_blacklist.isLoading);
    const remove_success = useSelector(state=>state.remove_otp_blacklist.data);
    const { addItem} = useContext(NewMainContext);

    useEffect(()=>{
        if( remove_success != null){
            dispatch(get_otp_blacklist());
            dispatch(remove_otp_blacklist_reset());
        }
    },[remove_success,dispatch])

    useEffect(()=>{
        if( flush_success != null){
            dispatch(get_otp_blacklist());
            dispatch(flush_otp_blacklist_reset());
        }
    },[flush_success,dispatch])

    const flushAll=()=>{
        Swal.fire({
            type:'warning',
            title:IMLocalized('remove_all_blacklist'),
            showConfirmButton:true,
            showCancelButton:true,
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!')
        })
        .then(result=>{
            if(result.value){
                dispatch(flush_otp_blacklist());
            }
        })
    }

    const removeUser=(user_or_ip,user)=>{
        Swal.fire({
            type:'warning',
            title:IMLocalized('confirm_remove'),
            showConfirmButton:true,
            showCancelButton:true,
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!')

        })
        .then((result)=>{
            if(result.value){
                dispatch(remove_otp_blacklist(user_or_ip,user));
            }
        })
    }

    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('otp_blacklist')}
                    ]}/>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    <h3 className="mb-0">{IMLocalized('otp_blacklist')}</h3>
                </Col>
                <Col className="col-auto">
                    {isLoading || isLoading1 ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> : 
                    <button className="btn btn-primary btn-sm" onClick={()=>flushAll()}>{IMLocalized('remove_all')}</button>}
                </Col>
            </Row>
            <Nav tabs>
                <NavItem className="cursor-pointer">
                    <NavLink className={classnames({active:activeTab === '1'})} onClick={()=>setActiveTab('1')}>
                        {IMLocalized('user')}
                    </NavLink>
                </NavItem>
                <NavItem className="cursor-pointer">
                    <NavLink className={classnames({active:activeTab === '2'})} onClick={()=>setActiveTab('2')}>
                        {IMLocalized('ip')}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <OtpUserBlackList list={items?.user ?? []} removeUser={removeUser} action={true} />
                </TabPane>
                <TabPane tabId="2">
                    <OtpIPBlackList list={items?.ip ?? []} removeUser={removeUser} action={false}  />
                </TabPane>
            </TabContent>
        </Container>
        </>
    )
}
export default OtpBlacklist;