import myaxios from '../../axios';

export const USERLEAVEGETLEAVEPICTURE_REQUEST = 'USERLEAVEGETLEAVEPICTURE_REQUEST';
export const USERLEAVEGETLEAVEPICTURE_SUCCESS = 'USERLEAVEGETLEAVEPICTURE_SUCCESS';
export const USERLEAVEGETLEAVEPICTURE_FAILURE = 'USERLEAVEGETLEAVEPICTURE_FAILURE';
export const USERLEAVEGETLEAVEPICTURE_RESET = 'USERLEAVEGETLEAVEPICTURE_RESET';


export const user_leave_get_leave_picture =(id)=>{
    return dispatch=>{
        dispatch({
            type:'USERLEAVEGETLEAVEPICTURE_REQUEST'
        });

        myaxios.get(`ob/leave/${id}/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'USERLEAVEGETLEAVEPICTURE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERLEAVEGETLEAVEPICTURE_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_user_leave_get_leave_picture = () => dispatch =>{
    dispatch({
        type:'USERLEAVEGETLEAVEPICTURE_RESET'
    });
}