import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';


const mapStateToProps=(state)=>({
    data:state.user_self.data,
    isLoading:state.user_self.isLoading,
})

function UserEmployeeShg(props){

    const [state,setState] = useState(null);

    useEffect(()=>{
        if(props.data.length !== 0){
            const {shg} = props.data;
            setState(shg);
        }
    },[props.data])

    return(
        <>
        <div className="d-flex justify-content-between mt-2 mb-2 pl-2 emp-tabcontent-title">
            <div className="d-flex align-items-center">
                <i className="far fa-id-card text-primary"/>
                <h4 className="text-primary m-0 pl-2">{IMLocalized('emp_shg')}</h4>
            </div>
        </div>
        {props.isLoading && <div className='p-2'><h4>{IMLocalized('loading')}..</h4></div>}
        {!props.isLoading && state === null && <div className='p-2'><h4>{IMLocalized('no_data')}</h4></div>}
        {!props.isLoading && state !== null &&
        <Row className='mt-2'>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>CDAC</h5>
                    </Col>
                    <Col xs="7">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="profile_cdac1" name='profile_cdac' className="custom-control-input" checked={state.cdac} disabled={true} />
                            <label className="custom-control-label" htmlFor="profile_cdac1"></label>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>ECF</h5>
                    </Col>
                    <Col xs="7">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="profile_ecf1" name='profile_ecf' className="custom-control-input" checked={state.ecf} disabled={true} />
                            <label className="custom-control-label" htmlFor="profile_ecf1"></label>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>MBMF</h5>
                    </Col>
                    <Col xs="7">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="profile_mbmf1" name='profile_mbmf' className="custom-control-input" checked={state.mbmf} disabled={true} />
                            <label className="custom-control-label" htmlFor="profile_mbmf"></label>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>SINDA</h5>
                    </Col>
                    <Col xs="7">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="profile_sinda1" name='profile_sinda' className="custom-control-input" checked={state.sinda} disabled={true} />
                            <label className="custom-control-label" htmlFor="profile_sinda1"></label>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>}
        </>
    )
}
export default connect(mapStateToProps,null)(UserEmployeeShg);