import * as aggridheader from '../../action/report/headcount_get_aggridheader';


const initialState={
    data:null,
    isLoading:false,
    error:null
}

const headcount_get_aggridheader =(state=initialState,action)=>{
    switch(action.type){
        case aggridheader.HEADCOUNTAGGRIDHEADER_REQUEST:
            return{
                ...state,
                isLoading:true,
                error:null,
                data:null
            }
        case aggridheader.HEADCOUNTAGGRIDHEADER_SUCCESS:
            return{
                ...state,
                data:action.payload.data,
                isLoading:false,
            }
        case aggridheader.HEADCOUNTAGGRIDHEADER_FAILURE:
            return{
                ...state,
                isLoading:false,
                error:action.payload
            }
        default:
            return state;
    }
}
export default headcount_get_aggridheader;
