import React, { useEffect, useState } from "react";
import {Container,Row,Col,Input,FormFeedback, FormGroup} from 'reactstrap';
import {useDispatch,useSelector} from 'react-redux';
import Swal from 'sweetalert2';
import {IMLocalized,init} from '../../language/IMLocalized';
import {get_general_logo} from '../../action/get_general_logo';
import a_line from '../../images/a_line.png';
import PasswordChecklist from 'react-password-checklist';
import { usePrevious } from "../../hook/usePrevious";
import { popError } from "../../func/popError";
import {user_reset_password} from '../../action/user_reset_password';

export default function PasswordForm(props){
    const dispatch = useDispatch();
    const general = useSelector(state=>state.get_general_logo);
    const reset = useSelector(state=>state.user_reset_password);
    const [password, setPassword] = useState('');
    const [err_password, setErrPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [err_confirm_password, setErrConfirmPassword] = useState('');
    const prevSuccess = usePrevious(reset.data);
    const prevErrors = usePrevious(reset.errors);    
    const [is_valid, setValid] = useState(false);

    useEffect(()=>{
        document.title = process.env.REACT_APP_TITLE;
        dispatch(get_general_logo());
    },[dispatch])

    useEffect(()=>{
        if(general.data?.data != null){
            const picture = general.data.data;
            const byte = picture.byteLength;
            if(byte === 0){
                document.getElementById("favicon").href = a_line;
            }
            else{
                const url =  process.env.REACT_APP_API_URL;
                document.getElementById("favicon").href = `https://${url}/api/general/logo/`;
            }
        }
    },[general.data])

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== reset.data && reset.data != null){
            Swal.fire({
                title:IMLocalized('update_success'),
                type:'success',
                confirmButtonText:IMLocalized('ok!')
            })
            .then((result)=>{
                if(result.value){
                    window.location.href='/#/'
                }
            })
        }
    },[reset.data,prevSuccess])

    useEffect(()=>{
        if( prevErrors !== undefined && prevErrors !== reset.errors && reset.errors != null){
            if(reset.errors.hasOwnProperty('code')){
                const word = reset.errors['code'].toString();
                popError(word);
            }
            else if(reset.errors.hasOwnProperty('non_field_errors')){
                const word = reset.errors['non_field_errors'].toString();
                popError(word);
            }
        }
    },[prevErrors,reset.errors])

    const onSubmit=()=>{
        if(is_valid){
            const code = props.match.params.data;
            dispatch(user_reset_password(code,password));
        }
    }

    const current  = window.navigator.language;
    const word = current.substring(0,2);
    
    if(word === 'zh') init('zh')
    else init('en')

    return(
        <Container className='login2_main' fluid>
            <Row className='d-flex justify-content-center'>
                <Col lg="6" md="8" sm="9" xs="11" className="user_form_main">
                    <div className="d-flex justify-content-center">
                        <h2 className="mb-0">{IMLocalized('reset_password')}</h2>
                    </div>
                    <FormGroup>
                        <label className="form-control-label" htmlFor="password">{IMLocalized('password')}</label>
                        <Input id="password" type="password" value={password} invalid={err_password.length >0}
                        onChange={(e)=>{
                            const value = e.target.value;
                            setPassword(value);
                            setErrPassword('');
                        }}/>
                        <FormFeedback>{err_password}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <label className="form-control-label" htmlFor="confirm_password">{IMLocalized('confirm_password')}</label>
                        <Input id="confirm_password" type="password" value={confirm_password} invalid={err_confirm_password.length >0} 
                        onChange={(e)=>{
                            const value = e.target.value;
                            setConfirmPassword(value);
                            setErrConfirmPassword('')
                        }}
                        />
                        <FormFeedback>{err_confirm_password}</FormFeedback>
                    </FormGroup>
                    <PasswordChecklist 
                        rules={['minLength','number','capital','match','lowercase','notEmpty','specialChar']}
                        minLength={8}
                        value={password}
                        valueAgain={confirm_password}
                        onChange={(is_valid)=>{setValid(is_valid)}}
                        messages={{
                            minLength: IMLocalized('password_has_8_char'),
                            number: IMLocalized('password_has_a_number'),
                            capital: IMLocalized('password_has_a_capital_letter'),
                            match:IMLocalized('password_match'),
                            notEmpty:IMLocalized('password_not_empty'),
                            lowercase:IMLocalized('password_has_a_lowercase_letter'),
                            specialChar:IMLocalized('password_has_special')
                        }}
                    />
                    {reset.isLoading ? <button className="btn btn-lg btn-block btn2-login mt-2" ><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> : <button className="btn btn-lg btn-block btn2-login mt-2" onClick={onSubmit}>{IMLocalized('submit')}</button>}
                </Col>
            </Row>
        </Container>
    )
}