import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NewMainContext } from '../../context/NewMainContext';
import HolidayDetail from './HolidayDetail';
import HolidayTable from './HolidayTable';

function HolidayList(){
    const create_success = useSelector(state=>state.create_holiday_list.data);
    const {holiday_id, holiday_step, setHolidayID, setHolidayStep} = useContext(NewMainContext);

    useEffect(()=>{
        if( create_success != null){
            const {id} = create_success;
            setHolidayID(id);
            setHolidayStep('1');
            localStorage.setItem('holiday_id',id);
            localStorage.setItem('holiday_step','1');
        }
    },[create_success,setHolidayID,setHolidayStep])


    return(
        <>
        {holiday_step === '0' && <HolidayTable  
        detailHoliday={(id)=>{
            setHolidayID(id);
            setHolidayStep('1');
            localStorage.setItem('holiday_id',id);
            localStorage.setItem('holiday_step','1');
        }} />}
        {holiday_step === '1' && <HolidayDetail id={holiday_id} />}
        </>
    )
}
export default HolidayList;