import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import {create_schedule} from '../../../action/create_schedule';
import { Row, Col, FormGroup, FormFeedback, Input, UncontrolledTooltip, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {IMLocalized} from '../../../language/IMLocalized';
import { usePrevious } from '../../../hook/usePrevious';

const mapStateToProps=(state)=>({
    holidaylist:state.get_holiday_list.data,
    choices:state.schedule_dropdown.calendar_choices,
    calendar_key:state.schedule_dropdown.calendar_key,
    isLoading:state.create_schedule.isLoading,
    errors:state.create_schedule.errors,
    success:state.create_schedule.data,
})

const mapDispatchToProps=(dispatch)=>({
    create_schedule:(name,start_year,working_hours,hour_per_year,array,holiday_list)=>{
        dispatch(create_schedule(name,start_year,working_hours,hour_per_year,array,holiday_list))
    },
})

function ModalCreateSchedule(props){
    const {errors,success,onSuccess} = props;
    const [working_hours,setWorkingHours] = useState('');
    const [name,setName] = useState('');
    const [start_year,setStartYear] = useState('');
    const [err_name, setErrName] = useState('');
    const [err_start_year,setErrStartYear] = useState('');
    const [hour_per_year,setHourPerYear] = useState('2288');
    const [hour_per_year_value,setHourValue] = useState('');
    const [err_hour_per_year_value,setErrHourValue] = useState('');
    const [err_working_hours,setErrWorkingHours] = useState('');
    const [array1,setArray1] = useState([{id:1,value:'o'},{id:2,value:'o'},{id:3,value:'o'},{id:4,value:'o'},{id:5,value:'o'},{id:6,value:'o'},{id:7,value:'o'}]);
    const [array2,setArray2] = useState([{id:1,value:'o'},{id:2,value:'o'},{id:3,value:'o'},{id:4,value:'o'},{id:5,value:'o'},{id:6,value:'o'},{id:7,value:'o'}]);
    const [array3,setArray3] = useState([{id:1,value:'o'},{id:2,value:'o'},{id:3,value:'o'},{id:4,value:'o'},{id:5,value:'o'},{id:6,value:'o'},{id:7,value:'o'}]);
    const [array4,setArray4] = useState([{id:1,value:'o'},{id:2,value:'o'},{id:3,value:'o'},{id:4,value:'o'},{id:5,value:'o'},{id:6,value:'o'},{id:7,value:'o'}]);
    const [holiday_list,setHoliday] = useState('');
    const [err_holiday_list,setErrHoliday] = useState('');
    const prevErrors = usePrevious(errors);
    const prevSuccess = usePrevious(success);

    useEffect(()=>{
        if(props.toggle){
            const today = new Date();
            const year = today.getFullYear();
            setStartYear(year);
            setWorkingHours('');
            setName('');
            setErrName('');
            setErrStartYear('');
            setHourPerYear('2288');
            setHourValue('');
            setErrHourValue('');
            setErrWorkingHours('');
            setArray1([{id:1,value:'o'},{id:2,value:'o'},{id:3,value:'o'},{id:4,value:'o'},{id:5,value:'o'},{id:6,value:'o'},{id:7,value:'o'}]);
            setArray2([{id:1,value:'o'},{id:2,value:'o'},{id:3,value:'o'},{id:4,value:'o'},{id:5,value:'o'},{id:6,value:'o'},{id:7,value:'o'}]);
            setArray3([{id:1,value:'o'},{id:2,value:'o'},{id:3,value:'o'},{id:4,value:'o'},{id:5,value:'o'},{id:6,value:'o'},{id:7,value:'o'}]);
            setArray4([{id:1,value:'o'},{id:2,value:'o'},{id:3,value:'o'},{id:4,value:'o'},{id:5,value:'o'},{id:6,value:'o'},{id:7,value:'o'}]);
            setHoliday('');
            setErrHoliday('');
        }
    },[props.toggle])

    useEffect(()=>{
        if(prevErrors !== errors && prevErrors !== undefined && errors !== null && props.toggle ){
            
            if('name' in errors){
                const word = errors['name'].toString();
                setErrName(word);
            }
        }
    },[prevErrors,errors,props.toggle])

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== success && success !== null && props.toggle){
            onSuccess();
        }
    },[prevSuccess,success,props.toggle,onSuccess])


    const calendar1 =()=>{
        let arr = [];
        if(props.choices.length !== 0){
            arr = array1.map((item,index)=>{
                const id = item.id;
                const value = item.value;
                if(value in props.choices){
                    const result = props.choices[value];
                    const table = 'cursor-pointer ' + result.table;
                    const name = result.name;
                    return <td id={id} style={{padding:'0.75rem 0'}} className={table} key={id} onClick={()=>changeDay1(id)}>{name}</td>
                }
                return false;
            })
        }

        return arr;
    }

    const calendar2 =()=>{
        let arr = [];
        if(props.choices.length !== 0){
            arr = array2.map((item,index)=>{
                const id = item.id;
                const value = item.value;
                if(value in props.choices){
                    const result = props.choices[value];
                    const table = 'cursor-pointer ' + result.table;
                    const name = result.name;
                    return <td style={{padding:'0.75rem 0'}} id={id} className={table} key={id} onClick={()=>changeDay2(id)}>{name}</td>
                }
                return false;
            })
        }

        return arr;
    }

    const calendar3 =()=>{
        let arr = [];
        if(props.choices.length !== 0){
            arr = array3.map((item,index)=>{
                const id = item.id;
                const value = item.value;
                if(value in props.choices){
                    const result = props.choices[value];
                    const table = 'cursor-pointer ' + result.table;
                    const name = result.name;
                    return <td style={{padding:'0.75rem 0'}} id={id} className={table} key={id} onClick={()=>changeDay3(id)}>{name}</td>
                }
                return false;
            })
        }

        return arr;
    }

    const calendar4 =()=>{
        let arr = [];
        if(props.choices.length !== 0){
            arr = array4.map((item,index)=>{
                const id = item.id;
                const value = item.value;
                if(value in props.choices){
                    const result = props.choices[value];
                    const table = 'cursor-pointer ' + result.table;
                    const name = result.name;
                    return <td style={{padding:'0.75rem 0'}} id={id} className={table} key={id} onClick={()=>changeDay4(id)}>{name}</td>
                }
                return false;
            })
        }

        return arr;
    }

    const changeDay1=(id)=>{
        const detail =  array1.find((item)=>item.id === id);
        const data = detail.value;
        const number = props.calendar_key.indexOf(data);
        if(number+1 !== props.calendar_key.length){
            const a = number + 1;
            const value = props.calendar_key[a];
            const new_item = array1.map((item)=>
            item.id === id
            ?{...item,value}
            :item)
            setArray1(new_item);
        }
        else if (number +1 === props.calendar_key.length){
            const a = 0;
            const value = props.calendar_key[a];
            const new_array = array1.map(item=>
                item.id === id
                ?{...item,value:value}
                :item    
            )
            setArray1(new_array)
            
        }
    }

    const changeDay2=(id)=>{
        const detail =  array2.find((item)=>item.id === id);
        const data = detail.value;
        const number = props.calendar_key.indexOf(data);
        if(number+1 !== props.calendar_key.length){
            const a = number + 1;
            const value = props.calendar_key[a];
            const new_item = array2.map((item)=>
            item.id === id
            ?{...item,value}
            :item)
            setArray2(new_item);
        }
        else if (number +1 === props.calendar_key.length){
            const a = 0;
            const value = props.calendar_key[a];
            const new_array = array2.map(item=>
                item.id === id
                ?{...item,value:value}
                :item    
            )
            setArray2(new_array)
            
        }
    }

    const changeDay3=(id)=>{
        const detail =  array3.find((item)=>item.id === id);
        const data = detail.value;
        const number = props.calendar_key.indexOf(data);
        if(number+1 !== props.calendar_key.length){
            const a = number + 1;
            const value = props.calendar_key[a];
            const new_item = array3.map((item)=>
            item.id === id
            ?{...item,value}
            :item)
            setArray3(new_item);
        }
        else if (number +1 === props.calendar_key.length){
            const a = 0;
            const value = props.calendar_key[a];
            const new_array = array3.map(item=>
                item.id === id
                ?{...item,value:value}
                :item    
            )
            setArray3(new_array)
            
        }
    }

    const changeDay4=(id)=>{
        const detail =  array4.find((item)=>item.id === id);
        const data = detail.value;
        const number = props.calendar_key.indexOf(data);
        if(number+1 !== props.calendar_key.length){
            const a = number + 1;
            const value = props.calendar_key[a];
            const new_item = array4.map((item)=>
            item.id === id
            ?{...item,value}
            :item)
            setArray4(new_item);
        }
        else if (number +1 === props.calendar_key.length){
            const a = 0;
            const value = props.calendar_key[a];
            const new_array = array4.map(item=>
                item.id === id
                ?{...item,value:value}
                :item    
            )
            setArray4(new_array)
            
        }
    }

    const createSchedule=()=>{
        let value = 0;
        let invalid = false;
        if(hour_per_year === 'custom'){
            if(hour_per_year_value !== ''){
                value = hour_per_year_value;
            }
            else{
                setErrHourValue(IMLocalized('hours_per_year_value_is_required'))
                invalid = true;
            }
        }
        else{
            value = hour_per_year;
        }
        if(!invalid){
            if(name !== '' && working_hours !== '' && start_year !== '' && holiday_list !== ''){
                const hello = /^(20[0-4]\d|2050)$/ ;
                if(!hello.test(start_year)){
                    setErrStartYear(IMLocalized('invalid_year'))
                }
                else{

                    const arr1 = array1.map((item)=>item.value) 
                    const arr2 = array2.map((item)=>item.value);
                    const arr3 = array3.map((item)=>item.value);
                    const arr4 = array4.map((item)=>item.value);
                    let arr = [];
                    arr.push(arr1);
                    arr.push(arr2);
                    arr.push(arr3);
                    arr.push(arr4);

                    props.create_schedule(name,start_year,working_hours,value,arr,holiday_list)
                }
                
            }
            else{
                if(name === ''){
                    setErrName(IMLocalized('name_is_required'));
                }
                if(working_hours === ''){
                    setErrWorkingHours(IMLocalized('working_hours_is_required'));
                }
                if(start_year === ''){
                    setErrStartYear(IMLocalized('start_year_is_required'));
                }
                if(holiday_list === ''){
                    setErrHoliday(IMLocalized('holiday_list_is_required'))
                }
            }
        } 
    }

    const copyWeek = () =>{
        setArray2(array1);
        setArray3(array1);
        setArray4(array1);
       
    }

    const calculation = ()=>{
        let amount = 0;
        let hour = 0;
        array1.forEach(item=>{
            const a = item.value;
            if(a in props.choices){
                const result = props.choices[a].amount;
                hour += result;
            }
        })

        array2.forEach(item=>{
            const a = item.value;
            if(a in props.choices){
                const result = props.choices[a].amount;
                hour += result;
            }
        })

        array3.forEach(item=>{
            const a = item.value;
            if(a in props.choices){
                const result = props.choices[a].amount;
                hour += result;
            }
        })

        array4.forEach(item=>{
            const a = item.value;
            if(a in props.choices){
                const result = props.choices[a].amount;
                hour += result;
            }
        })

        if(hour !== 0){
            amount = hour/4;
        }

        return amount;

    }

    return(
        <Modal isOpen={props.toggle} className="modal-dialog-centered" style={{maxWidth: '1600px', width: '80%'}} size="lg">
            <ModalHeader toggle={props.closeModal}>{IMLocalized('new_schedule')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('schedule_name')}</label>
                            <Input size="sm" name="name" id="name" type="text" value={name} onChange={(e)=>{setName(e.target.value); setErrName('')}} invalid={err_name.length >0} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' id="modal_create_schedule_tooltip1" htmlFor='start_year'>{IMLocalized('start_year')}</label>
                            <UncontrolledTooltip delay={0} placement="top" target="modal_create_schedule_tooltip1">
                                {IMLocalized('start_year_tooltip')}
                            </UncontrolledTooltip>
                            <Input size="sm" name="start_year" id="start_year" value={start_year} pattern="(20)\d\d" type="number"  onChange={(e)=>{setStartYear(e.target.value); setErrStartYear('')}} invalid={err_start_year.length >0} />
                            <FormFeedback>{err_start_year}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' id='modal_create_schedule_tooltip2' htmlFor='working_hours'>{IMLocalized('working_hours')}</label>
                            <UncontrolledTooltip delay={0} placement="top" target="modal_create_schedule_tooltip2">
                                {IMLocalized('working_hours_tooltip')}
                            </UncontrolledTooltip>
                            <Input id="working_hours" size="sm" type="number" value={working_hours} onChange={(e)=>{setWorkingHours(e.target.value); setErrWorkingHours('')}} invalid={err_working_hours.length >0} />
                            <FormFeedback>{err_working_hours}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='holiday_list'>{IMLocalized('holiday_list')}</label>
                            <Input size="sm" id="holiday_list" name="holiday_list" type="select" value={holiday_list} onChange={(e)=>{setHoliday(e.target.value); setErrHoliday('')}} invalid={err_holiday_list.length >0}>
                                <option value=""></option>
                                {props.holidaylist.map((item,index)=>{
                                    return <option value={item.name} key={index}>{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_holiday_list}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor=''>{IMLocalized('working_hours_in_year')}</label>
                            <div className='d-flex align-items-center'>
                                <div className='custom-control custom-radio'>
                                    <input
                                    className="custom-control-input"
                                    id="create_schedule_custom_radio1"
                                    name="createschedulecustomRadio"
                                    type="radio"
                                    value="2288"
                                    checked = {hour_per_year ==='2288'}
                                    onChange={(e)=>{setHourPerYear(e.target.value)}}
                                    />
                                    <label className="custom-control-label" htmlFor="create_schedule_custom_radio1">
                                        2288
                                    </label>
                                </div>
                                <div className="custom-control custom-radio ml-2">
                                    <input
                                    className="custom-control-input"
                                    id="create_schedule_custom_radio2"
                                    name="createschedulecustomRadio"
                                    type="radio"
                                    value='2080'
                                    checked = {hour_per_year ==='2080'}
                                    onChange={(e)=>{setHourPerYear(e.target.value)}}
                                    />
                                    <label className="custom-control-label" htmlFor="create_schedule_custom_radio2">
                                        2080
                                    </label>
                                </div>
                                <div className="custom-control custom-radio ml-2">
                                    <input
                                    className="custom-control-input"
                                    id="create_schedule_custom_radio3"
                                    name="createschedulecustomRadio"
                                    type="radio"
                                    value='custom'
                                    checked = {hour_per_year ==='custom'}
                                    onChange={(e)=>{setHourPerYear(e.target.value)}}
                                    />
                                    <label className="custom-control-label" htmlFor="create_schedule_custom_radio3">
                                        {IMLocalized('CUSTOM')}
                                    </label>
                                </div>
                                {hour_per_year === 'custom' &&
                                    <FormGroup className="mb-0 ml-2">
                                        <Input size="sm" type="number" min="0" name="hour_per_year_value" value={hour_per_year_value} onChange={(e)=>{setHourValue(e.target.value);setErrHourValue('')}} invalid={err_hour_per_year_value.length > 0} />
                                        <FormFeedback>{err_hour_per_year_value}</FormFeedback>
                                    </FormGroup>
                                }
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <div>
                            <label className='form-control-label'>{IMLocalized('work_days_in_week')} : </label> <span>{calculation()}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col>
                        <h4>{IMLocalized('calendar_table')}</h4>
                    </Col>
                    <Col className="col-auto">
                        <button className='btn btn-primary btn-sm' onClick={copyWeek}>{IMLocalized('copy_week_1')}</button>
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col>
                        <Table size="sm" className="align-items-center table-bordered" responsive>
                            <thead className='thead-light text-center'>
                                <tr>
                                    <th>{IMLocalized('WEEK')}</th>
                                    <th>{IMLocalized('MONDAY')}</th>
                                    <th>{IMLocalized('TUESDAY')}</th>
                                    <th>{IMLocalized('WEDNESDAY')}</th>
                                    <th>{IMLocalized('THURSDAY')}</th>
                                    <th>{IMLocalized('FRIDAY')}</th>
                                    <th>{IMLocalized('SATURDAY')}</th>
                                    <th>{IMLocalized('SUNDAY')}</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                <tr>
                                    <th>{IMLocalized('WEEK')} 1</th>
                                    {calendar1()}
                                </tr>
                                <tr>
                                    <th>{IMLocalized('WEEK')} 2</th>
                                    {calendar2()}
                                </tr>
                                <tr>
                                    <th>{IMLocalized('WEEK')} 3</th>
                                    {calendar3()}
                                </tr>
                                <tr>
                                    <th>{IMLocalized('WEEK')} 4</th>
                                    {calendar4()}
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {props.isLoading ?<button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <div>
                    <button className='btn btn-primary btn-sm' onClick={createSchedule} >{IMLocalized('create')}</button>
                    <button className='btn btn-danger btn-sm' onClick={props.closeModal}>{IMLocalized('close')}</button>
                </div>
                }
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalCreateSchedule);