import React, { useContext, useEffect, useState } from 'react';
import { Card, CardHeader, CardBody,Row,Col,ListGroup,ListGroupItem, Container } from 'reactstrap';
import { useDispatch, useSelector} from 'react-redux';
import {get_company_list} from '../../action/company_list';
import CompanyDetail from './CompanyDetail';
import {delete_company, remove_delete_company} from '../../action/company/delete_company';
import Swal from 'sweetalert2';
import {IMLocalized} from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';
import ModalCreateCompany from './modal/ModalCreateCompany';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

function CompanyList(){

    const [id,setID] = useState(null);
    const [toggle,setToggle] = useState(false);
    const {companylist_step, setCompanyListStep} = useContext(NewMainContext);
    const dispatch = useDispatch();
    const { addItem }  = useContext(NewMainContext);
    const companylist = useSelector(state=>state.company_list.data);
    const isLoading = useSelector(state=>state.company_list.isLoading);
    const delete_success = useSelector(state=>state.delete_company.data);
    const isLoading1 = useSelector(state=>state.delete_company.isLoading);


    useEffect(()=>{
        if(companylist_step === 1){
            const detail = localStorage.getItem('companydetail_id');
            setID(detail);
        }
    },[companylist_step])

    useEffect(()=>{
        if(delete_success !== null){
            dispatch(get_company_list());
            dispatch(remove_delete_company());
        }
    },[delete_success,dispatch])

    const toDetail=(id)=>{
        setCompanyListStep(1);
        setID(id);
        localStorage.setItem('companydetail_id',id);
        localStorage.setItem('companylist_step','1');
    }

    const removeCompany=(id,name)=>{
        Swal.fire({
            type:"warning",
            title:`${IMLocalized('delete_company_msg')} (` + name +') !',
            showCancelButton:true,
            confirmButtonColor:'red'
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_company(id));
            }
        })
    }

    const createSuccess=()=>{
        dispatch(get_company_list());
        setToggle(false);
    }

    return(
        <>
        {isLoading || isLoading1 ? <OverlayLoading/> :
        companylist_step === 0 ?
        <>
        <Container fluid>
            <Row className="border-bottom bg-white" >
                <Col className="p-2">
                    <BreadCrumbList list={
                        [
                            {active:false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                            {active:true, title:IMLocalized('company_list')},
                            
                        ]} />
                </Col>
            </Row>
        </Container>
        <Container className="mt-2">
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <Row >
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('company_list')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <div className="mr-1">
                                        <button onClick={()=>setToggle(true)} className="btn btn-primary btn-sm">{IMLocalized('create')}</button>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        {companylist.length === 0 ? <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>:
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 300px)'}}>
                            {companylist.map((item,index)=>{
                                const number = index +1;
                                return(
                                    <ListGroupItem className="py-2" key={item.id}>
                                        <Row className="align-items-center">
                                            <Col>
                                                {number}. {item.name}
                                            </Col>
                                            <Col className="col-auto mr-2">
                                                <button onClick={()=>toDetail(item.id)} className="btn btn-info btn-sm">{IMLocalized('detail')}</button>
                                                <button onClick={()=>removeCompany(item.id,item.name)} className="btn btn-danger btn-sm">{IMLocalized('delete')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem> 
                                )
                            })}
                        </ListGroup>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
        <ModalCreateCompany toggle={toggle} closeModal={()=>setToggle(false)} onSuccess={createSuccess}  />
        </>:
        <CompanyDetail onBack={()=>{setCompanyListStep(0); localStorage.setItem('companylist_step',0)}} id={id} />}
        </>
    )
}
export default CompanyList;