import myaxios from '../axios';

export const CONTRACTTEMPLATEDROPDOWN_REQUEST = 'CONTRACTTEMPLATEDROPDOWN_REQUEST';
export const CONTRACTTEMPLATEDROPDOWN_SUCCESS = 'CONTRACTTEMPLATEDROPDOWN_SUCCESS';
export const CONTRACTTEMPLATEDROPDOWN_FAILURE = 'CONTRACTTEMPLATEDROPDOWN_FAILURE';


export const get_contract_template_dropdown = () =>{
    return dispatch=>{
        dispatch({
            type: 'CONTRACTTEMPLATEDROPDOWN_REQUEST'
        });
        myaxios.get('contract/template/contracts/dropdown/')
        .then((response) =>{
            dispatch({
                type: 'CONTRACTTEMPLATEDROPDOWN_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type: 'CONTRACTTEMPLATEDROPDOWN_FAILURE',
                payload:error,
               
            })
        })
    }
}