import React, { useEffect, useState } from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { leave_assign_scheme1, reset_leave_assign_scheme1 } from '../../../../action/leave/leave_assign_scheme1';
import { IMLocalized } from '../../../../language/IMLocalized';


export default function ModalAssignLeaveScheme({ toggle, closeModal, onSuccess, id}){

    const schemelist = useSelector(state=>state.get_leave_scheme_list.data);
    const isLoading = useSelector(state=>state.leave_assign_scheme1.isLoading);
    const success = useSelector(state=>state.leave_assign_scheme1.data);
    const errors = useSelector(state=>state.leave_assign_scheme1.errors);
    const daterange = useSelector(state=>state.general_daterange.data);
    const dispatch = useDispatch();
    const [leave_scheme,setScheme] = useState('');
    const [err_leave_scheme,setErrScheme] = useState('');
    const [select_year,setYear] = useState('');
    const [err_select_year,setErrYear] = useState('');
    const [leave_full,setFull] = useState(false);
   

    useEffect(()=>{
        if(toggle){
            setScheme('');
            setErrScheme('');
            setErrYear('');
            setFull(false);
            if(daterange.length !== 0){
                const range = daterange.date_range;
                const leave = range.leave;
                const year = leave.year;
                setYear(year);
            }
            else{
                setYear('');
            }
        }
    },[toggle,daterange])

    useEffect(()=>{
        if( toggle && success != null){
           onSuccess();
           dispatch(reset_leave_assign_scheme1());
        }
    },[ success, toggle, onSuccess, dispatch])

    useEffect(()=>{
        if(toggle && errors !== null){
            if( errors.hasOwnProperty('year')){
                const word = errors['year'].toString();
                setErrYear(word);
            }
            dispatch(reset_leave_assign_scheme1());
        }
    },[toggle,errors,dispatch])

    const onAssign=()=>{
        if(leave_scheme !== ''){
            const year = select_year !== ''  ? select_year : null;
            const employee = [id];
            dispatch(leave_assign_scheme1(leave_scheme,employee,leave_full,year))
        }
        else{
            setErrScheme(IMLocalized('leave_scheme_is_required'));
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('assign_employee_leave_scheme')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='leave_scheme'>{IMLocalized('leave_scheme')}</label>
                            <Input type="select" size="sm" name="leave_scheme" id="leave_scheme" value={leave_scheme} onChange={(e)=>{setScheme(e.target.value);setErrScheme('')}} invalid={err_leave_scheme.length >0}>
                                <option></option>
                                {schemelist.map((item,index)=>{
                                    return <option value={item.id} key={index}>{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_leave_scheme}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='select_year'>{IMLocalized('year')}</label>
                            <Input type="text" size="sm" name="select_year" id="select_year" value={select_year} invalid={err_select_year.length >0} onChange={(e)=>{setYear(e.target.value); setErrYear('')}} />
                            <FormFeedback>{err_select_year}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id='empleavefullproration' name='leave_full' className="custom-control-input" checked={leave_full} onChange={(e)=>{setFull(e.target.checked)}} />
                            <label className="custom-control-label" htmlFor="empleavefullproration">{IMLocalized('full_proration')}</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={onAssign}>{IMLocalized('assign')}</button>
                    <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}