import * as detail from '../../action/approvals/get_calendar_approvals_leave_detail1';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const get_calendar_approvals_leave_detail1 = (state=initialState,action)=>{
    switch(action.type){
        case detail.GETCALENDARAPPROVALSLEAVEDETAIL1_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case detail.GETCALENDARAPPROVALSLEAVEDETAIL1_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case detail.GETCALENDARAPPROVALSLEAVEDETAIL1_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
} 
export default get_calendar_approvals_leave_detail1;