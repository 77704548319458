import * as createrelationship from '../../action/employee/create_employee_relationship';

const initialState={
    isLoading:false,
    data:null,
    errors:null,
}

const create_employee_relationship = (state=initialState,action)=>{
    switch(action.type){
        case createrelationship.NEW_CREATEEMPRELATION_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case createrelationship.NEW_CREATEEMPRELATION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createrelationship.NEW_CREATEEMPRELATION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case createrelationship.NEW_CREATEEMPRELATION_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default create_employee_relationship;