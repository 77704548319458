import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";
import OverlayLoading from "../loading_component/overlay_loading";
import IrasDetail from "./IrasDetail";


export default function IrasList(){

    const { iras_step} = useContext(NewMainContext);

    return(
        <>
        {iras_step === '0' && <IrasTable/>}
        {iras_step === '1' && <IrasDetail/>}
        </>
    )

}


function IrasTable(){
    const list = useSelector(state=>state.get_iras_list.data);
    const isLoading = useSelector(state=>state.get_iras_list.isLoading);

    const {setIrasStep, setIrasId, addItem} = useContext(NewMainContext);

    const toDetail=(id)=>{
        setIrasStep('1');
        setIrasId(id);
        localStorage.setItem('irasdetail_id',id);
        localStorage.setItem('iraslist_step','1');
    }


    const arr1 = list.sort((a,b)=>b.id - a.id);
    return(
        <>
        {isLoading ? <OverlayLoading/>:
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('iras')}
                    ]}
                    />
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('iras')}</h4>
                                </Col>
                            </Row>
                        </CardHeader>
                        {arr1.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {arr1.length !== 0 &&
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                            {arr1.map((item,index)=>{
                                const y = index+1;
                                return(
                                    <ListGroupItem key={item.id} className="py-2">
                                        <Row className="align-items-center">
                                            <Col>
                                                <span>{y}. {item.company_name} ({item.year})</span> 
                                            </Col>
                                            <Col className="col-auto mr-2">
                                                <button className="btn btn-info btn-sm" onClick={()=>toDetail(item.id)}>{IMLocalized('detail')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
        </>}
        </>
    )
}