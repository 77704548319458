import myaxios from '../../axios';

export const GETCLAIMGROUPTEMPLATE_REQUEST = 'GETCLAIMGROUPTEMPLATE_REQUEST';
export const GETCLAIMGROUPTEMPLATE_SUCCESS = 'GETCLAIMGROUPTEMPLATE_SUCCESS';
export const GETCLAIMGROUPTEMPLATE_FAILURE = 'GETCLAIMGROUPTEMPLATE_FAILURE';


export const get_claim_group_template = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETCLAIMGROUPTEMPLATE_REQUEST'
        });

        myaxios.get('claim/mass/template/')
        .then((response)=>{
            dispatch({
                type:'GETCLAIMGROUPTEMPLATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETCLAIMGROUPTEMPLATE_FAILURE',
                payload:error
            })
        })
    }
}