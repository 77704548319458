import * as savecontract from '../../action/employee/save_contract_template';

const initialState={
    isLoading:false,
    errors:null,
    data:null,
}

const save_contract_template = (state=initialState,action)=>{
    switch(action.type){
        case savecontract.SAVECONTACTTEMPLATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case savecontract.SAVECONTACTTEMPLATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case savecontract.SAVECONTACTTEMPLATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case savecontract.SAVECONTACTTEMPLATE_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default save_contract_template;