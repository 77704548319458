

export const percentagelist = [
    {
        value:true,
        display:'Percentage',
        display_zh:'百分比'
    },
    {
        value:true,
        display:'Amount',
        display_zh:'金额'
    }
]