import * as childarrayvalidate from '../../action/wizard_employee/employee_child_array_validate';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const employee_child_array_validate = (state=initialState,action)=>{
    switch(action.type){
        case childarrayvalidate.EMPCHILDARRAYVALIDATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case childarrayvalidate.EMPCHILDARRAYVALIDATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case childarrayvalidate.EMPCHILDARRAYVALIDATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case childarrayvalidate.EMPCHILDARRAYVALIDATE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default employee_child_array_validate;