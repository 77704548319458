import * as mark from '../../action/task/mark_task';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const mark_task = (state=initialState,action)=>{
    switch(action.type){
        case mark.MARKASDONE_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case mark.MARKASDONE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case mark.MARKASDONE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default mark_task;