import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../language/IMLocalized';
import {delete_contract_template, reset_delete_contract_template} from '../../action/delete_contract_template';
import { get_contract_template_list } from '../../action/get_contract_template_list';
import ModalCreateContractTemplate from './modal/ModalCreateContractTemplate';
import ModalViewContractTemplate from './modal/ModalViewContractTemplate';
import ModalEditContractTemplate from './modal/ModalEditContractTemplate';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

function ContractTemplateList(){
   
    const list = useSelector(state=>state.get_contract_template_list.data);
    const delete_success = useSelector(state=>state.delete_contract_template.data);
    const isLoading = useSelector(state=>state.get_contract_template_list.isLoading);

    const { addItem } = useContext(NewMainContext);
    const dispatch = useDispatch();

    const [add_toggle,setAddToggle] = useState(false);
    const [view_toggle,setViewToggle] = useState(false);
    const [view_detail,setViewDetail] = useState(null);
    const [edit_toggle,setEditToggle] = useState(false);
    const [edit_detail,setEditDetail] = useState(null);

    useEffect(()=>{
        if(delete_success != null){
            dispatch(get_contract_template_list());
            dispatch(reset_delete_contract_template());
        }
    },[delete_success,dispatch])

    const deleteTemplate=(id,name)=>{
        Swal.fire({
            type:"warning",
            title:`${IMLocalized('do_you_really_want_to_delete')} \n ${IMLocalized('contract_template')} (${name}) !`,
            showCancelButton:true,
            confirmButtonColor:'red'
        })
        .then((result)=>{
            if(result.value !== undefined){
                dispatch(delete_contract_template(id));
            }
        })
    }

    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('contract_template_list')}
                    ]} />
                </Col>
            </Row>
        </Container>
        <Container className='mt-2'>
            <Row>
                <Col>
                    <Card>
                        <CardHeader className='d-flex justify-content-between align-items-center'>
                            <h4 className='mb-0'>{IMLocalized('contract_template_list')}</h4>
                            <button className='btn btn-primary btn-sm' onClick={()=>setAddToggle(true)}>{IMLocalized('create')}</button>
                        </CardHeader>
                        {isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                        {!isLoading && list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {!isLoading && list.length !== 0 && 
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                            {list.map((item,index)=>{
                                return(
                                    <ListGroupItem className='py-2' key={index}>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <span>{index+1}. <span className='text-primary cursor-pointer' onClick={()=>{setViewDetail(item);setViewToggle(true)}}>{item.name}</span></span>
                                            <div className='mr-2'>
                                                <button className='btn btn-info btn-sm' onClick={()=>{setEditDetail(item);setEditToggle(true)}} >{IMLocalized('edit')}</button>
                                                <button className='btn btn-danger btn-sm' onClick={()=>deleteTemplate(item.id,item.name)}>{IMLocalized('delete')}</button>
                                            </div>
                                        </div>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
        </Container>
        <ModalCreateContractTemplate toggle={add_toggle} 
        closeModal={()=>setAddToggle(false)}
        />
        <ModalViewContractTemplate toggle={view_toggle} detail={view_detail} closeModal={()=>{setViewDetail(null); setViewToggle(false)}} />
        <ModalEditContractTemplate toggle={edit_toggle} detail={edit_detail} closeModal={()=>{setEditDetail(null); setEditToggle(false)}} />
        </>
    )
}
export default ContractTemplateList;