import myaxios from '../../axios';
export const EMPCONTRACTCOSTCENTER_SUCCESS = 'EMPCONTRACTCOSTCENTER_SUCCESS';
export const EMPCONTRACTCOSTCENTER_REQUEST = 'EMPCONTRACTCOSTCENTER_REQUEST';
export const EMPCONTRACTCOSTCENTER_FAILURE = 'EMPCONTRACTCOSTCENTER_FAILURE';


export const employee_contract_costcenter = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'EMPCONTRACTCOSTCENTER_REQUEST'
        });
        
        myaxios.post(`company/costcenter/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'EMPCONTRACTCOSTCENTER_SUCCESS',
                payload:response
                
            }
        )})
        .catch((error) =>{
            dispatch({
                type: 'EMPCONTRACTCOSTCENTER_FAILURE',
                payload: error,
                
            })
        })
    }
}