import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const MARKASDONE_REQUEST = 'MARKASDONE_REQUEST';
export const MARKASDONE_SUCCESS = 'MARKASDONE_SUCCESS';
export const MARKASDONE_FAILURE = 'MARKASDONE_FAILURE';

export const mark_task = (id)=>{
    return dispatch=>{
        dispatch({
            type:'MARKASDONE_REQUEST'
        });

        myaxios.post(`tasks/${id}/Acknowledge/`)
        .then((response)=>{
            dispatch({
                type:'MARKASDONE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            })
        })
        .catch((error)=>{
            dispatch({
                type:'MARKASDONE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}