import * as emp_array_validate from '../../action/employee/create_employee_array_validate';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const create_employee_array_validate = (state=initialState,action)=>{
    switch(action.type){
        case emp_array_validate.EMPARRAYVALIDATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case emp_array_validate.EMPARRAYVALIDATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case emp_array_validate.EMPARRAYVALIDATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default create_employee_array_validate;