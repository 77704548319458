import myaxios from '../../axios';
export const REQUEST_EMPBANK = 'REQUEST_EMPBANK';
export const GET_EMPBANK = 'GET_EMPBANK';
export const FAILURE_EMPBANK = 'FAILURE_EMPBANK';


export const get_employee_bank = ()=>{
    return dispatch =>{
        dispatch({
            type: 'REQUEST_EMPBANK'
        });
        myaxios.get('employee/bank/')
        .then((response)=>{
            dispatch({
                type: 'GET_EMPBANK',
                payload:response
            })
        })
        .catch((error) =>{
            dispatch({
                type: 'FAILURE_EMPBANK',
                payload: error,
            })
        })
    }
}
