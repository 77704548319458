import myaxios from '../../axios';

export const USERAVAILABLELEAVE_REQUEST = 'USERAVAILABLELEAVE_REQUEST';
export const USERAVAILABLELEAVE_SUCCESS = 'USERAVAILABLELEAVE_SUCCESS';
export const USERAVAILABLELEAVE_FAILURE = 'USERAVAILABLELEAVE_FAILURE';

export const user_get_available_leave =()=>{
    return dispatch=>{
        dispatch({
            type:'USERAVAILABLELEAVE_REQUEST'
        });
        
        myaxios.get('leave/self/available/')
        .then((response)=>{
            dispatch({
                type:'USERAVAILABLELEAVE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERAVAILABLELEAVE_FAILURE',
                payload:error
            })
        })
    }
}