import * as contract_status from '../../action/employee/get_employee_contract_status';

const initialState={
    data:[],
    upcoming:[],
    history:[],
    active:[],
    error:{},
    isLoading:false
}

const get_employee_contract_status = (state=initialState,action)=>{
    switch(action.type){
        case contract_status.EMPCONTRACTSTATUS_REQUEST:
            return{
                ...state,
                isLoading: true,
                data:[],
                error:{}
            }
        case contract_status.EMPCONTRACTSTATUS_SUCCESS:
            return{
                ...state,
                data:action.payload.data,
                upcoming:action.payload.data.upcoming,
                history:action.payload.data.history,
                active:action.payload.data.active,
                isLoading:false,
                error:{}
            }
        case contract_status.EMPCONTRACTSTATUS_FAILURE:
            return{
                ...state,
                error:action.payload,
                isLoading:false,
            }
        default:
            return state;
    }
}
export default get_employee_contract_status;
