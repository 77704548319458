import myaxios from '../../axios';
import FormData from 'form-data';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const ADDEMPCERT_REQUEST = 'ADDEMPCERT_REQUEST';
export const ADDEMPCERT_SUCCESS = 'ADDEMPCERT_SUCCESS';
export const ADDEMPCERT_FAILURE = 'ADDEMPCERT_FAILURE';
export const ADDEMPCERT_RESET = 'ADDEMPCERT_RESET';


export const add_employee_certification = (id,name,school,country,acquired_date,expiry_date,notification_duration_days,images)=>{
    return dispatch=>{
        dispatch({
            type:'ADDEMPCERT_REQUEST'
        });

        let formData = new FormData();
        if(images.length !== 0){
            for(let i=0;i<images.length;i++){
                formData.append('upload_images',images[i],images[i].name);
            }
        }
        formData.append('employee',id);
        formData.append('name',name);
        formData.append('school',school);
        formData.append('country',country);
        formData.append('acquired_date',acquired_date);
        formData.append('expiry_date',expiry_date);
        formData.append('notification_duration_days',notification_duration_days);

        myaxios.post('employee/certification/',formData,{headers:{'Content-Type':'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'ADDEMPCERT_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'ADDEMPCERT_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}


export const reset_add_employee_certification = () => dispatch =>{
    dispatch({
        type:'ADDEMPCERT_RESET'
    });
}
