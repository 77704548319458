import React, { useContext } from "react";
import { NewMainContext } from "../../context/NewMainContext";
import LeaveResetDetail from "./LeaveResetDetail";
import LeaveResetList from "./LeaveResetList";





export default function LeaveReset(){

    const {leave_reset_step } = useContext(NewMainContext);


    return(
        <>
        {leave_reset_step === '0' && <LeaveResetList  />}
        {leave_reset_step === '1' && <LeaveResetDetail />}
        </>
    )
}