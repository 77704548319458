import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELETECOMBANK_REQUEST = 'DELETECOMBANK_REQUEST';
export const DELETECOMBANK_SUCCESS = 'DELETECOMBANK_SUCCESS';
export const DELETECOMBANK_FAILURE = 'DELETECOMBANK_FAILURE';
export const DELETECOMBANK_RESET = 'DELETECOMBANK_RESET';

export const delete_company_bank = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELETECOMBANK_REQUEST'
        });

        myaxios.delete(`company/bank/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELETECOMBANK_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            })
        })
        .catch((errors)=>{
            dispatch({
                type:'DELETECOMBANK_FAILURE',
                payload:errors
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_company_bank = ()=> dispatch =>{
    dispatch({
        type:'DELETECOMBANK_RESET'
    });
}