import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';

const mapStateToProps=(state)=>({
    emplist:state.get_employee_list.data,
    isLoading:state.get_general_dashboard.isLoading,
    isLoading1:state.get_employee_list.isLoading,
})

function AbsentTable(props){

    return(
        <Row className=''>
            <Col>
                <Card style={{height:'270px'}}>
                    <CardHeader className="border-0 p-2 bg-white">
                        <h3 className='title-color1 pl-2 pt-2'>
                            {IMLocalized('employee_on_leave')}
                        </h3>
                    </CardHeader>
                    {props.isLoading || props.isLoading1 ?<CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>:null}
                    {!props.isLoading && !props.isLoading1 && props.list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                    {!props.isLoading && !props.isLoading1 && props.list.length !== 0 &&
                    <Table size="sm" className='align-items-center table-bordered' responsive>
                        <thead className='thead-light'>
                            <tr>
                                <th>{IMLocalized('name')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.list.map((item,index)=>{
                                const data = props.emplist.find(el=>el.id === item);

                                return(
                                    <tr key={index}>
                                        <td>{data?.name ?? ''}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>}
                </Card>
            </Col>
        </Row>
    )
}
export default connect(mapStateToProps,null)(AbsentTable);