import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import {extend_employee_probation, reset_extend_employee_probation} from '../../../../action/extend_employee_probation';
import { get_employee_list } from '../../../../action/employee/get_employee_list';
import { get_employee_probation_list } from '../../../../action/get_employee_probation_list';

function ModalExtendProbation({toggle,closeModal,id}){

    const probationlist = useSelector(state=>state.get_employee_probation_list.data);
    const isLoading = useSelector(state=>state.extend_employee_probation.isLoading);
    const extend_success = useSelector(state=>state.extend_employee_probation.data);
    const extend_errors = useSelector(state=>state.extend_employee_probation.errors);
    
    const dispatch = useDispatch();
    const [date,setDate] = useState('');
    const [err_date,setErrDate] = useState('');

    useEffect(()=>{
        if(toggle){
            setDate('');
            setErrDate('');
        }
    },[toggle])

    useEffect(()=>{
        if( extend_success !== null && toggle){
            closeModal();
            dispatch(get_employee_list());
            dispatch(get_employee_probation_list());
            dispatch(reset_extend_employee_probation());
        }
    },[extend_success,toggle,closeModal,dispatch])

    useEffect(()=>{
        if( extend_errors !== null && toggle){
            if(extend_errors.hasOwnProperty('probation_end_date')){
                const word = extend_errors['probation_end_date'].toString();
                console.log(word)
                setErrDate(word);
            }
            //dispatch(reset_extend_employee_probation());
        }
    },[extend_errors,toggle,dispatch])

    let name = '';
    if(probationlist.length !== 0){
        const data = probationlist.find((item)=>item.id === id);
        name = data ? data.name : '-';
    }

    const toExtend=()=>{
        if(date !== ''){
            dispatch(extend_employee_probation(id,date));
        }
        else{
            setErrDate(IMLocalized('probation_end_date_is_required'));
        }
    }
    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={()=>closeModal()}>{IMLocalized('extend')} {IMLocalized('emp_probation_end_date',{name})}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <label className='form-control-label'>{IMLocalized('probation_end_date')}</label>
                    <Input type="date" name="probation_end_date" size="sm" value={date} onChange={(e)=>{setDate(e.target.value); setErrDate('')}} invalid={err_date.length >0} />
                    <FormFeedback>{err_date}</FormFeedback>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                {isLoading ?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button onClick={()=>toExtend()} className="btn btn-primary btn-sm">{IMLocalized('confirm')}</button>
                    <button onClick={()=>closeModal()} className="btn btn-danger btn-sm" >{IMLocalized('close')}</button>
                </>}
                
            </ModalFooter>
        </Modal>
    )
}
export default ModalExtendProbation;