import * as auth from '../action/auth';

const initialState ={
    access:"",
    refresh:undefined,
    errors:{},
    expired:'',
    data:[]
}


const auth1 =(state=initialState,action) =>{

    switch(action.type) {
        case auth.AUTH_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:{}
            };
        case auth.AUTH_LOGIN:
            return{
                ...state,
            access:{
                token: action.payload?.access,
                
            },
            refresh:{
                    token: action.payload?.refresh
            },
            isLoading:false,
            data:action.payload,
            errors:{}
            }
        case auth.AUTH_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default auth1;

export function accessToken(state){
    if(state.access){
        return state.access.token
    }
}

export function isAccessTokenExpired(state){
    if (state.access){
        return false
    }
    return true
}

export function refreshToken(state){
    if(state.refresh){
        return state.refresh.token
    }
}

export function isRefreshTokenExpired(state){
    if(state.refresh){
        return false
    }
    return true
}

export function isAuthenticated(state){
    return !isRefreshTokenExpired(state)
}

export function errors(state){
    return state.errors
}