import myaxios from "../axios";
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from "../swal/SwalToast";

export const REMOVEOTPBLACKLIST_REQUEST = 'REMOVEOTPBLACKLIST_REQUEST';
export const REMOVEOTPBLACKLIST_SUCCESS = 'REMOVEOTPBLACKLIST_SUCCESS';
export const REMOVEOTPBLACKLIST_FAILURE = 'REMOVEOTPBLACKLIST_FAILURE';
export const REMOVEOTPBLACKLIST_RESET = 'REMOVEOTPBLACKLIST_RESET';

export const remove_otp_blacklist = (user_or_ip,user)=>{
    return dispatch=>{
        dispatch({
            type:'REMOVEOTPBLACKLIST_REQUEST'
        });
        
        myaxios.post(`otp/blacklist/remove/`,{
            user_or_ip:user_or_ip,
            user:user
        })
        .then((response)=>{
            dispatch({
                type:'REMOVEOTPBLACKLIST_SUCCESS',
                payload:response
            })
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('remove_success')
            })
        })
        .catch((error)=>{
            dispatch({
                type:'REMOVEOTPBLACKLIST_FAILURE',
                payload:error,
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('remove_failure')
            });
        })
    }
}

export const remove_otp_blacklist_reset = () => dispatch =>{
    dispatch({
        type:'REMOVEOTPBLACKLIST_RESET'
    });
}