import * as aggridheader from '../../action/report/payroll_computation_get_aggridheader';


const initialState={
    data:null,
    isLoading:false,
    error:null
}

const payroll_computation_get_aggridheader = (state=initialState,action)=>{
    switch(action.type){
        case aggridheader.COMPUTATIONAGGRIDHEADER_REQUEST:
            return{
                ...state,
                isLoading:true,
                error:null,
                data:null,
            }
        case aggridheader.COMPUTATIONAGGRIDHEADER_SUCCESS:
            return{
                ...state,
                data:action.payload?.data,
                isLoading:false,
            }
        case aggridheader.COMPUTATIONAGGRIDHEADER_FAILURE:
            return{
                ...state,
                isLoading:false,
                error:action.payload
            }
        default:
            return state;
    }
}
export default payroll_computation_get_aggridheader;
