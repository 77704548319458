import * as deletecontracttemplate from '../action/delete_contract_template';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const delete_contract_template = (state=initialState,action)=>{
    switch(action.type){
        case deletecontracttemplate.DELCONTRACTTEMPLATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            };
        case deletecontracttemplate.DELCONTRACTTEMPLATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            };
        case deletecontracttemplate.DELCONTRACTTEMPLATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case deletecontracttemplate.DELCONTRACTTEMPLATE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default delete_contract_template;