import * as self from '../../action/user/user_self';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}

const user_self = (state=initialState,action)=>{
    switch(action.type){
        case self.USERSELF_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case self.USERSELF_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case self.USERSELF_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default user_self;