import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import Swal from "sweetalert2";
import { create_new_leave_reset, reset_create_new_leave_reset } from "../../action/leave/create_new_leave_reset";
import { leave_reset_list } from "../../action/leave/leave_reset_list";
import { NewMainContext } from "../../context/NewMainContext";
import { popError } from "../../func/popError";
import { IMLocalized } from "../../language/IMLocalized";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";
import OverlayLoading from '../loading_component/overlay_loading';



export default function LeaveResetList(){

    const isLoading = useSelector(state=>state.leave_reset_list.isLoading);
    const list = useSelector(state=>state.leave_reset_list.data);
    const errors = useSelector(state=>state.create_new_leave_reset.errors);
    const success = useSelector(state=>state.create_new_leave_reset.data);
    const dispatch = useDispatch();
    const { addItem, setLeaveResetStep, setLeaveResetListId } = useContext(NewMainContext);


    useEffect(()=>{
        if(success != null){
            dispatch(leave_reset_list());
            dispatch(reset_create_new_leave_reset());
        }
    },[success, dispatch])

    useEffect(()=>{
        if(errors != null){
            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
            else if(errors.hasOwnProperty('date')){
                const word = errors['date'].toString();
                popError(word);
            }
            dispatch(reset_create_new_leave_reset());
        }
    },[errors,dispatch])

    const leaveReset = (index)=>{
        Swal.fire({
            title:IMLocalized('do_you_want_to_process_leave') +"?",
            type:'warning',
            showCancelButton:true,
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
        })
        .then((result)=>{
            if(result.value){
                setLeaveResetStep('1');
                setLeaveResetListId(index);
                localStorage.setItem('leave_reset_step','1');
                localStorage.setItem('leave_resetlist_id',index);
            }
        })
    } 

    const createNewReset=()=>{
        Swal.fire({
            title:IMLocalized('do_you_want_to_create_new_leave_reset'),
            type:'warning',
            showCancelButton:true,
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
        })
        .then((result)=>{
            if(result.hasOwnProperty('value')){
                dispatch(create_new_leave_reset());
            }
        })
    }

    return(
        <>
        {isLoading ? <OverlayLoading/> :
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('leave_reset_list')}
                    ]}/>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('leave_reset_list')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm mr-1" onClick={createNewReset}>{IMLocalized('create')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        {isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4 mb-0">{IMLocalized('loading')}..</span></CardBody>}
                        {!isLoading && list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4 mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {!isLoading && list.length !== 0 &&
                        <ListGroup className="list scrollbarstyle1" flush style={{overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                            {list.sort((a,b)=> b.year - a.year).map((item,index)=>{
                                const number = index + 1;
                                return(
                                    <ListGroupItem className="py-2" key={item.id}>
                                        <Row className="align-items-center">
                                            <Col>
                                                <span>{number}. {item.year}</span>
                                            </Col>
                                            <Col className="col-auto mr-2">
                                                <button onClick={()=>leaveReset(item.year)} className="btn btn-info btn-sm">{IMLocalized('detail')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
        </Container>
        </>}
        </>
    )
}