import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import LeaveCalendarColor from "./LeaveCalendarColor";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interaction from '@fullcalendar/interaction';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { IMLocalized } from "../../../language/IMLocalized";
import LeaveCalendarTypeCheckbox from "./LeaveCalendarTypeCheckbox";
import ModalLeaveDetail1 from "../Modal/ModalLeaveDetail1";
import { get_hr_employee_all_leave_detail } from "../../../action/employee/get_hr_employee_all_leave_detail";
import ModalEmpApplyLeave from "../../employee/hr_employee/modal/ModalEmpApplyLeave";
import { NewMainContext } from "../../../context/NewMainContext";
import LeaveCalendarStatusCheckbox from "./LeaveCalendarStatusCheckbox";

export default function LeaveCalendarTable({ view_detail, setViewDetail}){

    const { leave_calendar_reset, setLeaveCalendarReset, setLeaveCalendarApplyDetail, leave_calendar_apply_detail } = useContext(NewMainContext);
    const dispatch = useDispatch();
    // const applylist = useSelector(state=>state.get_apply_leave_list.data);
    const applylist = useSelector(state=>state.manager_leave_list.data);
    const colorlist = useSelector(state=>state.get_general_color.data);
    const leavelist = useSelector(state=>state.get_leave_list.data);
    const [ defaultlist, setDefaultList] = useState([]);
    const [ type_all, setTypeAll] = useState(true);
    const [ type_checkbox, setTypeCheckbox] = useState([]);
    const [ type_toggle, setTypeToggle] = useState(false);
    const [ currentDate, setCurrentDate] = useState('');
    const [ status_toggle, setStatusToggle] = useState(false);
    const [ status_option, setStatusOption] = useState('ALL');
    const [ detail_toggle, setDetailToggle] = useState(false);
    const [ selectedStartDate, setSelectedStartDate] = useState(null);
    const [ selectedStartDate1, setSelectedStartDate1] = useState(null);
    const [ apply_toggle, setApplyToggle] = useState(false);
    const [ apply_emp, setApplyEmployee ] = useState(null);
    const [ apply_leave,setApplyLeave] = useState(null);
    const calendarRef = useRef();

    useEffect(()=>{
        if(leave_calendar_apply_detail !== null){
            const {employee, leave_setting} = leave_calendar_apply_detail;
            setApplyLeave(leave_setting);
            setApplyEmployee(employee);
            setApplyToggle(true);
            setLeaveCalendarApplyDetail(null);
        }
    },[setLeaveCalendarApplyDetail, leave_calendar_apply_detail])

    useEffect(()=>{
        if(leave_calendar_reset === true){
            setTypeAll(true);
            setTypeCheckbox([]);
            setCurrentDate('');
            setStatusOption('ALL');
            setStatusToggle(false);
            setSelectedStartDate(null);
            setSelectedStartDate1(null);
            setTypeToggle(false);
            setLeaveCalendarReset(false);
        }
    },[leave_calendar_reset,setLeaveCalendarReset])

    useEffect(()=>{
        if(view_detail != null){
            calendarRef.current.calendar.gotoDate(view_detail);
            calendarRef.current.calendar.changeView('dayGridWeek');
            setCurrentDate(calendarRef.current.calendar.view.title);
            setViewDetail(null);

        }
    },[view_detail,setViewDetail])

    useEffect(()=>{
        const arr = leavelist.map((item)=>{
            const {name, id} = item;
            return { name, id, check: true}
        });

        setTypeCheckbox(arr);

    },[leavelist])

    useEffect(()=>{
        let arr = [];
        console.log(applylist.leaves)
        if(applylist.length !== 0 && colorlist.length !== 0 ){
            arr = applylist.leaves && applylist.leaves.map((item)=>{
                const { status, to_date, employee, leave_setting } = item;
                const { data} = colorlist;
                let backgroundColor;
                if(status === 'PENDING'){
                    
                    backgroundColor = data.PENDING;
                }
                else if (status ==='APPROVED'){
                    backgroundColor = data.APPROVED;
                }
                else if(status === 'REJECTED'){
                    backgroundColor = data.REJECTED;
                }
                else if(status=== 'CANCELLED'){
                    backgroundColor = data.CANCELLED;
                }
                else if(status === 'CONSUMED'){
                    backgroundColor = data.CONSUMED;
                }

                const end_date = moment(to_date, 'YYYY-MM-DD').add(1,'days').format('YYYY-MM-DD');
                const title = `${employee.name} - ${leave_setting.name}`;
                return {...item, title, backgroundColor, leave_id:leave_setting.id, start:item.from_date, end: end_date,}
            })
        }

        setDefaultList(arr);
        setTypeAll(true);
        setStatusOption('ALL');

    },[applylist,colorlist])


    useEffect(()=>{
        if(calendarRef?.current != null){
            setCurrentDate(calendarRef.current.calendar.view.title);
        }
    },[calendarRef])
   

    const changeView =(newView)=>{
        calendarRef.current.calendar.changeView(newView);
        setCurrentDate(calendarRef.current.calendar.view.title);
    }

    const changeTypeCheckbox=(index)=>{
        const data = [...type_checkbox];
        data[index].check = !data[index].check;
        setTypeCheckbox(data);

        const checked = data.every(element => element.check === true);
        setTypeAll(checked);
    }

    const prevBtn = (index) =>{
        if(index === 'prev'){
            calendarRef.current.calendar.prev();
        }
        else if (index === 'current'){
            calendarRef.current.calendar.today();
        }
        else if (index === 'next'){
            calendarRef.current.calendar.next();
        }
        setCurrentDate(calendarRef.current.calendar.view.title);
    }

    const radioChange =(e)=>{
        setStatusOption(e.target.value);
    }

    const setTypeAllCheckbox=(e)=>{
        const { checked } = e.target;
        setTypeAll(checked);

        const arr = type_checkbox.map((item)=>{
            return {...item, check:checked}
        });
        setTypeCheckbox(arr);
    }

    const selectToApply=(info)=>{
        setSelectedStartDate(moment(info.startStr));
        setSelectedStartDate1(info.startStr);
        setApplyToggle(true);
    }

    const eventClicked=({ event })=>{
        const id = event._def.publicId;
        dispatch(get_hr_employee_all_leave_detail(id));
        setDetailToggle(true);
    }

    const rowData= useMemo(()=>{
        const list = [];
        for(const type of type_checkbox){
            const { check, id } = type;
            if(check === true){
                const detail = defaultlist.filter(item=>item.leave_setting.id === id);
                list.push(...detail);
            }
        }

        return status_option === 'ALL' ? list : list.filter(item=>item.status === status_option);
    },[type_checkbox,defaultlist,status_option])

    return(
        <>
       <Row className="mt-4 d-flex justify-content-center">
            <Col lg="11">
                <Row>
                    <Col>
                        <Card className="card-calendar">
                            <CardHeader className="bg-info">
                                <Row>
                                    <Col lg="4">
                                        <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0 mr-1">
                                            {currentDate} Manager
                                        </h6>
                                    </Col>
                                    <Col className="mt-3 mt-lg-0 text-lg-right" lg="8">
                                        <Button className="fullcalendar-btn-prev btn-neutral" color="default" size="sm" onClick={()=>prevBtn('prev')}>
                                            <i className="fas fa-angle-left"  />
                                        </Button>
                                        <Button className="fullcalendar-btn-today btn-neutral" color="default" size="sm" onClick={()=>prevBtn('current')}>
                                            {IMLocalized('current')}
                                        </Button>
                                        <Button className="fullcalendar-btn-next btn-neutral" color="default" size="sm" onClick={()=>prevBtn('next')}>
                                            <i className="fas fa-angle-right" />
                                        </Button>
                                        <Button className="btn-neutral" color='default' data-calendar-view='month' onClick={()=>changeView('dayGridMonth')} size="sm">
                                            {IMLocalized('MONTH')}
                                        </Button>
                                        <Button className="btn-neutral" color="default" data-calendar-view="basicWeek" onClick={()=>changeView('dayGridWeek')} size="sm">
                                            {IMLocalized('WEEK')}
                                        </Button>
                                        <Button className="btn-neutral" color="default" data-calendar-view="basicDay" onClick={()=>changeView('dayGridDay')} size="sm">
                                            {IMLocalized('DAY')}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Dropdown size="sm" direction="down" isOpen={type_toggle} toggle={()=>setTypeToggle(prevState=>!prevState)}>
                                            <DropdownToggle className="bg-neutral text-primary" caret aria-expanded={type_toggle}>
                                                {IMLocalized('filter_by_leave_type')}
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-scroll">
                                                <div className="custom-control custom-checkbox ml-2">
                                                    <input type="checkbox" id="hrcalendarfiltertypeall" className="custom-control-input" checked={type_all} onChange={setTypeAllCheckbox}  />
                                                    <label className="custom-control-label" htmlFor="hrcalendarfiltertypeall">{IMLocalized('select_all')}</label>
                                                </div>
                                                <DropdownItem divider/>
                                                {type_checkbox.map((item,index)=>{
                                                    
                                                    return(
                                                        <LeaveCalendarTypeCheckbox {...item} index={index} changeTypeCheckbox={changeTypeCheckbox} key={item.id} />
                                                    )
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                        <LeaveCalendarStatusCheckbox status_toggle={status_toggle} setStatusToggle={setStatusToggle} status_option={status_option} radioChange={radioChange} />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <div className="calendar">
                                <FullCalendar
                                    events={rowData}
                                    height={700}
                                    defaultView="dayGridMonth"
                                    selectable={true}
                                    locale={localStorage.getItem('language')}
                                    editable={false}
                                    ref={calendarRef}
                                    className="calendar"  
                                    plugins={[dayGridPlugin,interaction]} 
                                    select={selectToApply}
                                    eventClick={eventClicked}
                                />
                            </div>
                        </Card>
                        
                    </Col>
                    <Col lg="2">
                        
                        <LeaveCalendarColor/>
                    </Col>
                </Row>
            </Col>
       </Row> 
       <ModalLeaveDetail1 toggle={detail_toggle} closeModal={()=>setDetailToggle(false)} toprofile={true} />
       <ModalEmpApplyLeave toggle={apply_toggle} closeModal={()=>setApplyToggle(false)} startDate={selectedStartDate} start_date={selectedStartDate1}  id={apply_emp} leave_setting={apply_leave} />
       </>
    )
}