import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELEMPCERTIFICATION_REQUEST = 'DELEMPCERTIFICATION_REQUEST';
export const DELEMPCERTIFICATION_SUCCESS = 'DELEMPCERTIFICATION_SUCCESS';
export const DELEMPCERTIFICATION_FAILURE = 'DELEMPCERTIFICATION_FAILURE';
export const DELEMPCERTIFICATION_RESET = 'DELEMPCERTIFICATION_RESET';

export const delete_employee_certification =(id)=>{
    return dispatch=>{
        dispatch({
            type:'DELEMPCERTIFICATION_REQUEST'
        });

        myaxios.delete(`employee/certification/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELEMPCERTIFICATION_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELEMPCERTIFICATION_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_employee_certification = () => dispatch =>{
    dispatch({
        type:'DELEMPCERTIFICATION_RESET'
    })
}