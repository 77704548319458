import * as availableleave from '../../action/employee/employee_get_available_leave_options';

const initialState={
    isLoading:false,
    errors:null,
    data:null,

}

const employee_get_available_leave_options = (state=initialState,action)=>{
    switch(action.type){
        case availableleave.EMPGETAVALEAVEOPTION_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case availableleave.EMPGETAVALEAVEOPTION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case availableleave.EMPGETAVALEAVEOPTION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default employee_get_available_leave_options;