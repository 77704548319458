import myaxios from '../../axios';

export const GETEMPLOYEERESIGNTODAY_REQUEST = 'GETEMPLOYEERESIGNTODAY_REQUEST';
export const GETEMPLOYEERESIGNTODAY_SUCCESS = 'GETEMPLOYEERESIGNTODAY_SUCCESS';
export const GETEMPLOYEERESIGNTODAY_FAILURE = 'GETEMPLOYEERESIGNTODAY_FAILURE';

export const get_employee_resign_today = () =>{
    return dispatch=>{
        dispatch({
            type:'GETEMPLOYEERESIGNTODAY_REQUEST'
        });
        myaxios.post('employee/resign_today/')
        .then((response)=>{
            dispatch({
                type: 'GETEMPLOYEERESIGNTODAY_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPLOYEERESIGNTODAY_FAILURE',
                payload:error
            })
        })
    }
}