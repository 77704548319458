import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { reset_resign_employee, resign_employee } from '../../../../action/employee/resign_employee';
import { IMLocalized } from '../../../../language/IMLocalized';


export default function ModalResgin({ toggle, closeModal, onSuccess, basic_pay, id, }){
    
    const dispatch = useDispatch();
    const min_resign_date = useSelector(state=>state.get_min_resign_date.data);
    const isLoading = useSelector(state=>state.resign_employee.isLoading);
    const success = useSelector(state=>state.resign_employee.data);

    const [resign_date,setResignDate] = useState('');
    const [err_resign_date,setErrResignDate] = useState('');
    const [month,setMonth] = useState(0);
    const [err_month,setErrMonth] = useState('');
    const [amount,setAmount] = useState(0);
    const [err_amount,setErrAmount] = useState('');
    const [notes,setNotes] = useState('');
    const [err_notes,setErrNotes] = useState('');
    const [blacklist,setBlackList] = useState(false);

    useEffect(()=>{
        if(toggle){
            setResignDate('');
            setErrResignDate('');
            setMonth(0);
            setErrMonth('');
            setAmount(0);
            setErrAmount('');
            setNotes('');
            setErrNotes('');
            setBlackList(false);
        }
    },[toggle])

    useEffect(()=>{
        if( success !== null && toggle){
            onSuccess();
            dispatch(reset_resign_employee());
        }
    },[toggle,success,onSuccess,dispatch])

    useEffect(()=>{
        if(basic_pay && month !== ''){
            const amount = basic_pay * month;
            setAmount(amount);
        }
    },[basic_pay,month])

    const resignEmployee = ()=>{
        if(resign_date !== '' && amount !== '' && month !== '' && notes !== ''){
            Swal.fire({
                title:IMLocalized('resign_employee_confirmation'),
                text:IMLocalized('revert_msg'),
                type:'warning',
                showCancelButton:true,
                cancelButtonColor:'#3085d6',
                confirmButtonColor:'#d33',
                confirmButtonText:IMLocalized('yes!'),
                cancelButtonText:IMLocalized('no!'),
            })
            .then((result)=>{
                if(result.value){
                    dispatch(resign_employee(id,resign_date,month,amount,notes,blacklist));
                }
            })
        }
        else{
            if(resign_date === ''){
                setErrResignDate(IMLocalized('resign_date_is_required'));
            }
            if(month === ''){
                setErrMonth(IMLocalized('severance_month_is_required'));
            }
            if(amount === ''){
                setErrAmount(IMLocalized('severance_amount_is_required'));
            }
            if(notes === ''){
                setErrNotes(IMLocalized('note_is_required'));
            }
        }
    }

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('resign')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='resign_date'>{IMLocalized('resign_date')}</label>
                            <Input size="sm" type="date" id="resign_date" min={min_resign_date !== null ? min_resign_date : null} value={resign_date} onChange={(e)=>{setResignDate(e.target.value); setErrResignDate('')}} invalid={err_resign_date.length >0} />
                            <FormFeedback>{err_resign_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='month'>{IMLocalized('severance_month')}</label>
                            <Input size="sm" type="number" step="1" min="0" id="month" name="month" value={month} onChange={(e)=>{setMonth(e.target.value); setErrMonth('')}} invalid={err_month.length > 0} />
                            <FormFeedback>{err_month}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='amount'>{IMLocalized('severance_amount')}</label>
                            <Input size="sm" type="number" step="1" min="0" id="amount" name="amount" value={amount} onChange={(e)=>{setAmount(e.target.value) ; setErrAmount('')}} invalid={err_amount.length > 0} />
                            <FormFeedback>{err_amount}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='notes'>{IMLocalized('note')}</label>
                            <Input size="sm" type="textarea" id="notes" name="notes" value={notes} onChange={(e)=>{setNotes(e.target.value); setErrNotes('')}} invalid={err_notes.length > 0} />
                            <FormFeedback>{err_notes}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="12">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id='blacklist' className="custom-control-input" name="blacklist" checked={blacklist} onChange={(e)=>setBlackList(e.target.checked)}  />
                            <label className="custom-control-label" htmlFor='blacklist'>{IMLocalized('blacklist')}</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button onClick={resignEmployee} className="btn btn-primary btn-sm">{IMLocalized('confirm')}</button>
                <button onClick={closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}