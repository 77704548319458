import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEEMPEDUCATION_REQUEST = 'UPDATEEMPEDUCATION_REQUEST';
export const UPDATEEMPEDUCATION_SUCCESS = 'UPDATEEMPEDUCATION_SUCCESS';
export const UPDATEEMPEDUCATION_FAILURE = 'UPDATEEMPEDUCATION_FAILURE';
export const UPDATEEMPEDUCATION_RESET = 'UPDATEEMPEDUCATION_RESET';

export const update_employee_education = (id,level,institute,school,country,certification,gpa,highest_education,graduation_date,enrollment_date,notes)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEEMPEDUCATION_REQUEST'
        });

        myaxios.patch(`employee/education/${id}/`,{
            level:level,
            institute:institute,
            school:school,
            country:country,
            certification:certification,
            gpa:gpa,
            highest_education:highest_education,
            graduation_date:graduation_date,
            enrollment_date:enrollment_date,
            notes:notes
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEEMPEDUCATION_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEEMPEDUCATION_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_employee_education = () => dispatch =>{
    dispatch({
        type:'UPDATEEMPEDUCATION_RESET'
    });
}
