import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { reset_update_employee_delegation_list, update_employee_delegation_list } from '../../../../action/employee/update_employee_delegation_list';
import { get_employee_delegation_list } from '../../../../action/employee/get_employee_delegation_list';
import { popError } from '../../../../func/popError';

const initialState={
    start_date:'',
    err_start_date:'',
    err_end_date:'',
    end_date:'',
    delegation:'',
    err_delegation:'',
    id:''
}

export default function ModalEditDelegation({ detail, toggle, closeModal}){

    const dispatch = useDispatch();
    const managerlist = useSelector(state=>state.get_user_manager_list.data);
    const isLoading = useSelector(state=>state.update_employee_delegation_list.isLoading);
    const success = useSelector(state=>state.update_employee_delegation_list.data);
    const errors = useSelector(state=>state.update_employee_delegation_list.errors);
    const data = useSelector(state=>state.user_self.data);
 
    const [state,setState] = useState(initialState);
    const [emp_id,setEmpID] = useState(null);


    useEffect(()=>{
        if(data.length !== 0 ){
            setEmpID(data?.id);
        }
    },[data])

    useEffect(()=>{
        if(toggle && success != null){
            closeModal();
            dispatch(get_employee_delegation_list());
            dispatch(reset_update_employee_delegation_list());
        }
    },[toggle, success, closeModal,dispatch])

    useEffect(()=>{
        if(toggle && errors != null){
            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
            dispatch(reset_update_employee_delegation_list());
        }
    },[toggle,errors,dispatch])

    useEffect(()=>{
        if(toggle && detail){
            setState(prevState=>({
                ...prevState,
               id: detail.id,
               delegation: detail.delegation,
               start_date: detail.start_date,
               end_date: detail.end_date,
               err_delegation:'',
               err_start_date:'',
               err_end_date:'', 
            }))
        }
    },[toggle,detail])

    const updateDelegation=()=>{
        if(state.start_date !== '' && state.end_date !== '' && state.delegation !== ''){
            dispatch(update_employee_delegation_list(state.id,state.start_date,state.end_date,state.delegation));
        }
        else{
            setState(prevState=>({
                ...prevState,
                err_delegation:state.delegation === '' ? IMLocalized('delegation_is_required') : '',
                err_end_date:state.end_date === '' ? IMLocalized('end_date_is_required') : '',
                err_start_date:state.start_date === '' ? IMLocalized('start_date_is_required')  : '',
            }))
        }
    }

    const managerlist1 = useMemo(()=>{
        return managerlist.filter(item=>item.id !== emp_id);
    },[managerlist,emp_id])

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('edit_delegation')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='start_date'>{IMLocalized('start_date')}</label>
                            <Input size="sm" type="date" id="start_date" value={state.start_date} invalid={state.err_start_date.length > 0}  
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,start_date:value,err_start_date:''}));
                            }}
                            />
                            <FormFeedback>{state.err_start_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='end_date'>{IMLocalized('end_date')}</label>
                            <Input size="sm" type="date" id="end_date" value={state.end_date} invalid={state.err_end_date.length >0 } 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,end_date:value,err_end_date:''}));
                            }}
                            />
                            <FormFeedback>{state.err_end_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='delegation'>{IMLocalized('delegation')}</label>
                            <Input size="sm" type="select" id="delegation" value={state.delegation} invalid={state.err_delegation.length >0} >
                                <option></option>
                                {managerlist1.map((item)=>{
                                    return <option value={item.id} key={item.id}>{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{state.err_delegation}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={updateDelegation}>{IMLocalized('update')}</button>
                    <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}