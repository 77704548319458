import myaxios from '../../axios';
export const EMPCONTRACTJOBLEVEL_SUCCESS = 'EMPCONTRACTJOBLEVEL_SUCCESS';
export const EMPCONTRACTJOBLEVEL_REQUEST = 'EMPCONTRACTJOBLEVEL_REQUEST';
export const EMPCONTRACTJOBLEVEL_FAILURE = 'EMPCONTRACTJOBLEVEL_FAILURE';


export const employee_contract_joblevel = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'EMPCONTRACTJOBLEVEL_REQUEST'
        });
        
        myaxios.post(`company/joblevel/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'EMPCONTRACTJOBLEVEL_SUCCESS',
                payload:response
            }
        )})
        .catch((error) =>
            dispatch({
                type: 'EMPCONTRACTJOBLEVEL_FAILURE',
                payload: error,
            })
        )
    }
}