import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, Col, Container, ListGroup, ListGroupItem, Row ,CardBody, } from 'reactstrap';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../language/IMLocalized';
import { delete_schedule } from '../../action/delete_schedule';
import { getScheduleList } from '../../action/schedule_list';
import { usePrevious } from '../../hook/usePrevious';
import ModalEditSchedule from './modal/ModalEditSchedule';
import ModalCreateSchedule from './modal/ModalCreateSchedule';
import { popError } from '../../func/popError';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';


const mapStateToProps=(state)=>({
    isLoading:state.schedule_list.isLoading,
    schedulelist:state.schedule_list.data,
    delete_success:state.delete_schedule.data,
    delete_errors:state.delete_schedule.errors,

})

const mapDispatchToProps=(dispatch)=>({
    delete_schedule:(id)=>{
        dispatch(delete_schedule(id))
    },
    get_schedule_list:()=>{
        dispatch(getScheduleList())
    }
})

function ScheduleList(props){
    const {delete_success,get_schedule_list,delete_errors} = props;
    const [edit_toggle,setEditToggle] = useState(false);
    const [edit_detail,setEditDetail] = useState(null);
    const [create_toggle,setCreateToggle] = useState(false);
    const prevDelete = usePrevious(delete_success);
    const prevErrors = usePrevious(delete_errors);
    const { addItem } = useContext(NewMainContext);

    useEffect(()=>{
        if(prevErrors !== undefined && prevErrors !== delete_errors && delete_errors !== null){
            const {data} = delete_errors.response;
            popError(data)
        }
    },[prevErrors,delete_errors])

    useEffect(()=>{
        if(prevDelete !== undefined && prevDelete !== delete_success && delete_success !== null){
            get_schedule_list();
        }
    },[prevDelete,delete_success,get_schedule_list])

    const deleteSchedule=(id,name)=>{
        Swal.fire({
            type:"warning",
            title:`${IMLocalized('delete_schedule_confirmation')} (` + name +')?',
            showCancelButton:true,
            confirmButtonColor:'red',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!')
        })
        .then((result)=>{
            if(result.value){
                props.delete_schedule(id);
            }
        })
    }

    return(
        <>
            <Container fluid>
                <Row className="border-bottom bg-white">
                    <Col className="p-2">
                        <BreadCrumbList list={[
                            { active: false, title: IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                            { active: true, title: IMLocalized('schedule_list')}
                        ]}/>
                    </Col>
                </Row>
            </Container>
            <Container className='mt-2'>
                <Card>
                    <CardHeader className='d-flex justify-content-between align-items-center'>
                        <h4 className="mb-0">{IMLocalized('schedule_list')}</h4>
                        <button className="btn btn-primary btn-sm" onClick={()=>setCreateToggle(true)}>{IMLocalized('create')}</button>
                    </CardHeader>
                    {props.isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                    {!props.isLoading && props.schedulelist.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                    {!props.isLoading && props.schedulelist.length !== 0 &&
                    <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                        {props.schedulelist.map((item,index)=>{
                            return(
                                <ListGroupItem className="py-2" key={item.id}>
                                    <Row className="align-items-center">
                                        <Col>
                                            {index+1}. {item.name}
                                        </Col>
                                        <Col className="col-auto mr-2">
                                            <button className="btn btn-info btn-sm" 
                                            onClick={()=>{
                                                const detail = props.schedulelist.find((item1,index)=>item1.id === item.id );
                                                setEditToggle(true);
                                                setEditDetail(detail ? detail : null);    
                                            }}>{IMLocalized('detail')}</button>
                                            <button onClick={()=>deleteSchedule(item.id,item.name)} className="btn btn-danger btn-sm">{IMLocalized('delete')}</button>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            )
                        })}
                    </ListGroup>}
                </Card>
            </Container>
            <ModalEditSchedule toggle={edit_toggle} detail={edit_detail} closeModal={()=>{setEditToggle(false); setEditDetail(null)}} onSuccess={()=>{setEditToggle(false); setEditDetail(null); props.get_schedule_list() }} />
            <ModalCreateSchedule toggle={create_toggle} closeModal={()=>setCreateToggle(false)} onSuccess={()=>{setCreateToggle(false); props.get_schedule_list()}}  />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ScheduleList);