import * as bank from '../../action/company/get_company_bank';

const initialState={
    data:[],
    isLoading:false,
    errors:null
}

const get_company_bank = (state=initialState,action)=>{
    switch(action.type){
        case bank.GETCOMBANK_REQUEST:
            return{
                isLoading:true,
                data:[],
                errors:null
            }
        case bank.GETCOMBANK_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case bank.GETCOMBANK_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_company_bank;

function sortBank(){

    return function(a,b){
        if(a.default === b.default){
            return 0;
        }
        else if (a.default === null){
            return 1;
        }
        else if (b.default === null){
            return -1;
        }
        else{
            return a < b ? -1 : 1;
        }
    }
}

export const get_company_bank_detail = (state,id)=>{
    const { data } = state.get_company_bank;

    return data.filter(item=>item.parent === id).sort(sortBank());
}