import * as wizarvalidatedepartment from '../../action/wizard/wizard_validate_department';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const wizard_validate_department= (state=initialState,action)=>{
    switch(action.type){
        case wizarvalidatedepartment.WIZARDVALIDATEDEPARTMENT_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case wizarvalidatedepartment.WIZARDVALIDATEDEPARTMENT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case wizarvalidatedepartment.WIZARDVALIDATEDEPARTMENT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default wizard_validate_department;