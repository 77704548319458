import React, { useState } from "react";
import { Button } from "reactstrap";
import { IMLocalized } from "../../language/IMLocalized";
import ModalEmpApplyClaim from "../employee/hr_employee/modal/ModalEmpApplyClaim";


export default function ClaimOfficerFunctionButton(){

    const [toggle, setToggle] = useState(false);

    return(
        <div className="mt-2">
            <Button color="default" outline size="sm" type="button" onClick={()=>setToggle(true)}>{IMLocalized('apply_employee_claim')}</Button>
            <ModalEmpApplyClaim toggle={toggle} closeModal={()=>setToggle(false)} />
        </div>
    )
}