import React from "react";
import { Collapse, Nav, NavItem, NavLink } from "reactstrap";
import { IMLocalized } from "../../../../language/IMLocalized";



export default function EmployeeListNavbar({name, list, icon, setList, toggle ,setToggle}){

    const handleCheckbox = (index) =>{
        const arr = [...list];
        arr[index].checked = !arr[index].checked;
        setList(arr);
    }

    return(
        <Nav navbar className="pb-2">
            <NavItem className="m-0 bg-white border">
                <NavLink className="bg_emp_list cursor-pointer" active data-toggle="collapse" role="button" aria-expanded={toggle} onClick={setToggle} aria-controls={`navbar-${name}`}>
                    <div className="d-flex">
                        <div className="px-2">
                            <i className={icon} />
                        </div>
                        <span className="nav-link-text font-weight-normal">{IMLocalized(name)}</span>
                    </div>
                </NavLink>
                <Collapse isOpen={toggle}>
                    <div className="dropdown-scroll scrollbarstyle1">
                        {list.map((item,index)=>{
                            return(
                                <div className="custom-control custom-checkbox ml-2 mt-1" key={item.label}>
                                    <input type="checkbox" id={`emplist_${name}_${item.label}`}  className="custom-control-input" checked={item.checked} onChange={()=>handleCheckbox(index)}
                                    />
                                    <label className="custom-control-label" htmlFor={`emplist_${name}_${item.label}`}>{item.label}</label>
                                </div>
                            )
                        })}
                    </div>
                </Collapse>
            </NavItem>
        </Nav>
    )
}