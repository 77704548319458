import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledTooltip } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";
import ScheduleTable from "../component/ScheduleTable";

export default function ModalEditScheduleList({toggle,closeModal,detail,holiday_list,index,list,updateList}){
    
    const calendar_key = useSelector(state=>state.schedule_dropdown.calendar_key);
    const choices = useSelector(state=>state.schedule_dropdown.calendar_choices);
    const [ name, setName] = useState('');
    const [ err_name, setErrName] = useState('');
    const [ start_year, setStartYear] = useState('');
    const [ err_start_year, setErrStartYear] = useState('');
    const [ working_hours, setWorkingHours] = useState('');
    const [ err_working_hours, setErrWorkingHours] = useState('');
    const [ holiday, setHoliday] = useState('');
    const [ err_holiday, setErrHoliday] = useState('');
    const [ hour_per_year, setHourPerYear] = useState('');
    const [ hour_per_year_value, setHourPerYearValue] = useState('');
    const [ err_hour_per_year_value, setErrHourPerYearValue] = useState('');
    const [ array1, setArray1] = useState([{id:1,value:'o'},{id:2,value:'o'},{id:3,value:'o'},{id:4,value:'o'},{id:5,value:'o'},{id:6,value:'o'},{id:7,value:'o'}]);
    const [ array2, setArray2] = useState([{id:1,value:'o'},{id:2,value:'o'},{id:3,value:'o'},{id:4,value:'o'},{id:5,value:'o'},{id:6,value:'o'},{id:7,value:'o'}]);
    const [ array3, setArray3] = useState([{id:1,value:'o'},{id:2,value:'o'},{id:3,value:'o'},{id:4,value:'o'},{id:5,value:'o'},{id:6,value:'o'},{id:7,value:'o'}]);
    const [ array4, setArray4] = useState([{id:1,value:'o'},{id:2,value:'o'},{id:3,value:'o'},{id:4,value:'o'},{id:5,value:'o'},{id:6,value:'o'},{id:7,value:'o'}]);

    useEffect(()=>{

        function getArrayDetail(list,index){
            const arr = [];
            const data = list[index];
            for(let i = 0; i< data.length; i++){
                const number = i+1;
                arr.push({ id: number , value: data[i] });
            }
            return arr;
        }

        if(toggle && detail){
            const {name,working_hours,start_year,holiday_list,calendar_array} = detail;
            setName(name);
            setWorkingHours(working_hours);
            setStartYear(start_year);
            setHoliday(holiday_list);
            const arr1 = getArrayDetail(calendar_array,0);
            const arr2 = getArrayDetail(calendar_array,1);
            const arr3 = getArrayDetail(calendar_array,2);
            const arr4 = getArrayDetail(calendar_array,3);
            setArray1(arr1);
            setArray2(arr2);
            setArray3(arr3);
            setArray4(arr4);
            const hours_in_year = detail.hours_in_year.toString();
            if(hours_in_year !== '2288' && hours_in_year !== '2080'){
                setHourPerYearValue(hours_in_year);
                setHourPerYear('custom');
            }
            else{
                setHourPerYear(hours_in_year);
            }
            

        }
    },[toggle,detail])

    const updateDetail=()=>{
        let value = 0;
        let invalid = false;
        if(hour_per_year === 'custom'){
            if(hour_per_year_value !== ''){
                value = hour_per_year_value;
            }
            else{
                setErrHourPerYearValue(IMLocalized('hours_per_year_value_is_required'));
                invalid = true;
            }
        }
        else{
            value = hour_per_year;
        }

        if(!invalid){
            if(name !== '' && working_hours !== '' && start_year !== '' && holiday !== ''){
                const reg = /^(20[0-4]\d|2050)$/ ;
                if(!reg.test(start_year)){
                    setErrStartYear(IMLocalized('invalid_year'));
                }
                else{
                    const arr1 = array1.map((item)=>item.value) 
                    const arr2 = array2.map((item)=>item.value);
                    const arr3 = array3.map((item)=>item.value);
                    const arr4 = array4.map((item)=>item.value);
                    const arr = [arr1,arr2,arr3,arr4];
                    const data  = [...list];
                    data[index] = {name,start_year,working_hours,hours_in_year:value,calendar_array:arr,holiday_list:holiday}
                    updateList(data);
                }
            }
        }
    }


    const changeDays1 =(id)=>{
        const arr = [...array1];
        const detail = arr.find(item=>item.id === id);
        const index = detail?.value ?? null;
        const number = calendar_key.indexOf(index);
        if(number + 1 !== calendar_key.length ){
            const a = number +1;
            const value = calendar_key[a];
            const new_array = arr.map(item=>
                item.id === id
                ?{...item,value}
                :item
            )
            setArray1(new_array);
        }
        else{
            const value = calendar_key[0];
            const new_array = arr.map(item=>
                item.id === id
                ? {...item, value}
                :item
            )
            setArray1(new_array);
        }
    }

    const changeDays2 =(id)=>{
        const arr = [...array2];
        const detail = arr.find(item=>item.id === id);
        const index = detail?.value ?? null;
        const number = calendar_key.indexOf(index);
        if(number + 1 !== calendar_key.length ){
            const a = number +1;
            const value = calendar_key[a];
            const new_array = arr.map(item=>
                item.id === id
                ?{...item,value}
                :item
            )
            setArray2(new_array);
        }
        else{
            const value = calendar_key[0];
            const new_array = arr.map(item=>
                item.id === id
                ? {...item, value}
                :item
            )
            setArray2(new_array);
        }
    }

    const changeDays3 =(id)=>{
        const arr = [...array3];
        const detail = arr.find(item=>item.id === id);
        const index = detail?.value ?? null;
        const number = calendar_key.indexOf(index);
        if(number + 1 !== calendar_key.length ){
            const a = number +1;
            const value = calendar_key[a];
            const new_array = arr.map(item=>
                item.id === id
                ?{...item,value}
                :item
            )
            setArray3(new_array);
        }
        else{
            const value = calendar_key[0];
            const new_array = arr.map(item=>
                item.id === id
                ? {...item, value}
                :item
            )
            setArray3(new_array);
        }
    }

    const changeDays4 =(id)=>{
        const arr = [...array4];
        const detail = arr.find(item=>item.id === id);
        const index = detail?.value ?? null;
        const number = calendar_key.indexOf(index);
        if(number + 1 !== calendar_key.length ){
            const a = number +1;
            const value = calendar_key[a];
            const new_array = arr.map(item=>
                item.id === id
                ?{...item,value}
                :item
            )
            setArray4(new_array);
        }
        else{
            const value = calendar_key[0];
            const new_array = arr.map(item=>
                item.id === id
                ? {...item, value}
                :item
            )
            setArray4(new_array);
        }
    }


    let hour = 0;
    for(const data of array1){
        const { value } = data;
        if(choices.hasOwnProperty(value)){
            const { amount } = choices[value];
            hour += amount;
        }
    }

    for(const data of array2){
        const { value } = data;
        if(choices.hasOwnProperty(value)){
            const { amount } = choices[value];
            hour += amount;
        }
    }

    for(const data of array3){
        const { value } = data;
        if(choices.hasOwnProperty(value)){
            const { amount } = choices[value];
            hour += amount;
        }
    }

    for(const data of array4){
        const { value } = data;
        if(choices.hasOwnProperty(value)){
            const { amount } = choices[value];
            hour += amount;
        }
    }

    const diw = hour !== 0 ? hour/4 : 0;

    return(
        <Modal isOpen={toggle} className="modal-dialog-centered" style={{maxWidth: '1600px', width: '80%'}} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('edit_schedule')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="name">{IMLocalized('schedule_name')}</label>
                            <Input type="text" id="name" size="sm" value={name} invalid={err_name.length > 0 } onChange={(e)=>{ setName(e.target.value); setErrName('') }} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="start_year" id="modalEditScheduleTooltip1">{IMLocalized('start_year')}</label>
                            <UncontrolledTooltip delay={0} placement="top" target="modalEditScheduleTooltip1" >
                                {IMLocalized('start_year_tooltip')}
                            </UncontrolledTooltip>
                            <Input size="sm" id="start_year" value={start_year} pattern="(20)\d\d"  type="number" onChange={(e)=>{ setStartYear(e.target.value); setErrStartYear('')}} invalid={err_start_year.length >0 } />
                            <FormFeedback>{err_start_year}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" id="modalEditScheduleTooltip2" htmlFor="working_hours">{IMLocalized('working_hours')}</label>
                            <UncontrolledTooltip delay={0} placement="top" target="modalEditScheduleTooltip2">
                                {IMLocalized('working_hours_tooltip')}
                            </UncontrolledTooltip>
                            <Input id="working_hours" size="sm" type="number" value={working_hours} onChange={(e)=>{ setWorkingHours(e.target.value); setErrWorkingHours('') }} invalid={err_working_hours.length >0} />
                            <FormFeedback>{err_working_hours}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="holiday_list">{IMLocalized('holiday_list')}</label>
                            <Input size="sm" id="holiday_list" type="select" value={holiday} onChange={(e)=>{ setHoliday(e.target.value); setErrHoliday('') }} invalid={err_holiday.length >0}>
                                <option></option>
                                {holiday_list.map((item,index)=>{
                                    return <option value={item.name} key={index}>{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_holiday}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="">{IMLocalized('working_hours_in_year')}</label>
                            <div className="d-flex align-items-center">
                                <div className="custom-control custom-radio">
                                    <input
                                    className= "custom-control-input"
                                    id= "editScheduleCustomRadio1"
                                    name= "editScheduleCustomRadio"
                                    type= "radio"
                                    value= "2288"
                                    checked= {hour_per_year === '2288'}
                                    onChange= {(e)=>{ setHourPerYear(e.target.value)}}
                                    />
                                    <label className="custom-control-label" htmlFor="editScheduleCustomRadio1">2288</label>
                                </div>
                                <div className="custom-control custom-radio ml-2">
                                    <input
                                    className= "custom-control-input"
                                    id= "editScheduleCustomRadio2"
                                    name= "editScheduleCustomRadio"
                                    type= "radio"
                                    value= "2080"
                                    checked= {hour_per_year === '2080'}
                                    onChange= {(e)=>{ setHourPerYear(e.target.value)}}
                                    />
                                    <label className="custom-control-label" htmlFor="editScheduleCustomRadio2">2080</label>
                                </div>
                                <div className="custom-control custom-radio ml-2">
                                    <input
                                    className="custom-control-input"
                                    id= "editScheduleCustomRadio3"
                                    name= "editScheduleCustomRadio"
                                    type= "radio"
                                    value= "custom"
                                    checked= {hour_per_year === 'custom'}
                                    onChange={(e)=> { setHourPerYear(e.target.value)}}
                                    />
                                    <label className="custom-control-label" htmlFor="editScheduleCustomRadio3">{IMLocalized('CUSTOM')}</label>
                                </div>
                                {hour_per_year === 'custom' &&
                                <FormGroup className="mb-0 ml-2">
                                    <Input size="sm" type="number" min="0" value={hour_per_year_value} onChange={(e)=>{setHourPerYearValue(e.target.value); setErrHourPerYearValue('')}} invalid={err_hour_per_year_value.length > 0 } />
                                    <FormFeedback>{err_hour_per_year_value}</FormFeedback>
                                </FormGroup>}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <div>
                            <label className="form-control-label">{IMLocalized('work_days_in_week')} : </label> <span>{diw}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <h4>{IMLocalized('calendar_table')}</h4>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col>
                        <Table size="sm" className="align-items-center table-bordered" responsive>
                            <thead className="thead-light text-center">
                                <tr>
                                    <th>{IMLocalized('WEEK')}</th>
                                    <th>{IMLocalized('MONDAY')}</th>
                                    <th>{IMLocalized('TUESDAY')}</th>
                                    <th>{IMLocalized('WEDNESDAY')}</th>
                                    <th>{IMLocalized('THURSDAY')}</th>
                                    <th>{IMLocalized('FRIDAY')}</th>
                                    <th>{IMLocalized('SATURDAY')}</th>
                                    <th>{IMLocalized('SUNDAY')}</th>
                                </tr>
                            </thead>
                            <tbody className="text-center">
                                <tr>
                                    <th>{IMLocalized('WEEK')} 1</th>
                                    {array1.map((item,index)=>{
                                        return <ScheduleTable key={index} id={item.id} value={item.value} onClick={()=>changeDays1(item.id)} />
                                    })}
                                </tr>
                                <tr>
                                    <th>{IMLocalized('WEEK')} 2</th>
                                    {array2.map((item,index)=>{
                                        return <ScheduleTable key={index} id={item.id} value={item.value} onClick={()=>changeDays2(item.id)} />
                                    })}
                                </tr>
                                <tr>
                                    <th>{IMLocalized('WEEK')} 3</th>
                                    {array3.map((item,index)=>{
                                        return <ScheduleTable key={index} id={item.id} value={item.value} onClick={()=>changeDays3(item.id)} />
                                    })}
                                </tr>
                                <tr>
                                    <th>{IMLocalized('WEEK')} 4</th>
                                    {array4.map((item,index)=>{
                                        return <ScheduleTable key={index} id={item.id} value={item.value} onClick={()=>changeDays4(item.id)} />
                                    })}
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary btn-sm" onClick={updateDetail}>{IMLocalized('update')}</button>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}