import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row,Input,FormFeedback } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { create_general_currency, reset_create_general_currency } from '../../../action/create_general_currency';
import { general_currency } from '../../../action/general_currency';
import { popError } from '../../../func/popError';

const initialState={
    name:'',
    err_name:'',
    symbol:'',
    err_symbol:'',
}

function ModalCreateCurrency({toggle, closeModal}){
    
    const [data,setData] = useState(initialState);
    const dispatch = useDispatch();
    const isLoading = useSelector(state=>state.create_general_currency.isLoading);
    const success = useSelector(state=>state.create_general_currency.data);
    const errors = useSelector(state=>state.create_general_currency.errors);

    useEffect(()=>{
        if(toggle){
            setData(initialState);
        }
    },[toggle])

    useEffect(()=>{
        if(errors !== null && toggle){
            setData(prevState=>({
                ...prevState,
                err_name: errors.hasOwnProperty('currency_name') ? errors['currency_name'].toString() :'',
                err_symbol: errors.hasOwnProperty('currency_symbol') ? errors['currency_symbol'].toString() :'',
            }))
            if(errors.hasOwnProperty('non_field_errors')){
                popError(errors['non_field_errors'].toString());
            }
            dispatch(reset_create_general_currency());
        }
    },[errors,toggle,dispatch])

    useEffect(()=>{
        if( success !== null && toggle){
            closeModal();
            dispatch(general_currency());
            dispatch(reset_create_general_currency());
        }
    },[success,dispatch,toggle,closeModal])

    const createCurrency=()=>{
        if(data.name !== '' && data.symbol !== ''){
            dispatch(create_general_currency(data.name,data.symbol));
        }
        else{
            setData(prevState=>({
                ...prevState,
                err_name:IMLocalized('name_is_required'),
                err_symbol:IMLocalized('symbol_is_required'),
            }))
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('create_new_foreign_currency')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('currency_name')}</label>
                            <Input size="sm" type="text" id="name" value={data.name} invalid={data.err_name.length>0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setData(prevState=>({
                                    ...prevState,
                                    name:value,
                                    err_name:''
                                }))
                            }}
                            />
                            <FormFeedback>{data.err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='symbol'>{IMLocalized('currency_symbol')}</label>
                            <Input size="sm" type="text" id="symbol" value={data.symbol} invalid={data.err_symbol.length>0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setData(prevState=>({
                                    ...prevState,
                                    symbol:value,
                                    err_symbol:''
                                }))
                            }}
                            />
                            <FormFeedback>{data.err_symbol}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button onClick={()=>createCurrency()} className="btn btn-primary btn-sm">{IMLocalized('create')}</button>
                <button onClick={()=>closeModal()} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default ModalCreateCurrency;