import React,{Component} from 'react';
import {connect} from 'react-redux';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import {Container,Col,Row, Modal, ModalHeader, ModalBody, Table, ModalFooter} from 'reactstrap';
import moment from 'moment';
import {create_employee_array_validate} from '../../../action/employee/create_employee_array_validate';
import {create_employee_unique_check} from '../../../action/employee/create_employee_unique_check';
import {create_employee_array_create} from '../../../action/employee/create_employee_array_create';
import {get_employee_list} from '../../../action/employee/get_employee_list';
import {get_employee_address} from '../../../action/employee/get_employee_address';
import {get_employee_list_withoutcontract} from '../../../action/employee/get_employee_list_withoutcontract';
import {get_latest_employee} from '../../../action/employee/get_latest_employee';
import {get_employee_probation_list} from '../../../action/get_employee_probation_list';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../../language/IMLocalized';
import 'ag-grid-enterprise';
import {religionlist,racelist,genderlist,countrylist,relationshiplist,paymentlist} from '../../../data/profile_data';
import {sgpr_employee_cpf_payment} from '../../../data/prcpf_data';
import OverlayLoading from '../../loading_component/overlay_loading';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { NewMainContext } from '../../../context/NewMainContext';
import BreadCrumbList from '../../breadcrumb/BreadCrumbList';

const gridOptions={
    defaultColDef: {
        resizable: true,
    },
}

class import_create_employee extends Component{

    constructor(props){
        super(props);
        this.state={
            rowData:[],
            noRowTemplate:IMLocalized('no_data'),
            columnDefs:[],
            error_msg:[],
            error_toggle:false,
            language:localStorage.getItem('language'),
            defaultColDef:{
                suppressMenu:true,
                resizable:true
            },
        }
    }

    componentDidMount(){
       
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.companylist!== this.props.companylist || prevProps.branchlist !== this.props.branchlist || prevProps.salutation !== this.props.salutation){
            this.getColumn();
        }
        if(prevProps.unique_errors !== this.props.unique_errors){
            const {validatelist} = this.state;
            const errors = this.props.unique_errors;
            let arr = [];
            if(errors.length !== 0){
                for(let i=0;i<errors.length;i++){
                    const data = errors[i];
                    if(data !== ''){
                        let name = validatelist[i].name;
                        const count = i +1;
                        if(name === null || name === '' || name === undefined){
                            name = IMLocalized('employee') + ' (' + IMLocalized('numberdi') + count + IMLocalized('row') + ')';
                        }
                        const word = data.toString();
                        arr.push(<tr><td>{name}</td><td>{word}</td></tr>)
                    }
                }
                this.setState({error_toggle:true});
                this.setState({error_msg:arr});
            }
            
        }
        if(prevProps.unique_data !== this.props.unique_data){
            this.props.create_employee_array_validate(this.state.validatelist);
        }
        if(prevProps.array_data !== this.props.array_data){
            Promise.resolve(this.props.create_employee_array_create(this.state.validatelist))
        }
        if(prevProps.array_errors !== this.props.array_errors){
            const errors = this.props.array_errors;
            const {validatelist,rowData} = this.state;
            
            if(errors.length !== 0){
                let arr = [];
                for(let i=0;i<errors.length ;i++){
                    const name = validatelist[i].name;
                    const errors1 = errors[i];
                    let arr1 = [];
                    if('salutation' in errors1){
                        const word = `${IMLocalized('salutation')} : ` + errors1['salutation'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_salutation = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('name' in errors1){
                        const word = `${IMLocalized('name')} : ` +errors1['name'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_name = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)

                    }
                    if('email' in errors1){
                        const word = `${IMLocalized('email_address')} : `+errors1['email'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_email = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('nationality' in errors1){
                        const word = `${IMLocalized('nationality')} : `+errors1['nationality'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_nationality = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('contact_number' in errors1){
                        const word = `${IMLocalized('contact_number')} : ` + errors1['contact_number'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_contact_number = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('gender' in errors1){
                        const word = `${IMLocalized('gender')} : `+errors1['gender'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_gender = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('race' in errors1){
                        const word = `${IMLocalized('race')} : `+errors1['race'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_race = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('religion' in errors1){
                        const word = `${IMLocalized('religion')} : `+errors1['religion'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_religion = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('relationship' in errors1){
                        const word = `${IMLocalized('relationship')} : `+errors1['relationship'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_relationship = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                       
                    }
                    if('date_of_birth' in errors1 ){
                        const word = `${IMLocalized('date_of_birth')} : `+errors1['date_of_birth'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_date_of_birth = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('join_date' in errors1){
                        const word = `${IMLocalized('join_date')} : `+errors1['join_date'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_join_date = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('is_pr' in errors1){
                        const word = `${IMLocalized('is_pr')} : `+errors1['is_pr'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_is_pr = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('probation_end_date' in errors1){
                        const word = `${IMLocalized('probation_end_date')} : `+errors1['probation_end_date'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_probation_end_date = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('company' in errors1){
                        const word = `${IMLocalized('company')} : `+errors1['company'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_company = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('branch' in errors1){
                        const word =`${IMLocalized('branch')} : `+errors1['branch'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_branch = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('payment' in errors1){
                        const word = `${IMLocalized('payment')} : `+errors1['payment'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_payment = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('country_of_residence' in errors1){
                        const word = `${IMLocalized('country_of_residence')} : `+errors1['country_of_residence'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_country_of_residence = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('block_number' in errors1){
                        const word =`${IMLocalized('block_number')} : `+errors1['block_number'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_block_number = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('street_name' in errors1){
                        const word = `${IMLocalized('street_name')} : `+errors1['street_name'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_street_name = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('level_number' in errors1){
                        const word = `${IMLocalized('level_number')} : `+errors1['level_number'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_level_number = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('unit_number' in errors1){
                        const word = `${IMLocalized('unit_number')} : `+errors1['unit_number'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_unit_number = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('sg_postal_code' in errors1){
                        const word = `${IMLocalized('sg_postal_code')} : `+errors1['sg_postal_code'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_sg_postal_code = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('address' in errors1){
                        const word = `${IMLocalized('address')} : `+errors1['address'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_address = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('pr_start_date' in errors1){
                        const word = `${IMLocalized('pr_start_date')} : `+errors1['pr_start_date'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_pr_start_date = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('identification_number' in errors1){
                        const word = `${IMLocalized('identification_number')} : `+errors1['identification_number'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_identification_number = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('postal_code' in errors1){ 
                        const word = `${IMLocalized('postal_code')} : ` + errors1['postal_code'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_postal_code = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }
                    if('id_type' in errors1){ 
                        const word = `${IMLocalized('id_type')} : ` + errors1['id_type'].toString();
                        arr1.push(<div><span>● {word}</span></div>);
                        rowData[i].err_id_type = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData)
                    }

                    arr.push(<tr><td>{name}</td><td>{arr1}</td></tr>)
                }
                this.setState({error_msg:arr});
                this.setState({error_toggle:true});
            }
            
        }
        if(prevProps.create_success !== this.props.create_success){
            Promise.resolve(this.clearData()).then(this.props.get_employee_list()).then(this.props.get_employee_address()).then(this.props.get_employee_list_withoutcontract())
            .then(this.props.get_latest_employee()).then(this.props.get_employee_probation_list())
        }
        if(prevProps.clear_step !== this.props.clear_step){
            this.clearData();
        }
    }

    onCellValueChanged=(params)=>{
        if(params.oldValue !== params.newValue){
            const column = params.column.colDef.field;
            if(column === 'salutation'){
                params.data.err_salutation =false;
            }
            else if(column === 'name'){
                params.data.err_name =false;
            }
            else if(column === 'employee_number'){
                params.data.err_employee_number =false;
            }
            else if(column === 'badge_number'){
                params.data.err_badge_number =false;
            }
            else if(column === 'email_address'){
                params.data.err_email_address =false;
            }
            else if(column === 'nationality'){
                params.data.err_nationality =false;
            }
            else if(column === 'id_type'){
                params.data.err_id_type =false;
            }
            else if(column === 'identification_number'){
                params.data.err_identification_number =false;
            }
            else if(column === 'contact_number'){
                params.data.err_contact_number =false;
            }
            else if(column === 'gender'){
                params.data.err_gender =false;
            }
            else if(column === 'race'){
                params.data.err_race =false;
            }
            else if(column === 'religion'){
                params.data.err_religion =false;
            }
            else if(column === 'relationship'){
                params.data.err_relationship =false;
            }
            else if(column === 'date_of_birth'){
                params.data.err_date_of_birth =false;
            }
            else if(column === 'company'){
                params.data.err_company =false;
            }
            else if(column === 'branch'){
                params.data.err_branch =false;
            }
            else if(column === 'payment'){
                params.data.err_payment =false;
            }
            else if(column === 'is_pr'){
                params.data.err_is_pr =false;
            }
            else if(column === 'pr_start_date'){
                params.data.err_pr_start_date =false;
            }
            else if(column === 'join_date'){
                params.data.err_join_date =false;
            }
            else if(column === 'probation_end_date'){
                params.data.err_probation_end_date =false;
            }
            else if(column === 'country_of_residence'){
                params.data.err_country_of_residence =false;
            }
            else if(column === 'sg_postal_code'){
                params.data.err_sg_postal_code =false;
            }
            else if(column === 'block_number'){
                params.data.err_block_number =false;
            }
            else if(column === 'street_name'){
                params.data.err_street_name =false;
            }
            else if(column === 'level_number'){
                params.data.err_level_number =false;
            }
            else if(column === 'unit_number'){
                params.data.err_unit_number =false;
            }
            else if(column === 'oversea_postal_code'){
                params.data.err_postal_code =false;
            }
            else if(column === 'oversea_address'){
                params.data.err_address =false;
            }

            params.api.redrawRows({
                force:true,
                columns:[column],
                rowNodes:[params.node]
            })
            this.setState({step:0});
        }
    }

    getColumn=()=>{
        const {salutation,nationality,sg_identification_type,companylist,branchlist} = this.props;
        const {language} = this.state;
        let salutation_list=[];
        if(salutation.length !== 0){
            salutation_list = salutation.map((item)=>{
                return item.display;
            });
        }

        let nationality_list = [];
        if(nationality.length !== 0){
            nationality_list = countrylist.map((item)=>{
                return item.display_name
            })
        }
        let id_list =[];
        if(sg_identification_type.length !== 0){
            id_list = sg_identification_type.map((item)=>{
                return item.display;
            })
        }

        let gender_list =[];
        if(genderlist.length !== 0){
            if(language === 'zh'){
                gender_list = genderlist.map((item)=>{
                    return item.display_zh;
                })
            }
            else{
                gender_list = genderlist.map((item)=>{
                    return item.display;
                })
            }
            
        }

        let race_list = [];
        if(racelist.length !== 0){
            if(language === 'zh'){
                race_list = racelist.map((item)=>{
                    return item.display_zh;
                })
            }
            else{
                race_list = racelist.map((item)=>{
                    return item.display;
                })
            }
            
        }

        let religion_list = [];
        if(religionlist.length !== 0){
            if(language ==='zh'){
                religion_list = religionlist.map((item)=>{
                    return item.display_zh;
                })
            }
            else{
                religion_list = religionlist.map((item)=>{
                    return item.display;
                })
            }
            
        }

        let relationship_list = [];
        if(relationshiplist.length !== 0){
            if(language ==='zh'){
                relationship_list = relationshiplist.map((item)=>{
                    return item.display_zh;
                })
            }
            else{
                relationship_list = relationshiplist.map((item)=>{
                    return item.display;
                })
            }
        }

        let cpf_contribution_list = [];
        if(sgpr_employee_cpf_payment.length !== 0){
            if(language ==='zh'){
                cpf_contribution_list = sgpr_employee_cpf_payment.map((item)=>{
                    return item.display_zh;
                })
            }
            else{
                cpf_contribution_list = sgpr_employee_cpf_payment.map((item)=>{
                    return item.display;
                })
            }
        }

        let company_list = [];
        if(companylist.length !== 0){
            company_list = companylist.map((item)=>{
                return item.name;
            })
        }

        let branch_list = [];
        if(branchlist.length !== 0){
            branch_list = branchlist.map((item)=>{
                return item.name;
            })
        }

        let payment_list = [];
        if(paymentlist.length !== 0){
            if(language==='zh'){
                payment_list = paymentlist.map((item)=>{
                    return item.display_zh;
                })
            }
            else{
                payment_list = paymentlist.map((item)=>{
                    return item.display;
                })
            }
            
        }
        const newList =[
            {
                headerName:IMLocalized('salutation'),
                field:'salutation',
                width:100,
                filter:false,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: salutation_list,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_salutation}
                },
            },
            {
                headerName:IMLocalized('name'),
                field:'name',
                filter:false,
                width:200,
                editable:true,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_name}
                },
            },
            {
                headerName:IMLocalized('employee_number'),
                field:'employee_number',
                editable:true,
                filter:false,
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_employee_number}
                },
            },
            {
                headerName:IMLocalized('badge_number'),
                field:'badge_number',
                editable:true,
                filter:false,
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_badge_number}
                },
            },
            {
                headerName:IMLocalized('email_address'),
                field:"email",
                width:200,
                filter:false,
                editable:true,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_email}
                },
            },
            {
                headerName:IMLocalized('nationality'),
                field:'nationality',
                width:200,
                editable:true,
                filter:false,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: nationality_list,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_nationality}
                },
            },
            {
                headerName:IMLocalized('id_type'),
                field:'id_type',
                width:200,
                editable:true,
                filter:false,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: id_list,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_id_type}
                },
            },
            {
                headerName:IMLocalized('identification_number'),
                field:"identification_number",
                width:200,
                filter:false,
                editable:true,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_identification_number}
                },
            },
            {
                headerName:IMLocalized('contact_number'),
                field:"contact_number",
                width:200,
                filter:false,
                editable:true,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_contact_number}
                },
            },
            {
                headerName:IMLocalized('gender'),
                field:'gender',
                width:200,
                filter:false,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: gender_list,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_gender}
                },
            },
            {
                headerName:IMLocalized('race'),
                field:'race',
                width:200,
                filter:false,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: race_list,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_race}
                },
            },
            {
                headerName:IMLocalized('religion'),
                field:'religion',
                width:200,
                editable:true,
                filter:false,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: religion_list,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_religion}
                },
            },
            {
                headerName:IMLocalized('relationship'),
                field:'relationship',
                width:200,
                filter:false,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: relationship_list,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_relationship}
                },
            },
            {
                headerName:IMLocalized('date_of_birth'),
                field:"date_of_birth",
                width:200,
                editable:true,
                filter:false,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_date_of_birth}
                },
            },
            {
                headerName:IMLocalized('company'),
                field:'company',
                width:200,
                editable:true,
                filter:false,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: company_list,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_company}
                },
            },
            {
                headerName:IMLocalized('branch'),
                field:'branch',
                width:200,
                editable:true,
                filter:false,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: branch_list,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_branch}
                },
            },
            {
                headerName:IMLocalized('payment'),
                field:'payment',
                width:200,
                editable:true,
                filter:false,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: payment_list,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_payment}
                },
            },
            {
                headerName:IMLocalized('is_pr'),
                field:'is_pr',
                width:200,
                editable:true,
                filter:false,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: [IMLocalized('excel_true'),IMLocalized('excel_false')],
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_is_pr}
                },
            },
            {
                headerName:IMLocalized('pr_start_date'),
                field:"pr_start_date",
                width:200,
                editable:true,
                filter:false,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_pr_start_date}
                },
            },
            {
                headerName:IMLocalized('pr_cpf_contribution_type'),
                field:"pr_cpf_contribution_type",
                width:200,
                editable:true,
                filter:false,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: cpf_contribution_list,
                },
            },
            {
                headerName:IMLocalized('join_date'),
                field:"join_date",
                width:200,
                editable:true,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_join_date}
                },
            },
            {
                headerName:IMLocalized('probation_end_date'),
                field:"probation_end_date",
                width:200,
                editable:true,
                filter:false,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_probation_end_date}
                },
            },
            {
                headerName:IMLocalized('country_of_residence'),
                field:"country_of_residence",
                width:200,
                editable:true,
                filter:false,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: nationality_list,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_country_of_residence}
                },
            },
            {
                headerName:IMLocalized('sg_postal_code'),
                field:"sg_postal_code",
                width:200,
                editable:true,
                filter:false,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_sg_postal_code}
                },
            },
            {
                headerName:IMLocalized('block_number'),
                field:"block_number",
                width:200,
                editable:true,
                filter:false,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_block_number}
                },
            },
            {
                headerName:IMLocalized('street_name'),
                field:"street_name",
                width:200,
                editable:true,
                filter:false,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_street_name}
                },
            },
            {
                headerName:IMLocalized('level_number'),
                field:"level_number",
                width:200,
                editable:true,
                filter:false,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_level_number}
                },
            },
            {
                headerName:IMLocalized('unit_number'),
                field:"unit_number",
                width:200,
                filter:false,
                editable:true,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_unit_number}
                },
            },
            {
                headerName:IMLocalized('oversea_postal_code'),
                field:"postal_code",
                width:200,
                filter:false,
                editable:true,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_postal_code}
                },
            },
            {
                headerName:IMLocalized('oversea_address'),
                field:"address",
                width:300,
                filter:false,
                editable:true,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_address}
                },
            },
        ]

        this.setState({columnDefs:newList})
    }

    generateExcel=()=>{
        const {language} = this.state;
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee');
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');
        ws.columns = [
            { header: IMLocalized('salutation'), key: 'salutation' },
            { header: IMLocalized('name'), key: 'name' },
            { header: IMLocalized('employee_number'),key: 'employee_number'},
            { header: IMLocalized('badge_number'), key:'badge_number'},
            { header: IMLocalized('email_address'), key:'email'},
            { header: IMLocalized('nationality'), key:'nationality'},
            { header: IMLocalized('id_type') ,key:'id_type'},
            { header: IMLocalized('identification_number'), key:'identification_number'},
            { header: IMLocalized('contact_number'), key:'contact number'},
            { header: IMLocalized('gender'), key:'gender'},
            { header: IMLocalized('race'), key:'race'},
            { header: IMLocalized('religion'), key:'religion'},
            { header: IMLocalized('relationship'), key:'relationship'},
            { header: IMLocalized('date_of_birth'), key:'date_of_birth'},
            { header: IMLocalized('company'), key:'company'},
            { header: IMLocalized('branch'), key:'branch'},
            { header: IMLocalized('payment'), key:'payment'},
            { header: IMLocalized('is_pr'), key:'pr'},
            { header: IMLocalized('pr_start_date'), key:'pr_startdate'},
            { header: IMLocalized('pr_cpf_contribution_type'),key:'pr_cpf_contribution_type'},
            { header: IMLocalized('join_date'), key:'join_date' },
            { header: IMLocalized('probation_end_date'), key:'probation_end_date'},
            { header: IMLocalized('country_of_residence'), key:'country_of_residence'},
            { header: IMLocalized('sg_postal_code'), key:'sg_postal_code'},
            { header: IMLocalized('block_number'), key:'block_number'},
            { header: IMLocalized('street_name'), key:'street_name'},
            { header: IMLocalized('level_number'), key:'level_number'},
            { header: IMLocalized('unit_number'), key:'unit_number'},
            { header: IMLocalized('oversea_postal_code'),key:'postal_code'},
            { header: IMLocalized('oversea_address'), key:'address'}
        ];

        for (let i=2;i<=199;i++){
            ws.getCell('N'+i).numFmt = 'd/M/yy';
            ws.getCell('N'+i).dataValidation={
                type:'date',
                operator:'lessThan',
                showErrorMessage:true,
                allowBlank:true,
                formulae:[new Date()]
            }
            ws.getCell('S'+i).numFmt = 'd/M/yy';
            ws.getCell('U'+i).numFmt = 'd/M/yy';
            ws.getCell('V'+i).numFmt = 'd/M/yy';
            ws.getCell('X'+i).numFmt = '@';
            ws.getCell('Y'+i).numFmt = '@';
            ws.getCell('Z'+i).numFmt = '@';
            ws.getCell('AA'+i).numFmt = '@';
            ws.getCell('AB'+i).numFmt = '@';
            ws.getCell('AC'+i).numFmt = '@';
            ws.getCell('AD'+i).numFmt = '@';
        }

        // for (let i=2;i<=199;i++){
        //     ws.getCell('N'+i).numFmt = 'DD/MM/YYYY';
        //     ws.getCell('S'+i).numFmt = 'DD/MM/YYYY';
        //     ws.getCell('T'+i).numFmt = 'DD/MM/YYYY';
        //     ws.getCell('U'+i).numFmt = 'DD/MM/YYYY';
        // }

        const {salutation} = this.props;
        for(let i=0;i<salutation.length ;i++){
            let x =i+1;
            ws2.getCell('A'+x).value = salutation[i].display;
        }

        if(salutation.length !== 0){
            for(let i=2;i<=2001;i++){
                ws.getCell('A'+i).dataValidation={
                    type:'list',
                    allowBlank:true,
                    formulae:['=setting!$A$1:$A$'+salutation.length],
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                };
            }
        }
        

        //Column NATIONALITY
        for(let i=0;i<countrylist.length;i++){
            let x = i+1;
            ws2.getCell('B'+x).value = countrylist[i].display_name;
        }

        if(countrylist.length !== 0){
            for(let i=2;i<=2001;i++){
                ws.getCell('F'+i).dataValidation={
                    type:'list',
                    allowBlank:true,
                    formulae:['=setting!$B$1:$B$'+countrylist.length],
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                }
            }
        }
        

        // Column GENDER
     
        for(let i=0;i<genderlist.length;i++){
            let x=i+1;
            if(language === 'zh'){
                ws2.getCell('C' +x).value = genderlist[i].display_zh;
            }
            else{
                ws2.getCell('C' +x).value = genderlist[i].display;
            }
        }

        if(genderlist.length !==0){
            for(let i=2;i<=2001;i++){
                ws.getCell('J'+i).dataValidation ={
                    type:'list',
                    allowBlank:true,
                    formulae:['=setting!$C$1:$C$' +genderlist.length],
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                };
            }
        }
        

        // Column Race
        
        for(let i=0;i<racelist.length;i++){
            let x= i+1;
            if(language === 'zh'){
                ws2.getCell('D'+x).value = racelist[i].display_zh;
            }
            else{
                ws2.getCell('D'+x).value = racelist[i].display;
            }
            
        }
        if(racelist.length !== 0){
            for(let i=2;i<=2001;i++){
                ws.getCell('K'+i).dataValidation={
                    type:'list',
                    allowBlank:true,
                    formulae:['=setting!$D$1:$D$'+racelist.length],
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                };
            }
        }
        

        // Column Religion
        
        for(let i=0;i<religionlist.length;i++){
            let x = i+1;
            if(language === 'zh'){
                ws2.getCell('E'+x).value = religionlist[i].display_zh;
            }
            else{
                ws2.getCell('E'+x).value = religionlist[i].display;
            }
            
        }

        if(religionlist.length !== 0){
            for(let i =2;i<=2001;i++){
                ws.getCell('L'+i).dataValidation={
                    type:'list',
                    allowBlank:true,
                    formulae:['=setting!$E$1:$E$' + religionlist.length],
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                };
            }
        }
        

        // Column Relationship
        
        for(let i=0;i<relationshiplist.length;i++){
            let x = i+1;
            if(language === 'zh'){
                ws2.getCell('F'+x).value = relationshiplist[i].display_zh;
            }
            else{
                ws2.getCell('F'+x).value = relationshiplist[i].display;
            }
           
        }
        if(relationshiplist.length !== 0){
            for(let i=2;i<=2001;i++){
                ws.getCell('M'+i).dataValidation={
                    type:'list',
                    allowBlank:true,
                    formulae:['=setting!$F$1:$F$'+relationshiplist.length],
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                };
            }
        }
        

        // Column Company
        let company  = this.props.companylist;
        for(let i=0;i<company.length;i++){
            let x= i+1;
            ws2.getCell('G'+x).value = company[i].name;
        }
        if(company.length !== 0){
            for(let i=2;i<=2001;i++){
                ws.getCell('O'+i).dataValidation={
                    type:'list',
                    allowBlank:true,
                    formulae:['=setting!$G$1:$G$'+company.length],
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                }
            }
        }
        

        // Column Branch
        let branch = this.props.branchlist;
        for(let i=0;i<branch.length;i++){
            let x= i+1;
            ws2.getCell('H'+x).value = branch[i].name;
        }
        if(branch.length !== 0){
            for(let i=2;i<=2001;i++){
                ws.getCell('P'+i).dataValidation={
                    type:'list',
                    allowBlank:true,
                    formulae:['=setting!$H$1:$H$'+branch.length],
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                }
            }
        }
        

        // Column Payment
        
        for(let i=0;i<paymentlist.length;i++){
            let x = i+1;
            if(language === 'zh'){
                ws2.getCell('I'+x).value = paymentlist[i].display_zh;
            }
            else{
                ws2.getCell('I'+x).value = paymentlist[i].display;
            }
            
        }
        if(paymentlist.length !== 0){
            for(let i=2;i<=2001;i++){
                ws.getCell('Q'+i).dataValidation={
                    type:'list',
                    allowBlank:true,
                    formulae:['=setting!$I$1:$I$'+paymentlist.length],
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                }
            }
        }
        

        // Column Is Pr
        let pr_data = [{data:IMLocalized('excel_true')},{data:IMLocalized('excel_false')}]
        for(let i=0;i<pr_data.length;i++){
            let y = i+1;
            ws2.getCell('J'+y).value = pr_data[i].data;
        }
        if(pr_data.length !== 0){
            for(let i=2;i<=2001;i++){
                ws.getCell('R'+i).dataValidation={
                    type:'list',
                    allowBlank:false,
                    formulae:['=setting!$J$1:$J$'+pr_data.length],
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                }
            }
        }
        

        const {sg_identification_type} = this.props;
        for(let i=0;i<sg_identification_type.length;i++){
            let x = i+1;
            ws2.getCell('K'+x).value = sg_identification_type[i].display;
        }

        for(let i=0;i<sgpr_employee_cpf_payment.length;i++){
            let x= i+1;
            if(language === 'zh'){
                ws2.getCell('L'+x).value = sgpr_employee_cpf_payment[i].display_zh;
            }
            else{
                ws2.getCell('L'+x).value = sgpr_employee_cpf_payment[i].display;
            }
        }

        if(sg_identification_type.length !== 0){
            for(let i=2;i<=2001;i++){
                ws.getCell('G'+i).dataValidation={
                    type:'list',
                    allowBlank:true,
                    formulae:['=setting!$K$1:$K$'+sg_identification_type.length],
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                }
            }
        }
        

        // Column Country

        for(let i=2;i<=2001;i++){
            ws.getCell('W'+i).dataValidation={
                type:'list',
                allowBlank:false,
                formulae:['=setting!$B$1:$B$'+countrylist.length],
                error:IMLocalized('excel_valid_in_list'),
                errorStyle:'error',
                errorTitle:'Error',
                showErrorMessage:true,
            }
        }

        for(let i=2;i<=2001;i++){
            ws.getCell('T'+i).dataValidation={
                type:'list',
                allowBlank:false,
                formulae:['=setting!$L$1:$L$'+sgpr_employee_cpf_payment.length],
                error:IMLocalized('excel_valid_in_list'),
                errorStyle:'error',
                errorTitle:'Error',
                showErrorMessage:true,
            }
        }

        ws.columns.forEach(column =>{
            column.width = column.header.length < 15 ? 15 : column.header.length
        });

        ws2.columns.forEach(column =>{
            column.width = 15
        });

        ws3.getCell('A1').value = 'EmployeeTemplate';
        ws3.getCell('A2').value = language

        wb.xlsx.writeBuffer().then((buf)=>{
            
            var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
           FileSaver.saveAs(file, 'Employee_Template.xlsx')
        });
    }

    uploadExcel=(e)=>{
        const {language} = this.state;
        let file = e.target.files[0];
        const reader = new FileReader();
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary', cellDates: true });
            const wsname = wb.SheetNames[0];
            const ws =wb.Sheets[wsname];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3]
            var columns={
                'A' : 'salutation',
                'B' : 'name',
                'C' : 'employee_number',
                'D' : 'badge_number',
                'E' : 'email',
                'F' : 'nationality',
                'G' : 'id_type',
                'H' : 'identification_number',
                'I' : 'contact_number',
                'J' : 'gender',
                'K' : 'race',
                'L' : 'religion',
                'M' : 'relationship',
                'N' : 'date_of_birth',
                'O' : 'company',
                'P' : 'branch',
                'Q' : 'payment',
                'R' : 'is_pr',
                'S' : 'pr_start_date',
                'T' : 'pr_cpf_contribution_type',
                'U' : 'join_date',
                'V' : 'probation_end_date',
                'W' : 'country_of_residence',
                'X' : 'sg_postal_code',
                'Y' : 'block_number',
                'Z' : 'street_name',
                'AA' : 'level_number',
                'AB' : 'unit_number',
                'AC' : 'postal_code',
                'AD' : 'address' 
            };

            var rowData=[];
            var rowIndex = 2;

            let idd = '';
            let lang = '';
            if(ws3 !== undefined){
                if(ws3['A1'] !== undefined){
                    idd = ws3['A1'].w;
                    lang = ws3['A2'].w;
                }
            }

            if(idd === 'EmployeeTemplate'){
                if(lang === language){
                    while(ws['A'+rowIndex]){
                        const err_salutation = false;
                        const err_name = false;
                        const err_email = false;
                        const err_nationality = false;
                        const err_contact_number = false;
                        const err_gender = false;
                        const err_race =  false;
                        const err_religion = false;
                        const err_relationship = false;
                        const err_join_date = false;
                        const err_is_pr = false;
                        const err_probation_end_date = false;
                        const err_company = false;
                        const err_branch = false;
                        const err_payment = false;
                        const err_country_of_residence = false;
                        const err_block_number = false;
                        const err_street_name = false;
                        const err_level_number = false;
                        const err_unit_number = false;
                        const err_sg_postal_code = false;
                        const err_address = false;
                        const err_pr_start_date = false;
                        const err_identification_number = false;
                        const err_postal_code = false;
                        const err_id_type = false;
                        const err_employee_number = false;
                        const err_date_of_birth = false;
                        var row = {err_id_type,err_unit_number,err_sg_postal_code,err_address,err_pr_start_date,err_identification_number,err_postal_code,err_payment,err_country_of_residence,err_block_number,err_street_name,err_level_number,err_relationship,err_join_date,err_is_pr,err_probation_end_date,err_company,err_branch,err_email,err_nationality,err_contact_number,err_gender,err_race,err_religion,err_salutation,err_name,err_employee_number,err_date_of_birth};
                        for(let i =0;i<Object.keys(columns).length;i++){
                            const column = Object.keys(columns)[i];
                            const index = column + rowIndex;
                            if(ws[index] !== undefined){
                                row[columns[column]] = ws[index].w;
                            }
                            else{
                                row[columns[column]] = null;
                            }
                        }
                       
                        rowData.push(row);
                        rowIndex++;
                    }
                    this.setState({rowData:rowData})
                }
                else{
                    Swal.fire({
                        title:IMLocalized('wrong_file_selected'),
                        text:IMLocalized('please_choose_correct_language'),
                        type:'error',
                        confirmButtonColor:'#d33',
                        confirmButtonText:IMLocalized('ok!'),
                    })
                }
                
            }
            else{
                Swal.fire({
                    title:IMLocalized('wrong_file_selected'),
                    type:'error',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!'),
                })
            }
        };
        reader.readAsBinaryString(file);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.resetRowHeights();
    };

    handleFile = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    validateData=()=>{
        Promise.resolve(this.getEmployeeDetail())
    }


    getEmployeeDetail=()=>{
        let arr = [];
        const {language} = this.state;
        this.gridApi.forEachNode(function(rowNode,index){
            const data = rowNode.data;

            let salutationlist = this.props.salutation;
            let salutation = null;
            if(data.salutation !== null){
                let emp_salutation  = salutationlist.filter(function(item){
                    return item.display === data.salutation;
                })
                if(emp_salutation.length !== 0){
                    salutation = emp_salutation[0].value;
                }
            }
            
            const name = data.name;
            const employee_number = data.employee_number;
            const badge_number = data.badge_number;
            const email = data.email;


           
            let nationality = null;
            if(data.nationality !== null){
                
                const emp_nationality = countrylist.filter(function(item){
                    return item.display_name === data.nationality;
                })

                if(emp_nationality.length !== 0){
                    nationality = emp_nationality[0].value;
                }
            }
            
            let country_of_residence = null;
            if(data.country_of_residence !== null){
                const emp_country = countrylist.filter(function(item){
                    return item.display_name === data.country_of_residence
                })
                
                if(emp_country.length !== 0){
                    country_of_residence = emp_country[0].value;
                }
            }

            let id_type = null;
            if(data.id_type !== null){
                const id_list = this.props.sg_identification_type;
                const id_detail =  id_list.filter(function(item){
                    return item.display === data.id_type;
                })
                
                if(id_detail.length !== 0){
                    id_type = id_detail[0].value;
                }
            }
            
            const identification_number = data.identification_number;
            const contact_number = data.contact_number;

            let gender = null;
            if(data.gender !== null){
                if(language === 'zh'){
                    const emp_gender = genderlist.filter(function(item){
                        return item.display_zh === data.gender;
                    })
                    
                    if(emp_gender.length !== 0){
                        gender = emp_gender[0].value;
                    }
                }
                else{
                    const emp_gender = genderlist.filter(function(item){
                        return item.display === data.gender;
                    })
                    
                    if(emp_gender.length !== 0){
                        gender = emp_gender[0].value;
                    }
                }
                
            }
            
            let race = null;
            if(data.race !== null){
                if(language === 'zh'){
                    const emp_race = racelist.filter(function(item){
                        return item.display_zh === data.race;
                    })
                    
                    if(emp_race.length !== 0){
                        race = emp_race[0].value;
                    }
                }
                else{
                    const emp_race = racelist.filter(function(item){
                        return item.display === data.race;
                    })
                    
                    if(emp_race.length !== 0){
                        race = emp_race[0].value;
                    }
                }
                
            }
            
            let religion= null;
            if(data.religion !== null){
                if(language=== 'zh'){
                    const emp_religion = religionlist.filter(function(item){
                        return item.display_zh === data.religion;
                    })
                    
                    if(emp_religion.length !== 0){
                        religion = emp_religion[0].value;
                    }
                }
                else{
                    const emp_religion = religionlist.filter(function(item){
                        return item.display === data.religion;
                    })
                    
                    if(emp_religion.length !== 0){
                        religion = emp_religion[0].value;
                    }
                }
            }
            
            let relationship = null;
            if(data.relationship !== null){
                if(language === 'zh'){
                    const emp_relationship = relationshiplist.filter(function(item){
                        return item.display_zh === data.relationship;
                    })
                    
                    if(emp_relationship.legnth !== 0){
                        relationship = emp_relationship[0].value;
                    }
                }
                else{
                    const emp_relationship = relationshiplist.filter(function(item){
                        return item.display === data.relationship;
                    })
                    
                    if(emp_relationship.legnth !== 0){
                        relationship = emp_relationship[0].value;
                    }
                }
                
            }

            const emp_date_of_birth = data.date_of_birth;
            let date_of_birth = null;
            if(emp_date_of_birth !== null && emp_date_of_birth !== ''){
                date_of_birth = this.convertDate(emp_date_of_birth);
            }

            
            let company = null;
            if(data.company !== null){
                const {companylist} = this.props;
                const emp_company = companylist.filter(function(item){
                    return item.name === data.company
                })

                if(emp_company.length !== 0){
                    company = emp_company[0].id;
                }
            }
            
            let branch = null;
            if(data.branch !== null){
                const {branchlist} = this.props;
                const emp_branch = branchlist.filter(function(item){
                    return item.name === data.branch
                })

                if(emp_branch.length !== 0){
                    branch = emp_branch[0].id;
                }
            }
            
            let payment = null;
            if(data.payment !== null){
                if(language === 'zh'){
                    const emp_payment = paymentlist.filter(function(item){
                        return item.display_zh === data.payment;
                    })
    
                    if(emp_payment.length !== 0){
                        payment = emp_payment[0].value;
                    }
                }
                else{
                    const emp_payment = paymentlist.filter(function(item){
                        return item.display === data.payment;
                    })
    
                    if(emp_payment.length !== 0){
                        payment = emp_payment[0].value;
                    }
                }
                
            }

            let is_pr = null;
            const emp_pr = data.is_pr;
            if(language === 'zh'){
                if(emp_pr === '是'){
                    is_pr = true;
                }
                else{
                    is_pr = false;
                }
            }
            else{
                if(emp_pr === 'Yes'){
                    is_pr = true;
                }
                else{
                    is_pr = false;
                }
            }
            
            
            let pr_cpf_contribution_type = null;
            if(data.pr_cpf_contribution_type !== null){
                if(language === 'zh'){
                    const type = sgpr_employee_cpf_payment.filter(function(item){
                        return item.display_zh === data.pr_cpf_contribution_type;
                    })
                    
                    if(type.length !== 0){
                        pr_cpf_contribution_type = type[0].value;
                    }
                }
                else{
                    const type = sgpr_employee_cpf_payment.filter(function(item){
                        return item.display === data.pr_cpf_contribution_type;
                    })
                    
                    if(type.length !== 0){
                        pr_cpf_contribution_type = type[0].value;
                    }
                }
                
            }
            else{
                pr_cpf_contribution_type = 'STD';
            }

            const emp_pr_start_date = data.pr_start_date;
            let pr_start_date = null;
            if(emp_pr_start_date !== '' && emp_pr_start_date !== null){
                pr_start_date = this.convertDate(emp_pr_start_date);
            }

            const emp_join_date = data.join_date;
            let join_date = null;
            if(emp_join_date !== '' && emp_join_date !== null){
                join_date = this.convertDate(emp_join_date);
            }

            const emp_probation_end_date = data.probation_end_date;
            let probation_end_date = null;
            if(emp_probation_end_date !== '' && emp_probation_end_date !== null){
                probation_end_date = this.convertDate(emp_probation_end_date);
            }

            const sg_postal_code = data.sg_postal_code;
            const block_number = data.block_number;
            const street_name = data.street_name;
            const level_number = data.level_number;
            const unit_number = data.unit_number;
            const postal_code = data.postal_code;
            const address = data.address;


            arr.push({salutation,name,employee_number,badge_number,email,nationality,id_type,identification_number,contact_number,gender,race,religion,relationship,date_of_birth,company,branch,payment,is_pr,pr_start_date,join_date,probation_end_date,country_of_residence,sg_postal_code,block_number,street_name,level_number,unit_number,postal_code,address,pr_cpf_contribution_type});
        }.bind(this))

        if(arr.length !== 0){
            const {rowData} = this.state;
            let err = [];
            for(let i =0;i<arr.length;i++){
                let err1 = [];
                const y = i +1;
                const date_of_birth = arr[i].date_of_birth;
                if(date_of_birth !== null){
                    if(new Date(date_of_birth) > new Date()){
                        err1.push(<div><span>● {IMLocalized('dob_cant_great_today')}</span></div>)
                        rowData[i].err_date_of_birth = true;
                        this.setState({rowData});
                        gridOptions.api.setRowData(rowData);
                    }
                }
                const name = arr[i].name;
                if(err1.length !== 0){
                    let string = '';
                    if(name === null){
                        string = `${IMLocalized('employee')} ${IMLocalized('row')} `+ y;
                    }
                    else{
                        string =name;
                    }
                    err.push(<tr><td>{string}</td><td>{err1}</td></tr>)
                }
               
            }
            if(err.length !== 0){
                this.setState({error_msg:err});
                this.setState({error_toggle:true});
            }
            else{
                this.setState({validatelist:arr});
                this.props.create_employee_unique_check(arr);
            }
           
        }
    }

    convertDate=(date)=>{
        
        const date1 = moment(date,'DD/MM/YYYY');
        const date2 = date1.format('YYYY-MM-DD');
        return date2;
    }

    closeError=()=>{
        const toggle = !this.state.error_toggle;
        this.setState({error_toggle:toggle});
    }

    getErrorTable=()=>{

        const table=(
            <Modal isOpen={this.state.error_toggle} size="lg">
                <ModalHeader toggle={this.closeError}>{IMLocalized('error_msg')}</ModalHeader>
                <ModalBody>
                    <Table className="align-items-center table-bordered" size="sm" responsive>
                        <thead className="p-2 thead-light">
                            <tr>
                                <th>{IMLocalized('name')}</th>
                                <th>{IMLocalized('error')}</th>
                            </tr>
                        </thead>
                        <tbody className="p-2">
                            {this.state.error_msg}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <button onClick={this.closeError} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </ModalFooter>
            </Modal>
        )
        return table;
    }

    clearData=()=>{
        this.setState({rowData:[]});
        this.setState({error_msg:[]});
    }

    toHome=()=>{
        this.props.toHome('1','dashboard');
    }

    render(){
        const {rowData,error_msg} = this.state;
        let btn ='';
        let error_btn ='';
        if(rowData.length !== 0){
            btn = <button onClick={this.validateData} className="btn btn-primary btn-sm">{IMLocalized('create')}</button>
        
            if(error_msg.length !== 0){
                error_btn = <button className="btn btn-danger btn-sm" onClick={this.closeError}>{IMLocalized('error_msg')}</button>
            }
        }

        const {action_toggle} = this.context;
        let currentHeight ='calc(100vh - 260px)';
        if(action_toggle === false){
            currentHeight = 'calc(100vh - 224px)';
        }
        return(
            <div>
                {this.props.isLoading1 || this.props.isLoading2 || this.props.isLoading3 || this.props.isLoading4 || this.props.isLoading5 || this.props.isLoading6 ? <OverlayLoading />:
                <div>
                    <Container fluid>
                        <Row className="border-bottom bg-white">
                            <Col className="p-2">
                                <BreadCrumbList list={[
                                    { active: false, title:IMLocalized('home'), onClick:()=>this.context.addItem('1','dashboard')},
                                    { active: true, title:IMLocalized('employee_import')}
                                ]}/>                                
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col>
                                <div className="ml-2 d-flex">
                                    <div className="mr-2">
                                    <button onClick={this.generateExcel.bind(this)} className="btn btn-primary btn-sm">{IMLocalized('get_template')}</button>
                                    </div>
                                    <div className="mr-2">
                                        <input type="file" style={{display:'none'}} id="importemployeecustomFile1" className="custom-file-input form-control-sm" onChange={this.uploadExcel.bind(this)} onClick={this.handleFile} accept=".xlsx" />
                                        <label className="btn btn-primary btn-sm" htmlFor="importemployeecustomFile1">{IMLocalized('choose_file')}</label>
                                    </div>
                                    <div>
                                        {btn}
                                        <button className="btn btn-primary btn-sm" onClick={this.clearData}>{IMLocalized('clear')}</button>
                                        {error_btn}
                                        {/* <button onClick={this.createEmployee.bind(this)} className="btn btn-primary btn-sm">Create</button> */}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <Row className="pt-2">
                            <Col>
                                <div className="ag-theme-balham" style={{height:currentHeight, width:'100%' }}>
                                    <AgGridReact
                                        onGridReady={this.onGridReady}
                                        columnDefs={this.state.columnDefs}
                                        rowData={this.state.rowData}
                                        suppressDragLeaveHidesColumns={true}
                                        overlayNoRowsTemplate = {this.state.noRowTemplate}
                                        suppressSizeToFit={true}
                                        suppressColumnMoveAnimation={false}
                                        rowStyle={this.state.rowStyle}
                                        getRowStyle={this.state.getRowStyle}
                                        getRowHeight={this.state.getRowHeight}
                                        singleClickEdit={true}
                                        gridOptions={gridOptions}
                                        defaultColDef={this.state.defaultColDef}
                                        stopEditingWhenCellsLoseFocus={true}
                                        onCellValueChanged={this.onCellValueChanged}
                                        //onColumnResized={this.onColumnResized.bind(this)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {this.getErrorTable.call(this)}
                    </Container>
                </div>}
            </div>
        )
    }
}
function mapStateToProps(state){
    return{
        salutation:state.dropdown_list.salutation,
        companylist:state.company_list.data,
        branchlist:state.get_branch_list.data,
        nationality:state.dropdown_list.countries,
        payment:state.dropdown_list.payment,
        sg_identification_type:state.dropdown_list.s_g_identification_type,
        unique_errors:state.create_employee_unique_check.errors,
        unique_data:state.create_employee_unique_check.data,
        array_errors:state.create_employee_array_validate.errors,
        array_data:state.create_employee_array_validate.data,
        create_success:state.create_employee_array_create.data,

        isLoading1:state.dropdown_list.isLoading,
        isLoading2:state.company_list.isLoading,
        isLoading3:state.get_branch_list.isLoading,
        isLoading4:state.create_employee_unique_check.isLoading,
        isLoading5:state.create_employee_array_validate.isLoading,
        isLoading6:state.create_employee_array_create.isLoading,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        create_employee_unique_check:(data)=>{
            dispatch(create_employee_unique_check(data))
        },
        create_employee_array_validate:(data)=>{
            dispatch(create_employee_array_validate(data))
        },
        create_employee_array_create:(data)=>{
            dispatch(create_employee_array_create(data))
        },
        get_employee_list:()=>{
            dispatch(get_employee_list())
        },
        get_employee_address:()=>{
            dispatch(get_employee_address())
        },
        get_employee_list_withoutcontract:()=>{
            dispatch(get_employee_list_withoutcontract())
        },
        get_latest_employee:()=>{
            dispatch(get_latest_employee())
        },
        get_employee_probation_list:()=>{
            dispatch(get_employee_probation_list())
        }
    }
}
import_create_employee.contextType = NewMainContext;
export default connect(mapStateToProps, mapDispatchToProps)(import_create_employee);