import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { get_employee_certification_picture, reset_get_employee_certification_picture } from '../../../../action/employee/get_employee_certification_picture';
import { delete_employee_certification_picture } from '../../../../action/employee/delete_employee_certification_picture';
import Swal from 'sweetalert2';
import Viewer from 'react-viewer';
import { Buffer } from 'buffer';
import { getDateFormat } from '../../../../func/getDateFormat';
import { nationality_detail } from '../../../../reducers/dropdown_list';


export default function ModalViewCertification({toggle, detail, closeModal, type}){

    const picture = useSelector(state=>state.get_employee_certification_picture.data);
    const dispatch = useDispatch();
    const [imagelist,setImgList] = useState([]);
    const [images,setImages] = useState([]);
    const [activeImg,setActive] = useState(0);
    const [img_toggle,setImgToggle] = useState(false);

    useEffect(()=>{
        if(toggle && detail){
            setImgList(detail.images);
            setImages([]);
        }
        else{
            setImgList([]);
            setImages([]);
            dispatch(reset_get_employee_certification_picture());
        }
    },[toggle,detail,dispatch])

    useEffect(()=>{
        if(imagelist.length !== 0 && toggle){
            setImages([]);
            for(let i=0;i<imagelist.length;i++){
                const id = imagelist[i];
                dispatch(get_employee_certification_picture(id));
            }
        }
    },[imagelist,dispatch,toggle])

    useEffect(()=>{
        if( picture !== null && toggle){
            const img = new Buffer.from(picture,'binary').toString('base64');
            const src = 'data:image/png;base64,'+img;
            setImages(state=>[...state,{src}]);
        }
    },[picture,toggle])

    const open=(index)=>{
        setImgToggle(true);
        setActive(index);
    }

    const removePicture=(src,e)=>{
        if(e && e.stopPropagation){
            e.stopPropagation();
        }
        Swal.fire({
            title:IMLocalized('delete_picture_confirmation'),
            text:IMLocalized('revert_msg'),
            type:'warning',
            showCancelButton:true,
            cancelButtonColor:'#3085d6',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
        })
        .then((result)=>{
            if(result.value){
                const index = images.findIndex(item=>item.src === src);
                const id = imagelist[index];
                const data = [...images];
                const data1 = [...imagelist];
                data.splice(index,1);
                data1.splice(index,1);
                setImgList(data1);
                setImages(data);
                dispatch(delete_employee_certification_picture(id))
            }
        })
    }

    const country_name = useSelector(state=>nationality_detail(state,detail?.country));

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('certification_detail')}</ModalHeader>
            <ModalBody>
                {detail &&
                <Row>
                    <Col>
                        <Table className="table-bordered" size="sm">
                            <tbody>
                                <tr>
                                    <td className="w-25">{IMLocalized('name')}</td>
                                    <td>{detail.name}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('school')}</td>
                                    <td>{detail.school}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('country')}</td>
                                    <td>{country_name }</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('acquired_date')}</td>
                                    <td>{getDateFormat(detail.acquired_date)}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('expiry_date')}</td>
                                    <td>{getDateFormat(detail.expiry_date)}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('notification_duration_days')}</td>
                                    <td>{detail?.notification_duration_days ?? '---'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('image')}</td>
                                    <td>
                                        <div className="image-gallery">
                                            {images.length !== 0 ? 
                                            images.map((item,index)=>{
                                                return(
                                                <div>
                                                    <div className="image-item mr-2" >
                                                        <div className="image-inner" onClick={()=>open(index)}>
                                                            <img src={item.src} key={index}  className="img-thumbnail" alt="emp_education_picture"></img>
                                                            {type === 'hr' &&
                                                            <div className='picture_btn ' style={{zIndex:100}} onClick={(e)=>removePicture(item.src,e)}>
                                                                <i className="ni ni-fat-remove text-danger cursor-pointer "  />
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )}):
                                            <span>---</span>}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                }
                <Viewer activeIndex={activeImg} zIndex={1060} visible={img_toggle} onClose={()=>setImgToggle(false)} images={images} />
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}