import React,{Component} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {connect} from 'react-redux';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, Table, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import moment from 'moment';
import {IMLocalized} from '../../../language/IMLocalized';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { employee_permit_array_save } from '../../../action/wizard_employee/employee_permit_array_save';
import { get_employee_permit } from '../../../action/employee/get_employee_permit';
import { NewMainContext } from '../../../context/NewMainContext';
import BreadCrumbList from '../../breadcrumb/BreadCrumbList';



const gridOptions={
    singleClickEdit:true,
    defaultColDef:{
        sortable:true,
        filter:true,
        suppressMenu:true,
        resizable:true
    },
    sideBar:{
        toolPanels:[
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                
            }
        ],
        defaultToolPanel:''
    },
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
}

class import_create_employee_permit extends Component{

    constructor(props){
        super(props);
        this.state={
            list:[],
            language:localStorage.getItem('language'),
            error_msg:[],
            rowData:[],
            rowData2:[],
            import_lang:'',
            errorlist:[],
            columnsDefs:[],
            err_toggle:false,
            noRowTemplate:IMLocalized('no_data'),
            step:0,
        }
    }

    componentDidMount(){
        const {emplist} = this.props;
        if(emplist.length !== 0){
            this.getDetail();
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.emplist !== this.props.emplist || prevState.step !== this.state.step){
            Promise.resolve(this.getDetail())
        }
        if(prevProps.success !== this.props.success){
            this.props.get_employee_permit();
            this.clearData();
        }
        if(this.context.import_employee_permit_reset === true){
            this.clearData();
            this.context.setImportEmployeePermitReset(false);
        }
    }

    getDetail=()=>{
        let arr = [];

        const {emplist,permitlist} = this.props;
        if(emplist.length !== 0){
            for(let i =0;i<emplist.length ;i++){
                const item = emplist[i];
                const employee = item.id;
                const name = item.name;
                const employee_number = item.employee_number;
                const is_active = item.active;
                const nationality = item.nationality;
                const is_pr = item.is_pr;
                if(is_active === true){
                    if(nationality !== 'SG' && is_pr !== true){
                        const permit_type = null;
                        const permit = null;
                        const man_year_entitlement = 'false';
                        const expiry_date = 'd/M/yy';
                        const issued_date = 'd/M/yy';
                        const application_date = 'd/M/yy';
                        const err_permit_type = false;
                        const err_permit = false;
                        const err_expiry = false;
                        const err_issued = false;
                        const err_application = false;
                        arr.push(
                            {permit_type,permit,man_year_entitlement,expiry_date,issued_date,application_date,err_permit_type,err_permit,err_expiry,err_issued,err_application,name,employee_number,employee}
                        )
                    }
                   
                }
            }
        }

        let permit_option = [''];
        if(permitlist.length !== 0){
            for(let i=0;i<permitlist.length;i++){
                const name = permitlist[i].display;
                permit_option.push(name);
            }
        }

        this.setState({rowData:arr});

        const columnsDefs=[
            {
                headerName:IMLocalized('agrid_select'),
                field:'',
                editable:false,
                width:120,
                lockPosition:true,
                pinned:'left',
                filter:false,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
            },
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee_number',
                editable:false,
                width:200
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'name',
                editable:false,
                width:200
            },
            {
                headerName:IMLocalized('agrid_permit_type'),
                field:'permit_type',
                editable:true,
                width:150,
                cellEditor:'agSelectCellEditor',
                cellEditorParams:{
                    values:permit_option,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_permit_type}
                },
            },
            {
                headerName:IMLocalized('agrid_permit_number'),
                field:'permit',
                editable:true,
                width:150,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_permit}
                },
            },
            {
                headerName:IMLocalized('agrid_application_date'),
                field:'application_date',
                editable:true,
                width:150,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_application}
                },
            },
            {
                headerName:IMLocalized('agrid_expiry_date'),
                field:'expiry_date',
                editable:true,
                width:150,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_expiry}
                },
            },
            {
                headerName:IMLocalized('agrid_issued_date'),
                field:'issued_date',
                editable:true,
                width:150,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_issued}
                },

            },
            {
                headerName:IMLocalized('agrid_man_year_entitlement'),
                field:'man_year_entitlement',
                editable:false,
                cellRenderer:this.checkboxCellRenderer,
                width:200
            }
           
        ]

        this.setState({columnsDefs});
        
    }

    isFirstColumn(params) {
        if(params !== null){
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }   
    }

    checkboxCellRenderer(params){
        if(params.value !== 'true' && params.value !== 'false'){
            params.setValue(params.value === true || params.value === 'true' ? 'true' : 'false');
        }
        else{
            var input = document.createElement("input");
            
            input.type = "checkbox";
            input.value = params.value === true || params.value === 'true' ? 'true' : 'false';
            input.checked = params.value === true || params.value === 'true' ? true : false;
            
            input.onclick = function(){
                params.setValue(this.checked === true ? 'true' : 'false');
            }
           
            return input;
        }
    }

    handleFile = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    getTemplate=()=>{
        const {language} = this.state;
        const {permitlist} = this.props;
        const rowData = gridOptions.api.getSelectedRows();
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_permit');
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');
        ws.columns=[
            {header:IMLocalized('agrid_emp_id'),key:'employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'name'},
            {header:IMLocalized('agrid_permit_type'),key:'permit_type'},
            {header:IMLocalized('agrid_permit_number'),key:'permit'},
            {header:IMLocalized('agrid_application_date'),key:'application_date'},
            {header:IMLocalized('agrid_expiry_date'),key:'expiry_date'},
            {header:IMLocalized('agrid_issued_date'),key:'issued_date'},
            {header:IMLocalized('agrid_man_year_entitlement'),key:'man_year_entitlement'}
        ];

        const columns=[
            {header:IMLocalized('agrid_emp_id'),key:'employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'name'},
            {header:IMLocalized('agrid_permit_type'),key:'permit_type'},
            {header:IMLocalized('agrid_permit_number'),key:'permit'},
            {header:IMLocalized('agrid_application_date'),key:'application_date'},
            {header:IMLocalized('agrid_expiry_date'),key:'expiry_date'},
            {header:IMLocalized('agrid_issued_date'),key:'issued_date'},
            {header:IMLocalized('agrid_man_year_entitlement'),key:'man_year_entitlement'}
        ];

        ws3.getCell('A1').value = 'ImportEmployeePermit';
        ws3.getCell('A2').value = language;
        for(let i=0;i<permitlist.length;i++){
            const y =i+1;
            ws2.getCell('A'+y).value = permitlist[i].display;

        }
        const datalist = [{data:IMLocalized('excel_true')},{data:IMLocalized('excel_false')}];
        for(let i=0;i<datalist.length;i++){
            const y=i+1;
            ws2.getCell('B'+y).value = datalist[i].data;
        }

        if(rowData.length !== 0){

            for(let i=0;i<columns.length;i++){
                const field = columns[i].key;
                for(let x=0;x<rowData.length;x++){
                    const step = 2+x;
                    const data = this.getValue(rowData[x],field);
                    ws.getCell(this.getColumnName(i)+step).value = data;
                    if(field === 'application_date' || field === 'expiry_date' || field === 'issued_date'){
                        ws.getCell(this.getColumnName(i)+step).numFmt = 'd/M/yy';
                        ws.getCell(this.getColumnName(i)+step).value = null;
                    }
                    else if (field ==='permit_type'){
                        if(permitlist.length !== 0){
                            ws.getCell(this.getColumnName(i)+step).dataValidation={
                                type:'list',
                                allowBlank:true,
                                formulae:['=setting!$A$1:$A$'+permitlist.length],
                                error:'Value must be in the list',
                                errorStyle:'error',
                                errorTitle:'Error',
                                showErrorMessage:true,
                            };
                        }
                        ws.getCell(this.getColumnName(i)+step).value = null;
                    }
                    else if(field === 'man_year_entitlement'){
                        if(datalist.length !== 0){
                            ws.getCell(this.getColumnName(i)+step).dataValidation={
                                type:'list',
                                allowBlank:true,
                                formulae:['=setting!$B$1:$B$'+datalist.length],
                                error:'Value must be in the list',
                                errorStyle:'error',
                                errorTitle:'Error',
                                showErrorMessage:true,
                            };
                        }
                        ws.getCell(this.getColumnName(i)+step).value = null;
                    }
                    else if(field ==='permit'){
                        ws.getCell(this.getColumnName(i)+step).value = null;
                    }
                    else{
                        ws.getCell(this.getColumnName(i)+step).value = data;
                    }
                }
            }

            ws.columns.forEach(column =>{
                column.width = column.header.length < 15 ? 15 : column.header.length
            });
    
            wb.xlsx.writeBuffer().then((buf)=>{
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
               FileSaver.saveAs(file, 'Import_Employee_Permit_Template.xlsx')
            });

        }
        else{
            Swal.fire({
                title:IMLocalized('no_employee_selected'),
                type:'warning',
                confirmButtonText:IMLocalized('ok!')
            })
        }
    }

    getValue(object, path) {
        const parts = path.split(".");
        return parts.reduce((value, key) => value ? value[key] : value, object)
    }

    getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }

    uploadExcel=(e)=>{
        const file = e.target.files[0];
        const reader = new FileReader();
        const {language} = this.state;
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3];

            const columns = {
                'A':'employee_number',
                'B':'name',
                'C':'permit_type',
                'D':'permit',
                'E':'application_date',
                'F':'expiry_date',
                'G':'issued_date',
                'H':'man_year_entitlement'
            }

            let rowData1 = [];
            let rowIndex =2;
            let idd = '';
            let lang = '';
            if(ws3 !== undefined){
                if(ws3['A1'] !== undefined){
                    idd = ws3['A1'].w;
                    lang = ws3['A2'].w;
                }
            }

            if(idd === 'ImportEmployeePermit'){
                if(lang === language){
                    while(ws['A'+rowIndex]){
                        let row ={};
                        for(let i =0;i<Object.keys(columns).length;i++){
                            const column = Object.keys(columns)[i];
                            if(ws[column+rowIndex] !== undefined){
                                row[columns[column]] = ws[column+rowIndex].w;
                            }
                            else{
                                row[columns[column]] = null;
                            }
                        }
                        rowData1.push(row);
                        rowIndex++;
                    }
                    Promise.resolve(this.getClearAggrid())
                    .then(()=> this.getImportData(rowData1,lang))
                }
                else{
                    Swal.fire({
                        title:IMLocalized('wrong_file_selected'),
                        text:IMLocalized('please_choose_correct_language'),
                        type:'error',
                        confirmButtonColor:'#d33',
                        confirmButtonText:IMLocalized('ok!'),
                    })
                }
                
            }
            else{
                Swal.fire({
                    title:IMLocalized('wrong_file_selected'),
                    type:'error',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!'),
                })
            }
        }
        reader.readAsBinaryString(file);
    }

    getClearAggrid=()=>{
        gridOptions.api.forEachNode((rowNode,index)=>{
            rowNode.setSelected(false);
        })
    }

    getImportData=(item1,lang)=>{
        for(let i =0;i<item1.length;i++){
            const item = item1[i];
            const employee_number = item.employee_number;
            const permit_type = item.permit_type;
            const permit = item.permit;
            const application_date = item.application_date;
            const issued_date = item.issued_date;
            const expiry_date = item.expiry_date;
            const man_year_entitlement = item.man_year_entitlement;
            let toggle = 'false';
            if(lang === 'zh'){
                if(man_year_entitlement === '是'){
                    toggle = 'true';
                }
            } 
            else{
                if(man_year_entitlement === 'Yes'){
                    toggle = 'true';
                }
            }

            gridOptions.api.forEachNode((rowNode,index)=>{
                const employee_number1 = rowNode.data.employee_number;
                if(employee_number1 === employee_number){
                    rowNode.data.permit_type = permit_type;
                    rowNode.data.permit = permit;
                    rowNode.data.application_date =application_date;
                    rowNode.data.expiry_date = expiry_date;
                    rowNode.data.issued_date = issued_date;
                    rowNode.data.man_year_entitlement = toggle;
                    rowNode.data.err_permit= false;
                    rowNode.data.err_permit_type = false;
                    rowNode.data.err_application = false;
                    rowNode.data.err_issued =false;
                    rowNode.data.err_expiry = false;
                    rowNode.setSelected(true);
                    gridOptions.api.refreshCells(rowNode)
                }
            })
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        
    }

    clearData=()=>{
        const {step} = this.state;
        this.setState({err_msg:[]});
        Promise.resolve(this.setState({rowData:[]}))
        .then(()=>this.setState({step:step+1}));
    }

    onCreate=()=>{
        this.setState({err_msg:[]});
        const data = gridOptions.api.getSelectedRows();
        const {permitlist} = this.props;
        let arr = [];
        let err = [];
        let validate = [];
        if(data.length !== 0){
            for(let i=0;i<data.length;i++){
                let err1 = [];
                const date1 = data[i].application_date;
                const date2 = data[i].expiry_date;
                const employee = data[i].employee;
                const name = data[i].name;
                const type = data[i].permit_type;
                const permit = data[i].permit;
                const number = data[i].employee_number;
                const date3 = data[i].issued_date;
                const man = data[i].man_year_entitlement;
                let application_date =null;
                let expiry_date = null;
                let issued_date =null;
                let permit_type = null;
                let err_permit = false;
                let err_permit_type = false;
                let err_expiry = false;
                let err_issued = false;
                let err_application = false;
                
                let man_year_entitlement = false;
                if(man === 'true'){
                    man_year_entitlement = true;
                }
                if(permit === '' || permit === null ){
                    err1.push(<div><span>● {IMLocalized('permit_number_is_required')}</span></div>);
                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee_number === number){
                            rowNode.data.err_permit = true;
                            gridOptions.api.refreshCells(rowNode)
                        }
                    })
                    
                }
                
                if(type !== '' && type !== null ){
                    
                    const detail = permitlist.filter(function(item){
                        return item.display === type;
                    })
                    permit_type = detail[0].value;
                }
                else{
                    err1.push(<div><span>● {IMLocalized('permit_type_is_required')}</span></div>);
                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee_number === number){
                            rowNode.data.err_permit_type = true;
                            gridOptions.api.refreshCells(rowNode)
                        }
                    })
                }
                if(date1 !== null && date1 !== '' && date1 !== 'd/M/yy'){
                    application_date = this.convertDate(date1);
                    if(application_date === 'Invalid date'){
                        err1.push(<div><span>● {IMLocalized('application_date_is_invalid')}</span></div>);
                        gridOptions.api.forEachNode((rowNode,index)=>{
                            if(rowNode.data.employee_number === number){
                                rowNode.data.err_application = true;
                                gridOptions.api.refreshCells(rowNode)
                            }
                        })
                    }
                }
                else{
                    const msg = IMLocalized('application_date_is_required');
                    err1.push(<div><span>● {msg}</span></div>);
                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee_number === number){
                            rowNode.data.err_application = true;
                            gridOptions.api.refreshCells(rowNode)
                        }
                    })
                }

                if(date2 !== null && date2 !== '' && date2 !== 'd/M/yy'){
                    expiry_date = this.convertDate(date2);
                    if(expiry_date === 'Invalid date'){
                        err1.push(<div><span>● {IMLocalized('expiry_date_is_invalid')}</span></div>);
                        gridOptions.api.forEachNode((rowNode,index)=>{
                            if(rowNode.data.employee_number === number){
                                rowNode.data.err_expiry = true;
                                gridOptions.api.refreshCells(rowNode)
                            }
                        })
                    }
                }
                else{
                    const msg = IMLocalized('expiry_date_is_required');
                    err1.push(<div><span>● {msg}</span></div>);
                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee_number === number){
                            rowNode.data.err_expiry = true;
                            gridOptions.api.refreshCells(rowNode)
                        }
                    })
                }

                if(date3 !== null && date3 !== '' && date3 !== 'd/M/yy'){
                    issued_date = this.convertDate(date3);
                    if(issued_date === 'Invalid date'){
                        err1.push(<div><span>● {IMLocalized('issued_date_is_invalid')}</span></div>);
                        gridOptions.api.forEachNode((rowNode,index)=>{
                            if(rowNode.data.employee_number === number){
                                rowNode.data.err_issued = true;
                                gridOptions.api.refreshCells(rowNode)
                            }
                        })
                    }
                }
                else{
                    const msg = IMLocalized('issued_date_is_required');
                    err1.push(<div><span>● {msg}</span></div>);
                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee_number === number){
                            rowNode.data.err_issued = true;
                            gridOptions.api.refreshCells(rowNode)
                        }
                    })
                }
                if(err1.length !== 0){
                    err.push({name,number,msg:err1})
                    validate.push({number,err_application,err_issued,err_expiry,err_permit,err_permit_type})
                }
                arr.push({employee,application_date,expiry_date,issued_date,permit,permit_type,man_year_entitlement});
            }
        }

        if(err.length !== 0){
            this.handleError(err,validate);
        }
        else{
            if(arr.length !== 0){
                this.props.employee_permit_array_save(arr);
            }
            else{
                this.popError(IMLocalized('no_employee_selected'));
            }
        }
        
    }

    handleError=(data,err)=>{
        let arr = [];
        for(let i=0;i<data.length ;i++){
            const msg = data[i].msg;
            const name = data[i].name;
            const number = data[i].number;
            arr.push(
                <tr>
                    <td>{number}</td>
                    <td>{name}</td>
                    <td>{msg}</td>
                </tr>
            )
        
        }
        this.setState({err_msg:arr});
        this.setState({err_toggle:true});
    }

    closeError=()=>{
        const toggle = !this.state.err_toggle;
        this.setState({err_toggle:toggle});
    }

    popError=(msg)=>{
        Swal.fire({
            title:msg,
            type:'warning',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('ok!')
        })
    }

    convertDate=(date)=>{
        const date1 = moment(date,'dd/M/YY');
        const date2 = date1.format('YYYY-MM-DD');
        return date2;
    }

    onCellValueChanged=(params)=>{
        if(params.oldValue !== params.newValue){
            const column = params.column.colDef.field;
            if(column === 'man_year_entitlement'){
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            if(column === 'permit'){
                params.data.err_permit = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            else if(column === 'application_date'){
                params.data.err_application = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            else if(column === 'expiry_date'){
                params.data.err_expiry = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            else if(column === 'issued_date'){
                params.data.err_issued = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            else if(column === 'permit_type'){
                params.data.err_permit_type = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            params.api.refreshCells();
        }
    }

    onCellFocused=(params)=>{
        if(params !== null){
            if(params.column.colDef !== null){
                if(params.column.colDef.field ==='permit' || params.column.colDef.field ==='application_date' || params.column.colDef.field ==='expiry_date' || params.column.colDef.field ==='issued_date' || params.column.colDef.field ==='permit_type' || params.column.colDef.field ==='man_year_entitlement'){
                    
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = true;
                }
                else{
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = false;
                }
            }   
        }
    }

    render(){

        const {rowData,error_msg} = this.state;
        let btn  ='';
        if(rowData.length !== 0){
            btn= <button className="btn btn-primary btn-sm" onClick={this.getTemplate}>{IMLocalized('get_employee_permit_template')}</button>
        }
        let error_btn = '';
        if(error_msg.length !== 0){
            error_btn =<button className="btn btn-danger btn-sm" onClick={this.setErrToggle}>{IMLocalized('error_msg')}</button>
        }
        const {action_toggle} = this.context;
        let currentHeight = 'calc(100vh - 260px)';
        if(action_toggle === false){
            currentHeight = 'calc(100vh - 224px)';
        }
        return(
            <Container fluid>
                <Row className="border-bottom bg-white">
                    <Col className="p-2">
                        <BreadCrumbList list={[
                            { active: false, title:IMLocalized('home'), onClick:()=>this.context.addItem('1','dashboard')},
                            { active: true, title:IMLocalized('employee_permit_import')}
                        ]} />
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col>
                        <div className="d-flex">
                            <div>
                                {btn}
                            </div>
                            <div className="ml-2 mr-2">
                                <input type="file" style={{display:'none'}} id="ImportPermitcustomFile1" className="custom-file-input form-control-sm" onChange={this.uploadExcel.bind(this)} onClick={this.handleFile} accept=".xlsx" />
                                <label className="btn btn-primary btn-sm" for="ImportPermitcustomFile1">{IMLocalized('import_from_file')}</label>
                            </div>
                            <div>
                                <button className="btn btn-primary btn-sm" onClick={this.clearData}>{IMLocalized('clear')}</button>
                                <button className="btn btn-primary btn-sm" onClick={this.onCreate}>{IMLocalized('create')}</button>
                                {error_btn}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col>
                        <div className="d-flex justify-content-center">
                            <div className="ag-theme-balham" style={{height:currentHeight, width:'100%' }}>
                                <AgGridReact
                                columnDefs={this.state.columnsDefs}
                                rowData={this.state.rowData}
                                suppressDragLeaveHidesColumns={true}
                                suppressSizeToFit={true}
                                suppressColumnMoveAnimation={false}
                                singleClickEdit={true}
                                onCellValueChanged={this.onCellValueChanged}
                                onCellFocused={this.onCellFocused}
                                rowClassRules={{
                                    'sick-days-warning': function (params) {
                                        var numSickDays = params.data.err_non;
                                        return numSickDays ===true
                                        },
                                
                                }}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                gridOptions={gridOptions}
                                overlayNoRowsTemplate = {this.state.noRowTemplate}
                                stopEditingWhenCellsLoseFocus={true}
                                >
                                </AgGridReact>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Modal isOpen={this.state.err_toggle} size="lg">
                    <ModalHeader toggle={this.closeError}>{IMLocalized('error_msg')}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Table size="sm" className="table-bordered align-items-center">
                                    <thead>
                                        <tr>
                                            <th>{IMLocalized('employee_number')}</th>
                                            <th>{IMLocalized('name')}</th>
                                            <th>{IMLocalized('error')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.err_msg}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-danger btn-sm" onClick={this.closeError}>{IMLocalized('close')}</button>
                    </ModalFooter>
                </Modal>
            </Container>
        )
    }
}
function mapStateToProps(state){
    return{
        emplist:state.get_employee_list.data,
        permitlist:state.dropdown_list.work_permit,
        isLoading1:state.dropdown_list.isLoading,
        isLoading:state.get_employee_list.isLoading,
        success:state.employee_permit_array_save.data,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        employee_permit_array_save:(data)=>{
            dispatch(employee_permit_array_save(data))
        },
        get_employee_permit:()=>{
            dispatch(get_employee_permit())
        }
    }  
}
import_create_employee_permit.contextType = NewMainContext;
export default connect(mapStateToProps, mapDispatchToProps)(import_create_employee_permit);