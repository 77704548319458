import * as createclaim from '../../action/claim/create_claim';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const create_claim = (state=initialState,action)=>{
    switch(action.type){
        case createclaim.CREATECLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case createclaim.CREATECLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createclaim.CREATECLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default create_claim;