import myaxios from '../../axios';

export const GETKEYEVENTS_REQUEST = 'GETKEYEVENTS_REQUEST';
export const GETKEYEVENTS_SUCCESS = 'GETKEYEVENTS_SUCCESS';
export const GETKEYEVENTS_FAILURE = 'GETKEYEVENTS_FAILURE';

export const get_key_events = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETKEYEVENTS_REQUEST'
        });

        myaxios.get('employee/events/')
        .then((response)=>{
            dispatch({
                type:'GETKEYEVENTS_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETKEYEVENTS_FAILURE',
                payload:error
            })
        })
    }
}