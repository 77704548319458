import React,{Component} from 'react';
import {connect} from 'react-redux';
import { Card, CardBody, Row,Col, CardHeader ,Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Container} from 'reactstrap';
import {Responsive,WidthProvider} from 'react-grid-layout';
import {get_general_dashboard} from '../../action/dashboard/get_general_dashboard';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import {update_user_layout } from '../../action/dashboard/update_user_layout';
import '../../css/dashboard.css';
import {IMLocalized} from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

const ResponsiveGridLayout = WidthProvider(Responsive);

class claim_officer_dashboard extends Component{
    
    constructor(props){
        super(props);
        this.state={
            userlist:[],
            userclaimlist:[],
            userleavelist:[],
            userpayslip:[],
            approvals:[],
            claimapprovals:[],
            layout:{
                lg:[
                    {i: '3', x: 0 , y: 4, w: 4, h: 7},
                    {i: '4', x: 4 , y: 4, w : 4, h: 7},
                    {i: '5', x: 0 , y: 0, w : 4, h: 3},
                    {i: '7', x: 4 , y: 0, w : 4, h: 3},
                    {i: '1', x: 8 , y: 0, w : 4, h: 3}
                ],
                md:[
                    {i: '3', x: 5 , y: 4, w: 5, h: 7 },
                    {i: '4', x: 0 , y: 8, w: 5, h: 7},
                    {i: '5', x: 0 , y: 0, w: 5, h: 3 },
                    {i: '7', x: 5 , y: 0, w: 5, h: 3},
                    {i: '1', x: 0 , y: 4, w: 5, h: 3},
                    
                ],
                sm:[
                    {i: '3', x: 0 , y: 12, w: 6, h: 7},
                    {i: '4', x: 0 , y: 20, w: 6, h: 7},
                    {i: '5', x: 0 , y: 0, w: 6, h: 3},
                    {i: '7', x: 0 , y: 4, w: 6, h: 3},
                    {i: '1', x: 0 , y: 8, w: 6, h: 3},
                ],
                xs:[
                    {i: '3', x: 0 , y: 12, w: 4, h: 7},
                    {i: '4', x: 0 , y: 20, w: 4, h: 7},
                    {i: '5', x: 0 , y: 0, w: 4, h: 3},
                    {i: '7', x: 0 , y: 4, w: 4, h: 3},
                    {i: '1', x: 0 , y: 8, w: 4, h: 3},
                ],
                xxs:[
                    {i: '3', x: 0 , y: 12, w: 2, h: 7},
                    {i: '4', x: 0 , y: 20, w: 2, h: 7},
                    {i: '5', x: 0 , y: 0, w: 2, h: 3},
                    {i: '7', x: 0 , y: 4, w: 2, h: 3},
                    {i: '1', x: 0 , y: 8, w: 2, h: 3},
                ]
            },
            defaultlayout:{
                lg:[
                    {i: '3', x: 0 , y: 4, w: 4, h: 7},
                    {i: '4', x: 4 , y: 4, w : 4, h: 7},
                    {i: '5', x: 0 , y: 0, w : 4, h: 3},
                    {i: '7', x: 4 , y: 0, w : 4, h: 3},
                    {i: '1', x: 8 , y: 0, w : 4, h: 3}
                ],
                md:[
                    {i: '3', x: 5 , y: 4, w: 5, h: 7 },
                    {i: '4', x: 0 , y: 8, w: 5, h: 7},
                    {i: '5', x: 0 , y: 0, w: 5, h: 3 },
                    {i: '7', x: 5 , y: 0, w: 5, h: 3},
                    {i: '1', x: 0 , y: 4, w: 5, h: 3},
                    
                ],
                sm:[
                    {i: '3', x: 0 , y: 12, w: 6, h: 7},
                    {i: '4', x: 0 , y: 20, w: 6, h: 7},
                    {i: '5', x: 0 , y: 0, w: 6, h: 3},
                    {i: '7', x: 0 , y: 4, w: 6, h: 3},
                    {i: '1', x: 0 , y: 8, w: 6, h: 3},
                ],
                xs:[
                    {i: '3', x: 0 , y: 12, w: 4, h: 7},
                    {i: '4', x: 0 , y: 20, w: 4, h: 7},
                    {i: '5', x: 0 , y: 0, w: 4, h: 3},
                    {i: '7', x: 0 , y: 4, w: 4, h: 3},
                    {i: '1', x: 0 , y: 8, w: 4, h: 3},
                ],
                xxs:[
                    {i: '3', x: 0 , y: 12, w: 2, h: 7},
                    {i: '4', x: 0 , y: 20, w: 2, h: 7},
                    {i: '5', x: 0 , y: 0, w: 2, h: 3},
                    {i: '7', x: 0 , y: 4, w: 2, h: 3},
                    {i: '1', x: 0 , y: 8, w: 2, h: 3},
                ]
            },
            toggle:false,
            drag:false,
            count:0,
            monthNames:[IMLocalized('january'), IMLocalized('february'), IMLocalized('march'), IMLocalized('april'), IMLocalized('may'),IMLocalized('june'),
                IMLocalized('july'),IMLocalized('august'), IMLocalized('september'), IMLocalized('october'), IMLocalized('november'), IMLocalized('december')
            ]
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.dashboardlist !== this.props.dashboardlist){
            const{dashboardlist} = this.props;
            const is_admin = dashboardlist.is_admin;
            if(is_admin === false){
                const user = dashboardlist.user;
                const userclaimlist = user.claims;
                const userpayslip = user.payslip;
                const userleavelist = user.leave;
                const approvals = user.approvals;
                const claimapprovals = user.claimapprovals;
                const layout = dashboardlist.user_layout;
                if(layout !== null){
                    this.setState({layout})
                }
                this.setState({claimapprovals});
                this.setState({approvals});
                this.setState({userleavelist});
                this.setState({userpayslip});
                this.setState({userlist:user});
                this.setState({userclaimlist});
            }
        }
        if(prevProps.success !== this.props.success){
            this.props.get_general_dashboard()
            this.setState({drag:false});
            this.setState({toggle:false});
        }
        if(prevProps.clear_step !== this.props.clear_step){
            this.setState({drag:false});
        }
    }

    viewPayroll=(payroll)=>{
        const {count} = this.state;
        const total_count = count +1;
        this.props.toUserPayroll(payroll,count);
        this.setState({count:total_count});
    }

    claimTable=()=>{
        const today = new Date();
        const current_month = today.getMonth();
        const {userclaimlist} = this.state;
        const {claimlist} = this.props;
        let claim_table=<CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>;
        if(userclaimlist.length !== 0){
            
            const list = userclaimlist.map((item,index)=>{
                const claim_type = item.claim_type;
                const claim_detail = claimlist.filter(function(item){
                    return item.id === claim_type
                })
                let claim_name;
                if(claim_detail.length !== 0){
                    claim_name = claim_detail[0].name;
                }
            
                const is_monthly = item.is_monthly;
                let current_value;
                if(is_monthly === true){
                    const remaining_monthly = item.remaining_monthly;
                    current_value = remaining_monthly[current_month];
                }
                else{
                    const remaining = item.remaining;
                    current_value = remaining;
                }
                return(
                    <tr key={index}>
                        <td>{claim_name}</td>
                        <td>$ {current_value}</td>
                    </tr>
                )

            })
            claim_table=(
                <Table size="sm" className="align-items-center table-bordered" responsive>
                    <thead className="text-center thead-light">
                        <tr>
                            <th>{IMLocalized('name')}</th>
                            <th className="text-center">{IMLocalized('remaining')}</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {list}
                    </tbody>
                </Table>
            )
            
        }

        const table=(
            <Row>
                <Col>
                    <Card style={{height:'270px'}}>
                        <CardHeader className="border-0 p-2 bg-white">
                            <div className="pl-2 pt-2">
                                <h3 className=" title-color1">
                                    {IMLocalized('total_claims_for_the_month')}
                                </h3>
                            </div>
                        </CardHeader>
                        {claim_table}
                    </Card>
                </Col>
            </Row>
        )
        return table;
    }

    leaveTable=()=>{
        const today = new Date();
        const current_month = today.getMonth();
        const {userleavelist} = this.state;
        const {leavelist} = this.props;
        let leave_table=<CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>;
        if(userleavelist.length !== 0){
            const detail = userleavelist.map(item=>{
                
                let current_value;
                const leave_type = item.leave_type;
                const leave_setting = item.leave_setting;
                const leave_detail = leavelist.filter(function(item){
                    return item.id === leave_setting
                })
                let name = '';
                if(leave_detail.length !== 0){
                    name = leave_detail[0].name;
                }
                if(leave_type=== 'E'){
                    const remaining_monthly = item.remaining_monthly;
                    current_value = remaining_monthly[current_month]; 
                }
                else{
                    current_value = item.remaining;
                }
                return(
                    <tr>
                        <td>{name}</td>
                        <td className="text-center">{parseFloat(current_value).toFixed(2)}</td>
                    </tr>
                )
            })

            leave_table=(
                <Table size="sm" className="align-items-center" striped responsive>
                    <thead className="thead-light">
                        <tr>
                            <th>{IMLocalized('leave_type')}</th>
                            <th className="text-center">{IMLocalized('remaining')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {detail}
                    </tbody>
                </Table>
            )
        }  

        const table=(
            <Row>
                <Col>
                    <Card style={{height:'270px'}}>
                        <CardHeader className="border-0 p-2 bg-white">
                            <div className="pl-2 pt-2">
                                <h3 className="title-color1">
                                    {IMLocalized('leave_balance')}
                                </h3>
                            </div>
                        </CardHeader>
                        {leave_table}
                    </Card>
                </Col>
            </Row>
        )

        return table;
    }

    payslipTable=()=>{
        const {userpayslip} = this.state;
        let table = '';
        if(userpayslip.length !== 0){
            const payroll_date = userpayslip[0].payroll_date;
            const month1 = new Date(payroll_date).getMonth();
            const month = this.state.monthNames[month1];
            const year = new Date(payroll_date).getFullYear();

            table=(
                <div className="card-single bg-primary">
                    <div>
                        <h1 className="text-white">{month} {year}</h1>
                        <span className="text-white">{IMLocalized('this_month_payslip')}</span>
                    </div>
                    <div>
                        <i className="far fa-money-bill-alt text-white"  style={{fontSize:'24px'}}></i>
                    </div>
                </div>
            )
        }
        else{
            table=(
                <div className="card-single bg-primary">
                    <div>
                        <h1 className="text-white">{IMLocalized('no_data')}</h1>
                        <span className="text-white">{IMLocalized('this_month_payslip')}</span>
                    </div>
                    <div>
                        <i className="far fa-money-bill-alt text-white"  style={{fontSize:'24px'}}></i>
                    </div>
                </div>
            )
        }
        return table;
    }

    approvalTable=()=>{
        const {approvals} = this.state;
        const number = approvals.length;

        const table=(
            <div className="card-single cursor-pointer bg-success" >
                <div>
                    <h1 className="text-white">{number}</h1>
                    <span className="text-white">{IMLocalized('leave_pending_for_approval')}</span>
                </div>
                <div>
                    <i className="far fa-calendar-alt text-white" style={{fontSize:'24px'}}/>
                </div>
            </div>
        )
        return table;
    }

    onLayoutChange = (layout) => {
        this.setState({layout});
    }

    onBreakpointChange = (breakpoint) => {
        this.setState({
          currentBreakpoint: breakpoint
        });
    };

    setToggle=()=>{
        const toggle = !this.state.toggle;
        this.setState({toggle});
    }

    handleCheckbox=(e)=>{
        const target = e.target;
        const name = target.name;
        const checked = target.checked;
        this.setState({
            [name]:checked
        })
    }

    onMoveCard=(index)=>{
        const {currentBreakpoint} = this.state;
        let {layout} = this.state;
        layout[currentBreakpoint] = index;
        this.setState({layout});
    }

    updateLayout=()=>{
        const {layout} = this.state;
        this.props.update_user_layout(layout);
    }

    defaultLayout=()=>{
        const {defaultlayout} = this.state;
        this.props.update_user_layout(defaultlayout)
    }

    claimapprovalTable=()=>{
        const {claimapprovals} = this.state;
        const number = claimapprovals.length;

        const table=(
            <div className="card-single cursor-pointer bg-orange">
                <div>
                    <h1 className="text-white">{number}</h1>
                    <span className="text-white">{IMLocalized('claim_pending_for_approval')}</span>
                </div>
                <div>
                    <i className="fas fa-money-check text-white" style={{fontSize:'24px'}} />
                </div>
            </div>
        )
        return table;
    }

    render(){

        const {toggle} = this.state;
        let btn = '';
        if(toggle === true){
            btn = <button onClick={this.updateLayout} className="btn btn-primary btn-sm">{IMLocalized('save')}</button>
        }

        return(
            <div>
                {this.props.isLoading || this.props.isLoading1 || this.props.isLoading2 ? 
                <OverlayLoading/> :
                <div>
                    <Container fluid>
                        <Row className="border-bottom bg-white">
                            <Col className="p-2">
                                <BreadCrumbList list={[
                                    { active:true, title:IMLocalized('home')}
                                ]} />
                            </Col>
                            <Col className="col-auto d-flex align-items-center">
                                <div >
                                    <Dropdown size="sm" isOpen={this.state.toggle} direction="left" toggle={this.setToggle} >
                                        <DropdownToggle className="d-flex align-items-center justify-content-center setting_btn" aria-expanded={this.state.toggle}>
                                            <i className="ni ni-settings-gear-65"/>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <div className="custom-control custom-checkbox ml-2">
                                                <input type="checkbox" id="hr_draggable" className="custom-control-input" name="drag" checked={this.state.drag} onChange={this.handleCheckbox}  />
                                                <label className="custom-control-label" htmlFor='hr_draggable'>{IMLocalized('is_draggable')}</label>
                                            </div>
                                            <DropdownItem divider></DropdownItem>
                                            <div className="pl-2">
                                                {btn}
                                                <button className="btn btn-secondary btn-sm" onClick={this.defaultLayout}>{IMLocalized('default')}</button>
                                            </div>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ResponsiveGridLayout
                                    layouts={this.state.layout }
                                    measureBeforeMount={true}
                                    className="layout"
                                    rowHeight={30}
                                    isDraggable={this.state.drag}
                                    isResizable={false}
                                    onDrag={this.onDragging}
                                    onDragStop={this.onMoveCard}
                                    onBreakpointChange={this.onBreakpointChange}
                                    margin={[10, 10]}
                                >
                                    <div key="3">
                                        {this.claimTable.call(this)}
                                    </div>
                                    <div key="4">
                                        {this.leaveTable.call(this)}
                                    </div>
                                    <div key="5">
                                        {this.payslipTable.call(this)}
                                    </div>
                                    
                                </ResponsiveGridLayout>
                            </Col>
                        </Row>
                    </Container>
                </div>}
            </div>
        )
    }
}
function mapStateToProps(state,ownProps){
    return{
        dashboardlist:state.get_general_dashboard.data,
        claimlist:state.get_claim_list.data,
        leavelist:state.get_leave_list.data,
        success:state.update_user_layout.data,
        isLoading:state.get_general_dashboard.isLoading,
        isLoading1:state.get_claim_list.isLoading,
        isLoading2:state.get_leave_list.isLoading
    }
}

const mapDispatchToProps =(dispatch)=>{
    return{
        get_general_dashboard:()=>{
            dispatch(get_general_dashboard())
        },
        update_user_layout:(layout)=>{
            dispatch(update_user_layout(layout))
        },
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(claim_officer_dashboard);