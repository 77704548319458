import * as wizarvalidateflat from '../../action/wizard/wizard_validate_flat';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const wizard_validate_flat = (state=initialState,action)=>{
    switch(action.type){
        case wizarvalidateflat.WIZARDVALIDATEFLAT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case wizarvalidateflat.WIZARDVALIDATEFLAT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case wizarvalidateflat.WIZARDVALIDATEFLAT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default wizard_validate_flat;