import * as branch from '../action/company/get_branch_list';

const initialState ={
    data:[],
    error:{},
    isLoading:false
}


const get_branch_list = (state=initialState,action) =>{
    switch(action.type) {
        case branch.REQUEST_BRANCHLIST:
            return{
                ...state,
                isLoading: true,
                error:{}
            }
        case branch.GET_BRANCHLIST:
            return{
                ...state,
                data: action.payload.data,
                error:{},
                isLoading:false
        }
        case branch.FAILURE_BRANCHLIST:
            return{
                ...state,
                error:{},
                isLoading:false
        }
        default:
            return state;
    }
}
export default get_branch_list;