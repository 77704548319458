import { NavItem, NavLink } from "reactstrap";
import classnames from 'classnames';
import { IMLocalized } from "../../../language/IMLocalized";
import { useContext } from "react";
import { NewMainContext } from "../../../context/NewMainContext";
import { useDispatch } from "react-redux";
import { get_employee_list } from "../../../action/employee/get_employee_list";
import { get_general_dashboard } from "../../../action/dashboard/get_general_dashboard";



export default function TabList({id,name}){

    const dispatch = useDispatch();
    const {activeTab, setActiveTab, current_user, removeItem, setLeaveCalendarRefresh, setLeaveApprovalsCalendarRefresh } = useContext(NewMainContext);

    const toggle = (id)=>{
        setActiveTab(id);
        localStorage.setItem('activeTabs',id);
        if(id === '1'){
            if(current_user === 'manager' || current_user === 'user'){
                dispatch(get_general_dashboard());
            }
            else{
                dispatch(get_employee_list());
                dispatch(get_general_dashboard());
            }
        }
        if(current_user === 'hr' || current_user === 'manager' || current_user === 'leave_officer'){
            if(id === '24'){
                setLeaveCalendarRefresh(true);
            }
            else if(id === '46'){
                setLeaveApprovalsCalendarRefresh(true)
            }
        }
        else if( current_user === 'manager' || current_user === 'user'){
            
            if (id === '5'){
                setLeaveCalendarRefresh(true);
            }
            else if (id === '9'){
                setLeaveApprovalsCalendarRefresh(true);
            }
        }
        else if( current_user === 'leave_officer' || current_user === 'manager'){
            if(id === '4'){
                setLeaveCalendarRefresh(true);
            }
        }
    }

    

    return(
        <NavItem className="cursor-pointer" key={id}>
            <NavLink className={`d-flex justify-content-center align-items-center ${classnames({active:activeTab === id})}`} >
                <span onClick={()=>toggle(id)}> {IMLocalized(name)}</span> 
                <div className="tab_button ml-2">
                    <i className="ni ni-fat-remove text-danger" onClick={()=>removeItem(id)} />
                </div>
                {/* <span onClick={()=>this.toggle(id)}>{IMLocalized(name) }</span> <div className="tab_button ml-2" ><i className="ni ni-fat-remove text-danger" onClick={()=>this.removeItem(id)} /></div> */}
            </NavLink>
        </NavItem>
    )
}