import myaxios from '../../axios';

export const GETHREMPALLLEAVEDETAIL_REQUEST = 'GETHREMPALLLEAVEDETAIL_REQUEST';
export const GETHREMPALLLEAVEDETAIL_SUCCESS = 'GETHREMPALLLEAVEDETAIL_SUCCESS';
export const GETHREMPALLLEAVEDETAIL_FAILURE = 'GETHREMPALLLEAVEDETAIL_FAILURE';
export const GETHREMPALLLEAVEDETAIL_RESET = 'GETHREMPALLLEAVEDETAIL_RESET';

export const get_hr_employee_all_leave_detail = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETHREMPALLLEAVEDETAIL_REQUEST'
        });

        myaxios.get(`leave/employee/${id}/`)
        .then((response)=>{
            dispatch({
                type:'GETHREMPALLLEAVEDETAIL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETHREMPALLLEAVEDETAIL_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_hr_employee_all_leave_detail = () => dispatch =>{
    dispatch({
        type:'GETHREMPALLLEAVEDETAIL_RESET'
    })
}
