import * as branch from '../../action/company/get_branch_list';

const initialState ={
    data:[],
    errors:null,
    isLoading:false
}


const branch_list = (state=initialState,action) =>{
    switch(action.type) {
        case branch.REQUEST_BRANCHLIST:
            return{
                ...state,
                isLoading: true,
                errors: null,
                data:[],
            }
        case branch.GET_BRANCHLIST:
            return{
                ...state,
                data:action.payload?.data,
                isLoading:false
        }
        case branch.FAILURE_BRANCHLIST:
            return{
                ...state,
                errors:action.payload,
                isLoading:false
            }
        default:
            return state;
    }
}

export default branch_list;


export const get_branch_list_detail = (state,id)=>{
    const { data } = state.get_branch_list;

    return id != null ? data.filter(item=>item.company === id) : [];
}