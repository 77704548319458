import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEBRANCH_SUCCESS = 'UPDATEBRANCH_SUCCESS';
export const UPDATEBRANCH_REQUEST = 'UPDATEBRANCH_REQUEST';
export const UPDATEBRANCH_FAILURE = 'UPDATEBRANCH_FAILURE';
export const UPDATEBRANCH_RESET = 'UPDATEBRANCH_RESET';

export const update_branch = (id,name,branch_code,unit_number,sg_postal_code,address,description)=>{
    return dispatch =>{
        dispatch({
            type: 'UPDATEBRANCH_REQUEST'
        });
        
        myaxios.patch(`company/branch/${id}/`,
        {
            name:name,
            branch_code:branch_code,
            address:address,
            unit_number:unit_number,
            sg_postal_code:sg_postal_code,
            description:description
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEBRANCH_SUCCESS',
                payload:response
                
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            })
        })
        .catch((error) =>{
            dispatch({
                type: 'UPDATEBRANCH_FAILURE',
                payload: error,
                error:true
            });
            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_branch = () => dispatch=>{
    dispatch({
        type:'UPDATEBRANCH_RESET'
    });
}