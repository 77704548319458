import React, { useContext } from "react";
import { Card, Col, UncontrolledTooltip } from "reactstrap";
import { NewMainContext } from "../../../../context/NewMainContext";
import blank_picture from '../../../../images/blank_picture.png';
import { IMLocalized } from "../../../../language/IMLocalized";


export default function EmployeeListCard({ id, name, contract_cache, resignation_date, employee_number, pic, goProfile }){

    const { setEmployeeListStep, setEmpProfileId, current_user } = useContext(NewMainContext);


    const handleOnClick = () =>{
        if(current_user === 'hr'){
            setEmpProfileId(id)
            setEmployeeListStep('1');
            localStorage.setItem('employeelist_step','1');
            localStorage.setItem('emp_profile_id',id);
        }
        else{
            goProfile(id);
        }
        
    }

    let borderRight = '2px solid #f5365c';
    if(contract_cache != null){
        if(resignation_date != null){
            borderRight = '2px solid #ffc0cb';
        }
        else{
            borderRight = '2px solid #2dce89';
        }
    }

    return(
        <Col lg="4" md="6" sm="12" key={id}>
            <Card className="d-flex flex-row cursor-pointer" style={{ borderRight:borderRight }} onClick={handleOnClick}>
                <div className="p-0 w-30">
                    <img src={pic?.img ?? blank_picture} alt={pic?.img ? "user_picture" : "blank"} className="img-thumbnail" style={{width:'100px',height:'100px'}}  />
                </div>
                <div className="pl-3 pt-2 pr-2 pb-2 w-70 border-left">
                    <h5 className="text-primary mb-0">{IMLocalized('employee_id')} : {employee_number}</h5>
                    <h4>{name}</h4>
                    {contract_cache === null ? <small>{IMLocalized('no_contract')}</small>:
                    <div className="d-flex align-items-center">
                        <span className="pr-2 d-flex align-items-center">
                            <i className="fas fa-building text-info pr-1" id={`emplisttooltipdepartment${id}`} />
                            <UncontrolledTooltip delay={0} placement="right" target={`emplisttooltipdepartment${id}`} >
                                {IMLocalized('department')}
                            </UncontrolledTooltip>
                            <small>{contract_cache?.department ?? '-'}</small>
                        </span>
                        <span className="pr-2 d-flex align-items-center">
                            <i className="ni ni-briefcase-24 text-info pr-1" id={`emplisttooltipjobtitle${id}`} />
                            <UncontrolledTooltip delay={0} placement="right" target={`emplisttooltipjobtitle${id}`} >
                                {IMLocalized('job_title')}
                            </UncontrolledTooltip>
                            <small>{contract_cache?.job_title ?? '-'}</small>
                        </span>
                    </div>}
                </div>
            </Card>
        </Col>
    )
}