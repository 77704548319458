import * as updatepicture2 from '../../action/employee/update_employee_profile_picture2';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const update_employee_profile_picture2 = (state=initialState,action)=>{
    switch(action.type){
        case updatepicture2.UPDATE2EMPPROFILEPICTURE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case updatepicture2.UPDATE2EMPPROFILEPICTURE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updatepicture2.UPDATE2EMPPROFILEPICTURE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default update_employee_profile_picture2;