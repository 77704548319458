import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import { getDateFormat } from "../../../func/getDateFormat";
import { IMLocalized } from "../../../language/IMLocalized";
import { delete_employee_delegation_list } from "../../../action/employee/delete_employee_delegation_list";
import { get_employee_delegation_list } from "../../../action/employee/get_employee_delegation_list";
import { usePrevious } from "../../../hook/usePrevious";
import ModalCreateDelegation from "./modal/ModalCreateDelegation";
import ModalEditDelegation from "./modal/ModalEditDelegation";

const mapStateToProps=(state)=>({
    data:state.user_self.data,
    managerlist:state.get_user_manager_list.data,
    list:state.get_employee_delegation_list.data,
    isLoading:state.get_employee_delegation_list.isLoading,
    isLoading1:state.get_employee_delegation_list.isLoading,
    delete_success:state.delete_employee_delegation_list.data,
})

const mapDispatchToProps=(dispatch)=>({
    delete_employee_delegation_list:(id)=>{
        dispatch(delete_employee_delegation_list(id))
    },
    get_employee_delegation_list:()=>{
        dispatch(get_employee_delegation_list())
    }
})

function UserEmployeeDelegation(props){
    const {delete_success,get_employee_delegation_list} = props;
    const [add_toggle,setAddToggle] = useState(false);
    const [delegationlist,setDelegationList] = useState([]);
    const [delegatedlist,setDelegatedList] = useState([]);
    const [update_toggle,setUpdateToggle] = useState(false);
    const [update_detail,setUpdateDetail] = useState(null);

    const prevDelete = usePrevious(delete_success);

    useEffect(()=>{
        if(prevDelete !== undefined && prevDelete !== delete_success && delete_success){
            get_employee_delegation_list();
        }
    },[prevDelete,delete_success,get_employee_delegation_list])

    useEffect(()=>{
        if(props.list.length !== 0){
            const {delegation,delegated} = props.list;
            setDelegatedList(delegated);
            setDelegationList(delegation);
        }
    },[props.list])

    const deleteDelegation=(id)=>{
        props.delete_employee_delegation_list(id);
    }

    const updateDelegation=(id)=>{
        const data = delegationlist.find(item=>item.id === id);
        setUpdateDetail(data);
        setUpdateToggle(true);
    }

    return(
        <>
        <div className='d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title'>
            <div className='d-flex align-items-center'>
                <i className="far fa-address-book text-primary"></i>
                <h4 className="text-primary pl-2 m-0">{IMLocalized('delegation')}</h4>
            </div>
            <div className='text-primary pt-2 cursor-pointer pr-2' onClick={()=>setAddToggle(true)}>
                <i className='fas fa-plus-circle mr-1 function_icon' />
                <small>{IMLocalized('add_delegation')}</small>
            </div>
        </div>
        {props.isLoading || props.isLoading1 ? <div className='p-2'><h4>{IMLocalized('loading')}..</h4></div> : null}
        {!props.isLoading && !props.isLoading1 && delegationlist.length === 0 && <div className='p-2'><h4>{IMLocalized('no_data')}</h4></div>}
        {!props.isLoading && !props.isLoading1 && delegationlist.length !== 0 && 
        <Table size="sm" className="align-items-center table-bordered mt-2">
            <thead className="thead-light text-center">
                <tr>
                    <th width="40%">{IMLocalized('manager_name')} </th>
                    <th>{IMLocalized('date')}</th>
                    <th width="20%">{IMLocalized('edit')} / {IMLocalized('delete')}</th>
                </tr>
            </thead>
            <tbody className="text-center">
                {delegationlist.map((item,index)=>{
                    const emp_detail = props.managerlist.find(el=>el.id === item.delegation);
                    const emp_name = emp_detail?.name ?? '-';
                    const date = item.start_date === item.end_date  ? getDateFormat(item.start_date) : `${getDateFormat(item.start_date)} to ${getDateFormat(item.end_date)}`;
                    return(
                        <tr key={index}>
                            <td>{emp_name}</td>
                            <td>{date}</td>
                            <td align="center" valign="center">
                                <div className="d-flex justify-content-center">
                                    <div className="primary_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-pen text-primary cursor-pointer" onClick={()=>updateDelegation(item.id)}  />
                                    </div>
                                    <div className="delete_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-trash text-red cursor-pointer" onClick={()=>deleteDelegation(item.id)} />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>}
        <div className="d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title">
            <div className="d-flex align-items-center">
                <i className="far fa-address-book text-primary" />
                <h4 className="text-primary m-0 pl-2">{IMLocalized('delegated')}</h4>
            </div>
        </div>
        {props.isLoading || props.isLoading1 ? <div className='p-2'><h4>{IMLocalized('loading')}..</h4></div> : null}
        {!props.isLoading && !props.isLoading1 && delegatedlist.length === 0 && <div className='p-2'><h4>{IMLocalized('no_data')}</h4></div>}
        {!props.isLoading && !props.isLoading1 && delegatedlist.length !== 0 &&
        <Table className="align-items-center table-bordered mt-2" size="sm" responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th width="40%">{IMLocalized('manager_name')} </th>
                    <th>{IMLocalized('date')}</th>
                </tr>
            </thead>
            <tbody className="text-center">
                {delegatedlist.map((item,index)=>{
                    const emp_detail = props.managerlist.find(el=>el.id === item.delegation);
                    const emp_name = emp_detail?.name ?? '-';
                    const date = item.start_date === item.end_date ? getDateFormat(item.start_date) : `${getDateFormat(item.start_date)} to ${getDateFormat(item.end_date)}`;
                    return(
                        <tr key={index }>
                            <td>{emp_name}</td>
                            <td>{date}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>}
        <ModalCreateDelegation toggle={add_toggle} closeModal={()=>{setAddToggle(false)}} />
        <ModalEditDelegation toggle={update_toggle} closeModal={()=>{setUpdateToggle(false); setUpdateDetail(null)}} detail={update_detail} />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(UserEmployeeDelegation);