import myaxios from '../../axios';

export const PAYSLIPEMPPICTURE_REQUEST = 'PAYSLIPEMPPICTURE_REQUEST';
export const PAYSLIPEMPPICTURE_SUCCESS = 'PAYSLIPEMPPICTURE_SUCCESS';
export const PAYSLIPEMPPICTURE_FAILURE = 'PAYSLIPEMPPICTURE_FAILURE';

export const payslip_get_emp_picture =(id)=>{
    return dispatch=>{
        dispatch({
            type:'PAYSLIPEMPPICTURE_REQUEST'
        });

        myaxios.get(`ob/employee/${id}/thumbnail/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'PAYSLIPEMPPICTURE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'PAYSLIPEMPPICTURE_FAILURE',
                payload:error
            })
        })
    }
}