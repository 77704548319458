import myaxios from '../axios';


export const GETOTPCODEHR_REQUEST = 'GETOTPCODEHR_REQUEST';
export const GETOTPCODEHR_SUCCESS = 'GETOTPCODEHR_SUCCESS';
export const GETOTPCODEHR_FAILURE = 'GETOTPCODEHR_FAILURE';



export const get_otp_code_hr = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETOTPCODEHR_REQUEST'
        });

        myaxios.get('otp/code/hr/')
        .then((response)=>{
            dispatch({
                type:'GETOTPCODEHR_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETOTPCODEHR_FAILURE',
                payload:error
            })
        })
    }
}