import * as basesetting from '../action/get_base_setting';

const initialState={
    data:null,
    errors:null,
    isLoading:false,
}

const get_base_setting = (state=initialState,action)=>{
    switch(action.type){
        case basesetting.GETBASESETTING_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case basesetting.GETBASESETTING_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case basesetting.GETBASESETTING_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_base_setting;