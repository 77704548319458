import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const NEW_CREATEEMPADDRESS_REQUEST = 'NEW_CREATEEMPADDRESS_REQUEST';
export const NEW_CREATEEMPADDRESS_SUCCESS = 'NEW_CREATEEMPADDRESS_SUCCESS';
export const NEW_CREATEEMPADDRESS_FAILURE = 'NEW_CREATEEMPADDRESS_FAILURE';
export const NEW_CREATEEMPADDRESS_RESET = 'NEW_CREATEEMPADDRESS_RESET';


export const create_employee_address = (data)=>{
    return dispatch=>{
        dispatch({
            type:'NEW_CREATEEMPADDRESS_REQUEST'
        });
        if(data.length !== 0 ){
            for(let i =0;i<data.length;i++){
                const { address, block_number, country_of_residence, level_number, sg_postal_code, street_name, unit_number, employee, postal_code}  = data[i];
                myaxios.post('employee/address/',{
                    address:address,
                    block_number:block_number,
                    country_of_residence:country_of_residence,
                    level_number:level_number,
                    sg_postal_code:sg_postal_code,
                    street_name:street_name,
                    unit_number:unit_number,
                    employee:employee,
                    postal_code:postal_code
                })
                .then((response)=>{
                    dispatch({
                    type:'NEW_CREATEEMPADDRESS_SUCCESS',
                    payload:response
                    });
                
                    Toast.fire({
                        type: 'success',
                        title: IMLocalized('create_success')
                    });
                })
                .catch((error)=>{
                    dispatch({
                        type:'NEW_CREATEEMPADDRESS_FAILURE',
                        payload:error
                    });
                    
                    ErrToast.fire({
                        type:'error',
                        title:IMLocalized('create_failure')
                    });
                })
            }
        }
    }
}

export const reset_create_employee_address = () => dispatch =>{
    dispatch({
        type:'NEW_CREATEEMPADDRESS_RESET'
    });
}