import * as assign from '../../action/approvals/assign_claim_approval';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const assign_claim_approval = (state=initialState,action)=>{
    switch(action.type){
        case assign.ASSIGNCLAIMAPPROVAL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case assign.ASSIGNCLAIMAPPROVAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case assign.ASSIGNCLAIMAPPROVAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case assign.ASSIGNCLAIMAPPROVAL_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default assign_claim_approval;