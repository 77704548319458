import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup,Input , Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { reset_update_resign, update_resign } from '../../../../action/employee/update_resign';
import { get_employee_list } from '../../../../action/employee/get_employee_list';
import { get_employee_detail } from '../../../../action/employee/get_employee_detail';

export default function ModalUpdateResign({ closeModal, toggle }){

    const dispatch = useDispatch();
    const isLoading = useSelector(state=>state.update_resign.isLoading);
    const success = useSelector(state=>state.update_resign.data);
    const emp_detail = useSelector(state=>state.get_employee_detail.data);

    const [can_edit,setEdit] = useState(false);
    const [resign_date,setResignDate] = useState('');
    const [err_resign_date,setErrResignDate] = useState('');
    const [severance_month,setMonth] = useState(0);
    const [err_severance_month,setErrMonth] = useState('');
    const [severance_amount,setAmount] = useState(0);
    const [err_severance_amount,setErrAmount] = useState('');
    const [notes,setNotes] = useState('');
    const [err_notes,setErrNotes] = useState('');
    const [blacklist,setBlackList] = useState(false);
    const [basic_pay,setPay] = useState(0);
    const [id,setID] = useState(null);

    useEffect(()=>{
        if(emp_detail !== null){
            setID(emp_detail.id);
            const {hr_cache} = emp_detail;
            if(hr_cache !==null ){
                setPay(parseFloat(hr_cache.pay))
            }
            else{
                setPay(0);
            }
            
        }
    },[emp_detail])

    useEffect(()=>{
        if(toggle && emp_detail != null){
            const {resign} = emp_detail;
            if(resign !== null){
                setEdit(resign.can_edit);
                setResignDate(resign.resignation_date);
                setMonth(resign.severance_month);
                setAmount(resign.severance_amount);
                setBlackList(resign.blacklist);
                setNotes(resign.notes);
                setErrAmount('');
                setErrMonth('');
                setErrNotes('');
                setErrResignDate('');
            }
        }
    },[toggle,emp_detail])

    useEffect(()=>{
        if( success !== null && toggle){
            closeModal();
            dispatch(get_employee_list());
            dispatch(get_employee_detail(id));
            dispatch(reset_update_resign());
        }
    },[success,toggle,id,closeModal,dispatch])

    const handleAmount = ()=>{
        if(severance_month !== ''){
            const amount = severance_month * basic_pay;
            setAmount(amount);
            setErrAmount('');
        }
        else{
            setAmount('');
        }
    }

    const updateResign=()=>{
        if(resign_date !== '' && severance_amount !== '' && severance_month !== '' && notes !== '' ){
            dispatch(update_resign(id,resign_date,severance_month,severance_amount,notes,blacklist));
        }
        else{
            if(resign_date === ''){
                setErrResignDate(IMLocalized('resign_date_is_required'));
            }
            if(notes === ''){
                setErrNotes(IMLocalized('note_is_required'));
            }
            if(severance_amount === ''){
                setErrAmount(IMLocalized('severance_amount_is_required'));
            }
            if(severance_month === ''){
                setErrMonth(IMLocalized('severance_month_is_required'));
            }
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('update_resign_detail')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='resign_date'>{IMLocalized('resign_date')}</label>
                            <Input size="sm" type="date" id="resign_date" value={resign_date} onChange={(e)=>{setResignDate(e.target.value); setErrResignDate('')}} invalid={err_resign_date.length >0} readOnly={!can_edit} />
                            <FormFeedback>{err_resign_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='severance_month'>{IMLocalized('severance_month')}</label>
                            <Input size="sm" type="number" min="0" step="1" id="severance_month" value={severance_month} onChange={(e)=>{setMonth(parseInt(e.target.value)); setErrMonth('')}} invalid={err_severance_month.length >0} readOnly={!can_edit} onBlur={()=>handleAmount()} />
                            <FormFeedback>{err_severance_month}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='severance_amount'>{IMLocalized('severance_amount')}</label>
                            <Input size="sm" type="number" min="0" step="1" id="severance_amount" value={severance_amount} onChange={(e)=>{setAmount(e.target.value); setErrAmount('')}} invalid={err_severance_amount.length >0} readOnly={!can_edit} />
                            <FormFeedback>{err_severance_amount}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='notes'>{IMLocalized('note')}</label>
                            <Input size="sm" type="textarea" id="notes" value={notes} onChange={(e)=>{setNotes(e.target.value); setErrNotes('')}} invalid={err_notes.length >0} readOnly={!can_edit} />
                            <FormFeedback>{err_notes}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="12">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id='modal_edit_resign_blacklist' className="custom-control-input" name="blacklist" checked={blacklist} onChange={(e)=>setBlackList(e.target.checked)} disabled={!can_edit}  />
                            <label className="custom-control-label" htmlFor='modal_edit_resign_blacklist'>{IMLocalized('blacklist')}</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading && <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>}
                {!isLoading && can_edit && <button onClick={()=>updateResign()} className="btn btn-primary btn-sm">{IMLocalized('update')}</button>}
                {!isLoading && <button onClick={closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>}
            </ModalFooter>
        </Modal>
    )
}