import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {Table} from 'reactstrap';
import {get_employee_contract_status} from '../../../action/employee/get_employee_contract_status';
import {get_additional_setting} from '../../../action/additional_setting';
import { get_employee_list } from '../../../action/employee/get_employee_list';
import {get_employee_list_withoutcontract} from '../../../action/employee/get_employee_list_withoutcontract';
import {IMLocalized} from '../../../language/IMLocalized';
import ModalViewContract from './modal/ModalViewContract';
import ModalCreateContract from './modal/ModalCreateContract';
import ModalEditContract from './modal/ModalEditContract';
import ModalSaveContract from './modal/ModalSaveContract';
import { getDateFormat } from '../../../func/getDateFormat';
import { reset_update_employee_contract } from '../../../action/employee/update_employee_contract';

const mapStateToProps = (state)=>({
    list:state.get_employee_contract_status.data,
    emplist:state.get_employee_list.data,
    update_contract_success:state.update_employee_contract.data,
})

const mapDispatchToProps =(dispatch)=>({
    get_employee_contract_status:(id)=>{
        dispatch(get_employee_contract_status(id))
    },
    get_additional_setting:()=>{
        dispatch(get_additional_setting())
    },
    get_employee_list:()=>{
        dispatch(get_employee_list())
    },
    get_employee_list_withoutcontract:()=>{
        dispatch(get_employee_list_withoutcontract())
    },
    reset_update_employee_contract:()=>{
        dispatch(reset_update_employee_contract())
    }
})

function EmployeeContract(props){
    const {get_employee_contract_status,update_contract_success,get_employee_list,get_additional_setting,get_employee_list_withoutcontract, reset_update_employee_contract} =props;
    const [view_detail,setViewDetail] = useState(null);
    const [view_toggle,setViewToggle] = useState(false);
    const [save_toggle,setSaveToggle] = useState(false);
    const [edit_toggle,setEditToggle] = useState(false);
    const [is_first,setIsFirst] = useState(false);
    const [edit_detail,setEditDetail] = useState(null);
    const [emp_join_date,setEmpJoinDate] = useState('');
    const [create_toggle,setCreateToggle] = useState(false);
    const [is_new,setIsNew] = useState(false);
    const saveRef = useRef();

    useEffect(()=>{
        if(props.id !== ''){
            get_employee_contract_status(props.id);
        }
        
    },[props.id,get_employee_contract_status])

    useEffect(()=>{
        if(props.id !== null && props.id !== '' && props.emplist.length !== 0){
            const detail = props.emplist.find(item=>item.id === parseInt(props.id))
            if(detail){
                setEmpJoinDate(detail.join_date);
            }
        }
    },[props.id,props.emplist])

    useEffect(()=>{
        if(update_contract_success !== null){
            setEditToggle(false);
            setEditDetail(null);
            get_additional_setting()
            get_employee_contract_status(props.id);
            get_employee_list();
            reset_update_employee_contract();
        }
    },[update_contract_success,get_additional_setting,get_employee_contract_status,get_employee_list,props.id,reset_update_employee_contract])

    useEffect(()=>{ 
        if(props.list.length === 0){
            setIsNew(true);
        }
        else{
            setIsNew(false);
        }
    },[props.list,props.id])

    const saveAsTemplate=()=>{
        const toggle = !save_toggle;
        saveRef.current.reset();
        setSaveToggle(toggle);
    }

    const successCreate=()=>{
        setCreateToggle(false);
        get_additional_setting();
        get_employee_list_withoutcontract();
        get_employee_contract_status(props.id);
        get_employee_list()
    }

    const toView = (name, index=null)=>{
        const toggle  = !view_toggle;
        let detail = null;
        if(toggle === true){
            if(name === 'active'){
                detail = props.list.active;
            }
            else if(name === 'upcoming'){
                detail =  props.list.upcoming;
            }
            else if(name ==='history'){
                detail = props.list.history[index];
            }
            setViewDetail(detail);
        }
        else{
            setViewDetail(null);
        }
        setViewToggle(toggle);
    }
    
    const editContract=(name,index = null)=>{
        const toggle = !edit_toggle;
        if(toggle === true){
            let detail = null;
            if(name === 'active'){
                detail = props.list.active;
                const history = props.list.history;
                if(history !== null && history !== undefined){
                    if(history.length === 0){
                        setIsFirst(true);
                    }
                    else{
                        setIsFirst(false);
                    }
                }
                else{
                    setIsFirst(true);
                }
            }
            else if(name === 'upcoming'){
                detail = props.list.upcoming;
            }
            else if(name === 'history'){
                detail = props.list.history[index];
            }

            setEditDetail(detail);
        }
        else{
            setEditDetail(null);
        }
        setEditToggle(toggle);

    }

    return(
        <>
            <div className='d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title'>
                <div className='d-flex align-items-center'>
                    <i className='fas fa-money-check-alt text-primary' />
                    <h4 className='text-primary m-0 pl-2'>{IMLocalized('employee_contract')}</h4>
                </div>
                {props.list.length !== 0 && props.list.upcoming === undefined &&
                <div className="text-primary pt-2 cursor-pointer pr-2" onClick={()=>setCreateToggle(true)} >
                    <i className="fas fa-plus-circle mr-1 function_icon "/>
                    <small>{IMLocalized('add_new_contract')}</small>
                </div>}
                {props.list.length === 0 &&
                <div className='text-primary pt-2 cursor-pointer pr-2' onClick={()=>setCreateToggle(true)}>
                    <i className='fas fa-plus-circle mr-1 function_icon'   />
                    <small>{IMLocalized('add_new_contract')}</small>
                </div>}
            </div>
            {props.list.length === 0 &&
            <div className='p-2'>
                <h4>{IMLocalized('no_data')}</h4>
            </div>}
            {props.list.length !== 0 && props.list.active !== undefined &&
            <div className='p-2'>
                <h4>{IMLocalized('current_contract')}</h4>
                <Table className="table-bordered align-items-center" size="sm" responsive>
                    <thead className='text-center thead-light' >
                        <tr>
                            <th className='w-25'>{IMLocalized('start_date')}</th>
                            <th className='w-25'>{IMLocalized('job_level')}</th>
                            <th className='w-25'>{IMLocalized('job_title')}</th>
                            <th className='w-25'>{IMLocalized('view')} / {IMLocalized('edit')}</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        <tr>
                            <td>{getDateFormat(props.list.active.start_date)}</td>
                            <td>{props.list.active.job_level !== null ? props.list.active.job_level : '---'}</td>
                            <td>{props.list.active.job_title !== null ? props.list.active.job_title : '---'}</td>
                            <td align='center' valign='center'> 
                                <div className='d-flex justify-content-center'>
                                    <div className='primary_btn d-flex align-items-center justify-content-center'>
                                        <i className='far fa-eye text-primary cursor-pointer' onClick={()=>toView('active')} />
                                    </div>
                                    {props.list.active.editable ? <div className="primary_btn d-flex align-items-center justify-content-center"><i className="fas fa-pen text-primary cursor-pointer" onClick={()=>editContract('active')} /></div> : <div className='default_btn'><span>-</span></div>}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>}
            {props.list.length !== 0 && props.list.upcoming !== undefined &&
            <div className='p-2'>
                <h4>{IMLocalized('upcoming_contract')}</h4>
                <Table className="table-bordered align-items-center" size="sm" responsive>
                    <thead className='text-center thead-light'>
                        <tr>
                            <th className='w-25'>{IMLocalized('start_date')}</th>
                            <th className='w-25'>{IMLocalized('job_level')}</th>
                            <th className='w-25'>{IMLocalized('job_title')}</th>
                            <th>{IMLocalized('view')} / {IMLocalized('edit')}</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        <tr>
                            <td>{getDateFormat(props.list.upcoming.start_date)}</td>
                            <td>{props.list.upcoming.job_level !== null ? props.list.upcoming.job_level : '---'}</td>
                            <td>{props.list.upcoming.job_title !== null ? props.list.upcoming.job_title : '---'}</td>
                            <td align='center' valign='center'>
                                <div className='d-flex justify-content-center'>
                                    <div className="primary_btn d-flex align-items-center justify-content-center">
                                        <i className="far fa-eye text-primary cursor-pointer" onClick={()=>toView('upcoming')} />
                                    </div>
                                    {props.list.upcoming.editable ? <div className="primary_btn d-flex align-items-center justify-content-center"><i className="fas fa-pen text-primary cursor-pointer" onClick={()=>editContract('upcoming')} /></div> : <div className='default_btn'><span>-</span></div>}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>}
            {props.list.history !== undefined && props.list.history.length !== 0 &&
            <div className='p-2'>
                <h4>{IMLocalized('previous_contract')}</h4>
                <Table className="table-bordered align-items-center" size="sm" responsive>
                    <thead className="text-center thead-light">
                        <tr>
                            <th className="w-25">{IMLocalized('start_date')}</th>
                            <th className="w-25">{IMLocalized('job_level')}</th>
                            <th className="w-25">{IMLocalized('job_title')}</th>
                            <th>{IMLocalized('view')} / {IMLocalized('edit')}</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {props.list.history.map((item,index)=>{
                            return(
                                <tr key={index}>
                                    <td>{getDateFormat(item.start_date)}</td>
                                    <td>{item.job_level !== null ? item.job_level : '---'}</td>
                                    <td>{item.job_title !== null ? item.job_title : '---'}</td>
                                    <td align='center' valign='center'>
                                        <div className='d-flex justify-content-center'>
                                            <div className="primary_btn d-flex align-items-center justify-content-center">
                                                <i className="far fa-eye text-primary cursor-pointer" onClick={()=>toView('history',index)}/>
                                            </div>
                                            {item.editable ? <div className="primary_btn d-flex align-items-center justify-content-center"><i className="fas fa-pen text-primary cursor-pointer" onClick={()=>editContract('history',index)} /></div> : <div className='default_btn'><span>-</span></div>}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>} 
            {create_toggle && <ModalCreateContract path="contract" toggle={create_toggle} list={props.list} is_new={is_new} closeModal={()=>setCreateToggle(false)} id={props.id} join_date={emp_join_date} onSuccess={()=>successCreate()} />}
            {view_detail && <ModalViewContract toggle={view_toggle} detail={view_detail} closeModal={()=>setViewToggle(false)} id={props.id} saveAsTemplate={()=>{saveAsTemplate()}} /> } 
            {edit_toggle && <ModalEditContract detail={edit_detail} toggle={edit_toggle} is_first={is_first} closeModal={()=>editContract()} id={props.id} />}
            <ModalSaveContract detail={view_detail} toggle={save_toggle} closeModal={()=>{saveAsTemplate()}} ref={saveRef} />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(EmployeeContract)