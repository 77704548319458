import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import {get_branch_list} from '../../action/company/get_branch_list';
import {delete_branch, reset_delete_branch} from '../../action/company/delete_branch';
import {get_company_list} from '../../action/company_list';
import { get_company_bank } from '../../action/company/get_company_bank';
import { delete_company_bank, reset_delete_company_bank } from '../../action/company/delete_company_bank';
import { reset_company_bank_default, set_company_bank_default } from '../../action/company/set_company_bank_default';
import {IMLocalized} from '../../language/IMLocalized';
import { Container, Row, Col,Card,CardBody,CardHeader, ListGroup,ListGroupItem } from 'reactstrap';
import ModalEditCompany from './modal/ModalEditCompany';
import ModalViewBranch from './modal/ModalViewBranch';
import ModalCreateBranch from './modal/ModalCreateBranch';
import ModalViewCompanyBank from './modal/ModalViewCompanyBank';
import ModalEditBranch from './modal/ModalEditBranch';
import ModalCreateBank from './modal/ModalCreateBank';
import { NewMainContext } from '../../context/NewMainContext';
import { company_list_detail } from '../../reducers/company_list';
import { get_company_bank_detail } from '../../reducers/company/get_company_bank';
import { get_branch_list_detail } from '../../reducers/company/get_branch_list';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

function CompanyDetail({id,onBack}){

    const { addItem } = useContext(NewMainContext);
    const dispatch = useDispatch();
    
    const company_data = useSelector(state=>company_list_detail(state,parseInt(id)));
    const bank_data = useSelector(state=>get_company_bank_detail(state,parseInt(id)));
    const branch_data = useSelector(state=>get_branch_list_detail(state,parseInt(id)));

    const banks = useSelector(state=>state.company_dropdown.bank);
    const default_success = useSelector(state=>state.set_company_bank_default.data);
    const delete_bank_success = useSelector(state=>state.delete_company_bank.data);
    const delete_success = useSelector(state=>state.delete_branch.data);

    const [update_company_toggle,setUpdateComToggle] = useState(false);
    const [bank_toggle,setBankToggle] = useState(false);
    const [view_bank_data,setViewBankData] = useState(null);
    const [view_bank_toggle,setViewBankToggle] = useState(false);
    const [create_branch_toggle,setCreateBranchToggle] = useState(false);
    const [branch_detail,setBranchDetail] = useState(null);
    const [branch_toggle,setBranchToggle] = useState(false);
    const [edit_branch_toggle,setEditBranchToggle] = useState(false);
    const [edit_branch_detail,setEditBranchDetail] = useState(null);


    useEffect(()=>{
        if( default_success !== null ){
            dispatch(reset_company_bank_default());
            dispatch(get_company_bank());
        }
    },[default_success,dispatch])

    useEffect(()=>{
        if(delete_bank_success !== null){
            dispatch(get_company_bank());
            dispatch(reset_delete_company_bank());
        }
    },[delete_bank_success,dispatch])

    useEffect(()=>{
        if(delete_success !== null){
            dispatch(reset_delete_branch());
            dispatch(get_branch_list());
        }
    },[delete_success,dispatch])

    const viewBankDetail = (item)=>{
        const data = bank_data.find((item1)=>item1.id === item);
        if(data){
            const detail = banks.find(item1=>item1.value === data.bank);
            setViewBankToggle(true);
            setViewBankData({bank:detail.display,account_number:data.account_number});
        }
    }

    const handleDeleteBank=(id,name)=>{
        Swal.fire({
            type:'warning',
            title:`${IMLocalized('deleting_bank')} - ${name} `,
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
            showCancelButton:true,
        })
        .then((result)=>{
            if('value' in result){
                dispatch(delete_company_bank(id));
            }
        })
    }

    const viewBranch=(id)=>{
        const data = branch_data.find((item)=>item.id === id);
        if(data){
            setBranchDetail(data);
            setBranchToggle(true);
        }
    }

    const editBranch=(id)=>{
        const toggle = !edit_branch_toggle;
        if(toggle === true){
            const data = branch_data.find(item=>item.id === id);
            setEditBranchDetail(data);
        }
        else{
            setEditBranchDetail(null);
        }
        setEditBranchToggle(toggle);
    }

    const removeBranch=(id,name)=>{
        
        Swal.fire({
            type:'warning',
            title:`${IMLocalized('deleting_this_branch')} - ${name} `,
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
            showCancelButton:true,
        })
        .then((result)=>{
            if('value' in result){
                dispatch(delete_branch(id));
            }
        })
    }

    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active:false, title :IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active:false, title :IMLocalized('company'), onClick:()=>onBack('0')},
                        { active:true, title :company_data?.name ?? '' }]
                    }/>
                    <div className='ml-2'>
                        <button onClick={()=>onBack('0')} className="btn btn-primary btn-sm"> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2 justify-content-center">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('company_detail')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-success btn-sm" onClick={()=>setUpdateComToggle(!update_company_toggle)}>{IMLocalized('edit')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        {company_data === null ? <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody> :
                        <ListGroup className="list" flush>
                            <ListGroupItem className="py-2">
                                <Row>
                                    <Col md="4" className="d-flex align-items-center">
                                        <h5 className="mb-0">{IMLocalized('name')}</h5>
                                    </Col>
                                    <Col>
                                        {company_data?.name}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem className="py-2">
                                <Row>
                                    <Col md="4" className="d-flex align-items-center">
                                        <h5 className="mb-0">{IMLocalized('contact_number')}</h5>
                                    </Col>
                                    <Col>
                                        {company_data.contact_number}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem className="py-2">
                                <Row>
                                    <Col md="4" className="d-flex align-items-center">
                                        <h5 className="mb-0">UEN</h5>
                                    </Col>
                                    <Col>
                                        {company_data.UEN}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem className="py-2">
                                <Row>
                                    <Col md="4" className="d-flex align-items-center">
                                        <h5 className="mb-0">CPF Payment Type</h5>
                                    </Col>
                                    <Col>
                                        {company_data.CPF_payment_type}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem className="py-2">
                                <Row>
                                    <Col md="4" className="d-flex align-items-center">
                                        <h5 className="mb-0">CPF Serial Number</h5>
                                    </Col>
                                    <Col>
                                        {company_data.CPF_serial_number}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem className="py-2">
                                <Row>
                                    <Col md="4" className="d-flex align-items-center">
                                        <h5 className="mb-0">{IMLocalized('sg_postal_code')}</h5>
                                    </Col>
                                    <Col>
                                        {company_data.sg_postal_code}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem className="py-2">
                                <Row>
                                    <Col md="4" className="d-flex align-items-center">
                                        <h5 className="mb-0">{IMLocalized('address')}</h5>
                                    </Col>
                                    <Col>
                                        {company_data.address}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem className="py-2">
                                <Row>
                                    <Col md="4" className="d-flex align-items-center">
                                        <h5 className="mb-0">{IMLocalized('unit_number')}</h5>
                                    </Col>
                                    <Col>
                                        {company_data.unit_number}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('bank')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm" onClick={()=>setBankToggle(true)}>{IMLocalized('create')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        {bank_data.length === 0 ? <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>:
                        <ListGroup className="list scrollbarstyle1" flush>
                            {bank_data.map((item,index)=>{
                                const number = index +1;
                                const bank = item.bank;
                                const object = banks.find((item)=>item.value === bank);
                                return(
                                    <ListGroupItem className="py-2" key={'bank'+index}>
                                        <Row>
                                            <Col >
                                                <div className="d-flex align-items-center" onClick={()=>viewBankDetail(item.id)}>
                                                    {number}. <a href="/#" className='ml-2'> {object.display}</a>{item.default !== null && <small className='ml-2 d-none d-md-block' style={{fontWeight:'bold'}}>( {IMLocalized('default')} )</small>}
                                                </div>
                                            </Col>
                                            <Col className="col-auto mr-1 align-items-center">
                                                {item.default === null &&  <button onClick={()=>dispatch(set_company_bank_default(item.id))} className='btn btn-success btn-sm'>{IMLocalized('set_default')}</button>}
                                                <button className="btn btn-danger btn-sm" onClick={()=>handleDeleteBank(item.id,object.display)} >{IMLocalized('delete')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>
                        }
                    </Card>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('branch')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm" onClick={()=>setCreateBranchToggle(true)}>{IMLocalized('create')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        {branch_data.length === 0 ? <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>:
                        <ListGroup className="list scrollbarstyle1" flush>
                            {branch_data.map((item,index)=>{
                                const number = index +1;
                                return(
                                    <ListGroupItem className="py-2" key={'branch'+index}>
                                        <Row>
                                            <Col className="d-flex align-items-center">
                                                {number}.  <a href="/#" onClick={()=>viewBranch(item.id)} className="ml-2">  {item.name}</a>
                                            </Col>
                                            <Col className="col-auto mr-1">
                                                <button className="btn btn-success btn-sm" onClick={()=>editBranch(item.id)}>{IMLocalized('edit')}</button>
                                                <button className="btn btn-danger btn-sm" onClick={()=>removeBranch(item.id,item.name)}>{IMLocalized('delete')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
        </Container>
        <ModalEditCompany toggle={update_company_toggle} companylist={company_data} closeModal={()=>setUpdateComToggle(false)}  
        onSuccess={()=>{
            setUpdateComToggle(false);
            dispatch(get_company_list());
        }} />
        <ModalViewBranch toggle={branch_toggle} detail={branch_detail} closeModal={()=>setBranchToggle(false)} />
        <ModalEditBranch toggle={edit_branch_toggle} detail={edit_branch_detail} closeModal={()=>setEditBranchToggle(false)} onSuccess={()=>{setEditBranchToggle(false); dispatch(get_branch_list())}}  />
        <ModalViewCompanyBank toggle={view_bank_toggle} data={view_bank_data} closeModal={()=>setViewBankToggle(false)} />
        <ModalCreateBranch toggle={create_branch_toggle}  closeModal={()=>setCreateBranchToggle(false)} id={id} onSuccess={()=>{setCreateBranchToggle(false); dispatch(get_branch_list())}} />
        <ModalCreateBank toggle={bank_toggle} id={id} closeModal={()=>setBankToggle(false)} onSuccess={()=>{setBankToggle(false); dispatch(get_company_bank())}} />
        </>
    )
}
export default CompanyDetail;