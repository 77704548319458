import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const WIZARDVALIDATEJOBLEVEL_REQUEST ='WIZARDVALIDATEJOBLEVEL_REQUEST';
export const WIZARDVALIDATEJOBLEVEL_SUCCESS ='WIZARDVALIDATEJOBLEVEL_SUCCESS';
export const WIZARDVALIDATEJOBLEVEL_FAILURE ='WIZARDVALIDATEJOBLEVEL_FAILURE';

export const wizard_validate_joblevel = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDVALIDATEJOBLEVEL_REQUEST'
        });

        myaxios.post('company/joblevel/array_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDVALIDATEJOBLEVEL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDVALIDATEJOBLEVEL_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}