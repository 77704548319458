import * as user_available_leave from '../../action/user/user_get_available_leave';

const initialState={
    isLoading:false,
    errors:[],
    data:[]
}

const user_get_available_leave = (state=initialState,action)=>{
    switch(action.type){
        case user_available_leave.USERAVAILABLELEAVE_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case user_available_leave.USERAVAILABLELEAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case user_available_leave.USERAVAILABLELEAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default user_get_available_leave;