import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { wizard_create_allowance } from '../../action/wizard/wizard_create_allowance';
import { wizard_create_company } from '../../action/wizard/wizard_create_company';
import { wizard_create_costcenter } from '../../action/wizard/wizard_create_costcenter';
import { wizard_create_department } from '../../action/wizard/wizard_create_department';
import { wizard_create_flat } from '../../action/wizard/wizard_create_flat';
import { wizard_create_holiday_list } from '../../action/wizard/wizard_create_holiday_list';
import { wizard_create_joblevel } from '../../action/wizard/wizard_create_joblevel';
import { wizard_create_jobtitle } from '../../action/wizard/wizard_create_jobtitle';
import { wizard_create_overtime } from '../../action/wizard/wizard_create_overtime';
import { wizard_create_recurring } from '../../action/wizard/wizard_create_recurring';
import { wizard_create_schedule } from '../../action/wizard/wizard_create_schedule';
import { wizard_create_section } from '../../action/wizard/wizard_create_section';
import { wizard_update_pay_period } from '../../action/wizard/wizard_update_pay_period';
import { IMLocalized } from '../../language/IMLocalized';
import AdditionalTable from './component/AdditionalTable';
import ModalAddAdditional from './modal/ModalAddAdditional';
import ModalPayroll from './modal/ModalPayroll';




export default function AdditionalDetail({company_data,period_data,overtime_data,allowance_data,recurring_data,fixed_data,holiday_data,schedule_data,removeUnload}){

    const dispatch = useDispatch();
    const complete_success = useSelector(state=>state.wizard_complete.data);
    const department_success = useSelector(state=>state.wizard_create_department.data);
    const section_success = useSelector(state=>state.wizard_create_section.data);
    const jobtitle_success = useSelector(state=>state.wizard_create_jobtitle.data);
    const joblevel_success = useSelector(state=>state.wizard_create_joblevel.data);
    const costcenter_success = useSelector(state=>state.wizard_create_costcenter.data);
    const company_success = useSelector(state=>state.wizard_create_company.data);
    const period_success = useSelector(state=>state.wizard_update_pay_period.data);
    const overtime_success = useSelector(state=>state.wizard_create_overtime.data);
    const allowance_success = useSelector(state=>state.wizard_create_allowance.data);
    const recurring_success = useSelector(state=>state.wizard_create_recurring.data);
    const fixed_success = useSelector(state=>state.wizard_create_flat.data);
    const holiday_success = useSelector(state=>state.wizard_create_holiday_list.data);
    const schedule_success = useSelector(state=>state.wizard_create_schedule.data);

    const [ departmentlist,setDepartmentList] = useState([]);
    const [ sectionlist,setSectionList] = useState([]);
    const [ jobtitlelist,setJobTitleList] = useState([]);
    const [ joblevellist,setJobLevelList] = useState([]);
    const [ costcenterlist,setCostCenterList] = useState([]);
    const [ add_toggle, setAddToggle] = useState(false);
    const [ add_type, setAddType] = useState(null);
    const [ payroll_toggle, setPayrollToggle] = useState(false);
    const [ is_pending, setIsPending] = useState(false);
    const [ pending_company, setPendingCompany] = useState(false);
    const [ pending_overtime, setPendingOvertime] = useState(false);
    const [ pending_allowance, setPendingAllowance] = useState(false);
    const [ pending_recurring, setPendingRecurring] = useState(false);
    const [ pending_department, setPendingDepartment] = useState(false);
    const [ pending_section, setPendingSection] = useState(false);
    const [ pending_jobtitle, setPendingJobTitle] = useState(false);
    const [ pending_joblevel, setPendingJobLevel] = useState(false);
    const [ pending_costcenter, setPendingCostCenter] = useState(false);
    const [ pending_fixed, setPendingFixed] = useState(false);
    const [ pending_holiday, setPendingHoliday] = useState(false);
    const [ pending_schedule, setPendingSchedule] = useState(false);
    const [ pending_period, setPendingPeriod] = useState(false);

    useEffect(()=>{
        if(company_success !== null){
            setPendingCompany(false);
        }
    },[company_success])

    useEffect(()=>{
        if(period_success !== null){
            setPendingPeriod(false);
        }
    },[period_success])

    useEffect(()=>{
        if(overtime_success !== null){
            setPendingOvertime(false);
        }
    },[overtime_success])

    useEffect(()=>{
        if(allowance_success !== null){
            setPendingAllowance(false);
        }
    },[allowance_success])

    useEffect(()=>{
        if(recurring_success !== null){
            setPendingRecurring(false);
        }
    },[recurring_success])

    useEffect(()=>{
        if(fixed_success !== null){
            setPendingFixed(false);
        }
    },[fixed_success])

    useEffect(()=>{
        if(holiday_success !== null){
            setPendingHoliday(false);
            if(schedule_data.length !==0){
                dispatch(wizard_create_schedule(schedule_data));
            }
        }
    },[holiday_success,schedule_data,dispatch])

    useEffect(()=>{
        if(schedule_success !== null){
            setPendingSchedule(false);
        }
    },[schedule_success])

    useEffect(()=>{
        if(department_success !== null){
            setPendingDepartment(false);
        }
    },[department_success])

    useEffect(()=>{
        if(section_success !== null){
            setPendingSection(false);
        }
    },[section_success])

    useEffect(()=>{
        if(jobtitle_success !== null){
            setPendingJobTitle(false);
        }
    },[jobtitle_success])

    useEffect(()=>{
        if(joblevel_success !== null){
            setPendingJobLevel(false);
        }
    },[joblevel_success])

    useEffect(()=>{
        if(costcenter_success !== null){
            setPendingCostCenter(false);
        }
    },[costcenter_success])

    useEffect(()=>{
        if(is_pending && !pending_allowance && !pending_company && !pending_costcenter && !pending_department && !pending_fixed && !pending_holiday && !pending_joblevel && !pending_jobtitle && !pending_overtime && !pending_period && !pending_recurring && !pending_schedule && !pending_section){
            Swal.fire({
                type:"warning",
                title:IMLocalized('complete_wizard_confirmation'),
                showCancelButton:true,
                cancelButtonText:IMLocalized('no!'),
                confirmButtonText:IMLocalized('yes!')
            })
            .then((result)=>{
                if(result.value){
                    window.location.href="/#/wizard/employee/";
                }
                else{
                    window.location.href="/";
                }
            })
        }
    },[is_pending,pending_allowance,pending_company,pending_costcenter,pending_department,pending_fixed,pending_holiday,pending_joblevel,pending_jobtitle,pending_overtime,pending_period,pending_recurring,pending_schedule,pending_section])

    useEffect(()=>{

        function createDetail(){
            if(departmentlist.length !== 0){
                setPendingDepartment(true);
                dispatch(wizard_create_department(departmentlist));
            }
            if(sectionlist.length !== 0){
                setPendingSection(true);
                dispatch(wizard_create_section(sectionlist));
            }
            if(jobtitlelist.length !== 0){
                setPendingJobTitle(true);
                dispatch(wizard_create_jobtitle(jobtitlelist));
            }
            if(joblevellist.length !== 0){
                setPendingJobLevel(true);
                dispatch(wizard_create_joblevel(joblevellist));
            }
            if(costcenterlist.length !== 0){
                setPendingCostCenter(true);
                dispatch(wizard_create_costcenter(costcenterlist));
            }
            dispatch(wizard_create_company(company_data));
            dispatch(wizard_update_pay_period(period_data));
            setPendingCompany(true);
            setPendingPeriod(true);
            if(overtime_data.length !== 0){
                setPendingOvertime(true);
                dispatch(wizard_create_overtime(overtime_data));
            }
            if(allowance_data.length !== 0){
                setPendingAllowance(true);
                dispatch(wizard_create_allowance(allowance_data));
            }
            if(recurring_data.length !== 0){
                setPendingRecurring(true);
                dispatch(wizard_create_recurring(recurring_data));
            }
            if(fixed_data.length !== 0){
                setPendingFixed(true);
                dispatch(wizard_create_flat(fixed_data));
            }
            if(holiday_data.length !== 0 ){
                setPendingHoliday(true);
                dispatch(wizard_create_holiday_list(holiday_data));
                if(schedule_data.length !== 0 ) setPendingSchedule(true);
            }
        }

        if(complete_success !== null){
            setPayrollToggle(false);
            createDetail();
            setIsPending(true);
            removeUnload();
        }
    },[complete_success,dispatch,company_data,period_data,overtime_data,allowance_data,recurring_data,fixed_data,departmentlist,sectionlist,joblevellist,jobtitlelist,costcenterlist,holiday_data,schedule_data,removeUnload])



    const depRef = useRef();
    const secRef = useRef();
    const levelRef = useRef();
    const titleRef = useRef();
    const costRef = useRef();

    const removeList=(type,index)=>{
        if(type === 'department'){
            const data = [...departmentlist];
            data.splice(index,1);
            setDepartmentList(data);
        }
        else if(type === 'section'){
            const data = [...sectionlist];
            data.splice(index,1);
            setSectionList(data);
        }
        else if(type === 'jobtitle'){
            const data = [...jobtitlelist];
            data.splice(index,1);
            setJobTitleList(data);
        }
        else if(type === 'joblevel'){
            const data = [...joblevellist];
            data.splice(index,1);
            setJobLevelList(data);
        }
        else if(type === 'costcenter'){
            const data = [...costcenterlist];
            data.splice(index,1);
            setCostCenterList(data);
        }
    }

    let addlist = [];
    if(add_type === 'department') addlist = departmentlist;
    else if (add_type === 'section') addlist = sectionlist;
    else if (add_type === 'jobtitle') addlist = jobtitlelist;
    else if (add_type === 'joblevel') addlist = joblevellist;
    else if (add_type === 'costcenter') addlist = costcenterlist;

    return(
        <>
        <Container>
            <Row className='mt-2'>
                <Col lg="9">
                    <div ref={depRef}>
                        <AdditionalTable type="department" list={departmentlist} removeList={removeList} onCreate={()=>{ setAddToggle(true); setAddType('department') } } />
                    </div>
                    <div ref={secRef}>
                        <AdditionalTable type="section" list={sectionlist} removeList={removeList} onCreate={()=>{ setAddToggle(true); setAddType('section') } } />
                    </div>
                    <div ref={titleRef}>
                        <AdditionalTable type="jobtitle" list={jobtitlelist} removeList={removeList} onCreate={()=>{ setAddToggle(true); setAddType('jobtitle') } } />
                    </div>
                    <div ref={levelRef}>
                        <AdditionalTable type="joblevel" list={joblevellist} removeList={removeList} onCreate={()=>{ setAddToggle(true); setAddType('joblevel') } } />
                    </div>
                    <div ref={costRef}>
                        <AdditionalTable type="costcenter" list={costcenterlist} removeList={removeList} onCreate={()=>{ setAddToggle(true); setAddType('costcenter') } } />
                    </div>
                </Col>
                <Col lg="3">
                    <div className='sticky-top'>
                        <ul>
                            <li><span className='text-primary cursor-pointer' onClick={()=>depRef.current.scrollIntoView({ block: 'center', behavior: 'smooth'})}>{IMLocalized('department')}</span></li>
                            <li><span className='text-primary cursor-pointer' onClick={()=>secRef.current.scrollIntoView({ block: 'center', behavior: 'smooth'})}>{IMLocalized('section')}</span></li>
                            <li><span className='text-primary cursor-pointer' onClick={()=>titleRef.current.scrollIntoView({ block: 'center', behavior: 'smooth'})}>{IMLocalized('job_title')}</span></li>
                            <li><span className='text-primary cursor-pointer' onClick={()=>levelRef.current.scrollIntoView({ block: 'center', behavior: 'smooth'})}>{IMLocalized('job_level')}</span></li>
                            <li><span className='text-primary cursor-pointer' onClick={()=>costRef.current.scrollIntoView({ block: 'center', behavior: 'smooth'})}>{IMLocalized('cost_center')}</span></li>
                        </ul>
                    </div>
                </Col>
            </Row>
            <div className='create_btn_parent'>
                <i className='fas fa-arrow-circle-right text-success cursor-pointer create_btn' onClick={()=>setPayrollToggle(true)}  />
            </div>
        </Container>
        <ModalAddAdditional type={add_type} toggle={add_toggle} closeModal={()=>{setAddToggle(false); setAddType(null)}} list={addlist} 
        addList={(item)=>{
            if(add_type === 'department'){
                setDepartmentList(prevState=>([...prevState,item]));
            }
            else if(add_type === 'section'){
                setSectionList(prevState=>([...prevState,item]));
            }
            else if(add_type === 'jobtitle'){
                setJobTitleList(prevState=>([...prevState,item]));
            }
            else if(add_type === 'joblevel'){
                setJobLevelList(prevState=>([...prevState,item]));
            }
            else if(add_type === 'costcenter'){
                setCostCenterList(prevState=>([...prevState,item]));
            }
        }} />
        <ModalPayroll toggle={payroll_toggle} closeModal={()=>setPayrollToggle(false)}  />
        </>
    )
}