import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const REJECTAPPLYCLAIM_REQUEST = 'REJECTAPPLYCLAIM_REQUEST';
export const REJECTAPPLYCLAIM_SUCCESS = 'REJECTAPPLYCLAIM_SUCCESS';
export const REJECTAPPLYCLAIM_FAILURE = 'REJECTAPPLYCLAIM_FAILURE';
export const REJECTAPPLYCLAIM_RESET = 'REJECTAPPLYCLAIM_RESET';

export const reject_apply_claim = (id,comment)=>{
    return dispatch=>{
        dispatch({
            type:'REJECTAPPLYCLAIM_REQUEST'
        });

        myaxios.post(`claim/apply/${id}/reject/`,{
            comment:comment
        })
        .then((response)=>{
            dispatch({
                type:'REJECTAPPLYCLAIM_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('reject_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'REJECTAPPLYCLAIM_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('reject_failure')
            });
        })
    }
}

export const reset_reject_apply_claim = ()=> dispatch =>{
    dispatch({
        type:'REJECTAPPLYCLAIM_RESET'
    });
}