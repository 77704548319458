import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { create_overtime, reset_create_overtime } from '../../../action/create_overtime';
import { get_additional_setting } from '../../../action/additional_setting';


const initialState={
    code:'',
    err_code:'',
    multiplier:'',
    err_multiplier:'',
}

function ModalCreateOvertime({toggle,closeModal}){
    
    const dispatch = useDispatch();
    const isLoading = useSelector(state=>state.create_overtime.isLoading);
    const success = useSelector(state=>state.create_overtime.data);
    const errors = useSelector(state=>state.create_overtime.errors);
    const [data,setData] = useState(initialState);

    useEffect(()=>{
        if(toggle){
            setData(initialState);
        }
    },[toggle])

    useEffect(()=>{
        if( toggle && success !== null){
            dispatch(get_additional_setting());
            dispatch(reset_create_overtime());
            closeModal();
        }
    },[success,toggle,closeModal,dispatch])

    useEffect(()=>{
        if( toggle && errors !== null){
            setData(prevState=>({
                ...prevState,
                err_code:errors.hasOwnProperty('code') ? errors['code'].toString() :'',
                err_multiplier:errors.hasOwnProperty('multiplier') ? errors['multiplier'].toString() :'',
            }));
            dispatch(reset_create_overtime());
        }
    },[errors,toggle,dispatch])

    const createOvertime=()=>{
        if(data.code !== '' && data.multiplier !== ''){
            dispatch(create_overtime(data.multiplier,data.code))
        }
        else{
            setData(prevState=>({
                ...prevState,
                err_code:data.code === '' ? IMLocalized('code_is_required') : '',
                err_multiplier:data.multiplier === '' ? IMLocalized('multiplier_is_required') : '',
            }))
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('add_overtime')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='code'>{IMLocalized('code')}</label>
                            <Input size="sm" type="text" id="code" value={data.code} onChange={(e)=>{ const value = e.target.value; setData(prevState=>({...prevState,code:value,err_code:''}))}} invalid={data.err_code.length > 0} />
                            <FormFeedback>{data.err_code}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='multiplier'>{IMLocalized('multiplier')}</label>
                            <Input size="sm" type="number" step='0.1' id="multiplier" value={data.multiplier} onChange={(e)=>{ const value = e.target.value; setData(prevState=>({...prevState,multiplier:value,err_multiplier:''}))}} invalid={data.err_multiplier.length > 0} />
                            <FormFeedback>{data.err_multiplier}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={()=>createOvertime()}>{IMLocalized('add')}</button> 
                    <button className="btn btn-danger btn-sm" onClick={()=>closeModal()}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default ModalCreateOvertime;