import myaxios from '../../axios';


export const GETAPPRAISALGROUPLIST_REQUEST = 'GETAPPRAISALGROUPLIST_REQUEST';
export const GETAPPRAISALGROUPLIST_SUCCESS = 'GETAPPRAISALGROUPLIST_SUCCESS';
export const GETAPPRAISALGROUPLIST_FAILURE = 'GETAPPRAISALGROUPLIST_FAILURE';


export const get_appraisal_group_list = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETAPPRAISALGROUPLIST_REQUEST'
        })

        myaxios.get('forms/groups/')
        .then((response)=>{
            dispatch({
                type:'GETAPPRAISALGROUPLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETAPPRAISALGROUPLIST_FAILURE',
                payload:error
            })
        })
    }
}