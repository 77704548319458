import * as get_template from '../../action/report/get_general_aggrid_template';


const initialState={
    data:[],
    errors:[],
    isLoading:false,
}


const get_general_aggrid_template=(state=initialState,action)=>{
    switch(action.type){
        case get_template.GETGENERALAGGRIDTEM_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case get_template.GETGENERALAGGRIDTEM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case get_template.GETGENERALAGGRIDTEM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_general_aggrid_template;