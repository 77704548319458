import { AgGridReact } from "ag-grid-react";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap";
import Swal from "sweetalert2";
import { claim_approvals_get_claim_detail, reset_claim_approvals_get_claim_detail } from "../../action/approvals/claim_approvals_get_claim_detail";
import { claim_manager_approve, reset_claim_manager_approve } from "../../action/approvals/claim_manager_approve";
import { get_apply_claim_list } from "../../action/claim/get_apply_claim_list";
import { get_approvals_claim_list } from "../../action/claim/get_approvals_claim_list";
import { get_general_dashboard } from "../../action/dashboard/get_general_dashboard";
import { get_all_employee_available_claim } from "../../action/employee/get_all_employee_available_claim";
import { user_get_available_claim } from "../../action/user/user_get_available_claim";
import { NewMainContext } from "../../context/NewMainContext";
import { amountRenderer } from "../ag-grid_component/amountRenderer";
import { date2Renderer } from "../ag-grid_component/date2Renderer";
import { isFirstColumn } from "../ag-grid_component/isFirstColumn";
import { popError } from "../../func/popError";
import { IMLocalized } from "../../language/IMLocalized";
import ModalManagerClaimDetail from "./modal/ModalManagerClaimDetail";
import OverlayLoading from '../loading_component/overlay_loading';
import { ClaimStatusRenderer } from "../../func/ClaimStatusRenderer";

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    suppressRowClickSelection:true,
    defaultColDef:{
        resizable:true,
        sortable:true,
        filter:true,
    },
}

const gridOptions2={
    
}



export default function ClaimApprovalList(){

    const { addItem, action_toggle,current_user } = useContext(NewMainContext);
    const dispatch = useDispatch();
    const list = useSelector(state=>state.get_approvals_claim_list.data);
    const isLoading = useSelector(state=>state.get_approvals_claim_list.isLoading);
    const approve_success = useSelector(state=>state.claim_manager_approve.data);
    const [ rowData, setRowData] = useState([]);
    const [ rowData2, setRowData2] = useState([]);
    const [ view_toggle, setViewToggle] = useState(false);

    useEffect(()=>{
        if(approve_success != null){
            if(current_user === 'hr'){
                dispatch(get_all_employee_available_claim());
            }
            else{
                dispatch(user_get_available_claim());
            }
            dispatch(get_apply_claim_list());
            dispatch(get_approvals_claim_list());
            dispatch(reset_claim_manager_approve());
            dispatch(get_general_dashboard());
            
            
        }
    },[approve_success,dispatch,current_user])


    useEffect(()=>{
        
        const arr = list?.claims ?? [];
        const arr1 = list?.approvals ? list.approvals.filter(item=>item.claim.status === 'PENDING') : [];
        setRowData(arr1);
        setRowData2(arr);

    },[list])

    const onCellFocused = (params)=>{
        if(params?.column?.colDef != null && params.column.colDef.field !== 'select'){
            const focusedCell = gridOptions.api.getFocusedCell();
            const cellValue = gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
            const {id} = cellValue.data;
            dispatch(claim_approvals_get_claim_detail(id));
            setViewToggle(true);
        }
    }

    const onCellFocused2 =(params)=> {
        if(params?.column?.colDef != null && params.column.colDef.field !== 'select'){
            const focusedCell = gridOptions2.api.getFocusedCell();
            const cellValue = gridOptions2.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
            const {id} = cellValue.data;
            dispatch(claim_approvals_get_claim_detail(id));
            setViewToggle(true);
        }
    }

    const arrayApprove=(boolean)=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0){
            Swal.fire({
                title: boolean ? IMLocalized('approving_claim') : IMLocalized('rejecting_claim'),
                input:'textarea',
                text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
                showCancelButton:true,
                cancelButtonText:IMLocalized('no!'),
                confirmButtonText:IMLocalized('yes!')
            })
            .then(result=>{
                if(result.hasOwnProperty('value')){
                    let remark = result.value;
                    let arr = data.map(item=>item.id);
                    dispatch(claim_manager_approve(arr,boolean,remark ));
                }
            })
        }
        else{
            popError(boolean ? IMLocalized('select_claim_to_approve') : IMLocalized('select_claim_to_reject') );
           
        }
    }

    let currentHeight;
    if(rowData.length !== 0){
        currentHeight = action_toggle === true ? 'calc(100vh - 550px)' : 'calc(100vh - 514px)'
    }
    else{
        currentHeight = action_toggle === true ? 'calc(100vh - 260px)' : 'calc(100vh - 224px)';
    }
    return(
        <>
        {isLoading ? <OverlayLoading/> :
        <>
        <Container fluid>
            <Row className="bg-white border-bottom">
                <Col className="p-2">
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('claim_approval_self')}</BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
            {rowData.length !== 0 && 
            <Row className="mt-2">
                <Col>
                    <Row>
                        <Col>
                            <h3 className="mb-0">{IMLocalized('pending_claim_list')}</h3>
                        </Col>
                        <Col className="col-auto">
                            <button className='btn btn-primary btn-sm' onClick={()=>arrayApprove(true)}>{IMLocalized('approve')}</button>
                            <button className='btn btn-danger btn-sm' onClick={()=>arrayApprove(false)}>{IMLocalized('reject')}</button>
                        </Col>
                    </Row>
                    <div className="mt-2 ag-theme-balham" style={{height:'250px', width:'100%' }}>
                        <AgGridReact
                            gridOptions={gridOptions}
                            rowData={rowData}
                            suppressDragLeaveHidesColumns={true}
                            suppressSizeToFit={true}
                            suppressColumnMoveAnimation={false}
                            overlayNoRowsTemplate = {IMLocalized('no_data')}
                            onCellFocused={onCellFocused}
                            columnDefs={[
                                {
                                    headerName:IMLocalized('select'),
                                    pinned:'left',
                                    field:'select',
                                    width:150,
                                    headerCheckboxSelection: isFirstColumn,
                                    checkboxSelection: isFirstColumn,
                                    headerCheckboxSelectionFilteredOnly:true,
                                    filter:false,
                                    lockPosition: true
                                },
                                {
                                    headerName:IMLocalized('claim_date'),
                                    field:'claim.claim_date',
                                    cellRendererFramework:date2Renderer,
                                },
                                {
                                    headerName:IMLocalized('employee_number'),
                                    field:'claim.employee.employee_number',
                                },
                                {
                                    headerName:IMLocalized('name'),
                                    field:'claim.employee.name'
                                },
                                {
                                    headerName:IMLocalized('claim_type'),
                                    field:'claim.claim_type.name'
                                },
                                {
                                    headerName:IMLocalized('status'),
                                    field:'claim.status',
                                    cellRendererFramework:ClaimStatusRenderer
                                },
                                {
                                    headerName:IMLocalized('claim_amount'),
                                    field:'claim.claim_amount',
                                    cellRendererFramework:amountRenderer
                                }
                            ]}
                        />
                    </div>
                </Col>
            </Row>}
            <Row className="mt-2" >
                <Col>
                    <h3 className="mb-0">{IMLocalized('approved_claim_list')}</h3>
                    <div className="mt-2 ag-theme-balham" style={{ height: currentHeight, width:'100%' }}>
                        <AgGridReact
                            enableRangeSelection={true}
                            gridOptions={gridOptions2}
                            rowData={rowData2}
                            suppressDragLeaveHidesColumns={true}
                            suppressSizeToFit={true}
                            overlayNoRowsTemplate = {IMLocalized('no_data')}
                            suppressColumnMoveAnimation={false}
                            onCellFocused={onCellFocused2}
                            columnDefs={[
                                {
                                    headerName:IMLocalized('claim_date'),
                                    field:'claim.claim_date',
                                    cellRendererFramework:date2Renderer,
                                },
                                {
                                    headerName:IMLocalized('employee_number'),
                                    field:'claim.employee.employee_number',
                                },
                                {
                                    headerName:IMLocalized('name'),
                                    field:'claim.employee.name'
                                },
                                {
                                    headerName:IMLocalized('claim_type'),
                                    field:'claim.claim_type.name'
                                },
                                {
                                    headerName:IMLocalized('status'),
                                    field:'claim.status',
                                    cellRendererFramework:ClaimStatusRenderer
                                },
                                {
                                    headerName:IMLocalized('claim_amount'),
                                    field:'claim.claim_amount',
                                    cellRendererFramework:amountRenderer
                                }
                            ]}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
        <ModalManagerClaimDetail toggle={view_toggle} closeModal={()=>{
            dispatch(reset_claim_approvals_get_claim_detail());
            setViewToggle(false);
        }} />
        </>}
        </>
    )
}