import * as template_dropdown from '../action/get_contract_template_dropdown';

const initialState={
    data:[],
    errors:{},
    contract_type:[],
    contract_rate:[],
    isLoading:false,
}

const contract_template_dropdown = (state=initialState,action) =>{
    switch(action.type){
        case template_dropdown.CONTRACTTEMPLATEDROPDOWN_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:{}
            }
        case template_dropdown.CONTRACTTEMPLATEDROPDOWN_SUCCESS:
            return{
                ...state,
                data:action.payload.data,
                contract_rate:action.payload.data.contract_rate,
                contract_type:action.payload.data.contract_type,
                errors:{},
                isLoading:false
            }
        case template_dropdown.CONTRACTTEMPLATEDROPDOWN_FAILURE:
            return{
                ...state,
                errors:action.payload,
                isLoading:false
            }
        default:
            return state;
    }
}
export default contract_template_dropdown;