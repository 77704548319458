import * as userapplyclaim from '../../action/user/user_apply_claim';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const user_apply_claim = (state=initialState,action)=>{
    switch(action.type){
        case userapplyclaim.USERAPPLYCLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case userapplyclaim.USERAPPLYCLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case userapplyclaim.USERAPPLYCLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case userapplyclaim.USERAPPLYCLAIM_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default user_apply_claim;