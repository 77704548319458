import * as color from '../action/get_general_color';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const get_general_color = (state=initialState,action)=>{
    switch(action.type){
        case color.GETGENERALCOLOR_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[]
            }
        case color.GETGENERALCOLOR_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case color.GETGENERALCOLOR_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_general_color;