import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const EMPALLLEAVEHRAPPROVE_REQUEST ='EMPALLLEAVEHRAPPROVE_REQUEST';
export const EMPALLLEAVEHRAPPROVE_SUCCESS ='EMPALLLEAVEHRAPPROVE_SUCCESS';
export const EMPALLLEAVEHRAPPROVE_FAILURE ='EMPALLLEAVEHRAPPROVE_FAILURE';
export const EMPALLLEAVEHRAPPROVE_RESET ='EMPALLLEAVEHRAPPROVE_RESET';

export const emp_all_array_approve_leave =(id,approve,comment)=>{
    return dispatch=>{
        dispatch({
            type:'EMPALLLEAVEHRAPPROVE_REQUEST'
        });

        const swal = approve ?  IMLocalized('approve_success') : IMLocalized('reject_success');
        const err_swal = approve ? IMLocalized('approve_failure') : IMLocalized('reject_failure');

        myaxios.post('leave/employee/approve/',{
            leaves:id,
            approve:approve,
            comment:comment
        })
        .then((response)=>{
            dispatch({
                type:'EMPALLLEAVEHRAPPROVE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: swal
            });
        })
        .catch((error)=>{
            dispatch({
                type:'EMPALLLEAVEHRAPPROVE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:err_swal,
            });
        })
    }
}


export const reset_emp_all_array_approve_leave = () => dispatch =>{
    dispatch({
        type:'EMPALLLEAVEHRAPPROVE_RESET'
    });
}