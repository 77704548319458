import React, { useContext, useEffect, useRef, useState } from 'react';
import { Table, Container, Row, Col } from 'reactstrap';
import {get_iras_detail, reset_get_iras_detail} from '../../action/iras/get_iras_detail';
import {get_iras_login} from '../../action/iras/get_iras_login';
import OverlayLoading from '../loading_component/overlay_loading';
import {IMLocalized} from '../../language/IMLocalized';
import '../../css/iras_text.css';
import { AgGridReact } from 'ag-grid-react';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getDateFormat } from '../../func/getDateFormat';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';


const gridOption={
    defaultColDef:{
        enableValue:true,
        enableRowGroup:true,
        enablePivot:true,
        sortable:true,
        filter:true,
        resizable:true
    },
    sideBar:true
}


export default function IrasDetail(props){

    const detail = useSelector(state=>state.get_iras_detail.data);
    const nationalitylist = useSelector(state=>state.get_iras_nationality.data);
    const post_success = useSelector(state=>state.get_iras_login.data);
    const isLoading2 = useSelector(state=>state.get_iras_login.isLoading);
    const isLoading1 = useSelector(state=>state.get_iras_nationality.isLoading);
    const isLoading = useSelector(state=>state.get_iras_detail.isLoading);
    const dispatch  = useDispatch();
    const { addItem, setIsRedirect, is_redirect, setIrasStep, iras_id } = useContext(NewMainContext);
    const [ has_posted, setHasPosted] = useState(false);
    const [ company, setCompany] = useState('');
    const [ year, setYear] = useState('');
    const [ data, setData] = useState(null);
    const [ rowData, setRowData] = useState([]);

    const irasRef = useRef();

    useEffect(()=>{
        if(iras_id !== ''){
            dispatch(get_iras_detail(iras_id));
        }
    },[iras_id,dispatch])

    useEffect(()=>{
        if(detail != null && nationalitylist.length !== 0){
            const {record,year,company_name,has_posted} = detail;
            setCompany(company_name);
            setYear(year);
            setHasPosted(has_posted);
            
            const arr = record.map((item)=>{
                const {nationality} = item;
                const nation = nationalitylist.find(el=>el.value === nationality);
                const nationality_name = nation?.nationality ?? '';
                const from_date1 = getDateFormat(item.from_date);
                const to_date1 = getDateFormat(item.to_date);
                return {...item,from_date1,to_date1,nationality_name}
            });
            setData(detail);
            setRowData(arr);
        }
    },[detail,nationalitylist])

    useEffect(()=>{
        if(!is_redirect && post_success != null){
            const {url} = post_success;
            localStorage.setItem('reoutside','1');
            localStorage.setItem('reside','1');
            window.location.href=url;
        }
    },[post_success,is_redirect])


    const onGridReady = (params) =>{
        irasRef.current =  params.api;
    }

    const exportExcel = ()=>{
        const today = moment(new Date()).format('DD/MM/YYYY');
        const time = moment().format('HH:mm:ss');
        const getRows = ()=>[
            [
                {
                    data:{
                        value: 'IRAS -' +company+' (' + year + ') - Printed On :' +today + ' ' + time,
                        type: 'String',
                    }
                },
            ],
            []
        ];

        const getParams=()=>({
            prependContent: getRows(),
            fileName:'IRAS_'+company+'_'+year+'.xlsx',
            sheetName:'iras',
            columnWidth: 120
        });

        irasRef.current.exportDataAsExcel(getParams());
    }

    const submitIras=()=>{
        dispatch(get_iras_login(iras_id));
        setIsRedirect(true);
    }

    const backMain = () =>{
        setIrasStep('0'); 
        dispatch(reset_get_iras_detail());
    }

    return(
        <>
        {isLoading || isLoading1  ? <OverlayLoading/>:
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('employee'), onClick:()=>backMain()},
                        { active: true, title:data? `${data.company_name} (${data.year})` : '-' }
                    ]}/>
                    <Row>
                        <Col>
                            <button className="btn btn-primary btn-sm ml-2" onClick={()=>{ setIrasStep('0'); dispatch(reset_get_iras_detail()) }}> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                        </Col>
                        <Col className='col-auto'>
                            {isLoading2 && <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>}
                            {!isLoading2 && !has_posted && <button className="btn btn-primary btn-sm" onClick={submitIras} >{IMLocalized('submit_to_iras')}</button>}
                            <button className="btn btn-success btn-sm" onClick={exportExcel}>{IMLocalized('export')}</button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {data !== null &&
            <Row className='mt-2'>
                <Col>
                    <Table className="table-sm table-white table-bordered" responsive>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="iras_title iras_font_size">Company Name : </span> <span className="iras_font_size">{data.company_name}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Year: </span> <span className="iras_font_size">{data.year}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Created Date : </span> <span className="iras_font_size">{moment(new Date(data.created_date)).format('DD/MM/YYYY HH:mm:ss')}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Auth Person Number : </span> <span className="iras_font_size">{data.auth_person_number}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="iras_title iras_font_size">Auth Person Name : </span> <span className="iras_font_size">{data.auth_person_name}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Auth Person Email : </span> <span className="iras_font_size">{data.auth_person_email}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Amendment : </span> <span className="iras_font_size">{data.amendment}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Amount : </span> <span className="iras_font_size">{data.amount}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="iras_title iras_font_size">Salary : </span> <span className="iras_font_size">{data.salary}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">CPF : </span> <span className="iras_font_size">{data.cpf}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Bonus : </span> <span className="iras_font_size">{data.bonus}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Director Fee : </span> <span className="iras_font_size">{data.director_fees}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="iras_title iras_font_size">Donation : </span> <span className="iras_font_size">{data.donation}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Exempt Income : </span> <span className="iras_font_size">{data.exempt_income}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Insurance : </span> <span className="iras_font_size">{data.insurance}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">MBMF : </span> <span className="iras_font_size">{data.mbmf}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="iras_title iras_font_size">Others : </span> <span className="iras_font_size">{data.others}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Records: </span> <span className="iras_font_size">{data.records}</span>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>}
            <Row className='mt-2 mb-2'>
                <Col>
                    <div className='ag-theme-balham' style={{height:'calc(100vh - 280px)', width:'100%' }}>
                        <AgGridReact
                        columnDefs={[
                            {
                                headerName:'EMP ID',
                                field:'employee.employee_number',
                                width:100,
                            },
                            {
                                headerName:'EMP NAME',
                                field:'employee.name',
                                width:200,
                            },
                            {
                                headerName:'TOTAL AMOUNT',
                                field:'amount',
                                width:150,
                            },
                            {
                                headerName:'SALARY',
                                field:'salary',
                                width:150,
                            },
                            {
                                headerName:'OTHERS',
                                field:'others',
                                width:150,
                            },
                            {
                                headerName:'CPF',
                                field:'cpf',
                                width:150,
                            },
                            {
                                headerName:'GROSS COMMISSION',
                                field:'gross_commission',
                                width:150,
                            },
                            {
                                headerName:'PENSION',
                                field:'pension',
                                width:150,
                            },
                            {
                                headerName:'TRANSPORT ALLOWANCE',
                                field:'transport_allowance',
                                width:100,
                            },
                            {
                                headerName:'ENTERTAINMENT ALLOWANCE',
                                field:'entertainment_allowance',
                                width:150,
                            },
                            {
                                headerName:'OTHER ALLOWANCE',
                                field:'other_allowance',
                                width:150,
                            },
                            {
                                headerName:'GRATUITY',
                                field:'gratuity',
                                width:150,
                            },
                            {
                                headerName:'EMPLOYER CPF EXCESS',
                                field:'employer_cpf_excess',
                                width:150,
                            },
                            {
                                headerName:'MBMF',
                                field:'mbmf',
                                width:150,
                            },
                            {
                                headerName:'DONATION',
                                field:'donation',
                                width:150,
                            },
                            {
                                headerName:'INSURANCE',
                                field:'insurance',
                                width:150,
                            },
                            {
                                headerName:'BONUS',
                                field:'bonus',
                                width:150,
                            },
                            {
                                headerName:'DIRECTOR FEES',
                                field:'director_fees',
                                width:150,
                            },
                            {
                                headerName:'IDENTIFICATION NUMBER',
                                field:'identification_number',
                                width:150,
                            },
                            {
                                headerName:'FROM DATE',
                                field:'from_date1',
                                width:150,
                            },
                            {
                                headerName:'TO DATE',
                                field:'to_date1',
                                width:150,
                            },
                            {
                                headerName:'NATIONALITY',
                                field:'nationality_name',
                                width:200,
                            },
                            {
                                headerName:'DEPARTMENT',
                                field:'employee.contract_cache.department',
                                width:200,
                            },
                            {
                                headerName:'SECTION',
                                field:'employee.contract_cache.section',
                                width:200,
                            },
                            {
                                headerName:'JOB TITLE',
                                field:'employee.contract_cache.job_title',
                                width:200,
                            },
                            {
                                headerName:'JOB LEVEL',
                                field:'employee.contract_cache.job_level',
                                width:200,
                            },
                            {
                                headerName:'COST CENTER',
                                field:'employee.contract_cache.cost_center',
                                width:200,
                            },
                        ]}
                        rowData={rowData}
                        gridOptions={gridOption}
                        suppressDragLeaveHidesColumns={true}
                        onGridReady={onGridReady}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
        </>}
        </>
    )
}