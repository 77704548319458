import * as all_available_leave from '../../action/employee/get_all_employee_available_leave';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}

const get_all_employee_available_leave = (state=initialState,action)=>{
    switch(action.type){
        case all_available_leave.GETALLEMPLEAVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:[]
            }
        case all_available_leave.GETALLEMPLEAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case all_available_leave.GETALLEMPLEAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_all_employee_available_leave;


export const get_all_employee_available_leave_detail = (state,id,leave_setting)=>{
    const { data} = state.get_all_employee_available_leave;

    return id != null && leave_setting != null ? data.filter(item=>item.employee === id).find(item=>item.leave_setting === leave_setting) : null
}