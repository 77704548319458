import myaxios from '../../axios';

export const GETIRASLOGIN_REQUEST = 'GETIRASLOGIN_REQUEST';
export const GETIRASLOGIN_SUCCESS = 'GETIRASLOGIN_SUCCESS';
export const GETIRASLOGIN_FAILURE = 'GETIRASLOGIN_FAILURE';
export const GETIRASLOGIN_RESET = 'GETIRASLOGIN_RESET';

export const get_iras_login = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETIRASLOGIN_REQUEST'
        });

        myaxios.post(`payroll/iras/${id}/login/`)
        .then((response)=>{
            dispatch({
                type:'GETIRASLOGIN_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETIRASLOGIN_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_get_iras_login = ()=>dispatch =>{
    dispatch({
        type:'GETIRASLOGIN_RESET'
    });
}