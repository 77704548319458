import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATENEWPAYROLL_REQUEST='CREATENEWPAYROLL_REQUEST';
export const CREATENEWPAYROLL_SUCCESS='CREATENEWPAYROLL_SUCCESS';
export const CREATENEWPAYROLL_FAILURE='CREATENEWPAYROLL_FAILURE';


export const create_new_payroll = (date,rate)=>{
    return dispatch=>{
        dispatch({
            type:'CREATENEWPAYROLL_REQUEST'
        });

        myaxios.post('payroll/payroll/',{
            payroll_date:date,
            rates:rate
        })
        .then((response)=>{
            dispatch({
                type:'CREATENEWPAYROLL_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATENEWPAYROLL_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}