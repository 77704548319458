import * as schedule from '../action/schedule_list';

const initialState={
    data:[],
    error:{},
    isLoading:false
}

const schedule_list = (state=initialState,action) =>{
    switch(action.type){
        case schedule.REQUEST_SCHEDULELIST:
            return{
                ...state,
                isLoading:true,
                error:{},
                data:[]
            }
        case schedule.GET_SCHEDULELIST:
            return{
                ...state,
                data:action.payload.data,
                error:{},
                isLoading:false
            }
        case schedule.FAILURE_SCHEDULELIST:
            return{
                ...state,
                error:{},
                isLoading:false
            }
            default:
                return state;
    }
}
export default schedule_list;