import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import Swal from "sweetalert2";
import { delete_claim_approval, reset_delete_claim_approval } from "../../action/claim/delete_claim_approval";
import { get_claim_approval } from "../../action/claim/get_claim_approval";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";



export default function ClaimApprovalSchemeList({setStep, setEditID}){

    const { addItem } = useContext(NewMainContext);
    const list = useSelector(state=>state.get_claim_approval.data);
    const isLoading = useSelector(state=>state.get_claim_approval.isLoading);
    const delete_success = useSelector(state=>state.delete_claim_approval.data);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(delete_success != null){
            dispatch(reset_delete_claim_approval());
            dispatch(get_claim_approval());
        }
    },[delete_success,dispatch])

    const editApproval = (id) =>{
        setEditID(id);
        setStep(2);
    }

    const deleteApproval = (id,name) =>{
        Swal.fire({
            type:'warning',
            title:`${IMLocalized('delete_claim_approval_confirmation')} (${name})!`,
            showCancelButton:true,
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!')
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_claim_approval(id));
            }
        })
    }


    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('claim_approval_list2')}</BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </Container>
        <Container className="mt-2">
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('claim_approval_list2')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm" onClick={()=>setStep(1)}>{IMLocalized('create')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        {isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                        {!isLoading && list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {!isLoading && list.length !== 0 && 
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 300px)'}}>
                            {list.map((item,index)=>{
                                const number = index + 1;
                                return(
                                    <ListGroupItem className="py-2" key={item.id}>
                                        <Row>
                                            <Col className="d-flex align-items-center">
                                                <span>{number}. {item.name}</span>
                                            </Col>
                                            <Col className="col-auto mr-1">
                                                <button className="btn btn-info btn-sm" onClick={()=>editApproval(item.id)}>{IMLocalized('edit')}</button>
                                                <button className="btn btn-danger btn-sm" onClick={()=>deleteApproval(item.id, item.name)}>{IMLocalized('delete')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    )
}