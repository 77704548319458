import { createContext, useState } from "react";


export const WizardHolidayContext = createContext({
    list:[],
    step:0
})

export default function WizardHolidayContextProvider({children,setData}){

    const [list, setList] = useState([]);
    const [step, setStep] = useState(0);
    const [holiday_id, setHolidayID] = useState(null);

    return(
        <WizardHolidayContext.Provider value={{
            list,
            step,
            setList,
            setStep,
            setHolidayID,
            holiday_id,
            setData
        }}>
            {children}
        </WizardHolidayContext.Provider>
    )
} 