import * as contractincrement from '../../action/payroll/create_contract_increment_pay';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const create_contract_increment_pay = (state=initialState,action)=>{
    switch(action.type){
        case contractincrement.CONTRACTINPAY_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case contractincrement.CONTRACTINPAY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractincrement.CONTRACTINPAY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default create_contract_increment_pay;