import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const ADDCLAIMGROUPTEMPLATE_REQUEST = 'ADDCLAIMGROUPTEMPLATE_REQUEST';
export const ADDCLAIMGROUPTEMPLATE_SUCCESS = 'ADDCLAIMGROUPTEMPLATE_SUCCESS';
export const ADDCLAIMGROUPTEMPLATE_FAILURE = 'ADDCLAIMGROUPTEMPLATE_FAILURE';
export const ADDCLAIMGROUPTEMPLATE_RESET = 'ADDCLAIMGROUPTEMPLATE_RESET';

export const add_claim_group_template = (name,groups)=>{
    return dispatch=>{
        dispatch({
            type:'ADDCLAIMGROUPTEMPLATE_REQUEST'
        });

        myaxios.post('claim/mass/template/',{
            name:name,
            groups:groups
        })
        .then((response)=>{
            dispatch({
                type:'ADDCLAIMGROUPTEMPLATE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type:'success',
                title:IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'ADDCLAIMGROUPTEMPLATE_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}

export const reset_add_claim_group_template = () => dispatch =>{
    dispatch({
        type:'ADDCLAIMGROUPTEMPLATE_RESET'
    });
}