import React,{Component} from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem,Table, Input,Card,CardBody,CardHeader, FormGroup, FormFeedback } from 'reactstrap';
import {connect} from 'react-redux';
import {IMLocalized} from '../../language/IMLocalized';
import {submit_cpf_test} from '../../action/test/submit_cpf_test';
import { NewMainContext } from '../../context/NewMainContext';

class cpf_test extends Component{

    constructor(props){
        super(props);
        this.state={
            employee_birth_date:'',
            err_employee_birth_date:'',
            payroll_date:'',
            err_payroll_date:'',
            ordinary_wage:'',
            err_ordinary_wage:'',
            additional_wage:0,
            err_additional_wage:'',
            ow_paid:0,
            err_ow_paid:'',
            aw_paid:0,
            err_aw_paid:'',
            pr:'',
            err_pr:'',
            pr_cpf_contribution_type:'STD',
            data:[]
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.success !== this.props.success){
            const {success} =  this.props;
            if(success !== undefined){
                if(success.length !== 0){
                    const data = success.data;
                    this.setState({data});
                }
            }
        }
    }

    toHome=()=>{
        this.context.addItem('1','dashboard');
    }

    handleInput=(e)=>{
        const target = e.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]:value
        })

        if(name === 'employee_birth_date'){
            this.setState({err_employee_birth_date:''});
        }
        if(name === 'payroll_date'){
            this.setState({err_payroll_date:''});

        }
        if(name === 'ordinary_wage'){
            this.setState({err_ordinary_wage:''});
        }

        if(name === 'additional_wage'){
            this.setState({err_additional_wage:''});
        }

        if(name === 'ow_paid'){
            this.setState({err_ow_paid:''});
        }

        if(name === 'aw_paid'){
            this.setState({err_aw_paid:''});
        }

        if(name === 'pr'){
            this.setState({err_pr:''});
        }
    }

    onCreate=()=>{
        let {pr} = this.state;
        const {employee_birth_date,payroll_date,ordinary_wage,additional_wage,ow_paid,aw_paid,pr_cpf_contribution_type} = this.state;
        if(employee_birth_date !== '' && payroll_date !== '' && ordinary_wage !== '' && additional_wage !== '' && ow_paid !== '' && aw_paid !== '' ){
            
            if(pr === ''){
                pr = null
            }

            this.props.submit_cpf_test(employee_birth_date,payroll_date,ordinary_wage,additional_wage,ow_paid,aw_paid,pr,pr_cpf_contribution_type);

        }
        else{
            if(employee_birth_date === ''){
                this.setState({err_employee_birth_date:'Employee birth date is required'})
            }
            if(payroll_date === ''){
                this.setState({err_payroll_date:'Payroll date is required'})
            }
            if(ordinary_wage === ''){
                this.setState({err_ordinary_wage:'Ordinary wage is required'})
            }
            if(additional_wage === ''){
                this.setState({err_additional_wage:'Additional wage is required'})
            }
            if(ow_paid === ''){
                this.setState({err_ow_paid:'OW paid is required'})
            }
            if(aw_paid === ''){
                this.setState({err_aw_paid:'AW paid is required'})
            }
            
        }
        
    }

    getTable=()=>{
        const {data} = this.state;
        let table = '';
        if(data.length !== 0){
            let body = [];
            for(let i =0;i<data.length ;i++){
                const item =  data[i];
                if(i === 0){
                    body.push(
                        <tr>
                            <td>Total</td>
                            <td>{item}</td>
                        </tr>
                    )
                }
                else if(i ===1){
                    body.push(
                        <tr>
                            <td>Employer</td>
                            <td>{item}</td>
                        </tr>
                    )
                }
                else if(i ===2){
                    body.push(
                        <tr>
                            <td>Employee</td>
                            <td>{item}</td>
                        </tr>
                    )
                }
                else if(i === 3){
                    body.push(
                        <tr>
                            <td>Age Group Max</td>
                            <td>{item}</td>
                        </tr>
                    )
                }
                else if(i === 4){
                    body.push(
                        <tr>
                            <td>Table Name</td>
                            <td>{item}</td>
                        </tr>
                    )
                }
            }

            table=(
                <Card>
                    <Table>
                        <tbody>
                            {body}
                        </tbody>
                    </Table>
                </Card>
               
            )
        }

        return table;
    }

    render(){

        const option = this.props.sgpr_employee_cpf_payment.map((item,index)=>{
            return <option value={item.value} key={index}>{item.display}</option>
        })

        let btn= <button onClick={this.onCreate} className="btn btn-primary btn-sm">{IMLocalized('submit')}</button>
        if(this.props.isLoading){
            btn = <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>
        }
        return(
            <div>
                <Container fluid>
                    <Row className="border-bottom bg-white">
                        <Col className="p-2">
                            <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                                <BreadcrumbItem><a href="/#" onClick={this.toHome}>{IMLocalized('home')}</a></BreadcrumbItem>
                                <BreadcrumbItem active>CPF Test</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className="pt-2">
                        <Col>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <h4 className="mb-0">CPF Test</h4>
                                        </Col>
                                        <Col className="col-auto">
                                            {btn}
                                        </Col>
                                    </Row>
                                   
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label">Employee Birth Date</label>
                                                <Input size="sm" type="date" name="employee_birth_date" value={this.state.employee_birth_date} onChange={this.handleInput} invalid={this.state.err_employee_birth_date.length >0} />
                                                <FormFeedback>{this.state.err_employee_birth_date}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label">Payroll Date</label>
                                                <Input size="sm" type="date" name="payroll_date" value={this.state.payroll_date} onChange={this.handleInput} invalid={this.state.err_payroll_date.length >0 } />
                                                <FormFeedback>{this.state.err_payroll_date}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label">Ordinary Wage</label>
                                                <Input size="sm" type="number" name="ordinary_wage" value={this.state.ordinary_wage} onChange={this.handleInput} invalid={this.state.err_ordinary_wage.length >0} />
                                                <FormFeedback>{this.state.err_ordinary_wage}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label">Additional Wage</label>
                                                <Input size="sm" type="number" name="additional_wage" value={this.state.additional_wage} onChange={this.handleInput} invalid={this.state.err_additional_wage.length >0} />
                                                <FormFeedback>{this.state.err_additional_wage}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label">OW Paid</label>
                                                <Input size="sm" type="number" name="ow_paid" value={this.state.ow_paid} onChange={this.handleInput} invalid={this.state.err_ow_paid.length >0} />
                                                <FormFeedback>{this.state.err_ow_paid}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label">AW Paid</label>
                                                <Input size="sm" type="number" name="aw_paid" value={this.state.aw_paid} onChange={this.handleInput} invalid={this.state.err_aw_paid.length > 0} />
                                                <FormFeedback>{this.state.err_aw_paid}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label">PR</label>
                                                <Input size="sm" type="number" step='1' name="pr" value={this.state.pr} onChange={this.handleInput} invalid={this.state.err_pr.length >0} />
                                                <FormFeedback>{this.state.err_pr}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label">PR CPF Contribution Type</label>
                                                <Input size="sm" type="select"  value={this.state.pr_cpf_contribution_type} name="pr_cpf_contribution_type" onChange={this.handleInput}>
                                                    {option}
                                                </Input>
                                                
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            {this.getTable.call(this)}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
function mapStateToProps(state,ownProps){
    return{
        sgpr_employee_cpf_payment:state.dropdown_list.sgpr_employee_cpf_payment,
        success:state.submit_cpf_test.data,
        errors:state.submit_cpf_test.errors,
        isLoading:state.submit_cpf_test.isLoading,
    }
}

const mapDispatchToProps =(dispatch)=>{
    return{
        submit_cpf_test:(employee_birth_date,payroll_date,ordinary_wage,additional_wage,ow_paid,aw_paid,pr,pr_cpf_contribution_type)=>{
            dispatch(submit_cpf_test(employee_birth_date,payroll_date,ordinary_wage,additional_wage,ow_paid,aw_paid,pr,pr_cpf_contribution_type))
        }
    }
}
cpf_test.contextType = NewMainContext;
export default  connect(mapStateToProps,mapDispatchToProps)(cpf_test);
