import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELEMPEDUCATION_REQUEST = 'DELEMPEDUCATION_REQUEST';
export const DELEMPEDUCATION_SUCCESS = 'DELEMPEDUCATION_SUCCESS';
export const DELEMPEDUCATION_FAILURE = 'DELEMPEDUCATION_FAILURE';
export const DELEMPEDUCATION_RESET = 'DELEMPEDUCATION_RESET';


export const delete_employee_education = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELEMPEDUCATION_REQUEST'
        });

        myaxios.delete(`employee/education/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELEMPEDUCATION_SUCCESS',
                payload:response
            });


            Toast.fire({
                type: 'success',
                title:IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELEMPEDUCATION_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_employee_education = ()=> dispatch=>{
    dispatch({
        type:'DELEMPEDUCATION_RESET'
    })
}