import * as companydropdown from '../action/company_dropdown';

const initialState ={
    data:[],
    error:{},
    cpf:[],
    isLoading:false,
    bank:[],
}


const company_dropdown = (state=initialState,action) =>{
    switch(action.type) {
        case companydropdown.REQUEST_COMPANYDROPDOWN:
            return{
                ...state,
                isLoading: true,
                error:{}
            }
        case companydropdown.GET_COMPANYDROPDOWN:
            return{
                ...state,
                data: action.payload.data,
                cpf:action.payload.data.CPF_payment_type,
                bank:action.payload.data.bank,
                error:{},
                isLoading:false
        }
        case companydropdown.FAILURE_COMPANYDROPDOWN:
            return{
                ...state,
                error:{},
                isLoading:false
            }
            default:
                    return state;
    }
}

export default company_dropdown;