import * as selfhelplist from '../../action/employee/get_employee_self_help';


const initialState ={
    data:[],
    isLoading:false,
    errors:null,
}


const get_employee_self_help = (state = initialState, action) =>{
    switch(action.type){
        case selfhelplist.GETEMPSELFHELP_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[],
                errors:null
            }
        case selfhelplist.GETEMPSELFHELP_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case selfhelplist.GETEMPSELFHELP_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state
    }
} 
export default get_employee_self_help;