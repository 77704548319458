import myaxios from '../../axios';

export const EMPDETAIL_REQUEST = 'EMPDETAIL_REQUEST';
export const EMPDETAIL_SUCCESS = 'EMPDETAIL_SUCCESS';
export const EMPDETAIL_FAILURE = 'EMPDETAIL_FAILURE';
export const EMPDETAIL_RESET = 'EMPDETAIL_RESET';

export const get_employee_detail = (id)=>{
    return dispatch=>{
        dispatch({
            type:'EMPDETAIL_REQUEST'
        })

        myaxios.get(`employee/employees/${id}/`)
        .then((response)=>{
            dispatch({
                type:'EMPDETAIL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPDETAIL_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_get_employee_detail = () => dispatch => {
    dispatch({
        type:'EMPDETAIL_RESET'
    })
}