import * as formlist from '../../action/appraisal/get_appraisal_form_list';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const get_appraisal_form_list = (state=initialState,action)=>{
    switch(action.type){
        case formlist.GETAPPRAISALFORMLIST_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case formlist.GETAPPRAISALFORMLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case formlist.GETAPPRAISALFORMLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_appraisal_form_list;


export const get_appraisal_form_list_detail = (state,id)=>{
    const { data } = state.get_appraisal_form_list;

    return id != null ? data.find(item=>item.id === id) : null;
}