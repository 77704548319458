import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { Toast } from '../../swal/SwalToast';

export const CREATENEWRESET_REQUEST = 'CREATENEWRESET_REQUEST';
export const CREATENEWRESET_SUCCESS = 'CREATENEWRESET_SUCCESS';
export const CREATENEWRESET_FAILURE = 'CREATENEWRESET_FAILURE';
export const CREATENEWRESET_RESET = 'CREATENEWRESET_RESET';


export const create_new_leave_reset = ()=>{
    return dispatch=>{
        dispatch({
            type:'CREATENEWRESET_REQUEST'
        });

        myaxios.post('leave/reset/')
        .then((response)=>{
            dispatch({
                type:'CREATENEWRESET_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type:'success',
                title:IMLocalized('create_success')
            })
        })
        .catch((error)=>{
            dispatch({
                type:'CREATENEWRESET_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_create_new_leave_reset = () => dispatch =>{
    dispatch({
        type:'CREATENEWRESET_RESET'
    });
}