import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATESCHEDULE_REQUEST1 = 'CREATESCHEDULE_REQUEST1';
export const CREATESCHEDULE_SUCCESS1 = 'CREATESCHEDULE_SUCCESS1';
export const CREATESCHEDULE_FAILURE1 = 'CREATESCHEDULE_FAILURE1';


export const wizard_create_schedule = (data) =>{
    return dispatch =>{
        dispatch({
            type: 'CREATESCHEDULE_REQUEST1'
        });

        const count = data.length;
        for(let i=0;i<count;i++){
            const y = count -1;
            const item = data[i];

            myaxios.post('contract/schedules/',item)
            .then((response)=>{
                if(i === y){
                    dispatch({
                        type: 'CREATESCHEDULE_SUCCESS1',
                        payload:response
                    });

                    Toast.fire({
                        type: 'success',
                        title: IMLocalized('create_success')
                    });
                }
            })
            .catch((error)=>{
                dispatch({
                    type:'CREATESCHEDULE_FAILURE1',
                    payload: error
                });

                ErrToast.fire({
                    type: 'error',
                    title: IMLocalized('create_failure')
                });
            })
        }
    }
}