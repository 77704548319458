import React,{Component} from 'react';



class NotFound extends Component {

    handleUnload=(e)=>{
        var message = "";
        (e || window.event).returnValue = message;
        return message;
    }

    toMainPage=()=>{
        window.removeEventListener('beforeunload',this.handleUnload);
        localStorage.clear();
        window.location.reload();
    }

    render(){

        return(
            <div className="page-wrap d-flex flex-row align-items-center login2_main" style={{height:'100vh'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <span className="display-1 d-block">Oops... Something went wrong</span>
                            <div className="mb-4 lead">Sorry, this page isn't available.</div>
                            <button className='btn btn-danger btn-sm'  onClick={this.toMainPage}>Back to Home</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default NotFound;