import React, { useState } from "react";
import { Card, CardHeader, Col, Row, Table } from "reactstrap";
import { getDateFormat } from "../../../func/getDateFormat";
import { IMLocalized } from "../../../language/IMLocalized";
import ModalCalculate from "../payslip_modal/ModalCalculate";
import OvertimeTable from "./OvertimeTable";
import AllowanceTable from './AllowanceTable';
import RecurringTable from './RecurringTable';
import FixedRateTable from './FixedRateTable';
import ClaimTable from './ClaimTable';
import BackpayTable from "./BackpayTable";
import BonusTable from './BonusTable';


function EarningTable(props){

    const [calculate_toggle,setCalculateToggle] = useState(false);
    const [calculate_detail,setCalculateDetail] = useState(null);

    const col1 = [];
    const col2 = [];
    let col1_amount = 0;
    let col2_amount = 0;

    if(props.overtimelist.length !== 0){
        col1_amount += props.overtimelist.length;
        col1.push(<OvertimeTable list={props.overtimelist} id={props.id} key='overtimetable' />)
    }

    if(props.allowancelist.length !== 0){
        if(col2_amount < col1_amount){
            col2_amount += props.allowancelist.length +1; 
            col2.push(<AllowanceTable list={props.allowancelist} id={props.id} key='allowancetable' />)
        }
        else{
            col1_amount += props.allowancelist.length +1; 
            col1.push(<AllowanceTable list={props.allowancelist} id={props.id} key='allowancetable'/>)
        }
    }

    if(props.recurringlist.length !== 0){
        if(col2_amount < col1_amount){
            col2_amount += props.recurringlist.length +1;
            col2.push(<RecurringTable list={props.recurringlist} key='recurringtable' />)
        }
        else{
            col1_amount += props.recurringlist.length +1;
            col1.push(<RecurringTable list={props.recurringlist} key='recurringtable' />)
        }
    }

    if(props.flatlist.length !== 0){
        if(col2_amount < col1_amount){
            col2_amount += props.flatlist.length +1;
            col2.push(<FixedRateTable list={props.flatlist} id={props.id} key='flattable' />)
        }
        else{
            col1_amount += props.flatlist.length +1;
            col1.push(<FixedRateTable list={props.flatlist} id={props.id} key='flattable' />)
        }
    }
    
    if(props.claimlist.length !== 0){
        if(col2_amount < col1_amount){
            col2.push(<ClaimTable list={props.claimlist} key="claimtable" />);
            col2_amount += props.claimlist.length +1;
        }
        else{
            col1.push(<ClaimTable list={props.claimlist} key="claimtable" />);
            col1_amount += props.claimlist.length +1;
        }
    }
   
    if(col2_amount < col1_amount){
        col2.push(<BackpayTable list={props.backpaylist} id={props.id} key="backpaylist" type="backpay" payslip_id={props.payslip_id} payslipcontract={props.emp_payslip.payslipcontract ?? [] } />);
        col2_amount +=  (props.backpaylist.length +2);
    }
    else{
        col1.push(<BackpayTable list={props.backpaylist} id={props.id} payslip_id={props.payslip_id} key="backpaylist" type="backpay" payslipcontract={props.emp_payslip?.payslipcontract ?? [] } />);
        col1_amount += (props.backpaylist.length +2);
    }

    if(col2_amount < col1_amount){
        col2.push(<BonusTable list={props.bonuslist} id={props.id} payslip_id={props.payslip_id} key="bonuslist" payslipcontract={props.emp_payslip?.payslipcontract ?? [] }  emp={props.emp} />);
        col2_amount +=  (props.bonuslist.length +3);
    }
    else{
        col1.push(<BonusTable list={props.bonuslist} id={props.id} payslip_id={props.payslip_id} key="bonuslist" payslipcontract={props.emp_payslip?.payslipcontract ?? [] } emp={props.emp}  />);
        col1_amount += (props.bonuslist.length +3);
    }

    return(
        <>
        <Row>
            <Col lg="12">
                <div className="d-flex justify-content-between align-items-center pl-2" style={{backgroundColor:'#dee6f1',height:'38px'}}>
                    <h2 className="title-color1 mb-0">{IMLocalized('payslip_title_earnings')}</h2>
                </div>
            </Col>
        </Row>
        <Row className="mt-2">
            <Col lg="12">
                <Card>
                    <CardHeader className="p-2 border-0 bg-white">
                        <h4 className="text-primary pl-2 pt-2">{IMLocalized('payslip_basic')}</h4>
                    </CardHeader>
                    {props.emp_payslip != null && props.emp_payslip.payslipcontract.length !== 0 &&
                    <Table size="sm" className="align-items-center" responsive>
                        <thead className="text-center p-2 thead-light">
                            <tr>
                                <th className="p-2">{IMLocalized('from_date')}</th>
                                <th className="p-2">{IMLocalized('to_date')}</th>
                                <th className="p-2">{IMLocalized('department')}</th>
                                <th className="p-2">{IMLocalized('section')}</th>
                                <th className="p-2">{IMLocalized('job_title')}</th>
                                <th className="p-2">{IMLocalized('job_level')}</th>
                                <th className="p-2">{IMLocalized('cost_center')}</th>
                                <th className="p-2">{IMLocalized('basic_pay')}</th>
                                <th className="p-2">{IMLocalized('payslip_gross_daily')}</th>
                                <th className="p-2">{IMLocalized('payslip_gross_month')}</th>
                                <th className="p-2">{IMLocalized('payslip_actual_work_days')}</th>
                            </tr>
                        </thead>
                        <tbody className="text-center p-2">
                            {props.emp_payslip.payslipcontract.map((item,index)=>{

                                return(
                                    <tr key={index}>
                                        <td className="p-2">{getDateFormat(item.from_date)}</td>
                                        <td className="p-2">{getDateFormat(item.to_date)}</td>
                                        <td className="p-2">{item.contract?.department ?? '-'}</td>
                                        <td className="p-2">{item.contract?.section ?? '-'}</td>
                                        <td className="p-2">{item.contract?.job_title ?? '-'}</td>
                                        <td className="p-2">{item.contract?.job_level ?? '-'}</td>
                                        <td className="p-2">{item.contract?.cost_center ?? '-'}</td>
                                        <td className="p-2">{item.contract?.pay ? `$${(item.contract.pay).toFixed(2)}` : '-'}</td>
                                        <td className="p-2">{item.gross_daily ? `$${(item.gross_daily).toFixed(2)}` : '-'}</td>
                                        <td className="p-2">
                                            {item.gross_ow ? `$${(item.gross_ow).toFixed(2)}` : '-'} <i className="fas fa-info-circle text-primary cursor-pointer" 
                                            onClick={()=>{
                                                const {payslipcontract} = props.emp_payslip;
                                                const {actual_work_days,month_total_work_days,gross_month} = payslipcontract[index];
                                                setCalculateDetail({actual_work_days,month_total_work_days,gross_month});
                                                setCalculateToggle(true);
                                            }}
                                        />
                                        </td>
                                        <td className="p-2">{item.actual_work_days}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>}
                </Card>
            </Col>
            <Col lg="6">
                {col1}
            </Col>
            <Col lg="6">
                {col2}
            </Col>
        </Row>
        <ModalCalculate toggle={calculate_toggle} detail={calculate_detail} closeModal={()=>{setCalculateToggle(false); setCalculateDetail(null)}}  />
        </>
    )
}
export default EarningTable;