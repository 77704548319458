import * as createemployee from '../../action/employee/create_employee';


const initialState ={
    isLoading:false,
    errors:null,
    address_errors:[],
    data:null
}


const create_employee= (state=initialState,action) =>{
    switch(action.type) {
        case createemployee.CREATEEMPLOYEE_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case createemployee.CREATEEMPLOYEE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case createemployee.CREATEEMPLOYEE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case createemployee.VALIDATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
            default:
                    return state;
    }
}
export default create_employee;