import * as nextprev from '../../action/employee/get_nextprev_employee';

const initialState ={
    data:[],
    nextid:'',
    nextname:'',
    prevname:'',
    previd:'',
    searchlist:[],
    error:{},
    isLoading:false,
    isLoaded:false
}


const get_nextprev_employee = (state=initialState,action) =>{
    switch(action.type) {
        case nextprev.NEXTPREV_REQUEST:
            return{
                ...state,
                isLoading: true,
                error:{}
            }
        case nextprev.NEXTPREV_SUCCESS:
            return{
                ...state,
            data: action.payload.data,
            nextid:action.payload.data.quickswitch.next.id,
            nextname:action.payload.data.quickswitch.next.name,
            prevname:action.payload.data.quickswitch.prev.name,
            previd:action.payload.data.quickswitch.prev.id,
            searchlist:action.payload.data.quickswitch.others,
            error:{},
            isLoading:false,
            isLoaded:true
        }
        case nextprev.NEXTPREV_FAILURE:
            return{
                ...state,
                error:{},
                isLoading:false
            }
        default:
            return state;
    }
}
export default get_nextprev_employee;