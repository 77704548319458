import * as createholidaylist1 from '../../action/wizard/wizard_create_holiday_list';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const wizard_create_holiday_list = (state=initialState,action)=>{
    switch(action.type){
        case createholidaylist1.CREATEHOLIDAYLIST_REQUEST1:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case createholidaylist1.CREATEHOLIDAYLIST_SUCCESS1:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createholidaylist1.CREATEHOLIDAYLIST_FAILURE1:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default wizard_create_holiday_list;