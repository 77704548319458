import * as addcert from '../../action/employee/add_employee_certification';


const initialState={
    isLoading:false,
    data:null,
    errors:[]
}

const add_employee_certification = (state=initialState,action)=>{
    switch(action.type){
        case addcert.ADDEMPCERT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
            }
        case addcert.ADDEMPCERT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case addcert.ADDEMPCERT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default add_employee_certification;