import myaxios from '../../axios';

export const GETEMPMATERNITY_REQUEST = 'GETEMPMATERNITY_REQUEST';
export const GETEMPMATERNITY_SUCCESS = 'GETEMPMATERNITY_SUCCESS';
export const GETEMPMATERNITY_FAILURE = 'GETEMPMATERNITY_FAILURE';


export const get_employee_maternity_list =()=>{
    return dispatch=>{
        dispatch({
            type:'GETEMPMATERNITY_REQUEST'
        });

        myaxios.get(`leave/sg/maternity/`)
        .then((response)=>{
            dispatch({
                type:'GETEMPMATERNITY_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPMATERNITY_FAILURE',
                payload:error
            })
        })
    }
}