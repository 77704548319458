import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Col, Container, Input, Row, Table } from 'reactstrap';
import { amountRenderer } from '../../ag-grid_component/amountRenderer';
import { date2Renderer } from '../../ag-grid_component/date2Renderer';
import { isFirstColumn } from '../../ag-grid_component/isFirstColumn';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalUserApplyClaim from './modal/ModalUserApplyClaim';
import { claim_detail_get_claim_detail } from '../../../action/user/claim_detail_get_claim_detail';
import ModalViewClaimDetail from './modal/ModalViewClaimDetail';
import OverlayLoading from '../../loading_component/overlay_loading';
import Swal from 'sweetalert2';
import { reset_user_claim_detail_cancel_apply_claim, user_claim_detail_cancel_apply_claim } from '../../../action/user/user_claim_detail_cancel_apply_claim';
import { user_get_available_claim } from '../../../action/user/user_get_available_claim';
import { user_apply_claim_list } from '../../../action/user/user_apply_claim_list';
import { NewMainContext } from '../../../context/NewMainContext';
import { ClaimStatusRenderer } from '../../../func/ClaimStatusRenderer';

const mapStateToProps=(state)=>({
    claimlist:state.get_claim_list.data,
    availablelist:state.user_get_available_claim.data,
    colorlist:state.get_general_color.data,
    applylist:state.user_apply_claim_list.data,
    isLoading:state.user_apply_claim_list.isLoading,
    isLoading1:state.get_general_color.isLoading,
    picture:state.user_claim_detail_get_claim_picture.data,
    cancel_success:state.user_claim_detail_cancel_apply_claim.data,
})

const mapDispatchToProps=(dispatch)=>({
    user_claim_detail_cancel_apply_claim:(id,comment)=>{
        dispatch(user_claim_detail_cancel_apply_claim(id,comment))
    },
    user_get_available_claim:()=>{
        dispatch(user_get_available_claim())
    },
    user_apply_claim_list:()=>{
        dispatch(user_apply_claim_list())
    },
    claim_detail_get_claim_detail:(id)=>{
        dispatch(claim_detail_get_claim_detail(id))
    },
    reset_user_claim_detail_cancel_apply_claim:()=>{
        dispatch(reset_user_claim_detail_cancel_apply_claim())
    }
})

const initialState={
    claim_name:'',
    thislist:null,
    selected:'4',
    rowData:[],
    datalist:[],
    detail_id:null,
    detail_toggle:false,
}

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    suppressRowClickSelection:true,
    defaultColDef:{
        resizable:true,
        sortable:true,
    }
}

function UserClaimDetail(props){
    const { user_apply_claim_list,user_get_available_claim,cancel_success,reset_user_claim_detail_cancel_apply_claim} = props;
    const { action_toggle, activeTab } = useContext(NewMainContext);
    const [state,setState] = useState(initialState);
    const [apply_toggle,setApplyToggle] = useState(false);
    const [monthNames] = useState([IMLocalized("JAN"), IMLocalized("FEB"), IMLocalized("MAR"), IMLocalized("APR"), IMLocalized("MAY"), IMLocalized("JUN"),
    IMLocalized("JUL"), IMLocalized("AUG"), IMLocalized("SEP"), IMLocalized("OCT"), IMLocalized("NOV"), IMLocalized("DEC")]);

    useEffect(()=>{
        if(activeTab === '2' && cancel_success != null ){
            user_apply_claim_list();
            user_get_available_claim();
            reset_user_claim_detail_cancel_apply_claim();
        }
    },[cancel_success,user_apply_claim_list,user_get_available_claim,reset_user_claim_detail_cancel_apply_claim,activeTab])

    useEffect(()=>{
        
        const sort = props.applylist.filter(item=>item.claim_type.id === parseInt(props.claim_id))
        .sort((a,b) => new Date(b.claim_date) - new Date(a.claim_date));
        setState(prevState=>({
            ...prevState,
            datalist:sort
        }));

    },[props.applylist,props.claim_id])

    useEffect(()=>{
        if(props.claimlist.length !== 0 && props.claim_id != null && props.claim_id !=='' ){
            const detail = props.claimlist.find(item=>item.id === parseInt(props.claim_id));
            const claim_name = detail?.name ?? '';
            setState(prevState=>({...prevState,claim_name}))
        }
    },[props.claim_id,props.claimlist])

    useEffect(()=>{

        const detail = props.availablelist.find(item=>item.claim_type === parseInt(props.claim_id));
        setState(prevState=>({
            ...prevState,
            thislist:detail
        }))
    },[props.availablelist,props.claim_id])

    const onCellFocused=(params)=>{
        if(params?.column?.colDef?.field != null && params.column.colDef.field !== 'select' ){
            const focusedCell = gridOptions.api.getFocusedCell();
            const cellValue = gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex)
            const id = cellValue.data.id;
            props.claim_detail_get_claim_detail(id);
            setState(prevState=>({
                ...prevState,
                detail_toggle:true
            }))
        }
    }

    const cancelPending =()=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0 ){
            Swal.fire({
                title:IMLocalized('cancelling_claim'),
                input:'textarea',
                text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
                showCancelButton:true,
                cancelButtonText:IMLocalized('no!'),
                confirmButtonText:IMLocalized('yes!')
            })
            .then((result)=>{
                if(result.value != null){
                    const arr2 = data.map(item=>{
                        return item.id
                    })
                    props.user_claim_detail_cancel_apply_claim(arr2,result.value);
                }
            })
        }
    }

    let columnDefs = [];
    if(state.selected === '0'){
        columnDefs=[
            {
                headerName:IMLocalized('select'),
                pinned:'left',
                field:'select',
                width:150,
                headerCheckboxSelection: isFirstColumn,
                checkboxSelection: isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
                lockPosition: true
            },
            {
                headerName:IMLocalized('claim_date'),
                field:'claim_date',
                cellRendererFramework:date2Renderer
            },
            {
                headerName:IMLocalized('claim_amount'),
                field:'claim_amount',
                cellRendererFramework:amountRenderer
            },
            {
                headerName:IMLocalized('status'),
                field:'status',
                cellRendererFramework:ClaimStatusRenderer
            }
        ]
    }
    else{
        columnDefs = [
            {
                headerName:IMLocalized('claim_date'),
                field:'claim_date',
                cellRendererFramework:date2Renderer
            },
            {
                headerName:IMLocalized('claim_amount'),
                field:'claim_amount',
                cellRendererFramework:amountRenderer
            },
            {
                headerName:IMLocalized('status'),
                field:'status',
                cellRendererFramework:ClaimStatusRenderer
            }
        ]
    }

    const row1 = state.datalist.filter(item=>{
        if(state.selected === '0'){
            return item.status === 'PENDING'
        }
        else if(state.selected === '1'){
            return item.status === 'APPROVED'
        }
        else if(state.selected === '2'){
            return item.status === 'REJECTED'
        }
        else if(state.selected === '3'){
            return item.status === 'CANCELLED'
        }
        else if(state.selected === '5'){
            return item.status === 'PAID'
        }
        else if(state.selected === '6'){
            return item.status === 'PAYROLL'
        }
        return item;
    })

    const pinnedData = [];
    if(row1.length !== 0 ){
        const claim_amount = row1.reduce((sum,item)=>{
            return sum+item.claim_amount;
        },0);
        pinnedData.push({claim_amount,claim_date:IMLocalized('total')});
    }

    return(
        <>
        {props.isLoading || props.isLoading1 ? <OverlayLoading/>:
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>props.toHome()}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem><a href="/#" onClick={()=>props.toProfile()}>{IMLocalized('profile')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{state.claim_name}</BreadcrumbItem>
                    </Breadcrumb>
                    <div className='ml-2'>
                        <button className="btn btn-primary btn-sm" onClick={()=>props.toProfile()}> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                        <button onClick={()=>setApplyToggle(true)} className="btn btn-primary btn-sm">{IMLocalized('apply')}</button>
                    </div>
                </Col>
            </Row>
            <Row className='mt-2 justify-content-center'>
                <Col lg="10">
                    {state.thislist != null && state.thislist?.is_monthly === true &&
                    <>
                        <h3>{state.claim_name}</h3>
                        <Table size='sm' className='bg-white align-items-center table-bordered' responsive>
                            <thead className='text-center thead-light'> 
                                <tr>
                                    <th>{IMLocalized('MONTH')}</th>
                                    {state.thislist.remaining_monthly.map((item,index)=>{
                                        const month_name =  monthNames[index];
                                        const year = state.thislist.year;
                                        return(<th key={index}>{month_name} {year}</th>)
                                    })}
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                <tr>
                                    <td>{IMLocalized('amount')}</td>
                                    {state.thislist.remaining_monthly.map((item,index)=>{
                                        return <td key={index}>$ {item}</td>
                                    })}
                                </tr>
                            </tbody>
                        </Table>
                    </>}
                    {state.thislist != null && state.thislist.is_monthly === false &&
                    <>
                        <h3>{state.claim_name}</h3>
                        <Table size="sm" style={{width:'25%'}} className='table-bordered bg-white align-items-center' responsive>
                            <tbody className='text-center'>
                                <tr>
                                    <td>{IMLocalized('remaining')}</td>
                                    <td>$ {state.thislist.remaining}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </>}
                    <div className='mt-2 d-flex justify-content-between'>
                        <h3 className='mb-0'>
                            {state.selected === '4' && IMLocalized('all_claim_list')}
                            {state.selected === '0' && IMLocalized('pending_claim_list')}
                            {state.selected === '1' && IMLocalized('approved_claim_list')}
                            {state.selected === '2' && IMLocalized('rejected_claim_list')}
                            {state.selected === '3' && IMLocalized('cancelled_claim_list')}
                            {state.selected === '5' && IMLocalized('paid_claim_list')}
                            {state.selected === '6' && IMLocalized('payroll_paid_claim_list')}
                        </h3>
                        <div className='d-flex'>
                            {state.selected === '0' && <div className='mr-2'><button className='btn btn-danger btn-sm' onClick={()=>cancelPending()}>{IMLocalized('cancel')}</button></div>}
                            <Input type="select" size="sm" value={state.selected} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,selected:value}));
                            }}>
                                <option value="4">{IMLocalized('ALL')}</option>
                                <option value='0'>{IMLocalized('PENDING')}</option>
                                <option value="1">{IMLocalized('APPROVED')}</option>
                                <option value="2">{IMLocalized('REJECTED')}</option>
                                <option value="3">{IMLocalized('CANCELLED')}</option>
                                <option value="5">{IMLocalized('PAID')}</option>
                                <option value="6">{IMLocalized('PAYROLL')}</option>
                            </Input>
                        </div>
                    </div>
                    <div className='mt-2 ag-theme-balham' style={{height:action_toggle ? 'calc(100vh - 450px)' : 'calc(100vh - 414px)',width:'100%' }}>
                        <AgGridReact
                        columnDefs={columnDefs}
                        gridOptions={gridOptions}
                        rowData={row1}
                        suppressDragLeaveHidesColumns={true}
                        suppressSizeToFit={true}
                        suppressColumnMoveAnimation={false}
                        overlayNoRowsTemplate={IMLocalized('no_data')}
                        onCellFocused={onCellFocused}
                        pinnedBottomRowData={pinnedData}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
        <ModalViewClaimDetail toggle={state.detail_toggle} 
        closeModal={()=>{
            setState(prevState=>({...prevState,detail_toggle:false}))
            gridOptions.api.clearFocusedCell();
        
        }}  />
        <ModalUserApplyClaim toggle={apply_toggle} closeModal={()=>{setApplyToggle(false)}} has_claim={true} claim_id={props.claim_id} claim_name={state.claim_name} />
        </>}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(UserClaimDetail);