import myaxios from '../../axios';

export const GETAPPROVALSLEAVELIST_REQUEST = 'GETAPPROVALSLEAVELIST_REQUEST';
export const GETAPPROVALSLEAVELIST_SUCCESS = 'GETAPPROVALSLEAVELIST_SUCCESS';
export const GETAPPROVALSLEAVELIST_FAILURE = 'GETAPPROVALSLEAVELIST_FAILURE';

export const get_approvals_leave_list =()=>{
    return dispatch=>{
        dispatch({
            type:'GETAPPROVALSLEAVELIST_REQUEST'
        });

        myaxios.get('leave/all/apply/')
        .then((response)=>{
            dispatch({
                type:'GETAPPROVALSLEAVELIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETAPPROVALSLEAVELIST_FAILURE',
                payload:error
            })
        })
    }
}