import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEEMPDELEGATIONLIST_REQUEST = 'UPDATEEMPDELEGATIONLIST_REQUEST';
export const UPDATEEMPDELEGATIONLIST_SUCCESS = 'UPDATEEMPDELEGATIONLIST_SUCCESS';
export const UPDATEEMPDELEGATIONLIST_FAILURE = 'UPDATEEMPDELEGATIONLIST_FAILURE';
export const UPDATEEMPDELEGATIONLIST_RESET = 'UPDATEEMPDELEGATIONLIST_RESET';

export const update_employee_delegation_list = (id,start_date,end_date,delegation)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEEMPDELEGATIONLIST_REQUEST'
        });

        myaxios.patch(`contract/delegation/${id}/`,{
            start_date:start_date,
            end_date:end_date,
            delegation:delegation
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEEMPDELEGATIONLIST_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEEMPDELEGATIONLIST_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_employee_delegation_list = () => dispatch =>{
    dispatch({
        type:'UPDATEEMPDELEGATIONLIST_RESET'
    })
}