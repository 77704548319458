import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';

const mapStateToProps=(state)=>({
    claimschemelist:state.get_claim_employee_noscheme.data,
    isLoading:state.get_claim_employee_noscheme.isLoading,
})

function ClaimNoSchemeTable(props){

    return(
        <Row>
            <Col>
                <Card style={{height:'270px'}}>
                    <CardHeader className="border-0 p-2 bg-white" >
                        <h3 className='title-color1 pl-2 pt-2'>{IMLocalized('employee_claim_scheme')}</h3>
                    </CardHeader>
                    {props.isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                    {!props.isLoading && props.claimschemelist.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                    {!props.isLoading && props.claimschemelist.length !== 0 && 
                    <Table className="align-items-center" size="sm" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th>{IMLocalized('emp_id')}</th>
                                <th>{IMLocalized('name')}</th>
                                <th className="text-center">{IMLocalized('scheme')}</th>
                                <th className="text-center">{IMLocalized('approval')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.claimschemelist.map((item,index)=>{
                                return(
                                    <tr key={index}>
                                        <td>{item.employee_number}</td>
                                        <td>{item.name}</td>
                                        <td className='text-center'><span className="mr-2" style={{color:item.scheme ? 'green' : 'red' }}>●</span></td>
                                        <td className='text-center'><span className="mr-2" style={{color:item.approval ? 'green' : 'red' }}>●</span></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>}
                </Card>
            </Col>
        </Row>
    )
}
export default connect(mapStateToProps,null)(ClaimNoSchemeTable);