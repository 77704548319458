import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATECOSTCENTER_SUCCESS = 'CREATECOSTCENTER_SUCCESS';
export const CREATECOSTCENTER_REQUEST = 'CREATECOSTCENTER_REQUEST';
export const CREATECOSTCENTER_FAILURE = 'CREATECOSTCENTER_FAILURE';
export const CREATECOSTCENTER_RESET = 'CREATECOSTCENTER_RESET';

export const create_costcenter = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATECOSTCENTER_REQUEST'
        });
        
        myaxios.post(`company/costcenter/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'CREATECOSTCENTER_SUCCESS',
                payload:response
                
            });
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            })
        })
        .catch((error) =>{
            dispatch({
                type: 'CREATECOSTCENTER_FAILURE',
                payload: error,
                error:true
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_costcenter = () =>dispatch=>{
    dispatch({
        type:'CREATECOSTCENTER_RESET'
    });
}