import * as wizarvalidatesection from '../../action/wizard/wizard_validate_section';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const wizard_validate_section = (state=initialState,action)=>{
    switch(action.type){
        case wizarvalidatesection.WIZARDVALIDATESECTION_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case wizarvalidatesection.WIZARDVALIDATESECTION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case wizarvalidatesection.WIZARDVALIDATESECTION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default wizard_validate_section;