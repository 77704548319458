import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CLAIMAPPROVAL2_REQUEST = 'CLAIMAPPROVAL2_REQUEST';
export const CLAIMAPPROVAL2_SUCCESS = 'CLAIMAPPROVAL2_SUCCESS';
export const CLAIMAPPROVAL2_FAILURE = 'CLAIMAPPROVAL2_FAILURE';


export const create_claim_approval2=(data)=>{
    return dispatch=>{
        dispatch({
            type:'CLAIMAPPROVAL2_REQUEST'
        });

        myaxios.post('employee/approval/create/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'CLAIMAPPROVAL2_SUCCESS',
                payload:response
            });

            Toast.fire({
                type:'success',
                title:IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CLAIMAPPROVAL2_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}