import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import '../../../css/aggrid.css';
import { get_leave_award_list } from "../../../action/employee/get_leave_award_list";
import { date2Renderer } from "../../ag-grid_component/date2Renderer";
import { NewMainContext } from "../../../context/NewMainContext";
import { emp_get_leave_award_list } from "../../../reducers/employee/get_leave_award_list";
import { get_employee_list_detail } from "../../../reducers/employee/get_employee_list";
import BreadCrumbList from "../../breadcrumb/BreadCrumbList";

export default function EmployeeAwardList({ toEmployeeList, toEmployeeProfile, id}){
    
    const {action_toggle, addItem} = useContext(NewMainContext);
    const emp_detail = useSelector(state=>get_employee_list_detail(state,parseInt(id)));
    const dispatch = useDispatch();
    const rowData = useSelector(state=>emp_get_leave_award_list(state,parseInt(id)))
    const columnDefs =[
        {
            headerName:IMLocalized('from_date'),
            field:'date_range.lower',
            cellRendererFramework:date2Renderer
        },
        {
            headerName:IMLocalized('to_date'),
            field:'date_range.upper',
            cellRendererFramework:date2Renderer
        },
        {
            headerName:IMLocalized('leave_type'),
            field:'leave_name',
        },
        {
            headerName:IMLocalized('leave_amount'),
            field:'amount'
        },
        {
            headerName:IMLocalized('remaining'),
            field:'remaining'
        }
    ];

    useEffect(()=>{
        dispatch(get_leave_award_list());
    },[dispatch])

    return(
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('employee'), onClick:()=>toEmployeeList('0')},
                        { active: false, title:emp_detail?.name ?? '', onClick:()=>toEmployeeProfile('1')},
                        { active: true, title:IMLocalized('awarded_leave_list')}
                    ]}/>
                    <div className="pl-2">
                        <button className="btn btn-primary btn-sm" onClick={()=>toEmployeeProfile('1')}><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2 d-flex justify-content-center">
                <Col lg="8">
                    <h2 className="mb-0">{IMLocalized('awarded_leave_list')}</h2>
                    <div className='ag-theme-balham' style={{height: action_toggle ? 'calc(100vh - 280px)' : 'calc(100vh -244px)' , width:'100%' }}>
                        <AgGridReact
                        columnDefs={columnDefs}
                        rowData={rowData}
                        overlayNoRowsTemplate={IMLocalized('no_data')}
                        >
                        </AgGridReact>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}