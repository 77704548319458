import memoize from 'lodash.memoize';
import i18n from 'i18n-js';
import En from './en.json';
import Zh from './zh.json';

export const translationGetters ={
    'en':En,
    'zh':Zh,
}

export const IMLocalized = memoize(
    (key, config) =>
      i18n.t(key, config).includes('missing') ? key : i18n.t(key, config),
    (key, config) => (config ? key + JSON.stringify(config) : key),
);

export const init = (string) => {
    
    i18n.translations = {
      [string]: translationGetters[string],
    };
    i18n.locale = string;
  };