import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormFeedback, FormGroup, Modal, ModalBody, ModalHeader, Row,Input,Table, ModalFooter } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { update_schedule } from '../../../action/update_schedule';
import { usePrevious } from '../../../hook/usePrevious';

const mapStateToProps=(state)=>({
    holidaylist:state.get_holiday_list.data,
    choices:state.schedule_dropdown.calendar_choices,
    calendar_key:state.schedule_dropdown.calendar_key,
    isLoading:state.update_schedule.isLoading,
    success:state.update_schedule.data,
})

const mapDispatchToProps = (dispatch)=>({
    update_schedule:(id,name,start_year,working_hours,hours_per_year,calendar_array,holiday_list)=>{
        dispatch(update_schedule(id,name,start_year,working_hours,hours_per_year,calendar_array,holiday_list))
    }
})

function ModalEditSchedule(props){
    const {success,onSuccess} = props;
    const [name,setName] = useState('');
    const [start_year,setStartYear] = useState('');
    const [working_hours,setWorkingHours] = useState('');
    const [err_working_hours,setErrWorkingHours] = useState('');
    const [holiday_list,setHolidayList] = useState('');
    const [err_holiday_list,setErrHolidayList] = useState('');
    const [hour_per_year,setHourPerYear] = useState('');
    const [hour_per_year_value,setHourValue] = useState('');
    const [err_hour_per_year_value,setErrHourValue] = useState('');
    const monthNames = [IMLocalized("january"), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")];
    const [display,setDisplay] = useState([]);
    const [array1,setArray1] = useState([]);
    const [array2,setArray2] = useState([]);
    const [array3,setArray3] = useState([]);
    const [array4,setArray4] = useState([]);
    const prevSuccess = usePrevious(success);

    useEffect(()=>{
        if(props.toggle && props.detail){
            setName(props.detail.name);
            setWorkingHours(props.detail.working_hours);
            setStartYear(props.detail.starting_year);
            setDisplay(props.detail.display);
            setHolidayList(props.detail.holiday_list);
            setErrWorkingHours('');
            setErrHolidayList('');
            setErrHourValue('');

           
            if(props.detail.hours_in_year !== 2288 && props.detail.hours_in_year !== 2080 ){
                setHourPerYear('custom');
                setHourValue(props.detail.hours_in_year.toString())
            }
            else{
                setHourValue('');
                setHourPerYear(props.detail.hours_in_year.toString())
            }
            if(props.detail.calendar_array){
                const arr1 = props.detail.calendar_array[0].map((item,index)=>{
                    return {id:index,value:item}
                });
                setArray1(arr1);

                const arr2 = props.detail.calendar_array[1].map((item,index)=>{
                    return {id:index,value:item}
                });
                setArray2(arr2);

                const arr3 = props.detail.calendar_array[2].map((item,index)=>{
                    return {id:index,value:item}
                });
                setArray3(arr3);

                const arr4 = props.detail.calendar_array[3].map((item,index)=>{
                    return {id:index,value:item}
                });
                setArray4(arr4);
            }
        }
    },[props.toggle,props.detail])

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== success && success !== null && props.toggle){
            onSuccess();
        }
    },[prevSuccess,success,props.toggle,onSuccess])

    const changeDay1 = (id)=>{
        const detail = array1.find((item)=>item.id === id);
        const data = detail.value;
        const number = props.calendar_key.indexOf(data);
        if(number+1 !== props.calendar_key.length){
            const a = number + 1;
            const value = props.calendar_key[a];
            const new_item = array1.map((item)=>
            item.id === id
            ?{...item,value}
            :item)
            setArray1(new_item);
        }
        else if (number +1 === props.calendar_key.length){
            const a = 0;
            const value = props.calendar_key[a];
            const new_array = array1.map(item=>
                item.id === id
                ?{...item,value:value}
                :item    
            )
            setArray1(new_array)
            
        }
    }

    const changeDay2 = (id)=>{
        const detail = array2.find((item)=>item.id === id);
        const data = detail.value;
        const number = props.calendar_key.indexOf(data);
        if(number+1 !== props.calendar_key.length){
            const a = number + 1;
            const value = props.calendar_key[a];
            const new_item = array2.map((item)=>
            item.id === id
            ?{...item,value}
            :item)
            setArray2(new_item);
        }
        else if (number +1 === props.calendar_key.length){
            const a = 0;
            const value = props.calendar_key[a];
            const new_array = array2.map(item=>
                item.id === id
                ?{...item,value:value}
                :item    
            )
            setArray2(new_array)
            
        }
    }

    const changeDay3 = (id)=>{
        const detail = array3.find((item)=>item.id === id);
        const data = detail.value;
        const number = props.calendar_key.indexOf(data);
        if(number+1 !== props.calendar_key.length){
            const a = number + 1;
            const value = props.calendar_key[a];
            const new_item = array3.map((item)=>
            item.id === id
            ?{...item,value}
            :item)
            setArray3(new_item);
        }
        else if (number +1 === props.calendar_key.length){
            const a = 0;
            const value = props.calendar_key[a];
            const new_array = array3.map(item=>
                item.id === id
                ?{...item,value:value}
                :item    
            )
            setArray3(new_array)
            
        }
    }

    const changeDay4 = (id)=>{
        const detail = array4.find((item)=>item.id === id);
        const data = detail.value;
        const number = props.calendar_key.indexOf(data);
        if(number+1 !== props.calendar_key.length){
            const a = number + 1;
            const value = props.calendar_key[a];
            const new_item = array4.map((item)=>
            item.id === id
            ?{...item,value}
            :item)
            setArray4(new_item);
        }
        else if (number +1 === props.calendar_key.length){
            const a = 0;
            const value = props.calendar_key[a];
            const new_array = array4.map(item=>
                item.id === id
                ?{...item,value:value}
                :item    
            )
            setArray4(new_array)
            
        }
    }

    const calendar1 = ()=>{
        let arr = [];
        if(array1.length !== 0 &&props.choices.length !== 0){
            arr = array1.map((item)=>{
                const id = item.id;
                const value = item.value;
                if(value in props.choices){
                    const result = props.choices[value];
                    const table = 'cursor-pointer ' + result.table;
                    const name = result.name;
                    return <td id={id} style={{padding:'0.75rem 0'}} className={table} key={id} onClick={()=>changeDay1(id)}>{name}</td>
                }
                return false
            })
        }
        return arr;
    }

    const calendar2 = ()=>{
        let arr = [];
        if(array2.length !== 0 &&props.choices.length !== 0){
            arr = array2.map((item)=>{
                const id = item.id;
                const value = item.value;
                if(value in props.choices){
                    const result = props.choices[value];
                    const table = 'cursor-pointer ' + result.table;
                    const name = result.name;
                    return <td id={id} style={{padding:'0.75rem 0'}} className={table} key={id} onClick={()=>changeDay2(id)}>{name}</td>
                }
                return false
            })
        }
        return arr;
    }

    const calendar3 = ()=>{
        let arr = [];
        if(array3.length !== 0 &&props.choices.length !== 0){
            arr = array3.map((item)=>{
                const id = item.id;
                const value = item.value;
                if(value in props.choices){
                    const result = props.choices[value];
                    const table = 'cursor-pointer ' + result.table;
                    const name = result.name;
                    return <td id={id} style={{padding:'0.75rem 0'}} className={table} key={id} onClick={()=>changeDay3(id)}>{name}</td>
                }
                return false
            })
        }
        return arr;
    }

    const calendar4 = ()=>{
        let arr = [];
        if(array3.length !== 0 &&props.choices.length !== 0){
            arr = array4.map((item)=>{
                const id = item.id;
                const value = item.value;
                if(value in props.choices){
                    const result = props.choices[value];
                    const table = 'cursor-pointer ' + result.table;
                    const name = result.name;
                    return <td id={id} style={{padding:'0.75rem 0'}} className={table} key={id} onClick={()=>changeDay4(id)}>{name}</td>
                }
                return false
            })
        }
        return arr;
    }

    const calendar_day = ()=>{
        let arr = [];
        for(let i =1;i<32;i++){
            arr.push(<th style={{padding:'0px'}} key={i}>{i}</th>);
        }

        return arr;
    }

    const calendar_month=()=>{
        const date = new Date();
        const year = date.getFullYear();
        let arr = [];
        if(display.length !== 0){
            if(year in display){
                const result = display[year];
                const keys_range = Object.keys(result).length;
                const qoqo = Object.values(result);
                for(let i =0;i<keys_range;i++){
                    const mon = monthNames[i];
                    const data_range = qoqo[i].length;
                    let month = [];
                    for (let j=0;j<data_range;j++){
                        const a = qoqo[i][j];
                        if(a in props.choices){
                            const result = props.choices[a];
                            const table = result.table;
                            const name = result.short;
                            month.push(<td className={table} style={{padding:'0px'}} key={'keys'+i+'date'+j} ><span>{name}</span></td>)
                        }
                    }
                    arr.push(<tr key={i}><th>{mon}</th>{month}</tr>)
                }
            }
        }
        return arr;
    }

    const calculation = () =>{
        let amount = 0;
        let hour = 0;
        array1.forEach(item=>{
            const a = item.value;
            if(a in props.choices){
                const result = props.choices[a].amount;
                hour += result;
            }
        })

        array2.forEach(item=>{
            const a = item.value;
            if(a in props.choices){
                const result = props.choices[a].amount;
                hour += result;
            }
        })

        array3.forEach(item=>{
            const a = item.value;
            if(a in props.choices){
                const result = props.choices[a].amount;
                hour += result;
            }
        })

        array4.forEach(item=>{
            const a = item.value;
            if(a in props.choices){
                const result = props.choices[a].amount;
                hour += result;
            }
        })

        if(hour !== 0){
            amount = hour/4;
        }

        return amount;
    }

    const updateSchedule = ()=>{
        let value = 0;
        let invalid = false;
        if(hour_per_year === 'custom'){
            if(hour_per_year_value !== ''){
                value = hour_per_year_value;
            }
            else{
                setErrHourValue(IMLocalized('hours_per_year_value_is_required'))
                invalid = true;
            }
        }
        else{
            value = hour_per_year;
        }

        if(!invalid){
            if(working_hours !== '' && holiday_list !== ''){
                const arr1 = array1.map((item)=>item.value) 
                const arr2 = array2.map((item)=>item.value);
                const arr3 = array3.map((item)=>item.value);
                const arr4 = array4.map((item)=>item.value);
                let arr = [];
                arr.push(arr1);
                arr.push(arr2);
                arr.push(arr3);
                arr.push(arr4);
                props.update_schedule(props.detail.id,name,start_year,working_hours,value,arr,holiday_list);
            }
            else{
                if(working_hours === ''){
                    setErrWorkingHours(IMLocalized('working_hours_is_required'));
                }
                if(holiday_list === ''){
                    setErrHolidayList(IMLocalized('holiday_list_is_required'));
                }
            }
        }
    }

    return(
        <Modal isOpen={props.toggle} className="modal-dialog-centered" style={{maxWidth: '1600px', width: '80%'}} size="lg">
            <ModalHeader toggle={props.closeModal}>{IMLocalized('edit_schedule')} - {name}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('schedule_name')}</label>
                            <Input type="text" id="name" name="name" size="sm" value={name} onChange={(e)=>setName(e.target.value)} readOnly />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='start_year'>{IMLocalized('start_year')}</label>
                            <Input id="start_year" size="sm" type="number" name="start_year" pattern="(20)\d\d" value={start_year} onChange={(e)=>setStartYear} readOnly />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='working_hours'>{IMLocalized('working_hours')}</label>
                            <Input size="sm" name="working_hours" type="number" value={working_hours} onChange={(e)=>{setWorkingHours(e.target.value) ; setErrWorkingHours('')}} invalid={err_working_hours.length >0} />
                            <FormFeedback>{err_working_hours}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='holiday_list'>{IMLocalized('holiday_list')}</label>
                            <Input size="sm" name="holiday_list" id="holiday_list" type="select" value={holiday_list} onChange={(e)=>{setHolidayList(e.target.value); setErrHolidayList('')}} invalid={err_holiday_list.length >0 } >
                                <option></option>
                                {props.holidaylist.map((item,index)=>{
                                    return <option value={item.id} key={index}>{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_holiday_list}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='working_hours_in_year'>{IMLocalized('working_hours_in_year')}</label>
                            <div className='d-flex align-items-center'>
                                <div className='custom-control custom-radio'>
                                    <input
                                    className='custom-control-input'
                                    id="edit_schedule_custom_radio1"
                                    name="editschedulecustomRadio"
                                    type="radio"
                                    value="2288"
                                    checked= {hour_per_year === '2288'}
                                    onChange={(e)=>setHourPerYear(e.target.value)}
                                    />
                                    <label className='custom-control-label' htmlFor='edit_schedule_custom_radio1'>
                                        2288
                                    </label>
                                </div>
                                <div className='custom-control custom-radio ml-2'>
                                    <input
                                    className='custom-control-input'
                                    id="edit_schedule_custom_radio2"
                                    name="editschedulecustomRadio"
                                    type="radio"
                                    value="2080"
                                    checked = {hour_per_year === '2080'}
                                    onChange={(e)=>{setHourPerYear(e.target.value)}}
                                    />
                                    <label className='custom-control-label' htmlFor='edit_schedule_custom_radio2'>
                                        2080
                                    </label>
                                </div>
                                <div className='custom-control custom-radio ml-2'>
                                    <input
                                    className='custom-control-input'
                                    id="edit_schedule_custom_radio3"
                                    name="editschedulecustomRadio"
                                    type="radio"
                                    value="custom"
                                    checked={hour_per_year === 'custom'}
                                    onChange={(e)=>{setHourPerYear(e.target.value)}}
                                    />
                                    <label className='custom-control-label' htmlFor='edit_schedule_custom_radio3'>
                                        {IMLocalized('CUSTOM')}
                                    </label>
                                </div>
                                {hour_per_year === 'custom' && 
                                    <FormGroup className="mb-0 ml-2">
                                        <Input size="sm" type="number" min="0" name="hour_per_year_value" value={hour_per_year_value} onChange={(e)=>{setHourValue(e.target.value); setErrHourValue('') }}  invalid={err_hour_per_year_value.length >0}/>
                                        <FormFeedback>{err_hour_per_year_value}</FormFeedback>
                                    </FormGroup>
                                }
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <div>
                            <label className='form-control-label'>{IMLocalized('work_days_in_week')} : </label> <span>{calculation()}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col>
                        <h4>{IMLocalized('calendar_table')}</h4>
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col>
                        <Table size="sm" className="align-items-center table-bordered" responsive>
                            <thead className='text-center'>
                                <tr>
                                    <th>{IMLocalized('WEEK')}</th>
                                    <th>{IMLocalized('MONDAY')}</th>
                                    <th>{IMLocalized('TUESDAY')}</th>
                                    <th>{IMLocalized('WEDNESDAY')}</th>
                                    <th>{IMLocalized('THURSDAY')}</th>
                                    <th>{IMLocalized('FRIDAY')}</th>
                                    <th>{IMLocalized('SATURDAY')}</th>
                                    <th>{IMLocalized('SUNDAY')}</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                <tr>
                                    <th>{IMLocalized('WEEK')} 1</th>
                                    {calendar1()}
                                </tr>
                                <tr>
                                    <th>{IMLocalized('WEEK')} 2</th>
                                    {calendar2()}
                                </tr>
                                <tr>
                                    <th>{IMLocalized('WEEK')} 3</th>
                                    {calendar3()}
                                </tr>
                                <tr>
                                    <th>{IMLocalized('WEEK')} 4</th>
                                    {calendar4()}
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{IMLocalized('schedule')} - {name}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table size="sm" className="align-items-center table-bordered" responsive>
                            <thead className="text-center">
                                <tr>
                                    <th>{IMLocalized('MONTH')}</th>
                                    {calendar_day()}
                                </tr>
                            </thead>
                            <tbody className="text-center">
                                {calendar_month()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {props.isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <div>
                    <button onClick={updateSchedule} className="btn btn-primary btn-sm">{IMLocalized('update')}</button>
                    <button onClick={props.closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </div>}
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalEditSchedule);