import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Table} from 'reactstrap';
import { IMLocalized} from '../../../language/IMLocalized';
import ModalUserApplyLeave from './modal/ModalUserApplyLeave';


const mapStateToProps=(state)=>({
    availablelist:state.user_get_available_leave.data,
    leavelist:state.get_leave_list.data,
    data:state.user_self.data,
    approval_group:state.user_leave_approval_group.data,
    isLoading:state.user_get_available_leave.isLoading,
})

function UserEmployeeLeave(props){

    const [leave_approvers,setLeaveApprovers] = useState([]);
    const [leave_exceptions,setLeaveExceptions] = useState([]);
    const [approval_name,setApprovalName] = useState('');
    const [leave_id,setLeaveID] = useState(null);
    const [leave_name,setLeaveName] = useState(null);
    const [apply_toggle,setApplyToggle] = useState(false);

    const approvalRef = useRef();

    useEffect(()=>{
        if(props.approval_group != null){
            const {exceptions,approvers,name} = props.approval_group;
            setLeaveApprovers(approvers);
            setLeaveExceptions(exceptions);
            setApprovalName(name);
        }
    },[props.approval_group])

    return(
        <>
        <div className="d-flex justify-content-end align-items-center text-primary pt-1 pr-2">
            <div className="cursor-pointer"  onClick={()=>approvalRef.current.scrollIntoView({block:'start',behavior:'smooth'})} >
                <i className="fas fa-angle-down mr-1 function_icon"/>
                <small>{IMLocalized('scroll_down')}</small>
            </div>
        </div>
        <div className='d-flex align-items-center pl-2 emp-tabcontent-title'>
            <i className='far fa-calendar-alt text-primary' />
            <h4 className='text-primary pl-2 m-0'>{IMLocalized('employee_leave')}</h4>
        </div>
        {props.isLoading && <h4 className='p-2'>{IMLocalized('loading')}..</h4>}
        {!props.isLoading && props.availablelist.length === 0 && <h4 className='p-2'>{IMLocalized('no_leave_scheme_found')}</h4>}
        {!props.isLoading &&props.availablelist.length !== 0 && 
        <>
        <Table className='mt-2 align-items-center table-bordered' size="sm" responsive>
            <thead className='thead-light text-center'>
                <tr>
                    <th>{IMLocalized('leave_type')}</th>
                    <th>{IMLocalized('current_balance')}</th>
                    <th>{IMLocalized('apply')}</th>
                </tr>  
            </thead>
            <tbody className='text-center'>
                {props.availablelist.map((item,index)=>{
                    const current_month = new Date().getMonth();
                    const current_remaining = item.leave_type === 'E' ? item.remaining_monthly[current_month] : item.remaining;
                    const detail = props.leavelist.find(el=>el.id === item.leave_setting);
                    
                    return(
                        <tr key={index}>
                            <td><span className='text-primary cursor-pointer' onClick={()=>props.toLeaveDetail(item.leave_setting)}>{detail?.name ?? '-'}</span></td>
                            <td>{parseFloat(current_remaining.toFixed(2))}</td>
                            <td>{parseFloat(current_remaining) <= 0 ? '-' : <button className='btn btn-primary btn-sm' onClick={()=>{setApplyToggle(true); setLeaveID(item.leave_setting); setLeaveName(detail?.name ?? null)}}>{IMLocalized('apply')}</button> }</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
        </>}
        <div className='d-flex justify-content-between align-items-center mt-2 pl-2 emp-tabcontent-title'>
            <div className='d-flex align-items-center'>
                <i className='far fa-calendar-alt text-primary' />
                <h4 className='text-primary pl-2 m-0'>{IMLocalized('employee_leave_scheme')}</h4>
            </div>
        </div>
        <h4 className='p-2'>
            {props.data.length !== 0 && props.data?.metadata?.leave_scheme?.name ? props.data.metadata.leave_scheme.name : IMLocalized('no_data')}
        </h4>
        <div ref={approvalRef}>
            <div className='d-flex align-items-center pl-2 emp-tabcontent-title'>
                <i className='far fa-calendar-alt text-primary' />
                <h4 className='text-primary pl-2 m-0'>{IMLocalized('leave_approval')}</h4>
            </div>
        </div>
        {leave_approvers.length === 0 && <h4 className='p-2'>{IMLocalized('no_data')}</h4>}
        {leave_approvers.length !== 0 && leave_exceptions.length !== 0 && 
        <>
            <h4 className='p-2'>{approval_name}</h4>
            {leave_approvers.map((item,index)=>{
                const group = leave_exceptions.filter(el=>el.group === index);
                const arr_table = group.map((el,index1)=>{
                    const detail = props.leavelist.find((item1)=>item1.id === el.leave_setting);
                    return(
                        <div key={'exception'+index1} className="pt-1"><span className="mr-2" >●</span><span>{detail?.name ?? '-'}</span></div>
                    )
                })

                const arr_approvers = item.map((data,index1)=>{
                    const data1 = data.map((item1,index2)=>{
                        return <div key={'approvers'+index2}><span>{item1.name} - ({IMLocalized('level')} : {index1+1})</span></div>
                    })

                    return data1;
                })

                if(arr_table.length !== 0 ){
                    return(
                        <div className='px-2' key={index}>
                            <h4>{IMLocalized('group')} {index+1}</h4>
                            <Table size="sm" className='table-bordered' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th className='w-50'>{IMLocalized('leave_type')}</th>
                                        <th>{IMLocalized('approval_level')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{arr_table}</td>
                                        <td>{arr_approvers}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )
                }
                return null;
            })}
        </>}
        <ModalUserApplyLeave toggle={apply_toggle} leave_id={leave_id} leave_name={leave_name}  has_leave={true}
        closeModal={()=>{
            setApplyToggle(false);
            setLeaveID(null);
            setLeaveName(null);
        }}  />
        </>
    )
}
export default connect(mapStateToProps,null)(UserEmployeeLeave);