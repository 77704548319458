import * as aggridheader from '../../action/payroll/payroll_detail_get_aggridheader';


const initialState={
    data:[],
    isLoading:false,
    error:[]
}

const payroll_detail_get_aggridheader = (state=initialState,action)=>{
    switch(action.type){
        case aggridheader.PAYROLLAGGRIDHEADER_REQUEST:
            return{
                ...state,
                isLoading:true,
                error:[]
            }
        case aggridheader.PAYROLLAGGRIDHEADER_SUCCESS:
            return{
                ...state,
                data:action.payload.data,
                isLoading:false,
                error:[],
                
            }
        case aggridheader.PAYROLLAGGRIDHEADER_FAILURE:
            return{
                ...state,
                isLoading:false,
                data:[],
                error:action.payload
            }
        default:
            return state;
    }
}
export default payroll_detail_get_aggridheader;