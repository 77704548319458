import myaxios from '../axios';

export const PAYROLLPERIOD_REQUEST ='PAYROLLPERIOD_REQUEST';
export const PAYROLLPERIOD_SUCCESS = 'PAYROLLPERIOD_SUCCESS';
export const PAYROLLPERIOD_FAILURE = 'PAYROLLPERIOD_FAILURE';

export const get_payroll_period = ()=>{
    return dispatch=>{
        dispatch({
            type:'PAYROLLPERIOD_REQEEST'
        });

        myaxios.get('payroll/basesetting/')
        .then((response)=>{
            dispatch({
                type:'PAYROLLPERIOD_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'PAYROLLPERIOD_FAILURE',
                payload:error
            })
        })
    }
}