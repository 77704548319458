import myaxios from '../../axios';

export const DOWNLOADPAYOUT_REQUEST = 'DOWNLOADPAYOUT_REQUEST';
export const DOWNLOADPAYOUT_SUCCESS = 'DOWNLOADPAYOUT_SUCCESS';
export const DOWNLOADPAYOUT_FAILURE = 'DOWNLOADPAYOUT_FAILURE';

export const payroll_printpayout = (id,idd,name)=>{
    return dispatch=>{
        dispatch({
            type:'DOWNLOADPAYOUT_REQUEST'
        });
        
        myaxios({
            url:`payroll/payroll/${id}/DownloadPayout/?payout=${idd}`,
            method:'GET',
            responseType:'blob'
        })
        .then((response)=>{
            const hello = `${name}.zip`;
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a');
            link.href= url;
            link.setAttribute('download',hello);
            document.body.appendChild(link);
            link.click();
            dispatch({
                type:'DOWNLOADPAYOUT_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'DOWNLOADPAYOUT_FAILURE',
                payload:error
            })
        })
    }
}