import myaxios from "../../axios";

export const GETEMPSHG_REQUEST = 'GETEMPSHG_REQUEST';
export const GETEMPSHG_SUCCESS = 'GETEMPSHG_SUCCESS';
export const GETEMPSHG_FAILURE = 'GETEMPSHG_FAILURE';

export const get_employee_shg = (id)=>{
    return dispatch=>{

        dispatch({
            type:'GETEMPSHG_REQUEST'
        })

        myaxios.get(`employee/employees/${id}/SHG/`)
        .then((response)=>{
            dispatch({
                type:'GETEMPSHG_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPSHG_FAILURE',
                payload:error
            })
        })
    }
}