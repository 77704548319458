import * as selfavailableleave from '../../action/user/get_self_available_leave_options';

const initialState={
    isLoading:false,
    errors:null,
    data:null,
}

const get_self_available_leave_options = (state=initialState,action)=>{
    switch(action.type){
        case selfavailableleave.GETSELFLEAVEOPTION_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case selfavailableleave.GETSELFLEAVEOPTION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data,
            }
        case selfavailableleave.GETSELFLEAVEOPTION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case selfavailableleave.GETSELFLEAVEOPTION_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default get_self_available_leave_options;