import * as code from '../action/get_otp_code_hr';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}


const get_otp_code_hr = (state=initialState,action)=>{
    switch(action.type){
        case code.GETOTPCODEHR_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case code.GETOTPCODEHR_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case code.GETOTPCODEHR_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default get_otp_code_hr;