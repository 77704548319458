import myaxios from '../axios';
export const CREATECONTRACTJOBTITLE_SUCCESS = 'CREATECONTRACTJOBTITLE_SUCCESS';
export const CREATECONTRACTJOBTITLE_REQUEST = 'CREATECONTRACTJOBTITLE_REQUEST';
export const CREATECONTRACTJOBTITLE_FAILURE = 'CREATECONTRACTJOBTITLE_FAILURE';
export const CREATECONTRACTJOBTITLE_RESET = 'CREATECONTRACTJOBTITLE_RESET';

export const create_contract_job_title = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATECONTRACTJOBTITLE_REQUEST'
        });
        
        myaxios.post(`company/jobtitle/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'CREATECONTRACTJOBTITLE_SUCCESS',
                payload:response
            }
        )})
        .catch((error) =>{
            dispatch({
                type: 'CREATECONTRACTJOBTITLE_FAILURE',
                payload:error,
            })
        })
    }
}

export const reset_create_contract_job_title = () => dispatch =>{
    dispatch({
        type:'CREATECONTRACTJOBTITLE_RESET'
    });
}