import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { employee_claim_apply_validate } from '../../../../action/employee/employee_claim_apply_validate';
import { employee_apply_claim } from '../../../../action/employee/employee_apply_claim';
import { usePrevious } from '../../../../hook/usePrevious';
import Swal from 'sweetalert2';
import { popError } from '../../../../func/popError';
import { get_all_employee_available_claim } from '../../../../action/employee/get_all_employee_available_claim';
import { get_apply_claim_list } from '../../../../action/claim/get_apply_claim_list';
import { get_medical_claim_autocomplete } from '../../../../action/claim/get_medical_claim_autocomplete';
import { get_approvals_claim_list } from '../../../../action/claim/get_approvals_claim_list';
import { NewMainContext } from '../../../../context/NewMainContext';

const mapStateToProps=(state)=>({
    doctorlist:state.get_medical_claim_autocomplete.doctor,
    locationlist:state.get_medical_claim_autocomplete.location,
    diagnosislist:state.get_medical_claim_autocomplete.diagnosis,
    isLoading:state.employee_apply_claim.isLoading,
    isLoading1:state.employee_claim_apply_validate.isLoading,
    emplist:state.get_employee_list.data,
    availablelist:state.get_all_employee_available_claim.data,
    claimlist:state.get_claim_list.data,
    success:state.employee_claim_apply_validate.data,
    errors:state.employee_claim_apply_validate.errors,
    apply_success:state.employee_apply_claim.data,
    apply_errors:state.employee_apply_claim.errors,
})

const mapDispatchToProps=(dispatch)=>({
    employee_apply_claim:(employee,claim_type,original_amount,claim_date,dependant,set_pending,comment,file,doctor,diagnosis,location)=>{
        dispatch(employee_apply_claim(employee,claim_type,original_amount,claim_date,dependant,set_pending,comment,file,doctor,diagnosis,location))
    },
    employee_claim_apply_validate:(employee,claim_type,original_amount,claim_date,dependant,set_pending,comment,file,doctor,diagnosis,location)=>{
        dispatch(employee_claim_apply_validate(employee,claim_type,original_amount,claim_date,dependant,set_pending,comment,file,doctor,diagnosis,location))
    },
    get_approvals_claim_list:()=>{
        dispatch(get_approvals_claim_list())
    },
    get_apply_claim_list:()=>{
        dispatch(get_apply_claim_list())
    },
    get_medical_claim_autocomplete:()=>{
        dispatch(get_medical_claim_autocomplete())
    },
    get_all_employee_available_claim:()=>{
        dispatch(get_all_employee_available_claim())
    },
})

function ModalEmpApplyClaim(props){
    const {success,employee_apply_claim,errors,apply_success,closeModal,get_all_employee_available_claim,get_apply_claim_list,get_approvals_claim_list,get_medical_claim_autocomplete} = props;
    const [employee,setEmployee]  = useState('');
    const [err_employee,setErrEmployee] = useState('');
    const [claim_type,setClaimType] = useState('');
    const [err_claim_type,setErrClaimType] = useState('');
    const [claim_date,setClaimDate] = useState('');
    const [err_claim_date,setErrClaimDate] = useState('');
    const [claim_amount,setClaimAmount] = useState('');
    const [err_claim_amount,setErrClaimAmount] = useState('');
    const [doctor,setDoctor] = useState('');
    const [err_doctor,setErrDoctor] = useState('');
    const [location,setLocation] = useState('');
    const [err_location,setErrLocation] = useState('');
    const [diagnosis,setDiagnosis] = useState('');
    const [err_diagnosis,setErrDiagnosis] = useState('');
    const [comment,setComment] = useState('');
    const [dependant,setDependant] = useState(false);
    const [set_pending,setPending] = useState(false);
    const [min,setMin] = useState(0);
    const [max,setMax] = useState(0);
    const [file,setFile] = useState([]);
    const [available,setAvailable] = useState([]);
    const [approval_group,setApprovalGroup] = useState([]);
    const [claim_exceptions,setClaimExceptions] = useState([]);
    const [claim_approvers,setClaimApprovers] = useState([]);
    const [err_images,setErrImages] = useState('');
    const [is_medical,setIsMedical] = useState(false);
    const { current_user } = useContext(NewMainContext);

    const prevSuccess = usePrevious(success);
    const prevErrors = usePrevious(errors);
    const prevApplySuccess = usePrevious(apply_success);

    useEffect(()=>{
        if(props.toggle){
            setEmployee(props.has_emp === true ? props.id : '');
            setClaimType(props.has_claim === true ? props.claim_type : '');
            setErrEmployee('');
            setErrClaimType('');
            setClaimDate('');
            setErrClaimDate('');
            setClaimAmount('');
            setErrClaimAmount('');
            setDoctor('');
            setErrDoctor('');
            setLocation('');
            setErrLocation('');
            setDiagnosis('');
            setErrDiagnosis('');
            setComment('');
            setDependant(false);
            setPending(false);
            setMax(0);
            setMin(0);
            setFile([]);
            setAvailable([]);
            setIsMedical(false);
        }
    },[props.toggle,props.has_emp,props.id,props.has_claim,props.claim_type])

    useEffect(()=>{
        if(claim_type !== '' && props.claimlist.length !== 0){
            const data = props.claimlist.find((item)=>item.id === parseInt(claim_type));
            setMin(data.minimum_images);
            setMax(data.maximum_images);
            setIsMedical(data.medical);
        }
        setFile([]);
        setErrImages('');
        setErrClaimDate('');
    },[claim_type,props.claimlist])

    useEffect(()=>{
        if(employee !== '' && props.availablelist.length !== 0 && props.toggle){
            const arr = props.availablelist.filter(function(item){
                return item.employee === parseInt(employee)
            })

            setAvailable(arr);

            const data = arr.map((item)=>{
                return {...item,group:0}
            })
            setApprovalGroup(data);
        }
        else{
            setAvailable([]);
            setApprovalGroup([]);
        }
    },[employee,props.availablelist,props.toggle])

    useEffect(()=>{
        let arr = [];
        let arr1 = approval_group;
        if(props.emplist.length !== 0 && employee !== '' && approval_group.length !== 0){
            const emp_data = props.emplist.find((item)=>item.id === parseInt(employee));
            const {metadata} = emp_data;
            if('cag' in metadata){
                const {cag} = metadata;
                if(cag !== null){
                    arr.push(cag.approvers);
                    const {exceptions} = cag;
                    for(let i=0;i<exceptions.length;i++){
                        const number = i+1;
                        const data = exceptions[i];
                        arr.push(data.approvers);
                        const exceptions1 = data.exceptions;
                        for(let j=0;j<exceptions1.length;j++){
                            arr1 = arr1.map(item=>
                                item.claim_type === exceptions1[j].id
                                ?{...item,group:number}
                                :item)
                        }
                    }
                }
            }
        }
        setClaimApprovers(arr);
        setClaimExceptions(arr1);
    },[props.emplist,approval_group,employee])

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== success && success !== null && props.toggle){
            if('error_message' in success){
                Swal.fire({
                    title:success.error_message,
                    type:"warning",
                    showCancelButton:true,
                    showConfirmButton:true,
                    cancelButtonColor:'red',
                    confirmButtonText:IMLocalized('yes!'),
                    cancelButtonText:IMLocalized('no!')
                })
                .then((result)=>{
                    if(result.value){
                        let doc = null;
                        let dia = null;
                        let loc = null;
                        if(is_medical){
                            doc = doctor;
                            dia = diagnosis;
                            loc = location;
                        }
                        employee_apply_claim(employee,claim_type,claim_amount,claim_date,dependant,set_pending,comment,file,doc,dia,loc);
                    }
                })
            }
            else{
                let doc = null;
                let dia = null;
                let loc = null;
                if(is_medical){
                    doc = doctor;
                    dia = diagnosis;
                    loc = location;
                }
                employee_apply_claim(employee,claim_type,claim_amount,claim_date,dependant,set_pending,comment,file,doc,dia,loc);
            }
        }
    },[success,prevSuccess,employee,claim_type,claim_amount,claim_date,dependant,set_pending,comment,file,doctor,diagnosis,location,is_medical,employee_apply_claim,props.toggle])

    useEffect(()=>{
        if(prevErrors !== undefined && prevErrors !== errors && errors !== null && props.toggle){
            if('dependant' in errors){
                const word = errors['dependant'].toString();
                popError(word);
            }
            else if('non_field_errors' in errors){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
            else if('claim_date' in errors){
                const word = errors['claim_date'].toString();
                setErrClaimDate(word);
            }
        }
    },[prevErrors,errors,props.toggle])

    useEffect(()=>{
        if(prevApplySuccess !== undefined && prevApplySuccess !== apply_success && apply_success !== null && props.toggle ){
            closeModal();
            get_all_employee_available_claim();
            get_apply_claim_list();
            get_medical_claim_autocomplete();
            if(current_user ==='hr'){
                get_approvals_claim_list();
            }
            
        }
    },[prevApplySuccess,apply_success,props.toggle,closeModal,get_all_employee_available_claim,get_apply_claim_list,get_approvals_claim_list,get_medical_claim_autocomplete,current_user])

    const approval_table=()=>{
        if(claim_approvers.length !== 0 && claim_exceptions.length !== 0 && claim_type !=='' && props.toggle){
            const detail = claim_exceptions.filter(function(item){
                return item.claim_type === parseInt(claim_type)
            })
            if(detail.length !== 0){
                const group = detail[0].group;
                const approver = claim_approvers[group];
                
                const arr_approvers = approver.map((item,index)=>{
                    const data = item.map((item1,index2)=>{
                        return <div key={'approvers'+index2}><span>{item1.name} -  ({IMLocalized('level')} : {index+1})</span></div>
                    })
                    return data
                })

                return(
                    <Row className='mt-2'>
                        <Col sm="3">
                            <label htmlFor='claim_approval'>{IMLocalized('claim_approval')}</label>
                        </Col>
                        <Col sm="9">
                            <Table size="sm" className='table-bordered' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th>{IMLocalized('approval_level')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {arr_approvers}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                )
            }
        }
    }

    const submitClaim=()=>{

        if(is_medical === true){
            if(claim_amount !=='' && claim_date !== '' && doctor !== '' && diagnosis !=='' && location !=='' && doctor !==null && diagnosis !== null && location !== null && claim_type !== ''){
                if(file.length <= max && file.length >= min){
                    props.employee_claim_apply_validate(employee,claim_type,claim_amount,claim_date,dependant,set_pending,comment,file,doctor,diagnosis,location);
                }
                else{
                    setErrImages(`* ${IMLocalized('must_upload_number')} ${min} ${IMLocalized('to')} ${max} ${IMLocalized('please_upload_number')}`);
                }
            }
            else{
                if(claim_amount === ''){
                    setErrClaimAmount(IMLocalized('claim_amount_required'));
                }
                if(claim_date === ''){
                    setErrClaimDate(IMLocalized('claim_date_required'));
                }
                if(doctor === '' || doctor=== null){
                    setErrDoctor(IMLocalized('doctor_required'));
                }
                if(diagnosis === '' || diagnosis === null){
                    setErrDiagnosis(IMLocalized('diagnosis_required'));
                }
                if(location === '' || location === null){
                    setErrLocation(IMLocalized('location_required'));
                }
                if(claim_type === '' ){
                    setErrClaimType(IMLocalized('claim_type_required'))
                }
            }
        }
        else{
            if(claim_amount !=='' && claim_date !== '' && claim_type !== ''){
                if(file.length <= max && file.length >= min){
                    props.employee_claim_apply_validate(employee,claim_type,claim_amount,claim_date,dependant,set_pending,comment,file,null,null,null);
                }
                else{
                    setErrImages(`* ${IMLocalized('must_upload_number')} ${min} ${IMLocalized('to')} ${max} ${IMLocalized('please_upload_number')}`);
                }
            }
            else{
                if(claim_amount === ''){
                    setErrClaimAmount(IMLocalized('claim_amount_required'));
                }
                if(claim_date === ''){
                    setErrClaimDate(IMLocalized('claim_date_required'));
                }
                if(claim_type === '' ){
                    setErrClaimType(IMLocalized('claim_type_required'))
                }
            }
        }
    }

    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('apply_claim')}  {props.name ? ' - ' + props.name : ''}</ModalHeader>
            <ModalBody>
                {!props.has_emp &&
                <Row>
                    <Col lg="3">
                        <label htmlFor='employee'>{IMLocalized('employee_name')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input id="employee" type="select" size="sm" value={employee} invalid={err_employee.length >0}
                            onChange={(e)=>{
                                setEmployee(e.target.value); 
                                setErrEmployee(''); 
                                setClaimType(''); 
                                setErrClaimType(''); 
                                setErrClaimDate(''); 
                                setClaimDate(''); 
                                setClaimAmount(''); 
                                setErrClaimAmount('');
                                setPending(false);
                                setDependant(false);
                                setFile([]);
                            }}>
                                <option></option>
                                {props.emplist.map((item,index)=>{
                                    return <option value={item.id} key={index} >{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_employee}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                }
                {employee !== '' && available.length === 0 &&
                <small className="text-danger">{IMLocalized('please_assign_claim_scheme_for_employee')}</small>}
                {!props.has_claim && available.length !== 0 &&
                <Row>
                    <Col lg="3">
                        <label htmlFor='claim_type'>{IMLocalized('claim_type')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input size="sm" type="select" name="claim_type" id="claim_type" value={claim_type} onChange={(e)=>{setClaimType(e.target.value); setErrClaimType('')}} invalid={err_claim_type.length >0}>
                                <option></option>
                                {available.map((item,index)=>{
                                    const detail = props.claimlist.find((item1)=>item1.id === item.claim_type);
                                    return <option value={item.claim_type} key={index}>{detail.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_claim_type}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>}
                {employee !== '' && available.length !== 0 &&
                <>
                <Row>
                    <Col lg="3">
                        <label htmlFor='claim_date'>{IMLocalized('claim_date')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input type="date" size="sm" name="claim_date" id="claim_date" value={claim_date} onChange={(e)=>{setClaimDate(e.target.value);setErrClaimDate('')}} invalid={err_claim_date.length >0} />
                            <FormFeedback>{err_claim_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="3">
                        <label htmlFor='claim_amount'>{IMLocalized('claim_amount')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input size="sm" min="0" type="number" id="claim_amount" value={claim_amount} onChange={(e)=>{setClaimAmount(e.target.value); setErrClaimAmount('')}} invalid={err_claim_amount.length >0} />
                            <FormFeedback>{err_claim_amount}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                {is_medical === true &&
                <>
                <Row>
                    <Col lg="3">
                        <label htmlFor='doctor'>{IMLocalized('doctor')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input className='form-control' size="sm" list="doctorlist" id="doctor" value={doctor} onChange={(e)=>{setDoctor(e.target.value); setErrDoctor('') }} invalid={err_doctor.length > 0} />
                            <datalist id="doctorlist" >
                                {props.doctorlist.map((item,index)=>{
                                    return <option value={item} key={index}></option>
                                })}
                            </datalist>
                            <FormFeedback>{err_doctor}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="3">
                        <label htmlFor='location'>{IMLocalized('location')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input size="sm" list="locationlist" id="location" value={location} onChange={(e)=>{setLocation(e.target.value); setErrLocation('')}} invalid={err_location.length >0}  />
                            <datalist id="locationlist">
                                {props.locationlist.map((item,index)=>{
                                    return <option value={item} key={index}></option>
                                })}
                            </datalist>
                            <FormFeedback>{err_location}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="3">
                        <label htmlFor='diagnosis'>{IMLocalized('diagnosis')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input size="sm" list="diagnosislist" id="diagnosis" value={diagnosis} onChange={(e)=>{setDiagnosis(e.target.value); setErrDiagnosis('')}} invalid={err_diagnosis.length >0}  />
                            <datalist id="diagnosislist">
                                {props.diagnosislist.map((item,index)=>{
                                    return <option value={item} key={index}></option>
                                })}
                            </datalist>
                            <FormFeedback>{err_diagnosis}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                </>}
                <Row>
                    <Col lg="3">
                        <label htmlFor='comment'>{IMLocalized('comment')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input size="sm" type="textarea" name="comment" id="comment" value={comment} onChange={(e)=>{setComment(e.target.value)}} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="3">
                        <label htmlFor='dependant'>{IMLocalized('dependant')}</label>
                    </Col>
                    <Col lg="9">
                        <div className='custom-control custom-checkbox'>
                            <input type="checkbox" id="modal_emp_claim_dependant" name="dependant" className='custom-control-input' checked={dependant} onChange={(e)=>setDependant(e.target.checked)} />
                            <label className='custom-control-label' htmlFor='modal_emp_claim_dependant'></label>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="3">
                        <label htmlFor='set_pending'>{IMLocalized('set_pending')}</label>
                    </Col>
                    <Col lg="9">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="modal_emp_claim_set_pending" name="set_pending" className="custom-control-input" checked={set_pending} onChange={(e)=>setPending(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="modal_emp_claim_set_pending"></label>
                        </div>
                    </Col>
                </Row>
                {min >0  || max >0 ?
                <Row className='mt-3'>
                    <Col lg="3">
                        <label htmlFor='image'>{IMLocalized('image')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <input type="file" multiple onChange={(e)=>{setFile(e.target.files); setErrImages('')}} accept='image/*' />
                            <div>
                                <small className='text-red'>{err_images}</small>
                            </div>
                            
                        </FormGroup>
                    </Col>
                </Row>
                :null
                }
                {approval_table()}
                </>}
            </ModalBody>
            <ModalFooter>
                {props.isLoading || props.isLoading1 ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> : null}
                {!props.isLoading && !props.isLoading1 && employee !== '' && claim_type !== '' &&
                <button className="btn btn-primary btn-sm" onClick={()=>submitClaim()}>{IMLocalized('apply')}</button>
                }
                <button onClick={()=>props.closeModal()} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalEmpApplyClaim);