import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const PAYROLLBASICPAYOUT_REQUEST = 'PAYROLLBASICPAYOUT_REQUEST';
export const PAYROLLBASICPAYOUT_SUCCESS = 'PAYROLLBASICPAYOUT_SUCCESS';
export const PAYROLLBASICPAYOUT_FAILURE = 'PAYROLLBASICPAYOUT_FAILURE';


export const payroll_basic_payout =(id,first)=>{
    return dispatch=>{
        dispatch({
            type:'PAYROLLBASICPAYOUT_REQUEST'
        })
        
        myaxios.post(`payroll/payroll/${id}/Payout/`,{
            first:first
        })
        .then((response)=>{
            dispatch({
                type:'PAYROLLBASICPAYOUT_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('payout_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'PAYROLLBASICPAYOUT_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('payout_failure')
            });
        })
    }
}