import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { delete_holiday_list, reset_delete_holiday_list } from '../../action/delete_holiday_list';
import { getHolidayList } from '../../action/get_holiday_list';
import { NewMainContext } from '../../context/NewMainContext';
import { IMLocalized } from '../../language/IMLocalized';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';
import ModalCreateHolidayList from './modal/ModalCreateHolidayList';
import ModalEditHolidayList from './modal/ModalEditHolidayList';

function HolidayTable({detailHoliday}){
    const holidaylist = useSelector(state=>state.get_holiday_list.data);
    const isLoading = useSelector(state=>state.get_holiday_list.isLoading);
    const delete_success = useSelector(state=>state.delete_holiday_list.data);

    const { addItem } = useContext(NewMainContext);
    const dispatch = useDispatch();

    const [edit_toggle,setEditToggle] = useState(false);
    const [edit_detail,setEditDetail] = useState(null);
    const [add_toggle,setAddToggle] = useState(false);

    useEffect(()=>{
        if( delete_success != null){
            dispatch(getHolidayList());
            dispatch(reset_delete_holiday_list());
        }
    },[delete_success,dispatch])


    const deleteHoliday=(id,name)=>{
        Swal.fire({
            type:"warning",
            title:`${IMLocalized('delete_holiday_confirmation')} (${name})?`,
            showCancelButton:true,
            confirmButtonColor:'red'
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_holiday_list(id));
            }
        })
    }
    return(
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title: IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title: IMLocalized('holiday_list')}
                    ]}/>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className='mt-2'>
                <Col>
                    <Card>
                        <CardHeader className='d-flex justify-content-between align-items-center'>
                            <h4 className='mb-0'>{IMLocalized('holiday_list')}</h4>
                            <button className='btn btn-primary btn-sm' onClick={()=>setAddToggle(true)} >{IMLocalized('create')}</button>
                        </CardHeader>
                        {isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                        {!isLoading && holidaylist.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {!isLoading && holidaylist.length !== 0 && 
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                            {holidaylist.sort((a,b)=>{
                                if(a.fix === b.fix){
                                    return 0;
                                }
                                else if(!a.fix){
                                    return 1;
                                }
                                else if (!b.fix){
                                    return -1;
                                }
                                return a < b ? -1 : 1 ;
                            }).map((item,index)=>{
                                return(
                                    <ListGroupItem className='py-2 d-flex align-items-center justify-content-between' key={index}>
                                        <span>{index+1}. {item.name} </span>
                                        <div className='mr-1'>
                                            <button className="btn btn-primary btn-sm" onClick={()=>detailHoliday(item.id)} >{IMLocalized('detail')}</button>
                                            <button className="btn btn-info btn-sm" onClick={()=>{setEditDetail(item); setEditToggle(true)}} >{IMLocalized('edit')}</button>
                                            <button className="btn btn-danger btn-sm" onClick={()=>deleteHoliday(item.id,item.name)} >{IMLocalized('delete')}</button>
                                        </div>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
        <ModalEditHolidayList toggle={edit_toggle} detail={edit_detail} closeModal={()=>{setEditDetail(null);setEditToggle(false)}} />
        <ModalCreateHolidayList toggle={add_toggle} closeModal={()=>setAddToggle(false)} />
        </>
    )
}
export default HolidayTable;