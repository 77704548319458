import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const WIZARDCREATESECTION_REQUEST = 'WIZARDCREATESECTION_REQUEST';
export const WIZARDCREATESECTION_SUCCESS = 'WIZARDCREATESECTION_SUCCESS';
export const WIZARDCREATESECTION_FAILURE = 'WIZARDCREATESECTION_FAILURE';

export const wizard_create_section = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDCREATESECTION_REQUEST'
        });

        myaxios.post('company/section/array_save/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDCREATESECTION_SUCCESS',
                payload:response
            });
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDCREATESECTION_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}