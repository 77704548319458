import * as additional_cost from '../../action/employee/employee_contract_additional_cost';

const initialState={
    isLoading:false,
    errors:[],
    data:null,
    
}

const employee_contract_additional_cost = (state=initialState,action)=>{
    switch(action.type){
        case additional_cost.EMPADDITIONALCOST_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[],
                data:null
            };
            case additional_cost.EMPADDITIONALCOST_SUCCESS:
                return{
                    ...state,
                    isLoading:false,
                    errors:[],
                    data:action.payload
                }
            case additional_cost.EMPADDITIONALCOST_FAILURE:
                return{
                    ...state,
                    isLoading:false,
                    errors:action.payload
                }
            default:
                return state;
    }
}
export default employee_contract_additional_cost;