import myaxios from '../../axios';

export const GETEMPLOYEEPICTURE_REQUEST = 'GETEMPLOYEEPICTURE_REQUEST';
export const GETEMPLOYEEPICTURE_SUCCESS = 'GETEMPLOYEEPICTURE_SUCCESS';
export const GETEMPLOYEEPICTURE_FAILURE = 'GETEMPLOYEEPICTURE_FAILURE';

export const get_employee_picture = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETEMPLOYEEPICTURE_REQUEST'
        });

        myaxios.get(`ob/employee/${id}/thumbnail/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'GETEMPLOYEEPICTURE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPLOYEEPICTURE_FAILURE',
                payload:error
            })
        })
    }
}