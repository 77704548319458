import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEEMPLOYEE_SUCCESS = 'UPDATEEMPLOYEE_SUCCESS';
export const UPDATEEMPLOYEE_REQUEST = 'UPDATEEMPLOYEE_REQUEST';
export const UPDATEEMPLOYEE_FAILURE = 'UPDATEEMPLOYEE_FAILURE';
export const UNIQUECHECK_FAILURE = 'UNIQUECHECK_FAILURE';


export const update_employee_profile = (id,salutation,name,employee_number,badge_number,email,nationality,gender,race,religion,relationship,identification_number,contact_number,date_of_birth,company,branch,payment,id_type,join_date,probation_end_date,is_pr,pr_start_date,pr_cpf_contribution_type)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEEMPLOYEE_REQUEST'
        });

        myaxios.post(`employee/employees/UniqueCheck/`,{
            data:[[parseInt(id),employee_number,badge_number,identification_number]]
        })
        .then((response)=>{
            myaxios.patch(`employee/employees/${id}/`,{
                salutation:salutation,
                name:name,
                employee_number:employee_number,
                badge_number:badge_number,
                email:email,
                nationality:nationality,
                gender:gender,
                race:race,
                religion:religion,
                relationship:relationship,
                identification_number:identification_number,
                contact_number:contact_number,
                date_of_birth:date_of_birth,
                company:company,
                branch:branch,
                payment:payment,
                id_type:id_type,
                join_date:join_date,
                probation_end_date:probation_end_date,
                is_pr:is_pr,
                pr_start_date:pr_start_date,
                pr_cpf_contribution_type:pr_cpf_contribution_type,
            })
            .then((response)=>{
                dispatch({
                    type:'UPDATEEMPLOYEE_SUCCESS',
                    payload:response
                });

                Toast.fire({
                    type: 'success',
                    title: IMLocalized('update_success')
                });
            })
            .catch((error)=>{
                dispatch({
                    type:'UPDATEEMPLOYEE_FAILURE',
                    payload:error
                });
                ErrToast.fire({
                    type:'error',
                    title:IMLocalized('update_failure')
                });
            })
        })
        .catch((error)=>{
            dispatch({
                type:'UNIQUECHECK_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}