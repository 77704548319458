import * as update_setting from '../action/update_general_setting';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_general_setting = (state=initialState,action)=>{
    switch(action.type){
        case update_setting.UPDATEGENSETTING_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case update_setting.UPDATEGENSETTING_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case update_setting.UPDATEGENSETTING_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case update_setting.UPDATEGENSETTING_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default update_general_setting;