import * as managerapprove from '../../action/approvals/claim_manager_approve';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const claim_manager_approve = (state=initialState,action)=>{
    switch(action.type){
        case managerapprove.CLAIMMANAGERAPPROVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case managerapprove.CLAIMMANAGERAPPROVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case managerapprove.CLAIMMANAGERAPPROVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case managerapprove.CLAIMMANAGERAPPROVE_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default claim_manager_approve;