import * as setdefault from '../../action/employee/setdefault_employee_bank';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const setdefault_employee_bank = (state=initialState,action)=>{
    switch(action.type){
        case setdefault.REQUEST_DEFAULTBANK:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case setdefault.GET_DEFAULTBANK:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case setdefault.FAILURE_DEFAULTBANK:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case setdefault.RESET_DEFAULTBANK:
            return{
                ...state,
                isLoading:false,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default setdefault_employee_bank;