import * as leave_hr_approve from '../action/leave_hr_approve';

const initialState={
    errors:[],
    isLoading:false,
    data:[]
}

const leave_approve = (state=initialState,action)=>{
    switch(action.type){
        case leave_hr_approve.LEAVEHRAPPROVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[],
                data:[]
            }
        case leave_hr_approve.LEAVEHRAPPROVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                errors:[],
                data:action.payload
            }
        case leave_hr_approve.LEAVEHRAPPROVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload,
                data:[]
            }
        default:
            return state;
    }
}
export default leave_approve;