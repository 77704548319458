import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const MASSASSIGNLEAVEGROUPCREATE_REQUEST = 'MASSASSIGNLEAVEGROUPCREATE_REQUEST';
export const MASSASSIGNLEAVEGROUPCREATE_SUCCESS = 'MASSASSIGNLEAVEGROUPCREATE_SUCCESS';
export const MASSASSIGNLEAVEGROUPCREATE_FAILURE = 'MASSASSIGNLEAVEGROUPCREATE_FAILURE';



export const mass_assign_leave_group_create =(leave_scheme,year,entitlements,groups)=>{
    return dispatch=>{
        dispatch({
            type:'MASSASSIGNLEAVEGROUPCREATE_REQUEST'
        });

        myaxios.post('leave/mass/group/create/',{
            leave_scheme:leave_scheme,
            year:year,
            entitlements:entitlements,
            groups:groups
        })
        .then((response)=>{
            dispatch({
                type:'MASSASSIGNLEAVEGROUPCREATE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('apply_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'MASSASSIGNLEAVEGROUPCREATE_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('assign_failure')
            });
        })
    }
}