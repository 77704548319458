import * as getleaveawardlist from '../../action/employee/get_leave_award_list';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const get_leave_award_list = (state=initialState,action)=>{
    switch(action.type){
        case getleaveawardlist.GETLEAVEAWARDLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case getleaveawardlist.GETLEAVEAWARDLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case getleaveawardlist.GETLEAVEAWARDLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_leave_award_list;

export const emp_get_leave_award_list = (state,id)=>{
    const { data } = state.get_leave_award_list;
    const leavelist = state.get_leave_list.data;

    return data.filter(item=>item.employee === id).map((item)=>{
        const detail = leavelist.find(el=>el.id === item.leave_setting);
        return {...item,leave_name:detail?.name ?? '-'}
    })
}