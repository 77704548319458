import myaxios from '../../axios';



export const PAYROLLINSTANCE_REQUEST = 'PAYROLLINSTANCE_REQUEST';
export const PAYROLLINSTANCE_SUCCESS = 'PAYROLLINSTANCE_SUCCESS';
export const PAYROLLINSTANCE_FAILURE = 'PAYROLLINSTANCE_FAILURE';

export const get_payroll_instance = (id) =>{
    return dispatch=>{
        dispatch({
            type: 'PAYROLLINSTANCE_REQUEST'
        });

        myaxios.get(`payroll/payroll/${id}/`)
        .then((response)=>{
            dispatch({
                type:'PAYROLLINSTANCE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'PAYROLLINSTANCE_FAILURE',
                payload:error.response
            })
        })
    }
}