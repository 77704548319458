import * as leave_approval from '../../action/leave/create_leave_approval';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const create_leave_approval = (state=initialState,action)=>{
    switch(action.type){
        case leave_approval.LEAVEAPPROVAL_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case leave_approval.LEAVEAPPROVAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case leave_approval.LEAVEAPPROVAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default create_leave_approval;