import React, { useCallback,useContext,useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { get_employee_role_list } from '../../../action/employee/get_employee_role_list';
import { Col, Container, Row } from 'reactstrap';
import { get_employee_list } from '../../../action/employee/get_employee_list';
import { get_user_manager_list } from '../../../action/approvals/get_user_manager_list';
import { IMLocalized } from '../../../language/IMLocalized';
import { get_otp_code_hr } from '../../../action/get_otp_code_hr';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import ModalRoleQr from './modal/ModalRoleQr';
import ModalAssignRole from './modal/ModalAssignRole';
import ModalUpdatePassword from './modal/ModalUpdatePassword';
import ModalUpdateRole from './modal/ModalUpdateRole';
import OverlayLoading from '../../loading_component/overlay_loading';
import { checkboxRenderer } from '../../ag-grid_component/checkboxRenderer';
import { usePrevious } from '../../../hook/usePrevious';
import { NewMainContext } from '../../../context/NewMainContext';
import BreadCrumbList from '../../breadcrumb/BreadCrumbList';

const mapStateToProps=(state)=>({
    list:state.get_employee_role_list.data,
    isLoading:state.get_employee_role_list.isLoading,
    code_data:state.get_otp_code_hr.data,
})

const mapDispatchToProps=(dispatch)=>({
    get_employee_role_list:()=>{
        dispatch(get_employee_role_list())
    },
    get_employee_list:()=>{
        dispatch(get_employee_list())
    },
    get_user_manager_list:()=>{
        dispatch(get_user_manager_list())
    },
    get_otp_code_hr:()=>{
        dispatch(get_otp_code_hr())
    }
})

const gridOptions={
    defaultColDef: {
        resizable: true,
    },
}

function EmployeeRoleList(props){
    const {code_data} = props;
    const {addItem, action_toggle} = useContext(NewMainContext);
    const [add_id,setAddID] = useState('');
    const [add_toggle,setAddToggle] = useState(false);
    const [add_name,setAddName] = useState('');
    const [change_toggle,setChangeToggle] = useState(false);
    const [change_id,setChangeID] = useState('');
    const [change_name,setChangeName] = useState('');
    const [update_toggle,setUpdateToggle] = useState(false);
    const [update_id,setUpdateID] = useState('');
    const [update_name,setUpdateName] = useState('');
    const [otp_code,setOtpCode] = useState('');
    const [role_detail,setRoleDetail] = useState(null);
    const [qr_toggle,setQrToggle] = useState(false);

    const prevCode = usePrevious(code_data);

    useEffect(()=>{
        if(prevCode !== undefined && prevCode !== code_data && code_data !== null){
            const {data} = code_data;
            const base = process.env.REACT_APP_BASE_LINK;
            const link = 'https://'+base+'/#/new_user/'+data+'/';
            setOtpCode(link);
            setQrToggle(true);
        }
        
    },[prevCode,code_data])

    const addRoleRenderer = useCallback(function(params){
        let btn = '---';
        const id = params.data.id;
        const name = params.data.name;
        const user = params.data.user;
        const roles = params.data.roles;
        let role_hr = params.data.role_hr;
        let role_claim_officer = params.data.role_claim_officer;
        let role_leave_officer = params.data.role_leave_officer;
        let role_manager = params.data.role_manager;
        if(roles.length !== 0){
            for(let i=0;i<roles.length;i++){
                const name = roles[i];
                if(name === 'role_hr'){
                    role_hr = true;
                }
                if(name === 'role_claim_officer'){
                    role_claim_officer = true;
                }
                if(name === 'role_leave_officer'){
                    role_leave_officer = true;
                }
                if(name === 'role_manager'){
                    role_manager = true;
                }
            }
        }
       
        if(user === null && role_hr ===false && role_leave_officer === false && role_claim_officer === false && role_manager === false){
            btn = <a href="/#" onClick={()=>{
                setAddID(id);
                setAddName(name);
                setAddToggle(prevState=>(!prevState));
            }}>{IMLocalized('add_user')}</a>
        }
        else{
            btn = <a href="/#" onClick={()=>{
                setChangeID(id);
                setChangeName(name);
                setChangeToggle(prevState=>(!prevState));
            }}>{IMLocalized('change_password')}</a>
        }
        return btn
    },[])

    const updateRoleRenderer = useCallback(function(params){
        let btn = '---';
        const id = params.data.id;
        const name = params.data.name;
        const user = params.data.user;
        if(user !== null){
            btn = <a href="/#" onClick={()=>{
                const detail = props.list.find((item)=>item.id === id);
                setRoleDetail(detail ? detail : null);
                setUpdateToggle(prevState=>(!prevState));
                setUpdateName(name);
                setUpdateID(id);

            }}>{IMLocalized('update_role')}</a>
        }
        return btn
    },[props.list])

    const columnDefs=[
        {
            headerName:IMLocalized('employee_number'),
            field:'employee_number',
            sortable:true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            headerName:IMLocalized('name'),
            field:'name',
            sortable:true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            headerName:IMLocalized('add_user_change_password'),
            field:'',
            cellRendererFramework:addRoleRenderer,
            width:250
        },
        {
            headerName:IMLocalized('role_hr'),
            field:'role_hr',
            editable:false,
            cellRendererFramework:checkboxRenderer
        },
        {
            headerName:IMLocalized('role_manager'),
            field:'role_manager',
            cellRendererFramework:checkboxRenderer
        },
        {
            headerName:IMLocalized('role_leave_officer'),
            field:'role_leave_officer',
            cellRendererFramework:checkboxRenderer
        },
        {
            headerName:IMLocalized('role_claim_officer'),
            field:'role_claim_officer',
            cellRendererFramework:checkboxRenderer
        },
        {
            headerName:IMLocalized('role_user'),
            field:'role_user',
            cellRendererFramework:checkboxRenderer
        },
        {
            headerName:IMLocalized('update_role'),
            field:'',
            cellRendererFramework:updateRoleRenderer
        }
    ]

    return(
        <>
        {props.isLoading ? <OverlayLoading/>:
        <>
            <Container fluid>
                <Row className='bg-white border-bottom'>
                    <Col className='p-2'>
                        <BreadCrumbList list={[
                            { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                            { active: true, title:IMLocalized('employee_role')}
                        ]}/>
                        <button className='btn btn-primary btn-sm ml-2' onClick={()=>props.get_otp_code_hr()}>{IMLocalized('emp_qr_registration')}</button>
                    </Col>
                </Row>
                <Row className="pt-2 justify-content-center">
                    <Col lg="12">
                        <div className="d-flex justify-content-center">
                            <div className="ag-theme-balham" style={{height:action_toggle ? 'calc(100vh - 250px)' :'calc(100vh - 214px)' , width:'100%' }}>
                                <AgGridReact
                                columnDefs={columnDefs}
                                rowData={props.list}
                                suppressDragLeaveHidesColumns={true}
                                suppressSizeToFit={true}
                                suppressColumnMoveAnimation={false}
                                gridOptions={gridOptions}
                                overlayNoRowsTemplate={IMLocalized('no_data')}
                                >
                                </AgGridReact>
                            </div>
                        </div>
                    </Col>
                </Row>
                <ModalAssignRole toggle={add_toggle} id={add_id} name={add_name} 
                onSuccess={()=>{
                    props.get_employee_list();
                    props.get_employee_role_list();
                    props.get_user_manager_list();
                    setAddToggle(false);
                }} 
                closeModal={()=>{
                    setAddToggle(false);
                    setAddID('');
                    setAddName('');
                }}
                />
                <ModalUpdatePassword toggle={change_toggle} id={change_id} name={change_name} 
                onSuccess={()=>setChangeToggle(false)}
                closeModal={()=>{
                    setChangeID('');
                    setChangeName('');
                    setChangeToggle(false);
                }} />
                <ModalUpdateRole toggle={update_toggle} id={update_id} name={update_name} detail={role_detail}
                onSuccess={()=>{
                    setUpdateToggle(false);
                    setRoleDetail(null);
                    props.get_employee_role_list();
                    props.get_employee_list();
                    props.get_user_manager_list();
                }} 
                closeModal={()=>{
                    setRoleDetail(null);
                    setUpdateToggle(false);
                    setUpdateName('');
                    setUpdateID('');
                }}
                />
                <ModalRoleQr toggle={qr_toggle} code={otp_code} closeModal={()=>{setQrToggle(false); setOtpCode('')}} />
            </Container>
        </>
        }
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(EmployeeRoleList);