import React, { useContext, useEffect, useState } from "react";
import { NewMainContext } from "../../context/NewMainContext";
import LeaveApprovalSchemeCreate from "./LeaveApprovalSchemeCreate";
import LeaveApprovalSchemeEdit from "./LeaveApprovalSchemeEdit";
import LeaveApprovalSchemeList from "./LeaveApprovalSchemeList";


export default function LeaveApprovalScheme(){

    const { leave_approval_scheme_reset, setLeaveApprovalSchemeReset } = useContext(NewMainContext);
    const [step, setStep ] = useState(0);
    const [edit_id,setEditID ] = useState(null);

    useEffect(()=>{
        if(leave_approval_scheme_reset === true){
            setStep(0);
            setLeaveApprovalSchemeReset(false);
        }
    },[leave_approval_scheme_reset, setLeaveApprovalSchemeReset])

    return(
        <>
        {step === 0 && <LeaveApprovalSchemeList setStep={setStep} setEditID={setEditID} />}
        {step === 1 && <LeaveApprovalSchemeCreate setStep={setStep}  />}
        {step === 2 && <LeaveApprovalSchemeEdit setStep={setStep} id={edit_id} />}
        </>
    )
}