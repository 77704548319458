import myaxios from '../axios';


export const GETBASESETTING_REQUEST = 'GETBASESETTING_REQUEST';
export const GETBASESETTING_SUCCESS = 'GETBASESETTING_SUCCESS';
export const GETBASESETTING_FAILURE = 'GETBASESETTING_FAILURE';

export const get_base_setting =()=>{
    return dispatch=>{
        dispatch({
            type:'GETBASESETTING_REQUEST'
        });

        myaxios.get('settings/')
        .then((response)=>{
            dispatch({
                type:'GETBASESETTING_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETBASESETTING_FAILURE',
                payload:error
            })
        })
    }
}