import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEEMPROLE_REQUEST = 'UPDATEEMPROLE_REQUEST';
export const UPDATEEMPROLE_SUCCESS = 'UPDATEEMPROLE_SUCCESS';
export const UPDATEEMPROLE_FAILURE = 'UPDATEEMPROLE_FAILURE';
export const UPDATEEMPROLE_RESET = 'UPDATEEMPROLE_RESET';

export const update_employee_role = (id,role_hr,role_leave_officer,role_claim_officer,role_manager)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEEMPROLE_REQUEST'
        });
        
        myaxios.post(`employee/users/${id}/roles/`,{
            role_hr:role_hr,
            role_claim_officer:role_claim_officer,
            role_leave_officer:role_leave_officer,
            role_manager:role_manager
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEEMPROLE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEEMPROLE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_employee_role = () => dispatch =>{
    dispatch({
        type:'UPDATEEMPROLE_RESET'
    });
}

