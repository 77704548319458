import * as contractjoblevel from '../../action/employee/employee_contract_joblevel';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}


const employee_contract_joblevel = (state=initialState,action)=>{
    switch(action.type){
        case contractjoblevel.EMPCONTRACTJOBLEVEL_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case contractjoblevel.EMPCONTRACTJOBLEVEL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractjoblevel.EMPCONTRACTJOBLEVEL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_contract_joblevel;