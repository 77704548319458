import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const ADDOTPLIST_REQUEST = 'ADDOTPLIST_REQUEST';
export const ADDOTPLIST_SUCCESS = 'ADDOTPLIST_SUCCESS';
export const ADDOTPLIST_FAILURE = 'ADDOTPLIST_FAILURE';
export const ADDOTPLIST_RESET = 'ADDOTPLIST_RESET';

export const add_otp_list = (secret,otp)=>{
    return dispatch=>{
        dispatch({
            type:'ADDOTPLIST_REQUEST'
        });

        myaxios.post('otp/create/',{
            secret:secret,
            otp:otp
        })
        .then((response)=>{
            dispatch({
                type:'ADDOTPLIST_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('add_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'ADDOTPLIST_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('add_failure')
            });
        })
    }
}

export const reset_add_otp_list = () =>dispatch =>{
    dispatch({
        type:'ADDOTPLIST_RESET'
    });
}