import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEEMPCERT_REQUEST = 'UPDATEEMPCERT_REQUEST';
export const UPDATEEMPCERT_SUCCESS = 'UPDATEEMPCERT_SUCCESS';
export const UPDATEEMPCERT_FAILURE = 'UPDATEEMPCERT_FAILURE';
export const UPDATEEMPCERT_RESET = 'UPDATEEMPCERT_RESET';

export const update_employee_certification = (id,name,school,country,acquired_date,expiry_date,notification_duration_days)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEEMPCERT_REQUEST'
        });

        myaxios.patch(`employee/certification/${id}/`,{
            name:name,
            school:school,
            country:country,
            acquired_date:acquired_date,
            expiry_date:expiry_date,
            notification_duration_days:notification_duration_days
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEEMPCERT_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEEMPCERT_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_employee_certification = () => dispatch =>{
    dispatch({
        type:'UPDATEEMPCERT_RESET'
    })
}
