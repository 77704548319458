import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const UPDATEGENERALCURRENCY_REQUEST = 'UPDATEGENERALCURRENCY_REQUEST';
export const UPDATEGENERALCURRENCY_SUCCESS = 'UPDATEGENERALCURRENCY_SUCCESS';
export const UPDATEGENERALCURRENCY_FAILURE = 'UPDATEGENERALCURRENCY_FAILURE';
export const UPDATEGENERALCURRENCY_RESET = 'UPDATEGENERALCURRENCY_RESET';

export const update_general_currency = (id,currency_name,currency_symbol)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEGENERALCURRENCY_REQUEST'
        });
        
        myaxios.patch(`general/currency/${id}/`,{
            currency_name:currency_name,
            currency_symbol:currency_symbol
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEGENERALCURRENCY_SUCCESS',
                payload:response
            });
            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEGENERALCURRENCY_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_general_currency = () => dispatch =>{
    dispatch({
        type:'UPDATEGENERALCURRENCY_RESET'
    });
}