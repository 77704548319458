import * as updatecompany from '../../action/company/update_company';


const initialState ={
    isLoading:false,
    errors:null,
    data:null
}

const update_company = (state=initialState,action) =>{
    switch(action.type) {
        case updatecompany.UPDATECOM_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case updatecompany.UPDATECOM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updatecompany.UPDATECOM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case updatecompany.UPDATECOM_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default update_company;
