import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import {delete_employee_certification, reset_delete_employee_certification} from '../../../action/employee/delete_employee_certification';
import {get_employee_certification} from '../../../action/employee/get_employee_certification';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalViewCertification from './modal/ModalViewCertification';
import ModalCreateCertification from './modal/ModalCreateCertification';
import ModalEditCertification from './modal/ModalEditCertification';
import { emp_get_employee_certification } from '../../../reducers/employee/get_employee_certification';
import LoadingTable from './component/LoadingTable';
import NoDataTable from './component/NoDataTable';
import CertificationTable from './component/CertificationTable';

export default function EmployeeCertification({id}){

    const delete_success = useSelector(state=>state.delete_employee_certification.data);
    const isLoading = useSelector(state=>state.get_employee_certification.isLoading);
    const list = useSelector(state=>emp_get_employee_certification(state,parseInt(id)));
    const dispatch = useDispatch();

    const [add_toggle,setAddToggle] = useState(false);
    const [view_toggle,setViewToggle] = useState(false);
    const [view_detail,setViewDetail] = useState(null);
    const [edit_detail,setEditDetail] = useState(null);
    const [edit_toggle,setEditToggle] = useState(false);

    useEffect(()=>{
        if(delete_success != null){
            dispatch(get_employee_certification());
            dispatch(reset_delete_employee_certification());
        }
    },[delete_success,dispatch])

    const deleteCert=(id)=>{
        const data = list.find((item)=>item.id === id);
        Swal.fire({
            title:`${IMLocalized('delete_certification_confirmation')} ( ${data?.name ?? ''} )?`,
            text:IMLocalized('revert_msg'),
            type:'warning',
            showCancelButton:true,
            cancelButtonColor:'#3085d6',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_employee_certification(id));
            }
        })
    }

    const toView=(id)=>{
        const toggle = !view_toggle;
        if(toggle){
            const data = list.find((item)=>item.id === id);
            setViewDetail(data);
        }
        else{
            dispatch(get_employee_certification());
            setViewDetail(null);
        }
        setViewToggle(toggle);
    }

    const editCertification=(id)=>{
        const data = list.find((item)=>item.id === id);
        setEditDetail(data);
        setEditToggle(true);
    }

    return(
        <>
            <div className="d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title">
                <div className="d-flex align-items-center">
                    <i className="fas fa-graduation-cap text-primary"></i>
                    <h4 className="text-primary m-0 pl-2">{IMLocalized('employee_certification')}</h4>
                </div>
                <div className="text-primary pt-2 cursor-pointer pr-2" onClick={()=>setAddToggle(true)} >
                    <i className="fas fa-plus-circle mr-1 function_icon"/>
                    <small>{IMLocalized('add_certification')}</small>
                </div>
            </div>
            {isLoading && <LoadingTable/>}
            {!isLoading && list.length === 0 &&  <NoDataTable/>}
            {!isLoading && list.length !== 0 && <CertificationTable list={list} toView={toView} editCertification={editCertification} deleteCert={deleteCert} />}
            <ModalCreateCertification toggle={add_toggle} closeModal={()=>setAddToggle(false)} onSuccess={()=>{ dispatch(get_employee_certification());setAddToggle(false)}} id={id} />
            <ModalViewCertification toggle={view_toggle} closeModal={()=>toView()} detail={view_detail} type="hr" />
            <ModalEditCertification toggle={edit_toggle} closeModal={()=>setEditToggle(false)} detail={edit_detail} onSuccess={()=>{dispatch(get_employee_certification());setEditToggle(false)}}  />
        </>
    )
}