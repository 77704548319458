import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";


export default function ModalErrorEmployeeChild({ list, toggle, closeModal}){


    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader>{IMLocalized('error_msg')}</ModalHeader>
            <ModalBody>
                <Table size="sm" className="table-bordered align-items-center" responsive>
                    <thead className="thead-light p-2">
                        <tr>
                            <th>{IMLocalized('employee_number')}</th>
                            <th>{IMLocalized('name')}</th>
                            <th>{IMLocalized('error')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item,index)=>{
                            const { msg } = item; 
                            return(
                                <tr key={index}>
                                    <td>
                                        {item.number}
                                    </td>
                                    <td>
                                        {item.name}
                                    </td>
                                    <td>
                                        {msg.map((el,index)=>{
                                            return(
                                                <div key={index}>
                                                    <span>● {el.msg}</span>
                                                </div>
                                            )
                                        })}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}