import * as claim_approval from '../../action/claim/create_claim_approval';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const create_claim_approval = (state=initialState,action)=>{
    switch(action.type){
        case claim_approval.CLAIMAPPROVAL_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case claim_approval.CLAIMAPPROVAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case claim_approval.CLAIMAPPROVAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default create_claim_approval;