import myaxios from '../../axios';

export const GETEMPRELATIONSHIP_REQUEST = 'GETEMPRELATIONSHIP_REQUEST';
export const GETEMPRELATIONSHIP_SUCCESS = 'GETEMPRELATIONSHIP_SUCCESS';
export const GETEMPRELATIONSHIP_FAILURE = 'GETEMPRELATIONSHIP_FAILURE';


export const get_employee_relationship = () =>{
    return dispatch =>{
        dispatch({
            type:'GETEMPRELATIONSHIP_REQUEST'
        });
        
        myaxios.get('employee/relationship/')
        .then((response)=>{
            dispatch({
                type:'GETEMPRELATIONSHIP_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPRELATIONSHIP_FAILURE',
                payload:error
            })
        })
    }
}