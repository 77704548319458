import React, { useContext } from "react";
import { TabContent, TabPane } from "reactstrap";
import { NewMainContext } from "../../../context/NewMainContext";
import AdditionalSetting from "../../company/AdditionalSetting";
import CompanyList from "../../company/CompanyList";
import OvertimeAllowance from "../../company/OvertimeAllowance";
import ContractTemplateList from "../../contract/ContractTemplateList";
import HrDashboard from "../../dashboard/HrDashboard";
import CreateEmployee from "../../employee/hr_employee/CreateEmployee";
import EmployeeMain from "../../employee/hr_employee/EmployeeMain";
import ImportCreateEmployee from "../../employee/hr_employee/import_create_employee"; //1
import ProbationList from "../../employee/hr_employee/ProbationList";
import GeneralCurrency from "../../general/GeneralCurrency";
import GeneralSetting from "../../general/GeneralSetting";
import LeaveBaseSetting from "../../leave/LeaveBaseSetting";
import LeaveCalendar from "../../leave/LeaveCalendar"; //
import LeaveList from "../../leave/LeaveLeaveList";
import LeaveResetList from "../../leave/LeaveReset";
import LeaveScheme from "../../leave/LeaveScheme";
import LeaveTable from "../../leave/LeaveTable";
import Payroll from "../../payroll/Payroll";
import MonthlyReport from "../../report/MonthlyReport2";
import PayrollComputationHeadcount from "../../report/PayrollComputationHeadcount";
import PayrollComputationSummary from "../../report/PayrollComputationSummary";
import PayrollVariance from "../../report/PayrollVarianceEmployee";
import HolidayList from "../../schedule/HolidayList";
import ScheduleList from "../../schedule/ScheduleList";
import AppliedClaimList from '../../claim/ClaimAll';
import ClaimOption from '../../claim/ClaimBaseSetting';
import ClaimList from '../../claim/ClaimClaimList';
import PaySummaryList from "../../claim/ClaimPaySummaryList";
import PayrollIncrement from "../../payroll/payroll_increment";//9
import History from "../../history/History";
import PayrollHistory from "../../history/PayrollHistory";
import ApprovalsList from '../../leave/leave_approval_list';//10
import ImportEmpContract from '../../employee/hr_employee/import_create_emp_contract';//11
import ImportAdditional from "../../company/ImportAdditional";
import EmployeeRoleList from "../../employee/hr_employee/EmployeeRoleList";
import ClaimApprovalList from '../../claim/ClaimApprovalList';
import ClaimMassGroupTemplate from "../../claim/ClaimMassGroupTemplate";
import LeaveMassGroupTemplate from "../../leave/LeaveMassGroupTemplate";
import LeaveApprovalScheme from '../../leave/LeaveApprovalScheme';
import ClaimApprovalScheme from '../../claim/ClaimApprovalScheme';
import LeaveMassScheme from "../../leave/leave_mass_scheme";//14
import ClaimMassScheme from "../../claim/claim_mass_scheme";//15
import AppraisalList from "../../appraisal/AppraisalList";
import AppraisalGroupList from "../../appraisal/AppraisalGroupList";
import AppraisalAction from "../../appraisal/AppraisalAction";
import LeaveMassApproval from '../../leave/LeaveMassApproval';
import ClaimMassApproval from "../../claim/ClaimMassApproval";
import EmpListingReport from '../../report/EmployeeListingReport';
import CustomReportTemplate from "../../report/CustomReportTemplate";
import ClaimMassScheme1 from "../../claim/ClaimMassScheme1";
import LeaveMassScheme1 from "../../leave/LeaveMassScheme1";
import IrasList from "../../iras/IrasList";
import OtpBlacklist from "../../company/OtpBlacklist";
import ImportCreateEmployeeBank from "../../employee/hr_employee/import_create_employee_bank";//18
import CpfTest from "../../test/cpf_test";//19
import PayrollPayslipReport from "../../report/PayrollPayslipReport";
import ImportCreateEmployeeContact from "../../employee/hr_employee/import_create_employee_contact";//20
import ImportCreateEmployeePassport from "../../employee/hr_employee/import_create_employee_passport";//21
import ImportCreateEmployeePermit from "../../employee/hr_employee/import_create_employee_permit";//22
import MonthlyReport2 from "../../report/MonthlyReport";
import PayrollIncrementView from "../../payroll/PayrollIncrementView";
import ClaimScheme from "../../claim/ClaimScheme";
import EmployeeMassSelfHelp from "../../employee/hr_employee/EmployeeMassSelfHelp";
import EmployeeMassUpdateProfile from "../../employee/hr_employee/EmployeeMassUpdateProfile";

export default function HrTabContent(){

    const { activeTab, action_toggle } = useContext(NewMainContext);

    return(
        <TabContent activeTab={activeTab} className={action_toggle === true ? 'body-tabpane scrollbarstyle1' : 'body-tabpane1 scrollbarstyle1'}>
            <TabPane tabId='1'>
                <HrDashboard/>
            </TabPane>
            <TabPane tabId="2">
                <CompanyList />
            </TabPane>
            <TabPane tabId="4">
                <AdditionalSetting/>
            </TabPane>
            <TabPane tabId="5">
                <OvertimeAllowance/>
            </TabPane>
            <TabPane tabId="7">
                <EmployeeMain/>
            </TabPane>
            <TabPane tabId="8">
                <ProbationList/>
            </TabPane>
            <TabPane tabId="9">
                <CreateEmployee/>
            </TabPane>
            <TabPane tabId="10">
                <ImportCreateEmployee/>
            </TabPane>
            <TabPane tabId="11">
                <ScheduleList/>
            </TabPane>
            <TabPane tabId="12">
                <HolidayList/>
            </TabPane>
            <TabPane tabId="15">
                <Payroll/>
            </TabPane>
            <TabPane tabId="16">
                <MonthlyReport/>
            </TabPane>
            <TabPane tabId="17">
                <PayrollVariance/>
            </TabPane>
            <TabPane tabId="18">
                <PayrollComputationSummary/>
            </TabPane>
            <TabPane tabId="19">
                <PayrollComputationHeadcount/>
            </TabPane>
            <TabPane tabId="20">
                <ContractTemplateList/>
            </TabPane>
            <TabPane tabId="22">
                <GeneralSetting/>
            </TabPane>
            <TabPane tabId="23">
                <GeneralCurrency/>
            </TabPane>
            <TabPane tabId="24">
                <LeaveCalendar/>
            </TabPane>
            <TabPane tabId="26">
                <LeaveTable/>
            </TabPane>
            <TabPane tabId="27">
                <LeaveBaseSetting/>
            </TabPane>
            <TabPane tabId="28">
                <LeaveList/>
            </TabPane>
            <TabPane tabId="30">
                <LeaveScheme/>
            </TabPane>
            <TabPane tabId="33">
                <LeaveResetList/>
            </TabPane>
            <TabPane tabId="34">
                <AppliedClaimList/>
            </TabPane>
            <TabPane tabId="35">
                <ClaimOption/>
            </TabPane>
            <TabPane tabId="36">
                <ClaimList/>
            </TabPane>
            <TabPane tabId="38">
                <ClaimScheme/>
            </TabPane>
            <TabPane tabId="41">
                <PaySummaryList/>
            </TabPane>
            <TabPane tabId="42">
                <PayrollIncrement/>
            </TabPane>
            <TabPane tabId="44">
                <History/>
            </TabPane>
            <TabPane tabId="45">
                <PayrollHistory/>
            </TabPane>
            <TabPane tabId="46">
                <ApprovalsList />
            </TabPane>
            <TabPane tabId="47">
                <ImportEmpContract/>
            </TabPane>
            <TabPane tabId="48">
                <ImportAdditional/>
            </TabPane>
            <TabPane tabId="49">
                <EmployeeRoleList/>
            </TabPane>
            <TabPane tabId="54">
                <ClaimApprovalList/>
            </TabPane>
            <TabPane tabId="58">
                <ClaimMassGroupTemplate/>
            </TabPane>
            <TabPane tabId="59">
                <LeaveMassGroupTemplate/>
            </TabPane>
            <TabPane tabId="62">
                <LeaveApprovalScheme/>
            </TabPane>
            <TabPane tabId="63">
                <ClaimApprovalScheme/>
            </TabPane>
            <TabPane tabId="64">
                <LeaveMassScheme/>
            </TabPane>
            <TabPane tabId="67">
                <ClaimMassScheme/>
            </TabPane>
            <TabPane tabId="68">
                <AppraisalList/>
            </TabPane>
            <TabPane tabId="69">
                <AppraisalGroupList  />
            </TabPane>
            <TabPane tabId="70">
                <AppraisalAction/>
            </TabPane>
            <TabPane tabId="71">
                <LeaveMassApproval/>
            </TabPane>
            <TabPane tabId="72">
                <ClaimMassApproval/>
            </TabPane>
            <TabPane tabId="73">
                <EmpListingReport/>
            </TabPane>
            <TabPane tabId="74">
                <CustomReportTemplate/>
            </TabPane>
            <TabPane tabId="75">
                <ClaimMassScheme1/>
            </TabPane>
            <TabPane tabId="76">
                <LeaveMassScheme1/>
            </TabPane>
            <TabPane tabId="77">
                <IrasList/>
            </TabPane>
            <TabPane tabId="78">
                <OtpBlacklist/>
            </TabPane>
            <TabPane tabId="79">
                <ImportCreateEmployeeBank/>
            </TabPane>
            <TabPane tabId="80">
                <CpfTest/>
            </TabPane>
            <TabPane tabId="81">
                <PayrollPayslipReport/>
            </TabPane>
            <TabPane tabId="82">
                <ImportCreateEmployeeContact/>
            </TabPane>
            <TabPane tabId="83">
                <ImportCreateEmployeePassport/>
            </TabPane>
            <TabPane tabId="84">
                <ImportCreateEmployeePermit/>
            </TabPane>
            <TabPane tabId="85">
                <MonthlyReport2/>
            </TabPane>
            <TabPane tabId="88">
                <PayrollIncrementView/>
            </TabPane>
            <TabPane tabId="89">
                <EmployeeMassSelfHelp/>
            </TabPane>
            <TabPane tabId="90">
                <EmployeeMassUpdateProfile/>
            </TabPane>
        </TabContent>
    )
}