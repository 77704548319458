import * as emppayroll from '../../action/report/payroll_variance_employee_payroll';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}


const payroll_variance_employee_payroll = (state=initialState,action)=>{
    switch(action.type){
        case emppayroll.VARIANCEEMPPAYROLL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[],
                errors:[]
            }
        case emppayroll.VARIANCEEMPPAYROLL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data,
            }
        case emppayroll.VARIANCEEMPPAYROLL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case emppayroll.VARIANCEEMPPAYROLL_RESET:
            return{
                ...state,
                data:[],
                errors:[]
            }
        default:
            return state;
    }
}
export default payroll_variance_employee_payroll;