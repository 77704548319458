import * as set from '../../action/company/set_company_bank_default';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const set_company_bank_default = (state=initialState,action)=>{
    switch(action.type){
        case set.SETCOMBANKDEFAULT_REQUEST:
            return{
                isLoading:true,
                data:null,
                errors:null
            }
        case set.SETCOMBANKDEFAULT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case set.SETCOMBANKDEFAULT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case set.SETCOMBANKDEFAULT_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state
    }
}
export default set_company_bank_default;