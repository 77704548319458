import * as sg_address from '../action/sg_address';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
    address:[]
}

const sgaddress = (state=initialState,action)=>{
    switch(action.type){
        case sg_address.SGADDDRESS_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[],
                data:[]
            }
        case sg_address.SGADDDRESS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                address:action.payload.data.results,
                errors:[]
            }
        case sg_address.SGADDDRESS_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload,
                address:'',
                data:[]
            }
        default:
            return state;
    }
}

export default sgaddress;