import myaxios from '../../axios';

export const GETAPPLYLEAVELIST2_REQUEST = 'GETAPPLYLEAVELIST2_REQUEST';
export const GETAPPLYLEAVELIST2_SUCCESS = 'GETAPPLYLEAVELIST2_SUCCESS';
export const GETAPPLYLEAVELIST2_FAILURE = 'GETAPPLYLEAVELIST2_FAILURE';

export const get_apply_leave_list2 =()=>{
    return dispatch=>{
        dispatch({
            type:'GETAPPLYLEAVELIST2_REQUEST'
        });

        myaxios.get('leave/apply/details/')
        .then((response)=>{
            dispatch({
                type:'GETAPPLYLEAVELIST2_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETAPPLYLEAVELIST2_FAILURE',
                payload:error
            })
        })
    }
}